import { isEmpty } from 'lodash';
import React from 'react';
import Dialog, { DialogAction, DialogContent, DialogFooter, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import TransactionFee from '../../../tx/transaction-fee/transaction-fee';
import useHandleTxResponses from '../../../tx/use-handle-tx-responses';
import { useWallet } from '../../../wallet/wallet-context';
import { RollappLiquidity } from '../eibc-client-types';
import { useRemoveRollappLiquidity } from './use-remove-rollapp-liquidity';
import './remove-rollapp-liquidity-dialog.scss';

interface RemovedRollappLiquidityDialogProps extends DialogProps {
    liquidity: RollappLiquidity;
}

const RemoveRollappLiquidityDialog: React.FC<RemovedRollappLiquidityDialogProps> = ({ liquidity, ...otherDialogProps }) => {
    const { hubWallet } = useWallet();
    const { broadcast, txState } = useRemoveRollappLiquidity(liquidity);
    useHandleTxResponses(txState, hubWallet, otherDialogProps.onRequestClose);

    return (
        <Dialog className='remove-rollapp-liquidity-dialog' closable {...otherDialogProps}>
            <DialogTitle>Revoke RollApp</DialogTitle>
            <DialogContent className='liquidity-dialog-content'>
                Are you sure you want to revoke this Rollapp? This action will stop serving it.
            </DialogContent>
            <DialogFooter className='section small'>
                <TransactionFee
                    fee={{ label: 'Transaction fee', value: txState.fee?.coins, loading: txState.feeLoading }}
                    hideAmount={!hubWallet}
                />
            </DialogFooter>
            <DialogAction
                className='remove-button'
                onClick={() => broadcast()}
                primary
                loading={txState.broadcasting}
                disabled={isEmpty(txState) || txState.broadcasting || txState.feeLoading || !liquidity.rollapp}
            >
                Revoke
            </DialogAction>
        </Dialog>
    );
};

export default RemoveRollappLiquidityDialog;
