import classNames from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ThunderIcon } from '../../../assets/icons/thunder.svg';
import Icon from '../../../shared/components/icon/icon';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getTimeLeftText, getTimeOffset } from '../../../shared/utils/date-utils';
import ConnectQuickAuthWalletDialog from '../../account/account-menu/connect-quick-auth-wallet-dialog/connect-quick-auth-wallet-dialog';
import { useLayout } from '../../app/layout/layout-context';
import { useQuickAuth } from '../quick-auth-context';
import QuickAuthDialog from '../quick-dialog/quick-auth-dialog';
import './quick-auth-action.scss';

const QuickAuthAction: FC = () => {
    const { ctaClassName } = useLayout();
    const [ quickAuthDialogOpen, setQuickAuthDialogOpen ] = useState(false);
    const [ connectQuickAuthWalletOpen, setConnectQuickAuthWalletOpen ] = useState(false);
    const { networkState, expiration, isConnected } = useQuickAuth();
    const { account } = useParams();
    const { isTablet } = useWindowSize();
    const navigate = useNavigate();

    const urlEncryptedAccount = useMemo(
        () => !account ? undefined : account.replace(`${window.location.origin}/account/`, ''),
        [ account ],
    );

    return <>
        <Tooltip title='Quick Auth' placement='top'>
            <div
                className={classNames('quick-auth-action', ctaClassName, { connected: isConnected })}
                onClick={() => isTablet ? setConnectQuickAuthWalletOpen(true) : setQuickAuthDialogOpen(true)}
            >
                <Icon className='thunder-icon'><ThunderIcon /></Icon>
                {isConnected && <span className='time'>{getTimeLeftText(getTimeOffset(expiration), 'double')}</span>}
            </div>
        </Tooltip>

        {quickAuthDialogOpen && networkState.network &&
            <QuickAuthDialog networkState={networkState} onRequestClose={() => setQuickAuthDialogOpen(false)} />}

        {networkState.network && (connectQuickAuthWalletOpen || urlEncryptedAccount) &&
            <ConnectQuickAuthWalletDialog
                urlEncryptedAccount={urlEncryptedAccount}
                onRequestClose={() => urlEncryptedAccount ? navigate('/') : setConnectQuickAuthWalletOpen(false)}
            />}
    </>;
};

export default QuickAuthAction;
