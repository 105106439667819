import { createProtobufRpcClient, QueryClient, StargateClient, StargateClientOptions } from 'cosmjs/packages/stargate';
import { Tendermint34Client, TendermintClient } from 'cosmjs/packages/tendermint-rpc';
import { QueryClientImpl as IbcTransferQueryClient } from 'cosmjs-types/ibc/applications/transfer/v1/query';
import { QueryClientImpl as StakingQueryClient } from 'cosmjs-types/cosmos/staking/v1beta1/query';
import { QueryClientImpl as DistributionQueryClient } from 'cosmjs-types/cosmos/distribution/v1beta1/query';
import { QueryClientImpl as AuthZQueryClient } from 'cosmjs-types/cosmos/authz/v1beta1/query';
import { QueryClientImpl as FeeGrantQueryClient } from 'cosmjs-types/cosmos/feegrant/v1beta1/query';
import { QueryClientImpl as GovQueryClient } from 'cosmjs-types/cosmos/gov/v1beta1/query';
import { NetworkDenom } from '../../currency/currency-types';
import { accountParser } from './account-parser/account-parser';
import { QueryClientImpl as GammQueryClient } from './dymension/generated/gamm/v1beta1/query';
import { QueryClientImpl as LockupQueryClient } from './dymension/generated/lockup/query';
import { QueryClientImpl as StreamerQueryClient } from './dymension/generated/streamer/query';
import { QueryClientImpl as IncentivesQueryClient } from './dymension/generated/incentives/query';
import { QueryClientImpl as DymnsQueryClient } from './dymension/generated/dymns/query';
import { QueryClientImpl as BankQueryClient } from './dymension/generated/bank/v1beta1/query';
import { QueryClientImpl as SponsorshipQueryClient } from './dymension/generated/sponsorship/query';
import { QueryClientImpl as EvmQueryClient } from './dymension/generated/ethermint/evm/v1/query';
import { ClientError } from '../client-error';
import { ChannelNetworkMap, Network, VirtualFrontierContract } from '../../network/network-types';

export class StationClient extends StargateClient {
    private readonly ibcTransferQueryClient: IbcTransferQueryClient;
    private readonly stakingQueryClient: StakingQueryClient;
    private readonly distributionQueryClient: DistributionQueryClient;
    private readonly bankQueryClient: BankQueryClient;
    private readonly govQueryClient: GovQueryClient;
    private readonly gammQueryClient: GammQueryClient;
    private readonly streamerQueryClient: StreamerQueryClient;
    private readonly lockupQueryClient: LockupQueryClient;
    private readonly authZQueryClient: AuthZQueryClient;
    private readonly feeGrantQueryClient: FeeGrantQueryClient;
    private readonly incentivesQueryClient: IncentivesQueryClient;
    private readonly dymnsQueryClient: DymnsQueryClient;
    private readonly sponsorshipQueryClient: SponsorshipQueryClient;
    private readonly evmQueryClient: EvmQueryClient;
    private readonly network: Network;
    private readonly hubNetwork: Network;
    private readonly allNetworks: Network[];
    private readonly hubChannelNetworkMap: ChannelNetworkMap;
    private hubNetworkDenoms?: NetworkDenom[];
    private vfcMap?: { [denom: string]: VirtualFrontierContract };

    public static async connectForNetwork(
        network: Network,
        hubNetwork: Network,
        allNetworks: Network[],
        hubChannelNetworkMap: ChannelNetworkMap,
        hubNetworkDenoms?: NetworkDenom[],
        vfcMap?: { [denom: string]: VirtualFrontierContract },
    ): Promise<StationClient> {
        if (!network.rpc) {
            throw new ClientError('CONNECT_CLIENT_FAILED', network, 'missing rpc');
        }
        try {
            const tmClient = await Tendermint34Client.connect(network.rpc);
            return new StationClient(
                tmClient,
                network,
                hubNetwork,
                allNetworks,
                hubChannelNetworkMap,
                { accountParser },
                hubNetworkDenoms,
                vfcMap,
            );
        } catch (error) {
            throw new ClientError('CONNECT_CLIENT_FAILED', network, error);
        }
    }

    public getNetwork(): Network {
        return this.network;
    }

    public getHubNetwork(): Network {
        return this.hubNetwork;
    }

    public getAllNetworks(): Network[] {
        return this.allNetworks;
    }

    public getHubChannelNetworkMap(): ChannelNetworkMap {
        return this.hubChannelNetworkMap;
    }

    public getTmClient() {
        return super.getTmClient();
    }

    public getIbcTransferQueryClient(): IbcTransferQueryClient {
        return this.ibcTransferQueryClient;
    }

    public getStakingQueryClient(): StakingQueryClient {
        return this.stakingQueryClient;
    }

    public getFeeGrantQueryClient(): FeeGrantQueryClient {
        return this.feeGrantQueryClient;
    }

    public getAuthZQueryClient(): AuthZQueryClient {
        return this.authZQueryClient;
    }

    public getDistributionQueryClient(): DistributionQueryClient {
        return this.distributionQueryClient;
    }

    public getGovQueryClient(): GovQueryClient {
        return this.govQueryClient;
    }

    public getBankQueryClient(): BankQueryClient {
        return this.bankQueryClient;
    }

    public getLockupQueryClient(): LockupQueryClient {
        return this.lockupQueryClient;
    }

    public getSponsorshipQueryClient(): SponsorshipQueryClient {
        return this.sponsorshipQueryClient;
    }

    public setHubNetworkDenoms(networkDenoms: NetworkDenom[]): void {
        this.hubNetworkDenoms = networkDenoms;
    }

    public getHubNetworkDenoms(): NetworkDenom[] | undefined {
        return this.hubNetworkDenoms;
    }

    public setHubVfcMap(vfcMap: { [denom: string]: VirtualFrontierContract }): void {
        this.vfcMap = vfcMap;
    }

    protected constructor(
        tmClient: TendermintClient,
        network: Network,
        hubNetwork: Network,
        allNetworks: Network[],
        hubChannelNetworkMap: ChannelNetworkMap,
        options: StargateClientOptions,
        hubNetworkDenoms?: NetworkDenom[],
        vfcMap?: { [denom: string]: VirtualFrontierContract },
    ) {
        super(tmClient, options);
        const queryClient = QueryClient.withExtensions(tmClient);
        const rpcClient = createProtobufRpcClient(queryClient);
        this.ibcTransferQueryClient = new IbcTransferQueryClient(rpcClient);
        this.stakingQueryClient = new StakingQueryClient(rpcClient);
        this.distributionQueryClient = new DistributionQueryClient(rpcClient);
        this.bankQueryClient = new BankQueryClient(rpcClient);
        this.authZQueryClient = new AuthZQueryClient(rpcClient);
        this.gammQueryClient = new GammQueryClient(rpcClient);
        this.lockupQueryClient = new LockupQueryClient(rpcClient);
        this.incentivesQueryClient = new IncentivesQueryClient(rpcClient);
        this.dymnsQueryClient = new DymnsQueryClient(rpcClient);
        this.streamerQueryClient = new StreamerQueryClient(rpcClient);
        this.govQueryClient = new GovQueryClient(rpcClient);
        this.evmQueryClient = new EvmQueryClient(rpcClient);
        this.sponsorshipQueryClient = new SponsorshipQueryClient(rpcClient);
        this.feeGrantQueryClient = new FeeGrantQueryClient(rpcClient);
        this.network = network;
        this.hubNetwork = hubNetwork;
        this.allNetworks = allNetworks;
        this.hubChannelNetworkMap = hubChannelNetworkMap;
        this.hubNetworkDenoms = hubNetworkDenoms;
        this.vfcMap = vfcMap;
    }
}
