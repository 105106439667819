import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { uniq } from 'lodash';
import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { ClientError } from '../client/client-error';
import {
    MsgCreatePoolEncodeObject,
    MsgExitPoolEncodeObject,
    MsgJoinPoolEncodeObject,
    MsgJoinSwapPoolEncodeObject,
    MsgSwapExactAmountInEncodeObject,
} from '../client/station-clients/dymension/generated/gamm/messages';
import { Params as AmmModuleParams } from '../client/station-clients/dymension/generated/gamm/v1beta1/genesis';
import { Pool as BalancerPool } from '../client/station-clients/dymension/generated/gamm/v1beta1/pool-models/balancer/balancerPool';
import { MsgBeginUnlockingEncodeObject, MsgLockTokensEncodeObject } from '../client/station-clients/dymension/generated/lockup/messages';
import { SwapAmountInRoute } from '../client/station-clients/dymension/generated/poolmanager/v1beta1/swap_route';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoin, convertToCoinsAmount, getFixedDenom, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { LOCK_DEFAULT_DURATION } from '../incentives/types';
import { getNetworkData } from '../network/network-service';
import { PoolAnalyticsSummary } from './statistics/analytics/pool-analytics-types';
import { AmmParams, LockedAsset, Pool, PoolPosition } from './types';

export const POOL_SHARE_PREFIX = 'gamm/pool/';

export const loadAmmParams = async (client: StationClient, signal: AbortSignal): Promise<AmmParams> => {
    const network = client.getNetwork();
    const params = await getNetworkData<AmmModuleParams>(network.chainId, 'amm-params', true, signal);
    if (!params?.poolCreationFee?.length) {
        throw new ClientError('FETCH_DATA_FAILED', network, new Error('Missing whitelist tokens'));
    }
    const [ vsCoins, ...poolCreationFees ] = await Promise.all(
        [ { denom: process.env.REACT_APP_VS_CURRENCY_DENOM, amount: '0' }, ...params.poolCreationFee ]
            .map((coin) => convertToCoinsAmount(coin, client, false)));
    if (!vsCoins) {
        throw new ClientError('FETCH_DATA_FAILED', network, new Error('Missing vs token'));
    }
    return {
        poolCreationTokens: poolCreationFees.filter(Boolean) as CoinsAmount[],
        exitFee: convertDecimalToInt(Number(params.globalFees?.exitFee) || 0),
        swapFee: convertDecimalToInt(Number(params.globalFees?.swapFee) || 0),
        takerFee: convertDecimalToInt(Number(params.takerFee) || 0),
        vsCoins,
    };
};

export const loadPools = async (client: StationClient, signal: AbortSignal): Promise<Pool[]> => {
    const network = client.getNetwork();
    const balancerPools = await getNetworkData<BalancerPool[]>(network.chainId, 'pools', false, signal);
    const pools = await Promise.all(balancerPools.map((pool) => convertPool(pool, client)));
    return pools.filter((pool) => pool.assets.length >= 2);
};

export const loadLockedAssets = async (client: StationClient, address: string): Promise<LockedAsset[]> => {
    const network = client.getNetwork();
    const response = await Promise.all([
        client.getLockupQueryClient().AccountLockedCoins({ owner: address }),
        client.getLockupQueryClient().AccountLockedDuration({ owner: address, duration: LOCK_DEFAULT_DURATION }),
    ]).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });
    return response[0].coins.map((lockedCoin) => {
        const lockedDuration = response[1].locks.find((lock) => lock.coins.some((coin) => coin.denom === lockedCoin.denom));
        return lockedDuration ? { coin: lockedCoin, lockId: Number(lockedDuration.ID) } : undefined;
    }).filter(Boolean) as { coin: Coin, lockId: number }[];
};

export const loadPositions = async (client: StationClient, address: string, lockedAssets: LockedAsset[]): Promise<PoolPosition[]> => {
    const network = client.getNetwork();
    const { balances } = await client.getBankQueryClient().AllBalances({
        address,
        pagination: {
            reverse: false,
            limit: 100000,
            offset: 0,
            countTotal: false,
            key: new Uint8Array(0),
        },
    }).catch((error) => {
        throw new ClientError('FETCH_DATA_FAILED', network, error);
    });
    const lpTokenDenoms = uniq([
        ...balances.map(({ denom }) => denom).filter((denom) => denom.includes(POOL_SHARE_PREFIX)),
        ...lockedAssets.map(({ coin }) => coin.denom).filter((denom) => denom.includes(POOL_SHARE_PREFIX)),
    ]);
    return lpTokenDenoms.map((denom) => {
        const balance = balances.find((balance) => balance.denom === denom);
        const lockedAsset = lockedAssets.find(({ coin }) => coin.denom === denom);
        return {
            poolId: Number((balance || lockedAsset?.coin)?.denom.replace(POOL_SHARE_PREFIX, '')) || 0,
            shares: BigInt(balance?.amount || '0') + BigInt(lockedAsset?.coin.amount || '0'),
            bondedShares: BigInt(lockedAsset?.coin.amount || '0'),
            lockId: Number(lockedAsset?.lockId) || undefined,
        };
    });
};

export const loadTotalLockedValues = async (
    client: StationClient,
    pools: Pool[],
    params: AmmParams,
    signal: AbortSignal,
): Promise<{ [denom: string]: number }> => {
    const network = client.getNetwork();
    const totalLockedTokens = await getNetworkData<Coin[]>(network.chainId, 'total-locked-tokens', false, signal);
    const totalLockedValues = await Promise.all(totalLockedTokens?.map(async (coin) => {
        let totalLockedValue = Number(coin.amount) || 0;
        if (!coin.denom.includes(POOL_SHARE_PREFIX)) {
            const lockedCoinsAmount = await convertToCoinsAmount(coin, client);
            totalLockedValue = lockedCoinsAmount ? getPrice(pools, params, lockedCoinsAmount, params.vsCoins) : 0;
        } else {
            const poolId = Number(coin.denom.replace(POOL_SHARE_PREFIX, ''));
            const pool = pools?.find((pool) => pool.id === poolId);
            if (pool) {
                totalLockedValue = (totalLockedValue / Number(pool.totalShares)) *
                    getMaxDenomAmount(pool.liquidity?.value.value || 0, params.vsCoins.currency);
            }
        }
        return { denom: coin.denom, value: totalLockedValue };
    }));
    return totalLockedValues.reduce((current, { denom, value }) => ({ ...current, [denom]: value }), {});
};

export const getOtherAssetPrice = (pool: Pool, coins: CoinsAmount): number => {
    let relation = pool.assets[0].amount / pool.assets[1].amount;
    if (isCoinsEquals(pool.assets[0], coins)) {
        relation = 1 / relation;
    }
    return coins.amount * relation;
};

export const getPrice = (pools: Pool[], ammParams: AmmParams, from: CoinsAmount, to: CoinsAmount): number => {
    if (isCoinsEquals(from, to)) {
        return from.amount;
    }
    const connectedPools = getConnectedPools(pools, ammParams, from, to);
    if (!connectedPools?.length) {
        return 0;
    }
    const coins = connectedPools.reduce((current, pool): CoinsAmount => {
        const otherToken = isCoinsEquals(pool.assets[0], current) ? pool.assets[1] : pool.assets[0];
        const otherTokenPrice = getOtherAssetPrice(pool, current);
        return { ...otherToken, amount: otherTokenPrice };
    }, from);
    return coins.amount;
};

export const createLiquidityMessage = (
    pool: Pool,
    sender: string,
    sharesAmount: bigint,
    assetBalances: CoinsAmount[],
    toRemove?: boolean,
): MsgJoinPoolEncodeObject | MsgJoinSwapPoolEncodeObject | MsgExitPoolEncodeObject | undefined => {
    if (!assetBalances.length) {
        return;
    }
    const tokens = assetBalances.map((coin) => convertToCoin(coin));
    if (toRemove) {
        tokens.forEach((token) => token.amount = '1');
        return {
            typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgExitPool',
            value: { poolId: pool.id, sender, shareInAmount: sharesAmount.toString(), tokenOutMins: tokens },
        };
    }
    if (tokens.length !== 1) {
        return {
            typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgJoinPool',
            value: { poolId: pool.id, sender, shareOutAmount: sharesAmount.toString(), tokenInMaxs: tokens },
        };
    }
    const token = tokens[0];
    return {
        typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgJoinSwapShareAmountOut',
        value: {
            poolId: pool.id,
            sender,
            shareOutAmount: sharesAmount.toString(),
            tokenInDenom: token.denom,
            tokenInMaxAmount: token.amount,
        },
    };
};

export const createLockMessage = (
    sender: string,
    coin: Coin,
    lockId?: number,
): MsgLockTokensEncodeObject | MsgBeginUnlockingEncodeObject => {
    if (lockId) {
        return {
            typeUrl: '/dymensionxyz.dymension.lockup.MsgBeginUnlocking',
            value: { owner: sender, ID: lockId, coins: [ coin ] },
        };
    }
    return {
        typeUrl: '/dymensionxyz.dymension.lockup.MsgLockTokens',
        value: { owner: sender, duration: LOCK_DEFAULT_DURATION, coins: [ coin ] },
    };
};

export const createSwapMessage = (
    sender: string,
    coins: CoinsAmount,
    connectedPools: Pool[],
    tokenOutMinAmount: string,
    baseAmount?: bigint,
): MsgSwapExactAmountInEncodeObject => {
    const swapParams = getSwapAmountInParams(coins, connectedPools);
    if (baseAmount !== undefined && baseAmount < BigInt(swapParams.tokenIn.amount)) {
        swapParams.tokenIn.amount = baseAmount.toString();
    }
    return {
        typeUrl: '/dymensionxyz.dymension.gamm.v1beta1.MsgSwapExactAmountIn',
        value: { ...swapParams, sender, tokenOutMinAmount },
    };
};

export const createCreatePoolMessage = (
    sender: string,
    swapFee: number,
    exitFee: number,
    assets: CoinsAmount[],
): MsgCreatePoolEncodeObject => {
    const tokens = assets.map((coins) => convertToCoin(coins));
    return {
        typeUrl: '/dymensionxyz.dymension.gamm.poolmodels.balancer.v1beta1.MsgCreateBalancerPool',
        value: {
            sender,
            futurePoolGovernor: '',
            poolParams: { swapFee: swapFee.toString(), exitFee: exitFee.toString(), smoothWeightChangeParams: undefined },
            poolAssets: tokens.map((token) => ({ token, weight: '50' })),
        },
    };
};

export const getPositionPart = (pool: Pool): number => {
    return (Number(pool.position?.shares) || 0) / Number(pool.totalShares);
};

export const getPositionBondedPart = (pool: Pool): number => {
    return (Number(pool.position?.bondedShares) || 0) / Number(pool.totalShares);
};

export const getPool = (pools: Pool[], coins1: CoinsAmount, coins2: CoinsAmount) => {
    return pools.find((pool) =>
        pool.assets.some((asset) => isCoinsEquals(asset, coins1) && pool.assets.some((asset) => isCoinsEquals(asset, coins2))),
    );
};

export const getConnectedPools = (
    pools: Pool[],
    ammParams: AmmParams,
    from: CoinsAmount,
    to: CoinsAmount,
    current: Pool[] = [],
    depth = ammParams.poolCreationTokens.length,
): Pool[] => {
    if (depth < 0) {
        return [];
    }
    const pool = getPool(pools, from, to);
    if (pool) {
        return [ ...current, pool ];
    }
    return ammParams.poolCreationTokens
        .map((coins) => getPool(pools, from, coins))
        .reduce((connectedPools, pool) => {
            const otherAsset = pool?.assets.find((asset) => !isCoinsEquals(asset, from));
            if (!pool || !otherAsset) {
                return connectedPools;
            }
            const checkedPools = getConnectedPools(pools, ammParams, otherAsset, to, [ ...current, pool ], depth - 1);
            return checkedPools.length ? checkedPools : connectedPools;
        }, [] as Pool[]);
};

const convertPool = async (pool: BalancerPool & PoolAnalyticsSummary, client: StationClient): Promise<Pool> => {
    const assets = await Promise.all(pool.poolAssets.filter((asset) => Boolean(asset.token))
        .map((asset) => convertToCoinsAmount(asset.token as Coin, client)));

    return {
        ...pool,
        id: pool.id,
        lpTokenDenom: POOL_SHARE_PREFIX + pool.id,
        assets: assets.filter(Boolean) as CoinsAmount[],
        swapFee: convertDecimalToInt(Number(pool.poolParams?.swapFee) || 0),
        exitFee: convertDecimalToInt(Number(pool.poolParams?.exitFee) || 0),
        totalShares: BigInt(pool.totalShares?.amount || '0'),
    };
};

const getSwapAmountInParams = (coins: CoinsAmount, connectedPools: Pool[]): { tokenIn: Coin, routes: SwapAmountInRoute[] } => {
    let tokenOut = coins;
    const routes = connectedPools.reduce((current, pool) => {
        tokenOut = isCoinsEquals(pool.assets[0], tokenOut) ? pool.assets[1] : pool.assets[0];
        return [ ...current, { poolId: pool.id, tokenOutDenom: getFixedDenom(tokenOut) } ];
    }, [] as SwapAmountInRoute[]);
    const tokenInCoin = convertToCoin(coins);
    return { tokenIn: tokenInCoin, routes };
};
