import classNames from 'classnames';
import React, { ReactNode } from 'react';
import CopyableItem from '../../shared/components/copyable-item/copyable-item';
import Link from '../../shared/components/link/link';
import { validateAndGetChildrenAsArray } from '../../shared/utils/react-utils';
import './path-nav.scss';

interface PathNavItemProps {
    label: string;
    url?: string;
    copyable?: boolean;
}

interface PathNavProps {
    children: ReactNode;
    className?: string;
}

export const PathNavItem: React.FC<PathNavItemProps> = ({ label, url, copyable }) => {
    return copyable ?
        <CopyableItem className='path-nav-item disabled' text={label} /> :
        <Link className='path-nav-item' disabled={!url} url={url}>{label}</Link>;
};

const PathNav: React.FC<PathNavProps> = ({ children, className }) => {
    const pathNavItems = validateAndGetChildrenAsArray(children, PathNavItem);

    return (
        <div className={classNames('path-nav', className)}>
            {pathNavItems}
        </div>
    );
};

export default PathNav;
