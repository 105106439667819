import React, { useMemo, useState } from 'react';
import { useAsset } from '../../asset/asset-context';
import { useNetwork } from '../../network/network-context';
import BridgeHistory from '../bridge/history/bridge-history';
import { BridgeHistoryContextProvider } from '../bridge/history/bridge-history-context';
import { IbcTransferContext, IbcTransferContextProvider } from '../ibc-transfer-context';
import IbcTransfer from '../ibc-transfer';
import Dialog, { DialogContent } from '../../../shared/components/dialog/dialog';
import { DepositWithdrawMode } from '../ibc-transfer-types';
import './deposit-withdraw.scss';

interface DepositWithdrawProps {
    dialogOpen: boolean;
    onDialogClose?: () => void;
}

const DepositWithdraw: React.FC<DepositWithdrawProps> = ({ dialogOpen, onDialogClose }) => {
    const { hubNetwork, getNetwork } = useNetwork();
    const { vsAsset } = useAsset();
    const [ depositWithdrawMode, setDepositWithdrawMode ] = useState<DepositWithdrawMode>('Deposit');
    const [ sourceId, setSourceId ] = useState<string>();
    const [ destinationId, setDestinationId ] = useState<string>();

    const optionalNetworks = useMemo(() => (process.env.REACT_APP_DEPOSIT_WITHDRAW_NETWORKS?.split(',') || [])
        .filter((networkId) => getNetwork(networkId)), [ getNetwork ]);

    const sourceNetworks = useMemo(
        () => depositWithdrawMode === 'Deposit' ? optionalNetworks : hubNetwork ? [ hubNetwork.chainId ] : [],
        [ depositWithdrawMode, hubNetwork, optionalNetworks ],
    );

    const destinationNetworks = useMemo(
        () => depositWithdrawMode !== 'Deposit' ? optionalNetworks : hubNetwork ? [ hubNetwork.chainId ] : [],
        [ depositWithdrawMode, hubNetwork, optionalNetworks ],
    );

    const availableBalances = useMemo(() => vsAsset ? [ vsAsset ] : [], [ vsAsset ]);

    return (
        <BridgeHistoryContextProvider>
            <IbcTransferContextProvider
                optionalSourceNetworks={sourceNetworks}
                optionalDestinationNetworks={destinationNetworks}
                initialAsset={vsAsset}
                enable={dialogOpen}
                initialSourceId={sourceId}
                initialDestinationId={destinationId}
                allowBridging
            >
                <IbcTransferContext.Consumer>
                    {(context) => dialogOpen && (
                        <Dialog
                            preventCloseOnEscape
                            closable={!context.bridgeState.broadcasting}
                            className='deposit-withdraw-dialog'
                            onRequestClose={() => !context.bridgeState.broadcasting && onDialogClose?.()}
                        >
                            <DialogContent>
                                <IbcTransfer
                                    availableBalances={availableBalances}
                                    depositWithdrawMode={depositWithdrawMode}
                                    onDepositWithdrawModeChange={(mode) => {
                                        setDestinationId(context.sourceData.network?.chainId);
                                        setSourceId(context.destinationData.network?.chainId);
                                        setDepositWithdrawMode(mode);
                                    }}
                                    historyTab={<BridgeHistory />}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </IbcTransferContext.Consumer>
            </IbcTransferContextProvider>
        </BridgeHistoryContextProvider>
    );
};

export default DepositWithdraw;

