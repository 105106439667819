import { isArray } from 'lodash';
import React, { ReactNode } from 'react';
import Button from '../../../../../../shared/components/button/button';
import TransactionFee from '../../../../../tx/transaction-fee/transaction-fee';
import { Fee } from '../../../../../tx/tx-types';
import './action-section.scss';

interface ActionSectionProps {
    label: string;
    header: ReactNode;
    description?: ReactNode;
    fees?: Fee | Fee[];
    callback: () => void;
    loading?: boolean;
    disabled?: boolean;
    callbackLoading?: boolean;
}

const ActionSection: React.FC<ActionSectionProps> = ({
    callback,
    loading,
    callbackLoading,
    disabled,
    label,
    header,
    description,
    fees,
}) => {
    return (
        <div className='action-section section'>
            <div className='section-header'>
                <div className='section-header-text'>
                    <h5>{header}</h5>
                    <p>{description}</p>
                </div>
                <Button
                    buttonType='primary'
                    loading={callbackLoading}
                    disabled={disabled || loading || callbackLoading}
                    className='action-button'
                    onClick={callback}
                >
                    {label}
                </Button>
            </div>

            {fees && (
                <div className='transaction-fees-container section small'>
                    {(isArray(fees) ? fees : [ fees ]).map((fee, feeIndex) =>
                        <TransactionFee fee={fee} key={feeIndex} loading={loading} />)}
                </div>
            )}
        </div>
    );
};

export default ActionSection;
