import React, { useState } from 'react';
import { useAsset } from '../../asset/asset-context';
import BuySell, { BuySellProps, TradeType } from '../../trade/buy-sell/buy-sell';
import { TradeContextProvider } from '../../trade/trade-context';
import { IroBuySellContext, IroBuySellContextProvider } from './iro-buy-sell-context';
import './iro-buy-sell.scss';

const IroBuySell: React.FC<Omit<BuySellProps, 'tradeType' | 'setTradeType'>> = (props) => {
    const { hubAsset } = useAsset();
    const [ tradeType, setTradeType ] = useState<TradeType>(TradeType.BUY);

    return (
        <IroBuySellContextProvider asset={props.asset} tradeType={tradeType}>
            <IroBuySellContext.Consumer>
                {(context) => (
                    <TradeContextProvider {...context}>
                        <BuySell
                            {...props}
                            className='iro-buy-sell'
                            asset={props.asset}
                            inverseAsset={hubAsset}
                            onTradeTypeChange={setTradeType}
                            canUseInverseAssetInBuy
                            amountShortcuts={tradeType === TradeType.BUY && context.useInverseAsset ? [ 1, 5, 10, 100 ] : undefined}
                        />
                    </TradeContextProvider>
                )}
            </IroBuySellContext.Consumer>
        </IroBuySellContextProvider>
    );
};

export default IroBuySell;
