import React, { ReactElement, useEffect, useRef } from 'react';
import Addresses from '../../../shared/components/addresses/addresses';
import Badge from '../../../shared/components/badge/badge';
import Spinner from '../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { getShortDateTimeString } from '../../../shared/utils/date-utils';
import { formatPrice } from '../../../shared/utils/number-utils';
import { Asset } from '../../asset/asset-types';
import { convertToHexAddress } from '../../wallet/wallet-service';
import { Trade } from '../types';
import { useTradeList } from './use-trade-list';
import './trade-list.scss';

interface TradeListProps {
    asset: Asset;
}

const TradeList: React.FC<TradeListProps> = ({ asset }) => {
    const { trades, loading, vsAsset, loadMore } = useTradeList(asset);
    const containerRef = useRef<HTMLDivElement>(null);
    const { canLoadMore } = useScrollPosition(containerRef.current || undefined, 0.05);

    useEffect(() => {
        if (containerRef.current && canLoadMore) {
            loadMore();
        }
    }, [ loadMore, canLoadMore ]);

    const renderAddressColumn = (trade: Trade): ReactElement => {
        return (
            <TableColumn>
                <Addresses addresses={[ convertToHexAddress(trade.address), trade.address ]} canCopy />
                {trade.address === asset.network.owner && (
                    <Badge
                        label='F'
                        info='Founder'
                        size='small'
                        rounded
                        className='address-badge'
                        color={getCssVariableValue('--light-blue-rgb').split(',').map(Number)}
                    />
                )}
            </TableColumn>
        );
    };

    const renderAmountColumn = (trade: Trade): ReactElement => {
        const amount = trade.tokenInNetwork === asset.networkId ? trade.tokenIn.amount : trade.tokenOut.amount;
        return <TableColumn>{formatPrice(amount, '')}</TableColumn>;
    };

    const renderVsAmountColumn = (trade: Trade): ReactElement => {
        const amount = trade.tokenInNetwork === asset.networkId ? trade.tokenOut.amount : trade.tokenIn.amount;
        return <TableColumn>{formatPrice(amount, '')}</TableColumn>;
    };

    const renderVolumeColumn = (trade: Trade): ReactElement => {
        return <TableColumn>{formatPrice(trade.usdVolume)}</TableColumn>;
    };

    const renderPriceColumn = (trade: Trade): ReactElement => {
        const isSell = trade.tokenInNetwork === asset.networkId;
        const price = isSell ? trade.tokenOut.amount / trade.tokenIn.amount : trade.tokenIn.amount / trade.tokenOut.amount;
        return <TableColumn>{formatPrice(price, '')}</TableColumn>;
    };

    const renderPriceUsdColumn = (trade: Trade): ReactElement => {
        const isSell = trade.tokenInNetwork === asset.networkId;
        const usdPrice = trade.usdVolume / (isSell ? trade.tokenIn.amount : trade.tokenOut.amount);
        return <TableColumn>{formatPrice(usdPrice)}</TableColumn>;
    };

    const renderTypeColumn = (trade: Trade): ReactElement => {
        const isBuy = trade.tokenOutNetwork === asset.networkId;

        return (
            <TableColumn>
                <Badge
                    label={isBuy ? 'Buy' : 'Sell'}
                    size='small'
                    rounded
                    color={getCssVariableValue(isBuy ? '--light-green-rgb' : '--red-rgb').split(',').map(Number)}
                />
            </TableColumn>
        );
    };

    const renderTimeColumn = (trade: Trade): ReactElement => {
        return <TableColumn align='right' contentClassName='no-wrap'>{getShortDateTimeString(trade.time)}</TableColumn>;
    };

    const renderTradeHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Address</TableColumn>
                <TableColumn>{asset.currency.displayDenom}</TableColumn>
                <TableColumn>{vsAsset?.currency.displayDenom}</TableColumn>
                <TableColumn>Price</TableColumn>
                <TableColumn>Price ({vsAsset?.currency.displayDenom})</TableColumn>
                <TableColumn>Volume</TableColumn>
                <TableColumn>Type</TableColumn>
                <TableColumn align='right'>Time</TableColumn>
            </TableRow>
        );
    };

    const renderTradeRow = (trade: Trade): ReactElement => {
        return (
            <TableRow key={trade.id} className='trade-row'>
                {renderAddressColumn(trade)}
                {renderAmountColumn(trade)}
                {renderVsAmountColumn(trade)}
                {renderPriceUsdColumn(trade)}
                {renderPriceColumn(trade)}
                {renderVolumeColumn(trade)}
                {renderTypeColumn(trade)}
                {renderTimeColumn(trade)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!trades?.length) {
            return <div className='no-data'>No Trades Yet</div>;
        }
    };

    return (
        <div className='trade-list-container' ref={containerRef}>
            <Table className='trade-list' headerSticky bottomBar={renderBottomBar()}>
                {renderTradeHeaderRow()}
                {trades?.map(renderTradeRow)}
            </Table>
        </div>
    );
};

export default TradeList;
