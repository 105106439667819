import { Decimal } from 'cosmjs/packages/math';
import { MsgBuyEncodeObject, MsgSellEncodeObject } from 'cosmjs/packages/stargate';
import { MsgBuyExactSpendEncodeObject } from 'cosmjs/packages/stargate/build/modules';
import { Plan } from '../../client/station-clients/dymension/generated/iro/iro';
import { CoinsAmount } from '../../currency/currency-types';

export const createIroBuyMessage = (
    buyer: string,
    plan: Plan,
    coins: CoinsAmount,
    inverseCoins: CoinsAmount,
): MsgBuyEncodeObject => {
    let amount = BigInt(Decimal.fromUserInput(coins.amount.toFixed(coins.currency.decimals), coins.currency.decimals).atomics);
    const amountToBuy = BigInt(plan.totalAllocation?.amount || '0') - BigInt(plan.soldAmt);
    if (amountToBuy < amount) {
        amount = amountToBuy;
    }
    const maxCostAmount = Decimal.fromUserInput(
        inverseCoins.amount.toFixed(inverseCoins.currency.decimals), inverseCoins.currency.decimals).atomics;
    return {
        typeUrl: '/dymensionxyz.dymension.iro.MsgBuy',
        value: { buyer, amount: amount.toString(), maxCostAmount, planId: plan.id.toString() },
    };
};

export const createIroBuyExactSpendMessage = (
    buyer: string,
    plan: Plan,
    coins: CoinsAmount,
    inverseCoins: CoinsAmount,
): MsgBuyExactSpendEncodeObject => {
    let amount = BigInt(Decimal.fromUserInput(coins.amount.toFixed(coins.currency.decimals), coins.currency.decimals).atomics);
    const amountToBuy = BigInt(plan.totalAllocation?.amount || '0') - BigInt(plan.soldAmt);
    if (amountToBuy < amount) {
        amount = amountToBuy;
    }
    const spend = Decimal.fromUserInput(
        inverseCoins.amount.toFixed(inverseCoins.currency.decimals), inverseCoins.currency.decimals).atomics;
    amount = amount <= BigInt(0) ? BigInt(1) : amount;
    return {
        typeUrl: '/dymensionxyz.dymension.iro.MsgBuyExactSpend',
        value: { buyer, planId: plan.id.toString(), spend, minOutTokensAmount: amount.toString() },
    };
};

export const createIroSellMessage = (
    seller: string,
    plan: Plan,
    coins: CoinsAmount,
    inverseCoins: CoinsAmount,
): MsgSellEncodeObject => {
    let amount = BigInt(Math.round(coins.amount * (10 ** coins.currency.decimals)));
    if (coins.baseAmount !== undefined && coins.baseAmount < amount) {
        amount = coins.baseAmount;
    }
    let minIncomeAmount = BigInt(Math.round(inverseCoins.amount * (10 ** inverseCoins.currency.decimals)));
    minIncomeAmount = minIncomeAmount <= BigInt(0) ? BigInt(1) : minIncomeAmount;
    return {
        typeUrl: '/dymensionxyz.dymension.iro.MsgSell',
        value: { seller, amount: amount.toString(), minIncomeAmount: minIncomeAmount.toString(), planId: plan.id.toString() },
    };
};


