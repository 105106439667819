import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pool } from '../../../amm/types';
import { getCurrencyLogoPath, getMainCurrency } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { Network } from '../../../network/network-types';
import { SponsorshipRecord } from '../../sponsorship-types';
import './sponsorship-name.scss';

interface SponsorshipNameProps {
    sponsorship: SponsorshipRecord;
}

const SponsorshipRollappName: React.FC<{ rollapp: Network }> = ({ rollapp }) => {
    const navigate = useNavigate();
    const rollappCurrency = useMemo(() => getMainCurrency(rollapp), [ rollapp ]);

    return (
        <>
            {<img className='sponsorship-item-logo rollapp' src={getNetworkLogoPath(rollapp)} alt='rollapp logo' />}
            <div className='sponsorship-item-title'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className='item-name ellipsis' onClick={() => navigate(`/rollapps/${rollapp.chainId}`)}>{rollapp.chainName}</a>
                <div className='item-subtitle'>{rollappCurrency?.displayDenom}</div>
            </div>
        </>
    );
};

const SponsorshipPoolName: React.FC<{ pool: Pool }> = ({ pool }) => {
    const navigate = useNavigate();
    const { getNetwork } = useNetwork();

    const assetsNetworks = useMemo(
        () => pool ? pool.assets.map((asset) => getNetwork(asset.networkId)) : [],
        [ getNetwork, pool ],
    );

    const renderAssetLogo = (asset: CoinsAmount, assetNetwork?: Network): ReactElement | undefined => {
        return assetNetwork &&
            <img className='sponsorship-item-logo' src={getCurrencyLogoPath(asset.currency, assetNetwork)} alt='currency logo' />;
    };

    return (
        <>
            {renderAssetLogo(pool.assets[0], assetsNetworks[0])}
            {renderAssetLogo(pool.assets[1], assetsNetworks[1])}
            <span className='sponsorship-item-title'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    className='item-name ellipsis'
                    onClick={() => navigate(`/amm/pool/${pool.id}`)}
                >
                    {pool.assets[0].currency.displayDenom} / {pool.assets[1].currency.displayDenom}
                </a>
                <span className='item-subtitle'>Pool #{pool.id.toString().padStart(3, '0')}</span>
            </span>
        </>
    );
};

const SponsorshipName: React.FC<SponsorshipNameProps> = ({ sponsorship }) => {
    if (sponsorship.rollapp) {
        return <SponsorshipRollappName rollapp={sponsorship.rollapp} />;
    } else if (sponsorship.pool) {
        return <SponsorshipPoolName pool={sponsorship.pool} />;
    }
    return <></>;
};

export default SponsorshipName;
