import React, { useMemo } from 'react';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { getMaxDenomAmount } from '../../../../currency/currency-service';
import { useNetwork } from '../../../../network/network-context';

const HubBurnProperty: React.FC = () => {
    const { hubNetwork, hubCurrency } = useNetwork();

    const currentValue = useMemo(
        () => (hubNetwork?.tokenBurns?.value || 0) - (hubNetwork?.tokenBurns?.previousDayValue || 0),
        [ hubNetwork?.tokenBurns?.previousDayValue, hubNetwork?.tokenBurns?.value ],
    );

    const previousValue = useMemo(
        () => (hubNetwork?.tokenBurns?.previousDayValue || 0) - (hubNetwork?.tokenBurns?.previousTwoDaysValue || 0),
        [ hubNetwork?.tokenBurns?.previousDayValue, hubNetwork?.tokenBurns?.previousTwoDaysValue ],
    );

    return (
        <div className='statistics-property'>
            <span className='property-label'>{hubCurrency?.displayDenom} Burn 🔥 (24h):</span>
            <StatisticsChange
                period='day'
                size='small'
                currentValue={currentValue}
                previousValue={previousValue}
            >
                {formatPrice(getMaxDenomAmount(currentValue, hubCurrency), hubCurrency?.displayDenom)}
            </StatisticsChange>
        </div>
    );
};

export default HubBurnProperty;
