import { GasPrice, SigningStargateClient } from 'cosmjs/packages/stargate';
import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { Network } from '../../network/network-types';
import { WalletError } from '../../wallet/wallet-error';
import { DEFAULT_GAS_PRICE_STEPS } from '../client-types';
import { ClientError } from '../client-error';
import { Wallet } from '../../wallet/wallet-types';
import { StationClient } from './station-client';
import { getFeeCurrency } from '../../currency/currency-service';

export class SigningStationClient extends SigningStargateClient {
    private readonly averageGasPrice: GasPrice;
    private readonly stationQueryClient: StationClient;
    private readonly wallet: Wallet;
    private readonly activeSigner: OfflineSigner;
    private quickAuthClient?: SigningStationClient;

    public static async connectForQueryClient(
        stationQueryClient: StationClient,
        wallet: Wallet,
        activeSigner?: OfflineSigner,
    ): Promise<SigningStationClient> {
        const network = stationQueryClient.getNetwork();
        if (!network.rpc) {
            throw new ClientError('CONNECT_CLIENT_FAILED', network, 'missing rpc');
        }
        try {
            const signer = activeSigner || await wallet.getOfflineSigner(network);
            const feeCurrency = getFeeCurrency(network);
            const gasPriceStep = network.gasPriceSteps?.average ?? DEFAULT_GAS_PRICE_STEPS.average;
            const gasPrice = GasPrice.fromString(network.gasPrice || `${gasPriceStep}${feeCurrency.baseDenom}`);
            return new SigningStationClient(stationQueryClient, wallet, signer, gasPrice, Boolean(activeSigner));
        } catch (error) {
            if (error instanceof WalletError) {
                throw error;
            }
            throw new ClientError('CONNECT_CLIENT_FAILED', network, error);
        }
    }

    public async setQuickAuthSigner(signer?: OfflineSigner): Promise<void> {
        if (signer) {
            this.quickAuthClient = await SigningStationClient.connectForQueryClient(this.stationQueryClient, this.wallet, signer);
        } else {
            this.quickAuthClient = undefined;
        }
    }

    public getQuickAuthClient(): SigningStationClient | undefined {
        return this.quickAuthClient;
    }

    public getNetwork(): Network {
        return this.stationQueryClient.getNetwork();
    }

    public getStationQueryClient(): StationClient {
        return this.stationQueryClient;
    }

    public getWallet(): Wallet {
        return this.wallet;
    }

    public getActiveSigner(): OfflineSigner {
        return this.activeSigner;
    }

    public getGasPrice(): GasPrice {
        return this.averageGasPrice;
    }

    protected constructor(
        queryClient: StationClient,
        wallet: Wallet,
        activeSigner: OfflineSigner,
        gasPrice: GasPrice,
        noEvm?: boolean,
    ) {
        super(
            queryClient.getTmClient(),
            activeSigner,
            {
                gasPrice,
                evm: !noEvm && wallet.getWalletType() !== 'PortalWallet' && (Boolean(queryClient.getNetwork().evm ||
                    queryClient.getNetwork().type === 'RollApp')),
                broadcastTimeoutMs: 600_000,
            },
        );
        this.stationQueryClient = queryClient;
        this.wallet = wallet;
        this.activeSigner = activeSigner;
        this.averageGasPrice = gasPrice;
    }
}
