import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Decimal } from 'cosmjs/packages/math';
import { MsgClaimEncodeObject } from 'cosmjs/packages/stargate';
import { Point } from 'recharts/types/shape/Curve';
import { convertIntToDecimal, roundNumber } from '../../shared/utils/number-utils';
import { BondingCurve as IroBondingCurve, Plan } from '../client/station-clients/dymension/generated/iro/iro';
import { getMainCurrency, getMaxDenomAmount } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { Network } from '../network/network-types';
import { CurveType } from '../rollapp/manage-rollapps-page/create-rollapp-page/types';
import { BondingCurve } from './types';

export const IRO_DENOM_PREFIX = 'IRO/';
export const BUY_EXACT_SPEND_CALCULATE_ITERATIONS = 100;
export const BUY_EXACT_SPEND_CALCULATE_EPSILON = 1 / (10 ** 10);

export const isIroDenom = (denom: string) => denom.startsWith(IRO_DENOM_PREFIX);

export const createIroDenom = (rollappId: string) => IRO_DENOM_PREFIX + rollappId;

export const convertIroToCoinsAmount = (coin: Coin, networks: Network[]): CoinsAmount | null => {
    const networkId = coin.denom.replace(IRO_DENOM_PREFIX, '');
    const rollapp = networks.find((network) => network.chainId === networkId);
    if (!rollapp) {
        return null;
    }
    const currency = getMainCurrency(rollapp);
    const amount = getMaxDenomAmount(Number(coin.amount) || 0, currency);
    try {
        return { amount, currency, networkId, iroDenom: coin.denom, baseAmount: BigInt(coin.amount) };
    } catch {
        return { amount, currency, networkId, iroDenom: coin.denom, baseAmount: BigInt(Math.round(Number(coin.amount))) };
    }
};

export const getPrice = (plan: Plan, amount: number): number => {
    if (!plan.bondingCurve) {
        return 0;
    }
    const { N, M, C } = convertToBondingCurve(plan.bondingCurve);
    const soldAmount = Number(plan.soldAmt) / (10 ** 18);
    const xPowN = !amount ? 0 : Math.pow(soldAmount, N);
    return amount * (M * xPowN + C);
};

export const getBuyCost = (plan: Plan, coins: CoinsAmount, tradeFactor: number): number => {
    if (!plan.bondingCurve) {
        return 0;
    }
    const soldAmount = Number(plan.soldAmt) / (10 ** 18);
    return getBuyCostFormBondingCurve(plan.bondingCurve, soldAmount, coins.amount, tradeFactor);
};

export const getBuyCostFormBondingCurve = (
    bondingCurve: IroBondingCurve,
    soldAmount: number,
    amount: number,
    tradeFactor = 0,
): number => {
    const newSupply = soldAmount + amount;
    const integralNewSupply = calculateIntegral(bondingCurve, newSupply);
    const integralCurrentSupply = calculateIntegral(bondingCurve, soldAmount);
    return roundNumber((integralNewSupply - integralCurrentSupply) * (1 + tradeFactor), 18, true);
};

export const getBuyExactSpendCost = (plan: Plan, spendCoins: CoinsAmount, tradeFactor: number): number => {
    if (!plan.bondingCurve) {
        return 0;
    }
    const currentSupply = Number(plan.soldAmt) / (10 ** 18);
    if (currentSupply < 1) {
        return 0;
    }
    let currentValue = spendCoins.amount;
    for (let i = 0; i < BUY_EXACT_SPEND_CALCULATE_ITERATIONS; i++) {
        const newSupply = currentSupply + currentValue;
        const integralNewSupply = calculateIntegral(plan.bondingCurve, newSupply);
        const integralCurrentSupply = calculateIntegral(plan.bondingCurve, currentSupply);
        const diff = integralNewSupply - integralCurrentSupply - spendCoins.amount;
        if (Math.abs(diff) < BUY_EXACT_SPEND_CALCULATE_EPSILON) {
            return roundNumber(currentValue / (1 + tradeFactor), 18, undefined, true);
        }
        const currentPlan = { ...plan, soldAmt: BigInt(newSupply * (10 ** 18)).toString() };
        const currentPrice = getPrice(currentPlan, 1);
        if (!currentPrice) {
            return 0;
        }
        currentValue = currentValue - (diff / currentPrice);
        if (Math.abs(diff / currentPrice) < BUY_EXACT_SPEND_CALCULATE_EPSILON * Math.abs(currentValue)) {
            return roundNumber(currentValue / (1 + tradeFactor), 18, undefined, true);
        }
        if (currentSupply + currentValue < 1) {
            currentValue = 1;
        }
    }
    return 0;
};

export const getSellCost = (plan: Plan, coins: CoinsAmount, tradeFactor: number): number => {
    if (!plan.bondingCurve) {
        return 0;
    }
    const currentSupply = Number(plan.soldAmt) / (10 ** 18);
    const newSupply = currentSupply - coins.amount;
    const integralCurrentSupply = calculateIntegral(plan.bondingCurve, currentSupply);
    const integralNewSupply = calculateIntegral(plan.bondingCurve, newSupply);
    return roundNumber((integralCurrentSupply - integralNewSupply) * (1 - tradeFactor), 18, true);
};

const calculateIntegral = (curve: IroBondingCurve, amount: number): number => {
    const { N, M, C } = convertToBondingCurve(curve);
    const xPowNplusOne = amount < 1 ? 0 : Math.pow(amount, N + 1);
    const mDivNPlusOne = M / (N + 1);
    return mDivNPlusOne * xPowNplusOne + (C * amount);
};

export const createClaimIroMessage = (planId: string, claimer: string): MsgClaimEncodeObject => {
    return { typeUrl: '/dymensionxyz.dymension.iro.MsgClaim', value: { planId, claimer } };
};

export const convertToBondingCurve = (curve: IroBondingCurve): BondingCurve => ({
    M: Decimal.fromAtomics(curve.M, 18).toFloatApproximation(),
    N: Decimal.fromAtomics(curve.N, 18).toFloatApproximation(),
    C: Decimal.fromAtomics(curve.C, 18).toFloatApproximation(),
});

export const convertFromBondingCurve = (curve: BondingCurve): IroBondingCurve => ({
    M: BigInt(Math.round(convertIntToDecimal(curve.M || 0))).toString(),
    N: BigInt(Math.round(convertIntToDecimal(curve.N || 1))).toString(),
    C: BigInt(Math.round(convertIntToDecimal(curve.C || 0))).toString(),
});

export const getCurveType = (curve: BondingCurve): CurveType =>
    curve.N > 1 ? 'Exponential' : curve.N < 1 ? 'Logarithmic' : curve.M === 0 ? 'Fixed' : 'Linear';

export const fetchPlanTargetRaise = (plan: Plan, part = 100): number => {
    if (!plan.bondingCurve || !plan.totalAllocation) {
        return 0;
    }
    const curve = convertToBondingCurve(plan.bondingCurve);
    const allocation = Decimal.fromAtomics(plan.totalAllocation.amount, 18).toFloatApproximation() * part / 100;
    return curve.M === 0 ? curve.C * allocation : curve.M * (allocation ** (curve.N + 1)) / (curve.N + 1);
};

export const calculateBondingCurvePoints = (curve: BondingCurve, allocation: number, pointsCount = 101): Point[] => {
    return Array.from({ length: pointsCount }).map((point, pointIndex) => {
        const x = (pointIndex / (pointsCount - 1)) * allocation;
        const y = curve.M * Math.pow(x, curve.N) + curve.C;
        return { x, y };
    });
};
