import { Delegation, Reward, StakingParams } from '../staking-types';

export interface StakingDataState {
    delegations?: Delegation[];
    rewards?: Reward[]
    delegationsLoading?: boolean;
    rewardsLoading?: boolean;
    stakeParams?: StakingParams,
    stakeParamsLoading?: boolean,
    error?: any;
}

export type StakingDataAction =
    { type: 'set-delegations', payload: Delegation[] | undefined } |
    { type: 'set-rewards', payload: Reward[] | undefined } |
    { type: 'set-delegations-loading', payload?: boolean } |
    { type: 'set-rewards-loading', payload?: boolean } |
    { type: 'set-params', payload: StakingParams | undefined } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-error', payload: any } |
    { type: 'clear-data', payload?: boolean }

export const STAKING_DATA_DEFAULTS: StakingDataState = {
    delegations: undefined,
    rewards: undefined,
    stakeParams: undefined,
    delegationsLoading: false,
    rewardsLoading: false,
    stakeParamsLoading: false,
};

export const stakingDataReducer = (state: StakingDataState, action: StakingDataAction): StakingDataState => {
    switch (action.type) {
        case 'set-delegations':
            return { ...state, delegations: action.payload, delegationsLoading: false };
        case 'set-rewards':
            return { ...state, rewards: action.payload, rewardsLoading: false };
        case 'set-params':
            return { ...state, stakeParams: action.payload, stakeParamsLoading: false };
        case 'set-delegations-loading':
            return { ...state, delegationsLoading: action.payload ?? true };
        case 'set-rewards-loading':
            return { ...state, rewardsLoading: action.payload ?? true };
        case 'set-params-loading':
            return { ...state, stakeParamsLoading: action.payload ?? true };
        case 'set-error':
            return { ...state, error: action.payload };
        case 'clear-data':
            return {
                ...state,
                ...STAKING_DATA_DEFAULTS,
                stakeParamsLoading: action.payload || false,
                rewardsLoading: action.payload || false,
                delegationsLoading: action.payload || false
            };
        default:
            return state;
    }
};
