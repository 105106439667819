import { useCallback } from 'react';
import { AccountNetworkState } from './account-network-state';
import { useSnackbar } from '../../shared/components/snackbar/snackbar-context';

export const useNetworkAddress = (): {
    getDisplayedAddress: (networkState: AccountNetworkState) => string,
    copyAddress: (address?: string, showCopiedNotification?: boolean) => void
} => {
    const { showErrorMessage, showSuccessMessage } = useSnackbar();

    const getDisplayedAddress = useCallback((networkState: AccountNetworkState): string => {
        return (networkState.network?.evm || networkState.network?.type === 'RollApp' ?
            networkState.hexAddress : networkState.address) || '';
    }, []);

    const copyAddress = useCallback((address?: string, showCopiedNotification?: boolean): void => {
        if (!address) {
            return;
        }
        navigator.clipboard.writeText(address)
            .then(() => showCopiedNotification &&
                showSuccessMessage({ content: 'Copied address to clipboard', key: address, duration: 3000, closeAction: false }))
            .catch((error) => {
                console.error(error);
                showErrorMessage({ content: 'Copied address to clipboard failed, please try again later', duration: 5000 });
            });
    }, [ showErrorMessage, showSuccessMessage ]);

    return { getDisplayedAddress, copyAddress };
};


