import classNames from 'classnames';
import React from 'react';
import { WalletType } from '../wallet-types';
import { ReactComponent as KeplrLogo } from '../../../assets/logos/wallets/keplr-logo.svg';
import { ReactComponent as RabbyLogo } from '../../../assets/logos/wallets/rabby-logo.svg';
import { ReactComponent as MetaMaskLogo } from '../../../assets/logos/wallets/meta-mask-logo.svg';
import { ReactComponent as LeapLogo } from '../../../assets/logos/wallets/leap-logo.svg';
import { ReactComponent as PhantomLogo } from '../../../assets/logos/wallets/phantom-logo.svg';
import { ReactComponent as ThunderIcon } from '../../../assets/icons/thunder.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import Icon from '../../../shared/components/icon/icon';
import './wallet-logo.scss';

interface WalletLogoProps {
    type?: WalletType;
    className?: string;
}

const WalletLogo: React.FC<WalletLogoProps> = ({ type, className }) => {
    let logo = null;
    if (type === 'Keplr') {
        logo = <KeplrLogo />;
    } else if (type === 'MetaMask') {
        logo = <MetaMaskLogo />;
    } else if (type === 'Rabby') {
        logo = <RabbyLogo />;
    }  else if (type === 'Leap') {
        logo = <LeapLogo />;
    } else if (type === 'Phantom') {
        logo = <PhantomLogo />;
    } else if (type === 'Quick Auth') {
        logo = <ThunderIcon />;
    }
    if (logo) {
        return (
            <Icon
                className={classNames(className, { 'quick-auth-logo': type === 'Quick Auth' })}
                iconColorMode={type !== 'Quick Auth' ? 'original' : undefined}
            >
                {logo}
            </Icon>
        );
    }
    return className ? <Icon className={className}><WalletIcon /></Icon> : <WalletIcon />;
};

export default WalletLogo;

