import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { useClient } from '../client/client-context';
import { Gauge } from '../client/station-clients/dymension/generated/incentives/gauge';
import { Stream } from '../client/station-clients/dymension/generated/streamer/stream';
import { useNetwork } from '../network/network-context';
import { INCENTIVES_STATE_DEFAULT, incentivesReducer, IncentivesState } from './incentives-state';
import { loadIncentives, loadIncentivesParams } from './incentives.service';
import { Incentive, IncentivesParams } from './types';

interface IncentivesContextValue {
    incentivesState: IncentivesState;
}

export const IncentivesContext = createContext<IncentivesContextValue>({} as IncentivesContextValue);

export const useIncentives = (): IncentivesContextValue => useContext(IncentivesContext);

export const IncentivesContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { hubNetwork } = useNetwork();
    const { clientStateMap, handleClientError } = useClient();
    const [ incentivesState, incentivesDispatch ] = useReducer(incentivesReducer, INCENTIVES_STATE_DEFAULT);
    const cancelAndSetParamsPromise = useCancelablePromise<IncentivesParams>();
    const cancelAndSetIncentivesPromise = useCancelablePromise<{ gauges: Gauge[], streams: Stream[], incentives: { [denom: string]: Incentive[] } }>();

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            incentivesDispatch({ type: 'set-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        incentivesDispatch({ type: 'set-params-loading' });
        cancelAndSetParamsPromise((signal) => loadIncentivesParams(clientState.client!, signal))
            .then((params) => incentivesDispatch({ type: 'set-params', payload: params }))
            .catch((error) => {
                incentivesDispatch({ type: 'set-params-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetParamsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            incentivesDispatch({ type: 'set-loading', payload: false });
            return;
        }
        if (!incentivesState.params || incentivesState.incentives || !clientState?.client || clientState?.connecting) {
            return;
        }
        incentivesDispatch({ type: 'set-loading' });
        cancelAndSetIncentivesPromise((signal) => loadIncentives(clientState.client!, incentivesState.params!, signal))
            .then(({ gauges, streams, incentives }) => {
                incentivesDispatch({ type: 'set-incentives', payload: incentives });
                incentivesDispatch({ type: 'set-gauges', payload: gauges });
                incentivesDispatch({ type: 'set-streams', payload: streams });
            })
            .catch((error) => {
                incentivesDispatch({ type: 'set-loading', payload: false });
                handleClientError(error);
            });
    }, [ incentivesState.incentives, incentivesState.params, cancelAndSetIncentivesPromise, clientState, handleClientError ]);

    return <IncentivesContext.Provider value={{ incentivesState }}>{children}</IncentivesContext.Provider>;
};
