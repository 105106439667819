import classNames from 'classnames';
import React, { useCallback } from 'react';
import Badge from '../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { useDymns } from '../../dymns/dymns-context';
import { getNetworkLogoPath } from '../../network/network-service';
import { Network } from '../../network/network-types';
import './common-network-badge.scss';

interface CommonNetworkBadgeProps {
    className?: string;
    network: Network;
    showNetworkDomain?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const CommonNetworkBadge: React.FC<CommonNetworkBadgeProps> = ({ className, network, disabled, onClick, showNetworkDomain, selected }) => {
    const { dymnsState } = useDymns();

    const getNetworkDomain = useCallback((network: Network) => {
        const alias = dymnsState.aliasesMap[network.chainId]?.aliases?.[0];
        return alias ? `@${alias}` : network.chainId;
    }, [ dymnsState.aliasesMap ]);

    return (
        <Badge
            size='large'
            color={getCssVariableValue('--cream-rgb').split(',').map(Number)}
            className={classNames('common-network', className, { selected })}
            onClick={onClick}
            disabled={disabled}
            label={<>
                {network && <img className='network-logo' src={getNetworkLogoPath(network)} alt='network logo' />}
                <span className='network-name-container'>
                    {network.chainName}
                    {showNetworkDomain && <span className='network-domain'>{getNetworkDomain(network)}</span>}
                </span>
            </>}
        />
    );
};

export default CommonNetworkBadge;
