import React, { useMemo } from 'react';
import { getCurrencyLogoPath } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { useNetwork } from '../network/network-context';

interface AssetLogoProps {
    asset: CoinsAmount;
    className?: string;
}

export const AssetLogo: React.FC<AssetLogoProps> = ({ asset, className }) => {
    const { getNetwork } = useNetwork();

    const logoPath = useMemo(() => {
        const currencyNetwork = getNetwork(asset.networkId);
        return currencyNetwork ? getCurrencyLogoPath(asset.currency, currencyNetwork) : undefined;
    }, [ asset.currency, asset.networkId, getNetwork ]);


    return logoPath ? <img className={className} src={logoPath} alt='currency logo' /> : <></>;
};

export default AssetLogo;
