import { ChangeEvent, DragEvent, useCallback, useEffect, useState } from 'react';
import { resizeImage } from '../../utils/file-utils';

export interface UseFileUploaderProps {
    onFileSelect?: (file: File, imageFileContent?: string) => void;
    acceptImages?: boolean;
    maxWidth?: number;
    maxHeight?: number;
    initialFile?: File;
    disabled?: boolean;
}

interface UseFileUploaderValue {
    file?: File;
    imageContent?: string;
    dragged: boolean;
    handleBrowseFile: (event: ChangeEvent<HTMLInputElement>) => void;
    handleDrop: (event: DragEvent<HTMLDivElement>) => void;
    handleDragOver: (event: DragEvent<HTMLDivElement>) => void;
    handleDragLeave: (event: DragEvent<HTMLDivElement>) => void;
    clear: () => void;
}

export const useFileUploader = ({
    initialFile,
    onFileSelect,
    acceptImages,
    maxHeight,
    maxWidth,
    disabled,
}: UseFileUploaderProps): UseFileUploaderValue => {
    const [ file, setFile ] = useState<File | undefined>();
    const [ imageContent, setImageContent ] = useState<string | undefined>();
    const [ dragged, setDragged ] = useState(false);

    const handleFile = useCallback(async (selectedFile: File) => {
        if (acceptImages && (maxWidth || maxHeight)) {
            selectedFile = await resizeImage(selectedFile, maxWidth, maxHeight);
        }
        setFile(selectedFile);
        if (acceptImages && selectedFile.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const imageContent = event.target?.result as string;
                setImageContent(imageContent);
                onFileSelect?.(selectedFile, imageContent);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            onFileSelect?.(selectedFile);
        }
    }, [ acceptImages, maxHeight, maxWidth, onFileSelect ]);

    useEffect(() => {
        if (!file && initialFile) {
            setFile(initialFile);
            const reader = new FileReader();
            reader.onload = (event) => {
                const imageContent = event.target?.result as string;
                setImageContent(imageContent);
            };
            reader.readAsDataURL(initialFile);
        }
    }, [ file, initialFile ]);

    const clear = useCallback((): void => {
        setFile(undefined);
        setImageContent(undefined);
    }, []);

    const handleBrowseFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!disabled && event.target.files && event.target.files.length > 0) {
            handleFile(event.target.files[0]).then();
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!disabled) {
            setDragged(true);
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!disabled && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            handleFile(event.dataTransfer.files[0]).then(() => event.dataTransfer.clearData());
        }
        setDragged(false);
    };

    const handleDragLeave = () => setDragged(false);

    return { file, imageContent, dragged, clear, handleBrowseFile, handleDragOver, handleDrop, handleDragLeave };
};
