import React, { ReactNode, useMemo } from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Icon from '../../../../shared/components/icon/icon';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { useNetwork } from '../../../network/network-context';
import { ReactComponent as CCTPLogo } from '../../../../assets/logos/cctp-logo.svg';
import { ReactComponent as IBCLogo } from '../../../../assets/logos/ibc-logo.svg';
import { getNetworkLogoPath } from '../../../network/network-service';
import { useIbcTransfer } from '../../ibc-transfer-context';
import './bridge-confirmation-dialog.scss';

const BridgeConfirmationDialog: React.FC<DialogProps> = (dialogProps) => {
    const { getNetwork } = useNetwork();
    const { sourceData, destinationData, bridgeState, transfer } = useIbcTransfer();
    const { isMobile } = useWindowSize();

    const cctpNetwork = useMemo(() => getNetwork(process.env.REACT_APP_DEFAULT_CCTP_NETWORK), [ getNetwork ]);

    const renderCctpTransferName = (): ReactNode => {
        return <><Icon iconColorMode='original' className='transfer-logo'><CCTPLogo /></Icon>{!isMobile && 'CCTP'}</>;
    };

    const renderIbcTransferName = (): ReactNode => {
        return <><Icon className='transfer-logo'><IBCLogo /></Icon>{!isMobile && 'Transfer'}</>;
    };

    return (
        <Dialog className='transfer-confirmation-dialog' {...dialogProps}>
            <DialogTitle>Transfer Confirmation</DialogTitle>
            <DialogContent className='dialog-content'>
                This transaction uses <b>Circle CCTP</b> to route native USDC through <b>{cctpNetwork?.chainName}</b> to <b>{destinationData.network?.chainName}</b>.
                CCTP requires 2-20 Min processing time to complete.
                <p className='signatures-label'>
                    {bridgeState.intermediateNetwork ? '2 signatures required' : 'One transaction is required.'}
                </p>

                <div className='networks-route'>
                    {sourceData.network && <img className='network-logo' src={getNetworkLogoPath(sourceData.network)} alt='network logo' />}

                    <span className='transfer-line'>
                        <span className='transfer-name'>
                            {bridgeState.intermediateNetwork ? renderIbcTransferName() : renderCctpTransferName()}
                        </span>
                    </span>

                    {cctpNetwork && <img className='network-logo' src={getNetworkLogoPath(cctpNetwork)} alt='network logo' />}

                    <span className='transfer-line'>
                        <span className='transfer-name'>
                            {bridgeState.intermediateNetwork ? renderCctpTransferName() : renderIbcTransferName()}
                        </span>
                    </span>

                    {destinationData.network &&
                        <img className='network-logo' src={getNetworkLogoPath(destinationData.network)} alt='network logo' />}
                </div>
            </DialogContent>
            <DialogAction close secondary onClick={dialogProps.onRequestClose}>Cancel</DialogAction>
            <DialogAction close primary onClick={transfer}>Confirm</DialogAction>
        </Dialog>
    );
};

export default BridgeConfirmationDialog;
