import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { useClient } from '../client/client-context';
import { Network } from '../network/network-types';
import { loadGovernanceParams, loadProposals } from './governance-service';
import { GOVERNANCE_STATE_DEFAULT, governanceReducer, GovernanceState } from './governance-state';
import { GovernanceParams, Proposal, ProposalStatus } from './governance-types';

interface GovernanceContextProps {
    network: Network;
    children: ReactNode;
}

interface GovernanceContextValue {
    network: Network;
    governanceState: GovernanceState;
    setSearchText: (searchText: string) => void;
    setStatusFilter: (status: ProposalStatus) => void;
}

export const GovernanceContext = createContext<GovernanceContextValue>({} as GovernanceContextValue);

export const useGovernance = (): GovernanceContextValue => useContext(GovernanceContext);

export const GovernanceContextProvider: React.FC<GovernanceContextProps> = ({ network, children }) => {
    const { clientStateMap, handleClientError } = useClient();
    const [ governanceState, governanceStateDispatch ] = useReducer(governanceReducer, GOVERNANCE_STATE_DEFAULT);
    const cancelAndSetProposalsPromise = useCancelablePromise<Proposal[]>();
    const cancelAndSetParamsPromise = useCancelablePromise<GovernanceParams>();

    const clientState = clientStateMap[network.chainId];

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            governanceStateDispatch({ type: 'set-proposals-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        governanceStateDispatch({ type: 'set-proposals-loading' });
        cancelAndSetProposalsPromise((signal) => loadProposals(clientState.client!, signal))
            .then((proposals) => governanceStateDispatch({ type: 'set-proposals', payload: proposals }))
            .catch((error) => {
                governanceStateDispatch({ type: 'set-proposals-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetProposalsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            governanceStateDispatch({ type: 'set-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        governanceStateDispatch({ type: 'set-params-loading' });
        cancelAndSetParamsPromise((signal) => loadGovernanceParams(clientState.client!, signal))
            .then((params) => governanceStateDispatch({ type: 'set-params', payload: params }))
            .catch((error) => {
                governanceStateDispatch({ type: 'set-params-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetParamsPromise, clientState, handleClientError ]);

    const setSearchText = useCallback(
        (searchText: string) => governanceStateDispatch({ type: 'set-search-text', payload: searchText }),
        [],
    );

    const setStatusFilter = useCallback(
        (status: ProposalStatus) => governanceStateDispatch({ type: 'set-status-filter', payload: status }),
        [],
    );

    return (
        <GovernanceContext.Provider value={{ network, governanceState, setSearchText, setStatusFilter }}>
            {children}
        </GovernanceContext.Provider>
    );
};
