import React, { useEffect, useState } from 'react';
import { ReactComponent as MagicWandIcon } from '../../../assets/icons/magic-wand.svg';
import Button from '../../../shared/components/button/button';
import Input from '../../../shared/components/form-controls/input/input';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import useWindowSize from '../../../shared/hooks/use-window-size';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import { useAmm } from '../amm-context';
import CreatePoolDialog from '../create-pool-dialog/create-pool-dialog';
import PoolList, { PoolListHeader } from './pool-list/pool-list';
import './pools-page.scss';

const PoolsPage: React.FC = () => {
    const { ammState, loadMore, setSearchText } = useAmm();
    const [ createPoolDialogOpen, setCreatePoolDialogOpen ] = useState<boolean>();
    const { canLoadMore } = useScrollPosition();
    const { isTablet } = useWindowSize();

    useEffect(() => {
        if (canLoadMore) {
            loadMore();
        }
    }, [ loadMore, canLoadMore ]);

    return (
        <div className='page pools-page'>
            <PathNav>
                <PathNavItem label='Liquidity' />
                <PathNavItem label='Pools' />
            </PathNav>

            <PoolListHeader className='pool-list-header positions-header'>My Positions</PoolListHeader>
            <PoolList positions />

            <PoolListHeader className='pool-list-header'>
                All Pools
                <span className='space' />
                <div className='pools-controls'>
                    <Input
                        controlSize='medium'
                        className='pools-search'
                        value={ammState.searchText}
                        type='search'
                        placeholder='Search pools...'
                        onValueChange={setSearchText}
                    />
                    <Button
                        buttonType='primary'
                        tooltipPlacement='top'
                        tooltip={isTablet ? 'Create new pool' : ''}
                        disabled={!ammState.pools}
                        onClick={() => setCreatePoolDialogOpen(true)}
                    >
                        <span className='add-pool-button-text'>Create New Pool&nbsp;&nbsp;</span><MagicWandIcon />
                    </Button>
                </div>
            </PoolListHeader>
            <PoolList />

            {createPoolDialogOpen && <CreatePoolDialog onRequestClose={() => setCreatePoolDialogOpen(false)} />}
        </div>
    );
};

export default PoolsPage;
