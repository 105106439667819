import React from 'react';
import Button from '../../../shared/components/button/button';
import { formatPrice } from '../../../shared/utils/number-utils';
import { Asset } from '../../asset/asset-types';
import TransactionFee from '../../tx/transaction-fee/transaction-fee';
import useClaimIro from './use-claim-iro';
import './claim-iro.scss';

interface ClaimIroProps {
    asset: Asset;
}

const ClaimIro: React.FC<ClaimIroProps> = ({ asset }) => {
    const { iroBalance, broadcast, txState } = useClaimIro(asset);

    if (!iroBalance?.amount) {
        return <></>;
    }

    return (
        <div className='claim-iro section small'>
            You have <b>{formatPrice(iroBalance.amount, iroBalance.currency.displayDenom)}</b> to claim
            <Button
                className='claim-button'
                onClick={() => broadcast()}
                loading={txState.broadcasting || txState.feeLoading}
                disabled={txState.broadcasting || txState.feeLoading}
            >
                Claim
            </Button>
            <TransactionFee fee={{ value: txState.fee?.coins, loading: txState.feeLoading, label: 'Transaction fee' }} />
        </div>
    );
};

export default ClaimIro;
