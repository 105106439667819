import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import Tooltip from '../../../../shared/components/tooltip/tooltip';
import { ROLLAPP_STATUS_INFO_MAP, RollappStatus } from '../../../network/network-types';
import './rollapp-status-indicator.scss';

export interface RollappStatusIndicatorProps {
    className?: string;
    containerClassName?: string;
    status?: RollappStatus;
}

const RollappStatusIndicator: React.FC<RollappStatusIndicatorProps> = ({ status = 'Registered', className, containerClassName }) => {
    return (
        <Tooltip title={<><b>{status}</b><br />{ROLLAPP_STATUS_INFO_MAP[status].infoText}</>} placement='top'>
            <div className={classNames('indicator-click-area', containerClassName)}>
                <span
                    className={classNames('status-indicator', className)}
                    style={{ '--color': `var(${ROLLAPP_STATUS_INFO_MAP[status].variableColor})` } as CSSProperties}
                />
            </div>
        </Tooltip>
    );
};

export default RollappStatusIndicator;
