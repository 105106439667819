import { doc, getDoc } from 'firebase/firestore';
import { useEffect } from 'react';
import { timeToMilliseconds } from '../utils/date-utils';
import { APP_GENERAL_SETTINGS, AppGeneralSettings, firestore } from '../utils/firebase-utils';
import useVisibility from './use-visibility';

const LAST_REFRESH_KEY = 'last-refresh';
const MIN_TIME_BETWEEN_REFRESHES = timeToMilliseconds({ minutes: 30 });

const useVersion = () => {
    const isVisible = useVisibility();

    useEffect(() => {
        const lastRefresh = Number(localStorage.getItem(LAST_REFRESH_KEY) || 0);
        if (Date.now() - lastRefresh <= MIN_TIME_BETWEEN_REFRESHES) {
            return;
        }
        const currentVersion = Number(process.env.REACT_APP_VERSION) || 0;
        if (isVisible) {
            getDoc(doc(firestore, APP_GENERAL_SETTINGS)).then((doc) => {
                const settings: AppGeneralSettings = doc.data() as AppGeneralSettings;
                console.log('versions: ', settings?.version, currentVersion);
                if (settings?.version && currentVersion < settings.version) {
                    localStorage.setItem(LAST_REFRESH_KEY, Date.now().toString());
                    window.location.reload();
                }
            });
        }
    }, [ isVisible ]);
};

export default useVersion;
