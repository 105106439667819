import React, { useMemo } from 'react';
import Addresses from '../../../../shared/components/addresses/addresses';
import Button from '../../../../shared/components/button/button';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import Link from '../../../../shared/components/link/link';
import Property from '../../../../shared/components/property/property';
import Spinner from '../../../../shared/components/spinner/spinner';
import useCopyToClipboard from '../../../../shared/hooks/use-copy-to-clipboard';
import { getShortenedAddress } from '../../../../shared/utils/text-utils';
import { getFixedDenom } from '../../../currency/currency-service';
import { useNetwork } from '../../../network/network-context';
import { convertToHexAddress } from '../../../wallet/wallet-service';
import { Asset } from '../../asset-types';
import './asset-info.scss';

interface AssetInfoProps {
    asset: Asset;
}

const AssetInfo: React.FC<AssetInfoProps> = ({ asset }) => {
    const { vfcsLoading, vfcMap } = useNetwork();
    const copyToClipboard = useCopyToClipboard();

    const erc20Contract = useMemo(() => {
        const denom = getFixedDenom(asset, false);
        return denom && vfcMap?.[denom]?.contractAddress;
    }, [ asset, vfcMap ]);


    const xAccountName = useMemo(() => {
        if (!asset.network.xAccount) {
            return '';
        }
        const xAccountUrlPart = asset.network.xAccount.split('/');
        return `@${xAccountUrlPart[xAccountUrlPart.length - 1]}`;
    }, [ asset.network.xAccount ]);

    return (
        <div className='asset-info section small'>
            {asset.network.type !== 'Hub' && (
                <Property label='ERC20 contract' className='info-property' valueClassName='info-property-value'>
                    {!erc20Contract && vfcsLoading ? <Spinner size='small' /> : (
                        <Button
                            size='small'
                            buttonType='icon'
                            disabled={!erc20Contract}
                            className='info-property-value-content website'
                            onClick={() => copyToClipboard(erc20Contract || '', 'contract address')}
                        >
                            <span className='ellipsis'>{erc20Contract || <>0<span>x</span>000000...000000</>}</span>&nbsp;&nbsp;
                            <CopyIcon />
                        </Button>
                    )}
                </Property>
            )}

            {asset.ibc?.representation && (
                <Property label='IBC contract' className='info-property' valueClassName='info-property-value'>
                    <Button
                        size='small'
                        buttonType='icon'
                        className='info-property-value-content'
                        onClick={() => copyToClipboard(asset.ibc?.representation || '', 'contract address')}
                    >
                        {getShortenedAddress(asset.ibc.representation, 12, 8)}&nbsp;&nbsp;
                        <CopyIcon />
                    </Button>
                </Property>
            )}

            {asset.network.website && (
                <Property label='Website' className='info-property' valueClassName='info-property-value'>
                    <Link url={asset.network.website} external className='info-property-value-content website' size='small'>
                        <span className='ellipsis'>{asset.network.website}</span>
                    </Link>
                </Property>
            )}

            {asset.network.owner && (
                <Property label='Founder' className='info-property' valueClassName='info-property-value'>
                    <Addresses
                        className='info-property-value-content'
                        display='long'
                        addresses={[ convertToHexAddress(asset.network.owner), asset.network.owner ]}
                        canCopy
                    />
                </Property>
            )}

            {asset.network.xAccount && (
                <Property label='X (Twitter)' className='info-property' valueClassName='info-property-value'>
                    <Link url={asset.network.xAccount} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{xAccountName}</span>
                    </Link>
                </Property>
            )}

            {asset.network.tgAccount && (
                <Property label='Telegram' className='info-property' valueClassName='info-property-value'>
                    <Link url={asset.network.tgAccount} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{asset.network.tgAccount}</span>
                    </Link>
                </Property>
            )}

            <Property label='Network ID' className='info-property' valueClassName='info-property-value'>
                <Button
                    size='small'
                    buttonType='icon'
                    className='info-property-value-content'
                    onClick={() => copyToClipboard(asset.networkId, 'network ID')}
                >
                    {asset.networkId}&nbsp;&nbsp;
                    <CopyIcon />
                </Button>
            </Property>

            {asset.network.description && (
                <Property label='Description' className='info-property' valueClassName='info-property-value text'>
                    {asset.network.description}
                </Property>
            )}
        </div>
    );
};

export default AssetInfo;
