import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo } from 'react';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import { convertToCoin, getFixedDenom, getMaxDenomAmount, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { AmountTxState } from '../../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../../tx/tx-state';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import { useAmm } from '../../../amm/amm-context';
import { createLockMessage } from '../../../amm/amm.service';

interface AssetBondValue {
    amountTxState: AmountTxState;
    availableBalances: CoinsAmount[];
    txState: TxState;
    setAmount: (amount: number) => void;
    broadcast: (memo?: string) => void;
}

export type AssetBondType = 'Bond' | 'Unbond';

export const useAssetBond = (coins: CoinsAmount, type: AssetBondType): AssetBondValue => {
    const networkState = useHubNetworkState();
    const { ammState } = useAmm();
    const { hubWallet, handleWalletError } = useWallet();
    const { handleClientError } = useClient();

    const assetBondMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        if (!networkState.address || !coins) {
            return [];
        }
        // if (fee && isCoinsEquals(coins1, fee)) {
        //     coins1 = { ...coins1, amount: Math.min(coins1.amount, asset1AvailableBalances[0].amount - fee.amount) };
        // } else if (fee && isCoinsEquals(coins2, fee)) {
        //     coins2 = { ...coins2, amount: Math.min(coins2.amount, asset2AvailableBalances[0].amount - fee.amount) };
        // }

        const coin = convertToCoin(coins);
        let lockId = undefined;
        if (type === 'Unbond') {
            const lockedAsset = ammState.lockedAssets?.find((asset) => getFixedDenom(coins) === asset.coin.denom);
            lockId = lockedAsset?.lockId;
        }
        return [ createLockMessage(networkState.address, coin, lockId) ];
    }, [ ammState.lockedAssets, networkState.address, type ]);

    const availableBalances = useMemo(() => {
        if (type === 'Bond') {
            return [ networkState.balances?.find((balance) => isCoinsEquals(balance, coins)) || { ...coins, amount: 0 } ];
        }
        const lockedAsset = ammState.lockedAssets?.find((asset) => getFixedDenom(coins) === asset.coin.denom);
        return [ { ...coins, amount: getMaxDenomAmount(Number(lockedAsset?.coin.amount) || 0, coins.currency) } ];
    }, [ ammState.lockedAssets, coins, networkState.balances, type ]);

    const {
        txState,
        amountTxState,
        setAmount,
        broadcast,
        calculateFee,
        clearFee,
    } = useAmountTx({
        availableBalances,
        networkState,
        amountTxMessagesCreator: assetBondMessagesCreator,
        reduceFeeFromBalances: type === 'Bond',
    });

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (hubWallet && networkState.network && coins) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, coins, hubWallet, networkState.network ]);

    return {
        txState,
        amountTxState,
        availableBalances,
        setAmount,
        broadcast,
    };
};


