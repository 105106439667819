import classNames from 'classnames';
import React, { useRef, ReactNode, MouseEvent } from 'react';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import Icon from '../icon/icon';
import Spinner from '../spinner/spinner';
import { useFileUploader, UseFileUploaderProps } from './use-file-uploader';
import './file-uploader.scss';

interface FileUploaderProps extends UseFileUploaderProps {
    error?: ReactNode;
    className?: string;
    loading?: boolean;
    circleImage?: boolean;
    hideFileName?: boolean;
    onBrowseFile?: (event: MouseEvent<HTMLInputElement>) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({
    className,
    loading,
    error,
    circleImage,
    hideFileName,
    onBrowseFile,
    ...otherFileUploaderProps
}) => {
    const {
        file,
        imageContent,
        dragged,
        handleBrowseFile,
        handleDragOver,
        handleDrop,
        handleDragLeave,
    } = useFileUploader(otherFileUploaderProps);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { acceptImages, disabled } = otherFileUploaderProps;

    return <>
        <div
            className={classNames('file-uploader', className, { disabled, dragged, error: Boolean(error) })}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onClick={() => fileInputRef.current?.click()}
        >
            {imageContent && <>
                <img
                    src={imageContent}
                    alt='preview'
                    className={classNames('image-preview', { dark: circleImage })}
                />
                {circleImage && <img src={imageContent} alt='preview' className='image-preview circle' />}
            </>}
            <input
                disabled={disabled}
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onClick={(event) => {
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    onBrowseFile?.(event);
                }}
                onChange={handleBrowseFile}
                {...(acceptImages ? { accept: 'image/*' } : {})}
            />
            <Icon className={classNames('upload-icon', { 'on-image': imageContent })}><UploadIcon /></Icon>
            {!imageContent && (
                <p className='file-drag-label'>
                    Drag and drop {acceptImages ? 'an image' : 'a'} file or browse
                </p>
            )}
            {(file || loading) ?
                <p className={classNames('uploaded-file-name', { 'image-file': acceptImages && !loading, hidden: hideFileName && file })}>
                    {loading ? <Spinner size='small' /> : file?.name}
                </p> : null}
        </div>
        <span className={classNames('error-label', { visible: Boolean(error) })}>{error}</span>
    </>;
};

export default FileUploader;
