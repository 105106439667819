import { EIP712ToSign } from '@evmos/transactions';
import { ChainInfo, Currency as WalletCurrency, EthSignType, Keplr } from '@keplr-wallet/types';
import { SignDoc } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { OfflineAminoSigner } from 'cosmjs/packages/amino';
import { StdSignDoc } from 'cosmjs/packages/amino/src/signdoc';
import { OfflineDirectSigner, OfflineSigner } from 'cosmjs/packages/proto-signing';
import { DEFAULT_GAS_PRICE_STEPS } from '../../client/client-types';
import { getCurrencyLogoPath, getFeeCurrency, getStakingCurrency } from '../../currency/currency-service';
import { CoinsAmount, Currency } from '../../currency/currency-types';
import { Network } from '../../network/network-types';
import { WalletError } from '../wallet-error';
import { convertToHexAddress } from '../wallet-service';
import { Wallet, WalletType } from '../wallet-types';

interface NetworkApplyingPromise {
    network: Network;
    resolve: (value: string) => void;
    reject: (reason?: any) => void;
}

export type CosmosProvider = Keplr;

export abstract class CosmosWallet implements Wallet {
    private static networkApplyingPromisesMap: { [walletType in WalletType]?: NetworkApplyingPromise[] } = {};
    private accountChangeListener?: () => void;
    private readonly networkApplyingPromises: NetworkApplyingPromise[];

    protected constructor(private keyChangeEventName: string) {
        this.networkApplyingPromises = CosmosWallet.networkApplyingPromisesMap[this.getWalletType()] || [];
        CosmosWallet.networkApplyingPromisesMap[this.getWalletType()] = this.networkApplyingPromises;
    }

    public validateWalletInstalled(): Promise<void> {
        return this.getProvider().then();
    }

    abstract getWalletType(): WalletType;

    abstract getCurrentProvider(): CosmosProvider | undefined;

    public async getAddress(network: Network): Promise<{ address: string, hexAddress?: string }> {
        const provider = await this.getProvider();
        let key = await provider.getKey(network.chainId).catch((error) => {
            if (error.message.toLowerCase().includes('reject')) {
                throw new WalletError('REQUEST_REJECTED', this.getWalletType(), network, error);
            }
        });
        if (key?.bech32Address) {
            return { address: key.bech32Address, hexAddress: convertToHexAddress(key.bech32Address) };
        }
        if (!provider.experimentalSuggestChain) {
            throw new WalletError('UPGRADE_WALLET', this.getWalletType(), network);
        }
        const address = await new Promise<string>((resolve, reject) => {
            if (this.networkApplyingPromises.push({ network, resolve, reject }) === 1) {
                this.suggestNextChain(provider);
            }
        });
        return { address, hexAddress: convertToHexAddress(address) };
    }

    public async suggestToken(coins: CoinsAmount, coinsOriginalNetwork: Network): Promise<void> {
        const provider = await this.getProvider();
        await new Promise<string>((resolve, reject) => {
            if (this.networkApplyingPromises.push({ network: coinsOriginalNetwork, resolve, reject }) === 1) {
                this.suggestNextChain(provider);
            }
        });
    }

    public async getOfflineSigner(network: Network, tries = 2): Promise<OfflineSigner> {
        const provider = await this.getProvider();
        const offlineSigner = await provider.getOfflineSignerAuto(network.chainId).catch((error) => {
            if (error.message.toLowerCase().includes('reject')) {
                throw new WalletError('REQUEST_REJECTED', this.getWalletType(), network, error);
            }
        });
        if (!offlineSigner) {
            if (tries === 1) {
                throw new WalletError('NO_OFFLINE_SIGNER', this.getWalletType(), network);
            }
            await this.getAddress(network).catch(() => {
                throw new WalletError('NO_OFFLINE_SIGNER', this.getWalletType(), network);
            });
            return this.getOfflineSigner(network, tries - 1);
        }
        if (!network.evm && network.type !== 'RollApp') {
            return offlineSigner;
        }
        return {
            getAccounts: () => offlineSigner.getAccounts(),
            signEIP712: (signerAddress: string, signDoc: EIP712ToSign): Promise<Uint8Array> => {
                const eip712Payload = JSON.stringify(signDoc);
                return provider.signEthereum(network.chainId, signerAddress, eip712Payload, EthSignType.EIP712);
            },
            signDirect: (signerAddress: string, signDoc: SignDoc) =>
                (offlineSigner as OfflineDirectSigner)?.signDirect(signerAddress, signDoc),
            signAmino: (signerAddress: string, signDoc: StdSignDoc) =>
                (offlineSigner as OfflineAminoSigner)?.signAmino(signerAddress, signDoc),
        };
    }

    public setAccountChangesListener(listener: () => void): void {
        this.accountChangeListener = listener;
        window.addEventListener(this.keyChangeEventName, this.accountChangeListener);
    }

    public clear(): void {
        if (this.accountChangeListener) {
            window.removeEventListener(this.keyChangeEventName, this.accountChangeListener);
        }
    }

    public async getProvider(): Promise<CosmosProvider> {
        let provider = this.getCurrentProvider();
        if (provider) {
            return provider;
        }
        provider = await new Promise<CosmosProvider | undefined>((resolve) => {
            if (document.readyState === 'complete') {
                resolve(this.getCurrentProvider());
                return;
            }
            const documentStateChange = (event: Event) => {
                if (event.target && (event.target as Document).readyState === 'complete') {
                    resolve(this.getCurrentProvider());
                    document.removeEventListener('readystatechange', documentStateChange);
                }
            };
            document.addEventListener('readystatechange', documentStateChange);
        });
        if (!provider) {
            throw new WalletError('INSTALL_WALLET', this.getWalletType(), undefined, undefined, false);
        }
        return provider;
    };

    private suggestNextChain(provider: CosmosProvider): void {
        if (this.networkApplyingPromises.length === 0) {
            return;
        }
        const { network, resolve, reject } = this.networkApplyingPromises[0];
        provider.experimentalSuggestChain(this.getChainInfo(network))
            .then(() => provider.enable(network.chainId))
            .then(() => provider.getKey(network.chainId))
            .then((key) => resolve(key.bech32Address))
            .catch((error) => reject(new WalletError('FAILED_INTEGRATE_CHAIN', this.getWalletType(), network, error)))
            .finally(() => {
                this.networkApplyingPromises.shift();
                this.suggestNextChain(provider);
            });
    }

    private getChainInfo(network: Network): ChainInfo {
        if (!network.rpc || !network.rest || !network.bech32Prefix) {
            throw new Error('Missing rpc or rest APIs');
        }
        return {
            ...network,
            chainName: network.chainName.slice(0, 30),
            evm: network.evm ? { chainId: Number(network.evm.chainId), rpc: network.evm.rpc || '' } : undefined,
            rpc: network.rpc,
            rest: network.rest,
            bip44: { coinType: network.coinType },
            bech32Config: {
                bech32PrefixAccAddr: network.bech32Prefix,
                bech32PrefixAccPub: network.bech32Prefix + 'pub',
                bech32PrefixValAddr: network.bech32Prefix + 'valoper',
                bech32PrefixValPub: network.bech32Prefix + 'valoperpub',
                bech32PrefixConsAddr: network.bech32Prefix + 'valcons',
                bech32PrefixConsPub: network.bech32Prefix + 'valconspub',
            },
            currencies: network.currencies.map((currency) => this.convertToWalletCurrency(currency, network)),
            stakeCurrency: this.convertToWalletCurrency(getStakingCurrency(network), network),
            feeCurrencies: [
                {
                    ...this.convertToWalletCurrency(getFeeCurrency(network), network),
                    gasPriceStep: network.gasPriceSteps ?? DEFAULT_GAS_PRICE_STEPS,
                },
            ],
            features: [
                'ibc-transfer',
                'ibc-go',
                ...(network.evmType === 'WASM' ? [ 'cosmwasm' ] : []),
                ...(network.type === 'Hub' || network.type === 'RollApp' ?
                    [ 'eth-address-gen', 'eth-key-sign', 'force-enable-evm-ledger' ] : []),
            ],
        };
    }

    private convertToWalletCurrency = (currency: Currency, network: Network): WalletCurrency => {
        return {
            coinMinimalDenom: currency.rollappIbcRepresentation || currency.baseDenom,
            coinDenom: currency.displayDenom,
            coinDecimals: currency.decimals,
            coinImageUrl: getCurrencyLogoPath(currency, network, false),
        };
    };
}

