import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import useVisibility from '../../../shared/hooks/use-visibility';
import { useAmm } from '../../amm/amm-context';
import { tradesObservable, useAsset } from '../../asset/asset-context';
import { Asset } from '../../asset/asset-types';
import { isCoinsEquals } from '../../currency/currency-service';
import { useNetwork } from '../../network/network-context';
import { Trade } from '../types';
import { getFixedTradeBase, loadTrades } from '../trade-service';

export const PAGE_SIZE = 15;

interface UseTradeListValue {
    trades?: Trade[];
    loading: boolean;
    loadMore: () => void;
    vsAsset?: Asset;
}

export const useTradeList = (asset: Asset): UseTradeListValue => {
    const { hubNetwork } = useNetwork();
    const { assets, vsAsset: vsUsdAsset, hubAsset } = useAsset();
    const { ammState } = useAmm();
    const [ trades, setTrades ] = useState<Trade[]>();
    const [ loading, setLoading ] = useState(true);
    const [ page, setPage ] = useState(0);
    const [ canLoadMore, setCanLoadMore ] = useState(true);
    const visibility = useVisibility();
    const cancelAndSetTradesPromise = useCancelablePromise<{ records: Trade[] }>();

    const vsAsset = useMemo(
        () => !hubAsset || !vsUsdAsset ? undefined : isCoinsEquals(hubAsset, asset) ? vsUsdAsset : hubAsset,
        [ asset, hubAsset, vsUsdAsset ],
    );

    useEffect(() => {
        if (visibility) {
            setPage(0);
            setCanLoadMore(true);
            setLoading(true);
        }
    }, [ visibility ]);

    useEffect(() => {
        if (!hubNetwork || !canLoadMore || !loading || !vsAsset) {
            return;
        }
        cancelAndSetTradesPromise((signal) => loadTrades(hubNetwork.chainId, asset, vsAsset, page, PAGE_SIZE, signal))
            .then(({ records: trades }) =>
                trades.map((trade) => getFixedTradeBase(trade, assets || [], ammState.params?.vsCoins.currency)).filter(Boolean) as Trade[])
            .then((trades) => {
                if (trades.length < PAGE_SIZE) {
                    setCanLoadMore(false);
                }
                setTrades((currentTrades) => page === 0 ? trades : uniqBy([ ...(currentTrades || []), ...trades ], (trade) => trade.id));
            })
            .finally(() => setLoading(false));
    }, [ ammState.params?.vsCoins.currency, asset, assets, canLoadMore, cancelAndSetTradesPromise, hubNetwork, loading, page, vsAsset ]);

    const loadMore = useCallback(() => {
        if (!loading && canLoadMore) {
            setLoading(true);
            setPage(page + 1);
        }
    }, [ canLoadMore, loading, page ]);

    useEffect(() => {
        if (!vsAsset) {
            return;
        }
        const subscription = tradesObservable.subscribe((newTrade) => {
            if ((!isCoinsEquals(newTrade.tokenIn, asset) || !isCoinsEquals(newTrade.tokenOut, vsAsset)) &&
                (!isCoinsEquals(newTrade.tokenIn, vsAsset) || !isCoinsEquals(newTrade.tokenOut, asset))) {
                return;
            }
            setTrades((currentTrades) => {
                if (currentTrades?.some((trade) => trade.id === newTrade.id)) {
                    return currentTrades;
                }
                return uniqBy(
                    [ newTrade, ...(currentTrades || []) ]
                        .sort((trade1, trade2) => trade2.time - trade1.time || trade2.usdVolume - trade1.usdVolume),
                    (trade) => trade.id.replace(/-[0-9]+$/, ''),
                );
            });
        });
        return () => subscription.unsubscribe();
    }, [ asset, asset.networkId, vsAsset ]);

    return { trades, loading, loadMore, vsAsset };
};
