import * as bech32 from 'bech32';
import { Network } from '../network/network-types';
import { Wallet, WalletInfoMap, WalletType } from './wallet-types';
import { KeplrWallet } from './wallets/keplr-wallet';
import { WalletError } from './wallet-error';
import { LeapWallet } from './wallets/leap-wallet';
import { MetaMaskWallet } from './wallets/meta-mask-wallet';
import { fromBech32, fromHex, toBech32, toHex } from 'cosmjs/packages/encoding';
import { PhantomWallet } from './wallets/phantom-wallet';
import { PortalWallet } from './wallets/portal-wallet/portal-wallet';
import { QuickAuthWallet } from './wallets/quick-auth/quick-auth-wallet';
import { RabbyWallet } from './wallets/rabby-wallet';

export const createWallet = async (walletType: WalletType, accountChangeListener?: () => void): Promise<Wallet> => {
    let wallet: Wallet | null = null;
    if (walletType === 'Keplr') {
        wallet = new KeplrWallet();
    } else if (walletType === 'MetaMask') {
        wallet = new MetaMaskWallet();
    } else if (walletType === 'Rabby') {
        wallet = new RabbyWallet();
    } else if (walletType === 'Leap') {
        wallet = new LeapWallet();
    } else if (walletType === 'Phantom') {
        wallet = new PhantomWallet();
    } else if (walletType === 'PortalWallet') {
        wallet = new PortalWallet();
    } else if (walletType === 'Quick Auth') {
        wallet = new QuickAuthWallet();
    }
    if (!wallet) {
        throw new WalletError('UNSUPPORTED_WALLET', walletType);
    }
    await wallet.validateWalletInstalled();
    if (accountChangeListener) {
        wallet.setAccountChangesListener(accountChangeListener);
    }
    return wallet;
};

export const convertToHexAddress = (bech32Address: string): string => {
    return '0x' + toHex(fromBech32(bech32Address).data);
};

export const convertToBech32Address = (hexAddress: string, bech32Prefix: string): string => {
    return toBech32(bech32Prefix, fromHex(hexAddress.replaceAll(/^0x/g, '')));
};

export const isValidBech32Address = (address: string, bech32Prefix: string) => {
    try {
        const { prefix, words } = bech32.decode(address);
        return bech32Prefix === prefix && words.length;
    } catch {
        return false;
    }
};

export const isWalletSupported = (network: Network, type: WalletType): boolean => {
    const walletNetworkType = WalletInfoMap[type].type;
    switch (walletNetworkType) {
        case 'evm':
            return network.type === 'EVM' || network.type === 'Hub' || Boolean(network.evm);
        case 'solana':
            return network.type === 'Solana';
        case 'cosmos':
            return network.type === 'Regular' || network.type === 'RollApp' || network.type === 'Hub';
    }
    return false;
};
