import React, { useState } from 'react';
import Dialog, { DialogAction, DialogContent, DialogTitle } from '../../shared/components/dialog/dialog';
import Icon from '../../shared/components/icon/icon';
import Link from '../../shared/components/link/link';
import { usePersistedState } from '../../shared/hooks/use-persisted-state';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified.svg';
import './terms-agreement-dialog.scss';

const TERMS_AGREED_KEY = 'terms-agreed-key';
const TERMS_URL = 'https://firebasestorage.googleapis.com/v0/b/dymension-mainnet.appspot.com/o/legal%2FTerms%20of%20Service%20for%20Dymension%20Portal.pdf?alt=media&token=8817f741-e090-4a66-9185-b7c7a715669e';

const TermsAgreementDialog: React.FC = () => {
    const [ termsAgreed, setTermsAgreed ] = usePersistedState(TERMS_AGREED_KEY, false);
    const [ agreeCheckboxValue, setAgreeCheckboxValue ] = useState(false);

    return termsAgreed ? <></> : <>
        <Dialog className='terms-agreement-dialog'>
            <DialogTitle>Terms of Use</DialogTitle>
            <DialogContent>
                <Icon className='terms-icon'><VerifiedIcon /></Icon>

                By Continuing, you agree to:

                <div className='agreements'>
                    <p className='section small'>
                        I understand the Portal is only a front-end interface designed to help users interact with the permissionless Dymension L1 blockchain.
                    </p>
                    <p className='section small'>
                        I understand the Portal disclaims all liability for rollups, their tokens, their creators, and how they operate.
                    </p>
                    <div className='section small'>
                        <label className='agree-checkbox-container'>
                            <input
                                className='agree-checkbox'
                                type='checkbox'
                                checked={agreeCheckboxValue}
                                onChange={(event) => setAgreeCheckboxValue(event.target.checked)}
                            />
                            I agree to Dymension’s&nbsp;<Link inline external url={TERMS_URL}>Terms of Use</Link>.
                        </label>
                    </div>
                </div>
            </DialogContent>
            <DialogAction
                className='continue-button'
                size='large'
                primary
                disabled={!agreeCheckboxValue}
                onClick={() => setTermsAgreed(true)}
            >
                Continue
            </DialogAction>
        </Dialog>
    </>;
};

export default TermsAgreementDialog;
