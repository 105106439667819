import classNames from 'classnames';
import { Decimal } from 'cosmjs/packages/math';
import { isEqual } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Point } from 'recharts/types/shape/Curve';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import Tooltip from '../../../../../../../shared/components/tooltip/tooltip';
import { formatNumber, formatPrice, roundNumber } from '../../../../../../../shared/utils/number-utils';
import {
    calculateBondingCurvePoints,
    convertFromBondingCurve,
    getBuyCostFormBondingCurve,
    getCurveType,
} from '../../../../../../iro/iro-service';
import { BondingCurve } from '../../../../../../iro/types';
import { useCreateRollapp } from '../../../create-rollapp-context';
import { calculateBondingCurve } from '../../../create-rollapp-service';
import { CURVE_OPTIONS, CurveType, DEFAULT_DENOM_EXPONENT } from '../../../types';
import './iro-curve-section.scss';

const IroCurveSection: React.FC = () => {
    const { rollapp, supplyAllocationAmounts, iro, setIro } = useCreateRollapp();

    const curveOptions = useMemo(() => {
        const allocation = Decimal.fromAtomics(supplyAllocationAmounts.iro.toString(), DEFAULT_DENOM_EXPONENT).toFloatApproximation();
        return CURVE_OPTIONS.map((option) => {
            const curve = calculateBondingCurve(iro.targetRaise || 0, allocation, option, 0);
            const points = calculateBondingCurvePoints(curve, allocation);
            const price = getBuyCostFormBondingCurve(convertFromBondingCurve(curve), 0, 1);
            const disabled = rollapp.sealed || (option.type !== 'Fixed' && !roundNumber(curve.M, 18, true)) || !price;
            return { curve, points, disabled, ...option };
        });
    }, [ iro.targetRaise, rollapp.sealed, supplyAllocationAmounts.iro ]);

    const onCurveSelect = useCallback((curve: BondingCurve) => {
        setIro({ ...iro, bondingCurve: curve });
    }, [ iro, setIro ]);

    useEffect(() => {
        if (!curveOptions.length) {
            return;
        }
        const curveType = iro.bondingCurve ? getCurveType(iro.bondingCurve) : 'Exponential';
        let option = curveOptions.find((option) => option.type === curveType);
        if (option?.disabled) {
            option = curveOptions.find((option) => option.type === 'Linear' && !option.disabled) ||
                curveOptions.find((option) => option.type !== 'Fixed' && !option.disabled) ||
                curveOptions.find((option) => !option.disabled);
        }
        if (!option) {
            return;
        }
        if (!iro.bondingCurve || !isEqual(option.curve, iro.bondingCurve)) {
            setIro({ ...iro, bondingCurve: option.curve });
        }
    }, [ curveOptions, iro, setIro ]);

    const yAxisDomain = useMemo(
        () => [ 0, Math.max(...curveOptions.map((option) => option.points[option.points.length - 1].y)) ],
        [ curveOptions ],
    );

    const yAxisTicks = useMemo(() => {
        const averagePrice = curveOptions.find((option) => option.type === 'Fixed')?.points[0].y || 0;
        return [ averagePrice, yAxisDomain[1] ].sort();
    }, [ curveOptions, yAxisDomain ]);

    const renderCurveChart = (data: { type: CurveType, curve: BondingCurve, disabled: boolean, points: Point[], title: string, info: string }): ReactElement => {
        const active = iro.bondingCurve && getCurveType(data.curve) === getCurveType(iro.bondingCurve);
        const areaBackgroundId = `area-background-${data.curve.N}-${data.curve.M}`;
        const xTicks = [ 20, 60, 100 ].map((index) => data.points[index].x);

        return (
            <div key={data.type} className={classNames('curve-chart-container', { active, disabled: data.disabled })}>
                <div className='curve-chart-header'>
                    {data.title}
                    <InfoIndicator className='curve-chart-info-indicator'>{data.info}</InfoIndicator>
                </div>
                <ResponsiveContainer height={200}>
                    <AreaChart
                        data={data.points}
                        margin={{ top: 10, right: 10 }}
                        onClick={() => !data.disabled && onCurveSelect(data.curve)}
                    >
                        <CartesianGrid strokeDasharray='3 3' className='chart-grid' />

                        <XAxis
                            dataKey='x'
                            height={24}
                            ticks={xTicks}
                            tickFormatter={(value) => formatPrice(value, '', { notation: 'compact' })}
                            fontSize={12}
                        />

                        <YAxis
                            mirror
                            fontSize={12}
                            ticks={yAxisTicks}
                            tick={({ payload, x, y, className, fill, fontSize, height }) => {
                                let options: Intl.NumberFormatOptions;
                                if (payload.value < 0.001) {
                                    options = { maximumSignificantDigits: 2 };
                                } else if (payload.value < 1) {
                                    options = { minimumFractionDigits: 2, maximumFractionDigits: 5 };
                                } else {
                                    options = { notation: 'compact', minimumFractionDigits: 2, maximumFractionDigits: 3 };
                                }
                                const dy = data.type === 'Fixed' && payload.value !== yAxisDomain[1] ? -6 : 4;
                                return (
                                    <text {...{ x, y, className, fill, fontSize, height, dy }}>
                                        {formatNumber(payload.value, options)} DYM
                                    </text>
                                );
                            }}
                            domain={yAxisDomain}
                        />
                        <defs>
                            <linearGradient id={areaBackgroundId} x1='0' y1='0' x2='0' y2='1'>
                                <stop offset='5%' stopOpacity={0.7} className={classNames('area-background')} />
                                <stop offset='95%' stopOpacity={0.1} className={classNames('area-background')} />
                            </linearGradient>
                        </defs>
                        <Area type='monotone' dataKey='y' className='area-chart' fill={`url(#${areaBackgroundId})`} />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        );
    };

    return (
        <div className='iro-curve-section section'>
            <h5 className='section-header'>
                Bonding Curve
                <InfoIndicator indicatorSize='small'>
                    Choose a curve to set how token prices change during your IRO.
                    This decision impacts how prices adjust as demand increases, influencing investor/trader behavior and funding success.
                    Consider your goals and strategy when selecting a curve.
                </InfoIndicator>
            </h5>

            <div className='controls-row curve-charts-container'>
                {curveOptions.map((option) => !option.disabled ? renderCurveChart(option) :
                    <Tooltip key={option.type} title='The target raise is too low.'>{renderCurveChart(option)}</Tooltip>)}
            </div>
        </div>
    );
};

export default IroCurveSection;
