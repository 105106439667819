import classNames from 'classnames';
import React, { cloneElement, ReactNode, useMemo } from 'react';
import { validateAndGetChildrenAsArray } from '../../utils/react-utils';
import './stepper.scss';

export interface StepProps {
    label: ReactNode;
    className?: string;
    disabled?: boolean;
}

export interface StepperProps {
    children: ReactNode;
    currentStep?: number;
    visibleStep?: number;
    className?: string;
    disabled?: boolean;
    onVisibleStepChange?: (stepIndex: number) => void;
}

interface InnerStepProps extends StepProps {
    stepNumber?: number;
    passed?: boolean;
    current?: boolean;
    visible?: boolean;
    clickable?: boolean;
    onStepClick?: () => void;
}

export const Step: React.FC<StepProps> = (stepProps) => <InnerStep {...stepProps} />;

const Stepper: React.FC<StepperProps> = ({ className, children, disabled, currentStep = 0, visibleStep = 0, onVisibleStepChange }) => {
    const steps = useMemo(() => {
        const steps = validateAndGetChildrenAsArray(children, Step);
        return steps.map((step, stepIndex) => cloneElement<InnerStepProps>(
            step, {
                stepNumber: stepIndex + 1,
                passed: stepIndex < currentStep,
                current: currentStep === stepIndex,
                visible: visibleStep === stepIndex,
                clickable: !disabled && !step.props.disabled && stepIndex <= currentStep,
                onStepClick: () => stepIndex <= currentStep ? onVisibleStepChange?.(stepIndex) : null,
            },
        ));
    }, [ children, currentStep, disabled, onVisibleStepChange, visibleStep ]);

    return (
        <div className={classNames('stepper', className)}>{steps}</div>
    );
};

const InnerStep: React.FC<InnerStepProps> = ({
    className,
    passed,
    disabled,
    visible,
    current,
    clickable,
    stepNumber,
    label,
    onStepClick,
}) => {
    return (
        <div
            className={classNames('step', className, { passed, visible, disabled, current, clickable })}
            onClick={() => !disabled && onStepClick?.()}
        >
            <div className='step-progress-container'>
                <span className='step-indicator'>{passed ? '✓' : ''}</span>
                <div className='step-progress' />
            </div>

            <div className='step-number'>STEP {stepNumber}</div>
            <div className={classNames('step-name', { visible })}>{label}</div>
        </div>
    );
};

export default Stepper;
