import { Pool } from '../amm/types';
import { Network } from '../network/network-types';

export const SPONSORSHIP_TYPES = [ 'RollApp', 'Pool' ] as const;
export type SponsorshipType = typeof SPONSORSHIP_TYPES[number];

export interface SponsorshipWeight {
    gaugeId: number;
    weight: number;
    power: number;
}

export interface SponsorshipRecord extends SponsorshipWeight {
    rollapp?: Network;
    pool?: Pool;
    type: SponsorshipType;
}

export interface SponsorshipParams {
    minAllocationWeight: number;
    minVotingPower: number;
}

