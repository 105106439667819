import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { analyticsMapReducer, AnalyticsMapState } from '../../analytics/analytics-map-state';
import { loadNetworkAnalyticsMap } from '../../network/statistics/analytics/network-analytics-service';
import { NetworksAnalytics } from '../../network/statistics/analytics/network-analytics-types';
import { useAsset } from '../asset-context';
import { loadPoolAnalyticsMap } from '../../amm/statistics/analytics/pool-analytics-service';
import { PoolAnalytics } from '../../amm/statistics/analytics/pool-analytics-types';
import { Asset } from '../asset-types';

interface AssetListContextValue {
    sortedFilteredAssets?: Asset[];
    assetsPoolsAnalyticsState: AnalyticsMapState<{ [poolId: string]: PoolAnalytics }>;
    assetsNetworksAnalyticsState: AnalyticsMapState<{ [networkId: string]: NetworksAnalytics }>;
    searchText: string;
    loadMore: () => void;
    setSearchText: (searchText: string) => void;
}

const PAGE_SIZE = 20;

export const AssetListContext = createContext<AssetListContextValue>({} as AssetListContextValue);

export const useAssetList = (): AssetListContextValue => useContext(AssetListContext);

export const AssetListContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { assets, loading, toCalculatePriceWithMarketCap } = useAsset();
    const [ assetsPoolsAnalyticsState, assetsPoolsAnalyticsStateDispatch ] = useReducer(analyticsMapReducer, {});
    const [ assetsNetworksAnalyticsState, assetsNetworksAnalyticsStateDispatch ] = useReducer(analyticsMapReducer, {});
    const [ searchText, setSearchText ] = useState<string>('');
    const [ page, setPage ] = useState(0);

    const sortedFilteredAssets = useMemo(() => {
        if (!assets) {
            return undefined;
        }
        let filteredAssets = assets;
        if (searchText) {
            const searchRegExp = new RegExp(searchText.trim(), 'i');
            filteredAssets = filteredAssets.filter((asset) =>
                searchRegExp.test(asset.currency.displayDenom) ||
                searchRegExp.test(asset.currency.baseDenom) ||
                searchRegExp.test(asset.network.chainName));
        }
        return filteredAssets
            .sort((asset1, asset2) => asset2.liquidity - asset1.liquidity)
            .slice(0, (page + 1) * PAGE_SIZE);
    }, [ assets, page, searchText ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === sortedFilteredAssets?.length) {
            setPage(page + 1);
        }
    }, [ page, sortedFilteredAssets?.length ]);

    useEffect(() => {
        if (loading || !assets?.length) {
            return;
        }
        const poolIds = sortedFilteredAssets
            ?.filter((asset) => !toCalculatePriceWithMarketCap(asset))
            .map((asset) => asset.pools[0]?.id.toString())
            .filter((poolId) => !assetsPoolsAnalyticsState?.analyticsMap?.[poolId] && !assetsPoolsAnalyticsState?.loadingMap?.[poolId]);

        if (!poolIds?.length) {
            return;
        }
        assetsPoolsAnalyticsStateDispatch({ type: 'set-loading', payload: { ids: poolIds } });
        loadPoolAnalyticsMap<keyof PoolAnalytics>(poolIds, { liquidity: [ 'month' ] })
            .then((analytics) => assetsPoolsAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => assetsPoolsAnalyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [
        loading,
        assets?.length,
        assetsPoolsAnalyticsState?.analyticsMap,
        assetsPoolsAnalyticsState?.loadingMap,
        sortedFilteredAssets,
        toCalculatePriceWithMarketCap,
    ]);

    useEffect(() => {
        const networkIds = sortedFilteredAssets
            ?.filter(toCalculatePriceWithMarketCap)
            .map((asset) => asset.networkId)
            .filter((networkId) =>
                !assetsNetworksAnalyticsState?.analyticsMap?.[networkId] && !assetsNetworksAnalyticsState?.loadingMap?.[networkId]);

        if (!networkIds?.length) {
            return;
        }
        assetsNetworksAnalyticsStateDispatch({ type: 'set-loading', payload: { ids: networkIds } });
        loadNetworkAnalyticsMap<keyof NetworksAnalytics>(networkIds, { totalSupply: [ 'month' ] })
            .then((analytics) => assetsNetworksAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => assetsNetworksAnalyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [
        assetsNetworksAnalyticsState?.analyticsMap,
        assetsNetworksAnalyticsState?.loadingMap,
        sortedFilteredAssets,
        toCalculatePriceWithMarketCap,
    ]);

    return (
        <AssetListContext.Provider
            value={{
                sortedFilteredAssets,
                assetsPoolsAnalyticsState,
                assetsNetworksAnalyticsState,
                searchText,
                loadMore,
                setSearchText,
            }}
        >
            {children}
        </AssetListContext.Provider>
    );
};
