/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Duration } from "../google/protobuf/duration";

export const protobufPackage = "dymensionxyz.dymension.dymns";

/** Params defines the parameters for the module. */
export interface Params {
  /** price defines setting for pricing of Dym-Name and price-related parameters. */
  price:
    | PriceParams
    | undefined;
  /** chains defines setting for prioritized aliases mapping. */
  chains:
    | ChainsParams
    | undefined;
  /** misc is group of miscellaneous parameters. */
  misc: MiscParams | undefined;
}

/** PriceParams defines the pricing of Dym-Name and price-related parameters. */
export interface PriceParams {
  /**
   * name_price_steps holds the price steps configuration for Dym-Name registration, apply to the first year.
   * The price of Dym-Name is calculated based on the number of letters.
   * The first element is the price of 1 letter Dym-Name, the last element is the price of N+ letters Dym-Name.
   * Minimum steps count allowed is 4, for 1/2/3/4+ letters Dym-Name.
   */
  namePriceSteps: string[];
  /**
   * alias_price_steps holds the price steps configuration for Alias registration, one off payment.
   * The price of Alias is calculated based on the number of letters.
   * The first element is the price of 1 letter Alias, the last element is the price of N+ letters Alias.
   * Minimum steps count allowed is 4, for 1/2/3/4+ letters Alias.
   */
  aliasPriceSteps: string[];
  /** price_extends is used to extends Dym-Name yearly, after the one-off payment for the first year. */
  priceExtends: string;
  /** price_denom is the required denomination of the pricing setup and trading policy. */
  priceDenom: string;
  /**
   * min_offer_price is minimum price allowed to place an offer.
   * Mostly used to prevent spamming and abusing store with low price offers,
   * so the value should not be so low.
   */
  minOfferPrice: string;
}

/** ChainsParams defines setting for prioritized aliases mapping. */
export interface ChainsParams {
  /**
   * aliases_of_chain_ids is set of chain-ids and their corresponding aliases,
   * used for UX improvement like we can do my-name@cosmos instead of my-name@cosmoshub-4.
   *
   * This list is prioritized over Roll-App aliases
   * the reason is to allow the community able to have control to fixes the potential problems with the aliases.
   */
  aliasesOfChainIds: AliasesOfChainId[];
}

/** AliasesOfChainId defines the multiple-aliases of a chain id. */
export interface AliasesOfChainId {
  /** chain_id which owned the aliases. */
  chainId: string;
  /**
   * aliases is a set of aliases of the chain id for UX improvement,
   * like we can do my-name@cosmos instead of my-name@cosmoshub-4
   */
  aliases: string[];
}

/** MiscParams defines group of miscellaneous parameters. */
export interface MiscParams {
  /** end_epoch_hook_identifier is the identifier of the end epoch hook. */
  endEpochHookIdentifier: string;
  /**
   * grace_period_duration is the amount of time that the former owner of an expired Dym-Name
   * can renew it before completely lost.
   */
  gracePeriodDuration:
    | Duration
    | undefined;
  /** sell_order_duration is the amount of time of a Sell-Order from created to expired. */
  sellOrderDuration:
    | Duration
    | undefined;
  /**
   * enable_trading_name is the flag to enable trading of Dym-Name.
   * To be used to stop trading of Dym-Name when needed.
   */
  enableTradingName: boolean;
  /**
   * enable_trading_alias is the flag to enable trading of Alias.
   * To be used in the future when Alias trading implementation is ready
   * or disable trading of Alias when needed.
   */
  enableTradingAlias: boolean;
}

function createBaseParams(): Params {
  return { price: undefined, chains: undefined, misc: undefined };
}

export const Params = {
  encode(message: Params, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.price !== undefined) {
      PriceParams.encode(message.price, writer.uint32(10).fork()).ldelim();
    }
    if (message.chains !== undefined) {
      ChainsParams.encode(message.chains, writer.uint32(18).fork()).ldelim();
    }
    if (message.misc !== undefined) {
      MiscParams.encode(message.misc, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Params {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.price = PriceParams.decode(reader, reader.uint32());
          break;
        case 2:
          message.chains = ChainsParams.decode(reader, reader.uint32());
          break;
        case 3:
          message.misc = MiscParams.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Params {
    return {
      price: isSet(object.price) ? PriceParams.fromJSON(object.price) : undefined,
      chains: isSet(object.chains) ? ChainsParams.fromJSON(object.chains) : undefined,
      misc: isSet(object.misc) ? MiscParams.fromJSON(object.misc) : undefined,
    };
  },

  toJSON(message: Params): unknown {
    const obj: any = {};
    message.price !== undefined && (obj.price = message.price ? PriceParams.toJSON(message.price) : undefined);
    message.chains !== undefined && (obj.chains = message.chains ? ChainsParams.toJSON(message.chains) : undefined);
    message.misc !== undefined && (obj.misc = message.misc ? MiscParams.toJSON(message.misc) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Params>, I>>(object: I): Params {
    const message = createBaseParams();
    message.price = (object.price !== undefined && object.price !== null)
      ? PriceParams.fromPartial(object.price)
      : undefined;
    message.chains = (object.chains !== undefined && object.chains !== null)
      ? ChainsParams.fromPartial(object.chains)
      : undefined;
    message.misc = (object.misc !== undefined && object.misc !== null)
      ? MiscParams.fromPartial(object.misc)
      : undefined;
    return message;
  },
};

function createBasePriceParams(): PriceParams {
  return { namePriceSteps: [], aliasPriceSteps: [], priceExtends: "", priceDenom: "", minOfferPrice: "" };
}

export const PriceParams = {
  encode(message: PriceParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.namePriceSteps) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.aliasPriceSteps) {
      writer.uint32(18).string(v!);
    }
    if (message.priceExtends !== "") {
      writer.uint32(26).string(message.priceExtends);
    }
    if (message.priceDenom !== "") {
      writer.uint32(34).string(message.priceDenom);
    }
    if (message.minOfferPrice !== "") {
      writer.uint32(42).string(message.minOfferPrice);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PriceParams {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePriceParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.namePriceSteps.push(reader.string());
          break;
        case 2:
          message.aliasPriceSteps.push(reader.string());
          break;
        case 3:
          message.priceExtends = reader.string();
          break;
        case 4:
          message.priceDenom = reader.string();
          break;
        case 5:
          message.minOfferPrice = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PriceParams {
    return {
      namePriceSteps: Array.isArray(object?.namePriceSteps) ? object.namePriceSteps.map((e: any) => String(e)) : [],
      aliasPriceSteps: Array.isArray(object?.aliasPriceSteps) ? object.aliasPriceSteps.map((e: any) => String(e)) : [],
      priceExtends: isSet(object.priceExtends) ? String(object.priceExtends) : "",
      priceDenom: isSet(object.priceDenom) ? String(object.priceDenom) : "",
      minOfferPrice: isSet(object.minOfferPrice) ? String(object.minOfferPrice) : "",
    };
  },

  toJSON(message: PriceParams): unknown {
    const obj: any = {};
    if (message.namePriceSteps) {
      obj.namePriceSteps = message.namePriceSteps.map((e) => e);
    } else {
      obj.namePriceSteps = [];
    }
    if (message.aliasPriceSteps) {
      obj.aliasPriceSteps = message.aliasPriceSteps.map((e) => e);
    } else {
      obj.aliasPriceSteps = [];
    }
    message.priceExtends !== undefined && (obj.priceExtends = message.priceExtends);
    message.priceDenom !== undefined && (obj.priceDenom = message.priceDenom);
    message.minOfferPrice !== undefined && (obj.minOfferPrice = message.minOfferPrice);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PriceParams>, I>>(object: I): PriceParams {
    const message = createBasePriceParams();
    message.namePriceSteps = object.namePriceSteps?.map((e) => e) || [];
    message.aliasPriceSteps = object.aliasPriceSteps?.map((e) => e) || [];
    message.priceExtends = object.priceExtends ?? "";
    message.priceDenom = object.priceDenom ?? "";
    message.minOfferPrice = object.minOfferPrice ?? "";
    return message;
  },
};

function createBaseChainsParams(): ChainsParams {
  return { aliasesOfChainIds: [] };
}

export const ChainsParams = {
  encode(message: ChainsParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aliasesOfChainIds) {
      AliasesOfChainId.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChainsParams {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChainsParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.aliasesOfChainIds.push(AliasesOfChainId.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChainsParams {
    return {
      aliasesOfChainIds: Array.isArray(object?.aliasesOfChainIds)
        ? object.aliasesOfChainIds.map((e: any) => AliasesOfChainId.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ChainsParams): unknown {
    const obj: any = {};
    if (message.aliasesOfChainIds) {
      obj.aliasesOfChainIds = message.aliasesOfChainIds.map((e) => e ? AliasesOfChainId.toJSON(e) : undefined);
    } else {
      obj.aliasesOfChainIds = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ChainsParams>, I>>(object: I): ChainsParams {
    const message = createBaseChainsParams();
    message.aliasesOfChainIds = object.aliasesOfChainIds?.map((e) => AliasesOfChainId.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAliasesOfChainId(): AliasesOfChainId {
  return { chainId: "", aliases: [] };
}

export const AliasesOfChainId = {
  encode(message: AliasesOfChainId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainId !== "") {
      writer.uint32(10).string(message.chainId);
    }
    for (const v of message.aliases) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AliasesOfChainId {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAliasesOfChainId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainId = reader.string();
          break;
        case 2:
          message.aliases.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AliasesOfChainId {
    return {
      chainId: isSet(object.chainId) ? String(object.chainId) : "",
      aliases: Array.isArray(object?.aliases) ? object.aliases.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: AliasesOfChainId): unknown {
    const obj: any = {};
    message.chainId !== undefined && (obj.chainId = message.chainId);
    if (message.aliases) {
      obj.aliases = message.aliases.map((e) => e);
    } else {
      obj.aliases = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AliasesOfChainId>, I>>(object: I): AliasesOfChainId {
    const message = createBaseAliasesOfChainId();
    message.chainId = object.chainId ?? "";
    message.aliases = object.aliases?.map((e) => e) || [];
    return message;
  },
};

function createBaseMiscParams(): MiscParams {
  return {
    endEpochHookIdentifier: "",
    gracePeriodDuration: undefined,
    sellOrderDuration: undefined,
    enableTradingName: false,
    enableTradingAlias: false,
  };
}

export const MiscParams = {
  encode(message: MiscParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.endEpochHookIdentifier !== "") {
      writer.uint32(10).string(message.endEpochHookIdentifier);
    }
    if (message.gracePeriodDuration !== undefined) {
      Duration.encode(message.gracePeriodDuration, writer.uint32(18).fork()).ldelim();
    }
    if (message.sellOrderDuration !== undefined) {
      Duration.encode(message.sellOrderDuration, writer.uint32(26).fork()).ldelim();
    }
    if (message.enableTradingName === true) {
      writer.uint32(32).bool(message.enableTradingName);
    }
    if (message.enableTradingAlias === true) {
      writer.uint32(40).bool(message.enableTradingAlias);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MiscParams {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMiscParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.endEpochHookIdentifier = reader.string();
          break;
        case 2:
          message.gracePeriodDuration = Duration.decode(reader, reader.uint32());
          break;
        case 3:
          message.sellOrderDuration = Duration.decode(reader, reader.uint32());
          break;
        case 4:
          message.enableTradingName = reader.bool();
          break;
        case 5:
          message.enableTradingAlias = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MiscParams {
    return {
      endEpochHookIdentifier: isSet(object.endEpochHookIdentifier) ? String(object.endEpochHookIdentifier) : "",
      gracePeriodDuration: isSet(object.gracePeriodDuration)
        ? Duration.fromJSON(object.gracePeriodDuration)
        : undefined,
      sellOrderDuration: isSet(object.sellOrderDuration) ? Duration.fromJSON(object.sellOrderDuration) : undefined,
      enableTradingName: isSet(object.enableTradingName) ? Boolean(object.enableTradingName) : false,
      enableTradingAlias: isSet(object.enableTradingAlias) ? Boolean(object.enableTradingAlias) : false,
    };
  },

  toJSON(message: MiscParams): unknown {
    const obj: any = {};
    message.endEpochHookIdentifier !== undefined && (obj.endEpochHookIdentifier = message.endEpochHookIdentifier);
    message.gracePeriodDuration !== undefined && (obj.gracePeriodDuration = message.gracePeriodDuration
      ? Duration.toJSON(message.gracePeriodDuration)
      : undefined);
    message.sellOrderDuration !== undefined
      && (obj.sellOrderDuration = message.sellOrderDuration ? Duration.toJSON(message.sellOrderDuration) : undefined);
    message.enableTradingName !== undefined && (obj.enableTradingName = message.enableTradingName);
    message.enableTradingAlias !== undefined && (obj.enableTradingAlias = message.enableTradingAlias);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MiscParams>, I>>(object: I): MiscParams {
    const message = createBaseMiscParams();
    message.endEpochHookIdentifier = object.endEpochHookIdentifier ?? "";
    message.gracePeriodDuration = (object.gracePeriodDuration !== undefined && object.gracePeriodDuration !== null)
      ? Duration.fromPartial(object.gracePeriodDuration)
      : undefined;
    message.sellOrderDuration = (object.sellOrderDuration !== undefined && object.sellOrderDuration !== null)
      ? Duration.fromPartial(object.sellOrderDuration)
      : undefined;
    message.enableTradingName = object.enableTradingName ?? false;
    message.enableTradingAlias = object.enableTradingAlias ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
