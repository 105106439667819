import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo } from 'react';
import { IbcTransferDetails } from '../../ibc-transfer/ibc-status/ibc-status-types';
import { TxValue, useTx } from '../../tx/use-tx';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useHubNetworkState } from '../hub-network-state-context';
import { createClaimMessage } from './claimable-tokens-service';

interface UseClaimableTokensValue extends Omit<TxValue, 'calculateFee' | 'clearFee' | 'txStateDispatch' | 'setGasPrice'> {
    finalizedTransfers: IbcTransferDetails[];
}

export const useClaimableTokens = (transfers?: IbcTransferDetails[]): UseClaimableTokensValue => {
    const networkState = useHubNetworkState();
    const { handleClientError } = useClient();
    const { handleWalletError } = useWallet();

    const finalizedTransfers = useMemo(() => (transfers || []).filter((transfer) => transfer.baseStatus === 'Claimable'), [ transfers ]);

    const claimMessagesCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !finalizedTransfers.length) {
            return [];
        }
        return finalizedTransfers.map((transfer) => createClaimMessage(networkState.address!, transfer));
    }, [ networkState.address, finalizedTransfers ]);

    const { txState, calculateFee, clearFee, broadcast } = useTx({
        networkState: networkState,
        txMessagesCreator: claimMessagesCreator,
    });

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => {
        if (networkState.address && finalizedTransfers.length) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, networkState.address, finalizedTransfers.length ]);

    return { txState, finalizedTransfers, broadcast };
};
