import React, { useMemo } from 'react';
import { App } from '../../client/station-clients/dymension/generated/rollapp/app';
import { useNetworkDashboard } from '../network-dashboard-context';
import './network-apps.scss';

const NetworkApps: React.FC = () => {
    const { network } = useNetworkDashboard();

    const apps = useMemo((): App[] => {
        if (network.apps?.length) {
            return network.apps.sort((app1, app2) => app1.order - app2.order);
        }
        if (!network.website) {
            return [];
        }
        return [
            {
                id: -1,
                name: network.chainName,
                url: network.website || '',
                description: network.description || network.shortDescription || network.website || '',
                order: 0,
                imageUrl: network.logo,
                rollappId: network.chainId,
            },
        ];
    }, [ network.apps, network.chainId, network.chainName, network.description, network.logo, network.shortDescription, network.website ]);

    return (
        <div className='network-apps'>
            {!apps.length ? <div className='section no-data'>No Apps</div> : undefined}
            {apps.map(((app, appIndex) => (
                <div key={appIndex} className='section small app-card' onClick={() => window.open(app.url, '_blank')}>
                    <img src={app.imageUrl} alt='network app' className='app-image' />
                    <div className='app-content'>
                        <h5 className='app-name'>{app.name}</h5>
                        <div className='app-description'>{app.description || app.url}</div>
                    </div>
                </div>
            )))}
        </div>
    );
};

export default NetworkApps;
