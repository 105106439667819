import React from 'react';
import classNames from 'classnames';
import { getShortDateTimeString } from '../../../../../shared/utils/date-utils';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import ChartDataValue from '../chart-data-value/chart-data-value';
import { Currency } from '../../../../currency/currency-types';
import './chart-tooltip.scss';

interface ChartTooltipProps extends Partial<TooltipProps<string | number | (string | number)[], string | number>> {
    currency?: Currency;
    dataValueClassName?: string;
    formatValueOptions?: Intl.NumberFormatOptions;
    formatLabel?: (value: number) => string;
    formatValue?: (value: number, options?: Intl.NumberFormatOptions) => string;
    formatDataKey?: (dataKey: string | number | undefined) => string;
}

export const ChartTooltip: React.FC<ChartTooltipProps> = ({
    label,
    payload,
    currency,
    formatValueOptions,
    formatValue,
    formatLabel,
    formatDataKey,
    wrapperClassName,
    dataValueClassName,
}) => {
    return (
        <div className={classNames('chart-tooltip', wrapperClassName)}>
            <h6 className='tooltip-label'>
                {formatLabel?.(label) || getShortDateTimeString(label, true)}
            </h6>
            {payload?.map(({ value, dataKey }) =>
                <ChartDataValue
                    value={Number(value) || 0}
                    key={dataKey}
                    type={formatDataKey?.(dataKey) || dataKey?.toString()}
                    formatValueOptions={formatValueOptions}
                    formatValue={formatValue}
                    hideIndicator={payload?.length <= 1}
                    currency={currency}
                    className={classNames('chart-tooltip-data-value', dataValueClassName)}
                />)}
        </div>
    );
};

export default ChartTooltip;
