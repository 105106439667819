import { Decimal } from 'cosmjs/packages/math';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Alert from '../../../../../../../../shared/components/alert/alert';
import Confirm from '../../../../../../../../shared/components/confirm/confirm';
import Link from '../../../../../../../../shared/components/link/link';
import Table, { TableColumn, TableRow } from '../../../../../../../../shared/components/table/table';
import TabBar, { Tab } from '../../../../../../../../shared/components/tabs/tab-bar';
import useScrollPosition from '../../../../../../../../shared/hooks/use-scroll-position';
import { OrderDirection } from '../../../../../../../../shared/types';
import { formatNumber } from '../../../../../../../../shared/utils/number-utils';
import { convertToBech32Address, isValidBech32Address } from '../../../../../../../wallet/wallet-service';
import { useCreateRollapp } from '../../../../create-rollapp-context';
import Button from '../../../../../../../../shared/components/button/button';
import Dialog, { DialogAction, DialogContent } from '../../../../../../../../shared/components/dialog/dialog';
import { ReactComponent as ClearIcon } from '../../../../../../../../assets/icons/clear.svg';
import Input from '../../../../../../../../shared/components/form-controls/input/input';
import { useSnackbar } from '../../../../../../../../shared/components/snackbar/snackbar-context';
import { getFormattedLocalDateTime } from '../../../../../../../../shared/utils/date-utils';
import { Account, DEFAULT_DENOM_EXPONENT, MAX_ACCOUNTS, MAX_HUB_ACCOUNTS } from '../../../../types';
import './edit-accounts-dialog.scss';

const PAGE_SIZE = 50;

interface EditAccountsDialogProps {
    onRequestClose?: () => void;
}

const ACCOUNT_TYPES = [ 'Regular', 'Vesting', 'Dymension' ] as const;
type AccountType = typeof ACCOUNT_TYPES[number];

const EditAccountsDialog: React.FC<EditAccountsDialogProps> = ({ onRequestClose }) => {
    const { showSuccessMessage, showErrorMessage } = useSnackbar();
    const {
        rollapp,
        supplyAllocationAmounts,
        accounts,
        updateAccounts,
        setShouldGenerateGenesis,
    } = useCreateRollapp();
    const [ updatedAccounts, setUpdatedAccounts ] = useState<Account[]>(accounts.map((account) => ({ ...account })));
    const [ showErrors, setShowErrors ] = useState(false);
    const [ dirty, setDirty ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ page, setPage ] = useState(0);
    const [ confirmDialogOpen, setConfirmDialogOpen ] = useState(false);
    const [ invalidAccountAddressesMap, setInvalidAccountAddressesMap ] = useState<{ [address: string]: boolean }>({});
    const [ duplicatedAccountAddressesMap, setDuplicatedAccountAddressesMap ] = useState<{ [address: string]: number }>({});
    const [ typeFilter, setTypeFilter ] = useState<AccountType>('Regular');
    const [ clearAccountsModalVisible, setClearAccountsModalVisible ] = useState(false);
    const [ searchText, setSearchText ] = useState('');
    const [ amountOrderDirection, setAmountOrderDirection ] = useState<OrderDirection>();
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const { canLoadMore } = useScrollPosition(tableContainerRef.current || undefined);

    useEffect(() => {
        if (tableContainerRef.current && canLoadMore) {
            setPage((page) => page + 1);
        }
    }, [ canLoadMore ]);

    useEffect(() => {
        setPage(0);
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
    }, [ searchText, typeFilter ]);

    const regularAccounts = useMemo(() => updatedAccounts.filter((account) => !account.vesting && !account.hub), [ updatedAccounts ]);

    const vestingAccounts = useMemo(() => updatedAccounts.filter((account) => account.vesting), [ updatedAccounts ]);

    const hubAccounts = useMemo(() => updatedAccounts.filter((account) => account.hub), [ updatedAccounts ]);

    const filteredAccounts = useMemo(() => {
        let accounts = [ ...(typeFilter === 'Regular' ? regularAccounts : typeFilter === 'Dymension' ? hubAccounts : vestingAccounts) ];
        if (searchText) {
            const lowerCaseText = searchText.toLowerCase();
            accounts = accounts.filter((account) =>
                account.address.toLowerCase().includes(lowerCaseText) || account.bech32Address?.includes(lowerCaseText));
        }
        if (amountOrderDirection) {
            accounts.sort((account1, account2) => Number((account1.amount - account2.amount)) * (amountOrderDirection === 'asc' ? -1 : 1));
        }
        return accounts.slice(0, (page + 1) * PAGE_SIZE);
    }, [ amountOrderDirection, hubAccounts, page, regularAccounts, searchText, typeFilter, vestingAccounts ]);

    useEffect(() => setDirty(true), [ updatedAccounts ]);

    const addNewAccount = useCallback(() => {
        setUpdatedAccounts((updatedAccounts) => {
            const account = {} as Account;
            if (typeFilter === 'Vesting') {
                account.vesting = {} as Account['vesting'];
            } else if (typeFilter === 'Dymension') {
                account.hub = true;
            }
            return [ account, ...updatedAccounts ];
        });
    }, [ typeFilter ]);

    useEffect(() => {
        setTimeout(() => setDirty(false), 50);
    }, []);

    const onDeleteAccount = useCallback((account: Account) => {
        const accountIndex = updatedAccounts.indexOf(account);
        if (accountIndex >= 0) {
            updatedAccounts.splice(accountIndex, 1);
            setUpdatedAccounts([ ...updatedAccounts ]);
        }
    }, [ updatedAccounts ]);

    const onClearAccounts = useCallback(
        () => setUpdatedAccounts((accounts) => accounts.filter((account) =>
            typeFilter === 'Dymension' ? !account.hub : typeFilter === 'Vesting' ? !account.vesting : account.hub || account.vesting)),
        [ typeFilter ],
    );

    const save = useCallback(() => {
        const duplicatedAccountAddressesMap = updatedAccounts.reduce((current, account) => {
            if (account.bech32Address) {
                current[account.bech32Address] = (current[account.bech32Address] || 0) + 1;
            }
            return current;
        }, {} as { [address: string]: number });
        setDuplicatedAccountAddressesMap(duplicatedAccountAddressesMap);

        const invalidAccount = updatedAccounts.find((account) => !account.address || !account.amount ||
            invalidAccountAddressesMap[account.address] ||
            (duplicatedAccountAddressesMap[account.bech32Address || ''] | 0) > 1 ||
            (account.vesting &&
                (!account.vesting.start_time || !account.vesting.end_time || account.vesting.end_time < account.vesting.start_time)));
        if (invalidAccount) {
            showErrorMessage({
                content: (
                    <span className='nowrap'>
                        Account <b>{invalidAccount.address}</b> is&nbsp;
                        {(duplicatedAccountAddressesMap[invalidAccount.bech32Address || ''] | 0) <= 1 ?
                            'invalid' : 'duplicated or cannot be both Regular and Vesting'}
                    </span>
                ),
            });
            setShowErrors(true);
            return;
        }
        setSaving(true);
        updateAccounts(updatedAccounts).then(() => {
            showSuccessMessage({
                title: !rollapp.genesisChecksum ? undefined : 'Accounts successfully saved!',
                content: !rollapp.genesisChecksum ? 'Accounts successfully saved!' : <p>
                    Be sure to click <b>Update</b> after closing the dialog to save these changes to the genesis file, otherwise, your data will be lost.
                </p>,
                key: new Date().getTime(),
            });
            setShouldGenerateGenesis(true);
            setShowErrors(false);
            setDirty(false);
        }).catch((error) => {
            console.error(`Can't save accounts`, error);
            showErrorMessage(`Can't save accounts, please try again later`);
        }).finally(() => setSaving(false));
    }, [
        invalidAccountAddressesMap,
        rollapp.genesisChecksum,
        setShouldGenerateGenesis,
        showErrorMessage,
        showSuccessMessage,
        updateAccounts,
        updatedAccounts,
    ]);

    const updateClaimAddress = useCallback((account: Account, value: string) => {
        account.address = value;
        let invalidAddress = false;
        if (value) {
            if (!value.startsWith('0x')) {
                showErrorMessage(`The address must start with the '0x' prefix.`);
                invalidAddress = true;
            } else if (value.length !== 42) {
                showErrorMessage(`Address must be exactly 42 characters long (including '0x').`);
                invalidAddress = true;
            } else {
                const bech32Prefix = account.hub ? 'dym' : rollapp.bech32Prefix;
                try {
                    account.bech32Address = bech32Prefix && convertToBech32Address(account.address, bech32Prefix);
                } catch {
                    showErrorMessage(`Can't convert ${account.address} to bech32 address.`);
                    invalidAddress = true;
                }
                if (account.bech32Address && bech32Prefix && !isValidBech32Address(account.bech32Address, bech32Prefix)) {
                    showErrorMessage(`${account.address} converted to an invalid bech32 address.`);
                    invalidAddress = true;
                }
            }
        }
        setInvalidAccountAddressesMap({ ...invalidAccountAddressesMap, [account.address]: invalidAddress });
        setUpdatedAccounts([ ...updatedAccounts ]);
    }, [ rollapp.bech32Prefix, invalidAccountAddressesMap, updatedAccounts, showErrorMessage ]);

    const updateAccountAmount = useCallback((account: Account, value: string, previousValue: string) => {
        const amountPattern = new RegExp('^([0-9]+\\.?)?([0-9]{0,18})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        if (value === '') {
            account.amount = undefined as any;
            return;
        }
        try {
            account.amount = BigInt(Decimal.fromUserInput(value, DEFAULT_DENOM_EXPONENT).atomics);
            setUpdatedAccounts([ ...updatedAccounts ]);
        } catch (ignore) {}
    }, [ updatedAccounts ]);

    const updateAccountVesting = useCallback((account: Account, field: 'start_time' | 'end_time', time: string) => {
        if (account.vesting) {
            account.vesting[field] = new Date(time).getTime();
        }
        setUpdatedAccounts([ ...updatedAccounts ]);
    }, [ updatedAccounts ]);

    const onDialogClose = useCallback(() => {
        if (dirty) {
            setConfirmDialogOpen(true);
        } else {
            onRequestClose?.();
        }
    }, [ dirty, onRequestClose ]);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (dirty) {
                event.preventDefault();
                event.returnValue = 'You have unsaved changes. Are you sure you want to close?';
                return event.returnValue;
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [ dirty ]);

    const onAllocateRest = useCallback((account: Account) => {
        account.amount = updatedAccounts.reduce((current, currentAccount) => {
            if (account.bech32Address === currentAccount.bech32Address || !current) {
                return current;
            }
            return current < currentAccount.amount ? BigInt(0) : current - currentAccount.amount;
        }, supplyAllocationAmounts.accounts || BigInt(0));
        setUpdatedAccounts([ ...updatedAccounts ]);
    }, [ updatedAccounts, supplyAllocationAmounts.accounts ]);

    const renderAccountsHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Address</TableColumn>
                <TableColumn sortable orderDirection={amountOrderDirection} onSort={setAmountOrderDirection}>Amount</TableColumn>
                {typeFilter === 'Vesting' && <TableColumn>Vesting Start Time</TableColumn>}
                {typeFilter === 'Vesting' && <TableColumn>Vesting End Time</TableColumn>}
                <TableColumn align='right'></TableColumn>
            </TableRow>
        );
    };

    const renderAccountRow = (account: Account, accountIndex: number): ReactElement => {
        const addressError = showErrors && (!account.address ? 'missing' :
            invalidAccountAddressesMap[account.address] ? 'invalid' :
                Number(duplicatedAccountAddressesMap[account.bech32Address || '']) > 1 ? 'duplicate' : '');
        return (
            <TableRow key={accountIndex}>
                <TableColumn>
                    <Input
                        placeholder='e.g., 0xfaf3a8a...'
                        className='address-input'
                        value={account.address}
                        onTypeFinish={(value) => updateClaimAddress(account, value)}
                        error={addressError}
                        suffix={addressError && <span className='address-suffix-error'>{addressError}</span>}
                    />
                </TableColumn>
                <TableColumn>
                    <Input
                        placeholder='e.g., 150'
                        className='amount-input'
                        onValueChange={(value, previousValue) =>
                            updateAccountAmount(account, value, previousValue)}
                        value={account.amount === undefined ? '' :
                            Decimal.fromAtomics(account.amount.toString(), DEFAULT_DENOM_EXPONENT).toString()}
                        suffix={(
                            <Button
                                className='remaining-supply-button'
                                size='xs'
                                buttonType='secondary'
                                onClick={() => onAllocateRest(account)}
                            >
                                Remaining supply
                            </Button>
                        )}
                        error={showErrors && !account.amount ? 'Amount is required' : null}
                    />
                </TableColumn>
                {typeFilter === 'Vesting' && (
                    <TableColumn>
                        <Input
                            className='vesting-date-input'
                            type='datetime-local'
                            min={getFormattedLocalDateTime(new Date())}
                            value={account.vesting && getFormattedLocalDateTime(new Date(account.vesting.start_time))}
                            onValueChange={(value) => updateAccountVesting(account, 'start_time', value)}
                            error={showErrors && !account.vesting?.start_time && 'Missing vesting start time'}
                        />
                    </TableColumn>
                )}
                {typeFilter === 'Vesting' && (
                    <TableColumn>
                        <Input
                            type='datetime-local'
                            className='vesting-date-input'
                            min={account.vesting && getFormattedLocalDateTime(new Date(account.vesting.start_time))}
                            value={account.vesting && getFormattedLocalDateTime(new Date(account.vesting.end_time))}
                            onValueChange={(value) => updateAccountVesting(account, 'end_time', value)}
                            error={showErrors && (!account.vesting?.end_time ? 'Missing vesting end time' :
                                (account.vesting.start_time >= account.vesting.end_time ? 'End time must be after start time' : ''))}
                        />
                    </TableColumn>
                )}
                <TableColumn align='right' className='delete-action-column'>
                    <Button
                        className='delete-account-button'
                        size='small'
                        buttonType='icon'
                        onClick={() => onDeleteAccount(account)}
                    >
                        <ClearIcon />
                    </Button>
                </TableColumn>
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (!filteredAccounts?.length) {
            return <div className='no-data'>No Accounts</div>;
        }
    };

    return <>
        <Dialog className='edit-accounts-dialog' onRequestClose={onDialogClose}>
            <DialogContent>
                <TabBar
                    className='accounts-type-tab-bar'
                    uniformTabWidth
                    size='large'
                    activeTabKey={typeFilter}
                    onTabChange={(type) => setTypeFilter(type as AccountType)}
                >
                    {ACCOUNT_TYPES.map((type) => {
                        const size = formatNumber(type === 'Regular' ? regularAccounts.length :
                            type === 'Dymension' ? hubAccounts.length : vestingAccounts.length);
                        return <Tab key={type} label={<>{type} ({size})</>} tabKey={type} />;
                    })}
                </TabBar>
                <h5 className='accounts-table-header'>
                    <Link
                        disabled={saving}
                        size='small'
                        className='clear-accounts-action'
                        onClick={() => setClearAccountsModalVisible(true)}
                    >
                        Clear {typeFilter} Accounts
                    </Link>
                    <span className='space' />
                    <Input
                        controlSize='medium'
                        type='search'
                        className='search-account-input'
                        value={searchText}
                        onValueChange={setSearchText}
                        placeholder={`Search ${typeFilter} Accounts...`}
                    />
                </h5>
                <div className='accounts-table-container' ref={tableContainerRef}>
                    <Table className='accounts-table' indexColumn bottomBar={renderBottomBar()}>
                        {renderAccountsHeaderRow()}
                        {filteredAccounts.map(renderAccountRow)}
                    </Table>
                </div>
                {rollapp.genesisChecksum && (
                    <Alert type='warning' className='update-warning'>
                        Be sure to click <b>Update</b> after closing the dialog to save these changes to the genesis file, otherwise, your data will be lost.
                    </Alert>
                )}
            </DialogContent>
            <DialogAction
                tooltip={updatedAccounts.length >= MAX_ACCOUNTS ? 'Reached the maximum account limit' :
                    typeFilter === 'Dymension' && hubAccounts.length >= MAX_HUB_ACCOUNTS ? 'Reached the maximum hub account limit' : ''}
                disabled={saving ||
                    updatedAccounts.length >= MAX_ACCOUNTS || (typeFilter === 'Dymension' && hubAccounts.length >= MAX_HUB_ACCOUNTS)}
                secondary
                className='add-account-action'
                onClick={addNewAccount}
            >
                Add Account
            </DialogAction>
            <DialogAction close disabled={saving}>Close</DialogAction>
            <DialogAction primary onClick={save} disabled={saving} loading={saving}>Save</DialogAction>
        </Dialog>
        {confirmDialogOpen &&
            <Confirm
                onConfirm={onRequestClose}
                title='Leave without saving?'
                content='You have unsaved changes. Are you sure you want to close?'
                onCancel={() => setConfirmDialogOpen(false)}
                onRequestClose={() => setConfirmDialogOpen(false)}
                okLabel='Ok'
                cancelLabel='Cancel'
            />}
        {clearAccountsModalVisible &&
            <Confirm
                closable={false}
                warning
                title={`Clear ${typeFilter} Accounts`}
                content={<>Are you sure you want to remove all <u>{typeFilter}</u> accounts?</>}
                okLabel='Confirm'
                cancelLabel='Cancel'
                onConfirm={onClearAccounts}
                onRequestClose={() => setClearAccountsModalVisible(false)}
                onCancel={() => setClearAccountsModalVisible(false)}
            />}
    </>;
};

export default EditAccountsDialog;
