import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Input from '../../../../shared/components/form-controls/input/input';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import { Option } from '../../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../../shared/components/form-controls/select/select';
import ToggleSwitch from '../../../../shared/components/toggle-switch/toggle-switch';
import { getFormattedLocalDateTime } from '../../../../shared/utils/date-utils';
import { DistrRecord } from '../../../client/station-clients/dymension/generated/streamer/distr_info';
import { CreateStreamProposal } from '../../../client/station-clients/dymension/generated/streamer/gov_stream';
import { EpochIdentifier } from '../../../incentives/types';
import './proposal-type.scss';

interface CreateStreamProps {
    content?: CreateStreamProposal;
    onInit?: (data: CreateStreamProposal) => void;
    onChange?: (data: Partial<CreateStreamProposal>, isValid: boolean) => void;
}

const MAX_COINS = 10;
const MAX_GAUGES = 50;
const SUPPORTED_EPOCH_IDENTIFIERS: EpochIdentifier[] = [ 'minute', 'hour', 'day', 'week' ];

const CreateStream: React.FC<CreateStreamProps> = ({ content, onInit, onChange }) => {
    const [ distributeToRecords, setDistributeToRecords ] = useState<DistrRecord[]>([]);

    const isValid = useMemo(
        () => Boolean(
            content?.numEpochsPaidOver &&
            content?.coins?.length && content.coins.every(({ denom, amount }) => denom && amount) &&
            (content.sponsored || (content?.distributeToRecords?.length &&
                content.distributeToRecords.every(({ gaugeId, weight }) => gaugeId && weight))),
        ),
        [ content ],
    );

    useEffect(() => onInit?.({
        title: '',
        description: '',
        distributeToRecords: [ { gaugeId: undefined as any, weight: '' } ],
        coins: [ { denom: '', amount: '' } ],
        distrEpochIdentifier: '',
        numEpochsPaidOver: undefined as any,
        startTime: undefined,
        sponsored: false,
    }), [ onInit ]);

    useEffect(() => {
        if (content?.distributeToRecords?.length) {
            setDistributeToRecords(content.distributeToRecords);
        }
    }, [ content?.distributeToRecords ]);

    useEffect(() => onChange?.({}, isValid), [ isValid, onChange ]);

    const currentStartTime = useMemo(() => content?.startTime && getFormattedLocalDateTime(content.startTime), [ content?.startTime ]);

    const updateDistributeToRecord = useCallback((record: DistrRecord, key: keyof DistrRecord, value: any) => {
        (record[key] as any) = value as any;
        if (content) {
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const addDistributeToRecord = useCallback(() => {
        if (content) {
            content.distributeToRecords.push({ gaugeId: undefined as any, weight: '' });
            onChange?.({ ...content }, false);
        }
    }, [ content, onChange ]);

    const onRemoveDistributeToRecord = useCallback((index: number) => {
        if (content) {
            content.distributeToRecords.splice(index, 1);
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const updateCoin = useCallback((record: Coin, key: keyof Coin, value: string) => {
        record[key] = value;
        if (content) {
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const addCoin = useCallback(() => {
        if (content) {
            content.coins.push({ denom: '', amount: '' });
            onChange?.({ ...content }, false);
        }
    }, [ content, onChange ]);

    const onRemoveCoin = useCallback((index: number) => {
        if (content) {
            content.coins.splice(index, 1);
            onChange?.({ ...content }, isValid);
        }
    }, [ content, isValid, onChange ]);

    const renderCoinsSection = (): ReactElement => {
        return (
            <div className='proposal-content-section'>
                <label className='proposal-control-label'>Coins</label>
                {content?.coins?.map((coin, coinIndex) => (
                    <div className='proposal-change-item' key={coinIndex}>
                        <label className='proposal-change-label'>Denom</label>
                        <Input
                            value={coin.denom}
                            onTypeFinish={(value) => updateCoin(coin, 'denom', value?.toString() || '')}
                        />
                        <label className='proposal-change-label'>Amount</label>
                        <Input
                            value={coin.amount}
                            type='number'
                            onTypeFinish={(value) => updateCoin(coin, 'amount', value?.toString() || '')}
                        />
                        <Button
                            disabled={content?.coins.length <= 1}
                            className='proposal-remove-item-button'
                            buttonType='icon'
                            onClick={() => onRemoveCoin(coinIndex)}
                        >
                            <ClearIcon />
                        </Button>
                    </div>
                ))}
                <Button
                    className='proposal-add-item-button'
                    buttonType='secondary'
                    disabled={(content?.coins?.length || 0) >= MAX_COINS}
                    onClick={addCoin}
                >
                    Add Coin
                </Button>
            </div>
        );
    };

    const renderDistributeToSection = (): ReactElement => {
        return (
            <div className='proposal-content-section'>
                <label className='proposal-control-label'>Distribute To</label>
                {content?.distributeToRecords?.map((record, recordIndex) => (
                    <div className='proposal-change-item' key={recordIndex}>
                        <label className='proposal-change-label'>Gauge ID</label>
                        <Input
                            type='number'
                            value={record.gaugeId}
                            onTypeFinish={(value) => updateDistributeToRecord(record, 'gaugeId', !value ? undefined : Number(value))}
                        />
                        <label className='proposal-change-label'>Weight</label>
                        <Input
                            value={record.weight}
                            type='number'
                            onTypeFinish={(value) => updateDistributeToRecord(record, 'weight', value?.toString() || '')}
                        />
                        <Button
                            disabled={content.distributeToRecords.length <= 1}
                            className='proposal-remove-item-button'
                            buttonType='icon'
                            onClick={() => onRemoveDistributeToRecord(recordIndex)}
                        >
                            <ClearIcon />
                        </Button>
                    </div>
                ))}
                <Button
                    className='proposal-add-item-button'
                    buttonType='secondary'
                    disabled={(content?.distributeToRecords?.length || 0) >= MAX_GAUGES}
                    onClick={addDistributeToRecord}
                >
                    Add Gauge
                </Button>
            </div>
        );
    };

    return (
        <div className='proposal-type'>
            <label className='proposal-control-label'>Start Time</label>
            <Input
                type='datetime-local'
                min={getFormattedLocalDateTime(new Date())}
                value={currentStartTime}
                onValueChange={(value) => onChange?.({ startTime: new Date(value) }, isValid)}
            />

            <label className='proposal-control-label'>Epoch Identifier</label>
            <Select
                className='proposal-select-control'
                value={content?.distrEpochIdentifier}
                onSelect={(value) => onChange?.({ distrEpochIdentifier: value as EpochIdentifier }, isValid)}
            >
                {SUPPORTED_EPOCH_IDENTIFIERS.map((epoch) => <Option key={epoch} value={epoch}>{epoch}</Option>)}
            </Select>

            <label className='proposal-control-label'>Epochs Number</label>
            <Input
                type='number'
                value={content?.numEpochsPaidOver}
                onValueChange={(value) => onChange?.({ numEpochsPaidOver: !value ? undefined : Number(value) }, isValid)}
            />
            <label className='proposal-control-label'>Endorsement</label>
            <ToggleSwitch
                isChecked={content?.sponsored}
                onCheck={(checked) => onChange?.({ sponsored: checked, distributeToRecords: checked ? [] : distributeToRecords }, isValid)}
                containerClassName='sponsorship-switch'
            />
            {renderCoinsSection()}
            {!content?.sponsored && renderDistributeToSection()}
        </div>
    );
};

export default CreateStream;
