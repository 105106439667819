import React from 'react';
import Icon from '../../../../../shared/components/icon/icon';
import Property from '../../../../../shared/components/property/property';
import { ReactComponent as EthereumLogo } from '../../../../../assets/logos/ethereum-logo2.svg';
import { ReactComponent as CosmWasmLogo } from '../../../../../assets/logos/cosm-wasm-logo.svg';
import { useNetworkDashboard } from '../../../../network-dashboard/network-dashboard-context';
import './vm-property.scss';

const VmProperty: React.FC = () => {
    const { network } = useNetworkDashboard();

    return (
        <Property label='Virtual Machine' className='vm-property'>
            <Icon className='vm-logo' iconColorMode='original'>{network.evmType === 'EVM' ? <EthereumLogo /> : <CosmWasmLogo />}</Icon>
            {network.evmType}
        </Property>
    );
};

export default VmProperty;
