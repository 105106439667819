import { Decimal } from 'cosmjs/packages/math';
import { BondingCurve } from '../../../iro/types';
import { EvmType } from '../../../network/network-types';
import evmGenesisFile from './default-genesis-versions/evm-genesis.json';
import wasmGenesisFile from './default-genesis-versions/wasm-genesis.json';

export const ACCOUNT_CODE_HASH = '0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470';
export const DISTRIBUTION_MODULE_ADDRESS = '0x93354845030274CD4BF1686ABD60AB28EC52E1A7';
export const DISTRIBUTION_MODULE_NAME = 'distribution';
export const HUB_GENESIS_MODULE_ADDRESS = '0xF54EBEEF798CA51615C02D13888768F9960863F2';
export const GOV_MODULE_ADDRESS = '0x7B5FE22B5446F7C62EA27B8BD71CEF94E03F3DF2';
export const HUB_GENESIS_MODULE_NAME = 'hubgenesis';
export const IRO_HUB_ADDRESS = 'dym1cy0lmcn6cssewkqrs2q3t9sg98mwmrwywaqfym';
export const DISCORD_OPERATORS_LINK = 'https://discord.com/channels/956961633165529098/1280130206358700095';
export const OPERATE_DOCS_URL = 'https://docs.dymension.xyz/launch/operate/overview';
export const ROLLAPP_DYM_IBC = 'ibc/FECACB927EB3102CCCB240FFB3B6FCCEEB8D944C6FEA8DFF079650FEFF59781D';

export const UNBONDING_TINE = 1814400;
export const MAX_ACCOUNTS = 200000;
export const MAX_HUB_ACCOUNTS = 100;
export const MIN_ROLLAPP_ID_NUMBER = 10000;
export const LOGO_MAX_SIZE = 256;
export const DEFAULT_DENOM_EXPONENT = 18;
export const TOKEN_FACTORY_CREATION_FEE = 10;
export const MIN_TOKEN_SYMBOL_LENGTH = 3;
export const MIN_IRO_ALLOCATION = 10;
export const MAX_IRO_ALLOCATION = 10 ** 9;
export const VOTING_PERIOD = process.env.REACT_APP_ENV === 'mainnet' || process.env.REACT_APP_ENV === 'mainnet-staging' ? 432000 : 300;
export const DEFAULT_TOTAL_SUPPLY = BigInt(Decimal.fromUserInput('1000000000', DEFAULT_DENOM_EXPONENT).atomics);
export const REGISTRATION_FEE = '10';
export const DYM_REGISTRATION_FEE = '1';
export const MIN_DEPOSIT = '1000';
export const DYM_MIN_DEPOSIT = '10';
export const MIN_GAS_PRICE = '0.00000002';
export const DYM_MIN_GAS_PRICE = '0.00000000001';
export const MIN_SEQUENCER_BOND = '100';

export type CurveType = 'Fixed' | 'Linear' | 'Logarithmic' | 'Exponential';
export type CurveOption = { type: CurveType, title: string, info: string, exponent: number };
export const CURVE_OPTIONS: CurveOption[] = [
    { type: 'Fixed', title: 'Fixed Price', info: 'Keeps the token price constant throughout the IRO period.', exponent: 1 },
    { type: 'Linear', title: 'Linear Growth', info: 'Increases the price steadily as more tokens are sold.', exponent: 1 },
    {
        type: 'Logarithmic',
        title: 'Logarithmic Growth',
        info: 'Starts with a rapid increase that stabilizes over time, rewarding early buyers.',
        exponent: 0.5,
    },
    {
        type: 'Exponential',
        title: 'Exponential Growth',
        info: 'Raises the price at an accelerating rate, promoting momentum.',
        exponent: 1.5,
    },
];

export const CREATE_ROLLAPP_STEPS = [ 'Register Domain', 'Tokenomics', 'Launch IRO', 'Set Operator' ] as const;
export type CreateRollappStep = typeof CREATE_ROLLAPP_STEPS[number];

export type Distribution = { sequencer?: number; communityPool?: number; };
export type SupplyAllocation = { iro?: number; communityPool?: number; };
export type SupplyAllocationAmounts = { iro: bigint, communityPool: bigint, accounts: bigint, displayIro: string, displayCommunityPool: string, displayAccounts: string };
export type Token = { name: string; denom: string; tokenless?: boolean; dymFee?: boolean, initialInflationRate?: number; inflationRateChange?: number, minimumInflationRate?: number, tokenFactoryEnabled?: boolean };
export type IRO = {
    targetRaise?: number,
    startTime?: number,
    planDuration: number,
    startTimeAfterSettlement: number,
    incentivesDistributionTime: number,
    bondingCurve?: BondingCurve;
};

export type DataAvailability = 'Celestia';

export const MAX_DESCRIPTION_LENGTH = 512;
export const MAX_URL_LENGTH = 256;

export const DA_LOGO_MAP: { [da in DataAvailability]: string } = {
    Celestia: require('../../../../assets/logos/celestia-logo-dark.png'),
};

export interface Account {
    address: string;
    amount: bigint;
    hub?: boolean;
    bech32Address?: string;
    vesting?: {
        start_time: number
        end_time: number
    };
}

export const GENESIS_VERSION_MAP: { [type in EvmType]: Genesis } = {
    EVM: evmGenesisFile as Genesis,
    WASM: wasmGenesisFile as Genesis,
};

export type BaseAccount = {
    address: string;
    pub_key: null;
    account_number: string;
    sequence: string;
};

export type GenesisAccount = {
    '@type': '/ethermint.types.v1.EthAccount';
    base_account: BaseAccount;
    code_hash: string;
} | {
    '@type': '/cosmos.auth.v1beta1.ModuleAccount';
    base_account: BaseAccount;
    name: string;
    permissions: [];
} | {
    '@type': '/cosmos.auth.v1beta1.BaseAccount'
} & BaseAccount | {
    '@type': '/cosmos.vesting.v1beta1.ContinuousVestingAccount';
    base_vesting_account: {
        base_account: BaseAccount;
        original_vesting: { denom: string; amount: string; }[];
        delegated_free: [];
        delegated_vesting: [];
        end_time: number;
    };
    start_time: number;
}

export interface Genesis {
    app_state: {
        mint: {
            minter: {
                current_inflation_rate?: string;
            };
            params: {
                target_inflation_rate?: string;
                inflation_rate_change?: string;
                mint_denom?: string;
            };
        };
        distribution: {
            params: {
                community_tax?: string;
                base_proposer_reward?: string;
            };
            fee_pool: {
                community_pool: {
                    amount: string;
                    denom: string;
                }[];
            };
        };
        hubgenesis: {
            genesis_accounts: { address: string; amount: string; }[];
        };
        staking: {
            params: {
                bond_denom: string;
                unbonding_time: string;
            };
        };
        gov: {
            deposit_params: {
                min_deposit: { denom: string; amount: string; }[];
            };
            voting_params: {
                voting_period: string;
            };
        };
        bank: {
            denom_metadata?: {
                description: string;
                base: string;
                denom_units: { aliases: []; denom: string; exponent: number; }[];
                display: string;
                name: string;
                symbol: string;
            }[];
            balances: { address: string; coins: { denom: string; amount: string; }[]; }[];
            supply: { denom: string; amount: string; }[];
        };
        auth: {
            accounts: GenesisAccount[];
        };
        sequencers: {
            params: {
                unbonding_time: string;
            };
        };
        rollappparams: {
            params: {
                min_gas_prices: { denom: string, amount: string }[];
                da: string;
            };
        };
    };
}

export interface EvmGenesis extends Genesis {
    app_state: Genesis['app_state'] & {
        evm: {
            params: {
                evm_denom: string;
                gas_denom: string;
            };
        };
        erc20: {
            params: {
                registration_fee: string;
            };
        };
        feemarket: {
            params: {
                min_gas_price: string;
            };
        };
    };
}

export interface WasmGenesis extends Genesis {
    app_state: Genesis['app_state'] & {
        tokenfactory: {
            params: {
                denom_creation_fee: { denom: string, amount: string }[]
            },
            factory_denoms: { authority_metadata: { admin: string }, denom: string }[];
        };
        callback: {
            params: {
                min_price_of_gas: { denom: string, amount: string };
            };
        };
        cwerrors: {
            params: {
                subscription_fee: { denom: string, amount: string };
            };
        };
        gasless: {
            params: {
                minimum_gas_deposit: { denom: string, amount: string }[];
            };
        };
    };
}

