import React from 'react';
import classNames from 'classnames';
import TokenBurnsChart from '../../../hub/statistics/token-burns-chart';
import TotalTvlChart from '../../../network/statistics/charts/total-tvl-chart';
import IbcTransfersChart from '../../../network/statistics/charts/ibc-transfers-chart/ibc-transfers-chart';
import MarketCapChart from '../../../network/statistics/charts/market-cap-chart/market-cap-chart';
import { useNetworkDashboard } from '../../network-dashboard-context';
import './network-charts.scss';

interface NetworkChartsProps {
    className?: string;
}

export const NetworkCharts: React.FC<NetworkChartsProps> = ({ className }) => {
    const { network, analyticsState } = useNetworkDashboard();

    return (
        <div className={classNames('network-charts', className)}>
            <MarketCapChart className='network-chart-container' />
            <TotalTvlChart className='network-chart-container' network={network} state={analyticsState} />
            <TokenBurnsChart className='network-chart-container' analyticsState={analyticsState} />
            <IbcTransfersChart className='network-chart-container' />
        </div>
    );
};

export default NetworkCharts;
