import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { getFeeCurrency, getMinDenomAmount, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { AmountTxValue, useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { useIncentives } from '../incentives-context';
import { createCreateGaugeMessage } from '../incentives.service';
import './add-incentives-dialog.scss';

interface UseAddIncentivesValue extends Omit<AmountTxValue, 'setAmount' | 'calculateFee' | 'clearFee' | 'txStateDispatch'> {
    startTime: Date;
    endTime?: Date;
    setStartTime: (date: Date) => void;
    setEndTime: (date: Date) => void;
    incentiveCreationFee?: CoinsAmount;
}

const INCENTIVE_CREATION_AMOUNT_FEE = 1;

export const useAddIncentives = (denom: string): UseAddIncentivesValue => {
    const { hubWallet, handleWalletError } = useWallet();
    const { handleClientError } = useClient();
    const { incentivesState } = useIncentives();
    const networkState = useHubNetworkState();
    const [ startTime, setStartTime ] = useState<Date>(new Date());
    const [ endTime, setEndTime ] = useState<Date>();

    const addIncentivesMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        const balance = networkState.balances?.find((balance) => coins && isCoinsEquals(balance, coins));
        if (!coins || !balance || !incentivesState.params || !networkState.address || !endTime) {
            return [];
        }
        let baseAmountWithoutFee = undefined;
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.max(0, Math.min(coins.amount, balance.amount - fee.amount)) };
            baseAmountWithoutFee =
                balance.baseAmount ? balance.baseAmount - BigInt(getMinDenomAmount(fee.amount, fee.currency)) : undefined;
            baseAmountWithoutFee = baseAmountWithoutFee && baseAmountWithoutFee < BigInt(0) ? BigInt(0) : baseAmountWithoutFee;
        }
        return [
            createCreateGaugeMessage(
                networkState.address,
                coins,
                denom,
                startTime,
                endTime,
                incentivesState.params,
                baseAmountWithoutFee,
            ),
        ];
    }, [ incentivesState.params, networkState.address, networkState.balances, denom, startTime, endTime ]);

    const incentiveCreationFee = useMemo((): CoinsAmount | undefined => {
        const feeCurrency = networkState.network && getFeeCurrency(networkState.network);
        return feeCurrency &&
            { currency: feeCurrency, amount: INCENTIVE_CREATION_AMOUNT_FEE, networkId: networkState.network?.chainId || '' };
    }, [ networkState.network ]);

    const incentiveCreationFees = useMemo(() => incentiveCreationFee && [ incentiveCreationFee ], [ incentiveCreationFee ]);

    const { txState, amountTxState, setCoins, broadcast, calculateFee, clearFee } = useAmountTx({
        networkState,
        amountTxMessagesCreator: addIncentivesMessagesCreator,
        reduceFeeFromBalances: true,
        selectInitialCurrency: true,
        extraFees: incentiveCreationFees,
    });

    useEffect(() => {
        const epochIdentifier = incentivesState.params?.epochIdentifier;
        if (!epochIdentifier) {
            return;
        }
        const date = new Date();
        if (epochIdentifier === 'year' || epochIdentifier === 'yearly') {
            date.setFullYear(date.getFullYear() + 1);
        } else {
            date.setMonth(date.getMonth() + 1);
        }
        setEndTime(date);
    }, [ incentivesState.params?.epochIdentifier ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (hubWallet && networkState.network && amountTxState.coins?.currency && incentivesState.params) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ incentivesState.params, amountTxState.coins?.currency, calculateFee, clearFee, hubWallet, networkState.network ]);

    return { startTime, endTime, txState, amountTxState, incentiveCreationFee, setCoins, setStartTime, setEndTime, broadcast };
};
