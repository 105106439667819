import React from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../shared/components/dialog/dialog';
import FileUploader from '../../../shared/components/file-uploader/file-uploader';
import Input from '../../../shared/components/form-controls/input/input';
import { useAuthUser } from './auth-user-context';
import './save-user-dialog.scss';

const SaveUserDialog: React.FC<DialogProps> = (dialogProps) => {
    const { user, avatarLoading, userAvatar, userSaving, saveUser, setUserAvatar, setUser } = useAuthUser();

    return (
        <Dialog className='save-user-dialog' closable {...dialogProps}>
            <DialogTitle>Save User</DialogTitle>
            <DialogContent>
                <div className='control-container'>
                    <div className='control-label-container'><label>Display Name</label></div>
                    <Input
                        onValueChange={(value) => setUser({ ...user, displayName: value })}
                        value={user.displayName}
                        maxLength={32}
                    />
                </div>

                <div className='control-container'>
                    <div className='control-label-container'><label>Avatar</label></div>
                    <FileUploader
                        acceptImages
                        circleImage
                        hideFileName
                        loading={avatarLoading}
                        maxHeight={128}
                        maxWidth={128}
                        className='avatar-uploader'
                        onFileSelect={setUserAvatar}
                        initialFile={userAvatar}
                    />
                </div>
            </DialogContent>
            <DialogAction disabled={!user.displayName || userSaving} loading={userSaving} onClick={saveUser} primary>Save</DialogAction>
        </Dialog>
    );
};

export default SaveUserDialog;
