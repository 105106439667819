import React from 'react';
import classNames from 'classnames';
import { useNetworkDashboard } from '../../../../network-dashboard/network-dashboard-context';
import AreaChart from '../area-chart/area-chart';
import './ibc-transfers-chart.scss';
import { SelectableChartProps } from '../chart-container/chart-container';

interface IbcTransfersChartProps extends SelectableChartProps {
    className?: string;
}

export const IbcTransfersChart: React.FC<IbcTransfersChartProps> = ({ className, ...selectableChartProps }) => {
    const { analyticsState, network } = useNetworkDashboard();

    return (
        <AreaChart
            {...selectableChartProps}
            na={!network.ibc}
            label='IBC Transfers'
            valueLabel='Transfers'
            className={classNames('ibc-transfers-chart-container', className)}
            compareDiffs
            loading={analyticsState.analytics === undefined && analyticsState.loading}
            fetchComparableValues={(value) => value.totalIn + value.totalOut}
            data={analyticsState.analytics?.ibcTransfers}
        />
    );
};

export default IbcTransfersChart;
