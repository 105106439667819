import classNames from 'classnames';
import React, { ReactElement } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsCards, { StatisticsCardsProps } from '../../../../shared/components/statistics-cards/statistics-cards';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { getTimeLeftText, getTimeOffset } from '../../../../shared/utils/date-utils';
import { formatPrice, roundNumber } from '../../../../shared/utils/number-utils';
import { useIRO } from '../../../iro/iro-context';
import IroProgress from '../../../iro/iro-progress/iro-progress';
import { useNetwork } from '../../../network/network-context';
import MarketCapProperty from '../../../network/statistics/properties/market-cap-property/market-cap-property';
import { useAsset } from '../../asset-context';
import { Asset } from '../../asset-types';
import './asset-statistics.scss';

interface AssetStatisticsProps extends Omit<StatisticsCardsProps, 'children'> {
    asset: Asset;
}

export const AssetStatistics: React.FC<AssetStatisticsProps> = ({ className, asset, ...props }) => {
    const { getTokenPrice } = useAsset();
    const { hubCurrency } = useNetwork();
    const { getIroPlan, loading: iroLoading } = useIRO();

    const renderPriceProperty = (): ReactElement => {
        return (
            <Property label='Price' className='flex-wrap' na={asset.futureIRO}>
                <StatisticsChange period='day' currentValue={asset.price} previousValue={asset.previousDayPrice}>
                    {formatPrice(getTokenPrice(asset, undefined, true) || 0)}
                </StatisticsChange>
            </Property>
        );
    };

    const renderVolumeProperty = (): ReactElement => {
        return (
            <Property label='Volume (24h)' className='flex-wrap' na={asset.futureIRO}>
                <StatisticsChange period='day' currentValue={asset.volume} previousValue={asset.previousDayVolume}>
                    {formatPrice(asset.volume)}
                </StatisticsChange>
            </Property>
        );
    };

    const renderLiquidityProperty = (): ReactElement => {
        return (
            <Property label='Liquidity' className='flex-wrap'>
                <StatisticsChange period='week' currentValue={asset.liquidity} previousValue={asset.previousWeekLiquidity}>
                    {formatPrice(asset.liquidity)}
                </StatisticsChange>
            </Property>
        );
    };

    const renderBondedLiquidityProperty = (): ReactElement => {
        return (
            <Property label='Bonded Liquidity'>
                {formatPrice(asset.locked)}
                <span className='bonded-percentage'>({roundNumber(asset.locked * 100 / (asset.liquidity || 1), 2)}%)</span>
            </Property>
        );
    };

    const renderIroProgressProperty = (): ReactElement => {
        return (
            <Property label={`IRO Progress (${formatPrice(asset.iroDymRaised, hubCurrency?.displayDenom)} raised)`}>
                <IroProgress asset={asset} />
            </Property>
        );
    };

    const renderIroTimeProperty = (): ReactElement => {
        const iroPlan = getIroPlan(asset.networkId);
        const today = new Date();
        const startTime = iroPlan?.startTime ? new Date(iroPlan.startTime) : today;
        const endTime = iroPlan?.preLaunchTime ? new Date(iroPlan.preLaunchTime) : today;

        return (
            <Property
                label={startTime > today ? 'IRO Start In' : 'IRO available for at least'}
                info={startTime > today ? undefined : 'When the deadline passes, the RollApp can launch at any time, ending the IRO.'}
                infoIndicatorSize='small'
                loading={!iroPlan && (iroLoading || asset.network.status === 'IRO')}
                valueClassName={iroPlan && endTime <= today ? 'rollapp-can-launch-property-value' : ''}
            >
                {!iroPlan ? '' : endTime <= today ? 'Ready to Launch' :
                    getTimeLeftText(getTimeOffset(startTime > today ? startTime : endTime), undefined, true)}
            </Property>
        );
    };

    return (
        <StatisticsCards {...props} className={classNames('asset-statistics', className)}>
            {renderPriceProperty()}
            {asset.currency.type !== 'main' ? <Property label='Market Cap' na>-</Property> :
                <MarketCapProperty network={asset.network} fullyDiluted={asset.network.type !== 'Hub'} />}
            {renderVolumeProperty()}
            {asset.iroDenom ? renderIroProgressProperty() : renderLiquidityProperty()}
            {asset.iroDenom ? renderIroTimeProperty() : renderBondedLiquidityProperty()}
        </StatisticsCards>
    );
};

export default AssetStatistics;
