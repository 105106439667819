import React, { useMemo } from 'react';
import Property from '../../shared/components/property/property';
import StatisticsCards from '../../shared/components/statistics-cards/statistics-cards';
import { formatNumber } from '../../shared/utils/number-utils';
import { useGovernance } from './governance-context';

const GovernanceAnalytics: React.FC = () => {
    const { governanceState } = useGovernance();

    const votingPeriodCount = useMemo(
        () => governanceState.proposals?.filter((proposal) =>
            proposal.status === 'Voting Period' || proposal.status === 'Deposit Period')?.length,
        [ governanceState.proposals ],
    );

    const passedCount = useMemo(
        () => governanceState.proposals?.filter((proposal) => proposal.status === 'Passed')?.length ,
        [ governanceState.proposals ],
    );

    const rejectedCount = useMemo(
        () => governanceState.proposals?.filter((proposal) => proposal.status === 'Rejected')?.length,
        [ governanceState.proposals ],
    );

    return (
        <StatisticsCards>
            <Property label='Total' loading={governanceState.proposals === undefined && governanceState.proposalsLoading}>
                {formatNumber(governanceState.proposals?.length || 0)}
            </Property>

            <Property label='Live' loading={votingPeriodCount === undefined && governanceState.proposalsLoading}>
                {formatNumber(votingPeriodCount || 0)}
            </Property>

            <Property label='Passed' loading={passedCount === undefined && governanceState.proposalsLoading}>
                {formatNumber(passedCount || 0)}
            </Property>

            <Property label='Rejected' loading={rejectedCount === undefined && governanceState.proposalsLoading}>
                {formatNumber(rejectedCount || 0)}
            </Property>
        </StatisticsCards>
    );
};

export default GovernanceAnalytics;


