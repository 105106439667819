import { Coin } from 'cosmjs-types/cosmos/base/v1beta1/coin';
import { Long } from 'cosmjs-types/helpers';
import { sha256 } from 'cosmjs/packages/crypto';
import { toHex } from 'cosmjs/packages/encoding';
import { Decimal } from 'cosmjs/packages/math';
import { MsgCreatePlanEncodeObject, MsgCreateRollappEncodeObject, MsgUpdateRollappEncodeObject } from 'cosmjs/packages/stargate';
import { getDownloadURL, ref, StorageReference } from 'firebase/storage';
import { isEmpty, isUndefined, omitBy } from 'lodash';
import { timeToMilliseconds } from '../../../../shared/utils/date-utils';
import { storage } from '../../../../shared/utils/firebase-utils';
import { formatNumber, roundNumber } from '../../../../shared/utils/number-utils';
import { DEFAULT_GAS_ADJUSTMENT, DEFAULT_GAS_PRICE_STEPS } from '../../../client/client-types';
import { GenesisInfo } from '../../../client/station-clients/dymension/generated/rollapp/genesis_info';
import { RollappMetadata } from '../../../client/station-clients/dymension/generated/rollapp/metadata';
import { rollapp_VMTypeFromJSON } from '../../../client/station-clients/dymension/generated/rollapp/rollapp';
import { Currency } from '../../../currency/currency-types';
import { epochToMilliseconds } from '../../../incentives/incentives.service';
import { EpochIdentifier } from '../../../incentives/types';
import { convertFromBondingCurve } from '../../../iro/iro-service';
import { BondingCurve } from '../../../iro/types';
import { fetchRollAppIdNumber } from '../../../network/network-service';
import { DataAvailability, EVM_COIN_TYPE, EvmType, Network } from '../../../network/network-types';
import { convertToBech32Address, convertToHexAddress } from '../../../wallet/wallet-service';
import {
    Account,
    ACCOUNT_CODE_HASH,
    BaseAccount,
    SupplyAllocation,
    DEFAULT_DENOM_EXPONENT,
    Distribution,
    EvmGenesis,
    Genesis,
    GENESIS_VERSION_MAP,
    MIN_ROLLAPP_ID_NUMBER,
    Token,
    SupplyAllocationAmounts,
    DISTRIBUTION_MODULE_ADDRESS,
    HUB_GENESIS_MODULE_ADDRESS,
    GenesisAccount,
    DISTRIBUTION_MODULE_NAME,
    HUB_GENESIS_MODULE_NAME,
    IRO_HUB_ADDRESS,
    IRO,
    CurveOption,
    UNBONDING_TINE,
    WasmGenesis,
    TOKEN_FACTORY_CREATION_FEE,
    GOV_MODULE_ADDRESS,
    MIN_DEPOSIT,
    VOTING_PERIOD,
    DYM_MIN_GAS_PRICE,
    MIN_GAS_PRICE,
    ROLLAPP_DYM_IBC,
    MIN_SEQUENCER_BOND,
    DYM_MIN_DEPOSIT,
    DYM_REGISTRATION_FEE,
    REGISTRATION_FEE,
} from './types';

export const initGenesis = (evmType: EvmType, rollAppID: string): Genesis => {
    const genesis = JSON.parse(JSON.stringify(GENESIS_VERSION_MAP[evmType]));
    genesis.chain_id = rollAppID;
    genesis.genesis_time = new Date().toISOString().replace(/Z$/, '000Z');
    return genesis;
};

export const updateGenesisDistribution = (
    genesis: Genesis,
    token: Token,
    distribution: Distribution,
    supplyAllocationAmounts: SupplyAllocationAmounts,
): void => {
    const genesisDistribution = genesis.app_state.distribution;
    genesisDistribution.params.community_tax = convertPercentagesToGenesisValue(distribution.communityPool || 0);
    genesisDistribution.params.base_proposer_reward = convertPercentagesToGenesisValue(distribution.sequencer || 0);
    if (!token.tokenless && supplyAllocationAmounts.communityPool) {
        genesisDistribution.fee_pool.community_pool.push({
            amount: supplyAllocationAmounts.communityPool.toString(),
            denom: getDenom(token),
        });
    }
};

export const updateGenesisMint = (genesis: Genesis, token: Token): void => {
    const { mint } = genesis.app_state;
    mint.params.inflation_rate_change = convertPercentagesToGenesisValue(token.tokenless ? 0 : token.inflationRateChange || 0);
    mint.params.target_inflation_rate = convertPercentagesToGenesisValue(token.tokenless ? 0 : token.minimumInflationRate || 0);
    mint.minter.current_inflation_rate = convertPercentagesToGenesisValue(token.tokenless ? 0 : token.initialInflationRate || 0);
    mint.params.mint_denom = token.tokenless ? '' : token.denom;
};

export const updateGenesisStaking = (genesis: Genesis, token: Token): void => {
    const { staking } = genesis.app_state;
    staking.params.bond_denom = getDenom(token);
};

export const updateGenesisRollappParams = (genesis: Genesis, token: Token, rollapp: Network): void => {
    const { rollappparams } = genesis.app_state;
    const amount = Decimal.fromUserInput(
        token.tokenless || token.dymFee ? DYM_MIN_GAS_PRICE : MIN_GAS_PRICE,
        DEFAULT_DENOM_EXPONENT,
    ).atomics;
    rollappparams.params.min_gas_prices = [ { denom: getFeeDenom(token), amount } ];
    if (rollapp.da && process.env.REACT_APP_ENV === 'testnet') {
        rollappparams.params.da = rollapp.da.toLowerCase();
    }
};

export const updateGenesisGov = (genesis: Genesis, token: Token): void => {
    const { gov } = genesis.app_state;
    const minDeposit = token.tokenless ? DYM_MIN_DEPOSIT : MIN_DEPOSIT;
    gov.deposit_params.min_deposit = [
        { denom: getDenom(token), amount: Decimal.fromUserInput(minDeposit, DEFAULT_DENOM_EXPONENT).atomics },
    ];
    gov.voting_params.voting_period = `${VOTING_PERIOD}s`;
};

export const updateGenesisSequencers = (genesis: Genesis): void => {
    const { params } = genesis.app_state.sequencers;
    params.unbonding_time = `${UNBONDING_TINE}s`;
};

export const updateGenesisBank = (
    genesis: Genesis,
    token: Token,
    rollapp: Network,
    accounts: Account[],
    totalSupply: bigint,
    supplyAllocationAmounts: SupplyAllocationAmounts,
): void => {
    const { bank } = genesis.app_state;
    const tokenName = token.tokenless ? 'DYM' : token.name;
    bank.denom_metadata = [];
    bank.denom_metadata.push({
        description: token.tokenless ? '' : `Native token of ${rollapp.chainId}`,
        base: getDenom(token),
        denom_units: [
            { aliases: [], denom: getDenom(token), exponent: 0 },
            { aliases: [], denom: tokenName, exponent: DEFAULT_DENOM_EXPONENT },
        ],
        display: tokenName,
        name: tokenName,
        symbol: tokenName,
    });
    if (!token.tokenless && token.dymFee) {
        const tokenName = 'DYM';
        bank.denom_metadata.push({
            description: `Fee token of ${rollapp.chainId}`,
            base: getFeeDenom(token),
            denom_units: [
                { aliases: [], denom: getFeeDenom(token), exponent: 0 },
                { aliases: [], denom: tokenName, exponent: DEFAULT_DENOM_EXPONENT },
            ],
            display: tokenName,
            name: tokenName,
            symbol: tokenName,
        });
    }
    if (!token.tokenless) {
        bank.supply = [ { denom: getDenom(token), amount: totalSupply.toString() } ];
        bank.balances = accounts.filter((account) => !account.hub).map((account) => ({
            address: account.bech32Address || '',
            coins: [ { denom: getDenom(token), amount: account.amount.toString() } ],
        }));
        if (supplyAllocationAmounts.communityPool) {
            bank.balances.push({
                address: convertToBech32Address(DISTRIBUTION_MODULE_ADDRESS, rollapp.bech32Prefix || ''),
                coins: [ { denom: getDenom(token), amount: supplyAllocationAmounts.communityPool.toString() } ],
            });
        }
        const hubAccountsSupply = getAccountsSupply(accounts.filter((account) => account.hub));
        const hubGenesisBalance = hubAccountsSupply + supplyAllocationAmounts.iro;
        if (hubGenesisBalance) {
            bank.balances.push({
                address: convertToBech32Address(HUB_GENESIS_MODULE_ADDRESS, rollapp.bech32Prefix || ''),
                coins: [ { denom: getDenom(token), amount: hubGenesisBalance.toString() } ],
            });
        }
    }
};

export const updateGenesisAuth = (
    genesis: Genesis,
    token: Token,
    rollapp: Network,
    accounts: Account[],
): void => {
    const { auth } = genesis.app_state;
    if (!token.tokenless) {
        auth.accounts = accounts.filter((account) => !account.hub).map((account) => {
            if (account.vesting) {
                return createGenesisAccount('/cosmos.vesting.v1beta1.ContinuousVestingAccount', account, token);
            }
            if (!rollapp.evmType || rollapp.evmType === 'EVM') {
                return createGenesisAccount('/ethermint.types.v1.EthAccount', account);
            }
            return createGenesisAccount('/cosmos.auth.v1beta1.BaseAccount', account);
        });
        [
            { name: DISTRIBUTION_MODULE_NAME, address: DISTRIBUTION_MODULE_ADDRESS },
            { name: HUB_GENESIS_MODULE_NAME, address: HUB_GENESIS_MODULE_ADDRESS },
        ].forEach(({ name, address }) => {
            let bech32Address = convertToBech32Address(address, rollapp.bech32Prefix || '');
            auth.accounts.push(createGenesisAccount('/cosmos.auth.v1beta1.ModuleAccount', { bech32Address }, undefined, name));
        });
    }
};

export const updateHubGenesis = (
    genesis: Genesis,
    token: Token,
    accounts: Account[],
    supplyAllocationAmounts: SupplyAllocationAmounts,
): void => {
    const { hubgenesis } = genesis.app_state;
    if (!token.tokenless) {
        if (supplyAllocationAmounts.iro) {
            hubgenesis.genesis_accounts.push({ address: IRO_HUB_ADDRESS, amount: supplyAllocationAmounts.iro.toString() });
        }
        accounts.filter((account) => account.hub).forEach((account) => hubgenesis.genesis_accounts.push({
            address: account.bech32Address || '', amount: account.amount.toString(),
        }));
    }
};

const createGenesisAccount = (
    type: GenesisAccount['@type'],
    account: Partial<Account>,
    token?: Token,
    moduleName?: string,
): GenesisAccount => {
    const baseAccount: BaseAccount = { address: account.bech32Address || '', pub_key: null, account_number: '0', sequence: '0' };
    if (type === '/ethermint.types.v1.EthAccount') {
        return { '@type': type, base_account: baseAccount, code_hash: ACCOUNT_CODE_HASH };
    }
    if (type === '/cosmos.vesting.v1beta1.ContinuousVestingAccount') {
        return {
            '@type': type,
            base_vesting_account: {
                base_account: baseAccount,
                original_vesting: [ { denom: token?.denom || '', amount: account.amount?.toString() || '0' } ],
                delegated_vesting: [],
                delegated_free: [],
                end_time: Number(account.vesting?.end_time),
            },
            start_time: Number(account.vesting?.start_time),
        };
    }
    if (type === '/cosmos.auth.v1beta1.ModuleAccount') {
        return { '@type': type, base_account: baseAccount, name: moduleName || '', permissions: [] };
    }
    return { '@type': type, ...baseAccount };
};

export const updateGenesisEvm = (genesis: EvmGenesis, token: Token): void => {
    const { evm } = genesis.app_state;
    evm.params.evm_denom = getFeeDenom(token);
    evm.params.gas_denom = getFeeDenom(token);
};

export const updateGenesisErc20 = (genesis: EvmGenesis, token: Token): void => {
    const { erc20 } = genesis.app_state;
    erc20.params.registration_fee =
        Decimal.fromUserInput(token.tokenless || token.dymFee ? DYM_REGISTRATION_FEE : REGISTRATION_FEE, DEFAULT_DENOM_EXPONENT).atomics;
};

export const updateGenesisFeemarket = (genesis: EvmGenesis, token: Token): void => {
    const { feemarket } = genesis.app_state;
    feemarket.params.min_gas_price =
        Decimal.fromUserInput(token.tokenless || token.dymFee ? DYM_MIN_GAS_PRICE : MIN_GAS_PRICE, DEFAULT_DENOM_EXPONENT).atomics;
};

export const updateGenesisTokenFactory = (genesis: WasmGenesis, rollapp: Network, token: Token): void => {
    const { tokenfactory } = genesis.app_state;
    if (!token.tokenless && token.tokenFactoryEnabled) {
        tokenfactory.params.denom_creation_fee = [
            {
                denom: getFeeDenom(token),
                amount: Decimal.fromUserInput(TOKEN_FACTORY_CREATION_FEE.toString(), DEFAULT_DENOM_EXPONENT).atomics,
            },
        ];
        tokenfactory.factory_denoms = [
            {
                denom: getDenom(token),
                authority_metadata: { admin: convertToBech32Address(GOV_MODULE_ADDRESS, rollapp.bech32Prefix || '') },
            },
        ];
    } else {
        tokenfactory.params.denom_creation_fee = [];
        tokenfactory.factory_denoms = [];
    }
};

export const updateGenesisCallback = (genesis: WasmGenesis, token: Token): void => {
    const { callback } = genesis.app_state;
    const minGasPrice = token.tokenless || token.dymFee ? DYM_MIN_GAS_PRICE : MIN_GAS_PRICE;
    callback.params.min_price_of_gas =
        { denom: getFeeDenom(token), amount: Decimal.fromUserInput(minGasPrice, DEFAULT_DENOM_EXPONENT).atomics };
};

export const updateGenesisCwerrors = (genesis: WasmGenesis, token: Token): void => {
    const { cwerrors } = genesis.app_state;
    cwerrors.params.subscription_fee = { denom: getFeeDenom(token), amount: '0' };
};

export const updateGenesisGasless = (genesis: WasmGenesis, token: Token): void => {
    const { gasless } = genesis.app_state;
    const minDeposit = token.tokenless || token.dymFee ? DYM_MIN_DEPOSIT : MIN_DEPOSIT;
    gasless.params.minimum_gas_deposit = [
        { denom: getFeeDenom(token), amount: Decimal.fromUserInput(minDeposit, DEFAULT_DENOM_EXPONENT).atomics },
    ];
};

export const generateGenesis = (
    rollapp: Network,
    token: Token,
    distribution: Distribution,
    supplyAllocationAmounts: SupplyAllocationAmounts,
    accounts: Account[],
    totalSupply: bigint,
): Genesis => {
    const genesis = initGenesis(rollapp.evmType || 'EVM', rollapp.chainId);
    updateGenesisDistribution(genesis, token, distribution, supplyAllocationAmounts);
    updateGenesisMint(genesis, token);
    updateGenesisStaking(genesis, token);
    updateGenesisGov(genesis, token);
    updateGenesisSequencers(genesis);
    updateGenesisBank(genesis, token, rollapp, accounts, totalSupply, supplyAllocationAmounts);
    updateGenesisAuth(genesis, token, rollapp, accounts);
    updateHubGenesis(genesis, token, accounts, supplyAllocationAmounts);
    updateGenesisRollappParams(genesis, token, rollapp);
    if (rollapp.evmType === 'EVM') {
        updateGenesisEvm(genesis as EvmGenesis, token);
        updateGenesisErc20(genesis as EvmGenesis, token);
        updateGenesisFeemarket(genesis as EvmGenesis, token);
    } else if (rollapp.evmType === 'WASM') {
        updateGenesisTokenFactory(genesis as WasmGenesis, rollapp, token);
        updateGenesisCallback(genesis as WasmGenesis, token);
        updateGenesisCwerrors(genesis as WasmGenesis, token);
        updateGenesisGasless(genesis as WasmGenesis, token);
    }
    return genesis;
};

export const getAllocationsFromGenesis = (genesis: Genesis): { accounts: Account[], accountsSupply: bigint, iroSupply: bigint, communityPoolSupply: bigint } => {
    const accountsMap = genesis.app_state.auth.accounts.reduce<{ [address: string]: Account }>((current, account) => {
        let baseAccount: BaseAccount | undefined = undefined;
        let vesting: Account['vesting'];
        if (account['@type'] === '/ethermint.types.v1.EthAccount') {
            baseAccount = account.base_account;
        } else if (account['@type'] === '/cosmos.auth.v1beta1.BaseAccount') {
            baseAccount = account;
        } else if (account['@type'] === '/cosmos.vesting.v1beta1.ContinuousVestingAccount') {
            baseAccount = account.base_vesting_account.base_account;
            vesting = { start_time: account.start_time, end_time: account.base_vesting_account.end_time };
        }
        if (baseAccount) {
            current[baseAccount.address] = {
                bech32Address: baseAccount.address,
                address: convertToHexAddress(baseAccount.address),
                amount: BigInt(0),
                vesting,
            };
        }
        return current;
    }, {} as { [address: string]: Account });

    let iroSupply = BigInt(0);
    genesis.app_state.hubgenesis.genesis_accounts.forEach((account) => {
        if (account.address === IRO_HUB_ADDRESS) {
            iroSupply = BigInt(account.amount);
            return;
        }
        accountsMap[account.address] = {
            bech32Address: account.address,
            address: convertToHexAddress(account.address),
            amount: BigInt(account.amount),
            hub: true,
        };
    });

    let communityPoolSupply = BigInt(0);
    genesis.app_state.bank.balances.forEach((balance) => {
        const account = accountsMap[balance.address];
        if (account) {
            account.amount = BigInt(balance.coins[0].amount);
            return;
        }
        const hexAddress = convertToHexAddress(balance.address);
        if (hexAddress.toLowerCase() === DISTRIBUTION_MODULE_ADDRESS.toLowerCase()) {
            communityPoolSupply = BigInt(balance.coins[0].amount);
        }
    });
    const accounts = Object.values(accountsMap);
    const accountsSupply = getAccountsSupply(accounts);
    return { accounts, accountsSupply, communityPoolSupply, iroSupply };
};

export const getTokenFromGenesis = (genesis: Genesis): Token => {
    const {
        mint: { minter: { current_inflation_rate }, params: { inflation_rate_change, target_inflation_rate, mint_denom } },
        rollappparams: { params: { min_gas_prices } },
    } = genesis.app_state;
    const denomMetadata = genesis.app_state.bank.denom_metadata?.find((metadata) => metadata.base === mint_denom);
    const dymFee = min_gas_prices.some(({ denom }) => denom === ROLLAPP_DYM_IBC);
    return {
        name: denomMetadata?.name || '',
        tokenless: !denomMetadata?.name,
        dymFee,
        denom: mint_denom || '',
        minimumInflationRate: target_inflation_rate ? convertGenesisValueToPercentages(target_inflation_rate) : 0,
        initialInflationRate: current_inflation_rate ? convertGenesisValueToPercentages(current_inflation_rate) : 0,
        inflationRateChange: inflation_rate_change ? convertGenesisValueToPercentages(inflation_rate_change) : 0,
        tokenFactoryEnabled: Boolean((genesis as WasmGenesis).app_state.tokenfactory?.factory_denoms?.length),
    };
};
export const getDistributionFromGenesis = (genesis: Genesis): Distribution => {
    const { community_tax, base_proposer_reward } = genesis.app_state.distribution.params;
    return {
        communityPool: community_tax ? convertGenesisValueToPercentages(community_tax) : 0,
        sequencer: base_proposer_reward ? convertGenesisValueToPercentages(base_proposer_reward) : 0,
    };
};

export const getAccountsSupply = (accounts: Account[]): bigint => {
    return accounts.reduce((current, account) => current + account.amount, BigInt(0));
};

export const createRegisterRollappMessage = (
    rollapp: Network,
    creator: string,
    alias: string,
    logoUrl?: string,
    minSequencerBond?: Coin,
): MsgCreateRollappEncodeObject => {
    return {
        typeUrl: '/dymensionxyz.dymension.rollapp.MsgCreateRollapp',
        value: {
            rollappId: rollapp.chainId,
            creator,
            alias,
            minSequencerBond,
            vmType: rollapp_VMTypeFromJSON(rollapp.evmType),
            metadata: fetchRollappMetadata(rollapp, logoUrl),
        },
    };
};

export const createUpdateRollappMessage = (
    rollapp: Network,
    owner: string,
    accounts?: Account[],
    nativeToken?: Currency,
    feeToken?: Currency,
    genesisChecksum?: string,
    genesisUrl?: string,
    logoUrl?: string,
    initialSupply?: bigint,
    iroSupply?: bigint,
): MsgUpdateRollappEncodeObject => {
    return {
        typeUrl: '/dymensionxyz.dymension.rollapp.MsgUpdateRollappInformation',
        value: omitBy({
            owner,
            rollappId: rollapp.chainId,
            metadata: fetchRollappMetadata(rollapp, logoUrl, genesisUrl, feeToken),
            minSequencerBond: rollapp.launched ? { denom: 'adym', amount: '-1' } : rollapp.minSequencerBond?.[0],
            genesisInfo: rollapp.sealed ? undefined :
                fetchGenesisInfo(rollapp, accounts, nativeToken, genesisChecksum, initialSupply, iroSupply),
            initialSequencer: rollapp.launched ? undefined : rollapp.initialSequencer,
        }, isEmpty),
    };
};

export const createIroPlanMessage = (
    rollapp: Network,
    owner: string,
    iro: IRO,
    iroSupply: bigint,
    epochIdentifier: EpochIdentifier,
): MsgCreatePlanEncodeObject => {
    return {
        typeUrl: '/dymensionxyz.dymension.iro.MsgCreatePlan',
        value: {
            owner: owner,
            rollappId: rollapp.chainId,
            bondingCurve: !iro.bondingCurve ? undefined : convertFromBondingCurve(iro.bondingCurve),
            startTime: new Date(iro.startTime || Date.now()),
            allocatedAmount: iroSupply.toString(),
            iroPlanDuration: { seconds: Long.fromNumber(Math.max(1000, iro.planDuration) / 1000), nanos: 0 },
            incentivePlanParams: {
                startTimeAfterSettlement: { seconds: Long.fromNumber(iro.startTimeAfterSettlement / 1000), nanos: 0 },
                numEpochsPaidOver: Math.round(iro.incentivesDistributionTime / epochToMilliseconds(epochIdentifier)),
            },
        },
    };
};

export const calculateChecksum = (genesisString: string): string => {
    return toHex(sha256(new TextEncoder().encode(genesisString)));
};

export const getGenesisFileRef = (fileName: string): StorageReference => {
    fileName = decodeURIComponent(fileName);
    const result = /genesis-files\/.+\.json/.exec(fileName);
    fileName = result ? result[0] : `genesis-files/${fileName}`;
    return ref(storage, fileName);
};

export const getRollappLogoFileRef = (fileName: string): StorageReference => {
    fileName = decodeURIComponent(fileName);
    const result = /rollapp-logos\/[^\\?]+\?/.exec(fileName);
    fileName = result ? result[0].slice(0, result[0].length - 1) : `rollapp-logos/${fileName}`;
    return ref(storage, fileName);
};

export const loadGenesis = async (fileName: string): Promise<{ genesisString: string, genesisChecksum: string }> => {
    const genesisFileRef = getGenesisFileRef(fileName);
    const downloadURL = await getDownloadURL(genesisFileRef);
    const response = await fetch(downloadURL);
    const genesisString = await response.text();
    const genesisChecksum = await new Promise((resolve: (value: string) => void) => {
        const worker = new Worker(new URL('./calculate-checksum-worker.ts', import.meta.url));
        worker.onmessage = (event) => resolve(event.data);
        worker.onerror = (error) => {
            resolve('');
            console.error('Worker error:', error);
        };
        worker.postMessage(genesisString.trim());
    });
    return { genesisString, genesisChecksum };
};

export const findAvailableRollappIdNumber = (rollapps: Network[]): number => {
    const rollappIdNumbers = rollapps.map((rollapp) => fetchRollAppIdNumber(rollapp.chainId));
    const idNumbersSet = new Set(rollappIdNumbers);
    let rollappIdNumber = MIN_ROLLAPP_ID_NUMBER + Math.round(Math.random() * 1000000);
    while (idNumbersSet.has(rollappIdNumber.toString())) {
        rollappIdNumber++;
    }
    return rollappIdNumber;
};

export const calculateBondingCurve = (
    targetRaise: number,
    allocation: number,
    curveOption: CurveOption,
    startPrice: number,
): BondingCurve => {
    const M = curveOption.type === 'Fixed' ? 0 :
        (targetRaise - startPrice * allocation) * (curveOption.exponent + 1) / Math.pow(allocation, curveOption.exponent + 1);
    const C = curveOption.type === 'Fixed' ? targetRaise / allocation : startPrice;
    return { C, M, N: curveOption.exponent };
};

export const createEmptyToken = (): Token => {
    return {
        denom: '',
        name: '',
        tokenless: false,
        dymFee: true,
        minimumInflationRate: 1,
        inflationRateChange: 50,
        initialInflationRate: 5,
    };
};

export const createEmptyDistribution = (): Distribution => {
    return { sequencer: 5, communityPool: 5 };
};

export const createEmptySupplyAllocation = (): SupplyAllocation => {
    return { iro: 100, communityPool: 0 };
};

export const createEmptyIro = (): IRO => {
    return {
        targetRaise: 35000,
        incentivesDistributionTime: timeToMilliseconds({ days: 365 }),
        startTimeAfterSettlement: timeToMilliseconds({ days: 1 }),
        planDuration: timeToMilliseconds({ days: 7 }),
    };
};

export const createEmptyRollapp = (): Network => {
    return {
        chainId: '',
        type: 'RollApp',
        logo: '',
        currencies: [],
        chainName: '',
        coinType: EVM_COIN_TYPE,
        evmType: 'EVM',
        owner: '',
        bech32Prefix: '',
        gasAdjustment: DEFAULT_GAS_ADJUSTMENT,
        gasPriceSteps: DEFAULT_GAS_PRICE_STEPS,
        minSequencerBond: [ { denom: 'adym', amount: Decimal.fromUserInput(MIN_SEQUENCER_BOND, DEFAULT_DENOM_EXPONENT).atomics } ],
        da: (localStorage.getItem('custom-da') || 'Celestia') as DataAvailability, // todo: temporary
    };
};

const fetchRollappMetadata = (rollapp: Network, logoUrl?: string, genesisUrl?: string, feeToken?: Currency): RollappMetadata => {
    return omitBy({
        description: rollapp.description,
        x: rollapp.xAccount,
        displayName: rollapp.chainName,
        website: rollapp.website,
        tagline: rollapp.shortDescription,
        logoUrl: logoUrl || rollapp.logo,
        telegram: rollapp.tgAccount,
        genesisUrl: genesisUrl || rollapp.genesisUrl,
        explorerUrl: rollapp.explorerUrl,
        tags: rollapp.tags?.length ? rollapp.tags : undefined,
        feeDenom: !feeToken ? undefined : { base: feeToken.baseDenom, exponent: feeToken.decimals, display: feeToken.displayDenom },
    } as RollappMetadata, isUndefined) as RollappMetadata;
};

const fetchGenesisInfo = (
    rollapp: Network,
    accounts?: Account[],
    nativeToken?: Currency,
    genesisChecksum?: string,
    initialSupply?: bigint,
    iroSupply?: bigint,
): GenesisInfo | undefined => {
    let genesisAccounts = accounts?.filter((account) => account.hub).map((account) => ({
        address: account.bech32Address,
        amount: account.amount.toString(),
    }));
    if (iroSupply) {
        genesisAccounts = [ ...(genesisAccounts || []), { address: IRO_HUB_ADDRESS, amount: iroSupply.toString() } ];
    }
    const genesisInfo = omitBy({
        genesisChecksum: genesisChecksum || rollapp.genesisChecksum,
        bech32Prefix: rollapp.bech32Prefix,
        nativeDenom: {
            base: nativeToken?.baseDenom || 'dummy',
            exponent: nativeToken?.decimals || DEFAULT_DENOM_EXPONENT,
            display: nativeToken?.displayDenom || 'dummy',
        },
        initialSupply: initialSupply?.toString() || '0',
        genesisAccounts: genesisAccounts?.length ? { accounts: genesisAccounts } : undefined,
    } as GenesisInfo, isEmpty);
    return isEmpty(genesisInfo) ? undefined : genesisInfo as GenesisInfo;
};

const convertPercentagesToGenesisValue = (value: number): string => {
    return formatNumber(roundNumber(value / 100, 6), { minimumFractionDigits: 2, maximumFractionDigits: 6 }).padEnd(20, '0');
};

const convertGenesisValueToPercentages = (genesisValue: string): number => {
    return roundNumber(Number(genesisValue) * 100, 4);
};

const getDenom = (token: Token): string => {
    return token.tokenless ? ROLLAPP_DYM_IBC : token.denom;
};

const getFeeDenom = (token: Token): string => {
    return token.tokenless || token.dymFee ? ROLLAPP_DYM_IBC : token.denom;
};

