import { toBech32 } from 'cosmjs/packages/encoding';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../shared/components/snackbar/snackbar-context';
import { usePersistedState } from '../../shared/hooks/use-persisted-state';
import { AccountNetworkState } from '../account/account-network-state';
import { useHubNetworkState } from '../account/hub-network-state-context';
import { useAccountNetwork } from '../account/use-account-network';
import { useClient } from '../client/client-context';
import { ClientError } from '../client/client-error';
import { getMinDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { useNetwork } from '../network/network-context';
import { Network } from '../network/network-types';
import { AmountTxValue, useAmountTx } from '../tx/amount-tx/use-amount-tx';
import { TxError } from '../tx/tx-error';
import { DeliveryTxCode, TxResponse } from '../tx/tx-types';
import { useWallet } from '../wallet/wallet-context';
import { WalletError } from '../wallet/wallet-error';
import { convertToHexAddress } from '../wallet/wallet-service';
import { BridgeState } from './bridge/bridge-state';
import { BridgeTransfer } from './bridge/history/bridge-hisotry-types';
import BridgeTransferDialog from './bridge/history/bridge-transfer-dialog/bridge-transfer-dialog';
import { useIbcStatus } from './ibc-status/ibc-status-context';
import { IbcTransferError } from './ibc-transfer-error';
import { createTransferMessage, getSourceChannel } from './ibc-transfer-service';
import { InitializedIbcTransferDetails } from './ibc-transfer-types';
import useBridge from './bridge/use-bridge';

export const DEFAULT_EIBC_FEE = 0.151;

interface IbcTransferContextProps {
    children: ReactNode;
    optionalSourceNetworks?: string[];
    optionalDestinationNetworks?: string[];
    initialSourceId?: string;
    initialDestinationId?: string;
    enable?: boolean;
    initialAsset?: CoinsAmount;
    allowBridging?: boolean;
}

interface IbcTransferContextValue extends Omit<AmountTxValue, 'calculateFee' | 'clearFee' | 'txStateDispatch' | 'broadcast'> {
    sourceData: AccountNetworkState;
    destinationData: AccountNetworkState;
    hubNetworkData: AccountNetworkState;
    error?: IbcTransferError;
    transferEnabled: boolean;
    setSource: (network?: Network | string, switchNetwork?: boolean) => void;
    setDestination: (network?: Network | string) => void;
    optionalSourceNetworks?: string[];
    optionalDestinationNetworks?: string[];
    optionalNetworks: Network[];
    noRoutesBalances?: CoinsAmount[];
    destinationNetworksWithRoutes?: Network[];
    bridgeState: BridgeState;
    cctpMinAmountToTransfer: number;
    eibcFee?: number;
    setEibcFee: (value?: number) => void;
    useEIbc: boolean;
    setUseEIbc: (value: boolean) => void;
    transfer: () => void;
    getInitializedIbcTransferDetailsId: () => string;
}

export const IbcTransferContext = createContext<IbcTransferContextValue>({} as IbcTransferContextValue);

export const useIbcTransfer = (): IbcTransferContextValue => useContext(IbcTransferContext);

export const IbcTransferContextProvider: React.FC<IbcTransferContextProps> = ({
    children,
    optionalSourceNetworks,
    optionalDestinationNetworks,
    initialSourceId,
    initialAsset,
    enable = true,
    initialDestinationId,
    allowBridging,
}) => {
    const { networks, hubNetwork, networkDenoms, getNetwork } = useNetwork();
    const { removeMessage } = useSnackbar();
    const { networkWalletTypeMap, networkWalletMap, hubWallet, handleWalletError, connectWallet, fetchMostSuitableWallet } = useWallet();
    const { clientStateMap, handleClientError } = useClient();
    const { transfers, setInitiatedTransferCreating, listenToIbcTransfer } = useIbcStatus();
    const [ sourceData, setSourceNetwork ] = useAccountNetwork(undefined, enable, enable);
    const [ destinationData, setDestinationNetwork ] = useAccountNetwork(undefined, false);
    const hubNetworkData = useHubNetworkState();
    const [ eibcFee, setEibcFee ] = usePersistedState<number | undefined>('default-eibc-fee', undefined);
    const [ useEIbc, setUseEIbc ] = usePersistedState<boolean>('use-eibc', true);
    const [ error, setError ] = useState<IbcTransferError>();
    const [ ibcResponseForBridging, setIbcResponseForBridging ] = useState<TxResponse>();
    const [ bridgeTransferDialogItem, setBridgeTransferDialogItem ] = useState<BridgeTransfer>();
    const {
        bridgeState,
        showTransferMessage,
        fetchUserWallets,
        setBridgeCoins,
        broadcastBridgeTransfer,
        setBroadcasting: setBridgeBroadcasting,
        saveCurrentTransfer,
    } = useBridge(sourceData, destinationData, setBridgeTransferDialogItem, enable);

    const sourceChannel = useMemo(() => {
        const destinationNetwork =
            bridgeState.toUseBridge && bridgeState.intermediateNetwork ? bridgeState.intermediateNetwork : destinationData.network;
        return getSourceChannel(sourceData.network, destinationNetwork);
    }, [ sourceData.network, bridgeState.toUseBridge, bridgeState.intermediateNetwork, destinationData.network ]);

    const optionalNetworks = useMemo(
        () => networks.filter((network) => (allowBridging || (network.type !== 'EVM' && network.type !== 'Solana')) &&
            network.ibc && (network.type !== 'RollApp' || network.status === 'Active' || network.status === 'Degraded')),
        [ allowBridging, networks ],
    );

    const fixedDestinationData = useMemo(
        () => bridgeState.toUseBridge && bridgeState.intermediateNetwork ?
            { network: bridgeState.intermediateNetwork, address: bridgeState.intermediateNetworkAddress } : destinationData,
        [ bridgeState.intermediateNetwork, bridgeState.intermediateNetworkAddress, bridgeState.toUseBridge, destinationData ],
    );

    const transferMessagesCreator = useCallback((
        fee?: CoinsAmount,
        coins?: CoinsAmount,
        params?: { intermediateNetworkAddress?: string },
        broadcasting?: boolean,
    ): EncodeObject[] => {
        const balance = sourceData.balances?.find((balance) => coins && isCoinsEquals(balance, coins)) || initialAsset;
        if (!coins ||
            !balance ||
            !sourceData.network ||
            !sourceData.balances ||
            !sourceData.address ||
            !sourceChannel ||
            !fixedDestinationData.network ||
            (!bridgeState.toUseBridge ? !fixedDestinationData.address : (broadcasting && !params?.intermediateNetworkAddress))) {
            return [];
        }
        let baseAmountWithoutFee = undefined;
        if (fee && isCoinsEquals(coins, fee)) {
            coins = { ...coins, amount: Math.max(0, Math.min(coins.amount, balance.amount - fee.amount)) };
            baseAmountWithoutFee =
                balance.baseAmount ? balance.baseAmount - BigInt(getMinDenomAmount(fee.amount, fee.currency)) : undefined;
            baseAmountWithoutFee = baseAmountWithoutFee && baseAmountWithoutFee < BigInt(0) ? BigInt(0) : baseAmountWithoutFee;
        }
        const destinationAddress = fixedDestinationData.address || (broadcasting ? params?.intermediateNetworkAddress :
            toBech32(fixedDestinationData.network.bech32Prefix || '', new Uint8Array(20)));
        const transferMessage = createTransferMessage({
            sourceData,
            destinationData: { ...fixedDestinationData, address: destinationAddress },
            hubNetworkData,
            balance,
            coins,
            eibcFee: useEIbc ? (eibcFee || DEFAULT_EIBC_FEE) : undefined,
            baseAmountWithoutFee,
        });
        return [ transferMessage ];
    }, [ initialAsset, bridgeState.toUseBridge, eibcFee, fixedDestinationData, hubNetworkData, sourceChannel, sourceData, useEIbc ]);

    const getNoRouteBalances = useCallback((destinationNetwork: Network | undefined = destinationData.network) => {
        const sourceAllowedDenoms = sourceData.network?.ibc?.allowedDenoms;
        const destinationAllowedDenoms = destinationNetwork?.ibc?.allowedDenoms;
        if (!sourceAllowedDenoms?.length && !destinationAllowedDenoms?.length) {
            return [];
        }
        let noRouteBalances = sourceData.balances?.filter((balance) => {
            if (sourceAllowedDenoms && !sourceAllowedDenoms.includes(balance.currency.baseDenom)) {
                return true;
            }
            if (!destinationAllowedDenoms?.length) {
                return false;
            }
            const isBalanceAllowed = destinationAllowedDenoms.some((denom) => {
                if (denom !== balance.currency.baseDenom) {
                    return false;
                }
                const currency = destinationNetwork?.currencies.find((currency) => currency.baseDenom === denom);
                if (!currency) {
                    return true;
                }
                if (!currency.ibcRepresentation) {
                    return balance.networkId === destinationNetwork?.chainId;
                }
                if (balance.ibc) {
                    return balance.ibc.representation === currency.ibcRepresentation;
                }
                const networkDenom = networkDenoms?.find((networkDenom) => networkDenom.denom === currency.ibcRepresentation);
                return sourceData.network?.chainId === networkDenom?.ibcNetworkId;
            });
            return !isBalanceAllowed;
        });

        // todo: temporary code - do it generic
        const usdtBalance = sourceData.balances?.find((balance) => balance.currency.baseDenom === 'erc20/tether/usdt');
        if (usdtBalance && sourceData.network?.type === 'EVM' && destinationNetwork?.chainId !== 'kava_2222-10' &&
            (!noRouteBalances || noRouteBalances?.every((balance) => !isCoinsEquals(usdtBalance, balance)))) {
            noRouteBalances = noRouteBalances || [];
            noRouteBalances.push(usdtBalance);
        }
        if (sourceData.network?.type === 'EVM' && destinationNetwork?.chainId === 'axelar-dojo-1') {
            noRouteBalances = noRouteBalances || [];
            noRouteBalances.push(...(sourceData.balances || []));
        }
        if (destinationNetwork?.chainId !== 'celestia' && noRouteBalances &&
            ((sourceData.network?.type === 'Hub' && destinationNetwork?.type === 'Regular') ||
                (sourceData.network?.type === 'Regular' && destinationNetwork?.type === 'Hub'))) {
            noRouteBalances = noRouteBalances.filter((balance) => getNetwork(balance.networkId)?.type !== 'RollApp');
        }
        return noRouteBalances;
    }, [
        destinationData.network,
        networkDenoms,
        getNetwork,
        sourceData.balances,
        sourceData.network?.chainId,
        sourceData.network?.ibc?.allowedDenoms,
        sourceData.network?.type,
    ]);

    const noRoutesBalances = useMemo(getNoRouteBalances, [ getNoRouteBalances ]);

    const { txState, amountTxState, setCoins, setAmount, calculateFee, clearFee, broadcast, txStateDispatch } = useAmountTx({
        networkState: sourceData,
        amountTxMessagesCreator: transferMessagesCreator,
        clearAmountAfterResponse: !bridgeState.toUseBridge,
        initialAsset,
    });

    useEffect(() => setBridgeCoins(amountTxState.coins), [ amountTxState.coins, setBridgeCoins ]);

    const destinationNetworksWithRoutes = useMemo(() => optionalNetworks.filter((network) => {
        if (!sourceData.balances?.length) {
            return true;
        }
        if (network.chainId === sourceData.network?.chainId) {
            return false;
        }
        const noRoutesBalances = getNoRouteBalances(network);
        return (sourceData.balances?.length || 0) > (noRoutesBalances?.length || 0);
    }), [ optionalNetworks, getNoRouteBalances, sourceData.balances, sourceData.network?.chainId ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof IbcTransferError) {
            setError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else if (error instanceof TxError) {
            txStateDispatch({ type: 'set-error', payload: error });
        } else {
            console.error(error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txStateDispatch ]);

    const getInitializedIbcTransferDetailsId = useCallback(() => {
        if (!txState.response || !hubNetwork?.chainId) {
            return '';
        }
        const sendPacketEvent = txState.response.nativeResponse?.events.find((event) => event.type === 'send_packet');
        if (!sendPacketEvent) {
            return '';
        }
        const packetSequence = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_sequence')?.value;
        const sourceChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_src_channel')?.value;
        const destinationChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_dst_channel')?.value;
        if (!sourceChannel || !destinationChannel || packetSequence === undefined || packetSequence === null) {
            return '';
        }
        const type = txState.response.network.type === 'Hub' ? 'out' : 'in';
        return `${hubNetwork?.chainId}-${sourceChannel}-${destinationChannel}-${packetSequence}-${type}`;
    }, [ hubNetwork?.chainId, txState.response ]);

    useEffect(() => {
        if (!txState.response || !hubNetwork?.chainId) {
            return;
        }
        const sendPacketEvent = txState.response.nativeResponse?.events.find((event) => event.type === 'send_packet');
        if (!sendPacketEvent) {
            return;
        }
        const packetData = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_data')?.value;
        const packetSequence = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_sequence')?.value;
        const timeoutTimestamp = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_timeout_timestamp')?.value;
        const sourceChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_src_channel')?.value;
        const destinationChannel = sendPacketEvent.attributes.find((attribute) => attribute.key === 'packet_dst_channel')?.value;
        if (!packetData ||
            !sourceChannel ||
            !destinationChannel ||
            packetSequence === undefined ||
            packetSequence === null ||
            !timeoutTimestamp
        ) {
            return;
        }
        const packetDataValue = JSON.parse(packetData) as { amount: string, denom: string, receiver: string, sender: string };
        if (!packetDataValue.amount || !packetDataValue.denom || !packetDataValue.receiver || !packetDataValue.sender) {
            return;
        }
        let hexSender = '';
        let hexReceiver = '';
        try {
            hexSender = packetDataValue.sender.indexOf('0x') === 0 ? packetDataValue.sender : convertToHexAddress(packetDataValue.sender);
            hexReceiver =
                packetDataValue.receiver.indexOf('0x') === 0 ? packetDataValue.receiver : convertToHexAddress(packetDataValue.receiver);
        } catch {}
        const transfer: InitializedIbcTransferDetails = {
            ...packetDataValue,
            amount: Number(packetDataValue.amount) || 0,
            hexSender,
            hexReceiver,
            hash: txState.response.hash,
            packetSequence: Number(packetSequence) || 0,
            timeout: (Number(timeoutTimestamp) || 0) / Math.pow(10, 6),
            sourceChannel,
            destinationChannel,
            time: new Date().getTime(),
            type: txState.response.network.type === 'Hub' ? 'Out' : 'In',
            network: hubNetwork?.chainId,
        };
        listenToIbcTransfer(
            `${hubNetwork?.chainId}-${sourceChannel}-${destinationChannel}-${packetSequence}-${transfer.type.toLowerCase()}`,
            !bridgeState.toUseBridge,
        );
        setInitiatedTransferCreating(true);
        fetch(process.env.REACT_APP_ADD_INITIAL_IBC_TRANSFER_DETAILS, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(transfer),
        })
            .catch((error) => console.error('Failed to add initial IBC transfer details', error))
            .finally(() => setInitiatedTransferCreating(false));
    }, [ bridgeState.toUseBridge, hubNetwork?.chainId, listenToIbcTransfer, setInitiatedTransferCreating, txState.response ]);

    const transfer = useCallback(async () => {
        const sourceWallet = sourceData.network && networkWalletMap[sourceData.network.chainId];
        if (!sourceData.network || !sourceWallet) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, sourceData.network));
            return;
        }
        if (!destinationData.network || !networkWalletMap[destinationData.network.chainId]) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, destinationData.network));
            return;
        }
        let intermediateNetworkAddress: string | undefined;
        if (bridgeState.toUseBridge) {
            const userWallets = await fetchUserWallets();
            if (bridgeState.intermediateNetwork) {
                const intermediateNetworkWallet = userWallets[bridgeState.intermediateNetwork.chainId];
                const addresses = await intermediateNetworkWallet?.getAddress(bridgeState.intermediateNetwork);
                intermediateNetworkAddress = addresses?.address;
                showTransferMessage(bridgeState.route?.sourceAssetChainID || sourceData.network.chainId, 'IBC Transfer initiated');
            }
        }
        if (bridgeState.toUseBridge && !bridgeState.intermediateNetwork) {
            return await (sourceWallet.switchNetwork?.(sourceData.network) || Promise.resolve())
                .then(() => broadcastBridgeTransfer())
                .catch((error) => handleError(error));
        }
        if (!sourceChannel) {
            handleError(new IbcTransferError('MISSING_CHANNEL', sourceData?.network));
            return [];
        }
        if (bridgeState.toUseBridge && bridgeState.intermediateNetwork) {
            setBridgeBroadcasting();
        }
        return broadcast(undefined, { intermediateNetworkAddress });
    }, [
        sourceData.network,
        networkWalletMap,
        destinationData.network,
        bridgeState.toUseBridge,
        bridgeState.intermediateNetwork,
        bridgeState.route?.sourceAssetChainID,
        sourceChannel,
        setBridgeBroadcasting,
        broadcast,
        handleWalletError,
        fetchUserWallets,
        showTransferMessage,
        broadcastBridgeTransfer,
        handleError,
    ]);

    useEffect(() => {
        if (!txState.broadcasting || !bridgeState.toUseBridge || !bridgeState.intermediateNetwork) {
            return;
        }
        const messageKey = bridgeState.route?.sourceAssetChainID || sourceData.network?.chainId || '';
        if (txState.signing) {
            showTransferMessage(messageKey, 'Your wallet is waiting for confirmation and a signature...');
        } else {
            showTransferMessage(
                messageKey,
                'IBC transfer is in progress - Please wait and avoid closing this window',
                { sourceNetwork: sourceData.network, destinationNetwork: bridgeState.intermediateNetwork },
                0.5,
            );
        }
    }, [
        bridgeState.intermediateNetwork,
        bridgeState.route?.sourceAssetChainID,
        bridgeState.toUseBridge,
        showTransferMessage,
        sourceData.network,
        sourceData.network?.chainId,
        txState.broadcasting,
        txState.signing,
    ]);

    useEffect(() => {
        if (txState.response && bridgeState.toUseBridge && fixedDestinationData.address &&
            txState.response.params?.intermediateNetworkAddress === fixedDestinationData.address
        ) {
            setIbcResponseForBridging(txState.response);
        }
    }, [ bridgeState.toUseBridge, fixedDestinationData.address, txState.response ]);

    useEffect(() => {
        if (!ibcResponseForBridging) {
            return;
        }
        switch (ibcResponseForBridging.deliveryTxCode) {
            case DeliveryTxCode.SUCCESS:
                const sendPacketEvent = ibcResponseForBridging.nativeResponse?.events.find((event) => event.type === 'send_packet');
                const packetSequence = Number(sendPacketEvent?.attributes.find((attribute) => attribute.key === 'packet_sequence')?.value);
                const transfer = transfers.find((transfer) =>
                    transfer.sourceNetwork?.chainId === sourceData.network?.chainId &&
                    transfer.destinationNetwork?.chainId === bridgeState.intermediateNetwork?.chainId &&
                    transfer.sequence === packetSequence);
                if (!transfer) {
                    break;
                }
                if (transfer.status === 'Success' || transfer.status === 'Sent') {
                    setIbcResponseForBridging(undefined);
                    txStateDispatch({ type: 'set-response', payload: undefined });
                    broadcastBridgeTransfer(ibcResponseForBridging.hash).then();
                } else if (transfer?.status === 'Failure' || transfer?.status === 'Refunding') {
                    txStateDispatch({ type: 'set-error', payload: new ClientError('BROADCAST_TX_FAILED', sourceData.network) });
                } else {
                    saveCurrentTransfer({ status: 'IbcTransferring', ibcHash: ibcResponseForBridging.hash }).then();
                    showTransferMessage(
                        bridgeState.route?.sourceAssetChainID || sourceData.network?.chainId || '',
                        'IBC transfer is in progress - Please wait and avoid closing this window',
                        { sourceNetwork: sourceData.network, destinationNetwork: bridgeState.intermediateNetwork },
                        0.5,
                        undefined,
                        ibcResponseForBridging.hash,
                    );
                }
                break;
            case DeliveryTxCode.INSUFFICIENT_FUNDS:
                txStateDispatch({ type: 'set-error', payload: new ClientError('INSUFFICIENT_FUNDS', sourceData.network) });
                break;
            case DeliveryTxCode.OUT_OF_GAS:
                txStateDispatch({ type: 'set-error', payload: new ClientError('INSUFFICIENT_FEES', sourceData.network) });
                break;
        }
    }, [
        bridgeState.intermediateNetwork,
        bridgeState.route?.sourceAssetChainID,
        broadcastBridgeTransfer,
        ibcResponseForBridging,
        removeMessage,
        saveCurrentTransfer,
        showTransferMessage,
        sourceData.network,
        transfers,
        txStateDispatch,
    ]);

    useEffect(() => {
        if (bridgeState.broadcasting && txState.error && bridgeState.toUseBridge &&
            fixedDestinationData.address && txState.params?.intermediateNetworkAddress === fixedDestinationData.address) {
            const messageKey = bridgeState.route?.sourceAssetChainID || sourceData.network?.chainId || '';
            removeMessage(messageKey);
            setBridgeBroadcasting(false);
            setIbcResponseForBridging(undefined);
            saveCurrentTransfer({ status: 'Failed', ibcHash: txState.response?.hash }).then();
        }
    }, [
        bridgeState.broadcasting,
        bridgeState.route?.sourceAssetChainID,
        bridgeState.toUseBridge,
        fixedDestinationData.address,
        removeMessage,
        saveCurrentTransfer,
        setBridgeBroadcasting,
        sourceData.network?.chainId,
        txState.error,
        txState.params?.intermediateNetworkAddress,
        txState.response?.hash,
    ]);

    const setSource = useCallback((network?: Network | string, switchNetwork = true): void => {
        network = typeof network === 'string' ? getNetwork(network) : network;
        setSourceNetwork(network);
        if (switchNetwork && network) {
            const sourceWallet = networkWalletMap[network.chainId];
            if (sourceWallet) {
                sourceWallet.switchNetwork?.(network).catch(handleError);
            }
        }
    }, [ getNetwork, handleError, networkWalletMap, setSourceNetwork ]);

    const setDestination = useCallback((network?: Network | string): void => {
        network = typeof network === 'string' ? getNetwork(network) : network;
        setDestinationNetwork(network);
    }, [ getNetwork, setDestinationNetwork ]);

    const cctpMinAmountToTransfer = useMemo(() => amountTxState.coins?.currency.cctp && bridgeState.toUseBridge ?
        Number(process.env.REACT_APP_CCTP_MIN_AMOUNT) || 0 : 0, [ amountTxState.coins?.currency.cctp, bridgeState.toUseBridge ]);

    const transferEnabled = useMemo(() => Boolean(
        sourceData.network &&
        destinationData.network &&
        !txState.broadcasting &&
        !bridgeState.broadcasting &&
        amountTxState.coins?.amount &&
        ((bridgeState.toUseBridge && !bridgeState.intermediateNetwork) || !txState.feeLoading) &&
        amountTxState.coins.amount >= cctpMinAmountToTransfer &&
        (!bridgeState.toUseBridge || (!bridgeState.routeSearching && !bridgeState.broadcasting)) &&
        (bridgeState.toUseBridge ||
            !networkWalletMap[sourceData.network.chainId] ||
            !networkWalletMap[destinationData.network.chainId] ||
            clientStateMap[sourceData.network.chainId]?.client),
    ), [
        bridgeState.intermediateNetwork,
        amountTxState.coins?.amount,
        bridgeState.routeSearching,
        bridgeState.broadcasting,
        bridgeState.toUseBridge,
        cctpMinAmountToTransfer,
        clientStateMap,
        destinationData.network,
        networkWalletMap,
        sourceData.network,
        txState.broadcasting,
        txState.feeLoading,
    ]);

    useEffect(() => handleError(txState.error), [ handleError, txState.error ]);

    useEffect(() => handleError(sourceData.error), [ handleError, sourceData.error ]);

    useEffect(() => handleError(destinationData.error), [ handleError, destinationData.error ]);

    useEffect(() => handleError(bridgeState.error), [ handleError, bridgeState.error ]);

    useEffect(() => {
        if (!optionalNetworks.length) {
            return;
        }
        let sourceNetwork = sourceData.network;
        if (!sourceNetwork) {
            const sourceNetworks =
                optionalNetworks.filter((network) => !optionalSourceNetworks || optionalSourceNetworks.includes(network.chainId));
            if (sourceNetworks.length) {
                sourceNetwork =
                    sourceNetworks.find((network) => network.chainId === initialSourceId) ||
                    sourceNetworks.find((network) => network.type === 'Hub' && network.chainId !== initialDestinationId) ||
                    sourceNetworks.find((network) => network.chainId !== initialDestinationId) ||
                    sourceNetworks[0];
                setSource(sourceNetwork, false);
            }
        }
        if (!destinationData.network && sourceNetwork) {
            const destinationNetworks = destinationNetworksWithRoutes.filter((network) => !optionalDestinationNetworks ||
                optionalDestinationNetworks.includes(network.chainId));
            if (destinationNetworks.length) {
                const optionalNetworks = destinationNetworks.filter((network) => network.chainId !== sourceNetwork?.chainId);
                const destinationNetwork =
                    optionalNetworks.find((network) => network.chainId === initialDestinationId) || optionalNetworks[0];
                setDestination(destinationNetwork);
            }
        }
    }, [
        destinationNetworksWithRoutes,
        destinationData.network,
        initialDestinationId,
        initialSourceId,
        optionalDestinationNetworks,
        optionalSourceNetworks,
        setDestination,
        setSource,
        sourceData.network,
        optionalNetworks,
    ]);

    useEffect(() => {
        const networkId = sourceData.network?.chainId;
        if (sourceData.network && networkId && !networkWalletTypeMap[networkId] && hubWallet && networkId !== hubNetwork?.chainId) {
            const walletType = fetchMostSuitableWallet(sourceData.network);
            if (walletType) {
                connectWallet(networkId, walletType);
            }
        }
    }, [ connectWallet, fetchMostSuitableWallet, hubNetwork?.chainId, hubWallet, networkWalletTypeMap, sourceData.network ]);

    useEffect(() => {
        const networkId = destinationData.network?.chainId;
        if (destinationData.network && networkId && !networkWalletTypeMap[networkId] && hubWallet && networkId !== hubNetwork?.chainId) {
            const walletType = fetchMostSuitableWallet(destinationData.network);
            if (walletType) {
                connectWallet(networkId, walletType);
            }
        }
    }, [ connectWallet, destinationData.network, fetchMostSuitableWallet, hubNetwork?.chainId, hubWallet, networkWalletTypeMap ]);

    useEffect(() => {
        if (sourceData.address && (bridgeState.toUseBridge || destinationData.address) && sourceChannel && amountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [
        amountTxState.coins?.currency,
        bridgeState.toUseBridge,
        calculateFee,
        clearFee,
        destinationData.address,
        sourceChannel,
        sourceData.address,
    ]);

    useEffect(() => {
        if (sourceData.network?.disabled || destinationData.network?.disabled) {
            setSource(undefined);
            setDestination(undefined);
        }
    }, [ destinationData.network?.disabled, setDestination, setSource, sourceData.network?.disabled ]);

    return (
        <IbcTransferContext.Provider
            value={{
                sourceData,
                destinationData,
                hubNetworkData,
                txState,
                amountTxState,
                noRoutesBalances,
                setSource,
                setDestination,
                error,
                transferEnabled,
                bridgeState,
                cctpMinAmountToTransfer,
                eibcFee,
                useEIbc,
                optionalNetworks,
                destinationNetworksWithRoutes,
                optionalSourceNetworks,
                optionalDestinationNetworks,
                getInitializedIbcTransferDetailsId,
                setEibcFee,
                setUseEIbc,
                setAmount,
                setCoins,
                transfer,
            }}
        >
            {children}
            {bridgeTransferDialogItem &&
                <BridgeTransferDialog transfer={bridgeTransferDialogItem} onRequestClose={() => setBridgeTransferDialogItem(undefined)} />}
        </IbcTransferContext.Provider>
    );
};
