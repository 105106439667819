import { useEffect, useMemo, useReducer } from 'react';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import { useClient } from '../../../client/client-context';
import { validatorListReducer, ValidatorListState } from './validator-list-state';
import { OrderDirection, SortActionType } from '../../../../shared/types';
import { useWallet } from '../../../wallet/wallet-context';
import { loadDelegatedValidators, loadUndelegatingValidators, loadValidators } from '../validator.service';
import { Validator, ValidatorStatus, ValidatorsType } from '../validator-types';
import { StakingDataState } from '../../staking-data/staking-data-state';
import { ValidatorListField } from './validator-list-types';
import { Network } from '../../../network/network-types';
import { AccountNetworkState } from '../../../account/account-network-state';
import { getStakingCurrency } from '../../../currency/currency-service';

export interface ValidatorListData {
    state: ValidatorListState;
    setOrder: SortActionType<ValidatorListField>;
    setStatus: (status: ValidatorStatus) => void;
    setSearchText: (query: string) => void;
}

export const useValidatorList = (
    network: Network,
    networkState: AccountNetworkState,
    type: ValidatorsType,
    stakingDataState?: StakingDataState,
    active?: boolean,
): ValidatorListData => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const [ state, stateDispatch ] = useReducer(validatorListReducer, {});
    const cancelAndSetValidatorsPromise = useCancelablePromise<Validator[]>();

    const networkWallet = networkWalletMap[network.chainId];
    const clientState = clientStateMap[network.chainId];

    const stakingCurrency = useMemo(() => getStakingCurrency(network), [ network ]);

    useEffect(() => {
        if (type === 'All') {
            stateDispatch({ type: 'clear-data' });
            stateDispatch({ type: 'set-validators-loading' });
            stateDispatch({ type: 'set-filter-status', payload: 'Active' });
        }
    }, [ type ]);

    useEffect(() => {
        if (type !== 'All') {
            stateDispatch({ type: 'clear-data' });
            if (networkWallet) {
                stateDispatch({ type: 'set-validators-loading' });
            }
        }
    }, [ networkWallet, type ]);

    /**
     * Update the validators delegations if delegations received.
     */
    useEffect(() => {
        if (stakingDataState?.delegations) {
            stateDispatch({ type: 'set-delegations', payload: stakingDataState.delegations });
        }
    }, [ stakingDataState?.delegations ]);

    /**
     * Update the validators rewards if rewards received.
     */
    useEffect(() => {
        if (stakingDataState?.rewards) {
            stateDispatch({ type: 'set-rewards', payload: stakingDataState.rewards });
        }
    }, [ stakingDataState?.rewards ]);

    /**
     * Load the network validators and update the state according to the results.
     */
    useEffect(() => {
        if (!active || (clientState && !clientState.client && !clientState.connecting)) {
            stateDispatch({ type: 'set-validators-loading', payload: false });
            return;
        }
        const client = clientState?.client;
        if (!client || (type !== 'All' && !networkState.address) || client?.getNetwork()?.chainId !== networkState?.network?.chainId) {
            return;
        }
        let validatorsPromise: Promise<Validator[]> | ((signal: AbortSignal) => Promise<Validator[]>) = Promise.resolve([]);
        if (type === 'All') {
            validatorsPromise = (signal: AbortSignal) => loadValidators(client, stakingCurrency, signal);
        } else if (type === 'Staked') {
            validatorsPromise = loadDelegatedValidators(client, stakingCurrency, networkState.address || '');
        } else if (type === 'Unstaking') {
            validatorsPromise = loadUndelegatingValidators(client, stakingCurrency, networkState.address || '');
        }
        stateDispatch({ type: 'set-validators-loading' });
        cancelAndSetValidatorsPromise(validatorsPromise)
            .then((validators) => {
                if (type === 'All') {
                    validators = validators.sort((validator1, validator2) => validator2.tokens.amount - validator1.tokens.amount);
                } else if (type === 'Staked') {
                    validators = validators.sort((validator1, validator2) =>
                        (validator2.amountStaked || 0) - (validator1.amountStaked || 0));
                } else if (type === 'Unstaking') {
                    validators = validators.sort((validator1, validator2) =>
                        (validator1.unstaking?.completionTime.getTime() || 0) - (validator2.unstaking?.completionTime.getTime() || 0));
                }
                stateDispatch({ type: 'set-validators', payload: validators });
            })
            .catch((error) => {
                stateDispatch({ type: 'set-validators-loading', payload: false });
                stateDispatch({ type: 'set-error', payload: error });
            });
    }, [ active, cancelAndSetValidatorsPromise, clientState, networkState.address, networkState?.network, stakingCurrency, type ]);

    const setOrder = (field: ValidatorListField, direction: OrderDirection): void => {
        stateDispatch({ type: 'set-order', payload: { field, direction } });
    };

    const setStatus = (status: ValidatorStatus): void => {
        stateDispatch({ type: 'set-filter-status', payload: status });
    };

    const setSearchText = (query: string): void => {
        stateDispatch({ type: 'set-search-text', payload: query });
    };

    return { state, setOrder, setStatus, setSearchText };
};
