import classNames from 'classnames';
import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import Badge from '../../../../shared/components/badge/badge';
import Button from '../../../../shared/components/button/button';
import Spinner from '../../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../../shared/components/table/table';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { getShortDateTimeString } from '../../../../shared/utils/date-utils';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAsset } from '../../../asset/asset-context';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { BridgeTransfer } from './bridge-hisotry-types';
import { useBridgeHistory } from './bridge-history-context';
import BridgeTransferDialog from './bridge-transfer-dialog/bridge-transfer-dialog';
import { useRefund } from './use-refund';
import './bridge-history.scss';

const BridgeHistory: React.FC = () => {
    const { networks } = useNetwork();
    const { transfers, loading } = useBridgeHistory();
    const { vsAsset } = useAsset();
    const { canRefund, refunding, balanceToRefund, refundingNetwork, refundChecking, checkRefund, refund } = useRefund();
    const [ bridgeTransferDialogItem, setBridgeTransferDialogItem ] = useState<BridgeTransfer>();

    const getStatusColor = useCallback((transfer: BridgeTransfer): string => {
        if (transfer.status === 'Success') {
            return '--light-green-rgb';
        }
        if (transfer.status === 'Failed') {
            return '--red-rgb';
        }
        return '--orange-rgb';
    }, []);

    const renderBridgeTransfer = (transfer: BridgeTransfer, transferIndex: number): ReactNode => {
        const sourceNetwork = networks.find((network) =>
            network.type === 'EVM' ? Number(network.evm?.chainId).toString() === transfer.sourceId : network.chainId === transfer.sourceId);
        const destinationNetwork = networks.find((network) =>
            network.type === 'EVM' ?
                Number(network.evm?.chainId).toString() === transfer.destinationId :
                network.chainId === transfer.destinationId);
        if (!sourceNetwork || !destinationNetwork || !vsAsset) {
            return undefined;
        }
        return (
            <TableRow
                key={transferIndex}
                className={classNames('bridge-transfer-row', transfer.status.toLowerCase())}
                onSelect={() => setBridgeTransferDialogItem(transfer)}
            >
                <TableColumn>
                    <img className='network-logo' src={getNetworkLogoPath(sourceNetwork)} alt='network-logo' />
                    {sourceNetwork.chainName}
                </TableColumn>
                <TableColumn>
                    <img className='network-logo' src={getNetworkLogoPath(destinationNetwork)} alt='network-logo' />
                    {destinationNetwork.chainName}
                </TableColumn>
                <TableColumn>
                    <img className='asset-logo' src={getCurrencyLogoPath(vsAsset.currency, vsAsset.network)} alt='asset logo' />
                    {formatPrice(Number(transfer.amount), '', { notation: 'compact' })}
                </TableColumn>
                <TableColumn className='status-column'>
                    <Badge
                        color={getCssVariableValue(getStatusColor(transfer)).split(',').map(Number)}
                        size='small'
                        label={transfer.status === 'IbcTransferring' || transfer.status === 'BridgeTransferring' ?
                            'Transferring' : transfer.status}
                    />
                </TableColumn>
                <TableColumn className='time-column' nowrap align='right'>{getShortDateTimeString(transfer.time)}</TableColumn>
            </TableRow>
        );
    };

    const renderBridgeTransferHeaderRow = (): ReactElement => {
        return (
            <TableRow header className='bridge-transfer-row'>
                <TableColumn>From</TableColumn>
                <TableColumn>To</TableColumn>
                <TableColumn>Amount</TableColumn>
                <TableColumn className='status-column'>Status</TableColumn>
                <TableColumn className='time-column' align='right'>Time</TableColumn>
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (!transfers && loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!transfers?.length) {
            return <div className='no-data'>No Transfers</div>;
        }
    };

    return (
        <div className='bridge-history'>
            <div className='table-container'>
                <Table bottomBar={renderBottomBar()}>
                    {renderBridgeTransferHeaderRow()}
                    {transfers?.map(renderBridgeTransfer).filter(Boolean)}
                </Table>
            </div>

            {!canRefund ? undefined : !balanceToRefund ? (
                <Button
                    disabled={refundChecking}
                    loading={refundChecking}
                    onClick={checkRefund}
                    buttonType='secondary'
                    size='small'
                >
                    Check {refundingNetwork?.chainName} Refund
                </Button>
            ) : !balanceToRefund.amount ? <span className='secondary-text'>No Funds on {refundingNetwork?.chainName}</span> : (
                <div className='horizontally-centered'>
                    <Button disabled={refunding} loading={refunding} onClick={refund} buttonType='primary' size='small'>
                        Refund {formatPrice(balanceToRefund.amount, balanceToRefund.currency.displayDenom)}
                    </Button>
                </div>
            )}

            {bridgeTransferDialogItem &&
                <BridgeTransferDialog transfer={bridgeTransferDialogItem} onRequestClose={() => setBridgeTransferDialogItem(undefined)} />}
        </div>
    );
};

export default BridgeHistory;

