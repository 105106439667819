import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../../utils/react-utils';
import Button, { ButtonProps } from '../../button/button';
import Select, { SelectProps } from '../select/select';
import Input, { InputProps } from '../input/input';
import './controls-composer.scss';

interface ControlsComposerProps {
    children: ReactNode;
    className?: string;
    reverse?: boolean;
    error?: ReactNode;
}

const ControlsComposer: React.FC<ControlsComposerProps> = ({ className, children, error, reverse }) => {
    const controls = validateAndGetChildrenAsArray<InputProps & SelectProps & ButtonProps>(children, Input, Select, Button);
    const controlsContainerClassName = classNames('controls-container', className, {
        large: controls.some((child) => child.props.controlSize === 'large'),
        error: Boolean(error)
    });
    return <>
        <div className={controlsContainerClassName}>{reverse ? [ ...controls ].reverse() : children}</div>
        <span className={classNames('error-label', { visible: Boolean(error) })}>{error}</span>
    </>;
};

export default ControlsComposer;
