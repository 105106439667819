/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Coin } from "../cosmos/base/v1beta1/coin";
import { MultipleAliases } from "./alias";
import { DymName } from "./dym_name";
import { BuyOrder, SellOrder } from "./market";
import { Params } from "./params";

export const protobufPackage = "dymensionxyz.dymension.dymns";

/** QueryParamsRequest is request type for the Query/Params RPC method. */
export interface QueryParamsRequest {
}

/** QueryParamsResponse is response type for the Query/Params RPC method. */
export interface QueryParamsResponse {
  /** params holds all the parameters of this module. */
  params: Params | undefined;
}

/** QueryDymNameRequest is the request type for the Query/DymName RPC method. */
export interface QueryDymNameRequest {
  /** dym_name is the name of the Dym-Name to query. */
  dymName: string;
}

/** QueryDymNameResponse is the response type for the Query/DymName RPC method. */
export interface QueryDymNameResponse {
  /** dym_name is the Dym-Name queried for. */
  dymName: DymName | undefined;
}

/** QueryAliasRequest is the request type for the Query/QueryAlias RPC method. */
export interface QueryAliasRequest {
  /** alias to query */
  alias: string;
}

/** QueryAliasResponse */
export interface QueryAliasResponse {
  /** chain_id associated with the alias */
  chainId: string;
  /** found_sell_order is true if active Sell-Order is found for the alias. */
  foundSellOrder: boolean;
  /** buy_order_ids is the list of Buy-Order IDs for the alias. */
  buyOrderIds: string[];
  /** same_chain_aliases is the list of aliases for the same chain that associated with the alias. */
  sameChainAliases: string[];
}

export interface QueryAliasesRequest {
  /** chain_id to query alias for, empty for all chains. */
  chainId: string;
}

export interface QueryAliasesResponse {
  /** aliases_by_chain_id is the map of aliases by chain id. */
  aliasesByChainId: { [key: string]: MultipleAliases };
}

export interface QueryAliasesResponse_AliasesByChainIdEntry {
  key: string;
  value: MultipleAliases | undefined;
}

/** ResolveDymNameAddressesRequest is the request type for the Query/ResolveDymNameAddresses RPC method. */
export interface ResolveDymNameAddressesRequest {
  /** addresses defines the Dym-Name addresses to resolve. */
  addresses: string[];
}

/** ResultDymNameAddress defines the result of a single Dym-Name address resolution. */
export interface ResultDymNameAddress {
  /** address is the input Dym-Name address to resolve. */
  address: string;
  /** resolved_address is the resolved account address. */
  resolvedAddress: string;
  /** error is the error that occurred during the resolution. */
  error: string;
}

/** ResolveDymNameAddressesResponse is the response type for the Query/ResolveDymNameAddresses RPC method. */
export interface ResolveDymNameAddressesResponse {
  /** resolved_addresses defines the resolved addresses for each input Dym-Name address. */
  resolvedAddresses: ResultDymNameAddress[];
}

/** QueryDymNamesOwnedByAccountRequest is the request type for the Query/DymNamesOwnedByAccount RPC method. */
export interface QueryDymNamesOwnedByAccountRequest {
  /** owner defines the address of the owner of the Dym-Names to query for. */
  owner: string;
}

/** QueryDymNamesOwnedByAccountResponse is the response type for the Query/DymNamesOwnedByAccount RPC method. */
export interface QueryDymNamesOwnedByAccountResponse {
  /** dym_names defines the Dym-Names owned by the input account. */
  dymNames: DymName[];
}

/** QuerySellOrderRequest is the request type for the Query/SellOrder RPC method. */
export interface QuerySellOrderRequest {
  /** asset_id is the Dym-Name/Alias to query the active Sell-Order for. */
  assetId: string;
  /** asset_type can be either "Dym-Name" or "Alias". */
  assetType: string;
}

/** QuerySellOrderResponse is the response type for the Query/SellOrder RPC method. */
export interface QuerySellOrderResponse {
  /** result is the active Sell-Order for the Dym-Name/Alias. */
  result: SellOrder | undefined;
}

/** EstimateRegisterNameRequest is the request type for the Query/EstimateRegisterName RPC method. */
export interface EstimateRegisterNameRequest {
  /** name is the Dym-Name to be registered. */
  name: string;
  /** duration is the number of years the Dym-Name will be registered for. */
  duration: number;
  /** owner is the bech32-encoded address of the account which owns the order. */
  owner: string;
}

/** EstimateRegisterNameResponse is the response type for the Query/EstimateRegisterName RPC method. */
export interface EstimateRegisterNameResponse {
  /** first_year_price is the price to register the Dym-Name for the first year. */
  firstYearPrice:
    | Coin
    | undefined;
  /** extend_price is the price to extend the Dym-Name registration for another year. */
  extendPrice:
    | Coin
    | undefined;
  /** total_price is the total price to register the Dym-Name for the specified duration. */
  totalPrice: Coin | undefined;
}

/** EstimateRegisterAliasRequest is the request type for the Query/EstimateRegisterAlias RPC method. */
export interface EstimateRegisterAliasRequest {
  /** alias to be registered. */
  alias: string;
  /** rollapp_id is the rollapp to link the alias to. */
  rollappId: string;
  /** owner is the bech32-encoded address of the account which owns the order. */
  owner: string;
}

/** EstimateRegisterAliasResponse is the response type for the Query/EstimateRegisterAlias RPC method. */
export interface EstimateRegisterAliasResponse {
  /** price is the price to register the alias. */
  price: Coin | undefined;
}

/** ReverseResolveAddressRequest is the request type for the Query/ReverseResolveAddress RPC method. */
export interface ReverseResolveAddressRequest {
  /** addresses defines the addresses to reverse resolve. Can be both bech32 and hex addresses. */
  addresses: string[];
  /**
   * working_chain_id defines the chain id to use for the reverse resolution.
   * Leave empty to use the host chain id.
   */
  workingChainId: string;
}

/** ReverseResolveAddressResponse is the response type for the Query/ReverseResolveAddress RPC method. */
export interface ReverseResolveAddressResponse {
  /** result defines the reverse resolution result for each input address. */
  result: { [key: string]: ReverseResolveAddressResult };
  /** working_chain_id is the chain id used for the reverse resolution. */
  workingChainId: string;
}

export interface ReverseResolveAddressResponse_ResultEntry {
  key: string;
  value: ReverseResolveAddressResult | undefined;
}

export interface ReverseResolveAddressResult {
  /** candidates are the Dym-Name addresses that the input address resolves to. Take one of them. */
  candidates: string[];
  /** error is the error that occurred during the resolution. */
  error: string;
}

/** QueryTranslateAliasOrChainIdToChainIdRequest is the request type for the Query/TranslateAliasOrChainIdToChainId RPC method. */
export interface QueryTranslateAliasOrChainIdToChainIdRequest {
  /** alias_or_chain_id is the alias or chain id to translate. */
  aliasOrChainId: string;
}

/** QueryTranslateAliasOrChainIdToChainIdResponse is the response type for the Query/TranslateAliasOrChainIdToChainId RPC method. */
export interface QueryTranslateAliasOrChainIdToChainIdResponse {
  /** chain_id is the chain id that the alias or chain id translates to. */
  chainId: string;
}

/** QueryBuyOrderByIdRequest is the request type for the Query/BuyOrderById RPC method. */
export interface QueryBuyOrderByIdRequest {
  /** id of buy offer to query. */
  id: string;
}

/** QueryBuyOrderByIdResponse is the response type for the Query/BuyOrderById RPC method. */
export interface QueryBuyOrderByIdResponse {
  /** buy_order is the result. */
  buyOrder: BuyOrder | undefined;
}

/** QueryBuyOrdersByAccountRequest is the request type for the Query/BuyOrdersPlacedByAccount RPC method. */
export interface QueryBuyOrdersPlacedByAccountRequest {
  /** account is the account address to query the placed buy offers. */
  account: string;
}

/** QueryBuyOrdersByAccountResponse is the response type for the Query/BuyOrdersPlacedByAccount RPC method. */
export interface QueryBuyOrdersPlacedByAccountResponse {
  /** offers are the Buy-Orders placed by the account. */
  buyOrders: BuyOrder[];
}

/** QueryBuyOrdersByDymNameRequest is the request type for the Query/BuyOrdersByDymName RPC method. */
export interface QueryBuyOrdersByDymNameRequest {
  /** name is the Dym-Name to query the buy offers placed for it. */
  name: string;
}

/** QueryBuyOrdersByDymNameResponse is the response type for the Query/BuyOrdersByDymName RPC method. */
export interface QueryBuyOrdersByDymNameResponse {
  /** buy_orders placed for the Dym-Name. */
  buyOrders: BuyOrder[];
}

/** QueryBuyOrdersOfDymNamesOwnedByAccountRequest is the request type for the Query/BuyOrdersOfDymNamesOwnedByAccount RPC method. */
export interface QueryBuyOrdersOfDymNamesOwnedByAccountRequest {
  /** account is the account address to query all the buy offers of the Dym-Names owned by it. */
  account: string;
}

/** QueryBuyOrdersOfDymNamesOwnedByAccountResponse is the response type for the Query/BuyOrdersOfDymNamesOwnedByAccount RPC method. */
export interface QueryBuyOrdersOfDymNamesOwnedByAccountResponse {
  /** buy_orders of all the Dym-Names owned by the input account. */
  buyOrders: BuyOrder[];
}

/** QueryBuyOrdersByAliasRequest is the request type for the Query/BuyOrdersByAlias RPC method. */
export interface QueryBuyOrdersByAliasRequest {
  /** alias is the alias to query the buy offers placed for it. */
  alias: string;
}

/** QueryBuyOrdersByAliasResponse is the response type for the Query/BuyOrdersByAlias RPC method. */
export interface QueryBuyOrdersByAliasResponse {
  /** buy_orders of the input alias. */
  buyOrders: BuyOrder[];
}

/** QueryBuyOrdersOfAliasesLinkedToRollAppRequest is the request type for the Query/BuyOrdersOfAliasesLinkedToRollApp RPC method. */
export interface QueryBuyOrdersOfAliasesLinkedToRollAppRequest {
  /** rollapp_id is the rollapp to query all the buy offers of the aliases linked to it */
  rollappId: string;
}

/** QueryBuyOrdersOfAliasesLinkedToRollAppResponse is the response type for the Query/BuyOrdersOfAliasesLinkedToRollApp RPC method. */
export interface QueryBuyOrdersOfAliasesLinkedToRollAppResponse {
  /** buy_orders are all the buy orders of the aliases linked to the input rollapp. */
  buyOrders: BuyOrder[];
}

function createBaseQueryParamsRequest(): QueryParamsRequest {
  return {};
}

export const QueryParamsRequest = {
  encode(_: QueryParamsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryParamsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryParamsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): QueryParamsRequest {
    return {};
  },

  toJSON(_: QueryParamsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryParamsRequest>, I>>(_: I): QueryParamsRequest {
    const message = createBaseQueryParamsRequest();
    return message;
  },
};

function createBaseQueryParamsResponse(): QueryParamsResponse {
  return { params: undefined };
}

export const QueryParamsResponse = {
  encode(message: QueryParamsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.params !== undefined) {
      Params.encode(message.params, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryParamsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryParamsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.params = Params.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryParamsResponse {
    return { params: isSet(object.params) ? Params.fromJSON(object.params) : undefined };
  },

  toJSON(message: QueryParamsResponse): unknown {
    const obj: any = {};
    message.params !== undefined && (obj.params = message.params ? Params.toJSON(message.params) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryParamsResponse>, I>>(object: I): QueryParamsResponse {
    const message = createBaseQueryParamsResponse();
    message.params = (object.params !== undefined && object.params !== null)
      ? Params.fromPartial(object.params)
      : undefined;
    return message;
  },
};

function createBaseQueryDymNameRequest(): QueryDymNameRequest {
  return { dymName: "" };
}

export const QueryDymNameRequest = {
  encode(message: QueryDymNameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dymName !== "") {
      writer.uint32(10).string(message.dymName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryDymNameRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDymNameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dymName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryDymNameRequest {
    return { dymName: isSet(object.dymName) ? String(object.dymName) : "" };
  },

  toJSON(message: QueryDymNameRequest): unknown {
    const obj: any = {};
    message.dymName !== undefined && (obj.dymName = message.dymName);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryDymNameRequest>, I>>(object: I): QueryDymNameRequest {
    const message = createBaseQueryDymNameRequest();
    message.dymName = object.dymName ?? "";
    return message;
  },
};

function createBaseQueryDymNameResponse(): QueryDymNameResponse {
  return { dymName: undefined };
}

export const QueryDymNameResponse = {
  encode(message: QueryDymNameResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dymName !== undefined) {
      DymName.encode(message.dymName, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryDymNameResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDymNameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dymName = DymName.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryDymNameResponse {
    return { dymName: isSet(object.dymName) ? DymName.fromJSON(object.dymName) : undefined };
  },

  toJSON(message: QueryDymNameResponse): unknown {
    const obj: any = {};
    message.dymName !== undefined && (obj.dymName = message.dymName ? DymName.toJSON(message.dymName) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryDymNameResponse>, I>>(object: I): QueryDymNameResponse {
    const message = createBaseQueryDymNameResponse();
    message.dymName = (object.dymName !== undefined && object.dymName !== null)
      ? DymName.fromPartial(object.dymName)
      : undefined;
    return message;
  },
};

function createBaseQueryAliasRequest(): QueryAliasRequest {
  return { alias: "" };
}

export const QueryAliasRequest = {
  encode(message: QueryAliasRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.alias !== "") {
      writer.uint32(10).string(message.alias);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryAliasRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryAliasRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.alias = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryAliasRequest {
    return { alias: isSet(object.alias) ? String(object.alias) : "" };
  },

  toJSON(message: QueryAliasRequest): unknown {
    const obj: any = {};
    message.alias !== undefined && (obj.alias = message.alias);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryAliasRequest>, I>>(object: I): QueryAliasRequest {
    const message = createBaseQueryAliasRequest();
    message.alias = object.alias ?? "";
    return message;
  },
};

function createBaseQueryAliasResponse(): QueryAliasResponse {
  return { chainId: "", foundSellOrder: false, buyOrderIds: [], sameChainAliases: [] };
}

export const QueryAliasResponse = {
  encode(message: QueryAliasResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainId !== "") {
      writer.uint32(10).string(message.chainId);
    }
    if (message.foundSellOrder === true) {
      writer.uint32(16).bool(message.foundSellOrder);
    }
    for (const v of message.buyOrderIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.sameChainAliases) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryAliasResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryAliasResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainId = reader.string();
          break;
        case 2:
          message.foundSellOrder = reader.bool();
          break;
        case 3:
          message.buyOrderIds.push(reader.string());
          break;
        case 4:
          message.sameChainAliases.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryAliasResponse {
    return {
      chainId: isSet(object.chainId) ? String(object.chainId) : "",
      foundSellOrder: isSet(object.foundSellOrder) ? Boolean(object.foundSellOrder) : false,
      buyOrderIds: Array.isArray(object?.buyOrderIds) ? object.buyOrderIds.map((e: any) => String(e)) : [],
      sameChainAliases: Array.isArray(object?.sameChainAliases)
        ? object.sameChainAliases.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: QueryAliasResponse): unknown {
    const obj: any = {};
    message.chainId !== undefined && (obj.chainId = message.chainId);
    message.foundSellOrder !== undefined && (obj.foundSellOrder = message.foundSellOrder);
    if (message.buyOrderIds) {
      obj.buyOrderIds = message.buyOrderIds.map((e) => e);
    } else {
      obj.buyOrderIds = [];
    }
    if (message.sameChainAliases) {
      obj.sameChainAliases = message.sameChainAliases.map((e) => e);
    } else {
      obj.sameChainAliases = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryAliasResponse>, I>>(object: I): QueryAliasResponse {
    const message = createBaseQueryAliasResponse();
    message.chainId = object.chainId ?? "";
    message.foundSellOrder = object.foundSellOrder ?? false;
    message.buyOrderIds = object.buyOrderIds?.map((e) => e) || [];
    message.sameChainAliases = object.sameChainAliases?.map((e) => e) || [];
    return message;
  },
};

function createBaseQueryAliasesRequest(): QueryAliasesRequest {
  return { chainId: "" };
}

export const QueryAliasesRequest = {
  encode(message: QueryAliasesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainId !== "") {
      writer.uint32(10).string(message.chainId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryAliasesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryAliasesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryAliasesRequest {
    return { chainId: isSet(object.chainId) ? String(object.chainId) : "" };
  },

  toJSON(message: QueryAliasesRequest): unknown {
    const obj: any = {};
    message.chainId !== undefined && (obj.chainId = message.chainId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryAliasesRequest>, I>>(object: I): QueryAliasesRequest {
    const message = createBaseQueryAliasesRequest();
    message.chainId = object.chainId ?? "";
    return message;
  },
};

function createBaseQueryAliasesResponse(): QueryAliasesResponse {
  return { aliasesByChainId: {} };
}

export const QueryAliasesResponse = {
  encode(message: QueryAliasesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.aliasesByChainId).forEach(([key, value]) => {
      QueryAliasesResponse_AliasesByChainIdEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryAliasesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryAliasesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry1 = QueryAliasesResponse_AliasesByChainIdEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.aliasesByChainId[entry1.key] = entry1.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryAliasesResponse {
    return {
      aliasesByChainId: isObject(object.aliasesByChainId)
        ? Object.entries(object.aliasesByChainId).reduce<{ [key: string]: MultipleAliases }>((acc, [key, value]) => {
          acc[key] = MultipleAliases.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QueryAliasesResponse): unknown {
    const obj: any = {};
    obj.aliasesByChainId = {};
    if (message.aliasesByChainId) {
      Object.entries(message.aliasesByChainId).forEach(([k, v]) => {
        obj.aliasesByChainId[k] = MultipleAliases.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryAliasesResponse>, I>>(object: I): QueryAliasesResponse {
    const message = createBaseQueryAliasesResponse();
    message.aliasesByChainId = Object.entries(object.aliasesByChainId ?? {}).reduce<{ [key: string]: MultipleAliases }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = MultipleAliases.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseQueryAliasesResponse_AliasesByChainIdEntry(): QueryAliasesResponse_AliasesByChainIdEntry {
  return { key: "", value: undefined };
}

export const QueryAliasesResponse_AliasesByChainIdEntry = {
  encode(message: QueryAliasesResponse_AliasesByChainIdEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      MultipleAliases.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryAliasesResponse_AliasesByChainIdEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryAliasesResponse_AliasesByChainIdEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = MultipleAliases.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryAliasesResponse_AliasesByChainIdEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? MultipleAliases.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: QueryAliasesResponse_AliasesByChainIdEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? MultipleAliases.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryAliasesResponse_AliasesByChainIdEntry>, I>>(
    object: I,
  ): QueryAliasesResponse_AliasesByChainIdEntry {
    const message = createBaseQueryAliasesResponse_AliasesByChainIdEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? MultipleAliases.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseResolveDymNameAddressesRequest(): ResolveDymNameAddressesRequest {
  return { addresses: [] };
}

export const ResolveDymNameAddressesRequest = {
  encode(message: ResolveDymNameAddressesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.addresses) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResolveDymNameAddressesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResolveDymNameAddressesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.addresses.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResolveDymNameAddressesRequest {
    return { addresses: Array.isArray(object?.addresses) ? object.addresses.map((e: any) => String(e)) : [] };
  },

  toJSON(message: ResolveDymNameAddressesRequest): unknown {
    const obj: any = {};
    if (message.addresses) {
      obj.addresses = message.addresses.map((e) => e);
    } else {
      obj.addresses = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResolveDymNameAddressesRequest>, I>>(
    object: I,
  ): ResolveDymNameAddressesRequest {
    const message = createBaseResolveDymNameAddressesRequest();
    message.addresses = object.addresses?.map((e) => e) || [];
    return message;
  },
};

function createBaseResultDymNameAddress(): ResultDymNameAddress {
  return { address: "", resolvedAddress: "", error: "" };
}

export const ResultDymNameAddress = {
  encode(message: ResultDymNameAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.resolvedAddress !== "") {
      writer.uint32(18).string(message.resolvedAddress);
    }
    if (message.error !== "") {
      writer.uint32(26).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResultDymNameAddress {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResultDymNameAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.address = reader.string();
          break;
        case 2:
          message.resolvedAddress = reader.string();
          break;
        case 3:
          message.error = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResultDymNameAddress {
    return {
      address: isSet(object.address) ? String(object.address) : "",
      resolvedAddress: isSet(object.resolvedAddress) ? String(object.resolvedAddress) : "",
      error: isSet(object.error) ? String(object.error) : "",
    };
  },

  toJSON(message: ResultDymNameAddress): unknown {
    const obj: any = {};
    message.address !== undefined && (obj.address = message.address);
    message.resolvedAddress !== undefined && (obj.resolvedAddress = message.resolvedAddress);
    message.error !== undefined && (obj.error = message.error);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResultDymNameAddress>, I>>(object: I): ResultDymNameAddress {
    const message = createBaseResultDymNameAddress();
    message.address = object.address ?? "";
    message.resolvedAddress = object.resolvedAddress ?? "";
    message.error = object.error ?? "";
    return message;
  },
};

function createBaseResolveDymNameAddressesResponse(): ResolveDymNameAddressesResponse {
  return { resolvedAddresses: [] };
}

export const ResolveDymNameAddressesResponse = {
  encode(message: ResolveDymNameAddressesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.resolvedAddresses) {
      ResultDymNameAddress.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResolveDymNameAddressesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResolveDymNameAddressesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.resolvedAddresses.push(ResultDymNameAddress.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResolveDymNameAddressesResponse {
    return {
      resolvedAddresses: Array.isArray(object?.resolvedAddresses)
        ? object.resolvedAddresses.map((e: any) => ResultDymNameAddress.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ResolveDymNameAddressesResponse): unknown {
    const obj: any = {};
    if (message.resolvedAddresses) {
      obj.resolvedAddresses = message.resolvedAddresses.map((e) => e ? ResultDymNameAddress.toJSON(e) : undefined);
    } else {
      obj.resolvedAddresses = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResolveDymNameAddressesResponse>, I>>(
    object: I,
  ): ResolveDymNameAddressesResponse {
    const message = createBaseResolveDymNameAddressesResponse();
    message.resolvedAddresses = object.resolvedAddresses?.map((e) => ResultDymNameAddress.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryDymNamesOwnedByAccountRequest(): QueryDymNamesOwnedByAccountRequest {
  return { owner: "" };
}

export const QueryDymNamesOwnedByAccountRequest = {
  encode(message: QueryDymNamesOwnedByAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.owner !== "") {
      writer.uint32(10).string(message.owner);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryDymNamesOwnedByAccountRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDymNamesOwnedByAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.owner = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryDymNamesOwnedByAccountRequest {
    return { owner: isSet(object.owner) ? String(object.owner) : "" };
  },

  toJSON(message: QueryDymNamesOwnedByAccountRequest): unknown {
    const obj: any = {};
    message.owner !== undefined && (obj.owner = message.owner);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryDymNamesOwnedByAccountRequest>, I>>(
    object: I,
  ): QueryDymNamesOwnedByAccountRequest {
    const message = createBaseQueryDymNamesOwnedByAccountRequest();
    message.owner = object.owner ?? "";
    return message;
  },
};

function createBaseQueryDymNamesOwnedByAccountResponse(): QueryDymNamesOwnedByAccountResponse {
  return { dymNames: [] };
}

export const QueryDymNamesOwnedByAccountResponse = {
  encode(message: QueryDymNamesOwnedByAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dymNames) {
      DymName.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryDymNamesOwnedByAccountResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryDymNamesOwnedByAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dymNames.push(DymName.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryDymNamesOwnedByAccountResponse {
    return { dymNames: Array.isArray(object?.dymNames) ? object.dymNames.map((e: any) => DymName.fromJSON(e)) : [] };
  },

  toJSON(message: QueryDymNamesOwnedByAccountResponse): unknown {
    const obj: any = {};
    if (message.dymNames) {
      obj.dymNames = message.dymNames.map((e) => e ? DymName.toJSON(e) : undefined);
    } else {
      obj.dymNames = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryDymNamesOwnedByAccountResponse>, I>>(
    object: I,
  ): QueryDymNamesOwnedByAccountResponse {
    const message = createBaseQueryDymNamesOwnedByAccountResponse();
    message.dymNames = object.dymNames?.map((e) => DymName.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuerySellOrderRequest(): QuerySellOrderRequest {
  return { assetId: "", assetType: "" };
}

export const QuerySellOrderRequest = {
  encode(message: QuerySellOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assetId !== "") {
      writer.uint32(10).string(message.assetId);
    }
    if (message.assetType !== "") {
      writer.uint32(18).string(message.assetType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuerySellOrderRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySellOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.assetId = reader.string();
          break;
        case 2:
          message.assetType = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QuerySellOrderRequest {
    return {
      assetId: isSet(object.assetId) ? String(object.assetId) : "",
      assetType: isSet(object.assetType) ? String(object.assetType) : "",
    };
  },

  toJSON(message: QuerySellOrderRequest): unknown {
    const obj: any = {};
    message.assetId !== undefined && (obj.assetId = message.assetId);
    message.assetType !== undefined && (obj.assetType = message.assetType);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QuerySellOrderRequest>, I>>(object: I): QuerySellOrderRequest {
    const message = createBaseQuerySellOrderRequest();
    message.assetId = object.assetId ?? "";
    message.assetType = object.assetType ?? "";
    return message;
  },
};

function createBaseQuerySellOrderResponse(): QuerySellOrderResponse {
  return { result: undefined };
}

export const QuerySellOrderResponse = {
  encode(message: QuerySellOrderResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== undefined) {
      SellOrder.encode(message.result, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuerySellOrderResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuerySellOrderResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.result = SellOrder.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QuerySellOrderResponse {
    return { result: isSet(object.result) ? SellOrder.fromJSON(object.result) : undefined };
  },

  toJSON(message: QuerySellOrderResponse): unknown {
    const obj: any = {};
    message.result !== undefined && (obj.result = message.result ? SellOrder.toJSON(message.result) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QuerySellOrderResponse>, I>>(object: I): QuerySellOrderResponse {
    const message = createBaseQuerySellOrderResponse();
    message.result = (object.result !== undefined && object.result !== null)
      ? SellOrder.fromPartial(object.result)
      : undefined;
    return message;
  },
};

function createBaseEstimateRegisterNameRequest(): EstimateRegisterNameRequest {
  return { name: "", duration: 0, owner: "" };
}

export const EstimateRegisterNameRequest = {
  encode(message: EstimateRegisterNameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.duration !== 0) {
      writer.uint32(16).int64(message.duration);
    }
    if (message.owner !== "") {
      writer.uint32(26).string(message.owner);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EstimateRegisterNameRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEstimateRegisterNameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.duration = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.owner = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EstimateRegisterNameRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      owner: isSet(object.owner) ? String(object.owner) : "",
    };
  },

  toJSON(message: EstimateRegisterNameRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.duration !== undefined && (obj.duration = Math.round(message.duration));
    message.owner !== undefined && (obj.owner = message.owner);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EstimateRegisterNameRequest>, I>>(object: I): EstimateRegisterNameRequest {
    const message = createBaseEstimateRegisterNameRequest();
    message.name = object.name ?? "";
    message.duration = object.duration ?? 0;
    message.owner = object.owner ?? "";
    return message;
  },
};

function createBaseEstimateRegisterNameResponse(): EstimateRegisterNameResponse {
  return { firstYearPrice: undefined, extendPrice: undefined, totalPrice: undefined };
}

export const EstimateRegisterNameResponse = {
  encode(message: EstimateRegisterNameResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.firstYearPrice !== undefined) {
      Coin.encode(message.firstYearPrice, writer.uint32(10).fork()).ldelim();
    }
    if (message.extendPrice !== undefined) {
      Coin.encode(message.extendPrice, writer.uint32(18).fork()).ldelim();
    }
    if (message.totalPrice !== undefined) {
      Coin.encode(message.totalPrice, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EstimateRegisterNameResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEstimateRegisterNameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.firstYearPrice = Coin.decode(reader, reader.uint32());
          break;
        case 2:
          message.extendPrice = Coin.decode(reader, reader.uint32());
          break;
        case 3:
          message.totalPrice = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EstimateRegisterNameResponse {
    return {
      firstYearPrice: isSet(object.firstYearPrice) ? Coin.fromJSON(object.firstYearPrice) : undefined,
      extendPrice: isSet(object.extendPrice) ? Coin.fromJSON(object.extendPrice) : undefined,
      totalPrice: isSet(object.totalPrice) ? Coin.fromJSON(object.totalPrice) : undefined,
    };
  },

  toJSON(message: EstimateRegisterNameResponse): unknown {
    const obj: any = {};
    message.firstYearPrice !== undefined
      && (obj.firstYearPrice = message.firstYearPrice ? Coin.toJSON(message.firstYearPrice) : undefined);
    message.extendPrice !== undefined
      && (obj.extendPrice = message.extendPrice ? Coin.toJSON(message.extendPrice) : undefined);
    message.totalPrice !== undefined
      && (obj.totalPrice = message.totalPrice ? Coin.toJSON(message.totalPrice) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EstimateRegisterNameResponse>, I>>(object: I): EstimateRegisterNameResponse {
    const message = createBaseEstimateRegisterNameResponse();
    message.firstYearPrice = (object.firstYearPrice !== undefined && object.firstYearPrice !== null)
      ? Coin.fromPartial(object.firstYearPrice)
      : undefined;
    message.extendPrice = (object.extendPrice !== undefined && object.extendPrice !== null)
      ? Coin.fromPartial(object.extendPrice)
      : undefined;
    message.totalPrice = (object.totalPrice !== undefined && object.totalPrice !== null)
      ? Coin.fromPartial(object.totalPrice)
      : undefined;
    return message;
  },
};

function createBaseEstimateRegisterAliasRequest(): EstimateRegisterAliasRequest {
  return { alias: "", rollappId: "", owner: "" };
}

export const EstimateRegisterAliasRequest = {
  encode(message: EstimateRegisterAliasRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.alias !== "") {
      writer.uint32(10).string(message.alias);
    }
    if (message.rollappId !== "") {
      writer.uint32(18).string(message.rollappId);
    }
    if (message.owner !== "") {
      writer.uint32(26).string(message.owner);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EstimateRegisterAliasRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEstimateRegisterAliasRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.alias = reader.string();
          break;
        case 2:
          message.rollappId = reader.string();
          break;
        case 3:
          message.owner = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EstimateRegisterAliasRequest {
    return {
      alias: isSet(object.alias) ? String(object.alias) : "",
      rollappId: isSet(object.rollappId) ? String(object.rollappId) : "",
      owner: isSet(object.owner) ? String(object.owner) : "",
    };
  },

  toJSON(message: EstimateRegisterAliasRequest): unknown {
    const obj: any = {};
    message.alias !== undefined && (obj.alias = message.alias);
    message.rollappId !== undefined && (obj.rollappId = message.rollappId);
    message.owner !== undefined && (obj.owner = message.owner);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EstimateRegisterAliasRequest>, I>>(object: I): EstimateRegisterAliasRequest {
    const message = createBaseEstimateRegisterAliasRequest();
    message.alias = object.alias ?? "";
    message.rollappId = object.rollappId ?? "";
    message.owner = object.owner ?? "";
    return message;
  },
};

function createBaseEstimateRegisterAliasResponse(): EstimateRegisterAliasResponse {
  return { price: undefined };
}

export const EstimateRegisterAliasResponse = {
  encode(message: EstimateRegisterAliasResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.price !== undefined) {
      Coin.encode(message.price, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EstimateRegisterAliasResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEstimateRegisterAliasResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.price = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EstimateRegisterAliasResponse {
    return { price: isSet(object.price) ? Coin.fromJSON(object.price) : undefined };
  },

  toJSON(message: EstimateRegisterAliasResponse): unknown {
    const obj: any = {};
    message.price !== undefined && (obj.price = message.price ? Coin.toJSON(message.price) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EstimateRegisterAliasResponse>, I>>(
    object: I,
  ): EstimateRegisterAliasResponse {
    const message = createBaseEstimateRegisterAliasResponse();
    message.price = (object.price !== undefined && object.price !== null) ? Coin.fromPartial(object.price) : undefined;
    return message;
  },
};

function createBaseReverseResolveAddressRequest(): ReverseResolveAddressRequest {
  return { addresses: [], workingChainId: "" };
}

export const ReverseResolveAddressRequest = {
  encode(message: ReverseResolveAddressRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.addresses) {
      writer.uint32(10).string(v!);
    }
    if (message.workingChainId !== "") {
      writer.uint32(18).string(message.workingChainId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReverseResolveAddressRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReverseResolveAddressRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.addresses.push(reader.string());
          break;
        case 2:
          message.workingChainId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReverseResolveAddressRequest {
    return {
      addresses: Array.isArray(object?.addresses) ? object.addresses.map((e: any) => String(e)) : [],
      workingChainId: isSet(object.workingChainId) ? String(object.workingChainId) : "",
    };
  },

  toJSON(message: ReverseResolveAddressRequest): unknown {
    const obj: any = {};
    if (message.addresses) {
      obj.addresses = message.addresses.map((e) => e);
    } else {
      obj.addresses = [];
    }
    message.workingChainId !== undefined && (obj.workingChainId = message.workingChainId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReverseResolveAddressRequest>, I>>(object: I): ReverseResolveAddressRequest {
    const message = createBaseReverseResolveAddressRequest();
    message.addresses = object.addresses?.map((e) => e) || [];
    message.workingChainId = object.workingChainId ?? "";
    return message;
  },
};

function createBaseReverseResolveAddressResponse(): ReverseResolveAddressResponse {
  return { result: {}, workingChainId: "" };
}

export const ReverseResolveAddressResponse = {
  encode(message: ReverseResolveAddressResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.result).forEach(([key, value]) => {
      ReverseResolveAddressResponse_ResultEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.workingChainId !== "") {
      writer.uint32(18).string(message.workingChainId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReverseResolveAddressResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReverseResolveAddressResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry1 = ReverseResolveAddressResponse_ResultEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.result[entry1.key] = entry1.value;
          }
          break;
        case 2:
          message.workingChainId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReverseResolveAddressResponse {
    return {
      result: isObject(object.result)
        ? Object.entries(object.result).reduce<{ [key: string]: ReverseResolveAddressResult }>((acc, [key, value]) => {
          acc[key] = ReverseResolveAddressResult.fromJSON(value);
          return acc;
        }, {})
        : {},
      workingChainId: isSet(object.workingChainId) ? String(object.workingChainId) : "",
    };
  },

  toJSON(message: ReverseResolveAddressResponse): unknown {
    const obj: any = {};
    obj.result = {};
    if (message.result) {
      Object.entries(message.result).forEach(([k, v]) => {
        obj.result[k] = ReverseResolveAddressResult.toJSON(v);
      });
    }
    message.workingChainId !== undefined && (obj.workingChainId = message.workingChainId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReverseResolveAddressResponse>, I>>(
    object: I,
  ): ReverseResolveAddressResponse {
    const message = createBaseReverseResolveAddressResponse();
    message.result = Object.entries(object.result ?? {}).reduce<{ [key: string]: ReverseResolveAddressResult }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ReverseResolveAddressResult.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.workingChainId = object.workingChainId ?? "";
    return message;
  },
};

function createBaseReverseResolveAddressResponse_ResultEntry(): ReverseResolveAddressResponse_ResultEntry {
  return { key: "", value: undefined };
}

export const ReverseResolveAddressResponse_ResultEntry = {
  encode(message: ReverseResolveAddressResponse_ResultEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      ReverseResolveAddressResult.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReverseResolveAddressResponse_ResultEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReverseResolveAddressResponse_ResultEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = ReverseResolveAddressResult.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReverseResolveAddressResponse_ResultEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ReverseResolveAddressResult.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ReverseResolveAddressResponse_ResultEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined
      && (obj.value = message.value ? ReverseResolveAddressResult.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReverseResolveAddressResponse_ResultEntry>, I>>(
    object: I,
  ): ReverseResolveAddressResponse_ResultEntry {
    const message = createBaseReverseResolveAddressResponse_ResultEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ReverseResolveAddressResult.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseReverseResolveAddressResult(): ReverseResolveAddressResult {
  return { candidates: [], error: "" };
}

export const ReverseResolveAddressResult = {
  encode(message: ReverseResolveAddressResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.candidates) {
      writer.uint32(10).string(v!);
    }
    if (message.error !== "") {
      writer.uint32(18).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReverseResolveAddressResult {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReverseResolveAddressResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.candidates.push(reader.string());
          break;
        case 2:
          message.error = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReverseResolveAddressResult {
    return {
      candidates: Array.isArray(object?.candidates) ? object.candidates.map((e: any) => String(e)) : [],
      error: isSet(object.error) ? String(object.error) : "",
    };
  },

  toJSON(message: ReverseResolveAddressResult): unknown {
    const obj: any = {};
    if (message.candidates) {
      obj.candidates = message.candidates.map((e) => e);
    } else {
      obj.candidates = [];
    }
    message.error !== undefined && (obj.error = message.error);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReverseResolveAddressResult>, I>>(object: I): ReverseResolveAddressResult {
    const message = createBaseReverseResolveAddressResult();
    message.candidates = object.candidates?.map((e) => e) || [];
    message.error = object.error ?? "";
    return message;
  },
};

function createBaseQueryTranslateAliasOrChainIdToChainIdRequest(): QueryTranslateAliasOrChainIdToChainIdRequest {
  return { aliasOrChainId: "" };
}

export const QueryTranslateAliasOrChainIdToChainIdRequest = {
  encode(message: QueryTranslateAliasOrChainIdToChainIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.aliasOrChainId !== "") {
      writer.uint32(10).string(message.aliasOrChainId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryTranslateAliasOrChainIdToChainIdRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTranslateAliasOrChainIdToChainIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.aliasOrChainId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryTranslateAliasOrChainIdToChainIdRequest {
    return { aliasOrChainId: isSet(object.aliasOrChainId) ? String(object.aliasOrChainId) : "" };
  },

  toJSON(message: QueryTranslateAliasOrChainIdToChainIdRequest): unknown {
    const obj: any = {};
    message.aliasOrChainId !== undefined && (obj.aliasOrChainId = message.aliasOrChainId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryTranslateAliasOrChainIdToChainIdRequest>, I>>(
    object: I,
  ): QueryTranslateAliasOrChainIdToChainIdRequest {
    const message = createBaseQueryTranslateAliasOrChainIdToChainIdRequest();
    message.aliasOrChainId = object.aliasOrChainId ?? "";
    return message;
  },
};

function createBaseQueryTranslateAliasOrChainIdToChainIdResponse(): QueryTranslateAliasOrChainIdToChainIdResponse {
  return { chainId: "" };
}

export const QueryTranslateAliasOrChainIdToChainIdResponse = {
  encode(message: QueryTranslateAliasOrChainIdToChainIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainId !== "") {
      writer.uint32(10).string(message.chainId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryTranslateAliasOrChainIdToChainIdResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryTranslateAliasOrChainIdToChainIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.chainId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryTranslateAliasOrChainIdToChainIdResponse {
    return { chainId: isSet(object.chainId) ? String(object.chainId) : "" };
  },

  toJSON(message: QueryTranslateAliasOrChainIdToChainIdResponse): unknown {
    const obj: any = {};
    message.chainId !== undefined && (obj.chainId = message.chainId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryTranslateAliasOrChainIdToChainIdResponse>, I>>(
    object: I,
  ): QueryTranslateAliasOrChainIdToChainIdResponse {
    const message = createBaseQueryTranslateAliasOrChainIdToChainIdResponse();
    message.chainId = object.chainId ?? "";
    return message;
  },
};

function createBaseQueryBuyOrderByIdRequest(): QueryBuyOrderByIdRequest {
  return { id: "" };
}

export const QueryBuyOrderByIdRequest = {
  encode(message: QueryBuyOrderByIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrderByIdRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrderByIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrderByIdRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: QueryBuyOrderByIdRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrderByIdRequest>, I>>(object: I): QueryBuyOrderByIdRequest {
    const message = createBaseQueryBuyOrderByIdRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseQueryBuyOrderByIdResponse(): QueryBuyOrderByIdResponse {
  return { buyOrder: undefined };
}

export const QueryBuyOrderByIdResponse = {
  encode(message: QueryBuyOrderByIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.buyOrder !== undefined) {
      BuyOrder.encode(message.buyOrder, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrderByIdResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrderByIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.buyOrder = BuyOrder.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrderByIdResponse {
    return { buyOrder: isSet(object.buyOrder) ? BuyOrder.fromJSON(object.buyOrder) : undefined };
  },

  toJSON(message: QueryBuyOrderByIdResponse): unknown {
    const obj: any = {};
    message.buyOrder !== undefined && (obj.buyOrder = message.buyOrder ? BuyOrder.toJSON(message.buyOrder) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrderByIdResponse>, I>>(object: I): QueryBuyOrderByIdResponse {
    const message = createBaseQueryBuyOrderByIdResponse();
    message.buyOrder = (object.buyOrder !== undefined && object.buyOrder !== null)
      ? BuyOrder.fromPartial(object.buyOrder)
      : undefined;
    return message;
  },
};

function createBaseQueryBuyOrdersPlacedByAccountRequest(): QueryBuyOrdersPlacedByAccountRequest {
  return { account: "" };
}

export const QueryBuyOrdersPlacedByAccountRequest = {
  encode(message: QueryBuyOrdersPlacedByAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.account !== "") {
      writer.uint32(10).string(message.account);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersPlacedByAccountRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersPlacedByAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.account = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersPlacedByAccountRequest {
    return { account: isSet(object.account) ? String(object.account) : "" };
  },

  toJSON(message: QueryBuyOrdersPlacedByAccountRequest): unknown {
    const obj: any = {};
    message.account !== undefined && (obj.account = message.account);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersPlacedByAccountRequest>, I>>(
    object: I,
  ): QueryBuyOrdersPlacedByAccountRequest {
    const message = createBaseQueryBuyOrdersPlacedByAccountRequest();
    message.account = object.account ?? "";
    return message;
  },
};

function createBaseQueryBuyOrdersPlacedByAccountResponse(): QueryBuyOrdersPlacedByAccountResponse {
  return { buyOrders: [] };
}

export const QueryBuyOrdersPlacedByAccountResponse = {
  encode(message: QueryBuyOrdersPlacedByAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.buyOrders) {
      BuyOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersPlacedByAccountResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersPlacedByAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.buyOrders.push(BuyOrder.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersPlacedByAccountResponse {
    return {
      buyOrders: Array.isArray(object?.buyOrders) ? object.buyOrders.map((e: any) => BuyOrder.fromJSON(e)) : [],
    };
  },

  toJSON(message: QueryBuyOrdersPlacedByAccountResponse): unknown {
    const obj: any = {};
    if (message.buyOrders) {
      obj.buyOrders = message.buyOrders.map((e) => e ? BuyOrder.toJSON(e) : undefined);
    } else {
      obj.buyOrders = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersPlacedByAccountResponse>, I>>(
    object: I,
  ): QueryBuyOrdersPlacedByAccountResponse {
    const message = createBaseQueryBuyOrdersPlacedByAccountResponse();
    message.buyOrders = object.buyOrders?.map((e) => BuyOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryBuyOrdersByDymNameRequest(): QueryBuyOrdersByDymNameRequest {
  return { name: "" };
}

export const QueryBuyOrdersByDymNameRequest = {
  encode(message: QueryBuyOrdersByDymNameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersByDymNameRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersByDymNameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersByDymNameRequest {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: QueryBuyOrdersByDymNameRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersByDymNameRequest>, I>>(
    object: I,
  ): QueryBuyOrdersByDymNameRequest {
    const message = createBaseQueryBuyOrdersByDymNameRequest();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseQueryBuyOrdersByDymNameResponse(): QueryBuyOrdersByDymNameResponse {
  return { buyOrders: [] };
}

export const QueryBuyOrdersByDymNameResponse = {
  encode(message: QueryBuyOrdersByDymNameResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.buyOrders) {
      BuyOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersByDymNameResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersByDymNameResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.buyOrders.push(BuyOrder.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersByDymNameResponse {
    return {
      buyOrders: Array.isArray(object?.buyOrders) ? object.buyOrders.map((e: any) => BuyOrder.fromJSON(e)) : [],
    };
  },

  toJSON(message: QueryBuyOrdersByDymNameResponse): unknown {
    const obj: any = {};
    if (message.buyOrders) {
      obj.buyOrders = message.buyOrders.map((e) => e ? BuyOrder.toJSON(e) : undefined);
    } else {
      obj.buyOrders = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersByDymNameResponse>, I>>(
    object: I,
  ): QueryBuyOrdersByDymNameResponse {
    const message = createBaseQueryBuyOrdersByDymNameResponse();
    message.buyOrders = object.buyOrders?.map((e) => BuyOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryBuyOrdersOfDymNamesOwnedByAccountRequest(): QueryBuyOrdersOfDymNamesOwnedByAccountRequest {
  return { account: "" };
}

export const QueryBuyOrdersOfDymNamesOwnedByAccountRequest = {
  encode(message: QueryBuyOrdersOfDymNamesOwnedByAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.account !== "") {
      writer.uint32(10).string(message.account);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersOfDymNamesOwnedByAccountRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersOfDymNamesOwnedByAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.account = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersOfDymNamesOwnedByAccountRequest {
    return { account: isSet(object.account) ? String(object.account) : "" };
  },

  toJSON(message: QueryBuyOrdersOfDymNamesOwnedByAccountRequest): unknown {
    const obj: any = {};
    message.account !== undefined && (obj.account = message.account);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersOfDymNamesOwnedByAccountRequest>, I>>(
    object: I,
  ): QueryBuyOrdersOfDymNamesOwnedByAccountRequest {
    const message = createBaseQueryBuyOrdersOfDymNamesOwnedByAccountRequest();
    message.account = object.account ?? "";
    return message;
  },
};

function createBaseQueryBuyOrdersOfDymNamesOwnedByAccountResponse(): QueryBuyOrdersOfDymNamesOwnedByAccountResponse {
  return { buyOrders: [] };
}

export const QueryBuyOrdersOfDymNamesOwnedByAccountResponse = {
  encode(
    message: QueryBuyOrdersOfDymNamesOwnedByAccountResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.buyOrders) {
      BuyOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersOfDymNamesOwnedByAccountResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersOfDymNamesOwnedByAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.buyOrders.push(BuyOrder.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersOfDymNamesOwnedByAccountResponse {
    return {
      buyOrders: Array.isArray(object?.buyOrders) ? object.buyOrders.map((e: any) => BuyOrder.fromJSON(e)) : [],
    };
  },

  toJSON(message: QueryBuyOrdersOfDymNamesOwnedByAccountResponse): unknown {
    const obj: any = {};
    if (message.buyOrders) {
      obj.buyOrders = message.buyOrders.map((e) => e ? BuyOrder.toJSON(e) : undefined);
    } else {
      obj.buyOrders = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersOfDymNamesOwnedByAccountResponse>, I>>(
    object: I,
  ): QueryBuyOrdersOfDymNamesOwnedByAccountResponse {
    const message = createBaseQueryBuyOrdersOfDymNamesOwnedByAccountResponse();
    message.buyOrders = object.buyOrders?.map((e) => BuyOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryBuyOrdersByAliasRequest(): QueryBuyOrdersByAliasRequest {
  return { alias: "" };
}

export const QueryBuyOrdersByAliasRequest = {
  encode(message: QueryBuyOrdersByAliasRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.alias !== "") {
      writer.uint32(10).string(message.alias);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersByAliasRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersByAliasRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.alias = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersByAliasRequest {
    return { alias: isSet(object.alias) ? String(object.alias) : "" };
  },

  toJSON(message: QueryBuyOrdersByAliasRequest): unknown {
    const obj: any = {};
    message.alias !== undefined && (obj.alias = message.alias);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersByAliasRequest>, I>>(object: I): QueryBuyOrdersByAliasRequest {
    const message = createBaseQueryBuyOrdersByAliasRequest();
    message.alias = object.alias ?? "";
    return message;
  },
};

function createBaseQueryBuyOrdersByAliasResponse(): QueryBuyOrdersByAliasResponse {
  return { buyOrders: [] };
}

export const QueryBuyOrdersByAliasResponse = {
  encode(message: QueryBuyOrdersByAliasResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.buyOrders) {
      BuyOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersByAliasResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersByAliasResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.buyOrders.push(BuyOrder.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersByAliasResponse {
    return {
      buyOrders: Array.isArray(object?.buyOrders) ? object.buyOrders.map((e: any) => BuyOrder.fromJSON(e)) : [],
    };
  },

  toJSON(message: QueryBuyOrdersByAliasResponse): unknown {
    const obj: any = {};
    if (message.buyOrders) {
      obj.buyOrders = message.buyOrders.map((e) => e ? BuyOrder.toJSON(e) : undefined);
    } else {
      obj.buyOrders = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersByAliasResponse>, I>>(
    object: I,
  ): QueryBuyOrdersByAliasResponse {
    const message = createBaseQueryBuyOrdersByAliasResponse();
    message.buyOrders = object.buyOrders?.map((e) => BuyOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryBuyOrdersOfAliasesLinkedToRollAppRequest(): QueryBuyOrdersOfAliasesLinkedToRollAppRequest {
  return { rollappId: "" };
}

export const QueryBuyOrdersOfAliasesLinkedToRollAppRequest = {
  encode(message: QueryBuyOrdersOfAliasesLinkedToRollAppRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollappId !== "") {
      writer.uint32(10).string(message.rollappId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersOfAliasesLinkedToRollAppRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersOfAliasesLinkedToRollAppRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rollappId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersOfAliasesLinkedToRollAppRequest {
    return { rollappId: isSet(object.rollappId) ? String(object.rollappId) : "" };
  },

  toJSON(message: QueryBuyOrdersOfAliasesLinkedToRollAppRequest): unknown {
    const obj: any = {};
    message.rollappId !== undefined && (obj.rollappId = message.rollappId);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersOfAliasesLinkedToRollAppRequest>, I>>(
    object: I,
  ): QueryBuyOrdersOfAliasesLinkedToRollAppRequest {
    const message = createBaseQueryBuyOrdersOfAliasesLinkedToRollAppRequest();
    message.rollappId = object.rollappId ?? "";
    return message;
  },
};

function createBaseQueryBuyOrdersOfAliasesLinkedToRollAppResponse(): QueryBuyOrdersOfAliasesLinkedToRollAppResponse {
  return { buyOrders: [] };
}

export const QueryBuyOrdersOfAliasesLinkedToRollAppResponse = {
  encode(
    message: QueryBuyOrdersOfAliasesLinkedToRollAppResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.buyOrders) {
      BuyOrder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QueryBuyOrdersOfAliasesLinkedToRollAppResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryBuyOrdersOfAliasesLinkedToRollAppResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.buyOrders.push(BuyOrder.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): QueryBuyOrdersOfAliasesLinkedToRollAppResponse {
    return {
      buyOrders: Array.isArray(object?.buyOrders) ? object.buyOrders.map((e: any) => BuyOrder.fromJSON(e)) : [],
    };
  },

  toJSON(message: QueryBuyOrdersOfAliasesLinkedToRollAppResponse): unknown {
    const obj: any = {};
    if (message.buyOrders) {
      obj.buyOrders = message.buyOrders.map((e) => e ? BuyOrder.toJSON(e) : undefined);
    } else {
      obj.buyOrders = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<QueryBuyOrdersOfAliasesLinkedToRollAppResponse>, I>>(
    object: I,
  ): QueryBuyOrdersOfAliasesLinkedToRollAppResponse {
    const message = createBaseQueryBuyOrdersOfAliasesLinkedToRollAppResponse();
    message.buyOrders = object.buyOrders?.map((e) => BuyOrder.fromPartial(e)) || [];
    return message;
  },
};

/** Query defines the gRPC querier service. */
export interface Query {
  /** Params queries the parameters of the module. */
  Params(request: QueryParamsRequest): Promise<QueryParamsResponse>;
  /** DymName queries a Dym-Name by its name. */
  DymName(request: QueryDymNameRequest): Promise<QueryDymNameResponse>;
  /** Alias queries the chain_id associated as well as the Sell-Order and Buy-Order IDs relates to the alias. */
  Alias(request: QueryAliasRequest): Promise<QueryAliasResponse>;
  /** Aliases queries all the aliases for a chain id or all chains. */
  Aliases(request: QueryAliasesRequest): Promise<QueryAliasesResponse>;
  /**
   * ResolveDymNameAddresses resolves multiple Dym-Name Addresses to account address of each pointing to.
   *
   * For example:
   *   - "my-name@dym" => "dym1a..."
   *   - "another.my-name@dym" => "dym1b..."
   *   - "my-name@nim" => "nim1..."
   *   - (extra format) "0x1234...6789@nim" => "nim1..."
   *   - (extra format) "dym1a...@nim" => "nim1..."
   */
  ResolveDymNameAddresses(request: ResolveDymNameAddressesRequest): Promise<ResolveDymNameAddressesResponse>;
  /** DymNamesOwnedByAccount queries the Dym-Names owned by an account. */
  DymNamesOwnedByAccount(request: QueryDymNamesOwnedByAccountRequest): Promise<QueryDymNamesOwnedByAccountResponse>;
  /** SellOrder queries the active SO of a Dym-Name/Alias. */
  SellOrder(request: QuerySellOrderRequest): Promise<QuerySellOrderResponse>;
  /** EstimateRegisterName estimates the cost to register a Dym-Name. */
  EstimateRegisterName(request: EstimateRegisterNameRequest): Promise<EstimateRegisterNameResponse>;
  /** EstimateRegisterAlias estimates the cost to register an Alias. */
  EstimateRegisterAlias(request: EstimateRegisterAliasRequest): Promise<EstimateRegisterAliasResponse>;
  /**
   * ReverseResolveAddress resolves multiple account addresses to Dym-Name Addresses which point to each.
   * This function may return multiple possible Dym-Name-Addresses those point to each of the input address.
   *
   * For example: when we have "my-name@dym" resolves to "dym1a..."
   * so reverse resolve will return "my-name@dym" when input is "dym1a..."
   */
  ReverseResolveAddress(request: ReverseResolveAddressRequest): Promise<ReverseResolveAddressResponse>;
  /**
   * TranslateAliasOrChainIdToChainId tries to translate an alias/handle to a chain id.
   * If an alias/handle can not be translated to chain-id, it is treated as a chain-id and returns.
   */
  TranslateAliasOrChainIdToChainId(
    request: QueryTranslateAliasOrChainIdToChainIdRequest,
  ): Promise<QueryTranslateAliasOrChainIdToChainIdResponse>;
  /** BuyOrderById queries a Buy-Order by its id. */
  BuyOrderById(request: QueryBuyOrderByIdRequest): Promise<QueryBuyOrderByIdResponse>;
  /** BuyOrdersPlacedByAccount queries the all the buy orders placed by an account. */
  BuyOrdersPlacedByAccount(
    request: QueryBuyOrdersPlacedByAccountRequest,
  ): Promise<QueryBuyOrdersPlacedByAccountResponse>;
  /** BuyOrdersByDymName queries all the buy orders of a Dym-Name. */
  BuyOrdersByDymName(request: QueryBuyOrdersByDymNameRequest): Promise<QueryBuyOrdersByDymNameResponse>;
  /** BuyOrdersOfDymNamesOwnedByAccount queries all the buy orders of all Dym-Names owned by an account. */
  BuyOrdersOfDymNamesOwnedByAccount(
    request: QueryBuyOrdersOfDymNamesOwnedByAccountRequest,
  ): Promise<QueryBuyOrdersOfDymNamesOwnedByAccountResponse>;
  /** BuyOrdersByAlias queries all the buy orders of an Alias. */
  BuyOrdersByAlias(request: QueryBuyOrdersByAliasRequest): Promise<QueryBuyOrdersByAliasResponse>;
  /** BuyOrdersOfAliasesLinkedToRollApp queries all the buy orders of all Aliases linked to a RollApp. */
  BuyOrdersOfAliasesLinkedToRollApp(
    request: QueryBuyOrdersOfAliasesLinkedToRollAppRequest,
  ): Promise<QueryBuyOrdersOfAliasesLinkedToRollAppResponse>;
}

export class QueryClientImpl implements Query {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Params = this.Params.bind(this);
    this.DymName = this.DymName.bind(this);
    this.Alias = this.Alias.bind(this);
    this.Aliases = this.Aliases.bind(this);
    this.ResolveDymNameAddresses = this.ResolveDymNameAddresses.bind(this);
    this.DymNamesOwnedByAccount = this.DymNamesOwnedByAccount.bind(this);
    this.SellOrder = this.SellOrder.bind(this);
    this.EstimateRegisterName = this.EstimateRegisterName.bind(this);
    this.EstimateRegisterAlias = this.EstimateRegisterAlias.bind(this);
    this.ReverseResolveAddress = this.ReverseResolveAddress.bind(this);
    this.TranslateAliasOrChainIdToChainId = this.TranslateAliasOrChainIdToChainId.bind(this);
    this.BuyOrderById = this.BuyOrderById.bind(this);
    this.BuyOrdersPlacedByAccount = this.BuyOrdersPlacedByAccount.bind(this);
    this.BuyOrdersByDymName = this.BuyOrdersByDymName.bind(this);
    this.BuyOrdersOfDymNamesOwnedByAccount = this.BuyOrdersOfDymNamesOwnedByAccount.bind(this);
    this.BuyOrdersByAlias = this.BuyOrdersByAlias.bind(this);
    this.BuyOrdersOfAliasesLinkedToRollApp = this.BuyOrdersOfAliasesLinkedToRollApp.bind(this);
  }
  Params(request: QueryParamsRequest): Promise<QueryParamsResponse> {
    const data = QueryParamsRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "Params", data);
    return promise.then((data) => QueryParamsResponse.decode(new _m0.Reader(data)));
  }

  DymName(request: QueryDymNameRequest): Promise<QueryDymNameResponse> {
    const data = QueryDymNameRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "DymName", data);
    return promise.then((data) => QueryDymNameResponse.decode(new _m0.Reader(data)));
  }

  Alias(request: QueryAliasRequest): Promise<QueryAliasResponse> {
    const data = QueryAliasRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "Alias", data);
    return promise.then((data) => QueryAliasResponse.decode(new _m0.Reader(data)));
  }

  Aliases(request: QueryAliasesRequest): Promise<QueryAliasesResponse> {
    const data = QueryAliasesRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "Aliases", data);
    return promise.then((data) => QueryAliasesResponse.decode(new _m0.Reader(data)));
  }

  ResolveDymNameAddresses(request: ResolveDymNameAddressesRequest): Promise<ResolveDymNameAddressesResponse> {
    const data = ResolveDymNameAddressesRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "ResolveDymNameAddresses", data);
    return promise.then((data) => ResolveDymNameAddressesResponse.decode(new _m0.Reader(data)));
  }

  DymNamesOwnedByAccount(request: QueryDymNamesOwnedByAccountRequest): Promise<QueryDymNamesOwnedByAccountResponse> {
    const data = QueryDymNamesOwnedByAccountRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "DymNamesOwnedByAccount", data);
    return promise.then((data) => QueryDymNamesOwnedByAccountResponse.decode(new _m0.Reader(data)));
  }

  SellOrder(request: QuerySellOrderRequest): Promise<QuerySellOrderResponse> {
    const data = QuerySellOrderRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "SellOrder", data);
    return promise.then((data) => QuerySellOrderResponse.decode(new _m0.Reader(data)));
  }

  EstimateRegisterName(request: EstimateRegisterNameRequest): Promise<EstimateRegisterNameResponse> {
    const data = EstimateRegisterNameRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "EstimateRegisterName", data);
    return promise.then((data) => EstimateRegisterNameResponse.decode(new _m0.Reader(data)));
  }

  EstimateRegisterAlias(request: EstimateRegisterAliasRequest): Promise<EstimateRegisterAliasResponse> {
    const data = EstimateRegisterAliasRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "EstimateRegisterAlias", data);
    return promise.then((data) => EstimateRegisterAliasResponse.decode(new _m0.Reader(data)));
  }

  ReverseResolveAddress(request: ReverseResolveAddressRequest): Promise<ReverseResolveAddressResponse> {
    const data = ReverseResolveAddressRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "ReverseResolveAddress", data);
    return promise.then((data) => ReverseResolveAddressResponse.decode(new _m0.Reader(data)));
  }

  TranslateAliasOrChainIdToChainId(
    request: QueryTranslateAliasOrChainIdToChainIdRequest,
  ): Promise<QueryTranslateAliasOrChainIdToChainIdResponse> {
    const data = QueryTranslateAliasOrChainIdToChainIdRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "TranslateAliasOrChainIdToChainId", data);
    return promise.then((data) => QueryTranslateAliasOrChainIdToChainIdResponse.decode(new _m0.Reader(data)));
  }

  BuyOrderById(request: QueryBuyOrderByIdRequest): Promise<QueryBuyOrderByIdResponse> {
    const data = QueryBuyOrderByIdRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "BuyOrderById", data);
    return promise.then((data) => QueryBuyOrderByIdResponse.decode(new _m0.Reader(data)));
  }

  BuyOrdersPlacedByAccount(
    request: QueryBuyOrdersPlacedByAccountRequest,
  ): Promise<QueryBuyOrdersPlacedByAccountResponse> {
    const data = QueryBuyOrdersPlacedByAccountRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "BuyOrdersPlacedByAccount", data);
    return promise.then((data) => QueryBuyOrdersPlacedByAccountResponse.decode(new _m0.Reader(data)));
  }

  BuyOrdersByDymName(request: QueryBuyOrdersByDymNameRequest): Promise<QueryBuyOrdersByDymNameResponse> {
    const data = QueryBuyOrdersByDymNameRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "BuyOrdersByDymName", data);
    return promise.then((data) => QueryBuyOrdersByDymNameResponse.decode(new _m0.Reader(data)));
  }

  BuyOrdersOfDymNamesOwnedByAccount(
    request: QueryBuyOrdersOfDymNamesOwnedByAccountRequest,
  ): Promise<QueryBuyOrdersOfDymNamesOwnedByAccountResponse> {
    const data = QueryBuyOrdersOfDymNamesOwnedByAccountRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "BuyOrdersOfDymNamesOwnedByAccount", data);
    return promise.then((data) => QueryBuyOrdersOfDymNamesOwnedByAccountResponse.decode(new _m0.Reader(data)));
  }

  BuyOrdersByAlias(request: QueryBuyOrdersByAliasRequest): Promise<QueryBuyOrdersByAliasResponse> {
    const data = QueryBuyOrdersByAliasRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "BuyOrdersByAlias", data);
    return promise.then((data) => QueryBuyOrdersByAliasResponse.decode(new _m0.Reader(data)));
  }

  BuyOrdersOfAliasesLinkedToRollApp(
    request: QueryBuyOrdersOfAliasesLinkedToRollAppRequest,
  ): Promise<QueryBuyOrdersOfAliasesLinkedToRollAppResponse> {
    const data = QueryBuyOrdersOfAliasesLinkedToRollAppRequest.encode(request).finish();
    const promise = this.rpc.request("dymensionxyz.dymension.dymns.Query", "BuyOrdersOfAliasesLinkedToRollApp", data);
    return promise.then((data) => QueryBuyOrdersOfAliasesLinkedToRollAppResponse.decode(new _m0.Reader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
