import { FC } from 'react';
import Dialog, { DialogContent, DialogProps } from '../../../../../shared/components/dialog/dialog';
import Spinner from '../../../../../shared/components/spinner/spinner';
import './genesis-validator-modal.scss';

const GenesisValidatorModal: FC<DialogProps> = (dialogProps) => {

    return (
        <Dialog className='genesis-validator-modal' {...dialogProps}>
            <DialogContent className='modal-content'>
                <Spinner className='modal-spinner' />
                Your genesis info is being validated.<br />This might take some time.
            </DialogContent>
        </Dialog>
    );
};

export default GenesisValidatorModal;
