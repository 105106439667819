import React, { ReactNode } from 'react';
import Badge from '../../../../../../../shared/components/badge/badge';
import { Option } from '../../../../../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../../../../../shared/components/form-controls/select/select';
import { getCssVariableValue } from '../../../../../../../shared/utils/color-utils';
import { ROLLAPP_TAGS, RollAppTag } from '../../../../../rollapp-types';
import { useCreateRollapp } from '../../../create-rollapp-context';
import Input from '../../../../../../../shared/components/form-controls/input/input';
import TextArea from '../../../../../../../shared/components/form-controls/text-area/text-area';
import { validateUrl } from '../../../../../../../shared/utils/text-utils';
import { MAX_DESCRIPTION_LENGTH, MAX_URL_LENGTH } from '../../../types';
import './metadata-section.scss';

const MetadataSection: React.FC = () => {
    const { rollapp, updateRollapp } = useCreateRollapp();

    const renderTags = (tags: RollAppTag[]): ReactNode => {
        const color = getCssVariableValue('--light-blue-rgb').split(',').map(Number);
        return <>
            {ROLLAPP_TAGS.filter((tag) => tags.includes(tag)).slice(0, 3).map((tag) => (
                <Badge size='small' className='rollapp-tag-badge' rounded label={tag} key={tag} color={color} />
            ))}
            {tags.length > 3 && <small className='more-label'>(+{tags.length - 3} more)</small>}
        </>;
    };

    return (
        <div className='metadata-section section'>
            <h5 className='section-header'>Metadata</h5>
            <div className='controls-row'>
                <div className='control-container'>
                    <div className='control-label-container'><label>Website</label></div>
                    <Input
                        value={rollapp.website}
                        onTypeFinish={(value) => updateRollapp('website', value)}
                        placeholder='e.g., https://xyz.com/'
                        typeFinishDelay={1000}
                        maxLength={MAX_URL_LENGTH}
                        error={rollapp.website && validateUrl(rollapp.website)}
                    />
                </div>
                <div className='control-container'>
                    <div className='control-label-container'><label>X Link</label></div>
                    <Input
                        value={rollapp.xAccount}
                        placeholder='e.g., https://x.com/XYZ'
                        onTypeFinish={(value) => updateRollapp('xAccount', value)}
                        typeFinishDelay={1000}
                        maxLength={MAX_URL_LENGTH}
                        error={rollapp.xAccount && validateUrl(rollapp.xAccount)}
                    />
                </div>
                <div className='control-container'>
                    <div className='control-label-container'><label>Telegram Link</label></div>
                    <Input
                        value={rollapp.tgAccount}
                        placeholder='e.g., https://t.me/xyz'
                        onTypeFinish={(value) => updateRollapp('tgAccount', value)}
                        typeFinishDelay={1000}
                        maxLength={MAX_URL_LENGTH}
                        error={rollapp.tgAccount && validateUrl(rollapp.tgAccount)}
                    />
                </div>
            </div>
            <div className='controls-row'>
                <div className='control-container'>
                    <div className='control-label-container'><label>Tagline</label></div>
                    <Input
                        value={rollapp.shortDescription}
                        maxLength={64}
                        onValueChange={(value) => updateRollapp('shortDescription', value as string)}
                    />
                </div>
                <div className='control-container tags'>
                    <div className='control-label-container'><label>Tags</label></div>
                    <Select
                        multiselect
                        value={rollapp.tags}
                        maxValues={3}
                        placeholder='Select tags'
                        className='tags-selector'
                        renderTriggerSelectedOption={(values) => renderTags(values as RollAppTag[])}
                        onSelect={(value) => updateRollapp('tags', value as RollAppTag[])}
                    >
                        {ROLLAPP_TAGS.map((tag) => <Option value={tag} key={tag}>{tag}</Option>)}
                    </Select>
                </div>
            </div>
            <div className='control-container'>
                <div className='control-label-container'>
                    <label>Description{rollapp.description ? ` (${rollapp.description.length}/${MAX_DESCRIPTION_LENGTH})` : ''}</label>
                </div>
                <TextArea
                    value={rollapp.description}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    onValueChange={(value) => updateRollapp('description', value as string)}
                />
            </div>
        </div>
    );
};

export default MetadataSection;
