import { Gauge } from '../client/station-clients/dymension/generated/incentives/gauge';
import { Stream } from '../client/station-clients/dymension/generated/streamer/stream';
import { Incentive, IncentivesParams } from './types';

export interface IncentivesState {
    incentives?: { [denom: string]: Incentive[] };
    params?: IncentivesParams;
    streams?: Stream[];
    gauges?: Gauge[];
    loading?: boolean;
    paramsLoading?: boolean;
}

export const INCENTIVES_STATE_DEFAULT: IncentivesState = {
    loading: true,
    paramsLoading: true,
};

export type IncentivesAction =
    { type: 'set-params', payload: IncentivesParams | undefined } |
    { type: 'set-incentives', payload: { [denom: string]: Incentive[] } | undefined } |
    { type: 'set-gauges', payload: Gauge[] | undefined } |
    { type: 'set-streams', payload: Stream[] | undefined } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-loading', payload?: boolean };

export const incentivesReducer = (state: IncentivesState, action: IncentivesAction): IncentivesState => {
    switch (action.type) {
        case 'set-params':
            return { ...state, params: action.payload, paramsLoading: false };
        case 'set-incentives':
            return { ...state, incentives: action.payload, loading: false };
        case 'set-gauges':
            return { ...state, gauges: action.payload, loading: false };
        case 'set-streams':
            return { ...state, streams: action.payload, loading: false };
        case 'set-loading':
            return { ...state, loading: action.payload ?? true };
        case 'set-params-loading':
            return { ...state, paramsLoading: action.payload ?? true };
        default:
            return state;
    }
};

