import React from 'react';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { AnalyticsState } from '../../../analytics/analytics-state';
import { getMaxDenomAmount } from '../../../currency/currency-service';
import AreaChart from '../../../network/statistics/charts/area-chart/area-chart';
import { SelectableChartProps } from '../../../network/statistics/charts/chart-container/chart-container';
import { useAmm } from '../../amm-context';
import { PoolAnalytics } from '../../statistics/analytics/pool-analytics-types';

interface TradingVolumeChartProps extends SelectableChartProps {
    analyticsState: AnalyticsState<PoolAnalytics>;
    className?: string;
}

export const TradingVolumeChart: React.FC<TradingVolumeChartProps> = ({ analyticsState, className, ...selectableChartProps }) => {
    const { ammState } = useAmm();

    return (
        <AreaChart
            {...selectableChartProps}
            label='Trading Volume'
            valueLabel='Amount'
            compareDiffs
            formatValue={(value, options) =>
                formatPrice(ammState.params ? getMaxDenomAmount(value, ammState.params?.vsCoins.currency) : 0, undefined, options)}
            className={className}
            loading={analyticsState.loading}
            data={analyticsState.analytics?.volume}
        />
    );
};

export default TradingVolumeChart;
