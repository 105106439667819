import React, { useMemo } from 'react';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { AnalyticsState } from '../../../analytics/analytics-state';
import AreaChart from '../../../network/statistics/charts/area-chart/area-chart';
import { SelectableChartProps } from '../../../network/statistics/charts/chart-container/chart-container';
import { PoolAnalytics } from '../../statistics/analytics/pool-analytics-types';
import { Pool } from '../../types';

interface PoolPriceChartChartProps extends SelectableChartProps {
    pool: Pool,
    analyticsState: AnalyticsState<PoolAnalytics>;
    className?: string;
}

export const PoolPriceChart: React.FC<PoolPriceChartChartProps> = ({
    pool,
    analyticsState,
    className,
    ...selectableChartProps
}) => {

    const decimalsValueFactor = useMemo(
        () => Math.pow(10, pool.assets[1].currency.decimals - pool.assets[0].currency.decimals),
        [ pool.assets ],
    );

    return (
        <AreaChart
            {...selectableChartProps}
            label={`Price (${pool.assets[0].currency.displayDenom} per ${pool.assets[1].currency.displayDenom})`}
            valueLabel='Price'
            fetchComparableValues={(value) => (value.asset1Amount / value.asset2Amount) * decimalsValueFactor}
            formatValue={(value, options) => formatPrice(value, '', options)}
            className={className}
            loading={analyticsState.loading}
            data={analyticsState.analytics?.liquidity}
        />
    );
};

export default PoolPriceChart;
