import classNames from 'classnames';
import React from 'react';
import useCopyToClipboard from '../../hooks/use-copy-to-clipboard';
import './copyable-item.scss';
import Tooltip, { TooltipPlacement } from '../tooltip/tooltip';

interface CopyableItemProps {
    text: string,
    dataName?: string
    className?: string;
    tooltipPlacement?: TooltipPlacement;
}

const CopyableItem: React.FC<CopyableItemProps> = ({ text, dataName, className, tooltipPlacement }) => {
    const copyToClipboard = useCopyToClipboard();

    return (
        <Tooltip
            title={`Copy${dataName ? ' ' + dataName : ''}`}
            clickTitle={`${dataName ? dataName + ' ' : ''}copied!`}
            placement={tooltipPlacement}
        >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
                className={classNames('copyable-item', className)}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    copyToClipboard(text, dataName);
                }}
            >
                {text}
            </a>
        </Tooltip>
    );
};

export default CopyableItem;
