import { Scanner } from '@yudiel/react-qr-scanner';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Input from '../../../../shared/components/form-controls/input/input';
import { useSnackbar } from '../../../../shared/components/snackbar/snackbar-context';
import { PIN_CODE_DIGITS_CHARACTERS } from '../../../../shared/utils/text-utils';
import { useQuickAuth } from '../../../quick-auth/quick-auth-context';
import { decryptQuickAuthAccount } from '../../../quick-auth/quick-auth-service';
import { useWallet } from '../../../wallet/wallet-context';
import WalletLogo from '../../../wallet/wallet-logo/wallet-logo';
import './connect-quick-auth-wallet-dialog.scss';
import { PIN_CODE_LENGTH } from '../../../quick-auth/quick-auth-types';

interface ConnectPortalWalletDialogProps extends DialogProps {
    onConnect?: () => void;
    urlEncryptedAccount?: string;
}

const EMPTY_CHARACTER = '-';

const ConnectQuickAuthWalletDialog: React.FC<ConnectPortalWalletDialogProps> = ({
    className,
    onConnect,
    urlEncryptedAccount,
    ...otherDialogProps
}) => {
    const { connectWallet } = useWallet();
    const { showErrorMessage } = useSnackbar();
    const [ encryptedAccount, setEncryptedAccount ] = useState<string | undefined>(urlEncryptedAccount);
    const [ pinCode, setPinCode ] = useState<string>();
    const { networkState, setFromQuickAuthAccount } = useQuickAuth();
    const pinCodeCharInputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const onConfirmClick = useCallback(async (): Promise<void> => {
        if (!encryptedAccount || !pinCode || !networkState.network) {
            return;
        }
        try {
            const { error, account } = await decryptQuickAuthAccount(encryptedAccount, pinCode);
            if (error) {
                showErrorMessage(error);
                return;
            } else if (account) {
                setFromQuickAuthAccount(account);
                connectWallet(networkState.network.chainId, 'Quick Auth', false, false, { account });
                onConnect?.();
            }
            otherDialogProps.onRequestClose?.();
        } catch (error) {
            console.error(error, encryptedAccount);
            showErrorMessage('Something went wrong, please try again later');
        }
    }, [
        connectWallet,
        encryptedAccount,
        networkState.network,
        onConnect,
        otherDialogProps,
        pinCode,
        setFromQuickAuthAccount,
        showErrorMessage,
    ]);

    const onPinCharacterKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>, charIndex: number) => {
        event.preventDefault();
        event.stopPropagation();

        const charInput = pinCodeCharInputRefs.current[charIndex] as HTMLInputElement;
        if (event.key === 'Unidentified') {
            charInput.value = '';
        }
        setTimeout(() => {
            const key = event.key === 'Unidentified' ? charInput.value : event.key;
            if (key && PIN_CODE_DIGITS_CHARACTERS.includes(key)) {
                charInput.value = key;
                charIndex++;
            } else if (key === 'Enter' || key === 'ArrowRight') {
                charIndex++;
            } else if (/\s/.test(key) || key === 'Space') {
                charInput.value = '';
                charIndex++;
            } else if (key === 'Tab') {
                charIndex += (event.shiftKey ? -1 : 1);
            } else if (key === 'Backspace' || key === '') {
                charInput.value = '';
                charIndex--;
            } else if (key === 'ArrowLeft') {
                charIndex--;
            } else {
                return;
            }
            setPinCode(pinCodeCharInputRefs.current.map((charInput) => charInput?.value || EMPTY_CHARACTER).join(''));
            charIndex = Math.max(0, Math.min(pinCodeCharInputRefs.current.length - 1, charIndex));
            const nextCharInput = pinCodeCharInputRefs.current[charIndex];
            nextCharInput?.focus();
            nextCharInput?.select();
        });
    }, []);

    return (
        <Dialog className={classNames('init-quick-auth-wallet-dialog', className)} closable {...otherDialogProps}>
            <DialogTitle className='dialog-title'>
                <WalletLogo className='wallet-logo' type='Quick Auth' />&nbsp;Quick Auth
            </DialogTitle>
            <DialogContent className='dialog-content'>
                {!encryptedAccount ? <>
                    <p>Open the account menu on your desktop, click the quick-auth thunder button, and then scan the displayed QR code.</p>
                    <div className='qr-scanner-container'>
                        <Scanner
                            onScan={(result) => {
                                const value = result[0].rawValue
                                    .replace(`${window.location.origin}`, '')
                                    .replace(/(\/(\/connect\/)?account\/)/, '')
                                    .replace(/.*\?qrAccount=/, '');
                                setEncryptedAccount(value);
                            }}
                        />
                    </div>
                </> : (
                    <div className='pin-code-section'>
                        <p className='pin-code-label'>Enter confirmation PIN code:</p>
                        <div className='pin-code-characters'>
                            {Array.from({ length: PIN_CODE_LENGTH }).map((_, charIndex) => (
                                <Input
                                    ref={(ref) => pinCodeCharInputRefs.current[charIndex] = ref}
                                    key={charIndex}
                                    className='pin-code-character-input'
                                    controlSize='large'
                                    type='number'
                                    maxLength={1}
                                    max={1}
                                    minLength={1}
                                    spellCheck={false}
                                    value={pinCode?.charAt(charIndex) === EMPTY_CHARACTER ? '' : pinCode?.charAt(charIndex)}
                                    onKeyDown={(event) => onPinCharacterKeyDown(event, charIndex)}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogAction
                primary
                className='confirm-button'
                onClick={onConfirmClick}
                disabled={!pinCode || pinCode.replaceAll(EMPTY_CHARACTER, '').length < PIN_CODE_LENGTH}
            >
                Connect
            </DialogAction>
        </Dialog>
    );
};

export default ConnectQuickAuthWalletDialog;
