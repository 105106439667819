import React from 'react';
import StatisticsCards, { StatisticsCardsProps } from '../../../shared/components/statistics-cards/statistics-cards';
import TotalSupplyProperty from '../../network/statistics/properties/total-supply-property';
import TotalTvlProperty from '../../network/statistics/properties/total-tvl-property';
import MarketCapProperty from '../../network/statistics/properties/market-cap-property/market-cap-property';
import StakingAprProperty from '../../network/statistics/properties/staking-apr-property';
import IbcTransfersProperty from '../../network/statistics/properties/ibc-transfers-property';
import VmProperty from '../../network/statistics/properties/vm-property/vm-property';
import { useNetworkDashboard } from '../network-dashboard-context';

export const NetworkStatistics: React.FC<Omit<StatisticsCardsProps, 'children'>> = (props) => {
    const { network } = useNetworkDashboard();

    return (
        <StatisticsCards {...props}>
            {network.tokenless ? <VmProperty /> : <MarketCapProperty network={network} fullyDiluted={network.type !== 'Hub'} />}

            <TotalTvlProperty />

            {network.type === 'Hub' ? <IbcTransfersProperty /> : <TotalSupplyProperty />}

            <StakingAprProperty />
        </StatisticsCards>
    );
};

export default NetworkStatistics;
