import { Decimal } from 'cosmjs/packages/math';
import React, { useCallback, useMemo } from 'react';
import { useCreateRollapp } from '../../../create-rollapp-context';
import Input from '../../../../../../../shared/components/form-controls/input/input';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import { DEFAULT_DENOM_EXPONENT, Distribution } from '../../../types';

const DistributionSection: React.FC = () => {
    const { rollapp, distribution, setDistribution, setShouldGenerateGenesis } = useCreateRollapp();

    const onDistributionChange = useCallback((
        type: keyof Distribution,
        value: string,
        previousValue: string,
    ) => {
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        if (!value) {
            setDistribution({ ...distribution, [type]: undefined });
            return '';
        }
        const amountPattern = new RegExp('^[0-9]*(\\.[0-9]{0,4})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        const amount = Number(value);
        const maxValue = 100 - ((type === 'sequencer' ? distribution.communityPool : distribution.sequencer) || 0);
        setDistribution({ ...distribution, [type]: Math.min(maxValue, amount) });
        setShouldGenerateGenesis(true);
        return amount > maxValue ? maxValue.toString() : undefined;
    }, [ distribution, setShouldGenerateGenesis, setDistribution ]);

    const stakeDistribution = useMemo(() => {
        return Decimal.fromUserInput('100', DEFAULT_DENOM_EXPONENT)
            .minus(Decimal.fromUserInput((distribution.sequencer || 0).toString(), DEFAULT_DENOM_EXPONENT))
            .minus(Decimal.fromUserInput((distribution.communityPool || 0).toString(), DEFAULT_DENOM_EXPONENT))
            .toString();
    }, [ distribution.communityPool, distribution.sequencer ]);

    return (
        <div className='section'>
            <h5 className='section-header'>
                Token mint and fees distribution
                <InfoIndicator indicatorSize='small'>
                    Token issuance can be distributed between the sequencer producing blocks, the RollApp community pool, and those who stake the RollApp token.
                </InfoIndicator>
            </h5>

            <div className='controls-row'>
                <div className='control-container'>
                    <div className='control-label-container'><label>Sequencer</label></div>
                    <Input
                        disabled={rollapp.sealed}
                        value={distribution.sequencer}
                        onValueChange={(value, previousValue) =>
                            onDistributionChange('sequencer', value, previousValue)}
                        suffix='%'
                    />
                </div>

                <div className='control-container'>
                    <div className='control-label-container'><label>Community Pool</label></div>
                    <Input
                        disabled={rollapp.sealed}
                        value={distribution.communityPool}
                        onValueChange={(value, previousValue) =>
                            onDistributionChange('communityPool', value, previousValue)}
                        suffix='%'
                    />
                </div>

                <div className='control-container'>
                    <div className='control-label-container'><label>Stakers</label></div>
                    <Input disabled value={stakeDistribution} suffix='%' />
                </div>
            </div>

            <p className='secondary-text'>
                All fees and newly minted rollapp tokens will be distributed:&nbsp;
                <span className='primary-text'><b>{distribution.sequencer || 0}%</b></span> to sequencer,&nbsp;
                <span className='primary-text'><b>{distribution.communityPool || 0}%</b></span> to community pool,
                and <span className='primary-text'><b>{stakeDistribution}%</b></span> to your RollApp stakers.
            </p>
        </div>
    );
};

export default DistributionSection;
