import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../shared/components/spinner/spinner';
import NetworkDashboardPage from '../../network-dashboard/network-dashboard-page';
import { NetworkDashboardContextProvider } from '../../network-dashboard/network-dashboard-context';
import { useNetwork } from '../../network/network-context';
import './rollapp-dashboard-page.scss';

const RollappDashboardPage: React.FC = () => {
    const { id } = useParams();
    const { loading, getNetwork } = useNetwork();
    const network = id && getNetwork(id);

    if (network) {
        if (network.type !== 'RollApp') {
            return <h5 className='not-found-message'>Unsupported network</h5>;
        }
        return <NetworkDashboardContextProvider network={network}><NetworkDashboardPage /></NetworkDashboardContextProvider>;
    }
    if (loading) {
        return <Spinner size='large' className='rollapp-dashboard-loader' />;
    }
    return <h5 className='not-found-message'>RollApp not found</h5>;
};

export default RollappDashboardPage;
