import React, { useMemo } from 'react';
import Property from '../../../shared/components/property/property';
import StatisticsCards, { StatisticsCardsProps } from '../../../shared/components/statistics-cards/statistics-cards';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber, formatPrice, roundNumber } from '../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { getStakingCurrency } from '../../currency/currency-service';
import { useIncentives } from '../../incentives/incentives-context';
import { useNetwork } from '../../network/network-context';
import { useSponsorship } from '../sponsorship-context';
import './sponsorship-statistics.scss';

export const SponsorshipStatistics: React.FC<Omit<StatisticsCardsProps, 'children'>> = (props) => {
    const { hubNetwork, hubCurrency, rollapps } = useNetwork();
    const networkState = useHubNetworkState();
    const {
        loading,
        voteLoading,
        vote,
        distribution,
        stakingLoading,
        stakedAmount,
        dailyStreamed,
        totalStreamed,
        daysStreamed,
    } = useSponsorship();
    const { incentivesState } = useIncentives();

    const stakingCurrency = useMemo(() => hubNetwork && getStakingCurrency(hubNetwork), [ hubNetwork ]);

    const sponsored = useMemo(() => vote?.reduce((current, record) => current + record.power, 0), [ vote ]);

    const weight = useMemo(() => !vote ? 0 : vote.reduce((current, record) => current + record.weight, 0), [ vote ]);

    const totalSponsored = useMemo(() => distribution?.reduce((current, record) => current + record.power, 0), [ distribution ]);

    const totalSponsoredChange = useMemo(() => {
        const currentValue = Number(rollapps.reduce((current, rollapp) => current +
            BigInt(rollapp.totalSponsored?.value?.power || '0'), BigInt(0)));
        const previousValue = Number(rollapps.reduce((current, rollapp) => current +
            BigInt(rollapp.totalSponsored?.previousWeekValue?.power || '0'), BigInt(0)));
        return { currentValue, previousValue };
    }, [ rollapps ]);

    return (
        <StatisticsCards {...props} className='sponsorship-statistics'>
            <Property
                label='Total Protocol Endorsements'
                loading={totalSponsored === undefined && loading}
                info='Total DYM allocated to endorsements across all users in the protocol.'
            >
                <StatisticsChange
                    period='week'
                    currentValue={totalSponsoredChange.currentValue}
                    previousValue={totalSponsoredChange.previousValue}
                >
                    {formatPrice(totalSponsored || 0, '', { minimumFractionDigits: 0 })}
                    &nbsp;<small>{hubCurrency?.displayDenom}</small>
                </StatisticsChange>
            </Property>

            <Property
                label='Total Reward Distribution'
                loading={!incentivesState.streams && incentivesState.loading}
                info='Total payout of incentives to RollApp creators and liquidity pool providers based on current endorsement allocations'
            >
                {formatPrice(totalStreamed, '')}&nbsp;<small>{hubCurrency?.displayDenom}</small>&nbsp;
                <small className='secondary-text'>({formatNumber(daysStreamed)} day{daysStreamed === 1 ? '' : 's'})</small>
            </Property>

            <Property
                label='Daily Reward Distribution'
                loading={!incentivesState.streams && incentivesState.loading}
                info='Daily payout of incentives to RollApp creators and liquidity pool providers based on current endorsement allocations'
            >
                {formatPrice(dailyStreamed, '')}&nbsp;<small>{hubCurrency?.displayDenom}</small>&nbsp;
            </Property>

            <Property
                label='My Endorsements'
                info='Amount of your staked DYM currently allocated to endorsement RollApps or liquidity pools.'
                valueSuffix={`(${roundNumber(weight * 100, 2)}%)`}
                valueSuffixClassName='secondary-text'
                loading={sponsored === undefined && voteLoading}
                na={!networkState.address}
            >
                {formatPrice(sponsored || 0, '')}&nbsp;<small>{hubCurrency?.displayDenom}</small>
            </Property>

            <Property
                label='My Endorsing Rights'
                info='Your total endorsement voting rights are determined by your staked DYM on a 1:1 basis.'
                valueSuffix={stakingCurrency?.displayDenom}
                loading={stakedAmount === undefined && stakingLoading}
                na={!networkState.address}
            >
                {formatPrice(stakedAmount?.amount || 0, '')}
            </Property>
        </StatisticsCards>
    );
};

export default SponsorshipStatistics;
