import { deleteObject } from '@firebase/storage';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useState } from 'react';
import { useHubNetworkState } from '../../../../../../../account/hub-network-state-context';
import { App } from '../../../../../../../client/station-clients/dymension/generated/rollapp/app';
import { useNetwork } from '../../../../../../../network/network-context';
import { TxState } from '../../../../../../../tx/tx-state';
import { DeliveryTxCode } from '../../../../../../../tx/tx-types';
import { useTx } from '../../../../../../../tx/use-tx';
import { useWallet } from '../../../../../../../wallet/wallet-context';
import { WalletError } from '../../../../../../../wallet/wallet-error';
import { createRemoveAppMessage, getAppLogoFileRef } from '../apps-service';

interface UseDeleteAppValue {
    appTxState: TxState;
    broadcastAppTx: () => Promise<void>;
}

export const useRemoveApp = (app: App): UseDeleteAppValue => {
    const { refreshRollapp } = useNetwork();
    const networkState = useHubNetworkState();
    const { handleWalletError } = useWallet();
    const [ appTxResponseHash, setAppTxResponseHash ] = useState('');

    const removeMessagesCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !app) {
            return [];
        }
        return [ createRemoveAppMessage(app, networkState.address) ];
    }, [ app, networkState.address ]);

    const {
        txState: appTxState,
        broadcast: broadcastAppTx,
        calculateFee: calculateAppTxFee,
        clearFee: clearAppTxFee,
    } = useTx({
        networkState: networkState,
        txMessagesCreator: removeMessagesCreator,
    });

    useEffect(() => {
        const { deliveryTxCode, hash } = appTxState.response || {};
        if (deliveryTxCode !== DeliveryTxCode.SUCCESS || hash === appTxResponseHash) {
            return;
        }
        setAppTxResponseHash(hash || '');
        refreshRollapp(app.rollappId);

        if (app.imageUrl) {
            const fileRef = getAppLogoFileRef(app.imageUrl);
            deleteObject(fileRef).then().catch((error) => {
                console.error(`Can't delete app logo`, error)
            });
        }
    }, [ app.imageUrl, app.rollappId, appTxResponseHash, appTxState.response, refreshRollapp ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateAppTxFee(false);
    }, [ calculateAppTxFee, handleWalletError ]);

    useEffect(() => handleError(appTxState.error), [ handleError, appTxState.error ]);

    useEffect(() => {
        if (networkState.address && app) {
            calculateAppTxFee();
        } else {
            clearAppTxFee();
        }
    }, [ networkState.address, calculateAppTxFee, clearAppTxFee, app ]);

    return { appTxState, broadcastAppTx };
};
