import React from 'react';
import { formatNumber } from '../../../../../shared/utils/number-utils';
import { useNetwork } from '../../../../network/network-context';

const RollappsCountProperty: React.FC = () => {
    const { hubNetwork } = useNetwork();

    return <>
        <div className='statistics-property'>
            <span className='property-label'>RollApps:</span> {formatNumber(Number(hubNetwork?.rollappsCount?.value) || 0)}
        </div>
    </>;
};

export default RollappsCountProperty;
