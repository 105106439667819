import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo } from 'react';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import { CoinsAmount } from '../../../currency/currency-types';
import { AmountTxState } from '../../../tx/amount-tx/amount-tx-state';
import { useAmountTx } from '../../../tx/amount-tx/use-amount-tx';
import { TxState } from '../../../tx/tx-state';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import { createUpdateDemandOrderMessage } from '../ibc-status-service';
import { IbcTransferDetails } from '../ibc-status-types';

export interface UseEibcFeeEditModalValue {
    txState: TxState;
    amountTxState: AmountTxState;
    setAmount: (value: number) => void;
    broadcast: (memo?: string) => void;
}

export const useEibcFeeEditModal = (transfer: IbcTransferDetails): UseEibcFeeEditModalValue => {
    const networkState = useHubNetworkState();
    const { handleWalletError } = useWallet();
    const { handleClientError } = useClient();

    const updateDemandOrderMessagesCreator = useCallback((fee?: CoinsAmount, coins?: CoinsAmount): EncodeObject[] => {
        if (!coins?.currency) {
            return [];
        }
        return [ createUpdateDemandOrderMessage(transfer, coins) ];
    }, [ transfer ]);

    const availableBalances = useMemo(() => filterNonEmptyValues([ transfer.coins ]), [ transfer.coins ]);

    const { txState, amountTxState, clearFee, calculateFee, setAmount, setCoins, broadcast } = useAmountTx({
        networkState,
        availableBalances,
        amountTxMessagesCreator: updateDemandOrderMessagesCreator,
    });

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else if (txState.error instanceof WalletError) {
            handleWalletError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, handleWalletError, txState.error ]);

    useEffect(() => {
        if (networkState.network && amountTxState.coins?.currency) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ amountTxState.coins?.currency, calculateFee, clearFee, networkState.network ]);

    useEffect(() => {
        if (transfer.eibcFeeCoins) {
            setCoins(transfer.eibcFeeCoins);
        }
    }, [ setCoins, transfer.eibcFeeCoins ]);

    return { txState, amountTxState, setAmount, broadcast };
};
