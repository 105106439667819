/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Coin } from "../cosmos/base/v1beta1/coin";

export const protobufPackage = "dymensionxyz.dymension.dymns";

/** AssetType present type of the asset of the Buy/Sell order. */
export enum AssetType {
  AT_UNKNOWN = 0,
  AT_DYM_NAME = 1,
  AT_ALIAS = 2,
  UNRECOGNIZED = -1,
}

export function assetTypeFromJSON(object: any): AssetType {
  switch (object) {
    case 0:
    case "AT_UNKNOWN":
      return AssetType.AT_UNKNOWN;
    case 1:
    case "AT_DYM_NAME":
      return AssetType.AT_DYM_NAME;
    case 2:
    case "AT_ALIAS":
      return AssetType.AT_ALIAS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AssetType.UNRECOGNIZED;
  }
}

export function assetTypeToJSON(object: AssetType): string {
  switch (object) {
    case AssetType.AT_UNKNOWN:
      return "AT_UNKNOWN";
    case AssetType.AT_DYM_NAME:
      return "AT_DYM_NAME";
    case AssetType.AT_ALIAS:
      return "AT_ALIAS";
    case AssetType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * SellOrder defines a sell order, placed by owner, to sell a Dym-Name/Alias.
 * Sell-Order has an expiry date.
 * After expiry date, if no one has placed a bid, this Sell-Order will be closed, no change.
 * If there is a bid, the highest bid will win, and the Dym-Name/Alias ownership will be transferred to the winner.
 * If the bid matches the sell price, the Dym-Name/Alias ownership will be transferred to the bidder immediately.
 */
export interface SellOrder {
  /** asset_id is the Dym-Name/Alias being opened to be sold. */
  assetId: string;
  /** asset_type is the type of the asset of the order, is Dym-Name/Alias. */
  assetType: AssetType;
  /** expire_at is the last effective date of this SO */
  expireAt: number;
  /** min_price is the minimum price that the owner is willing to accept for the asset. */
  minPrice:
    | Coin
    | undefined;
  /**
   * sell_price is the price that the owner is willing to sell the Dym-Name/Alias for,
   * the SO will be completed when the price is met, ownership transferred.
   * If the sell price is zero, the SO will be closed when the expire_at is reached and the highest bidder wins.
   */
  sellPrice:
    | Coin
    | undefined;
  /** highest_bid is the highest bid on the SO, if any. Price must be greater than or equal to the min_price. */
  highestBid: SellOrderBid | undefined;
}

/**
 * ActiveSellOrdersExpiration contains list of active SOs, store expiration date mapped by asset identity.
 * Used by hook to find out expired SO instead of iterating through all records.
 */
export interface ActiveSellOrdersExpiration {
  records: ActiveSellOrdersExpirationRecord[];
}

/** ActiveSellOrdersExpirationRecord contains the expiration date of an active Sell-Order. */
export interface ActiveSellOrdersExpirationRecord {
  /** asset_id is the Dym-Name/Alias being opened to be sold. */
  assetId: string;
  /** expire_at is the last effective date of this Sell-Order. */
  expireAt: number;
}

/** SellOrderBid defines a bid placed by an account on a Sell-Order. */
export interface SellOrderBid {
  /** bidder is the account address of the account which placed the bid. */
  bidder: string;
  /** price is the amount of coin offered by the bidder. */
  price:
    | Coin
    | undefined;
  /**
   * params is the list of parameters of the bid.
   * It is empty for asset type Dym-Name.
   * It has one element for asset type Alias, which is the rollapp_id to assigned for.
   */
  params: string[];
}

/**
 * BuyOrder defines an offer to buy a Dym-Name/Alias, placed by buyer.
 * Buyer will need to deposit the offer amount to the module account.
 * When the owner of the Dym-Name/Alias accepts the offer, deposited amount will be transferred to the owner.
 * When the buyer cancels the offer, deposited amount will be refunded to the buyer.
 */
export interface BuyOrder {
  /** id is the unique identifier of the order. Generated by the module. */
  id: string;
  /** asset_id of the Dym-Name/Alias willing to buy. */
  assetId: string;
  /** asset_type is type of the asset of the order, is Dym-Name/Alias */
  assetType: AssetType;
  /**
   * params is the list of parameters of the Buy-Order.
   * It is empty for asset type Dym-Name.
   * It has one element for asset type Alias, which is the rollapp_id to assigned for.
   */
  params: string[];
  /** buyer is bech32 address of the account which placed the order. */
  buyer: string;
  /**
   * offer_price is the amount of coins that buyer willing to pay for the asset.
   * This amount is deposited to the module account upon placing the offer.
   */
  offerPrice:
    | Coin
    | undefined;
  /**
   * counterparty_offer_price is the price that the Dym-Name/Alias owner is willing to sell for.
   * This is used for counterparty price negotiation and for information only.
   * The transaction can only be executed when the owner accepts the offer with exact offer_price.
   */
  counterpartyOfferPrice: Coin | undefined;
}

/** ReverseLookupBuyOrderIds contains a list of Buy-Orders IDs for reverse lookup. */
export interface ReverseLookupBuyOrderIds {
  /** order_ids is a list of Buy-Order IDs of the Buy-Orders linked to the reverse-lookup record. */
  orderIds: string[];
}

function createBaseSellOrder(): SellOrder {
  return { assetId: "", assetType: 0, expireAt: 0, minPrice: undefined, sellPrice: undefined, highestBid: undefined };
}

export const SellOrder = {
  encode(message: SellOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assetId !== "") {
      writer.uint32(10).string(message.assetId);
    }
    if (message.assetType !== 0) {
      writer.uint32(16).int32(message.assetType);
    }
    if (message.expireAt !== 0) {
      writer.uint32(24).int64(message.expireAt);
    }
    if (message.minPrice !== undefined) {
      Coin.encode(message.minPrice, writer.uint32(34).fork()).ldelim();
    }
    if (message.sellPrice !== undefined) {
      Coin.encode(message.sellPrice, writer.uint32(42).fork()).ldelim();
    }
    if (message.highestBid !== undefined) {
      SellOrderBid.encode(message.highestBid, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SellOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSellOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.assetId = reader.string();
          break;
        case 2:
          message.assetType = reader.int32() as any;
          break;
        case 3:
          message.expireAt = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.minPrice = Coin.decode(reader, reader.uint32());
          break;
        case 5:
          message.sellPrice = Coin.decode(reader, reader.uint32());
          break;
        case 6:
          message.highestBid = SellOrderBid.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SellOrder {
    return {
      assetId: isSet(object.assetId) ? String(object.assetId) : "",
      assetType: isSet(object.assetType) ? assetTypeFromJSON(object.assetType) : 0,
      expireAt: isSet(object.expireAt) ? Number(object.expireAt) : 0,
      minPrice: isSet(object.minPrice) ? Coin.fromJSON(object.minPrice) : undefined,
      sellPrice: isSet(object.sellPrice) ? Coin.fromJSON(object.sellPrice) : undefined,
      highestBid: isSet(object.highestBid) ? SellOrderBid.fromJSON(object.highestBid) : undefined,
    };
  },

  toJSON(message: SellOrder): unknown {
    const obj: any = {};
    message.assetId !== undefined && (obj.assetId = message.assetId);
    message.assetType !== undefined && (obj.assetType = assetTypeToJSON(message.assetType));
    message.expireAt !== undefined && (obj.expireAt = Math.round(message.expireAt));
    message.minPrice !== undefined && (obj.minPrice = message.minPrice ? Coin.toJSON(message.minPrice) : undefined);
    message.sellPrice !== undefined && (obj.sellPrice = message.sellPrice ? Coin.toJSON(message.sellPrice) : undefined);
    message.highestBid !== undefined
      && (obj.highestBid = message.highestBid ? SellOrderBid.toJSON(message.highestBid) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SellOrder>, I>>(object: I): SellOrder {
    const message = createBaseSellOrder();
    message.assetId = object.assetId ?? "";
    message.assetType = object.assetType ?? 0;
    message.expireAt = object.expireAt ?? 0;
    message.minPrice = (object.minPrice !== undefined && object.minPrice !== null)
      ? Coin.fromPartial(object.minPrice)
      : undefined;
    message.sellPrice = (object.sellPrice !== undefined && object.sellPrice !== null)
      ? Coin.fromPartial(object.sellPrice)
      : undefined;
    message.highestBid = (object.highestBid !== undefined && object.highestBid !== null)
      ? SellOrderBid.fromPartial(object.highestBid)
      : undefined;
    return message;
  },
};

function createBaseActiveSellOrdersExpiration(): ActiveSellOrdersExpiration {
  return { records: [] };
}

export const ActiveSellOrdersExpiration = {
  encode(message: ActiveSellOrdersExpiration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.records) {
      ActiveSellOrdersExpirationRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActiveSellOrdersExpiration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActiveSellOrdersExpiration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.records.push(ActiveSellOrdersExpirationRecord.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActiveSellOrdersExpiration {
    return {
      records: Array.isArray(object?.records)
        ? object.records.map((e: any) => ActiveSellOrdersExpirationRecord.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ActiveSellOrdersExpiration): unknown {
    const obj: any = {};
    if (message.records) {
      obj.records = message.records.map((e) => e ? ActiveSellOrdersExpirationRecord.toJSON(e) : undefined);
    } else {
      obj.records = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ActiveSellOrdersExpiration>, I>>(object: I): ActiveSellOrdersExpiration {
    const message = createBaseActiveSellOrdersExpiration();
    message.records = object.records?.map((e) => ActiveSellOrdersExpirationRecord.fromPartial(e)) || [];
    return message;
  },
};

function createBaseActiveSellOrdersExpirationRecord(): ActiveSellOrdersExpirationRecord {
  return { assetId: "", expireAt: 0 };
}

export const ActiveSellOrdersExpirationRecord = {
  encode(message: ActiveSellOrdersExpirationRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assetId !== "") {
      writer.uint32(10).string(message.assetId);
    }
    if (message.expireAt !== 0) {
      writer.uint32(16).int64(message.expireAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActiveSellOrdersExpirationRecord {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActiveSellOrdersExpirationRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.assetId = reader.string();
          break;
        case 2:
          message.expireAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActiveSellOrdersExpirationRecord {
    return {
      assetId: isSet(object.assetId) ? String(object.assetId) : "",
      expireAt: isSet(object.expireAt) ? Number(object.expireAt) : 0,
    };
  },

  toJSON(message: ActiveSellOrdersExpirationRecord): unknown {
    const obj: any = {};
    message.assetId !== undefined && (obj.assetId = message.assetId);
    message.expireAt !== undefined && (obj.expireAt = Math.round(message.expireAt));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ActiveSellOrdersExpirationRecord>, I>>(
    object: I,
  ): ActiveSellOrdersExpirationRecord {
    const message = createBaseActiveSellOrdersExpirationRecord();
    message.assetId = object.assetId ?? "";
    message.expireAt = object.expireAt ?? 0;
    return message;
  },
};

function createBaseSellOrderBid(): SellOrderBid {
  return { bidder: "", price: undefined, params: [] };
}

export const SellOrderBid = {
  encode(message: SellOrderBid, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bidder !== "") {
      writer.uint32(10).string(message.bidder);
    }
    if (message.price !== undefined) {
      Coin.encode(message.price, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.params) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SellOrderBid {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSellOrderBid();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.bidder = reader.string();
          break;
        case 2:
          message.price = Coin.decode(reader, reader.uint32());
          break;
        case 3:
          message.params.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SellOrderBid {
    return {
      bidder: isSet(object.bidder) ? String(object.bidder) : "",
      price: isSet(object.price) ? Coin.fromJSON(object.price) : undefined,
      params: Array.isArray(object?.params) ? object.params.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SellOrderBid): unknown {
    const obj: any = {};
    message.bidder !== undefined && (obj.bidder = message.bidder);
    message.price !== undefined && (obj.price = message.price ? Coin.toJSON(message.price) : undefined);
    if (message.params) {
      obj.params = message.params.map((e) => e);
    } else {
      obj.params = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SellOrderBid>, I>>(object: I): SellOrderBid {
    const message = createBaseSellOrderBid();
    message.bidder = object.bidder ?? "";
    message.price = (object.price !== undefined && object.price !== null) ? Coin.fromPartial(object.price) : undefined;
    message.params = object.params?.map((e) => e) || [];
    return message;
  },
};

function createBaseBuyOrder(): BuyOrder {
  return {
    id: "",
    assetId: "",
    assetType: 0,
    params: [],
    buyer: "",
    offerPrice: undefined,
    counterpartyOfferPrice: undefined,
  };
}

export const BuyOrder = {
  encode(message: BuyOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.assetId !== "") {
      writer.uint32(18).string(message.assetId);
    }
    if (message.assetType !== 0) {
      writer.uint32(24).int32(message.assetType);
    }
    for (const v of message.params) {
      writer.uint32(34).string(v!);
    }
    if (message.buyer !== "") {
      writer.uint32(42).string(message.buyer);
    }
    if (message.offerPrice !== undefined) {
      Coin.encode(message.offerPrice, writer.uint32(50).fork()).ldelim();
    }
    if (message.counterpartyOfferPrice !== undefined) {
      Coin.encode(message.counterpartyOfferPrice, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuyOrder {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuyOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.assetId = reader.string();
          break;
        case 3:
          message.assetType = reader.int32() as any;
          break;
        case 4:
          message.params.push(reader.string());
          break;
        case 5:
          message.buyer = reader.string();
          break;
        case 6:
          message.offerPrice = Coin.decode(reader, reader.uint32());
          break;
        case 7:
          message.counterpartyOfferPrice = Coin.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BuyOrder {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      assetId: isSet(object.assetId) ? String(object.assetId) : "",
      assetType: isSet(object.assetType) ? assetTypeFromJSON(object.assetType) : 0,
      params: Array.isArray(object?.params) ? object.params.map((e: any) => String(e)) : [],
      buyer: isSet(object.buyer) ? String(object.buyer) : "",
      offerPrice: isSet(object.offerPrice) ? Coin.fromJSON(object.offerPrice) : undefined,
      counterpartyOfferPrice: isSet(object.counterpartyOfferPrice)
        ? Coin.fromJSON(object.counterpartyOfferPrice)
        : undefined,
    };
  },

  toJSON(message: BuyOrder): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.assetId !== undefined && (obj.assetId = message.assetId);
    message.assetType !== undefined && (obj.assetType = assetTypeToJSON(message.assetType));
    if (message.params) {
      obj.params = message.params.map((e) => e);
    } else {
      obj.params = [];
    }
    message.buyer !== undefined && (obj.buyer = message.buyer);
    message.offerPrice !== undefined
      && (obj.offerPrice = message.offerPrice ? Coin.toJSON(message.offerPrice) : undefined);
    message.counterpartyOfferPrice !== undefined && (obj.counterpartyOfferPrice = message.counterpartyOfferPrice
      ? Coin.toJSON(message.counterpartyOfferPrice)
      : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<BuyOrder>, I>>(object: I): BuyOrder {
    const message = createBaseBuyOrder();
    message.id = object.id ?? "";
    message.assetId = object.assetId ?? "";
    message.assetType = object.assetType ?? 0;
    message.params = object.params?.map((e) => e) || [];
    message.buyer = object.buyer ?? "";
    message.offerPrice = (object.offerPrice !== undefined && object.offerPrice !== null)
      ? Coin.fromPartial(object.offerPrice)
      : undefined;
    message.counterpartyOfferPrice =
      (object.counterpartyOfferPrice !== undefined && object.counterpartyOfferPrice !== null)
        ? Coin.fromPartial(object.counterpartyOfferPrice)
        : undefined;
    return message;
  },
};

function createBaseReverseLookupBuyOrderIds(): ReverseLookupBuyOrderIds {
  return { orderIds: [] };
}

export const ReverseLookupBuyOrderIds = {
  encode(message: ReverseLookupBuyOrderIds, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.orderIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReverseLookupBuyOrderIds {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReverseLookupBuyOrderIds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.orderIds.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReverseLookupBuyOrderIds {
    return { orderIds: Array.isArray(object?.orderIds) ? object.orderIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: ReverseLookupBuyOrderIds): unknown {
    const obj: any = {};
    if (message.orderIds) {
      obj.orderIds = message.orderIds.map((e) => e);
    } else {
      obj.orderIds = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReverseLookupBuyOrderIds>, I>>(object: I): ReverseLookupBuyOrderIds {
    const message = createBaseReverseLookupBuyOrderIds();
    message.orderIds = object.orderIds?.map((e) => e) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
