import classNames from 'classnames';
import React, { ReactElement, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../shared/components/button/button';
import Menu, { MenuAction } from '../../../shared/components/menu/menu';
import Spinner from '../../../shared/components/spinner/spinner';
import { ReactComponent as MoreMenuIcon } from '../../../assets/icons/menu-more.svg';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import {
    getCompareValues,
    getHistoryValuesInPeriod,
} from '../../../shared/components/statistics/statistics-change/statistics-change-service';
import { AnalyticsChangePeriod } from '../../../shared/components/statistics/statistics-change/statistics-change-types';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import { formatPrice } from '../../../shared/utils/number-utils';
import { getCurrencyLogoPath, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import DisplayNameWithFuture from '../../currency/display-name-with-future/display-name-with-future';
import SimpleLineChart from '../../network/statistics/charts/simple-line-chart/simple-line-chart';
import { useAsset } from '../asset-context';
import { useAssetList } from './asset-list-context';
import { Asset } from '../asset-types';
import ImportTokenDialog from '../import-token-dialog/import-token-dialog';
import AssetBondDialog, { AssetBondDialogProps } from './asset-bond-dialog/asset-bond-dialog';
import './asset-list.scss';

export const AssetListHeader: React.FC<{ children: ReactNode, className?: string }> = ({ children, className }) => {
    return <h5 className={classNames('asset-list-header', className)}>{children}</h5>;
};

const AssetList: React.FC = () => {
    const navigate = useNavigate();
    const { loading, toCalculatePriceWithMarketCap } = useAsset();
    const {
        sortedFilteredAssets,
        assetsPoolsAnalyticsState,
        assetsNetworksAnalyticsState,
    } = useAssetList();
    const [ importedToken, setImportedToken ] = useState<CoinsAmount>();
    const [ assetBondDialogProps, setAssetBondDialogProps ] = useState<AssetBondDialogProps>();

    const renderAssetNameColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn className='asset-name-container'>
                <img className='currency-logo' src={getCurrencyLogoPath(asset.currency, asset.network)} alt='currency logo' />
                <div className='asset-name'>
                    <DisplayNameWithFuture coins={asset} />
                    <span className='network-name'>{asset.network.chainName}</span>
                </div>
            </TableColumn>
        );
    };

    const renderAssetPriceColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {!asset.price || asset.futureIRO ? <span className='secondary-text'>N/A</span> : formatPrice(asset.price)}
            </TableColumn>
        );
    };

    const renderAssetPricePercentageColumn = (asset: Asset, period: AnalyticsChangePeriod): ReactElement => {
        let currentValue = asset.price;
        let previousValue = asset.previousDayPrice;
        let isLoading = false;

        if (period !== 'day' && asset.pools.length) {
            if (toCalculatePriceWithMarketCap(asset)) {
                const data = assetsNetworksAnalyticsState.analyticsMap?.[asset.networkId]?.totalSupply;
                const { currentValue: currentAnalyticsValue = 0, previousValue: previousAnalyticsValue = 0 } = data ? getCompareValues(
                    data,
                    period,
                    false,
                    ({ marketCap, amount }) => marketCap && amount ? marketCap / amount : 0,
                ) : {};
                currentValue = currentAnalyticsValue;
                previousValue = previousAnalyticsValue;
                isLoading = !data && assetsNetworksAnalyticsState.loadingMap?.[asset.networkId] !== false;
            } else {
                const data = assetsPoolsAnalyticsState.analyticsMap?.[asset.pools[0].id]?.liquidity;
                const assetIndex = isCoinsEquals(asset.pools[0].assets[0], asset) ? 0 : 1;
                const { currentValue: currentAnalyticsValue = 0, previousValue: previousAnalyticsValue = 0 } = data ? getCompareValues(
                    data,
                    period,
                    false,
                    ({ asset1Amount, asset2Amount, value }) => (value / 2) / (assetIndex === 0 ? asset1Amount : asset2Amount),
                ) : {};
                currentValue = currentAnalyticsValue;
                previousValue = previousAnalyticsValue;
                isLoading = !data && assetsPoolsAnalyticsState.loadingMap?.[asset.pools[0].id] !== false;
            }
        }

        return (
            <TableColumn align='right'>
                {isLoading ? <Spinner size='small' /> :
                    !asset.price || asset.futureIRO || !previousValue ? <span className='secondary-text'>N/A</span> : (
                        <StatisticsChange
                            className='analytics-change'
                            period={period}
                            currentValue={currentValue}
                            previousValue={previousValue}
                        />
                    )}
            </TableColumn>
        );
    };

    const renderAssetPriceChangeColumn = (asset: Asset): ReactElement => {
        const data = asset.pools.length && assetsPoolsAnalyticsState.analyticsMap?.[asset.pools[0].id]?.liquidity;
        const assetIndex = asset.pools.length && isCoinsEquals(asset.pools[0].assets[0], asset) ? 0 : 1;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');
        const isLoading = asset.pools.length && assetsPoolsAnalyticsState.loadingMap?.[asset.pools[0].id] !== false;

        return (
            <TableColumn align='right'>
                {!data && isLoading ? <Spinner size='small' /> : !weekData ? <span className='secondary-text'>N/A</span> : (
                    <SimpleLineChart
                        historyList={weekData}
                        fetchComparableValues={
                            ({ asset1Amount, asset2Amount, value }) => (value / 2) / (assetIndex === 0 ? asset1Amount : asset2Amount)}
                    />
                )}
            </TableColumn>
        );
    };

    const renderMarketCapPriceChangeColumn = (asset: Asset): ReactElement => {
        const data = assetsNetworksAnalyticsState.analyticsMap?.[asset.networkId]?.totalSupply;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');
        const isLoading = assetsNetworksAnalyticsState.loadingMap?.[asset.networkId] !== false;
        return (
            <TableColumn align='right'>
                {!data && isLoading ? <Spinner size='small' /> :
                    !weekData || !asset || asset.futureIRO ? <span className='secondary-text'>N/A</span> : weekData && (
                        <SimpleLineChart
                            historyList={weekData}
                            fetchComparableValues={({ marketCap, amount }) => marketCap && amount ? marketCap / amount : 0}
                        />
                    )}
            </TableColumn>
        );
    };

    const renderAssetTradingVolumeColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {!asset.price || asset.futureIRO ? <span className='secondary-text'>N/A</span> :
                    formatPrice(asset.volume, undefined, { notation: 'compact' })}
            </TableColumn>
        );
    };

    const renderAssetLiquidityColumn = (asset: Asset): ReactElement => {
        return (
            <TableColumn align='right'>
                {!asset.price || asset.futureIRO ? <span className='secondary-text'>N/A</span> :
                    formatPrice(asset.liquidity, undefined, { notation: 'compact' })}
            </TableColumn>
        );
    };

    const renderMenuColumn = (asset: Asset): JSX.Element => {
        const stakable = false; // Boolean(ammState.incentives?.[getFixedDenom(asset)]?.coins);
        return (
            <TableColumn className='menu-column' align='right'>
                <Menu closeWhenScroll trigger={<Button buttonType='icon'><MoreMenuIcon /></Button>}>
                    <MenuAction onClick={() => setImportedToken(asset)}>Import to wallet</MenuAction>
                    <MenuAction disabled={!stakable} onClick={() => setAssetBondDialogProps({ type: 'Bond', coins: asset })}>
                        Bond
                    </MenuAction>
                    <MenuAction disabled={!stakable} onClick={() => setAssetBondDialogProps({ type: 'Unbond', coins: asset })}>
                        Unbond
                    </MenuAction>
                </Menu>
            </TableColumn>
        );
    };

    const renderAssetHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Asset</TableColumn>
                <TableColumn align='right'>Price</TableColumn>
                <TableColumn align='right'>24h %</TableColumn>
                <TableColumn align='right'>7d %</TableColumn>
                <TableColumn align='right'>1m %</TableColumn>
                <TableColumn align='right'>Liquidity</TableColumn>
                <TableColumn align='right'>Volume (24h)</TableColumn>
                <TableColumn align='right' nowrap>Price Change (7d)</TableColumn>
                <TableColumn align='right' />
            </TableRow>
        );
    };

    const renderAssetRow = (asset: Asset): ReactElement => {
        return (
            <TableRow key={asset.key} onSelect={() => navigate(`/amm/asset/${encodeURIComponent(asset.key)}`)}>
                {renderAssetNameColumn(asset)}
                {renderAssetPriceColumn(asset)}
                {renderAssetPricePercentageColumn(asset, 'day')}
                {renderAssetPricePercentageColumn(asset, 'week')}
                {renderAssetPricePercentageColumn(asset, 'month')}
                {renderAssetLiquidityColumn(asset)}
                {renderAssetTradingVolumeColumn(asset)}
                {toCalculatePriceWithMarketCap(asset) ?
                    renderMarketCapPriceChangeColumn(asset) :
                    renderAssetPriceChangeColumn(asset)}
                {renderMenuColumn(asset)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (!sortedFilteredAssets && loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!sortedFilteredAssets?.length) {
            return <div className='no-data'>No Assets</div>;
        }
    };

    return (
        <div className='asset-list-container'>
            <Table className={classNames('asset-list')} indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                {renderAssetHeaderRow()}
                {sortedFilteredAssets?.map(renderAssetRow)}
            </Table>

            {importedToken && <ImportTokenDialog token={importedToken} onRequestClose={() => setImportedToken(undefined)} />}

            {assetBondDialogProps &&
                <AssetBondDialog {...assetBondDialogProps} onRequestClose={() => setAssetBondDialogProps(undefined)} />}
        </div>
    );
};

export default AssetList;

