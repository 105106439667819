import React, { createContext, ReactNode, useContext } from 'react';
import { SnackbarMessage } from '../../shared/components/snackbar/snackbar-types';
import { CoinsAmount } from '../currency/currency-types';
import { AmountTxState } from '../tx/amount-tx/amount-tx-state';
import { TxState } from '../tx/tx-state';
import { Fee, TxResponse } from '../tx/tx-types';

export const DEFAULT_SLIPPAGE_TOLERANCE = 5;

interface TradeContextValue {
    asset1AmountTxState: AmountTxState;
    asset2AmountTxState: AmountTxState;
    updateAsset1Coins: (coins: CoinsAmount) => void;
    updateAsset2Coins: (coins: CoinsAmount) => void;
    availableBalances?: CoinsAmount[];
    txState: TxState;
    fees?: Fee[];
    spotPrice: number;
    loading: boolean;
    disabled?: boolean;
    useInverseAsset: boolean;
    setUseInverseAsset: (value: boolean) => void;
    slippageTolerance?: number;
    currentSlippage?: number;
    setSlippageTolerance?: (value?: number) => void;
    switchTokens?: () => void;
    slippageToleranceMode?: 'alert' | 'prevent' | 'ignore';
    broadcast: (memo?: string) => void;
    getTokensMinAmounts?: (coins1: CoinsAmount, coins2: CoinsAmount) => { minCoins1Amount: number, tokenOutMinAmount: string };
    getTxResponseMessage?: (response: TxResponse) => Partial<SnackbarMessage> | undefined;
}

interface TradeContextProps extends TradeContextValue {
    children: ReactNode;
}

export const TradeContext = createContext<TradeContextValue>({} as TradeContextValue);

export const useTrade = (): TradeContextValue => useContext(TradeContext);

export const TradeContextProvider = ({ children, ...props }: TradeContextProps) =>
    <TradeContext.Provider value={props}>{children}</TradeContext.Provider>;


