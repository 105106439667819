/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Coin } from '../cosmos/base/v1beta1/coin';
import { Timestamp } from "../google/protobuf/timestamp";
import { GenesisInfo } from "./genesis_info";
import { RollappMetadata } from "./metadata";
import { StateInfoIndex } from "./state_info";

export const protobufPackage = "dymensionxyz.dymension.rollapp";

/**
 * RollappGenesisState is a partial repr of the state the hub can expect the
 * rollapp to be in upon genesis
 */
export interface RollappGenesisState {
  /**
   * 0 means unpopulated
   * If populated, it's the proof height that the hub received the genesis transfer
   * packet from the rollapp. If populated, the bridge is considered open. It's not allowed
   * to fork to a height prior to this height.
   */
  transferProofHeight: number;
}

/**
 * Rollapp defines a rollapp object. First, the RollApp is created and then
 * sequencers can be created and attached. The RollApp is identified by
 * rollappId
 */
export interface Rollapp {
  /**
   * The unique identifier of the rollapp chain.
   * The rollapp_id follows the same standard as cosmos chain_id.
   */
  rollappId: string;
  /** owner is the bech32-encoded address of the rollapp owner. */
  owner: string;
  /**
   * genesis_state is a partial repr of the state the hub can expect the rollapp
   * to be in upon genesis
   */
  genesisState:
    | RollappGenesisState
    | undefined;
  /** channel_id will be set to the canonical IBC channel of the rollapp. */
  channelId: string;
  /** metadata is the rollapp metadata */
  metadata:
    | RollappMetadata
    | undefined;
  /** genesis_info keeps immutable rollapp fields */
  genesisInfo:
    | GenesisInfo
    | undefined;
  /**
   * initial_sequencer is an option to preset one or more coma-separated
   * bech32-encoded addresses of the sequencer(s) that are allowed to initially
   * register and serve for this rollapp. if left empty, no sequencer is allowed
   * to register. if set to "*" any sequencer can register.
   */
  initialSequencer: string;
  /**
   * How much a sequencer needs to have at stake to be proposer be proposer.
   * Denom is dym. It is empty or ONE coin only. Coins is just a convenience to avoid
   * marshalling issues.
   */
  minSequencerBond: Coin[];
  /** vm_type is the type of rollapp machine: EVM or WASM */
  vmType: Rollapp_VMType;
  /**
   * launched is a boolean that indicates that the Rollapp has been launched and
   * the immutable fields are no longer updatable.
   */
  launched: boolean;
  /**
   * pre_launch_time is the timestamp indicating the time before which the
   * rollapp cannot be started.
   * Set when creating IRO plan for the rollapp
   */
  preLaunchTime:
    | Date
    | undefined;
  /**
   * LivenessEventHeight is the height on the HUB of an upcoming liveness event (slash or
   * jail against the rollapp). 0 means not set
   */
  livenessEventHeight: number;
  /**
   * The height on the HUB that we start counting liveness from. If the rollapp is not active
   * for a long time after this height, a liveness event will happen.
   */
  livenessCountdownStartHeight: number;
  /** Revisions is a list of all the rollapp revisions. */
  revisions: Revision[];
}

export enum Rollapp_VMType {
  Unspecified = 0,
  EVM = 1,
  WASM = 2,
  UNRECOGNIZED = -1,
}

export function rollapp_VMTypeFromJSON(object: any): Rollapp_VMType {
  switch (object) {
    case 0:
    case "Unspecified":
      return Rollapp_VMType.Unspecified;
    case 1:
    case "EVM":
      return Rollapp_VMType.EVM;
    case 2:
    case "WASM":
      return Rollapp_VMType.WASM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Rollapp_VMType.UNRECOGNIZED;
  }
}

export function rollapp_VMTypeToJSON(object: Rollapp_VMType): string {
  switch (object) {
    case Rollapp_VMType.Unspecified:
      return "Unspecified";
    case Rollapp_VMType.EVM:
      return "EVM";
    case Rollapp_VMType.WASM:
      return "WASM";
    case Rollapp_VMType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Revision is a representation of the rollapp revision. */
export interface Revision {
  /** Number is the revision number of the rollapp. Always start with 0 revision. */
  number: number;
  /** StartHeight is the first height of the rollapp when the revision started. */
  startHeight: number;
}

/** Rollapp summary is a compact representation of Rollapp */
export interface RollappSummary {
  /**
   * The unique identifier of the rollapp chain.
   * The rollappId follows the same standard as cosmos chain_id.
   */
  rollappId: string;
  /** Defines the index of the last rollapp UpdateState. */
  latestStateIndex:
    | StateInfoIndex
    | undefined;
  /** Defines the index of the last rollapp UpdateState that was finalized. */
  latestFinalizedStateIndex:
    | StateInfoIndex
    | undefined;
  /** TODO: */
  latestHeight: number;
  /** TODO: */
  latestFinalizedHeight: number;
}

function createBaseRollappGenesisState(): RollappGenesisState {
  return { transferProofHeight: 0 };
}

export const RollappGenesisState = {
  encode(message: RollappGenesisState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transferProofHeight !== 0) {
      writer.uint32(24).uint64(message.transferProofHeight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RollappGenesisState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRollappGenesisState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.transferProofHeight = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RollappGenesisState {
    return { transferProofHeight: isSet(object.transferProofHeight) ? Number(object.transferProofHeight) : 0 };
  },

  toJSON(message: RollappGenesisState): unknown {
    const obj: any = {};
    message.transferProofHeight !== undefined && (obj.transferProofHeight = Math.round(message.transferProofHeight));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RollappGenesisState>, I>>(object: I): RollappGenesisState {
    const message = createBaseRollappGenesisState();
    message.transferProofHeight = object.transferProofHeight ?? 0;
    return message;
  },
};

function createBaseRollapp(): Rollapp {
  return {
    rollappId: "",
    owner: "",
    genesisState: undefined,
    channelId: "",
    metadata: undefined,
    genesisInfo: undefined,
    initialSequencer: "",
    minSequencerBond: [],
    vmType: 0,
    launched: false,
    preLaunchTime: undefined,
    livenessEventHeight: 0,
    livenessCountdownStartHeight: 0,
    revisions: [],
  };
}

export const Rollapp = {
  encode(message: Rollapp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollappId !== "") {
      writer.uint32(10).string(message.rollappId);
    }
    if (message.owner !== "") {
      writer.uint32(18).string(message.owner);
    }
    if (message.genesisState !== undefined) {
      RollappGenesisState.encode(message.genesisState, writer.uint32(58).fork()).ldelim();
    }
    if (message.channelId !== "") {
      writer.uint32(66).string(message.channelId);
    }
    if (message.metadata !== undefined) {
      RollappMetadata.encode(message.metadata, writer.uint32(90).fork()).ldelim();
    }
    if (message.genesisInfo !== undefined) {
      GenesisInfo.encode(message.genesisInfo, writer.uint32(98).fork()).ldelim();
    }
    if (message.initialSequencer !== "") {
      writer.uint32(106).string(message.initialSequencer);
    }
    for (const v of message.minSequencerBond) {
      Coin.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    if (message.vmType !== 0) {
      writer.uint32(112).int32(message.vmType);
    }
    if (message.launched === true) {
      writer.uint32(120).bool(message.launched);
    }
    if (message.preLaunchTime !== undefined) {
      Timestamp.encode(toTimestamp(message.preLaunchTime), writer.uint32(130).fork()).ldelim();
    }
    if (message.livenessEventHeight !== 0) {
      writer.uint32(136).int64(message.livenessEventHeight);
    }
    if (message.livenessCountdownStartHeight !== 0) {
      writer.uint32(144).int64(message.livenessCountdownStartHeight);
    }
    for (const v of message.revisions) {
      Revision.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Rollapp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRollapp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rollappId = reader.string();
          break;
        case 2:
          message.owner = reader.string();
          break;
        case 7:
          message.genesisState = RollappGenesisState.decode(reader, reader.uint32());
          break;
        case 8:
          message.channelId = reader.string();
          break;
        case 11:
          message.metadata = RollappMetadata.decode(reader, reader.uint32());
          break;
        case 12:
          message.genesisInfo = GenesisInfo.decode(reader, reader.uint32());
          break;
        case 13:
          message.initialSequencer = reader.string();
          break;
        case 20:
          message.minSequencerBond.push(Coin.decode(reader, reader.uint32()));
          break;
        case 14:
          message.vmType = reader.int32() as any;
          break;
        case 15:
          message.launched = reader.bool();
          break;
        case 16:
          message.preLaunchTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 17:
          message.livenessEventHeight = longToNumber(reader.int64() as Long);
          break;
        case 18:
          message.livenessCountdownStartHeight = longToNumber(reader.int64() as Long);
          break;
        case 19:
          message.revisions.push(Revision.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Rollapp {
    return {
      rollappId: isSet(object.rollappId) ? String(object.rollappId) : "",
      owner: isSet(object.owner) ? String(object.owner) : "",
      genesisState: isSet(object.genesisState) ? RollappGenesisState.fromJSON(object.genesisState) : undefined,
      channelId: isSet(object.channelId) ? String(object.channelId) : "",
      metadata: isSet(object.metadata) ? RollappMetadata.fromJSON(object.metadata) : undefined,
      genesisInfo: isSet(object.genesisInfo) ? GenesisInfo.fromJSON(object.genesisInfo) : undefined,
      initialSequencer: isSet(object.initialSequencer) ? String(object.initialSequencer) : "",
      minSequencerBond: Array.isArray(object?.minSequencerBond)
        ? object.minSequencerBond.map((e: any) => Coin.fromJSON(e))
        : [],
      vmType: isSet(object.vmType) ? rollapp_VMTypeFromJSON(object.vmType) : 0,
      launched: isSet(object.launched) ? Boolean(object.launched) : false,
      preLaunchTime: isSet(object.preLaunchTime) ? fromJsonTimestamp(object.preLaunchTime) : undefined,
      livenessEventHeight: isSet(object.livenessEventHeight) ? Number(object.livenessEventHeight) : 0,
      livenessCountdownStartHeight: isSet(object.livenessCountdownStartHeight)
        ? Number(object.livenessCountdownStartHeight)
        : 0,
      revisions: Array.isArray(object?.revisions) ? object.revisions.map((e: any) => Revision.fromJSON(e)) : [],
    };
  },

  toJSON(message: Rollapp): unknown {
    const obj: any = {};
    message.rollappId !== undefined && (obj.rollappId = message.rollappId);
    message.owner !== undefined && (obj.owner = message.owner);
    message.genesisState !== undefined
      && (obj.genesisState = message.genesisState ? RollappGenesisState.toJSON(message.genesisState) : undefined);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.metadata !== undefined
      && (obj.metadata = message.metadata ? RollappMetadata.toJSON(message.metadata) : undefined);
    message.genesisInfo !== undefined
      && (obj.genesisInfo = message.genesisInfo ? GenesisInfo.toJSON(message.genesisInfo) : undefined);
    message.initialSequencer !== undefined && (obj.initialSequencer = message.initialSequencer);
    if (message.minSequencerBond) {
      obj.minSequencerBond = message.minSequencerBond.map((e) => e ? Coin.toJSON(e) : undefined);
    } else {
      obj.minSequencerBond = [];
    }
    message.vmType !== undefined && (obj.vmType = rollapp_VMTypeToJSON(message.vmType));
    message.launched !== undefined && (obj.launched = message.launched);
    message.preLaunchTime !== undefined && (obj.preLaunchTime = message.preLaunchTime.toISOString());
    message.livenessEventHeight !== undefined && (obj.livenessEventHeight = Math.round(message.livenessEventHeight));
    message.livenessCountdownStartHeight !== undefined
      && (obj.livenessCountdownStartHeight = Math.round(message.livenessCountdownStartHeight));
    if (message.revisions) {
      obj.revisions = message.revisions.map((e) => e ? Revision.toJSON(e) : undefined);
    } else {
      obj.revisions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Rollapp>, I>>(object: I): Rollapp {
    const message = createBaseRollapp();
    message.rollappId = object.rollappId ?? "";
    message.owner = object.owner ?? "";
    message.genesisState = (object.genesisState !== undefined && object.genesisState !== null)
      ? RollappGenesisState.fromPartial(object.genesisState)
      : undefined;
    message.channelId = object.channelId ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? RollappMetadata.fromPartial(object.metadata)
      : undefined;
    message.genesisInfo = (object.genesisInfo !== undefined && object.genesisInfo !== null)
      ? GenesisInfo.fromPartial(object.genesisInfo)
      : undefined;
    message.initialSequencer = object.initialSequencer ?? "";
    message.minSequencerBond = object.minSequencerBond?.map((e) => Coin.fromPartial(e)) || [];
    message.vmType = object.vmType ?? 0;
    message.launched = object.launched ?? false;
    message.preLaunchTime = object.preLaunchTime ?? undefined;
    message.livenessEventHeight = object.livenessEventHeight ?? 0;
    message.livenessCountdownStartHeight = object.livenessCountdownStartHeight ?? 0;
    message.revisions = object.revisions?.map((e) => Revision.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRevision(): Revision {
  return { number: 0, startHeight: 0 };
}

export const Revision = {
  encode(message: Revision, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== 0) {
      writer.uint32(152).uint64(message.number);
    }
    if (message.startHeight !== 0) {
      writer.uint32(160).uint64(message.startHeight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Revision {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevision();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 19:
          message.number = longToNumber(reader.uint64() as Long);
          break;
        case 20:
          message.startHeight = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Revision {
    return {
      number: isSet(object.number) ? Number(object.number) : 0,
      startHeight: isSet(object.startHeight) ? Number(object.startHeight) : 0,
    };
  },

  toJSON(message: Revision): unknown {
    const obj: any = {};
    message.number !== undefined && (obj.number = Math.round(message.number));
    message.startHeight !== undefined && (obj.startHeight = Math.round(message.startHeight));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Revision>, I>>(object: I): Revision {
    const message = createBaseRevision();
    message.number = object.number ?? 0;
    message.startHeight = object.startHeight ?? 0;
    return message;
  },
};

function createBaseRollappSummary(): RollappSummary {
  return {
    rollappId: "",
    latestStateIndex: undefined,
    latestFinalizedStateIndex: undefined,
    latestHeight: 0,
    latestFinalizedHeight: 0,
  };
}

export const RollappSummary = {
  encode(message: RollappSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollappId !== "") {
      writer.uint32(10).string(message.rollappId);
    }
    if (message.latestStateIndex !== undefined) {
      StateInfoIndex.encode(message.latestStateIndex, writer.uint32(18).fork()).ldelim();
    }
    if (message.latestFinalizedStateIndex !== undefined) {
      StateInfoIndex.encode(message.latestFinalizedStateIndex, writer.uint32(26).fork()).ldelim();
    }
    if (message.latestHeight !== 0) {
      writer.uint32(32).uint64(message.latestHeight);
    }
    if (message.latestFinalizedHeight !== 0) {
      writer.uint32(40).uint64(message.latestFinalizedHeight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RollappSummary {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRollappSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rollappId = reader.string();
          break;
        case 2:
          message.latestStateIndex = StateInfoIndex.decode(reader, reader.uint32());
          break;
        case 3:
          message.latestFinalizedStateIndex = StateInfoIndex.decode(reader, reader.uint32());
          break;
        case 4:
          message.latestHeight = longToNumber(reader.uint64() as Long);
          break;
        case 5:
          message.latestFinalizedHeight = longToNumber(reader.uint64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RollappSummary {
    return {
      rollappId: isSet(object.rollappId) ? String(object.rollappId) : "",
      latestStateIndex: isSet(object.latestStateIndex) ? StateInfoIndex.fromJSON(object.latestStateIndex) : undefined,
      latestFinalizedStateIndex: isSet(object.latestFinalizedStateIndex)
        ? StateInfoIndex.fromJSON(object.latestFinalizedStateIndex)
        : undefined,
      latestHeight: isSet(object.latestHeight) ? Number(object.latestHeight) : 0,
      latestFinalizedHeight: isSet(object.latestFinalizedHeight) ? Number(object.latestFinalizedHeight) : 0,
    };
  },

  toJSON(message: RollappSummary): unknown {
    const obj: any = {};
    message.rollappId !== undefined && (obj.rollappId = message.rollappId);
    message.latestStateIndex !== undefined
      && (obj.latestStateIndex = message.latestStateIndex
        ? StateInfoIndex.toJSON(message.latestStateIndex)
        : undefined);
    message.latestFinalizedStateIndex !== undefined
      && (obj.latestFinalizedStateIndex = message.latestFinalizedStateIndex
        ? StateInfoIndex.toJSON(message.latestFinalizedStateIndex)
        : undefined);
    message.latestHeight !== undefined && (obj.latestHeight = Math.round(message.latestHeight));
    message.latestFinalizedHeight !== undefined
      && (obj.latestFinalizedHeight = Math.round(message.latestFinalizedHeight));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RollappSummary>, I>>(object: I): RollappSummary {
    const message = createBaseRollappSummary();
    message.rollappId = object.rollappId ?? "";
    message.latestStateIndex = (object.latestStateIndex !== undefined && object.latestStateIndex !== null)
      ? StateInfoIndex.fromPartial(object.latestStateIndex)
      : undefined;
    message.latestFinalizedStateIndex =
      (object.latestFinalizedStateIndex !== undefined && object.latestFinalizedStateIndex !== null)
        ? StateInfoIndex.fromPartial(object.latestFinalizedStateIndex)
        : undefined;
    message.latestHeight = object.latestHeight ?? 0;
    message.latestFinalizedHeight = object.latestFinalizedHeight ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
