import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Network } from '../network/network-types';
import { Wallet, WALLET_TYPES, WalletInfoMap, WalletType } from './wallet-types';
import { createWallet, isWalletSupported } from './wallet-service';
import { usePersistedState } from '../../shared/hooks/use-persisted-state';
import { WalletError } from './wallet-error';
import { useNetwork } from '../network/network-context';
import { PortalWalletInitProps, PortalWalletSourceType } from './wallets/portal-wallet/types';
import { PortalWallet } from './wallets/portal-wallet/portal-wallet';
import { QuickAuthWallet } from './wallets/quick-auth/quick-auth-wallet';
import { QuickAuthInitProps } from './wallets/quick-auth/types';

interface WalletContextValue {
    connectWallet: (
        networkId: string,
        type: WalletType,
        reloadIfNotInstalled?: boolean,
        switchNetworkAfterConnect?: boolean,
        initProps?: PortalWalletInitProps | QuickAuthInitProps,
    ) => void;
    disconnectWallet: (networkId: string) => void;
    hubWallet?: Wallet;
    generatedPinCode?: string;
    portalWalletSourceType?: PortalWalletSourceType;
    fetchMostSuitableWallet: (network: Network) => WalletType | undefined;
    installedWallets: { [type in WalletType]?: boolean };
    networkWalletMap: { [networkId: string]: Wallet | undefined };
    networkWalletTypeMap: { [networkId: string]: WalletType | undefined };
    networkWalletConnectingMap: { [networkId: string]: boolean };
    walletError?: WalletError;
    handleWalletError: (error: any) => void;
}

const NETWORK_WALLET_TYPE_MAP_KEY = 'networkWalletTypeMapKey';
const PORTAL_WALLET_SOURCE_TYPE_KEY = 'portalWalletSourceTypeKey';

export const WalletContext = createContext<WalletContextValue>({} as WalletContextValue);

export const useWallet = (): WalletContextValue => useContext(WalletContext);

export const WalletContextProvider = ({ children }: { children: ReactNode }) => {
    const { hubNetwork, getNetwork } = useNetwork();
    const [ networkWalletMap, setNetworkWalletMap ] = useState<{ [networkId: string]: Wallet | undefined }>({});
    const [ networkWalletConnectingMap, setNetworkWalletConnectingMap ] = useState<{ [networkId: string]: boolean }>({}); // todo: update all the maps to wallets state
    const [ networkWalletTypeMap, setNetworkWalletMapType, saveNetworkWalletMapType ] =
        usePersistedState<{ [networkId: string]: WalletType | undefined }>(NETWORK_WALLET_TYPE_MAP_KEY, {});
    const [ portalWalletSourceType, setPortalWalletSourceType, savePortalWalletSourceType ] =
        usePersistedState<PortalWalletSourceType | undefined>(PORTAL_WALLET_SOURCE_TYPE_KEY, undefined);
    const [ walletError, setWalletError ] = useState<WalletError>();
    const [ installedWallets, setInstalledWallets ] = useState<{ [type in WalletType]?: boolean }>({});
    const [ generatedPinCode, setGeneratedPinCode ] = useState<string>();

    const hubWallet = useMemo(() => hubNetwork && networkWalletMap[hubNetwork.chainId], [ hubNetwork, networkWalletMap ]);

    const handleWalletError = useCallback((error: any): void => {
        if (error instanceof WalletError && error.code !== 'UNSUPPORTED_WALLET') {
            if (error.originalError?.message?.includes(`key doesn't exist`)) {
                error = new WalletError('KEY_NOT_FOUND', error.walletType, error.network, error.originalError);
            }
            if (error.originalError?.message?.toLowerCase().includes('reject')) {
                error = new WalletError('REQUEST_REJECTED', error.walletType, error.network, error.originalError);
                if (error.network) {
                    setNetworkWalletMapType((networkWalletTypeMap) => {
                        networkWalletTypeMap[error.network.chainId] = undefined;
                        return networkWalletTypeMap;
                    });
                    setNetworkWalletMap((networkWalletMap) => {
                        networkWalletMap[error.network.chainId] = undefined;
                        return networkWalletMap;
                    });
                    setNetworkWalletConnectingMap((networkWalletConnectingMap) => {
                        networkWalletConnectingMap[error.network.chainId] = false;
                        return networkWalletConnectingMap;
                    });
                }
            }
            setWalletError(error);
            setTimeout(() => setWalletError(undefined), 50); // todo: do it different (the error object should be paces at the specific modules)
        } else {
            console.error(error);
        }
    }, [ setNetworkWalletMapType ]);

    const createAndSaveWallet = useCallback((
        networkId: string,
        walletType: WalletType,
        switchNetworkAfterConnect?: boolean,
        initProps?: PortalWalletInitProps | QuickAuthInitProps,
    ) => {
        const network = getNetwork(networkId);
        if (network && networkWalletMap[networkId]?.getWalletType() !== walletType) {
            setNetworkWalletConnectingMap((connectingMap) => {
                if (connectingMap[networkId]) {
                    return connectingMap;
                }
                createWallet(walletType, () => window.location.reload())
                    .then(async (wallet) => {
                        if (walletType === 'PortalWallet' && initProps) {
                            initProps.network = network;
                            const { success, generatedPinCode } = await (wallet as PortalWallet).init(initProps as PortalWalletInitProps);
                            if (!success) {
                                return;
                            }
                            setGeneratedPinCode(generatedPinCode);
                        } else if (walletType === 'Quick Auth') {
                            initProps = { ...initProps, network };
                            const success = await (wallet as QuickAuthWallet).init(initProps as QuickAuthInitProps);
                            if (!success) {
                                return;
                            }
                        }
                        setNetworkWalletMap({ ...networkWalletMap, [networkId]: wallet });
                        if (switchNetworkAfterConnect) {
                            wallet.switchNetwork?.(network);
                        }
                    })
                    .catch((error) => {
                        handleWalletError(error);
                        setNetworkWalletMapType({ ...networkWalletTypeMap, [networkId]: undefined });
                        setNetworkWalletMap((networkWalletMap) => ({ ...networkWalletMap, [networkId]: undefined }));
                    })
                    .finally(() => setNetworkWalletConnectingMap((connectingMap) => ({ ...connectingMap, [networkId]: false })));
                return ({ ...connectingMap, [networkId]: true });
            });
        }
    }, [ handleWalletError, networkWalletMap, networkWalletTypeMap, getNetwork, setNetworkWalletMapType ]);

    const connectWallet = useCallback((
        networkId: string,
        type: WalletType,
        reloadIfNotInstalled?: boolean,
        switchNetworkAfterConnect?: boolean,
        initProps?: PortalWalletInitProps | QuickAuthInitProps,
    ) => {
        if (!installedWallets[type] && (type !== 'MetaMask' || !isMobile)) {
            if (reloadIfNotInstalled) {
                saveNetworkWalletMapType(({ ...networkWalletTypeMap, [networkId]: type }));
                window.location.reload();
            }
            return;
        } else {
            setNetworkWalletMap(({ ...networkWalletMap, [networkId]: undefined }));
            setNetworkWalletMapType(({ ...networkWalletTypeMap, [networkId]: type }));
        }
        if (initProps && type === 'PortalWallet') {
            savePortalWalletSourceType((initProps as PortalWalletInitProps).sourceType);
        }
        createAndSaveWallet(networkId, type, switchNetworkAfterConnect, initProps);
    }, [
        installedWallets,
        createAndSaveWallet,
        saveNetworkWalletMapType,
        networkWalletTypeMap,
        networkWalletMap,
        setNetworkWalletMapType,
        savePortalWalletSourceType,
    ]);

    const disconnectWallet = useCallback((networkId: string): void => {
        networkWalletMap[networkId]?.clear();
        setNetworkWalletMapType({ ...networkWalletTypeMap, [networkId]: undefined });
        setNetworkWalletMap({ ...networkWalletMap, [networkId]: undefined });
        setPortalWalletSourceType(undefined);
    }, [ networkWalletMap, networkWalletTypeMap, setNetworkWalletMapType, setPortalWalletSourceType ]);

    useEffect(() => Object.keys(networkWalletTypeMap).forEach((networkId) => {
        const walletType = networkWalletTypeMap[networkId];
        if (!walletType && networkWalletMap[networkId]) {
            setNetworkWalletMap({ ...networkWalletMap, [networkId]: undefined });
        }
        if (walletType) {
            const portalWalletInitProps = walletType === 'PortalWallet' && portalWalletSourceType ?
                { sourceType: portalWalletSourceType, cacheOnly: true } : undefined;
            createAndSaveWallet(networkId, walletType, false, portalWalletInitProps);
        }
    }), [ createAndSaveWallet, getNetwork, networkWalletMap, networkWalletTypeMap, portalWalletSourceType ]);

    useEffect(() => {
        WALLET_TYPES.map((walletType) =>
            (walletType === 'PortalWallet' || walletType === 'Quick Auth' ? Promise.resolve() : createWallet(walletType))
                .then(() => setInstalledWallets((installedWallets) => ({ ...installedWallets, [walletType]: true })))
                .catch(() => undefined),
        );
    }, []);

    const fetchMostSuitableWallet = useCallback((network: Network): WalletType | undefined => {
        if (networkWalletTypeMap[network.chainId]) {
            return networkWalletTypeMap[network.chainId];
        }
        let walletType: WalletType | undefined = hubWallet?.getWalletType();
        const walletInfoType = walletType && WalletInfoMap[walletType].type;
        if (walletInfoType !== 'evm' && network.type === 'EVM') {
            walletType = Object.values(networkWalletTypeMap).find((type) => type && WalletInfoMap[type].type === 'evm');
        } else if (walletInfoType !== 'cosmos' && (network.type === 'Regular' || (network.type === 'RollApp' && !network.evm))) {
            walletType = Object.values(networkWalletTypeMap).find((type) => type && WalletInfoMap[type].type === 'cosmos');
        } else if (network.type === 'Solana') {
            walletType = Object.values(networkWalletTypeMap).find((type) => type && WalletInfoMap[type].type === 'solana');
        }
        if (!walletType) {
            walletType = WALLET_TYPES.find((type) =>
                installedWallets[type] && isWalletSupported(network, type) && type !== 'Quick Auth' && type !== 'PortalWallet');
        }
        return walletType;
    }, [ hubWallet, installedWallets, networkWalletTypeMap ]);

    return (
        <WalletContext.Provider
            value={{
                installedWallets,
                walletError,
                hubWallet,
                fetchMostSuitableWallet,
                generatedPinCode,
                portalWalletSourceType,
                networkWalletConnectingMap,
                networkWalletTypeMap,
                networkWalletMap,
                handleWalletError,
                connectWallet,
                disconnectWallet,
            }}
        >
            {children}
        </WalletContext.Provider>
    );
};
