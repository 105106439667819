import { Auth } from '@firebase/auth';
import { Analytics } from '@firebase/analytics';
import { Firestore } from '@firebase/firestore';
import { FirebaseStorage } from '@firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

export const FIREBASE_CONFIG = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export let analytics: Analytics;
export let firestore: Firestore;
export let auth: Auth;
export let storage: FirebaseStorage;

export const APP_GENERAL_SETTINGS = 'app-settings/general';

export interface AppGeneralSettings {
    version: number;
}

export const initializeFirebase = () => {
    const firebaseApp = initializeApp(FIREBASE_CONFIG);
    firestore = getFirestore(firebaseApp);
    auth = getAuth(firebaseApp);
    storage = getStorage(firebaseApp);

    if (process.env.REACT_APP_ENV === 'local') {
        connectStorageEmulator(storage, 'localhost', 9199);
        connectFirestoreEmulator(firestore, 'localhost', 8080);
    } else {
        analytics = getAnalytics(firebaseApp);
    }
};
