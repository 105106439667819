import { OfflineSigner } from 'cosmjs/packages/proto-signing';
import { AccountData } from 'cosmjs/packages/proto-signing/src/signer';
import { WalletError } from '../../wallet-error';
import { convertToHexAddress } from '../../wallet-service';
import { Wallet, WalletType } from '../../wallet-types';
import { ACCOUNT_SERIALIZATION_KEY, QuickAuthInitProps } from './types';

export class QuickAuthWallet implements Wallet {
    private sourceAccount?: AccountData;

    public clear(): void {
        localStorage.removeItem(ACCOUNT_SERIALIZATION_KEY);
    }

    public getWalletType(): WalletType {
        return 'Quick Auth';
    }

    public async getAddress(): Promise<{ address?: string, hexAddress?: string }> {
        if (!this.sourceAccount) {
            throw new WalletError('KEY_NOT_FOUND', this.getWalletType());
        }
        return { address: this.sourceAccount.address, hexAddress: convertToHexAddress(this.sourceAccount.address) };
    }

    public async getOfflineSigner(): Promise<OfflineSigner> {
        if (!this.sourceAccount) {
            throw new WalletError('NO_OFFLINE_SIGNER', this.getWalletType());
        }
        const accounts = [ this.sourceAccount ];
        return {
            getAccounts: async () => accounts,
            signDirect: async () => {
                throw new WalletError('UNSUPPORTED_MESSAGE', this.getWalletType());
            },
        };
    }

    public setAccountChangesListener(listener: () => void): void {}

    public async validateWalletInstalled(): Promise<void> {}

    public async init(initProps: QuickAuthInitProps): Promise<boolean> {
        if (initProps.account?.sourceAccount) {
            this.sourceAccount = initProps.account.sourceAccount;
            localStorage.setItem(ACCOUNT_SERIALIZATION_KEY, JSON.stringify(this.sourceAccount));
        } else {
            const serialization = localStorage.getItem(ACCOUNT_SERIALIZATION_KEY);
            if (serialization) {
                this.sourceAccount = JSON.parse(serialization, (key, value) => key === 'pubkey' ? Object.values(value) : value);
            }
        }
        return Boolean(this.sourceAccount);
    }
}

