import { useEffect, useState } from 'react';

const useVisibility = () => {
    const [ isVisible, setIsVisible ] = useState<boolean>(document.visibilityState === 'visible');

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsVisible(document.visibilityState === 'visible');
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, []);

    return isVisible;
};

export default useVisibility;
