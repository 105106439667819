import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog, {
    DialogAction,
    DialogContent,
    DialogFooter,
    DialogProps,
    DialogTitle,
} from '../../../../../../../../shared/components/dialog/dialog';
import FileUploader from '../../../../../../../../shared/components/file-uploader/file-uploader';
import Input from '../../../../../../../../shared/components/form-controls/input/input';
import TextArea from '../../../../../../../../shared/components/form-controls/text-area/text-area';
import { convertToFile } from '../../../../../../../../shared/utils/file-utils';
import { validateUrl } from '../../../../../../../../shared/utils/text-utils';
import { App } from '../../../../../../../client/station-clients/dymension/generated/rollapp/app';
import { getFeeCurrency } from '../../../../../../../currency/currency-service';
import { useNetwork } from '../../../../../../../network/network-context';
import TransactionFee from '../../../../../../../tx/transaction-fee/transaction-fee';
import useHandleTxResponses from '../../../../../../../tx/use-handle-tx-responses';
import { useWallet } from '../../../../../../../wallet/wallet-context';
import { LOGO_MAX_SIZE, MAX_DESCRIPTION_LENGTH, MAX_URL_LENGTH } from '../../../../types';
import { useEditApp } from './use-edit-app';
import './edit-app-dialog.scss';

interface EditAppDialogProps extends DialogProps {
    appToEdit?: App;
}

const EditAppDialog: React.FC<EditAppDialogProps> = ({ appToEdit, ...dialogProps }) => {
    const { hubNetwork, rollAppParamsLoading } = useNetwork();
    const { hubWallet } = useWallet();
    const {
        app,
        showErrors,
        appLogoFile,
        appTxState,
        logoUploading,
        appRegistrationFee,
        appRegistrationFeeLoading,
        appNameAlreadyExists,
        saveApp,
        updateApp,
        setAppLogoFile,
    } = useEditApp(appToEdit);
    const [ initialAppLogo, setInitialAppLogo ] = useState<File>();
    const [ appLogoLoading, setAppLogoLoading ] = useState(false);
    useHandleTxResponses(appTxState, hubWallet, dialogProps.onRequestClose);

    useEffect(() => {
        if (app.imageUrl) {
            setAppLogoLoading(true);
            convertToFile(app.imageUrl).then(setInitialAppLogo).finally(() => setAppLogoLoading(false));
        }
    }, [ app.imageUrl ]);

    const onOrderChange = useCallback((value: string, previousValue: string) => {
        if (/[^0-9]/.test(value)) {
            return previousValue;
        }
    }, []);

    const fees = useMemo(() => {
        const feeCurrency = hubNetwork && getFeeCurrency(hubNetwork);
        return !feeCurrency ? [] : [
            {
                label: 'Transaction fee',
                loading: appTxState.feeLoading,
                value: appTxState.fee?.coins || { currency: feeCurrency, amount: 0, networkId: hubNetwork?.chainId },
            },
            ...(appToEdit ? [] : [
                {
                    label: 'App registration fee',
                    loading: appRegistrationFeeLoading,
                    value: appRegistrationFee || { currency: feeCurrency, amount: 0, networkId: hubNetwork?.chainId },
                },
            ]),
        ];
    }, [ appRegistrationFee, appRegistrationFeeLoading, appToEdit, appTxState.fee?.coins, appTxState.feeLoading, hubNetwork ]);

    return (
        <Dialog className='edit-app-dialog' {...dialogProps}>
            <DialogTitle>{appToEdit ? 'Edit' : 'Create'} App</DialogTitle>
            <DialogContent>
                <div className='controls-row'>
                    <div className='control-container app-logo-container'>
                        <div className='control-label-container'>
                            <label className='required'>Upload App Logo</label>
                        </div>
                        <FileUploader
                            className='logo-uploader'
                            acceptImages
                            hideFileName
                            disabled={appLogoLoading}
                            loading={appLogoLoading}
                            maxHeight={LOGO_MAX_SIZE}
                            maxWidth={LOGO_MAX_SIZE}
                            error={showErrors && (!appLogoFile && !app.imageUrl) && 'App logo is required'}
                            onFileSelect={setAppLogoFile}
                            initialFile={initialAppLogo}
                        />
                    </div>

                    <div className='controls-group'>
                        <div className='control-container'>
                            <div className='control-label-container'><label className='required'>Name</label></div>
                            <Input
                                required
                                value={app.name}
                                maxLength={32}
                                onValueChange={(value) => updateApp('name', value)}
                                error={showErrors &&
                                    (!app.name?.trim() ? 'Missing name' : appNameAlreadyExists ? 'App name already exists' : undefined)}
                            />
                        </div>

                        <div className='control-container'>
                            <div className='control-label-container'><label className='required'>URL</label></div>
                            <Input
                                required
                                value={app.url}
                                placeholder='e.g., https://app-domain.com/'
                                typeFinishDelay={1000}
                                maxLength={MAX_URL_LENGTH}
                                error={showErrors && validateUrl(app.url)}
                                onTypeFinish={(value) => updateApp('url', value)}
                            />
                        </div>

                        <div className='control-container'>
                            <div className='control-label-container'><label>Order</label></div>
                            <Input
                                value={app.order === -1 ? '' : app.order}
                                typeFinishDelay={300}
                                onValueChange={onOrderChange}
                                onTypeFinish={(value) => updateApp('order', !value ? '' : Number(value))}
                            />
                        </div>
                    </div>
                </div>

                <div className='control-container'>
                    <div className='control-label-container'>
                        <label>Description{app.description ? ` (${app.description.length}/${MAX_DESCRIPTION_LENGTH})` : ''}</label>
                    </div>
                    <TextArea
                        value={app.description}
                        maxLength={MAX_DESCRIPTION_LENGTH}
                        onValueChange={(value) => updateApp('description', value)}
                    />
                </div>
            </DialogContent>
            <DialogAction
                primary
                className='save-button'
                loading={logoUploading || appTxState.broadcasting}
                disabled={logoUploading || appTxState.broadcasting || rollAppParamsLoading}
                onClick={saveApp}
            >
                Save
            </DialogAction>
            <DialogFooter className='dialog-footer section small'>
                {fees.map((fee, feeIndex) => <TransactionFee key={feeIndex} fee={fee} hideAmount={!hubWallet} />)}
            </DialogFooter>
        </Dialog>
    );
};

export default EditAppDialog;
