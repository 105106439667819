import {
    MsgBeginRedelegateEncodeObject,
    MsgDelegateEncodeObject,
    MsgUndelegateEncodeObject,
    MsgCancelUnbondingDelegationEncodeObject,
} from 'cosmjs/packages/stargate';
import { CoinsAmount } from '../../../currency/currency-types';
import { NewDelegationType } from './new-delegation-types';
import { convertToCoin } from '../../../currency/currency-service';
import { Validator } from '../../validator/validator-types';

interface NewDelegationMessageParams {
    validator: Validator;
    redelegateTo?: Validator;
    type: NewDelegationType;
    delegatorAddress: string;
    coins: CoinsAmount;
    undelegateHeight?: number;
    baseAmountWithoutFee?: bigint;
}

export const createDelegationMessage = ({
    validator,
    redelegateTo,
    type,
    coins,
    undelegateHeight,
    delegatorAddress,
    baseAmountWithoutFee,
}: NewDelegationMessageParams): MsgDelegateEncodeObject | MsgUndelegateEncodeObject | MsgBeginRedelegateEncodeObject | MsgCancelUnbondingDelegationEncodeObject | undefined => {
    const amount = convertToCoin(coins);
    if (baseAmountWithoutFee !== undefined && baseAmountWithoutFee < BigInt(amount.amount)) {
        amount.amount = baseAmountWithoutFee.toString();
    }
    if (type === 'delegate') {
        return {
            typeUrl: '/cosmos.staking.v1beta1.MsgDelegate',
            value: { amount, delegatorAddress, validatorAddress: validator.address },
        };
    } else if (type === 'undelegate') {
        return {
            typeUrl: '/cosmos.staking.v1beta1.MsgUndelegate',
            value: { amount, delegatorAddress, validatorAddress: validator.address },
        };
    } else if (type === 'redelegate') {
        return {
            typeUrl: '/cosmos.staking.v1beta1.MsgBeginRedelegate',
            value: { amount, delegatorAddress, validatorSrcAddress: validator.address, validatorDstAddress: redelegateTo?.address },
        };
    } else if (type === 'cancel-undelegate') {
        return {
            typeUrl: '/cosmos.staking.v1beta1.MsgCancelUnbondingDelegation',
            value: { delegatorAddress, validatorAddress: validator.address, amount, creationHeight: undelegateHeight },
        };
    }
};
