import classNames from 'classnames';
import React, { useMemo } from 'react';
import Addresses from '../../../../shared/components/addresses/addresses';
import Button from '../../../../shared/components/button/button';
import Link from '../../../../shared/components/link/link';
import Property from '../../../../shared/components/property/property';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import useCopyToClipboard from '../../../../shared/hooks/use-copy-to-clipboard';
import { Network } from '../../../network/network-types';
import '../info-section.scss';
import { convertToHexAddress } from '../../../wallet/wallet-service';

interface NetworkInfoProps {
    network: Network;
    className?: string;
}

const NetworkInfo: React.FC<NetworkInfoProps> = ({ network, className }) => {
    const copyToClipboard = useCopyToClipboard();

    const xAccountName = useMemo(() => {
        if (!network.xAccount) {
            return '';
        }
        const xAccountUrlPart = network.xAccount.split('/');
        return `@${xAccountUrlPart[xAccountUrlPart.length - 1]}`;
    }, [ network.xAccount ]);

    return (
        <div className={classNames('info-section section small', className)}>
            <Property label='Network ID' className='info-property' valueClassName='info-property-value'>
                <Button
                    size='small'
                    buttonType='icon'
                    className='info-property-value-content'
                    onClick={() => copyToClipboard(network.chainId || '', 'network ID')}
                >
                    {network.chainId}&nbsp;&nbsp;
                    <CopyIcon />
                </Button>
            </Property>

            {network.website && (
                <Property label='Website' className='info-property' valueClassName='info-property-value'>
                    <Link url={network.website} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{network.website}</span>
                    </Link>
                </Property>
            )}

            {network.owner && (
                <Property label='Founder' className='info-property' valueClassName='info-property-value'>
                    <Addresses
                        className='info-property-value-content'
                        display='long'
                        addresses={[ convertToHexAddress(network.owner), network.owner ]}
                        canCopy
                    />
                </Property>
            )}

            {network.xAccount && (
                <Property label='X (Twitter)' className='info-property' valueClassName='info-property-value'>
                    <Link url={network.xAccount} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{xAccountName}</span>
                    </Link>
                </Property>
            )}

            {network.tgAccount && (
                <Property label='Telegram' className='info-property' valueClassName='info-property-value'>
                    <Link url={network.tgAccount} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{network.tgAccount}</span>
                    </Link>
                </Property>
            )}

            {network.rpc && (
                <Property label='RPC' className='info-property' valueClassName='info-property-value'>
                    <Button
                        size='small'
                        buttonType='icon'
                        className='info-property-value-content'
                        onClick={() => copyToClipboard(network.rpc || '', 'RPC')}
                    >
                        <span className='ellipsis'>{network.rpc}</span>&nbsp;&nbsp;
                        <CopyIcon />
                    </Button>
                </Property>
            )}

            {network.rest && (
                <Property label='REST' className='info-property' valueClassName='info-property-value'>
                    <Button
                        size='small'
                        buttonType='icon'
                        className='info-property-value-content'
                        onClick={() => copyToClipboard(network.rest || '', 'REST')}
                    >
                        <span className='ellipsis'>{network.rest}</span>&nbsp;&nbsp;
                        <CopyIcon />
                    </Button>
                </Property>
            )}

            {network.evm?.rpc && (
                <Property label='Evm RPC' className='info-property' valueClassName='info-property-value'>
                    <Button
                        size='small'
                        buttonType='icon'
                        className='info-property-value-content'
                        onClick={() => copyToClipboard(network.evm?.rpc || '', 'Evm RPC')}
                    >
                        <span className='ellipsis'>{network.evm.rpc}</span>&nbsp;&nbsp;
                        <CopyIcon />
                    </Button>
                </Property>
            )}

            {network.explorerUrl && (
                <Property label='Explorer' className='info-property' valueClassName='info-property-value'>
                    <Link url={network.explorerUrl} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{network.explorerUrl}</span>
                    </Link>
                </Property>
            )}

            {network.description && (
                <Property label='Description' className='info-property' valueClassName='info-property-value text'>
                    {network.description}
                </Property>
            )}
        </div>
    );
};

export default NetworkInfo;
