import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Menu, { MenuAction } from '../../../../shared/components/menu/menu';
import Property from '../../../../shared/components/property/property';
import Spinner from '../../../../shared/components/spinner/spinner';
import { formatNumber, formatPrice } from '../../../../shared/utils/number-utils';
import { useAsset } from '../../../asset/asset-context';
import { getValidatorLogoPath } from '../../../staking/validator/validator.service';
import useHandleTxResponses from '../../../tx/use-handle-tx-responses';
import { AccountNetworkState } from '../../account-network-state';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/wallet.svg';
import { ReactComponent as StakeIcon } from '../../../../assets/icons/stake.svg';
import { getStakingCurrency } from '../../../currency/currency-service';
import NewDelegationDialog, {
    NewDelegationDialogProps,
} from '../../../staking/delegation/new-delegation/new-delegation-dialog/new-delegation-dialog';
import { NewDelegationType } from '../../../staking/delegation/new-delegation/new-delegation-types';
import { StakingContextProvider, useStaking } from '../../../staking/staking-context';
import { Validator } from '../../../staking/validator/validator-types';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletError } from '../../../wallet/wallet-error';
import AccountTotalStakedValue from './account-total-staked-value/account-total-staked-value';
import './account-stake.scss';

interface AccountStakeProps {
    className?: string;
    networkState: AccountNetworkState;
    onRequestClose?: () => void;
    dataRefreshing?: boolean;
    onRefreshDone?: () => void;
}

const VALIDATOR_FALLBACK_IMAGE = require('../../../../assets/icons/validator.svg').default;

const AccountStake: React.FC<AccountStakeProps> = ({ className, networkState, dataRefreshing, onRefreshDone, onRequestClose }) => {
    const navigate = useNavigate();
    const { networkWalletMap, handleWalletError } = useWallet();
    const { getTokenPrice } = useAsset();
    const { stakedValidatorsData, stakingDataState, rewardsTxState, withdrawRewards } = useStaking();
    const [ delegationDialogProps, setDelegationDialogProps ] = useState<NewDelegationDialogProps>();
    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];
    useHandleTxResponses(rewardsTxState, networkWallet);

    const openNewDelegationDialog = (validator: Validator, type: NewDelegationType): void => {
        if (!networkWallet) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED'));
            return;
        }
        setDelegationDialogProps({ validator, type });
    };

    const stakeCurrency = useMemo(() => networkState.network && getStakingCurrency(networkState.network), [ networkState ]);

    const totalRewardsAmount = useMemo(
        () => stakedValidatorsData?.state?.validators?.reduce((current, validator) => current + (validator.reward || 0), 0),
        [ stakedValidatorsData?.state?.validators ],
    );

    const onImageError = (imageElement: HTMLImageElement | null): void => {
        if (imageElement) {
            imageElement.src = VALIDATOR_FALLBACK_IMAGE;
            imageElement.classList.add('fallback');
        }
    };

    useEffect(() => {
        if (dataRefreshing && !stakedValidatorsData?.state.loading) {
            onRefreshDone?.();
        }
    }, [ dataRefreshing, onRefreshDone, stakedValidatorsData?.state.loading ]);

    return <>
        <AccountTotalStakedValue networkState={networkState} />
        <div className='account-menu-actions claim-rewards'>
            <Property label='Claimable Rewards' className='account-menu-action claimable-rewards-property'>
                {totalRewardsAmount === undefined && (stakedValidatorsData?.state?.loading || stakingDataState?.rewardsLoading) ?
                    <Spinner size='xs' /> :
                    `${(totalRewardsAmount && totalRewardsAmount < 0.0001 ? '< 0.0001' :
                        formatNumber(totalRewardsAmount || 0, { maximumFractionDigits: 4 }))}
                    ${stakeCurrency?.displayDenom}`}
            </Property>
            <Button
                className='account-menu-action'
                size='small'
                disabled={!totalRewardsAmount || rewardsTxState?.broadcasting}
                loading={rewardsTxState?.broadcasting}
                onClick={() => withdrawRewards()}
            >
                <WalletIcon />&nbsp;Claim All
            </Button>
        </div>
        <ul className={classNames('account-stake', className)}>
            {(dataRefreshing || stakedValidatorsData?.state.validators === undefined) && stakedValidatorsData?.state.loading ?
                <Spinner className='delegations-loader' /> :
                !stakedValidatorsData?.state.validators?.length && (
                    <Button
                        className='new-stake-button'
                        onClick={() => {
                            navigate(`/${networkState.network?.type === 'Hub' ?
                                'dymension' : 'rollapps/' + networkState.network?.chainId}/staking`);
                            onRequestClose?.();
                        }}
                    >
                        <StakeIcon />&nbsp;New Stake
                    </Button>)}
            {stakedValidatorsData?.state.validators?.map((validator, validatorIndex) => {
                const validatorDelegation = (
                    <li className='delegation-row'>
                        <img
                            src={networkState.network ? getValidatorLogoPath(networkState.network, validator) : ''}
                            className='validator-logo'
                            alt='validator-logo'
                            onError={(error) => onImageError(error.target as HTMLImageElement)}
                        />
                        <span className='validator-name'>{validator.name}</span>

                        <span className='delegation-amount'>
                            {formatPrice(validator.amountStaked || 0, stakeCurrency?.displayDenom, undefined, 9)}
                            <span className='delegation-value'>
                                {stakeCurrency && formatPrice(getTokenPrice({
                                    amount: validator.amountStaked || 0,
                                    currency: stakeCurrency,
                                    networkId: networkState.network?.chainId || '',
                                }) || 0)}
                            </span>
                        </span>
                    </li>
                );
                return (
                    <Menu closeWhenScroll trigger={validatorDelegation} key={validatorIndex}>
                        <MenuAction onClick={() => openNewDelegationDialog(validator, 'delegate')}>Stake</MenuAction>
                        <MenuAction onClick={() => openNewDelegationDialog(validator, 'redelegate')} disabled={!validator.amountStaked}>
                            Redelegate
                        </MenuAction>
                        <MenuAction onClick={() => openNewDelegationDialog(validator, 'undelegate')} disabled={!validator.amountStaked}>
                            Unstake
                        </MenuAction>
                        <MenuAction onClick={() => withdrawRewards(validator)} disabled={!validator.reward || rewardsTxState?.broadcasting}>
                            Claim rewards
                        </MenuAction>
                    </Menu>
                );
            })}
        </ul>
        {delegationDialogProps ?
            <NewDelegationDialog {...delegationDialogProps} onRequestClose={() => setDelegationDialogProps(undefined)} /> :
            undefined}
    </>;
};

const AccountStakeWithContext = (props: AccountStakeProps) => props.networkState.network ? (
    <StakingContextProvider network={props.networkState.network} types={[ 'Staked' ]}>
        <AccountStake {...props} />
    </StakingContextProvider>
) : <></>;

export default AccountStakeWithContext;
