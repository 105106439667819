import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../dialog/dialog';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import Icon from '../icon/icon';
import './confirm.scss';

export interface ConfirmProps extends DialogProps {
    warning?: boolean;
    warningStyle?: boolean;
    title?: ReactNode;
    content?: ReactNode;
    okLabel?: ReactNode;
    cancelLabel?: ReactNode;
    onConfirm?: () => void;
    onCancel?: () => void;
}

const Confirm: React.FC<ConfirmProps> = ({
    warning,
    warningStyle = true,
    className,
    title,
    content,
    okLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    ...otherDialogProps
}) => {
    const showWarning = useMemo(() => warning && warningStyle, [ warning, warningStyle ]);
    const haveTitle = useMemo(() => title || showWarning, [ showWarning, title ]);

    return (
        <Dialog closable className={classNames('confirm-dialog', className)} {...otherDialogProps}>
            {haveTitle && (
                <DialogTitle className={classNames('confirm-dialog-title', { warning: showWarning })}>
                    {showWarning && <Icon className='warning-icon'><WarningIcon /></Icon>}
                    {title ?? (showWarning ? 'Warning' : '')}
                </DialogTitle>
            )}
            {content && <DialogContent className={classNames('confirm-dialog-content', { haveTitle })}>{content}</DialogContent>}
            {cancelLabel && <DialogAction primary={showWarning} close onClick={onCancel}>{cancelLabel}</DialogAction>}
            {okLabel && <DialogAction primary={!showWarning} close onClick={onConfirm}>{okLabel}</DialogAction>}
        </Dialog>
    );
};

export default Confirm;
