import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../shared/components/alert/alert';
import Button from '../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../shared/components/dialog/dialog';
import Spinner from '../../../shared/components/spinner/spinner';
import { usePersistedState } from '../../../shared/hooks/use-persisted-state';
import { formatNumber, formatPrice, roundNumber } from '../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { useAmountTx } from '../../tx/amount-tx/use-amount-tx';
import TransactionFee from '../../tx/transaction-fee/transaction-fee';
import useHandleTxResponses from '../../tx/use-handle-tx-responses';
import { useWallet } from '../../wallet/wallet-context';
import { useSponsorship } from '../sponsorship-context';
import { SponsorshipRecord } from '../sponsorship-types';
import { useSponsorshipVote } from './use-sponsorship-vote';
import './sponsorship-vote-dialog.scss';

interface SponsorshipVoteDialogProps extends DialogProps {
    sponsorship: SponsorshipRecord;
}

const SponsorshipVoteDialog: React.FC<SponsorshipVoteDialogProps> = ({ sponsorship, ...dialogProps }) => {
    const { hubWallet } = useWallet();
    const { hubNetwork, hubCurrency } = useNetwork();
    const navigate = useNavigate();
    const networkState = useHubNetworkState();
    const { loading, voteLoading, paramsLoading, params, stakedAmount, stakingLoading, loadVote } = useSponsorship();
    const { txState, currentVote, totalWeight, setCurrentVote, broadcast } = useSponsorshipVote();
    const [ votePart, setVotePart ] = useState<number | undefined>();
    const [ lastUpdate, setLastUpdate, saveLastUpdate ] = usePersistedState<number>('last-update', 0);
    useHandleTxResponses(txState, hubWallet, () => {
        saveLastUpdate(Date.now());
        dialogProps.onRequestClose?.();
    });

    useEffect(() => loadVote(), [ loadVote ]);

    const availableStaked = useMemo((): CoinsAmount[] => {
        if (!stakedAmount) {
            return !hubCurrency ? [] : [ { currency: hubCurrency, amount: 0, networkId: hubNetwork?.chainId || '' } ];
        }
        const currentRecordWeight = currentVote?.find((record) => record.gaugeId === sponsorship.gaugeId)?.weight || 0;
        return [
            {
                ...stakedAmount,
                amount: roundNumber(stakedAmount.amount * (1 - ((totalWeight - currentRecordWeight) / 100)), 8, true),
            },
        ];
    }, [ currentVote, hubCurrency, hubNetwork?.chainId, sponsorship.gaugeId, stakedAmount, totalWeight ]);

    const {
        amountTxState: votePowerTxState,
        setCoins: setVotePowerCoins,
        setAmount: setVotePowerAmount,
    } = useAmountTx({ networkState, availableBalances: availableStaked });

    const maxWeight = useMemo(
        () => !stakedAmount || !availableStaked.length ? 0 : (100 * availableStaked[0].amount / stakedAmount.amount) || 0,
        [ availableStaked, stakedAmount ],
    );

    const underMinVotingPower = useMemo(
        () => !stakingLoading && stakedAmount && stakedAmount.amount < (params?.minVotingPower || 0),
        [ params?.minVotingPower, stakedAmount, stakingLoading ],
    );

    const notEnoughStake = useMemo(
        () => Boolean(availableStaked.length && !stakingLoading &&
            maxWeight < (params?.minAllocationWeight || 0)) && stakedAmount && params && stakedAmount.amount >= params.minVotingPower,
        [ availableStaked.length, maxWeight, params, stakedAmount, stakingLoading ],
    );

    const onCurrentVoteChange = useCallback((gaugeId: number, weight: number | undefined = 0, updatePowerAmount = true) => {
        const record = currentVote?.find((record) => record.gaugeId === gaugeId);
        if (currentVote && record) {
            record.weight = weight;
            setCurrentVote([ ...currentVote ]);
            if (gaugeId === sponsorship.gaugeId) {
                setVotePart(weight);
                if (stakedAmount && updatePowerAmount) {
                    setVotePowerAmount(roundNumber(weight * stakedAmount.amount / 100, 8));
                }
            }
        }
    }, [ currentVote, setCurrentVote, setVotePowerAmount, sponsorship.gaugeId, stakedAmount ]);

    const onVotePowerChange = useCallback((coins: CoinsAmount) => {
        if (!stakedAmount) {
            return;
        }
        const weight = Math.min(maxWeight, 100 * coins.amount / stakedAmount.amount);
        onCurrentVoteChange(sponsorship.gaugeId, weight, false);
        setVotePowerCoins(coins);
    }, [ maxWeight, onCurrentVoteChange, setVotePowerCoins, sponsorship.gaugeId, stakedAmount ]);

    useEffect(() => {
        if (!voteLoading && !stakingLoading && stakedAmount !== undefined && params) {
            const voteRecord = currentVote?.find((vote) => vote.gaugeId === sponsorship.gaugeId);
            const weight = voteRecord?.weight || params.minAllocationWeight;
            setVotePart(weight);
            setVotePowerCoins({ ...stakedAmount, amount: roundNumber(weight * stakedAmount.amount / 100, 8) });
            if (currentVote && voteRecord && !voteRecord.weight) {
                voteRecord.weight = weight;
                setCurrentVote([ ...currentVote ]);
            }
        }
    }, [ currentVote, params, setCurrentVote, setVotePowerCoins, sponsorship.gaugeId, stakedAmount, stakingLoading, voteLoading ]);

    useEffect(() => {
        const diff = Date.now() - lastUpdate;
        if (diff < 5500) {
            setTimeout(() => setLastUpdate(0), 5500 - diff);
        }
    }, [ lastUpdate, setLastUpdate ]);

    const renderVotingPowerWeightControl = (): ReactElement => {
        return <>
            <input
                min={params?.minAllocationWeight || 0}
                max={maxWeight}
                value={votePart || 0}
                type='range'
                step={0.01}
                className='vote-slider'
                onChange={(event) => onCurrentVoteChange(sponsorship.gaugeId, Number(event.target.value) || 0)}
            />
            <div className='voting-power-label'>
                <span className='secondary-text'>Voting Power:</span>&nbsp;
                {maxWeight && (votePart || 0) <= maxWeight ? <>
                    {formatNumber(votePart || 0, { minimumFractionDigits: 2, maximumFractionDigits: 4 })}%
                    <span className='secondary-text'>
                    &nbsp;/&nbsp;
                </span>
                </> : undefined}
                <span className='secondary-text'>
                    {formatNumber(maxWeight, { minimumFractionDigits: 2, maximumFractionDigits: 4 })}%
                </span>
            </div>
        </>;
    };

    const getSponsorshipName = (): string => {
        if (sponsorship.rollapp) {
            return sponsorship.rollapp.chainName;
        } else if (sponsorship.pool) {
            const poolAssets = sponsorship.pool.assets;
            return `${poolAssets[0]?.currency.displayDenom} / ${poolAssets[1]?.currency.displayDenom}`;
        }
        return '';
    };

    const renderDialogContent = (): ReactElement => {
        return <>
            <AmountTx
                txState={{}}
                controlSize='large'
                inputLoading={stakingLoading}
                amountDisabled={stakingLoading || !stakedAmount || !availableStaked.length ||
                    maxWeight < (params?.minAllocationWeight || 0)}
                amountTxState={votePowerTxState}
                networkState={networkState}
                availableBalances={availableStaked}
                loading={stakingLoading || !availableStaked.length}
                onCoinsChange={onVotePowerChange}
                displayFee={false}
            />
            {renderVotingPowerWeightControl()}

            {totalWeight > 100 && !notEnoughStake &&
                <Alert className='invalid-vote-alert' type='error'>Total cumulative percentage must be less or equal to 100%</Alert>}

            {currentVote?.some((record) => record.weight && record.weight < (params?.minAllocationWeight || 0)) &&
                <Alert className='invalid-vote-alert' type='error'>
                    Percentage must be greater or equal to {roundNumber(params?.minAllocationWeight || 0, 2)}%
                </Alert>}

            {stakedAmount === undefined || stakingLoading || paramsLoading || (!notEnoughStake && !underMinVotingPower) ? undefined : (
                <Alert className='invalid-vote-alert' type={notEnoughStake || underMinVotingPower ? 'warning' : 'info'}>
                    {underMinVotingPower ? <>
                        Your total staking amount is less than the required minimum voting power
                        ({formatPrice(params?.minVotingPower || 0, hubCurrency?.displayDenom)})
                    </> : notEnoughStake ? <>
                        Your available staked amount is less than the minimum endorsement weight for one endorsement
                        ({formatNumber(params?.minAllocationWeight || 0, { minimumFractionDigits: 2 })}%)
                    </> : undefined}
                    <Button className='stake-button' size='small' onClick={() => navigate('/dymension/staking', { relative: 'path' })}>
                        Stake
                    </Button>
                </Alert>
            )}

            <Button
                className='vote-dialog-save-button'
                disabled={hubWallet && (txState.broadcasting ||
                    loading ||
                    voteLoading ||
                    paramsLoading ||
                    stakingLoading ||
                    (availableStaked[0]?.amount || 0) < (params?.minVotingPower || 0))}
                loading={txState.broadcasting}
                onClick={() => broadcast()}
            >
                Confirm
            </Button>
            <TransactionFee fee={{ loading: txState.feeLoading, label: 'Transaction fee', value: txState.fee?.coins }} />
        </>;
    };

    return (
        <Dialog className='sponsorship-vote-dialog' closable {...dialogProps}>
            <DialogTitle>{`Endorse ${getSponsorshipName()}`}</DialogTitle>
            <DialogContent className='dialog-content'>
                {Date.now() - lastUpdate < 5500 ? <Spinner className='margin-horizontally-centered' /> : renderDialogContent()}
            </DialogContent>
        </Dialog>
    );
};

export default SponsorshipVoteDialog;
