import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from '../../../shared/components/form-controls/select/select';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Input, { InputProps } from '../../../shared/components/form-controls/input/input';
import Button from '../../../shared/components/button/button';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import { MenuRefProps } from '../../../shared/components/menu/menu';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getShortenedAddress } from '../../../shared/utils/text-utils';
import { useAsset } from '../../asset/asset-context';
import { useClient } from '../../client/client-context';
import DisplayNameWithFuture from '../../currency/display-name-with-future/display-name-with-future';
import { useDymns } from '../../dymns/dymns-context';
import { Network } from '../../network/network-types';
import { useWallet } from '../../wallet/wallet-context';
import {
    createEmptyCoinsAmount,
    getCurrencyLogoPath,
    getFeeCurrency,
    getMainCurrency,
    isCoinsEquals,
} from '../../currency/currency-service';
import { formatNumber, formatPrice } from '../../../shared/utils/number-utils';
import { CoinsAmount, CUSTOM_BASE_DENOM_PREFIX_REGEX } from '../../currency/currency-types';
import TransactionFee from '../transaction-fee/transaction-fee';
import { TxState } from '../tx-state';
import { AmountTxState } from './amount-tx-state';
import { ReactComponent as ExplorerIcon } from '../../../assets/icons/explorer.svg';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { DeliveryTxCode, Fee, TxResponse } from '../tx-types';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import { TxError } from '../tx-error';
import { AccountNetworkState } from '../../account/account-network-state';
import Spinner from '../../../shared/components/spinner/spinner';
import { useNetwork } from '../../network/network-context';
import CommonTokenBadge from './common-token-badge/common-token-badge';
import './amount-tx.scss';

export interface AmountTxProps {
    txState: TxState,
    amountTxState: AmountTxState,
    networkState: AccountNetworkState;
    availableBalances?: CoinsAmount[];
    disabledBalances?: CoinsAmount[];
    commonTokens?: CoinsAmount[];
    reduceFeeFromBalances?: boolean;
    showPrice?: 'inside' | 'above';
    onCoinsChange: (coins: CoinsAmount) => void;
    getTxResponseMessage?: (response: TxResponse) => Partial<SnackbarMessage> | undefined;
    controlSize?: 'medium' | 'large';
    submitButtonContainer?: ReactElement;
    onTypeFinish?: InputProps['onTypeFinish'];
    displayFee?: boolean;
    hideMaxValueAction?: boolean;
    amountDisabled?: boolean;
    emptyFee?: boolean;
    tokenSelectorDisabled?: boolean;
    loading?: boolean;
    inputLoading?: boolean;
    displayBalance?: boolean;
    showMessages?: boolean;
    extraFees?: Fee[];
    error?: string;
}

const TRANSACTION_IN_PROGRESS_KEY = 'transactionInProgress';

const AmountTx: React.FC<AmountTxProps> = ({
    txState,
    amountTxState,
    networkState,
    availableBalances,
    disabledBalances,
    commonTokens,
    reduceFeeFromBalances = true,
    tokenSelectorDisabled,
    amountDisabled,
    getTxResponseMessage,
    onCoinsChange,
    controlSize,
    submitButtonContainer,
    loading,
    onTypeFinish,
    inputLoading,
    displayFee = true,
    emptyFee,
    showMessages = true,
    error,
    displayBalance = true,
    showPrice,
    extraFees = [],
    hideMaxValueAction,
}) => {
    const { showErrorMessage, showMessage, showWarningMessage, removeMessage } = useSnackbar();
    const { isMobile } = useWindowSize();
    const { networkDenoms, getNetwork } = useNetwork();
    const { networkWalletMap } = useWallet();
    const { clientError } = useClient();
    const { dymnsState } = useDymns();
    const { getTokenPrice } = useAsset();
    const [ currentAmount, setCurrentAmount ] = useState<string>('');
    const [ handledResponses, setHandledResponses ] = useState<{ [key: string]: boolean }>({});
    const containerRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<MenuRefProps>(null);

    const networkWallet = networkState.network ? networkWalletMap[networkState.network?.chainId] : null;
    showPrice = showPrice && isMobile ? 'above' : showPrice;

    useEffect(() => setCurrentAmount(''), [ networkState.network ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        if (currentAmount.endsWith('.')) {
            return;
        }
        if (amountTxState.coins?.amount !== undefined) {
            setCurrentAmount(amountTxState.coins.amount ? amountTxState.coins.amount.toString() : '');
        } else if (!Number(currentAmount)) {
            setCurrentAmount('');
        }
    }, [ amountTxState.coins?.amount, currentAmount ]);

    // todo: when sending all ibc tokens (from rollapp), the balances removed the token and the currency selector is empty

    useEffect(() => {
        if (showMessages && txState.broadcasting) {
            removeMessage(TRANSACTION_IN_PROGRESS_KEY);
            if (txState.signing) {
                showMessage({ content: 'Your wallet is waiting for confirmation and a signature...', key: TRANSACTION_IN_PROGRESS_KEY });
            } else {
                showMessage({
                    content: (
                        <div className='horizontally-centered'>
                            <Spinner size='small' />&nbsp;&nbsp;Transaction is in progress.
                        </div>
                    ),
                    duration: 600000,
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            }
        } else {
            setTimeout(() => removeMessage(TRANSACTION_IN_PROGRESS_KEY), 50);
        }
    }, [ removeMessage, showMessage, showMessages, txState.broadcasting, txState.signing ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        if (!txState.response || handledResponses[txState.response.hash] || !showMessages) {
            return;
        }
        const { hash, network, deliveryTxCode } = txState.response;
        let exploreLink: string = '';
        try {
            exploreLink = network.exploreTxUrl ? (new URL(hash, network.exploreTxUrl)).href : '';
        } catch {}
        const action: SnackbarMessage['action'] = exploreLink ?
            { label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>, callback: () => window.open(exploreLink, '_blank') } :
            undefined;
        const message = getTxResponseMessage?.(txState.response);
        let content = message?.content;
        if (!content) {
            switch (deliveryTxCode) {
                case DeliveryTxCode.SUCCESS:
                    content = 'Transaction successfully submitted!';
                    break;
                case DeliveryTxCode.INSUFFICIENT_FUNDS:
                    console.error(txState.response);
                    content = 'Transaction delivery failed - insufficient funds';
                    break;
                case DeliveryTxCode.OUT_OF_GAS:
                    console.error(txState.response);
                    content = 'Transaction delivery failed - out of gas';
                    break;
                default:
                    if (networkWallet?.getWalletType() === 'Quick Auth' &&
                        [ 'failed to update grant', 'authorization not found', 'fee-grant not found' ]
                            .some((message) => txState.response?.nativeResponse?.rawLog?.includes(message))) {
                        content = 'Unauthorized transaction, check if your Quick Auth session has expired';
                    } else {
                        console.log('Transaction delivery failed with code: ' + deliveryTxCode);
                        content = 'Transaction delivery failed, please try again later';
                    }
            }
        }
        showMessage({
            content,
            action,
            type: deliveryTxCode === DeliveryTxCode.SUCCESS ? 'success' : 'error',
            key: message?.key || hash,
            ...message,
        });
        setCurrentAmount('');
        setHandledResponses({ ...handledResponses, [txState.response.hash]: true });
    }, [ showMessages, getTxResponseMessage, txState.response, showMessage, handledResponses, removeMessage, networkWallet ]);

    useEffect(() => {
        if (!(txState.error instanceof TxError)) {
            return;
        }
        switch (txState.error.code) {
            case 'MISSING_DATA':
                showErrorMessage('Transaction delivery failed: invalid transaction parameters.');
                break;
            case 'MISSING_ROUTE':
                showErrorMessage(`Transaction delivery failed: can't establish a route.`);
                break;
            default:
                showErrorMessage('Transaction delivery failed, please try again later');
        }
    }, [ txState.error, showErrorMessage ]);

    // todo: handle errors different
    useEffect(() => {
        if (!clientError) {
            return;
        }
        const network = clientError.network;
        const networkNameLabel = clientError.network?.chainName || 'the';
        switch (clientError.code) {
            case 'FETCH_DATA_FAILED':
                showErrorMessage(`Can't fetch data from ${networkNameLabel} client, please try again later`);
                break;
            case 'SIMULATE_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to calculate fee, please try again later`);
                break;
            case 'BROADCAST_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to broadcast the transaction, please try again later`);
                break;
            case 'NO_BALANCES':
                const currency = network ? getMainCurrency(network) : undefined;
                const action: SnackbarMessage['action'] = !currency || !network?.faucetUrl ? undefined :
                    {
                        label: 'Get ' + currency.displayDenom,
                        callback: () => window.open(network?.faucetUrl, '_blank'),
                        close: true,
                    };
                showWarningMessage({
                    content: <>
                        There are no balances in your {network ? `${network.chainName} ` : ''}account.<br />
                        Send some tokens there before trying to query or make a transaction.
                    </>,
                    action, duration: 20000,
                    key: 'no-balances-' + network?.chainId,
                });
                break;
            case 'UNSUPPORTED_MESSAGE':
                showErrorMessage(`This transaction not supported by ${networkWallet?.getWalletType() || 'the connected wallet'}`);
                break;
            case 'INSUFFICIENT_FUNDS':
                if (clientError.originalError?.message?.includes?.('send coins to txfees account')) {
                    const denom = clientError.originalError?.message?.includes?.('adym') ? 'DYM' : 'tokens';
                    showErrorMessage(`insufficient ${denom} for fees.`);
                    break;
                }
                showWarningMessage(`Insufficient balance in your ${network ? `${network.chainName} ` : ''}account`);
                break;
            case 'SIGNATURE_VERIFICATION_FAILED':
                showErrorMessage(`Signature verification failed`);
                break;
            case 'INSUFFICIENT_FEES':
                showErrorMessage(`The transaction broadcast encountered a failure due to insufficient fees`);
                break;
            case 'INVALID_COINS':
                showErrorMessage('Insufficient coins type or amount');
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage('The request rejected by the user');
                break;
            default:
                // todo: handle errors different
                showErrorMessage(`${networkNameLabel} client connection failed, please try again later`);
        }
    }, [ clientError, networkWallet, showErrorMessage, showWarningMessage ]);

    const renderCurrencyOption = (balance = amountTxState.coins, showAmount?: boolean): ReactElement | null => {
        if (!balance) {
            return null;
        }
        const showNetworkDomain = balances.some((otherBalance) =>
            otherBalance.currency.displayDenom === balance.currency.displayDenom && otherBalance.networkId !== balance.networkId);
        const showBaseDenom = !showNetworkDomain && balances.some((otherBalance) =>
            otherBalance.currency.displayDenom.toLowerCase() === balance.currency.displayDenom.toLowerCase() &&
            otherBalance.currency.baseDenom !== balance.currency.baseDenom);
        const currencyNetwork = getNetwork(balance.networkId);
        return <>
            {currencyNetwork ? (
                <img className='currency-logo' src={getCurrencyLogoPath(balance.currency, currencyNetwork)} alt='currency logo' />
            ) : null}

            <span className='currency-name-container'>
                <DisplayNameWithFuture coins={balance} />
                {showNetworkDomain && (
                    <span className='currency-network-domain'>
                        {!currencyNetwork ? balance.networkId : getNetworkDomain(currencyNetwork)}
                    </span>
                )}
                {showBaseDenom && (
                    <span className='currency-network-domain'>
                        {!CUSTOM_BASE_DENOM_PREFIX_REGEX.test(balance.currency.baseDenom) ? balance.currency.baseDenom :
                            getShortenedAddress(balance.currency.baseDenom.replace(CUSTOM_BASE_DENOM_PREFIX_REGEX, ''))}
                    </span>
                )}
            </span>

            {showAmount && balance.amount !== undefined && <span className='currency-option-balance'>
                {formatNumber(balance.amount, { minimumFractionDigits: 2, maximumFractionDigits: Math.max(2, balance.currency.decimals) })}
            </span>}
        </>;
    };

    const balances = useMemo(() => {
        const network = networkState.network;
        return availableBalances || networkState.balances || (!network ? [] :
            network.currencies.map((currency) => createEmptyCoinsAmount(network, currency, networkDenoms)));
    }, [ availableBalances, networkDenoms, networkState.balances, networkState.network ]);

    const getNetworkDomain = useCallback((network: Network) => {
        const alias = dymnsState.aliasesMap[network.chainId]?.aliases?.[0];
        return alias ? `@${alias}` : network.chainName;
    }, [ dymnsState.aliasesMap ]);

    const currencyOptionValue = useMemo(
        () => balances.findIndex((balance) => amountTxState.coins && isCoinsEquals(balance, amountTxState.coins)),
        [ amountTxState.coins, balances ],
    );

    const fees = useMemo((): Fee[] => {
        let feeCoins = txState.fee?.coins;
        if (!feeCoins && networkState.network) {
            const networkFeeCurrency = getFeeCurrency(networkState.network);
            feeCoins = createEmptyCoinsAmount(networkState.network, networkFeeCurrency, networkDenoms);
        }
        return [
            {
                label: 'Transaction fee',
                value: emptyFee ? `0.00 ${feeCoins?.currency.displayDenom || ''}` : feeCoins,
                loading: !emptyFee && txState.feeLoading,
            },
            ...extraFees,
        ];
    }, [ emptyFee, extraFees, networkDenoms, networkState.network, txState.fee?.coins, txState.feeLoading ]);

    const renderCurrencyOptions = (): ReactElement[] => {
        return balances.map((balance, balanceIndex) => (
            <Option
                className='currency-option'
                value={balanceIndex}
                key={balanceIndex}
                disabled={disabledBalances?.some((disabledBalance) => isCoinsEquals(disabledBalance, balance))}
            >
                {renderCurrencyOption(balance, true)}
            </Option>
        ));
    };

    const getSelectedCurrencyBalance = (): string => {
        if (loading || (reduceFeeFromBalances &&
            ((!networkState.balances && networkState.balancesLoading) || (!emptyFee && !txState.fee && txState.feeLoading)))) {
            return 'Available: loading...';
        }
        const { coins, availableAmount } = amountTxState;
        if (!networkState.network || !coins) {
            return '';
        }
        if (!networkWallet) {
            return `Available: 0.00 ${coins.currency.displayDenom}`;
        }
        const balance = formatNumber(
            availableAmount, { minimumFractionDigits: 2, maximumFractionDigits: Math.max(2, coins.currency.decimals) },
        );
        return `Available: ${balance} ${coins.currency.displayDenom}`;
    };

    const onCurrencySelect = (currencyIndex: number): void => {
        const balance = balances[currencyIndex];
        if (balance?.currency) {
            setCurrentAmount('');
            onCoinsChange({ ...balance, amount: 0 });
        }
    };

    const onAmountChange = (value: string, previousValue: string): string => {
        let { coins, availableAmount } = amountTxState;
        if (!networkState.network || !coins) {
            return value;
        }
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        const amountPattern = new RegExp('^[0-9]*(\\.[0-9]{0,' + coins.currency.decimals + '})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        let amount = Number(value);
        if (availableAmount && !hideMaxValueAction && amount > availableAmount) {
            amount = availableAmount;
            value = amount.toString();
        }
        onCoinsChange({ ...coins, amount });
        return value;
    };

    const setFullAmount = (): void => {
        const { coins, availableAmount } = amountTxState;
        if (!coins) {
            return;
        }
        setCurrentAmount(availableAmount.toString());
        onCoinsChange({ ...coins, amount: availableAmount });
    };

    const renderInputAmountSuffix = (): ReactElement | undefined => {
        return <>
            {inputLoading && <Spinner size={'small'} className='input-loader' />}

            {showPrice && (
                <span className={classNames('amount-price', showPrice)}>
                    ≈&nbsp;
                    {formatPrice(
                        (amountTxState.coins && getTokenPrice(amountTxState.coins)) || 0,
                        undefined,
                        { notation: 'compact', maximumFractionDigits: 3 },
                    )}
                </span>
            )}

            {!hideMaxValueAction && (
                <Button
                    className='amount-action'
                    buttonType='secondary'
                    size='xs'
                    disabled={inputDisabled}
                    onClick={setFullAmount}
                >
                    MAX
                </Button>
            )}
        </>;
    };

    const currencyOptions = renderCurrencyOptions();

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const coin = balances?.[value as number];
        if (!coin) {
            return false;
        }
        const searchRegExp = new RegExp(searchText, 'i');
        return searchRegExp.test(coin.currency.displayDenom) || searchRegExp.test(coin.currency.baseDenom);
    }, [ balances ]);

    const inputDisabled = useMemo(
        () => amountDisabled || disabledBalances?.some(
            (disabledBalance) => amountTxState.coins && isCoinsEquals(disabledBalance, amountTxState.coins)),
        [ amountDisabled, amountTxState.coins, disabledBalances ],
    );

    const renderHeader = (): ReactElement | null => {
        if (!commonTokens?.length) {
            return null;
        }
        return (
            <div className='common-tokens' style={{ width: containerRef.current?.offsetWidth }}>
                {commonTokens.map((token, tokenIndex) => {
                    const showNetworkDomain = commonTokens?.some((otherToken, otherTokenIndex) =>
                        tokenIndex !== otherTokenIndex &&
                        token.currency.displayDenom.toLowerCase() === otherToken.currency.displayDenom.toLowerCase());
                    return (
                        <CommonTokenBadge
                            key={tokenIndex}
                            token={token}
                            showNetworkDomain={showNetworkDomain}
                            selected={amountTxState.coins && isCoinsEquals(amountTxState.coins, token)}
                            onClick={() => {
                                selectRef.current?.toggleMenu(false);
                                setCurrentAmount('');
                                onCoinsChange(token);
                            }}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className='amount-tx-container' ref={containerRef}>
            <ControlsComposer className='amount-controls' error={error}>
                <Select
                    ref={selectRef}
                    searchPlaceholder='Search...'
                    searchFilterPredicate={searchFilterPredicate}
                    className='token-select'
                    emptySearchResultsLabel='No results found'
                    controlSize={controlSize}
                    header={renderHeader()}
                    value={currencyOptionValue}
                    disabled={!networkState.network || tokenSelectorDisabled}
                    placeholder='Select a token'
                    optionsMenuOpenDisabled={currencyOptionValue >= 0 && availableBalances?.length === 1}
                    moreOptionsLoading={Boolean(!availableBalances && networkState.balancesLoading)}
                    renderTriggerSelectedOption={() => renderCurrencyOption()}
                    onSelect={(currencyIndex) => onCurrencySelect(Number(currencyIndex))}
                    loading={loading}
                >
                    {currencyOptions}
                </Select>
                <Input
                    placeholder='0.00'
                    controlSize={controlSize}
                    value={currentAmount}
                    disabled={inputDisabled}
                    suffix={renderInputAmountSuffix()}
                    onValueChange={onAmountChange}
                    onTypeFinish={onTypeFinish}
                />
            </ControlsComposer>

            {displayBalance && <p className='selected-currency-balance'>{getSelectedCurrencyBalance()}</p>}
            {submitButtonContainer}
            <span className='transaction-bottom-space' />
            {displayFee && fees.length ? (
                <div className='transaction-fees-container section small'>
                    {fees.map((fee, feeIndex) =>
                        <TransactionFee key={feeIndex} loading={loading} fee={fee} hideAmount={!networkState.network || !networkWallet} />,
                    )}
                </div>
            ) : undefined}
        </div>
    );
};

export default AmountTx;
