import React, { useMemo } from 'react';
import Icon from '../../../../../shared/components/icon/icon';
import Property from '../../../../../shared/components/property/property';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/warning.svg';
import { useAsset } from '../../../../asset/asset-context';
import { getMainCurrency } from '../../../../currency/currency-service';
import { Network } from '../../../network-types';
import './market-cap-property.scss';

interface MarketCapPropertyProps {
    network: Network;
    fullyDiluted?: boolean;
}

const MarketCapProperty: React.FC<MarketCapPropertyProps> = ({ network, fullyDiluted }) => {
    const { mainAssetMap, loading } = useAsset();

    const nativeAsset = useMemo(() => mainAssetMap?.[network.chainId], [ mainAssetMap, network.chainId ]);

    const totalAmount = useMemo(() => {
        const { amount = 0, vesting = 0, onChainGov = 0 } = network.totalSupply?.value || {};
        return fullyDiluted ? amount : Math.max(0, amount - vesting - onChainGov);
    }, [ fullyDiluted, network.totalSupply?.value ]);

    const previousDayTotalAmount = useMemo(() => {
        const { amount = 0, vesting = 0, onChainGov = 0 } = network.totalSupply?.previousDayValue || {};
        return fullyDiluted ? amount : Math.max(amount - vesting - onChainGov);
    }, [ fullyDiluted, network.totalSupply?.previousDayValue ]);

    const currentMarketCap = useMemo(
        () => (nativeAsset?.price || 0) * totalAmount / Math.pow(10, getMainCurrency(network).decimals),
        [ nativeAsset?.price, network, totalAmount ],
    );

    const previousDayMarketCap = useMemo(
        () => (nativeAsset?.previousDayPrice || 0) * previousDayTotalAmount / Math.pow(10, getMainCurrency(network).decimals),
        [ nativeAsset?.previousDayPrice, network, previousDayTotalAmount ],
    );

    return (
        <Property
            label={fullyDiluted ? 'FDV Market Cap' : 'Market Cap'}
            className='market-cap-property flex-wrap'
            na={!nativeAsset || nativeAsset.currency.type !== 'main' ||
                (network.type !== 'RollApp' && network.type !== 'Hub') || nativeAsset.futureIRO}
            loading={!mainAssetMap && loading}
        >
            {nativeAsset && (
                <StatisticsChange
                    period='day'
                    currentValue={currentMarketCap}
                    previousValue={previousDayMarketCap}
                >
                    {nativeAsset.invalidMarketCap &&
                        <Icon className='mc-warning-icon' tooltip='Misleading valuation due to illiquid token.'><WarningIcon /></Icon>}
                    {formatPrice(
                        currentMarketCap,
                        undefined,
                        currentMarketCap >= 100000 ? { minimumFractionDigits: 0, maximumFractionDigits: 0 } : {},
                    )}
                </StatisticsChange>
            )}
        </Property>
    );
};

export default MarketCapProperty;

