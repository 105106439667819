import { createContext, ReactNode, useContext, useState } from 'react';

interface LayoutContextValue {
    footerClassName?: string;
    ctaClassName?: string;
    setFooterClassName: (className?: string) => void;
    setCtaClassName: (className?: string) => void;
}

export const LayoutContext = createContext<LayoutContextValue>({} as LayoutContextValue);

export const useLayout = (): LayoutContextValue => useContext(LayoutContext);

export const LayoutContextProvider = ({ children }: { children: ReactNode }) => {
    const [ footerClassName, setFooterClassName ] = useState<string>();
    const [ ctaClassName, setCtaClassName ] = useState<string>();

    return (
        <LayoutContext.Provider value={{ footerClassName, ctaClassName, setFooterClassName, setCtaClassName }}>
            {children}
        </LayoutContext.Provider>
    );
};

