import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Subscription } from 'zen-observable-ts';
import { formatNumber, formatPrice } from '../../../../../shared/utils/number-utils';
import { convertKeysToCamelCase } from '../../../../../shared/utils/object-utils';
import {
    Bar,
    CustomTimezones,
    IBasicDataFeed,
    IChartingLibraryWidget,
    ISymbolValueFormatter,
    LibrarySymbolInfo,
    ResolutionString,
    widget,
} from '../../../../../trading-view';
import { getMaxDenomAmount, isDenomsEquals } from '../../../../currency/currency-service';
import { loadTradeGroups } from '../../../../trade/trade-service';
import { TradeGroup, TradeGroupPeriod } from '../../../../trade/types';
import { Asset } from '../../../asset-types';

const SUPPORTED_RESOLUTIONS = [ '5', '30', '60', '1D' ] as ResolutionString[];
const DEFAULT_RESOLUTION = '30' as ResolutionString;
const DEFAULT_IRO_RESOLUTION = '5' as ResolutionString;

let chartWidget: IChartingLibraryWidget;

// noinspection GraphQLUnresolvedReference
const TRADE_GROUPS_SUBSCRIPTION = gql`subscription TradeGroupsSubscription { tradesGroups(mutation: [INSERT, UPDATE]) { _entity } }`;

const gqlClient = new ApolloClient({
    link: new WebSocketLink({ uri: process.env.REACT_APP_SUBQUERY_URL.replace(/^(http)/, 'ws'), options: { reconnect: true } }),
    cache: new InMemoryCache(),
});

export const createPriceChartWidget = (
    asset: Asset,
    vsAsset: Asset,
    usdAsset: Asset,
    chartContainerId: string,
    hubId: string,
): IChartingLibraryWidget => {
    const valueFormatterFactory = (): ISymbolValueFormatter => ({
        format: (price) => formatPrice(Math.max(0, price), '', price < 10 ? { maximumFractionDigits: 4 } : {}),
    });
    chartWidget = new widget({
        symbol: getSymbolName(asset),
        autosize: true,
        interval: asset.iroDenom ? DEFAULT_IRO_RESOLUTION : DEFAULT_RESOLUTION,
        container: chartContainerId,
        disabled_features: [
            'header_symbol_search',
            'header_compare',
            'symbol_search_hot_key',
            'use_localstorage_for_settings',
            'timeframes_toolbar',
            'popup_hints',
        ],
        enabled_features: [ 'items_favoriting' ],
        favorites: { intervals: SUPPORTED_RESOLUTIONS },
        datafeed: createPriceChartDataFeed(asset, hubId, vsAsset, usdAsset),
        library_path: '/trading-view/',
        locale: 'en',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as CustomTimezones,
        theme: 'dark',
        custom_css_url: '/trading-view/styles.css',
        overrides: { 'paneProperties.background': '#1a1717', 'paneProperties.backgroundType': 'solid' },
        custom_formatters: { priceFormatterFactory: valueFormatterFactory, studyFormatterFactory: valueFormatterFactory },
    });
    if (asset.network.type !== 'Hub') {
        chartWidget.onChartReady(() => {
            const button = chartWidget.createButton({ align: 'left', useTradingViewStyle: false });
            button.onclick = () => {
                const activeChart = chartWidget.activeChart();
                const currentSymbol = activeChart.symbolExt()?.name;
                const usdSymbol = getSymbolName(asset);
                const vsCurrencySymbol = getSymbolName(asset, vsAsset);
                button.textContent = `Show price in ${currentSymbol === usdSymbol ? 'USD' : vsAsset.currency.displayDenom}`;
                chartWidget.setSymbol(
                    currentSymbol === usdSymbol ? vsCurrencySymbol : usdSymbol,
                    activeChart.resolution(),
                    () => undefined,
                );
            };
            button.style.cursor = 'pointer';
            button.textContent = `Show price in ${vsAsset.currency.displayDenom}`;
        });
    }
    return chartWidget;
};

const createPriceChartDataFeed = (asset: Asset, hubId: string, vsAsset: Asset, usdAsset: Asset): IBasicDataFeed => {
    const subscriptions: { [uuid: string]: Subscription } = {};
    let loadedGroups: undefined | TradeGroup[];
    let totalCount: number;

    return {
        onReady: (callback) => setTimeout(() => callback({ supported_resolutions: SUPPORTED_RESOLUTIONS })),

        searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => onResultReadyCallback([]),

        resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
            const symbolInfo: LibrarySymbolInfo = {
                ticker: symbolName.replace(new RegExp(`^${asset.currency.displayDenom}`), `${asset.currency.displayDenom} / `),
                name: symbolName,
                type: '',
                session: '24x7',
                timezone: 'Etc/UTC',
                description: '',
                exchange: '',
                listed_exchange: '',
                format: 'price',
                minmov: 1,
                pricescale: 10 ** 32,
                has_intraday: true,
                has_weekly_and_monthly: false,
                supported_resolutions: SUPPORTED_RESOLUTIONS,
                data_status: 'streaming',
            };
            setTimeout(() => onSymbolResolvedCallback(symbolInfo));
        },

        getBars: async (
            symbolInfo,
            resolution,
            periodParams,
            onHistoryCallback,
            onErrorCallback,
        ) => {
            const { from, to, firstDataRequest } = periodParams;
            const fixedFrom = from * 1000;
            let fixedTo = to * 1000;
            if (firstDataRequest) {
                loadedGroups = undefined;
                totalCount = 0;
            }
            try {
                const period = convertResolutionToTradeGroupPeriod(resolution);
                if (!loadedGroups || !loadedGroups.length || (fixedFrom < loadedGroups[0].time && totalCount > loadedGroups.length)) {
                    const loadToTime = loadedGroups?.length ? loadedGroups[0].time - 1 : fixedTo;
                    const { records, count } = await loadTradeGroups(hubId, asset, vsAsset, period, loadToTime);
                    loadedGroups = [ ...records.reverse(), ...(loadedGroups || []) ];
                    totalCount = totalCount || count;
                    fixedTo = Math.max(fixedTo, loadToTime);
                }
                const showInUsd = symbolInfo.name === getSymbolName(asset);
                const bars: Bar[] = loadedGroups
                    .filter((group) => group.time >= fixedFrom && group.time <= fixedTo)
                    .map((group) => convertTradesGroupToChartBar(group, asset, vsAsset, showInUsd ? usdAsset : undefined));
                const noData = bars.length === 0 &&
                    (!loadedGroups.length || loadedGroups[0].time >= fixedFrom) && totalCount <= loadedGroups.length;
                onHistoryCallback(bars, { noData });
            } catch (error) {
                console.error(error);
                onErrorCallback(`Can't load price-chart bars`);
            }
        },

        subscribeBars: (
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
        ) => {
            const observable = gqlClient.subscribe<{ tradesGroups: { _entity: TradeGroup } }>({ query: TRADE_GROUPS_SUBSCRIPTION });
            subscriptions[subscriberUID] = observable.subscribe({
                next: (response) => {
                    const tradeGroup = response.data && convertKeysToCamelCase(response.data.tradesGroups._entity) as TradeGroup;
                    const period = convertResolutionToTradeGroupPeriod(resolution);
                    if (!tradeGroup || tradeGroup.network !== hubId || tradeGroup.period !== period ||
                        ((!isDenomsEquals(asset, tradeGroup.tokenInDenom, undefined, true) ||
                                !isDenomsEquals(vsAsset, tradeGroup.tokenOutDenom, undefined, true)) &&
                            (!isDenomsEquals(vsAsset, tradeGroup.tokenInDenom, undefined, true) ||
                                !isDenomsEquals(asset, tradeGroup.tokenOutDenom, undefined, true)))) {
                        return;
                    }
                    const showInUsd = symbolInfo.name === getSymbolName(asset);
                    const bar = convertTradesGroupToChartBar(tradeGroup, asset, vsAsset, showInUsd ? usdAsset : undefined);
                    onRealtimeCallback(bar);
                },
                error: (error) => console.error('Trade groups subscription error: ', error),
            });
        },
        unsubscribeBars: (subscriberUID) => subscriptions[subscriberUID]?.unsubscribe(),
    };
};

const formatChartBarPrice = (value: number, decimals = 0): number => {
    value = decimals ? value / (10 ** decimals) : value;
    return Number(formatNumber(value, value < 1 ? { maximumSignificantDigits: 4 } : { maximumFractionDigits: 4, useGrouping: false }));
};

const convertTradesGroupToChartBar = (group: TradeGroup, targetAsset: Asset, vsAsset?: Asset, usdAsset?: Asset): Bar => {
    const isSell = isDenomsEquals(targetAsset, group.tokenInDenom, undefined, true);
    if (usdAsset) {
        const decimals = usdAsset.currency.decimals - targetAsset.currency.decimals;
        return {
            time: group.time,
            volume: getMaxDenomAmount(group.usdVolume, usdAsset.currency),
            open: formatChartBarPrice(!isSell ? group.usdOpen : !group.open ? 0 : group.usdOpen / group.open, decimals),
            close: formatChartBarPrice(!isSell ? group.usdClose : !group.close ? 0 : group.usdClose / group.close, decimals),
            low: formatChartBarPrice(!isSell ? group.usdLow : !group.high ? 0 : group.usdHigh / group.high, decimals),
            high: formatChartBarPrice(!isSell ? group.usdHigh : !group.low ? 0 : group.usdLow / group.low, decimals),
        };
    }
    const decimals = vsAsset ? vsAsset.currency.decimals - targetAsset.currency.decimals : 0;
    return {
        time: group.time,
        volume: getMaxDenomAmount(Number(isSell ? group.tokenOutAmount : group.tokenInAmount), vsAsset?.currency),
        open: formatChartBarPrice(!isSell ? group.open : !group.open ? 0 : 1 / group.open, decimals),
        close: formatChartBarPrice(!isSell ? group.close : !group.close ? 0 : 1 / group.close, decimals),
        low: formatChartBarPrice(!isSell ? group.low : !group.high ? 0 : 1 / group.high, decimals),
        high: formatChartBarPrice(!isSell ? group.high : !group.low ? 0 : 1 / group.low, decimals),
    };
};

const getSymbolName = (asset: Asset, vsAsset?: Asset): string => {
    return `${asset.currency.displayDenom}${vsAsset?.currency.displayDenom || 'USD'}`;
};

const convertResolutionToTradeGroupPeriod = (resolution: ResolutionString): TradeGroupPeriod => {
    switch (resolution) {
        case '5':
            return 5;
        case '30':
            return 30;
        case '60':
            return 60;
        case '1D':
            return 1440;
    }
    return 5;
};
