import classNames from 'classnames';
import { Decimal } from 'cosmjs/packages/math';
import { isNumber } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import Alert from '../../../../../../../shared/components/alert/alert';
import ToggleSwitch from '../../../../../../../shared/components/toggle-switch/toggle-switch';
import { formatNumber } from '../../../../../../../shared/utils/number-utils';
import { getMaxDenomAmount } from '../../../../../../currency/currency-service';
import { useSnackbar } from '../../../../../../../shared/components/snackbar/snackbar-context';
import { convertToBech32Address } from '../../../../../../wallet/wallet-service';
import { useCreateRollapp } from '../../../create-rollapp-context';
import Input from '../../../../../../../shared/components/form-controls/input/input';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import { DEFAULT_DENOM_EXPONENT, MIN_TOKEN_SYMBOL_LENGTH, Token } from '../../../types';
import './token-info-section.scss';

const MAX_INFLATION = 100;

const TokenInfoSection: React.FC = () => {
    const { showErrorMessage } = useSnackbar();
    const {
        showErrors,
        rollapp,
        token,
        totalSupply,
        accounts,
        updateRollapp,
        updateAccounts,
        setShouldGenerateGenesis,
        setToken,
        setTotalSupply,
    } = useCreateRollapp();

    const onTokenInflationValueChange = useCallback((
        type: keyof Token,
        value: string,
        previousValue: string,
    ) => {
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        if (!value) {
            setToken({ ...token, [type]: undefined });
            return '';
        }
        const amountPattern = new RegExp('^[0-9]*(\\.[0-9]*)?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        const maxInflation = type === 'inflationRateChange' ? 100 : MAX_INFLATION;
        const amount = Number(value);
        setToken({ ...token, [type]: Math.min(maxInflation, amount) });
        setShouldGenerateGenesis(true);
        return amount > maxInflation ? maxInflation.toString() : undefined;
    }, [ setToken, setShouldGenerateGenesis, token ]);

    const onTokenNameChange = useCallback((value: string, previousValue: string) => {
        if (/[^a-zA-Z]/.test(value)) {
            return previousValue;
        }
        return value.toUpperCase();
    }, []);

    const onAddressPrefixChange = useCallback((value: string, previousValue: string) => {
        if (/[^a-zA-Z]/.test(value)) {
            return previousValue;
        }
        updateRollapp('bech32Prefix', value.toLowerCase());
        return value.toLowerCase();
    }, [ updateRollapp ]);


    const updateTokenName = useCallback((value: string) => {
        setToken({ ...token, name: value.toUpperCase(), denom: value ? `a${value.toLowerCase()}` : '' });
        if (value.length < MIN_TOKEN_SYMBOL_LENGTH) {
            return;
        }
        const newBech32Prefix = value.toLowerCase();
        accounts.filter((account) => !account.hub && account.bech32Address).forEach((account) => {
            try {
                account.bech32Address = convertToBech32Address(account.address, newBech32Prefix);
            } catch {
                account.bech32Address = undefined;
                showErrorMessage(`Can't convert ${account.address} to bech32 address.`);
            }
        });
        updateAccounts([ ...accounts ])
            .then(() => setShouldGenerateGenesis(true))
            .catch((error) => {
                console.error(`Can't update accounts with the new bech32 prefix.`, error);
                showErrorMessage(`Can't update accounts with the new bech32 prefix, please try again later.`);
            });
    }, [ accounts, setShouldGenerateGenesis, setToken, showErrorMessage, token, updateAccounts ]);

    const updateTotalSupply = useCallback((value: string, previousValue: string) => {
        const amountPattern = new RegExp('^[0-9]{0,18}$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        try {
            setTotalSupply(!value ? undefined : BigInt(Decimal.fromUserInput(value, DEFAULT_DENOM_EXPONENT).atomics));
            setShouldGenerateGenesis(true);
        } catch (error) {}
    }, [ setShouldGenerateGenesis, setTotalSupply ]);

    const invalidMinInflation = useMemo(
        () => isNumber(token.minimumInflationRate) && isNumber(token.initialInflationRate) &&
            token.minimumInflationRate > token.initialInflationRate,
        [ token.initialInflationRate, token.minimumInflationRate ],
    );

    const inflationHitMinYears = useMemo(() => {
        if (token.minimumInflationRate === undefined || !token.inflationRateChange || !token.initialInflationRate) {
            return undefined;
        }
        let currentInflation = token.initialInflationRate;
        let yearsAmount = 0;
        while (currentInflation > token.minimumInflationRate && yearsAmount <= 100) {
            yearsAmount++;
            currentInflation -= (currentInflation * token.inflationRateChange / 100);
        }
        if (yearsAmount === 1 && currentInflation < token.minimumInflationRate) {
            return 0.5;
        }
        return yearsAmount;
    }, [ token.inflationRateChange, token.initialInflationRate, token.minimumInflationRate ]);

    return (
        <div className='token-info-section section'>
            <h5 className='section-header'>RollApp Token</h5>

            <div className='controls-row token-header-row'>
                <div className='control-container control-switch-container'>
                    <div className='control-label-container'>
                        <label>DYM Native</label>
                        <InfoIndicator indicatorSize='small'>
                            This RollApp is powered by DYM
                        </InfoIndicator>
                    </div>
                    <ToggleSwitch
                        isChecked={token.tokenless}
                        disabled={rollapp.sealed}
                        onCheck={(value) => {
                            setShouldGenerateGenesis(true);
                            setToken({ ...token, tokenless: value });
                        }}
                        containerClassName='control-switch'
                    >
                        Use DYM as the RollApp token
                    </ToggleSwitch>
                </div>

                {/*{!token.tokenless ? (*/}
                {/*    <div className='control-container control-switch-container'>*/}
                {/*        <div className='control-label-container'>*/}
                {/*            <label>DYM Fee</label>*/}
                {/*        </div>*/}
                {/*        <ToggleSwitch*/}
                {/*            isChecked={token.dymFee}*/}
                {/*            disabled={rollapp.sealed}*/}
                {/*            onCheck={(value) => {*/}
                {/*                setShouldGenerateGenesis(true);*/}
                {/*                setToken({ ...token, dymFee: value });*/}
                {/*            }}*/}
                {/*            containerClassName='control-switch'*/}
                {/*        >*/}
                {/*            Use DYM as the RollApp fee token*/}
                {/*        </ToggleSwitch>*/}
                {/*    </div>*/}
                {/*) : (*/}
                {token.tokenless && (
                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label className='required'>Address Prefix</label>
                            <InfoIndicator indicatorSize='small'>
                                The address prefix determines the format of all addresses on your RollApp, they are all compatible with both 0x style and the branded address format such as dym1...xyz.
                            </InfoIndicator>
                        </div>
                        <Input
                            error={showErrors && (!rollapp.bech32Prefix ? 'Address prefix is required' :
                                rollapp.bech32Prefix.length < MIN_TOKEN_SYMBOL_LENGTH ?
                                    `Address prefix must be at least ${MIN_TOKEN_SYMBOL_LENGTH} characters.` : '')}
                            placeholder='e.g., rol, dym'
                            maxLength={16}
                            minLength={MIN_TOKEN_SYMBOL_LENGTH}
                            value={rollapp.bech32Prefix}
                            disabled={rollapp.sealed}
                            onValueChange={onAddressPrefixChange}
                        />
                    </div>
                )}
            </div>

            {!token.tokenless && <>
                <div className='controls-row'>
                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label className='required'>Token Symbol</label>
                            <InfoIndicator indicatorSize='small'>
                                The token symbol, or ticker (e.g. 'ROL'), represents the RollApp's token. Its base denomination (e.g. 'aROL') is the smallest unit; in this example, 1 ROL equals 10¹⁸ aROL.
                            </InfoIndicator>
                        </div>
                        <Input
                            className='token-symbol'
                            error={showErrors && (!token.name ? 'Name is required' : token.name.length < MIN_TOKEN_SYMBOL_LENGTH ?
                                `Token symbol must be at least ${MIN_TOKEN_SYMBOL_LENGTH} characters.` : '')}
                            placeholder='e.g., BTC, ETH, DYM'
                            maxLength={6}
                            minLength={MIN_TOKEN_SYMBOL_LENGTH}
                            value={token.name}
                            disabled={rollapp.sealed}
                            onValueChange={onTokenNameChange}
                            onTypeFinish={updateTokenName}
                            suffix={token.denom && (
                                <p className='base-denom-suffix'>
                                    <span className='secondary-text'>1</span> {token.name}
                                    <span className='secondary-text'> = 10<sup>{DEFAULT_DENOM_EXPONENT}</sup></span> {token.denom}
                                </p>
                            )}
                        />
                    </div>

                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label className='required'>Total Supply</label>
                        </div>
                        <Input
                            required
                            className='total-supply'
                            error={showErrors && !totalSupply && 'Total supply is required'}
                            value={!totalSupply ? '' : Decimal.fromAtomics(totalSupply.toString(), DEFAULT_DENOM_EXPONENT).toString()}
                            disabled={rollapp.sealed}
                            onValueChange={updateTotalSupply}
                            suffix={!totalSupply ? '' : (
                                <p className='total-supply-suffix'>
                                    <span className='secondary-text'>
                                        {formatNumber(
                                            getMaxDenomAmount(Number(totalSupply), undefined, DEFAULT_DENOM_EXPONENT),
                                            { notation: 'compact', maximumFractionDigits: 2 },
                                        )}
                                    </span> {token.name}
                                </p>
                            )}
                        />
                    </div>
                </div>
                <div className='controls-row'>
                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label>Initial Inflation Rate</label>
                            <InfoIndicator indicatorSize='small'>
                                The annual rate at which the token is issued. For example, with a total supply of 1B, 10% inflation would result in 100M tokens issued over a year.
                            </InfoIndicator>
                        </div>
                        <Input
                            suffix='%'
                            disabled={rollapp.sealed}
                            value={token.initialInflationRate}
                            error={invalidMinInflation && <></>}
                            onValueChange={(value, previousValue) =>
                                onTokenInflationValueChange('initialInflationRate', value, previousValue)}
                        />
                    </div>

                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label>Decrease Yearly</label>
                            <InfoIndicator indicatorSize='small'>
                                The annual rate at which token issuance decreases. For example, a rate of 50% halves the issuance each year, similar to Bitcoin's halving mechanism but applied annually.
                            </InfoIndicator>
                        </div>
                        <Input
                            suffix='%'
                            disabled={rollapp.sealed}
                            value={token.inflationRateChange}
                            onValueChange={(value, previousValue) =>
                                onTokenInflationValueChange('inflationRateChange', value, previousValue)}
                        />
                    </div>

                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label>Minimum Inflation Rate</label>
                            <InfoIndicator indicatorSize='small'>
                                The rate at which inflation remains constant, i.e. the minimum rate of inflation. This rate can be amended by RollApp governance.
                            </InfoIndicator>
                        </div>
                        <Input
                            suffix='%'
                            disabled={rollapp.sealed}
                            value={token.minimumInflationRate}
                            error={invalidMinInflation && <></>}
                            onValueChange={(value, previousValue) =>
                                onTokenInflationValueChange('minimumInflationRate', value, previousValue)}
                        />
                    </div>
                </div>

                {inflationHitMinYears === undefined ? undefined : (
                    <Alert>
                        Inflation rate will begin at <b>{token.initialInflationRate}%</b> and gradually decrease to <b>{token.minimumInflationRate}%</b>
                        {inflationHitMinYears < 1 ? <> in less than <b>1 year</b>.</> :
                            <> over the course of <b>
                                {Math.min(100, inflationHitMinYears)}{inflationHitMinYears > 100 ? '+' : ''} years</b>.</>}
                    </Alert>
                )}

                <span className={classNames('error-label', { visible: invalidMinInflation })}>
                    Minimum inflation rate must be less than or equal to the initial inflation rate.
                </span>
                {rollapp.evmType === 'WASM' && (
                    <div className='control-container token-factory-switch-container'>
                        <div className='control-label-container'>
                            <label>Token Factory</label>
                            <InfoIndicator indicatorSize='small'>
                                If checked, a smart contract address set by a governance proposal will have the permission to mint and burn native tokens.
                            </InfoIndicator>
                        </div>
                        <ToggleSwitch
                            isChecked={token.tokenFactoryEnabled}
                            disabled={rollapp.sealed}
                            onCheck={(value) => {
                                if (!token.denom) {
                                    showErrorMessage('Missing token name');
                                    return false;
                                }
                                setShouldGenerateGenesis(true);
                                setToken({ ...token, tokenFactoryEnabled: value });
                            }}
                            containerClassName='token-factory-switch'
                        >
                            Allow smart contract mint and burn of native token
                        </ToggleSwitch>
                    </div>
                )}
            </>}

            {token.tokenless || inflationHitMinYears === undefined ? <div className='no-inflation-alert' /> : undefined}
        </div>
    );
};

export default TokenInfoSection;
