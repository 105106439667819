import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { getMainCurrency, getMaxDenomAmount } from '../../../currency/currency-service';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';

const TotalSupplyProperty: React.FC = () => {
    const { network } = useNetworkDashboard();
    const mainCurrency = useMemo(() => getMainCurrency(network), [ network ]);

    return (
        <Property label='Total Supply' na={!network.totalSupply || network.tokenless}>
            <StatisticsChange
                period='day'
                currentValue={network.totalSupply?.value.amount || 0}
                previousValue={network.totalSupply?.previousDayValue?.amount || 0}
            >
                {formatPrice(getMaxDenomAmount(network.totalSupply?.value.amount || 0, mainCurrency), '')}
            </StatisticsChange>
        </Property>
    );
};

export default TotalSupplyProperty;
