import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import Badge from '../../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { getCurrencyLogoPath } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import './common-token-badge.scss';
import { useDymns } from '../../../dymns/dymns-context';
import { useNetwork } from '../../../network/network-context';
import { Network } from '../../../network/network-types';

interface CommonTokenBadgeProps {
    className?: string;
    token: CoinsAmount;
    showNetworkDomain?: boolean;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const CommonTokenBadge: React.FC<CommonTokenBadgeProps> = ({ className, token, disabled, onClick, showNetworkDomain, selected }) => {
    const { getNetwork } = useNetwork();
    const { dymnsState } = useDymns();

    const getNetworkDomain = useCallback((network: Network) => {
        const alias = dymnsState.aliasesMap[network.chainId]?.aliases?.[0];
        return alias ? `@${alias}` : network.chainName;
    }, [ dymnsState.aliasesMap ]);

    const network = useMemo(() => getNetwork(token.networkId), [ getNetwork, token.networkId ]);

    return (
        <Badge
            size='large'
            color={getCssVariableValue('--cream-rgb').split(',').map(Number)}
            className={classNames('common-token', className, { selected })}
            onClick={onClick}
            label={<>
                {network && <img className='currency-logo' src={getCurrencyLogoPath(token.currency, network)} alt='currency logo' />}
                <span className='currency-name-container'>
                    {token.currency.displayDenom}
                    {showNetworkDomain && (
                        <span className='currency-network-domain'>{!network ? token.networkId : getNetworkDomain(network)}</span>
                    )}
                </span>
            </>}
        />
    );
};

export default CommonTokenBadge;
