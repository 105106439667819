import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { PieLabel, PieLabelRenderProps } from 'recharts/types/polar/Pie';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { formatNumber, formatPrice, roundNumber } from '../../../shared/utils/number-utils';
import { SponsorshipRecord, SponsorshipType } from '../sponsorship-types';
import './sponsorship-distribution-chart.scss';

interface SponsorshipsChartProps {
    totalStreamed: number;
    daysStreamed: number;
    distribution?: SponsorshipRecord[];
}

const REST_ROLLAPPS_ID = -1;
const REST_POOLS_ID = -2;
const MAX_DISPLAYED_RECORDS = 10;

type ChartData = { name: string, weight: number, id: number, type: SponsorshipType };

const SponsorshipDistributionChart: React.FC<SponsorshipsChartProps> = ({ distribution, totalStreamed, daysStreamed }) => {
    const { isTablet, width } = useWindowSize();

    const getSponsorshipName = useCallback((sponsorship: SponsorshipRecord) => {
        if (sponsorship.rollapp) {
            return sponsorship.rollapp.chainName;
        }
        if (sponsorship.pool) {
            return `${sponsorship.pool.assets[0].currency.displayDenom} / ${sponsorship.pool.assets[1].currency.displayDenom}`;
        }
        return '';
    }, []);

    const chartData = useMemo<ChartData[]>(() => {
        if (!distribution) {
            return [];
        }
        const displayedRecords = distribution.slice(0, MAX_DISPLAYED_RECORDS).map<ChartData>((sponsorship) =>
            ({ id: sponsorship.gaugeId, weight: sponsorship.weight, type: sponsorship.type, name: getSponsorshipName(sponsorship) }));
        const restRollappsRecord = distribution
            .slice(MAX_DISPLAYED_RECORDS)
            .filter((record) => record.type === 'RollApp')
            .reduce<ChartData>(
                (current, record) => ({ ...current, weight: current.weight + record.weight }),
                { name: 'Rest of RollApps', weight: 0, id: REST_ROLLAPPS_ID, type: 'RollApp' },
            );
        const restPoolsRecord = distribution
            .slice(MAX_DISPLAYED_RECORDS)
            .filter((record) => record.type === 'Pool')
            .reduce<ChartData>(
                (current, record) => ({ ...current, weight: current.weight + record.weight }),
                { name: 'Rest of Pools', weight: 0, id: REST_POOLS_ID, type: 'Pool' },
            );
        return [ ...displayedRecords, restRollappsRecord, restPoolsRecord ].filter((record) => record.weight);
    }, [ getSponsorshipName, distribution ]);

    const getCellColor = useCallback((item: ChartData): string => {
        let colorVariable: string;
        if (item.type === 'RollApp') {
            colorVariable = item.id === REST_ROLLAPPS_ID ? '--dark-blue' : '--light-blue';
        } else {
            colorVariable = item.id === REST_POOLS_ID ? '--dark-green' : '--light-green';
        }
        return getCssVariableValue(colorVariable);
    }, []);

    const renderCustomizedLabel: PieLabel<PieLabelRenderProps & SponsorshipRecord> = (props) => {
        const { index, cx, cy, midAngle, fill, outerRadius, value, ...sponsorship } = props;
        const RADIAN = Math.PI / 180;
        const middleFactor = 1 + (90 - Math.abs(midAngle % 180 - 90)) / 270;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        let startX = Number(cx) + Number(outerRadius) * cos;
        const startY = Number(cy) + Number(outerRadius) * sin;
        let middleX = Number(cx) + cos * (Number(outerRadius) + 8) * middleFactor;
        const middleY = Number(cy) + sin * (Number(outerRadius) + 8) * middleFactor;
        const endX = middleX + (cos >= 0 ? 1 : -1) * (isTablet ? 16 : 32);
        const endY = middleY;
        const startLabelX = endX + (cos >= 0 ? 1 : -1) * 8;
        const startLabelY = endY + 4;
        const labelAnchor = cos >= 0 ? 'start' : 'end';
        if (value === 0) {
            startX -= 3;
            middleX -= 3;
        }
        let sponsorshipName = sponsorship.name;
        if (isTablet && sponsorship.name.length > 24) {
            sponsorshipName = `${sponsorship.name.slice(0, 24)}...`;
        }
        if (width < 800 && sponsorship.name.length > 16) {
            sponsorshipName = `${sponsorship.name.slice(0, 16)}...`;
        }
        return (
            <g className='customized-label'>
                {index === 0 && (
                    <text x={cx} y={cy} textAnchor='middle' fontSize={isTablet ? 14 : 16}>
                        <tspan fontWeight={400} x={cx} dy={-8}>Total Incentives</tspan>
                        <tspan fontSize={isTablet ? 16 : 18} fontWeight='bold' x={cx} dy={28}>
                            {formatPrice(totalStreamed, 'DYM')}
                        </tspan>
                        <tspan
                            fill={getCssVariableValue('--cream-very-dark')}
                            fontSize={isTablet ? 14 : 16}
                            fontWeight='bold'
                            x={cx}
                            dy={24}
                        >
                            {formatNumber(daysStreamed)} day{daysStreamed === 1 ? '' : 's'}
                        </tspan>
                    </text>
                )}
                <path stroke={fill} fill='none' d={`M${startX},${startY}L${middleX},${middleY}L${endX},${endY}`} />
                <circle cx={endX} cy={endY} r={2} fill={fill} stroke='none' />
                <text x={startLabelX} y={startLabelY} fontWeight={500} fontSize={isTablet ? 12 : 14} textAnchor={labelAnchor}>
                    <tspan dx={4 * (cos >= 0 ? 1 : -1)}>{sponsorshipName}</tspan>
                    <tspan fontSize={12} dx={4} textAnchor={labelAnchor} opacity={0.7}>
                        ({roundNumber(value * 100, 2)}%)
                    </tspan>
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width='100%' height={isTablet ? 320 : 420}>
            <PieChart
                className={classNames('sponsorship-distribution-chart', { visible: distribution?.length })}
            >
                <Pie
                    data={chartData}
                    innerRadius={isTablet ? 95 : 125}
                    outerRadius={isTablet ? 110 : 145}
                    paddingAngle={2}
                    isAnimationActive={false}
                    minAngle={4}
                    label={renderCustomizedLabel}
                    labelLine={false}
                    legendType='plainline'
                    stroke='transparent'
                    nameKey='id'
                    dataKey='weight'
                >
                    {chartData.map((dataItem) => <Cell key={dataItem.id} fill={getCellColor(dataItem)} />)}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default SponsorshipDistributionChart;
