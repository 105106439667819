import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import Tooltip from '../tooltip/tooltip';
import './toggle-switch.scss';

interface ToggleSwitchProps {
    children?: ReactNode;
    className?: string;
    containerClassName?: string;
    size?: 'small' | 'medium';
    isChecked?: boolean;
    disabled?: boolean;
    tooltip?: ReactNode;
    onCheck?: (value: boolean) => boolean | void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    children,
    className,
    tooltip,
    size = 'medium',
    disabled,
    containerClassName,
    isChecked,
    onCheck,
}) => {
    const [ checked, setChecked ] = useState(Boolean(isChecked));

    useEffect(() => {
        if (isChecked !== undefined) {
            setChecked(isChecked);
        }
    }, [ isChecked ]);

    const renderInput = (): ReactElement => {
        return (
            <label className={classNames('toggle-switch', className, { 'have-label': Boolean(children) })}>
                <input
                    disabled={disabled}
                    className='toggle-switch-checkbox'
                    type='checkbox'
                    checked={checked}
                    onChange={(event) => {
                        const toSetChecked = !onCheck || onCheck(event.target.checked) !== false;
                        if (toSetChecked) {
                            setChecked(event.target.checked);
                        }
                    }}
                />
                <span className='toggle-switch-slider'></span>
            </label>
        );
    };

    return (
        <div className={classNames('toggle-switch-container', size, containerClassName, { disabled})}>
            {children}
            <span className='space' />
            {!tooltip ? renderInput() : <Tooltip title={tooltip}>{renderInput()}</Tooltip>}
        </div>
    );
};

export default ToggleSwitch;
