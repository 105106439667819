import { useCallback, useEffect, useMemo, useState } from 'react';

type ScrollPosition = { left?: number; top?: number, target?: HTMLElement };

const useScrollPosition = (
    container?: HTMLElement,
    scrollThreshold = 0.25,
): { scrollPosition: ScrollPosition, canLoadMore: boolean } => {
    const [ scrollPosition, setScrollPosition ] = useState<ScrollPosition>({});

    const updatePosition = useCallback((event?: Partial<Event>): void => {
        let target = event?.target as HTMLElement;
        if ('scrollingElement' in target) {
            target = target.scrollingElement as HTMLElement;
        }
        const containerElement = container || document.documentElement;
        if (!target.isSameNode(containerElement)) {
            return;
        }
        setScrollPosition({ left: target?.scrollLeft, top: target?.scrollTop, target });
    }, [ container ]);

    useEffect(() => {
        const scrollableContainer = container || document;
        scrollableContainer.addEventListener('scroll', updatePosition, true);
        updatePosition({ target: container || document.documentElement });
        return () => scrollableContainer.removeEventListener('scroll', updatePosition, true);
    }, [ container, updatePosition ]);

    const canLoadMore = useMemo(
        () => scrollPosition.target?.scrollTop !== undefined &&
            (!container || scrollPosition.target.clientHeight < scrollPosition.target.scrollHeight) &&
            scrollPosition.target.scrollTop +
            (1 + scrollThreshold) * scrollPosition.target.clientHeight >= scrollPosition.target.scrollHeight,
        [
            scrollPosition.target?.clientHeight,
            scrollPosition.target?.scrollHeight,
            scrollPosition.target?.scrollTop,
            scrollThreshold,
            container,
        ],
    );

    return { scrollPosition, canLoadMore };
};

export default useScrollPosition;
