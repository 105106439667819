import classNames from 'classnames';
import React, { useMemo } from 'react';
import Icon from '../../../shared/components/icon/icon';
import InfoIndicator from '../../../shared/components/info-indicator/info-indicator';
import { ReactComponent as EndorsementLogo } from '../../../assets/icons/endorsement.svg';
import Spinner from '../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../shared/utils/number-utils';
import { useNetwork } from '../../network/network-context';
import { useSponsorship } from '../../sponsorship/sponsorship-context';
import { useNetworkDashboard } from '../network-dashboard-context';
import './network-sponsorship.scss';

interface NetworkSponsorshipProps {
    className?: string;
}

const NetworkSponsorship: React.FC<NetworkSponsorshipProps> = ({ className }) => {
    const { network } = useNetworkDashboard();
    const { distribution, loading } = useSponsorship();
    const { hubCurrency } = useNetwork();

    const sponsorship = useMemo(
        () => distribution?.find((record) => record.rollapp?.chainId === network.chainId),
        [ distribution, network.chainId ],
    );

    return <>
        <div className={classNames('network-sponsorship section small', className)}>
            {loading && !distribution ? <div className='sponsorship-loader'><Spinner /></div> : <>
                <p className='sponsorship-header'>
                    <Icon className='dymension-logo'><EndorsementLogo /></Icon>
                    Total {hubCurrency?.displayDenom} Endorsed
                    <InfoIndicator>
                        The total staked DYM endorsed for this RollApp. This value determines the proportion of DYM rewards allocated to it.
                    </InfoIndicator>
                </p>
                {sponsorship ? (
                    <div className='voting-power-progress-bar'>
                        <span className='voting-power-weight' style={{ width: `${(sponsorship?.weight || 0) * 100}%` }} />
                        <span className='voting-power-value'>
                            {formatPrice(sponsorship?.power || 0, hubCurrency?.displayDenom)}
                        </span>
                    </div>
                ) : <p className='sponsorship-disabled'>Endorsement disabled until RollApp launch</p>}
            </>}
        </div>
    </>;
};

export default NetworkSponsorship;
