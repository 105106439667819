import classNames from 'classnames';
import { omit } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Button from '../../../shared/components/button/button';
import Property from '../../../shared/components/property/property';
import Spinner from '../../../shared/components/spinner/spinner';
import { ReactComponent as DepositIcon } from '../../../assets/icons/deposit.svg';
import StatisticsCards from '../../../shared/components/statistics-cards/statistics-cards';
import { DurationTime, getShortDateTimeString, getTimeLeftText } from '../../../shared/utils/date-utils';
import { formatNumber } from '../../../shared/utils/number-utils';
import { useClient } from '../../client/client-context';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import useHandleTxResponses from '../../tx/use-handle-tx-responses';
import { useWallet } from '../../wallet/wallet-context';
import { getTimeLeft } from '../governance-service';
import ProposalTypeBadge from '../proposal-type-badge/proposal-type-badge';
import DepositDialog from './deposit-dialog/deposit-dialog';
import { useProposal } from './proposal-context';
import VoteDialog from './vote-dialog/vote-dialog';
import VotesSummary from './votes-summary/votes-summary';
import './proposal-page.scss';

const ProposalPage: React.FC = () => {
    const { network, proposalState, voteTxState, setVoteOption } = useProposal();
    const { refreshClient } = useClient();
    const [ voteDialogOpen, setVoteDialogOpen ] = useState(false);
    const [ depositDialogOpen, setDepositDialogOpen ] = useState(false);
    const { networkWalletMap } = useWallet();
    const [ timeLeft, setTimeLeft ] = useState<DurationTime>();
    useHandleTxResponses(voteTxState, networkWalletMap[network.chainId], () => {
        setVoteDialogOpen(false);
        if (proposalState.voteOption) {
            setVoteOption(proposalState.voteOption, proposalState.voteOption);
        }
    });

    const proposal = useMemo(() => proposalState.proposal, [ proposalState.proposal ]);

    useEffect(() => {
        if (proposal) {
            setTimeLeft(getTimeLeft(proposal));
        }
        const interval = setInterval(() => {
            if (!proposal) {
                return;
            }
            if ((proposal.status === 'Voting Period' && proposal.votingEndTime < Date.now()) ||
                (proposal.status === 'Deposit Period' && proposal.depositEndTime < Date.now())) {
                refreshClient(network.chainId);
                setTimeout(() => refreshClient(network.chainId), 5000);
                clearInterval(interval);
                return;
            }
            setTimeLeft(getTimeLeft(proposal));
        }, 1000);
        return () => clearInterval(interval);
    }, [ network.chainId, proposal, refreshClient ]);

    useEffect(() => {
        const markdownLinks = document.querySelectorAll('.proposal-description.markdown a');
        markdownLinks.forEach((link) => link.setAttribute('target', '_blank'));
    }, [ proposal ]);

    if (proposal === undefined && proposalState.loading) {
        return <Spinner size='large' className='proposal-loader' />;
    }
    if (!proposal) {
        return <h5 className='not-found-message'>Proposal not found</h5>;
    }

    return (
        <div className='proposal-page page'>
            {network.type === 'RollApp' ? (
                <PathNav>
                    <PathNavItem label='RollApps' url='/rollapps' />
                    <PathNavItem label={network.chainName} url={`/rollapps/${network.chainId}`} />
                    <PathNavItem label='Governance' url={`/rollapps/${network.chainId}/governance`} />
                    <PathNavItem label={`Proposal #${proposal.id}`} />
                </PathNav>
            ) : (
                <PathNav>
                    <PathNavItem label='Dymension' url='/dymension/metrics' />
                    <PathNavItem label='Governance' url={`/dymension/governance`} />
                    <PathNavItem label={`Proposal #${proposal.id}`} />
                </PathNav>
            )}
            <div className='proposal-actions'>
                {(proposal.status === 'Deposit Period' || proposal.status === 'Voting Period') && (
                    <Button
                        buttonType={proposal.status === 'Deposit Period' ? 'primary' : 'secondary'}
                        onClick={() => setDepositDialogOpen(true)}
                    >
                        Deposit&nbsp;&nbsp;<DepositIcon />
                    </Button>
                )}
                {proposal.status === 'Voting Period' && (
                    <Button
                        buttonType='primary'
                        className={classNames('vote-button', proposalState.originalVoteOption?.toLowerCase())}
                        onClick={() => setVoteDialogOpen(true)}
                    >
                        {!proposalState.originalVoteOption ? 'Vote' : `Voted: ${proposalState.originalVoteOption}`}
                    </Button>
                )}
            </div>
            <h3 className='proposal-title'>#{proposal.id} {proposal.title}</h3>
            <div className='type-badge-container'>
                <ProposalTypeBadge proposal={proposal} size='large' />
                {timeLeft && proposal.status === 'Voting Period' && (
                    <span className={classNames('expires-in-label', { alert: timeLeft.days < 2 })}>
                        Expires in {getTimeLeftText(timeLeft, 'double')}
                    </span>
                )}
            </div>
            <Markdown className='proposal-description markdown' remarkPlugins={[ remarkGfm ]}>{proposal.description}</Markdown>

            <StatisticsCards>
                <Property label='Submit On'>
                    {getShortDateTimeString(proposal.submitTime, true)}
                </Property>
                {proposal.status === 'Deposit Period' && (
                    <Property label='Deposit End Time'>
                        {getShortDateTimeString(proposal.depositEndTime, true)}
                    </Property>
                )}
                {proposal.status !== 'Deposit Period' && (
                    <Property label='Voting Starts'>
                        {getShortDateTimeString(proposal.votingStartTime, true)}
                    </Property>
                )}
                {proposal.status !== 'Deposit Period' && (
                    <Property label='Voting Ends'>
                        {getShortDateTimeString(proposal.votingEndTime, true)}
                    </Property>
                )}
                <Property label='Total Deposit'>
                    {formatNumber(proposal.totalDeposit.amount, { maximumSignificantDigits: 6 })}&nbsp;
                    {proposal.totalDeposit.currency.displayDenom}
                </Property>
                {proposalState.params?.minDeposit && proposal.status === 'Deposit Period' && (
                    <Property label='Target Deposit'>
                        {formatNumber(proposalState.params.minDeposit.amount, { maximumSignificantDigits: 6 })}&nbsp;
                        {proposalState.params.minDeposit.currency.displayDenom}
                    </Property>
                )}
            </StatisticsCards>

            {proposal.status !== 'Deposit Period' && (
                <div className='votes-summary-container'>
                    {proposalState.summary === undefined && proposalState.summaryLoading ? <Spinner /> : <VotesSummary />}
                </div>
            )}

            <h5 className='proposal-content-header'>Message</h5>
            <div className='proposal-content section small'>
                <ReactJson
                    style={{ backgroundColor: 'transparent' }}
                    name='content'
                    src={omit(proposal.content, 'title', 'description')}
                    theme='embers'
                    collapsed={false}
                />
            </div>

            {voteDialogOpen && <VoteDialog closable onRequestClose={() => setVoteDialogOpen(false)} />}

            {depositDialogOpen && <DepositDialog closable onRequestClose={() => setDepositDialogOpen(false)} />}
        </div>
    );
};

export default ProposalPage;
