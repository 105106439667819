import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface SplashscreenContextValue {
    loading: boolean;
}

export const SplashscreenContext = createContext<SplashscreenContextValue>({} as SplashscreenContextValue);

export const useSplashscreen = (): SplashscreenContextValue => useContext(SplashscreenContext);

export const SplashscreenContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 3000);
    }, []);

    return <SplashscreenContext.Provider value={{ loading }}>{children}</SplashscreenContext.Provider>;
};
