import React from 'react';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { useAsset } from '../../../../asset/asset-context';

const HubPriceProperty: React.FC = () => {
    const { hubAsset } = useAsset();

    return (
        <div className='statistics-property'>
            <span className='property-label'>{hubAsset?.currency.displayDenom} Price:</span>
            <StatisticsChange period='day' size='small' currentValue={hubAsset?.price || 0} previousValue={hubAsset?.previousDayPrice || 0}>
                {formatPrice(hubAsset?.price || 0)}
            </StatisticsChange>
        </div>
    );
};

export default HubPriceProperty;
