import classNames from 'classnames';
import React, { useMemo } from 'react';
import Icon from '../../../../shared/components/icon/icon';
import Spinner from '../../../../shared/components/spinner/spinner';
import { ReactComponent as ThunderIcon } from '../../../../assets/icons/thunder.svg';
import { ReactComponent as InfoOutlineIcon } from '../../../../assets/icons/info-outline.svg';
import Tooltip from '../../../../shared/components/tooltip/tooltip';
import { formatNumber, roundNumber } from '../../../../shared/utils/number-utils';
import { useIncentives } from '../../../incentives/incentives-context';
import { useSponsorship } from '../../../sponsorship/sponsorship-context';
import { useAmm } from '../../amm-context';
import { Pool } from '../../types';
import './pool-apr.scss';

export interface PoolAprProps {
    pool: Pool;
}

const PoolApr: React.FC<PoolAprProps> = ({ pool }) => {
    const { ammState, incentiveAprs } = useAmm();
    const { distribution, sponsorshipPoolAprs, loading: sponsorshipLoading } = useSponsorship();
    const { incentivesState } = useIncentives();

    const haveIncentives = useMemo(
        () => Boolean(incentiveAprs[pool.id] || sponsorshipPoolAprs[pool.id]),
        [ incentiveAprs, pool.id, sponsorshipPoolAprs ],
    );

    const totalApr = useMemo(
        () => roundNumber(((pool.apr || 0) + incentiveAprs[pool.id] + sponsorshipPoolAprs[pool.id]) * 100, 2),
        [ incentiveAprs, pool.apr, pool.id, sponsorshipPoolAprs ],
    );
    const isLoading = useMemo(() => (!incentivesState.incentives && incentivesState.loading) ||
        (!distribution && sponsorshipLoading) || (!ammState.totalLockedValues && ammState.totalLockedValuesLoading), [
        ammState.totalLockedValues,
        ammState.totalLockedValuesLoading,
        distribution,
        incentivesState.incentives,
        incentivesState.loading,
        sponsorshipLoading,
    ]);

    const incentiveAprTooltipContent = (
        <div className='apr-tooltip'>
            <p>Base fee: <b>{roundNumber((pool.apr || 0) * 100, 2)}</b>%</p>
            {!incentiveAprs[pool.id] ? undefined : <p>
                Incentive rewards: <b>{incentiveAprs[pool.id] < 0.0001 ? '< 0.01' : roundNumber(incentiveAprs[pool.id] * 100, 2)}</b>%
            </p>}
            {!sponsorshipPoolAprs[pool.id] ? undefined : <p>
                Endorsement rewards: <b>{sponsorshipPoolAprs[pool.id] < 0.0001 ? '< 0.01' :
                roundNumber(sponsorshipPoolAprs[pool.id] * 100, 2)}</b>%
            </p>}
        </div>
    );

    if (isLoading) {
        return <Spinner size='small' />;
    }
    return (
        <Tooltip title={incentiveAprTooltipContent} placement='top'>
            <span className={classNames('pool-apr', { 'incentive': haveIncentives })}>
                {haveIncentives ?
                    <Icon className='thunder-icon'><ThunderIcon /></Icon> :
                    <Icon className='apr-info-icon'><InfoOutlineIcon /></Icon>
                }
                {formatNumber(totalApr)}%
            </span>
        </Tooltip>
    );
};

export default PoolApr;
