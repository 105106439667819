import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { useClient } from '../client/client-context';
import { Params, Plan } from '../client/station-clients/dymension/generated/iro/iro';
import { CoinsAmount } from '../currency/currency-types';
import { useNetwork } from '../network/network-context';
import { getNetworkData } from '../network/network-service';
import { getPrice } from './iro-service';

interface IroContextValue {
    iroPlans?: Plan[];
    loading: boolean;
    iroParams?: Params;
    iroParamsLoading: boolean;
    getIroPlan: (rollappId: string) => Plan | undefined;
    refreshIroPlans: () => void;
    getTokenPrice: (coins: CoinsAmount, priceOfOneToken?: boolean) => number;
}

export const IroContext = createContext<IroContextValue>({} as IroContextValue);

export const useIRO = (): IroContextValue => useContext(IroContext);

export const IroContextProvider = ({ children }: { children: ReactNode }) => {
    const { hubNetwork } = useNetwork();
    const { clientStateMap, handleClientError } = useClient();
    const [ iroPlans, setIroPlans ] = useState<Plan[]>();
    const [ iroParamsLoading, setIroParamsLoading ] = useState(true);
    const [ iroParams, setIroParams ] = useState<Params>();
    const [ loading, setLoading ] = useState(true);
    const cancelAndSetIroPlansPromise = useCancelablePromise<Plan[]>();

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setLoading(false);
            return;
        }
        if (!hubNetwork || !clientState) {
            return;
        }
        setLoading(true);
        cancelAndSetIroPlansPromise((signal) => getNetworkData<Plan[]>(hubNetwork.chainId, 'iro-plans', false, signal))
            .then(setIroPlans)
            .catch(handleClientError)
            .finally(() => setLoading(false));
    }, [ clientState, cancelAndSetIroPlansPromise, handleClientError, hubNetwork ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setIroParamsLoading(false);
            return;
        }
        if (!hubNetwork || !clientState) {
            return;
        }
        setIroParamsLoading(true);
        getNetworkData<Params>(hubNetwork.chainId, 'iro-params', true)
            .then(setIroParams)
            .catch(handleClientError)
            .finally(() => setIroParamsLoading(false));
    }, [ handleClientError, clientState, hubNetwork ]);

    const getIroPlan = useCallback((rollappId: string) => iroPlans?.find((plan) => plan.rollappId === rollappId), [ iroPlans ]);

    const getTokenPrice = useCallback((coins: CoinsAmount, priceOfOneToken?: boolean): number => {
        const plan = coins.iroDenom && getIroPlan(coins.networkId);
        if (!plan) {
            return 0;
        }
        return getPrice(plan, priceOfOneToken ? 1 : coins.amount);
    }, [ getIroPlan ]);

    const refreshIroPlans = useCallback(() => setIroPlans((iroPlans) => [ ...(iroPlans || []) ]), []);

    return (
        <IroContext.Provider
            value={{
                iroPlans,
                iroParams,
                loading,
                refreshIroPlans,
                iroParamsLoading,
                getIroPlan,
                getTokenPrice,
            }}
        >
            {children}
        </IroContext.Provider>
    );
};


