import { EthSignType } from '@keplr-wallet/types';
import { toHex } from 'cosmjs/packages/encoding';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { convertToFile, getFileSuffix, readStream } from '../../../shared/utils/file-utils';
import { firestore, storage } from '../../../shared/utils/firebase-utils';
import { useWallet } from '../../wallet/wallet-context';
import { WalletError } from '../../wallet/wallet-error';
import { WalletInfoMap } from '../../wallet/wallet-types';
import { CosmosWallet } from '../../wallet/wallets/cosmos-wallet';
import { EthereumWallet } from '../../wallet/wallets/ethereum-wallet';
import { useHubNetworkState } from '../hub-network-state-context';
import { EMPTY_USER, User, USERS_PATH } from './types';

interface AuthUserContextValue {
    user: User;
    avatarLoading: boolean;
    saveUserDialogOpen: boolean;
    userSaving: boolean;
    userSigning: boolean;
    userAvatar?: File;
    setUser: (user: User) => void;
    setUserAvatar: (file?: File) => void;
    setSaveUserDialogOpen: (value: boolean) => void;
    saveUser: () => void;
    signInUser: () => Promise<string | undefined>;
}

export const AuthUserContext = createContext<AuthUserContextValue>({} as AuthUserContextValue);

export const useAuthUser = (): AuthUserContextValue => useContext(AuthUserContext);

export const AuthUserContextProvider = ({ children }: { children: ReactNode }) => {
    const { showErrorMessage } = useSnackbar();
    const { hubWallet, handleWalletError } = useWallet();
    const hubNetworkState = useHubNetworkState();
    const [ user, setUser ] = useState<User>({ ...EMPTY_USER });
    const [ userSaving, setUserSaving ] = useState(false);
    const [ userSigning, setUserSigning ] = useState(false);
    const [ avatarLoading, setAvatarLoading ] = useState(false);
    const [ userAvatar, setUserAvatar ] = useState<File>();
    const [ saveUserDialogOpen, setSaveUserDialogOpen ] = useState(false);

    useEffect(() => {
        return getAuth().onAuthStateChanged({
            next: async (authUser) => {
                if (!authUser) {
                    setUserAvatar(undefined);
                    setUser({ ...EMPTY_USER });
                    return;
                }
                const userSnapshot = await getDoc(doc(firestore, `${USERS_PATH}/${authUser.uid}`));
                if (userSnapshot.exists()) {
                    setUserSigning(false);
                    setUser({ ...EMPTY_USER, ...userSnapshot.data(), id: userSnapshot.id } as User);
                    return;
                }
                if (userSigning) {
                    setUser({ ...EMPTY_USER, id: authUser.uid });
                    setUserAvatar(undefined);
                    setUserSigning(false);
                    setSaveUserDialogOpen(true);
                    return;
                }
            },
            error: (error) => console.error(`Can't get auth state`, error),
            complete: () => console.log('Complete listening to auth state'),
        });
    }, [ userSigning ]);

    useEffect(() => {
        if (user.id && (!hubNetworkState.hexAddress || user.id !== hubNetworkState.hexAddress.toLowerCase())) {
            getAuth().signOut().then(() => {
                setUserAvatar(undefined);
                setUser({ ...EMPTY_USER });
            });
        }
    }, [ hubNetworkState.hexAddress, user.id ]);

    useEffect(() => {
        if (user.avatar && !userAvatar) {
            setAvatarLoading(true);
            convertToFile(user.avatar).then(setUserAvatar).finally(() => setAvatarLoading(false));
        }
    }, [ user.avatar, user.id, userAvatar ]);

    const generateSignInMessage = useCallback((nonce: number): string => {
        return `Sign this message to authenticate with your wallet. Nonce: ${nonce}. Address: ${hubNetworkState.hexAddress}`;
    }, [ hubNetworkState.hexAddress ]);

    const signInUser = useCallback(async (): Promise<string | undefined> => {
        if (!hubNetworkState.network || !hubNetworkState.hexAddress || !hubNetworkState.address) {
            handleWalletError(new WalletError('WALLET_NOT_CONNECTED', undefined, hubNetworkState.network));
            return undefined;
        }
        if (!hubWallet) {
            return undefined;
        }
        if (hubWallet.getWalletType() === 'PortalWallet' || hubWallet.getWalletType() === 'Quick Auth') {
            showErrorMessage(`Currently, the ${hubWallet.getWalletType() === 'PortalWallet' ?
                'Portal-Wallet' : 'Quick Auth wallet'} cannot be used for posting comments. use different wallet.`);
            return undefined;
        }
        setUserSigning(true);
        let signature: string;
        const address = hubNetworkState.hexAddress.toLowerCase();
        const nonce = new Date().getTime();
        const walletInfoType = WalletInfoMap[hubWallet.getWalletType()].type;
        const signInMessage = generateSignInMessage(nonce);
        if (walletInfoType === 'evm') {
            const provider = await (hubWallet as EthereumWallet).getProvider();
            signature = await provider.request({ method: 'personal_sign', params: [ signInMessage, address ] }).catch((error) => {
                console.error('Failed to sign on sign-in message', error);
                return undefined;
            });
        } else {
            const provider = await (hubWallet as CosmosWallet).getProvider();
            const signatureBuffer = await provider.signEthereum(
                hubNetworkState.network.chainId, hubNetworkState.address, signInMessage, EthSignType.MESSAGE).catch((error) => {
                console.error('Failed to sign on sign-in message', error);
                return undefined;
            });
            signature = signatureBuffer ? '0x' + toHex(signatureBuffer) : '';
        }
        const response = !signature ? undefined : await fetch(process.env.REACT_APP_SIGN_IN_USER_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ address, nonce, signature }),
        }).catch((error) => {
            console.error('Failed to verify signature', error);
            return undefined;
        });
        const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
        const customToken = (JSON.parse(responseText || '{}') as { customToken: string })?.customToken;
        const userCredential = !customToken ? undefined : await signInWithCustomToken(getAuth(), customToken).catch((error) => {
            console.error('Failed to sign-in', error);
            return undefined;
        });
        if (!userCredential?.user.uid) {
            showErrorMessage(`Can't sign-in, please try again later`);
            setUserSigning(false);
        }
        return userCredential?.user.uid;
    }, [
        generateSignInMessage,
        handleWalletError,
        hubNetworkState.address,
        hubNetworkState.hexAddress,
        hubNetworkState.network,
        hubWallet,
        showErrorMessage,
    ]);

    const saveUser = useCallback(async () => {
        if (!hubNetworkState.hexAddress || !user.displayName || !user.id) {
            return;
        }
        setUserSaving(true);
        if (userAvatar) { // todo: only if different than the current photo
            const fileName = `user-avatars/${user.id}/${getFileSuffix(userAvatar.name)}`;
            const fileRef = ref(storage, fileName);
            user.avatar = await uploadBytes(fileRef, userAvatar).then((result) => getDownloadURL(result.ref)).catch((error) => {
                console.error('Failed to upload user avatar', error);
                return user.avatar;
            });
        }
        user.address = hubNetworkState.hexAddress.toLowerCase();
        await setDoc(doc(firestore, `${USERS_PATH}/${user.id}`), user)
            .then(() => setSaveUserDialogOpen(false))
            .catch((error) => {
                console.error(`Can't save user`, error);
                showErrorMessage(`Can't save user, please try again later`);
            })
            .finally(() => setUserSaving(false));
    }, [ hubNetworkState.hexAddress, showErrorMessage, user, userAvatar ]);

    return (
        <AuthUserContext.Provider
            value={{
                user,
                saveUserDialogOpen,
                avatarLoading,
                userAvatar,
                saveUser,
                userSaving,
                signInUser,
                userSigning,
                setUser,
                setSaveUserDialogOpen,
                setUserAvatar,
            }}
        >
            {children}
        </AuthUserContext.Provider>
    );
};

