import { convertToSubNumber, convertToSuperNumber } from './text-utils';

const DECIMAL_AMOUNT_FACTOR = Math.pow(10, 18);

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions): string => {
    return Intl.NumberFormat('en', options).format(value);
};

export const formatPrice = (
    value: number,
    currency?: string,
    options?: Intl.NumberFormatOptions,
    minDecimalsForCompact?: number,
    decimalPrefixZeros = 3,
): string => {
    if (value > 10 ** 18) {
        const fixedValue =
            `${formatNumber(value, { notation: 'scientific', ...(currency === undefined && { style: 'currency', currency: 'USD' }) })
                .replace(/([0-9\\.]+)E([0-9]+)/, (_, base, exponent) => `${base}x10${convertToSuperNumber(exponent)}`)}`;
        return `${fixedValue}${currency ? ' ' + currency : ''}`;
    }
    const price = `${formatNumber(value, {
        ...(value < 0.00001 ? { maximumSignificantDigits: 4 } : value < 1 ?
            { minimumSignificantDigits: 2, maximumSignificantDigits: 4 } :
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        ...(currency !== undefined ? {} : { style: 'currency', currency: 'USD' }),
        ...(minDecimalsForCompact !== undefined && value > Math.pow(10, minDecimalsForCompact) ? { notation: 'compact' } : undefined),
        ...options,
    })}${currency ? ' ' + currency : ''}`;

    return price.replace(new RegExp(`^(\\$?0\\.0{${decimalPrefixZeros},})([1-9][0-9]*)`), (_, zerosPrefix, significantDigits) => {
        const prefixParts = zerosPrefix.split('.');
        return `${prefixParts[0]}.0${convertToSubNumber(prefixParts.slice(1).join('').length)}${significantDigits}`;
    });
};

export const roundNumber = (value: number, fractionDigits: number, floor?: boolean, ceil?: boolean): number => {
    if (floor || ceil) {
        const power = Math.pow(10, fractionDigits);
        value = (floor ? Math.floor : Math.ceil)(value * power) / power;
    }
    return parseFloat(formatNumber(value, { maximumFractionDigits: fractionDigits }).replace(/,/g, ''));
};

export const convertDecimalToInt = (decimalValue: number): number => {
    return decimalValue / DECIMAL_AMOUNT_FACTOR;
};

export const convertIntToDecimal = (intValue: number): number => {
    return intValue * DECIMAL_AMOUNT_FACTOR;
};
