import classNames from 'classnames';
import React, { useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { useAsset } from '../../../../asset/asset-context';
import { AccountNetworkState } from '../../../account-network-state';
import { isCoinsEquals } from '../../../../currency/currency-service';
import './account-total-value.scss';

interface AccountTotalValueProps {
    className?: string;
    networkState: AccountNetworkState;
}

const AccountTotalValue: React.FC<AccountTotalValueProps> = ({ className, networkState }) => {
    const { loading, assets, getTokenPrice } = useAsset();

    const currentValue = useMemo(() => {
        if ((!networkState?.balances && networkState.balancesLoading) || (!assets && loading)) {
            return undefined;
        }
        return networkState?.balances?.reduce((current, balance) => current + (getTokenPrice(balance) || 0), 0);
    }, [ assets, getTokenPrice, loading, networkState?.balances, networkState.balancesLoading ]);

    const previousValue = useMemo(
        () => networkState?.balances?.reduce((current, balance) => {
            const asset = assets?.find((asset) => isCoinsEquals(asset, balance));
            return asset ? current + (balance.amount * asset.previousDayPrice) : current;
        }, 0) || 0,
        [ assets, networkState?.balances ],
    );

    return (
        <Property
            label='Total Available'
            className={classNames('account-total-value', className)}
            valueClassName='property-value'
            labelClassName='property-label'
        >
            {currentValue === undefined && networkState.balancesLoading ? <Spinner /> :
                <StatisticsChange
                    period='day'
                    currentValue={currentValue || 0}
                    previousValue={(previousValue || 0)}
                >
                    {formatPrice(currentValue || 0, undefined, undefined, 10)}&nbsp;
                </StatisticsChange>}
        </Property>
    );
};

export default AccountTotalValue;
