import { Any } from '@bufbuild/protobuf';
import {
    DepositParams,
    Proposal as GovProposal,
    ProposalStatus as GovProposalStatus,
    TallyParams,
    TallyResult, TextProposal,
    VoteOption,
} from 'cosmjs-types/cosmos/gov/v1beta1/gov';
import { ParameterChangeProposal } from 'cosmjs-types/cosmos/params/v1beta1/params';
import { CancelSoftwareUpgradeProposal, SoftwareUpgradeProposal } from 'cosmjs-types/cosmos/upgrade/v1beta1/upgrade';
import { Long, PageRequest } from 'cosmjs-types/helpers';
import { ClientUpdateProposal } from 'cosmjs-types/ibc/core/client/v1/client';
import { MsgDepositEncodeObject, MsgSubmitProposalEncodeObject, MsgVoteEncodeObject } from 'cosmjs/packages/stargate';
import { RegisterERC20Proposal } from 'cosmjs/packages/stargate/build/modules/erc20/ts-client/erc20';
import { isEmpty } from 'lodash';
import { Writer } from 'protobufjs';
import { DurationTime, getTimeOffset, timeToMilliseconds } from '../../shared/utils/date-utils';
import { convertBufferToNumber } from '../../shared/utils/encode-utils';
import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { ClientError } from '../client/client-error';
import {
    CreateDenomMetadataProposal,
    UpdateDenomMetadataProposal,
} from '../client/station-clients/dymension/generated/denommetadata/gov_denommetadata';
import { SubmitFraudProposal } from '../client/station-clients/dymension/generated/rollapp/proposal';
import {
    ReplaceStreamDistributionProposal,
    UpdateStreamDistributionProposal,
} from '../client/station-clients/dymension/generated/streamer/gov_distribution';
import { CreateStreamProposal, TerminateStreamProposal } from '../client/station-clients/dymension/generated/streamer/gov_stream';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoin, convertToCoinsAmount, getMainCurrency, getMaxDenomAmount, isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { getNetworkData, getNetworkDataItem } from '../network/network-service';
import { Network } from '../network/network-types';
import {
    GovernanceParams,
    GovProposalContent,
    Proposal,
    ProposalStatus,
    ProposalType,
    ProposalVoteOption,
    ProposalVotesSummary,
} from './governance-types';

const VISIBLE_MIN_DEPOSIT = 200;

const FETCH_ALL_PAGINATION: PageRequest =
    { reverse: false, limit: Long.MAX_VALUE, offset: Long.fromNumber(0), countTotal: false, key: new Uint8Array(0) };

const COUNT_PAGINATION: PageRequest =
    { reverse: false, limit: Long.fromNumber(1), offset: Long.fromNumber(0), countTotal: true, key: new Uint8Array(0) };

export const loadProposals = async (client: StationClient, signal: AbortSignal): Promise<Proposal[]> => {
    const network = client.getNetwork();
    let proposals: GovProposal[] = [];
    if (network.collectData) {
        proposals = await getNetworkData<GovProposal[]>(network.chainId, 'proposals', false, signal);
    }
    if (!proposals?.length) {
        const response = await client.getGovQueryClient().Proposals({
            proposalStatus: GovProposalStatus.PROPOSAL_STATUS_UNSPECIFIED,
            pagination: FETCH_ALL_PAGINATION,
            voter: '',
            depositor: '',
        }).catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', network, error);
        });
        proposals = response.proposals;
    }
    return (await Promise.all(proposals.map((proposal) => convertProposal(client, proposal)))).filter((proposal) =>
        proposal && (process.env.REACT_APP_ENV !== 'mainnet' || proposal.totalDeposit.amount >= VISIBLE_MIN_DEPOSIT)) as Proposal[];
};

export const loadGovernanceParams = async (client: StationClient, signal: AbortSignal): Promise<GovernanceParams> => {
    const network = client.getNetwork();
    let params: (DepositParams & TallyParams) | undefined;
    if (network.collectData) {
        params = await getNetworkData<DepositParams & TallyParams>(network.chainId, 'governance-params', true, signal);
    }
    if (!params || isEmpty(params)) {
        const responses = await Promise.all([
            client.getGovQueryClient().Params({ paramsType: 'tallying' }),
            client.getGovQueryClient().Params({ paramsType: 'deposit' }),
        ]).catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', network, error);
        });
        const tallyParams = responses[0].tallyParams;
        const depositParams = responses[1].depositParams;
        if (!tallyParams || !depositParams) {
            throw new Error(`Can't fetch governance params`);
        }
        params = { ...tallyParams, ...depositParams };
    }
    return {
        maxDepositPeriod: params?.maxDepositPeriod?.seconds ?
            Math.ceil(Long.fromValue(params.maxDepositPeriod.seconds).toInt() * 1000 / timeToMilliseconds({ days: 1 })) : 0,
        quorum: convertDecimalToInt(convertBufferToNumber(params?.quorum)),
        threshold: convertDecimalToInt(convertBufferToNumber(params?.threshold)),
        vetoThreshold: convertDecimalToInt(convertBufferToNumber(params?.vetoThreshold)),
        minDeposit: await convertToCoinsAmount(params?.minDeposit[0], client),
    };
};

export const loadProposal = async (client: StationClient, proposalId: number, signal: AbortSignal): Promise<Proposal | undefined> => {
    const network = client.getNetwork();
    let govProposal: GovProposal | undefined;
    if (network.collectData) {
        govProposal = await getNetworkDataItem<GovProposal>(network.chainId, 'proposals', proposalId, signal);
    }
    if (!govProposal || isEmpty(govProposal)) {
        const response = await client.getGovQueryClient().Proposal({ proposalId: Long.fromNumber(proposalId) }).catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', network, error);
        });
        govProposal = response.proposal;
    }
    const proposal = govProposal && await convertProposal(client, govProposal);
    return process.env.REACT_APP_ENV !== 'mainnet' || (proposal &&
        proposal.totalDeposit.amount >= VISIBLE_MIN_DEPOSIT) ? proposal : undefined;
};

export const loadProposalTallyResult = async (
    client: StationClient,
    proposalId: number,
    signal: AbortSignal,
): Promise<ProposalVotesSummary | undefined> => {
    let tallyResult: TallyResult | undefined;
    const network = client.getNetwork();
    if (network.collectData) {
        tallyResult = await getNetworkDataItem<TallyResult>(network.chainId, 'proposal-tally-results', proposalId, signal);
    }
    if (!tallyResult || isEmpty(tallyResult)) {
        const response = await client.getGovQueryClient().TallyResult({ proposalId: Long.fromNumber(proposalId) }).catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', network, error);
        });
        tallyResult = response.tally;
    }
    return tallyResult && convertTallyResult(tallyResult, network);
};

export const loadVotesCount = async (client: StationClient, proposalId: number): Promise<number> => {
    const response = await client.getGovQueryClient()
        .Votes({ proposalId: Long.fromNumber(proposalId), pagination: COUNT_PAGINATION })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    return response.pagination?.total.toNumber() || 0;
};

export const loadVote = async (client: StationClient, proposalId: number, voter: string): Promise<ProposalVoteOption | undefined> => {
    const response = await client.getGovQueryClient()
        .Vote({ proposalId: Long.fromNumber(proposalId), voter })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    const option = response.vote?.options.reduce((current, option) => Number(option.weight) > Number(current.weight) ? option : current);
    return option && convertVoteOption(option.option);
};

export const getTimeLeft = (proposal: Proposal): DurationTime => {
    return getTimeOffset(proposal.status === 'Deposit Period' ? proposal.depositEndTime : proposal.votingEndTime);
};

const convertProposal = async (client: StationClient, proposal: GovProposal): Promise<Proposal | undefined> => {
    let status = getProposalStatus(proposal);
    const type = getProposalType(proposal);
    const content = type && getProposalContent(proposal, type);

    if (!status || !type || !content) {
        return undefined;
    }

    const votingEndTime = !proposal.votingEndTime?.seconds ? 0 : Long.fromValue(proposal.votingEndTime.seconds).toNumber() * 1000;
    const depositEndTime = !proposal.depositEndTime?.seconds ? 0 : Long.fromValue(proposal.depositEndTime.seconds).toNumber() * 1000;
    if ((status === 'Voting Period' && votingEndTime < Date.now()) || (status === 'Deposit Period' && depositEndTime < Date.now())) {
        status = 'Unrecognized';
    }
    const network = client.getNetwork();
    const mainCurrency = getMainCurrency(network);
    const depositAmount = (await Promise.all(proposal.totalDeposit.map((coin) => convertToCoinsAmount(coin, client))))
        .filter((coins) => coins && isCoinsEquals(coins, { currency: mainCurrency, amount: 0, networkId: network.chainId }))
        .reduce((current, coins) => current + (coins?.amount || 0), 0);

    return {
        id: Long.fromValue(proposal.proposalId).toNumber(),
        status,
        type,
        title: content.title,
        description: content.description,
        content,
        submitTime: !proposal.submitTime?.seconds ? 0 : Long.fromValue(proposal.submitTime.seconds).toNumber() * 1000,
        votingStartTime: !proposal.votingStartTime?.seconds ? 0 : Long.fromValue(proposal.votingStartTime.seconds).toNumber() * 1000,
        depositEndTime,
        votingEndTime,
        totalDeposit: { currency: mainCurrency, amount: depositAmount, networkId: network.chainId },
        finalVotesSummary: proposal.finalTallyResult && status !== 'Voting Period' ?
            convertTallyResult(proposal.finalTallyResult, network) : undefined,
    };
};

const getProposalType = (proposal: GovProposal): ProposalType | undefined => {
    if (proposal.content?.typeUrl.includes('ParameterChangeProposal')) {
        return 'Parameter Change';
    }
    if (proposal.content?.typeUrl.includes('CancelSoftwareUpgradeProposal')) {
        return 'Cancel Software Upgrade';
    }
    if (proposal.content?.typeUrl.includes('CreateStreamProposal')) {
        return 'Create Stream';
    }
    if (proposal.content?.typeUrl.includes('RegisterERC20Proposal')) {
        return 'Register ERC20 Proposal';
    }
    if (proposal.content?.typeUrl.includes('TextProposal')) {
        return 'Text';
    }
    if (proposal.content?.typeUrl.includes('SubmitFraudProposal')) {
        return 'Submit Fraud Proposal';
    }
    if (proposal.content?.typeUrl.includes('CreateDenomMetadataProposal')) {
        return 'Create Denom Metadata Proposal';
    }
    if (proposal.content?.typeUrl.includes('UpdateDenomMetadataProposal')) {
        return 'Update Denom Metadata Proposal';
    }
    if (proposal.content?.typeUrl.includes('ReplaceStreamDistributionProposal')) {
        return 'Replace Stream Distribution';
    }
    if (proposal.content?.typeUrl.includes('SoftwareUpgradeProposal')) {
        return 'Software Upgrade';
    }
    if (proposal.content?.typeUrl.includes('TerminateStreamProposal')) {
        return 'Terminate Stream';
    }
    if (proposal.content?.typeUrl.includes('ClientUpdateProposal')) {
        return 'Client Update';
    }
    if (proposal.content?.typeUrl.includes('UpdateStreamDistributionProposal')) {
        return 'Update Stream Distribution';
    }
};

const getProposalContent = (proposal: GovProposal, type: ProposalType): GovProposalContent | undefined => {
    if (!proposal.content?.value) {
        return undefined;
    }
    const content = new Uint8Array(Object.values(proposal.content.value));
    switch (type) {
        case 'Cancel Software Upgrade':
            return CancelSoftwareUpgradeProposal.decode(content);
        case 'Client Update':
            return ClientUpdateProposal.decode(content);
        case 'Create Stream':
            return CreateStreamProposal.decode(content);
        case 'Parameter Change':
            return ParameterChangeProposal.decode(content);
        case 'Replace Stream Distribution':
            return ReplaceStreamDistributionProposal.decode(content);
        case 'Software Upgrade':
            return SoftwareUpgradeProposal.decode(content);
        case 'Terminate Stream':
            return TerminateStreamProposal.decode(content);
        case 'Submit Fraud Proposal':
            return SubmitFraudProposal.decode(content);
        case 'Create Denom Metadata Proposal':
            return CreateDenomMetadataProposal.decode(content);
        case 'Update Denom Metadata Proposal':
            return UpdateDenomMetadataProposal.decode(content);
        case 'Update Stream Distribution':
            return UpdateStreamDistributionProposal.decode(content);
        case 'Register ERC20 Proposal':
            return RegisterERC20Proposal.decode(content);
        case 'Text':
            return TextProposal.decode(content);
    }
};

const getGovProposalContent = (type: ProposalType, content: GovProposalContent): Any | undefined => {
    const writer = Writer.create();
    try {
        switch (type) {
            case 'Parameter Change':
                ParameterChangeProposal.encode(content as ParameterChangeProposal, writer);
                return new Any({ typeUrl: '/cosmos.params.v1beta1.ParameterChangeProposal', value: writer.finish() });

            case 'Cancel Software Upgrade':
                CancelSoftwareUpgradeProposal.encode(content as CancelSoftwareUpgradeProposal, writer);
                return new Any({ typeUrl: '/cosmos.upgrade.v1beta1.CancelSoftwareUpgradeProposal', value: writer.finish() });

            case 'Text':
                TextProposal.encode(content as TextProposal, writer);
                return new Any({ typeUrl: '/cosmos.gov.v1beta1.TextProposal', value: writer.finish() });

            case 'Create Stream':
                CreateStreamProposal.encode(content as CreateStreamProposal, writer);
                return new Any({ typeUrl: '/dymensionxyz.dymension.streamer.CreateStreamProposal', value: writer.finish() });

            case 'Client Update':
                ClientUpdateProposal.encode(content as ClientUpdateProposal, writer);
                return new Any({ typeUrl: '/ibc.core.client.v1.ClientUpdateProposal', value: writer.finish() });

            case 'Replace Stream Distribution':
                ReplaceStreamDistributionProposal.encode(content as ReplaceStreamDistributionProposal, writer);
                return new Any({ typeUrl: '/dymensionxyz.dymension.streamer.ReplaceStreamDistributionProposal', value: writer.finish() });

            case 'Software Upgrade':
                SoftwareUpgradeProposal.encode(content as SoftwareUpgradeProposal, writer);
                return new Any({ typeUrl: '/cosmos.upgrade.v1beta1.SoftwareUpgradeProposal', value: writer.finish() });

            case 'Terminate Stream':
                TerminateStreamProposal.encode(content as TerminateStreamProposal, writer);
                return new Any({ typeUrl: '/dymensionxyz.dymension.streamer.TerminateStreamProposal', value: writer.finish() });

            case 'Submit Fraud Proposal':
                SubmitFraudProposal.encode(content as SubmitFraudProposal, writer);
                return new Any({ typeUrl: '/dymensionxyz.dymension.rollapp.SubmitFraudProposal', value: writer.finish() });

            case 'Create Denom Metadata Proposal':
                CreateDenomMetadataProposal.encode(content as CreateDenomMetadataProposal, writer);
                return new Any({ typeUrl: '/dymensionxyz.dymension.denommetadata.CreateDenomMetadataProposal', value: writer.finish() });

            case 'Update Denom Metadata Proposal':
                UpdateDenomMetadataProposal.encode(content as UpdateDenomMetadataProposal, writer);
                return new Any({ typeUrl: '/dymensionxyz.dymension.denommetadata.UpdateDenomMetadataProposal', value: writer.finish() });

            case 'Update Stream Distribution':
                UpdateStreamDistributionProposal.encode(content as UpdateStreamDistributionProposal, writer);
                return new Any({ typeUrl: '/dymensionxyz.dymension.streamer.UpdateStreamDistributionProposal', value: writer.finish() });
        }
    } catch {
        return undefined;
    }
};

const getProposalStatus = (proposal: GovProposal): ProposalStatus | undefined => {
    switch (proposal.status) {
        case GovProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD:
            return 'Deposit Period';
        case GovProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD:
            return 'Voting Period';
        case GovProposalStatus.PROPOSAL_STATUS_PASSED:
            return 'Passed';
        case GovProposalStatus.PROPOSAL_STATUS_REJECTED:
            return 'Rejected';
        case GovProposalStatus.PROPOSAL_STATUS_FAILED:
            return 'Failed';
        default:
            return 'Unrecognized';
    }
};

export const createVoteMessage = (proposalId: number, voter: string, voteOption?: ProposalVoteOption): MsgVoteEncodeObject => {
    let option: VoteOption;
    switch (voteOption) {
        case 'YES':
            option = VoteOption.VOTE_OPTION_YES;
            break;
        case 'NO':
            option = VoteOption.VOTE_OPTION_NO;
            break;
        case 'Veto':
            option = VoteOption.VOTE_OPTION_NO_WITH_VETO;
            break;
        case 'Abstain':
            option = VoteOption.VOTE_OPTION_ABSTAIN;
            break;
        default:
            option = VoteOption.VOTE_OPTION_ABSTAIN;
    }
    return {
        typeUrl: '/cosmos.gov.v1beta1.MsgVote',
        value: { proposalId: Long.fromNumber(proposalId), voter, option },
    };
};

export const createDepositMessage = (
    proposalId: number,
    depositor: string,
    coins: CoinsAmount,
    baseAmountWithoutFee?: bigint,
): MsgDepositEncodeObject => {
    const amount = [ convertToCoin(coins) ];
    if (baseAmountWithoutFee !== undefined && baseAmountWithoutFee < BigInt(amount[0].amount)) {
        amount[0].amount = baseAmountWithoutFee.toString();
    }
    return {
        typeUrl: '/cosmos.gov.v1beta1.MsgDeposit',
        value: { proposalId: Long.fromNumber(proposalId), depositor, amount },
    };
};

export const createProposalMessage = (
    type: ProposalType,
    proposer: string,
    deposit: CoinsAmount,
    content: GovProposalContent,
): MsgSubmitProposalEncodeObject => {
    const initialDeposit = [ convertToCoin(deposit) ];
    return {
        typeUrl: '/cosmos.gov.v1beta1.MsgSubmitProposal',
        value: { proposer, initialDeposit, content: getGovProposalContent(type, content) },
    };
};

const convertVoteOption = (option: VoteOption): ProposalVoteOption | undefined => {
    switch (option) {
        case VoteOption.VOTE_OPTION_YES:
            return 'YES';
        case VoteOption.VOTE_OPTION_NO:
            return 'NO';
        case VoteOption.VOTE_OPTION_NO_WITH_VETO:
            return 'Veto';
        case VoteOption.VOTE_OPTION_ABSTAIN:
            return 'Abstain';
    }
};

const convertTallyResult = (tally: TallyResult, network: Network): ProposalVotesSummary | undefined => {
    const mainCurrency = getMainCurrency(network);
    const yesAmount = getMaxDenomAmount(Number(tally.yes) || 0, mainCurrency);
    const noAmount = getMaxDenomAmount(Number(tally.no) || 0, mainCurrency);
    const abstainAmount = getMaxDenomAmount(Number(tally.abstain) || 0, mainCurrency);
    const noWithVetoAmount = getMaxDenomAmount(Number(tally.noWithVeto) || 0, mainCurrency);
    const totalAmount = (yesAmount + noAmount + abstainAmount + noWithVetoAmount) || 1;

    return {
        YES: { amount: yesAmount, part: yesAmount / totalAmount },
        NO: { amount: noAmount, part: noAmount / totalAmount },
        Abstain: { amount: abstainAmount, part: abstainAmount / totalAmount },
        Veto: { amount: noWithVetoAmount, part: noWithVetoAmount / totalAmount },
    };
};
