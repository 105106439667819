import React, { useMemo } from 'react';
import Spinner from '../../../shared/components/spinner/spinner';
import { useAsset } from '../../asset/asset-context';
import AssetDashboard from '../../asset/asset-dashboard/asset-dashboard';
import { useNetworkDashboard } from '../network-dashboard-context';
import './network-asset.scss';

export const NetworkAsset: React.FC = () => {
    const { mainAssetMap, loading } = useAsset();
    const { network } = useNetworkDashboard();

    const asset = useMemo(() => mainAssetMap?.[network.chainId], [ mainAssetMap, network.chainId ]);

    if (!asset && loading) {
        return <Spinner size='large' className='asset-dashboard-loader' />;
    }
    if (!asset) {
        return <h5 className='not-found-message'>Asset not found</h5>;
    }
    return <AssetDashboard asset={asset} />;
};

export default NetworkAsset;
