import React from 'react';
import HubBurnProperty from './properties/hub-burn-property';
import HubPriceProperty from './properties/hub-price-property';
import RollappsCountProperty from './properties/rollapps-count-property';
import TotalIbcTransfersProperty from './properties/total-ibc-transfers-property';
import TotalMarketCapProperty from './properties/total-market-cap-property';
import TotalVolumeProperty from './properties/total-volume-property';
import './rollapps-statistics.scss';

const RollappsStatistics: React.FC = () => {
    return (
        <div className='rollapps-statistics-container'>
            <div className='rollapps-statistics-carousel-container'>
                <div className='rollapps-statistics-carousel'>
                    <RollappsCountProperty />
                    <HubPriceProperty />
                    <TotalMarketCapProperty />
                    <TotalVolumeProperty />
                    <HubBurnProperty />
                    <TotalIbcTransfersProperty />
                </div>
            </div>
        </div>
    );
};

export default RollappsStatistics;
