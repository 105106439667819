import React, { ReactElement, useCallback, useEffect } from 'react';
import Button from '../../../shared/components/button/button';
import Dialog, { DialogContent, DialogTitle } from '../../../shared/components/dialog/dialog';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import Spinner from '../../../shared/components/spinner/spinner';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useClient } from '../../client/client-context';
import { isCoinsEquals } from '../../currency/currency-service';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { useAmm } from '../amm-context';
import { useCreatePool } from './use-create-pool';
import './create-pool-dialog.scss';

export interface CreatePoolDialogProps {
    onRequestClose?: () => void;
}

const CreatePoolDialog: React.FC<CreatePoolDialogProps> = ({ onRequestClose }) => {
    const { showErrorMessage } = useSnackbar();
    const { clientStateMap } = useClient();
    const { networkWalletMap } = useWallet();
    const networkState = useHubNetworkState();
    const { ammState } = useAmm();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        txState,
        asset1AvailableBalances,
        asset2AvailableBalances,
        poolCreationFee,
        setAsset1Coins,
        setAsset2Coins,
        broadcast,
    } = useCreatePool();

    const clientState = networkState.network && clientStateMap[networkState.network.chainId];
    const networkWallet = networkState.network && networkWalletMap[networkState.network.chainId];

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            return { content: 'New pool successfully created!' };
        }
    }, []);

    const onSubmitClick = useCallback(() => {
        if (!ammState.pools || !asset1AmountTxState.coins || !asset2AmountTxState.coins) {
            showErrorMessage('Missing pools or assets data');
            return;
        }
        if (ammState.pools.some((pool) =>
            pool.assets.some((asset) => asset1AmountTxState.coins && isCoinsEquals(asset, asset1AmountTxState.coins)) &&
            pool.assets.some((asset) => asset2AmountTxState.coins && isCoinsEquals(asset, asset2AmountTxState.coins)))
        ) {
            showErrorMessage('Pool with the specified assets already exists, please choose different assets.');
            return;
        }
        broadcast();
    }, [ asset1AmountTxState.coins, asset2AmountTxState.coins, broadcast, ammState.pools, showErrorMessage ]);

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            onRequestClose?.();
        }
    }, [ onRequestClose, txState.response?.deliveryTxCode ]);

    const confirmButtonDisabled = Boolean(
        txState.broadcasting ||
        txState.feeLoading ||
        !asset1AmountTxState.coins?.amount ||
        !asset2AmountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    const renderDialogContent = (): ReactElement => {
        if (ammState.paramsLoading) {
            return <Spinner className='content-loading' />;
        }
        return <>
            <label className='token-label'>Token 1</label>
            <AmountTx
                txState={{}}
                controlSize='large'
                amountTxState={asset1AmountTxState}
                networkState={networkState}
                availableBalances={asset1AvailableBalances}
                disabledBalances={asset2AmountTxState.coins ? [ asset2AmountTxState.coins ] : []}
                onCoinsChange={setAsset1Coins}
                displayFee={false}
            />

            <label className='token-label'>Token 2</label>
            <AmountTx
                txState={txState}
                controlSize='large'
                amountTxState={asset2AmountTxState}
                disabledBalances={asset1AmountTxState.coins ? [ asset1AmountTxState.coins ] : []}
                getTxResponseMessage={getTxResponseMessage}
                availableBalances={asset2AvailableBalances}
                networkState={networkState}
                amountDisabled={!asset1AmountTxState.coins}
                onCoinsChange={setAsset2Coins}
                extraFees={[ { label: 'Pool creation fee', value: poolCreationFee, loading: ammState.paramsLoading } ]}
                submitButtonContainer={(
                    <Button
                        size='x-large'
                        className='submit-button'
                        loading={txState.broadcasting || txState.feeLoading}
                        disabled={confirmButtonDisabled}
                        onClick={onSubmitClick}
                    >
                        Create Pool
                    </Button>
                )}
            />
        </>;
    };

    return (
        <Dialog closable className='create-pool-dialog' onRequestClose={onRequestClose}>
            <DialogTitle>Create New Pool</DialogTitle>
            <DialogContent className='dialog-content'>
                {renderDialogContent()}
            </DialogContent>
        </Dialog>
    );
};

export default CreatePoolDialog;
