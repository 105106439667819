import { MsgAddAppEncodeObject, MsgUpdateAppEncodeObject, MsgRemoveAppEncodeObject } from 'cosmjs/packages/stargate';
import { ref, StorageReference } from 'firebase/storage';
import { storage } from '../../../../../../../shared/utils/firebase-utils';
import { App } from '../../../../../../client/station-clients/dymension/generated/rollapp/app';
import { Network } from '../../../../../../network/network-types';

export const createEmptyRollappApp = (rollapp: Network): App => {
    const order = 1 + (rollapp.apps?.reduce((maxOrder, app) => Math.max(maxOrder, app.order), 0) || 0);
    return { rollappId: rollapp.chainId, order } as App;
};

export const createAddAppMessage = (app: App, creator: string, logoUrl?: string): MsgAddAppEncodeObject => {
    return { typeUrl: '/dymensionxyz.dymension.rollapp.MsgAddApp', value: { ...app, image: logoUrl, creator } };
};

export const createUpdateAppMessage = (app: App, creator: string, logoUrl?: string): MsgUpdateAppEncodeObject => {
    return { typeUrl: '/dymensionxyz.dymension.rollapp.MsgUpdateApp', value: { ...app, creator, image: logoUrl || app.imageUrl } };
};

export const createRemoveAppMessage = (app: App, creator: string): MsgRemoveAppEncodeObject => {
    return { typeUrl: '/dymensionxyz.dymension.rollapp.MsgRemoveApp', value: { ...app, creator } };
};

export const getAppLogoFileRef = (fileName: string): StorageReference => {
    fileName = decodeURIComponent(fileName);
    const result = /rollapp-app-logos\/[^\\?]+\?/.exec(fileName);
    fileName = result ? result[0].slice(0, result[0].length - 1) : `rollapp-app-logos/${fileName}`;
    return ref(storage, fileName);
};
