import React, { ReactNode, useMemo } from 'react';

export type TextHighlight = { regex: RegExp, replace: (word: string, key: string) => ReactNode };

interface HighlightTextProps {
    children: string;
    highlights: TextHighlight[];
}

const HighlightText: React.FC<HighlightTextProps> = ({ children, highlights }) => {
    const highlightedText = useMemo(() => {
        return highlights.reduce<(string | ReactNode)[]>((currentParts, highlight, highlightIndex) =>
            currentParts.reduce<(string | ReactNode)[]>((current, part, partIndex) => {
                if (typeof part !== 'string') {
                    return current;
                }
                return {
                    ...current,
                    ...part.split(highlight.regex).map((subPart, subPartIndex) => {
                        const key = `${highlightIndex}-${partIndex}-${subPartIndex}`;
                        return highlight.regex.test(subPart) ? highlight.replace(subPart, key) : subPart;
                    }),
                };
            }, []), [ children ]);
    }, [ highlights, children ]);

    return <>{Object.values(highlightedText)}</>;
};

export default HighlightText;
