import { useCallback, useEffect, useReducer, useState } from 'react';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import { useCancelablePromise } from '../../../shared/hooks/use-cancelable-promise';
import { getERC20Tokens } from '../../account/account-service';
import { useClient } from '../../client/client-context';
import { getFixedDenom, isCoinsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { Network } from '../../network/network-types';
import { useWallet } from '../../wallet/wallet-context';
import { createWallet } from '../../wallet/wallet-service';
import { WalletType } from '../../wallet/wallet-types';
import { importTokenReducer, ImportTokenState } from './import-token-state';

interface UseImportTokenValue {
    importTokenState: ImportTokenState;
    selectWallet: (walletType: WalletType) => void;
    selectNetwork: (networkId: string) => void;
    importToken: () => void;
}

export const useImportToken = (token: CoinsAmount, walletType?: WalletType, network?: Network): UseImportTokenValue => {
    const { showErrorMessage, showSuccessMessage } = useSnackbar();
    const { hubNetwork, vfcMap, getNetwork, toHubCoins } = useNetwork();
    const { installedWallets } = useWallet();
    const { clientStateMap, connectClient } = useClient();
    const [ importTokenState, importTokenStateDispatch ] =
        useReducer(importTokenReducer, { walletType: walletType || 'MetaMask', network, coinsToImportLoading: Boolean(network) });
    const [ toImport, setToImport ] = useState<boolean>();
    const cancelAndSetERC20PairsPromise = useCancelablePromise<CoinsAmount[]>();
    const cancelAndSetImportPromise = useCancelablePromise<void>();

    const selectWallet = useCallback((walletType: WalletType) => {
        importTokenStateDispatch({ type: 'set-wallet', payload: walletType });
        importTokenStateDispatch({ type: 'set-network', payload: undefined });
    }, []);

    const selectNetwork = useCallback((networkId: string) => {
        importTokenStateDispatch({ type: 'set-network', payload: getNetwork(networkId) });
        importTokenStateDispatch({ type: 'set-coins-to-import-loading' });
    }, [ getNetwork ]);

    useEffect(() => {
        if (!toImport || !importTokenState.network || !importTokenState.coinsToImport) {
            return;
        }
        importTokenStateDispatch({ type: 'set-importing' });
        if (!installedWallets[importTokenState.walletType]) {
            importTokenStateDispatch({ type: 'set-importing', payload: false });
            showErrorMessage(`${importTokenState.walletType} wallet not installed`);
            return;
        }
        setToImport(false);
        const coinsOriginalNetwork = getNetwork(importTokenState.coinsToImport.networkId) as Network;
        cancelAndSetImportPromise(createWallet(importTokenState.walletType)
            .then((wallet) => importTokenState.coinsToImport &&
                wallet?.suggestToken?.(importTokenState.coinsToImport, coinsOriginalNetwork, importTokenState.network)))
            .then(() => showSuccessMessage(`Token successfully imported!`))
            .catch((error) => {
                console.error(error);
                showErrorMessage(`Can't import token, please try again later`);
            })
            .finally(() => importTokenStateDispatch({ type: 'set-importing', payload: false }));
    }, [
        cancelAndSetImportPromise,
        getNetwork,
        hubNetwork,
        importTokenState.coinsToImport,
        importTokenState.network,
        importTokenState.walletType,
        installedWallets,
        showErrorMessage,
        showSuccessMessage,
        toImport,
    ]);

    const importToken = useCallback(() => setToImport(true), []);

    useEffect(() => {
        if (importTokenState.walletType && importTokenState.walletType !== 'MetaMask') {
            importTokenStateDispatch({ type: 'set-network', payload: hubNetwork });
            importTokenStateDispatch({ type: 'set-coins-to-import', payload: token });
            return;
        }
        if (!importTokenState.network || !importTokenState.walletType || importTokenState.network.chainId === token.networkId) {
            cancelAndSetERC20PairsPromise();
            importTokenStateDispatch({ type: 'set-coins-to-import-loading', payload: false });
            return;
        }
        const clientState = clientStateMap[importTokenState.network.chainId];
        if (clientState && !clientState.client && !clientState.connecting) {
            importTokenStateDispatch({ type: 'set-coins-to-import-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            const network = getNetwork(importTokenState.network.chainId);
            if (network) {
                connectClient(network);
            }
            return;
        }
        if (importTokenState.network.type === 'Hub') {
            const denom = getFixedDenom(token, false)
            const vfcContract = denom && vfcMap?.[denom]?.contractAddress;
            if (!vfcContract) {
                showErrorMessage(`Can't fetch erc20 contract address, please try again later`);
                importTokenStateDispatch({ type: 'set-coins-to-import', payload: undefined });
            } else {
                importTokenStateDispatch({
                    type: 'set-coins-to-import',
                    payload: { ...token, erc20Address: vfcContract },
                });
            }
            return;
        }
        importTokenStateDispatch({ type: 'set-coins-to-import-loading', payload: Boolean(network) });
        cancelAndSetERC20PairsPromise(getERC20Tokens(clientState.client))
            .then((coins) => importTokenStateDispatch({
                type: 'set-coins-to-import',
                payload: coins.find((currentToken) => isCoinsEquals(token, toHubCoins(currentToken))),
            }))
            .catch((error) => {
                console.error(error);
                showErrorMessage(`Can't fetch erc20 contract address, please try again later`);
                importTokenStateDispatch({ type: 'set-coins-to-import', payload: undefined });
            });
    }, [
        cancelAndSetERC20PairsPromise,
        clientStateMap,
        connectClient,
        getNetwork,
        hubNetwork,
        importTokenState.network,
        importTokenState.walletType,
        network,
        showErrorMessage,
        toHubCoins,
        token,
        vfcMap,
    ]);

    return { importTokenState, selectWallet, selectNetwork, importToken };
};
