import { SKIP_API_URL, SkipClient } from '@skip-go/client';
import { setDoc, doc, collection, query, where, getDocs, or, runTransaction } from 'firebase/firestore';
import { isUndefined, omitBy } from 'lodash';
import { firestore } from '../../../../shared/utils/firebase-utils';
import { BRIDGE_TRANSFERS_PATH, BridgeTransfer } from './bridge-hisotry-types';

export const saveHistoryTransfer = async (transfer: BridgeTransfer): Promise<void> => {
    const key = transfer.ibcHash || transfer.hash;
    if (!key) {
        console.error('Missing ibc ot cctp hash');
        return;
    }
    const transferDoc = doc(firestore, `${BRIDGE_TRANSFERS_PATH}/${key}`);
    await setDoc(transferDoc, omitBy(transfer, isUndefined));
};

export const trackTransfer = (transfer: BridgeTransfer) => {
    if (!transfer.hash) {
        return;
    }
    new SkipClient({ apiURL: SKIP_API_URL })
        .waitForTransaction({ chainID: transfer.intermediateId || transfer.sourceId, txHash: transfer.hash })
        .then((response) => transfer.status = response.error || response.state !== 'STATE_COMPLETED_SUCCESS' ? 'Failed' : 'Success')
        .catch(() => transfer.status = 'Failed')
        .finally(() => saveHistoryTransfer(transfer));
};

export const loadHistoryTransfers = async (address: string): Promise<BridgeTransfer[]> => {
    const transfersRef = collection(firestore, BRIDGE_TRANSFERS_PATH);
    const transfersQuery = query(transfersRef, or(where('sourceAddress', '==', address), where('destinationAddress', '==', address)));
    const newFailedTransferIds: string[] = [];
    const transfers = await getDocs(transfersQuery).then((snapshot) => snapshot.docs.map((doc) => {
        const transfer = doc.data() as BridgeTransfer;
        if (transfer.status === 'IbcTransferring' || (transfer.status === 'BridgeTransferring' && !transfer.hash)) {
            transfer.status = 'Failed';
            newFailedTransferIds.push(doc.id);
        } else if (transfer.status === 'BridgeTransferring') {
            trackTransfer(transfer);
        }
        return transfer;
    }));
    if (newFailedTransferIds) {
        runTransaction(firestore, async (transaction) => newFailedTransferIds.forEach((transferId) => {
            const transferRef = doc(firestore, BRIDGE_TRANSFERS_PATH, transferId);
            transaction.update(transferRef, { status: 'Failed' });
        })).then();
    }
    return transfers;
};
