import React from 'react';
import StatisticsChange from '../../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { useAsset } from '../../../../asset/asset-context';

const TotalVolumeProperty: React.FC = () => {
    const { hubAsset } = useAsset();

    return (
        <div className='statistics-property'>
            <span className='property-label'>Volume (24h):</span>
            <StatisticsChange
                period='day'
                size='small'
                currentValue={hubAsset?.volume || 0}
                previousValue={hubAsset?.previousDayVolume || 0}
            >
                {formatPrice(hubAsset?.volume || 0, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </StatisticsChange>
        </div>
    );
};

export default TotalVolumeProperty;
