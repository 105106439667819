import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { BridgeTransfer } from './bridge-hisotry-types';
import { loadHistoryTransfers, saveHistoryTransfer } from './bridge-history-service';

interface BridgeHistoryContextValue {
    transfers?: BridgeTransfer[];
    loading: boolean;
    getTransfer: (ibcHash?: string, hash?: string) => BridgeTransfer | undefined;
    saveTransfer: (data: BridgeTransfer) => Promise<void>;
}

export const BridgeHistoryContext = createContext<BridgeHistoryContextValue>({} as BridgeHistoryContextValue);

export const useBridgeHistory = (): BridgeHistoryContextValue => useContext(BridgeHistoryContext);

export const BridgeHistoryContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const hubNetworkState = useHubNetworkState();
    const [ transfers, setTransfers ] = useState<BridgeTransfer[]>();
    const [ loading, setLoading ] = useState(false);

    const getTransfer = useCallback((ibcHash?: string, hash?: string) => transfers?.find((transfer) =>
        (ibcHash && transfer.ibcHash === ibcHash) || (hash && transfer.hash === hash)), [ transfers ]);

    const saveTransfer = useCallback(async (transferData: BridgeTransfer): Promise<void> => {
        if (!transferData.ibcHash && !transferData.hash) {
            console.warn('Missing ibc ot cctp hash');
            return;
        }
        setTransfers((transfers) => {
            if (!transfers) {
                console.warn('Transfers still not loaded');
                return;
            }
            const transferIndex = transfers.findIndex((transfer) =>
                (transferData.ibcHash && transfer.ibcHash === transferData.ibcHash) ||
                (transferData.hash && transfer.hash === transferData.hash));
            if (transferIndex >= 0) {
                transfers[transferIndex] = { ...transfers[transferIndex], ...transferData };
            } else {
                transfers.unshift(transferData);
            }
            return transfers;
        });
        await saveHistoryTransfer(transferData);
    }, []);

    useEffect(() => {
        if (!hubNetworkState.address) {
            return;
        }
        setLoading(true);
        loadHistoryTransfers(hubNetworkState.address)
            .then((transfers) => setTransfers(transfers.sort((transfer1, transfer2) => transfer2.time - transfer1.time)))
            .finally(() => setLoading(false));
    }, [ hubNetworkState.address ]);

    return (
        <BridgeHistoryContext.Provider value={{ transfers, loading, getTransfer, saveTransfer }}>
            {children}
        </BridgeHistoryContext.Provider>
    );
};
