import classNames from 'classnames';
import React from 'react';
import Button from '../../../shared/components/button/button';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useAmm } from '../../amm/amm-context';
import { isCoinsEquals } from '../../currency/currency-service';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { ReactComponent as SwapIcon } from '../../../assets/icons/swap-vert.svg';
import ConfirmTradeButton from '../confirm-trade-button/confirm-trade-button';
import SlippageAlerts from '../slippage-alerts/slippage-alerts';
import TradeDetails from '../trade-details/trade-details';
import { useTrade } from '../trade-context';
import './swap.scss';

interface TradeProps {
    className?: string;
    title?: string;
    actionLabel?: string;
}

const Swap: React.FC<TradeProps> = ({ className, title, actionLabel }) => {
    const networkState = useHubNetworkState();
    const { commonTokens } = useAmm();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        txState,
        loading,
        availableBalances,
        switchTokens,
        updateAsset1Coins,
        updateAsset2Coins,
        getTxResponseMessage,
    } = useTrade();

    return <>
        <section className={classNames('section trade-section', className)}>
            <h3 className='trade-title'>{title || 'Trade'}</h3>

            <label className='token-label'>From token</label>
            <AmountTx
                txState={{}}
                showPrice='inside'
                controlSize='large'
                commonTokens={commonTokens}
                amountTxState={asset1AmountTxState}
                networkState={networkState}
                availableBalances={availableBalances}
                disabledBalances={asset2AmountTxState.coins ? [ asset2AmountTxState.coins ] : []}
                onCoinsChange={(coins) => {
                    if (asset2AmountTxState.coins && isCoinsEquals(coins, asset2AmountTxState.coins)) {
                        switchTokens?.();
                    } else {
                        updateAsset1Coins(coins);
                    }
                }}
                displayFee={false}
                loading={loading || (!networkState.balances && networkState.balancesLoading) || (!txState.fee && txState.feeLoading)}
            />

            <Button
                className='swap-button'
                buttonType='icon'
                size='x-large'
                disabled={!asset1AmountTxState.coins || !asset2AmountTxState.coins}
                onClick={switchTokens}
            >
                <SwapIcon />
            </Button>

            <label className='token-label'>To token</label>
            <AmountTx
                txState={txState}
                controlSize='large'
                showPrice='inside'
                hideMaxValueAction
                commonTokens={commonTokens}
                amountTxState={asset2AmountTxState}
                disabledBalances={asset1AmountTxState.coins ? [ asset1AmountTxState.coins ] : []}
                getTxResponseMessage={getTxResponseMessage}
                availableBalances={availableBalances}
                networkState={networkState}
                loading={loading}
                displayFee={false}
                onCoinsChange={(coins) => {
                    if (asset1AmountTxState.coins && isCoinsEquals(coins, asset1AmountTxState.coins)) {
                        switchTokens?.();
                    } else {
                        updateAsset2Coins(coins);
                    }
                }}
                submitButtonContainer={<ConfirmTradeButton className='' size='x-large'>{actionLabel || 'Trade'}</ConfirmTradeButton>}
            />

            <SlippageAlerts />

            <TradeDetails persistedExpanded expanded />
        </section>
    </>;
};

export default Swap;
