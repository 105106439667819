import { WalletType } from './wallet-types';
import { PortalError } from '../error/portal-error';
import { Network } from '../network/network-types';

export type WalletErrorCode =
    'WALLET_NOT_CONNECTED'
    | 'INSTALL_WALLET'
    | 'UPGRADE_WALLET'
    | 'FAILED_INTEGRATE_CHAIN'
    | 'FAILED_INIT_WALLET'
    | 'KEY_NOT_FOUND'
    | 'ACCOUNTS_ALREADY_REQUESTED'
    | 'SWITCH_NETWORK'
    | 'REQUEST_REJECTED'
    | 'UNSUPPORTED_WALLET'
    | 'UNSUPPORTED_NETWORK'
    | 'UNSUPPORTED_MESSAGE'
    | 'NO_OFFLINE_SIGNER'
    | 'PHANTOM_SETTINGS_REQUIRED'

export class WalletError extends PortalError<WalletErrorCode> {
    public walletType?: WalletType;

    constructor(code: WalletErrorCode, walletType?: WalletType, network?: Network, originalError?: any, printError?: boolean) {
        super(code, network, originalError, printError);
        this.walletType = walletType;
    }
}

