import { Decimal } from 'cosmjs/packages/math';
import React, { useMemo } from 'react';
import { Area, AreaChart, CartesianGrid, ReferenceLine, XAxis, YAxis } from 'recharts';
import { Point } from 'recharts/types/shape/Curve';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { Asset } from '../../asset/asset-types';
import { DEFAULT_DENOM_EXPONENT } from '../../rollapp/manage-rollapps-page/create-rollapp-page/types';
import { useIRO } from '../iro-context';
import { calculateBondingCurvePoints, convertToBondingCurve, getCurveType } from '../iro-service';
import './simple-bonding-curve.scss';

interface SimpleBondingCurveProps {
    asset: Asset;
}

const SimpleBondingCurve: React.FC<SimpleBondingCurveProps> = ({ asset }) => {
    const { getIroPlan } = useIRO();

    const iroPlan = useMemo(() => getIroPlan(asset.network.chainId), [ getIroPlan, asset.network.chainId ]);

    const curve = useMemo(() => iroPlan?.bondingCurve && convertToBondingCurve(iroPlan?.bondingCurve), [ iroPlan?.bondingCurve ]);

    const curveType = useMemo(() => curve && getCurveType(curve), [ curve ]);

    const points = useMemo(() => {
        if (!curve || !iroPlan?.totalAllocation) {
            return [];
        }
        const allocation = Decimal.fromAtomics(iroPlan.totalAllocation.amount, DEFAULT_DENOM_EXPONENT).toFloatApproximation();
        return calculateBondingCurvePoints(curve, allocation, 1001);
    }, [ curve, iroPlan?.totalAllocation ]);

    const currentSold = useMemo((): Point | undefined => {
        if (!iroPlan?.soldAmt) {
            return undefined;
        }
        const soldAmount = Decimal.fromAtomics(iroPlan.soldAmt, DEFAULT_DENOM_EXPONENT).toFloatApproximation();
        return points.reduce((current, point) => Math.abs(point.x - soldAmount) < Math.abs(current.x - soldAmount) ? point : current);
    }, [ iroPlan?.soldAmt, points ]);

    const yAxisDomain = useMemo(
        () => !points.length ? undefined : [ 0, points[points.length - 1].y * (curveType === 'Fixed' ? 2 : 1) ],
        [ curveType, points ],
    );

    return (
        <AreaChart data={points} width={160} height={56}>
            <XAxis dataKey='x' height={0} />
            <YAxis domain={yAxisDomain} width={0} />
            <CartesianGrid width={0} />

            {currentSold && (
                <ReferenceLine
                    x={currentSold.x}
                    stroke={getCssVariableValue('--green')}
                    strokeWidth={2}
                    strokeDasharray='3 3'
                />
            )}

            <Area
                strokeWidth={2}
                stroke={getCssVariableValue('--green')}
                type='monotone'
                dataKey='y'
                fill={`rgba(${getCssVariableValue('--green-rgb')},0.4)`}
            />
        </AreaChart>
    );

};

export default SimpleBondingCurve;
