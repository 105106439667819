import React, { useEffect } from 'react';
import Input from '../../../shared/components/form-controls/input/input';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import AssetList, { AssetListHeader } from '../asset-list/asset-list';
import { AssetListContextProvider, useAssetList } from '../asset-list/asset-list-context';
import './assets-page.scss';

const AssetsPage: React.FC = () => {
    const { searchText, loadMore, setSearchText } = useAssetList();
    const { canLoadMore } = useScrollPosition();

    useEffect(() => {
        if (canLoadMore) {
            loadMore();
        }
    }, [ loadMore, canLoadMore ]);

    return (
        <div className='page assets-page'>
            <PathNav>
                <PathNavItem label='Liquidity' />
                <PathNavItem label='Assets' />
            </PathNav>

            <AssetListHeader className='asset-list-header'>
                All Assets
                <span className='space' />
                <Input
                    controlSize='medium'
                    className='assets-search'
                    value={searchText}
                    type='search'
                    placeholder='Search assets...'
                    onValueChange={setSearchText}
                />
            </AssetListHeader>
            <AssetList />
        </div>
    );
};

const AssetsPageWithContext = () =>
    <AssetListContextProvider><AssetsPage /></AssetListContextProvider>;

export default AssetsPageWithContext;


