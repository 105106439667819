import { Decimal } from 'cosmjs/packages/math';
import { MsgRevokeSponsorshipVoteEncodeObject, MsgSponsorshipVoteEncodeObject } from 'cosmjs/packages/stargate';
import { Pool } from '../amm/types';
import { ClientError } from '../client/client-error';
import { Gauge } from '../client/station-clients/dymension/generated/incentives/gauge';
import { StationClient } from '../client/station-clients/station-client';
import { getNetworkData } from '../network/network-service';
import { Distribution, Params, Vote } from '../client/station-clients/dymension/generated/sponsorship/sponsorship';
import { Network } from '../network/network-types';
import { SponsorshipRecord } from './sponsorship-types';

export const loadSponsorshipDistribution = async (network: Network, signal: AbortSignal): Promise<Distribution> => {
    const distribution = await getNetworkData<Distribution>(network.chainId, 'sponsorship-distribution', true, signal);
    if (!distribution) {
        throw new ClientError('FETCH_DATA_FAILED', network, new Error(`Can't fetch sponsorship distribution`));
    }
    return distribution;
};

export const loadSponsorshipParams = async (network: Network, signal: AbortSignal): Promise<Params> => {
    const params = await getNetworkData<Params>(network.chainId, 'sponsorship-params', true, signal);
    if (!params) {
        throw new ClientError('FETCH_DATA_FAILED', network, new Error(`Can't fetch sponsorship params`));
    }
    return params;
};

export const loadSponsorshipVote = async (client: StationClient, voter: string): Promise<Vote> => {
    const voterResponse = await client.getSponsorshipQueryClient().Vote({ voter }).catch((error) => {
        console.error(`Can't fetch sponsorship vote`, error);
        return undefined;
    });
    return voterResponse?.vote || { votingPower: '0', weights: [] };
};

export const createSponsorshipRecords = (
    totalVotingPower: number,
    weights: { gaugeId: number, weight: number, power: number }[],
    gauges: Gauge[],
    networks: Network[],
    pools: Pool[],
): SponsorshipRecord[] => {
    const records = weights.map<SponsorshipRecord | undefined>(({ gaugeId, weight, power }) => {
        const gauge = gauges.find((gauge) => gauge.id === gaugeId);
        if (!gauge) {
            return undefined;
        }
        if (gauge.rollapp?.rollappId) {
            const rollapp = networks.find((network) => network.type === 'RollApp' && network.chainId === gauge.rollapp?.rollappId);
            return rollapp ? { gaugeId, weight, rollapp, power: power, type: 'RollApp' } : undefined;
        }
        if (gauge.asset?.denom) {
            const pool = pools.find((pool) => pool.lpTokenDenom === gauge.asset?.denom);
            return pool ? { gaugeId, weight, pool, power: power, type: 'Pool' } : undefined;
        }
        return undefined;
    }).filter(Boolean) as SponsorshipRecord[];
    return records.sort((record1, record2) => record2.weight - record1.weight);
};

export const createSponsorshipVoteMessage = (voter: string, records: SponsorshipRecord[]): MsgSponsorshipVoteEncodeObject => {
    return {
        typeUrl: '/dymensionxyz.dymension.sponsorship.MsgVote',
        value: {
            voter,
            weights: records.map((record) => ({
                gaugeId: record.gaugeId,
                weight: Decimal.fromUserInput(record.weight.toString(), 18).atomics,
            })),
        },
    };
};

export const createSponsorshipRevokeMessage = (voter: string): MsgRevokeSponsorshipVoteEncodeObject => {
    return {
        typeUrl: '/dymensionxyz.dymension.sponsorship.MsgRevokeVote',
        value: { voter },
    };
};

