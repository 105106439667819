import { Decimal } from 'cosmjs/packages/math';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { useHubNetworkState } from '../account/hub-network-state-context';
import { useAmm } from '../amm/amm-context';
import { useClient } from '../client/client-context';
import { Distribution, Params, Vote } from '../client/station-clients/dymension/generated/sponsorship/sponsorship';
import { getMaxDenomAmount, getStakingCurrency } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { useIncentives } from '../incentives/incentives-context';
import { epochToMilliseconds } from '../incentives/incentives.service';
import { useNetwork } from '../network/network-context';
import { loadDelegations } from '../staking/staking-service';
import { Delegation } from '../staking/staking-types';
import { useWallet } from '../wallet/wallet-context';
import {
    createSponsorshipRecords,
    loadSponsorshipDistribution,
    loadSponsorshipParams,
    loadSponsorshipVote,
} from './sponsorship-service';
import { SponsorshipParams, SponsorshipRecord, SponsorshipWeight } from './sponsorship-types';

interface SponsorshipContextValue {
    loading: boolean;
    paramsLoading: boolean;
    voteLoading: boolean;
    params?: SponsorshipParams;
    distribution?: SponsorshipRecord[];
    vote?: SponsorshipRecord[];
    stakedAmount?: CoinsAmount;
    sponsorshipPoolAprs: { [poolId: number]: number };
    stakingLoading: boolean;
    totalStreamed: number;
    dailyStreamed: number;
    daysStreamed: number;
    startTime: number;
    endTime: number;
    loadVote: () => void;
}

export const SponsorshipContext = createContext<SponsorshipContextValue>({} as SponsorshipContextValue);

export const useSponsorship = (): SponsorshipContextValue => useContext(SponsorshipContext);

export const SponsorshipContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const networkState = useHubNetworkState();
    const { hubWallet } = useWallet();
    const { hubNetwork, hubCurrency, rollapps } = useNetwork();
    const { clientStateMap } = useClient();
    const { incentivesState } = useIncentives();
    const { ammState, getTokenPrice } = useAmm();
    const { handleClientError } = useClient();
    const [ stakedAmount, setStakedAmount ] = useState<CoinsAmount>();
    const [ toLoadVote, seTotLoadVote ] = useState(false);
    const [ stakingLoading, setStakingLoading ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ paramsLoading, setParamsLoading ] = useState(true);
    const [ voteLoading, setVoteLoading ] = useState(false);
    const [ sponsorshipDistribution, setSponsorshipDistribution ] = useState<Distribution>();
    const [ params, setParams ] = useState<SponsorshipParams>();
    const [ sponsorshipVote, setSponsorshipVote ] = useState<Vote | undefined>();
    const cancelAndSetSponsorshipVotePromise = useCancelablePromise<Vote | undefined>();
    const cancelAndSetDelegationsPromise = useCancelablePromise<Delegation[]>();
    const cancelAndSetDistributionPromise = useCancelablePromise<Distribution>();
    const cancelAndSetParamsPromise = useCancelablePromise<Params>();

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    const loadVote = useCallback(() => seTotLoadVote(true), []);

    useEffect(() => {
        if (hubWallet && toLoadVote) {
            setVoteLoading(true);
            setStakingLoading(true);
        } else {
            setSponsorshipVote(undefined);
            setStakedAmount(undefined);
        }
    }, [ hubWallet, toLoadVote ]);

    const distribution = useMemo(() => {
        if (!incentivesState.gauges || !ammState.pools || !hubCurrency || (!sponsorshipDistribution && loading)) {
            return undefined;
        }
        if (!sponsorshipDistribution?.gauges) {
            return [];
        }
        const totalWeight = sponsorshipDistribution.gauges.reduce((current, gauge) => current + BigInt(gauge.power), BigInt(0));
        const fixedTotalWeight = Decimal.fromAtomics(totalWeight.toString(), hubCurrency.decimals).toFloatApproximation();
        const weights: SponsorshipWeight[] = sponsorshipDistribution.gauges.map((gauge) => {
            const power = Decimal.fromAtomics(gauge.power, hubCurrency.decimals).toFloatApproximation();
            return { gaugeId: gauge.gaugeId, weight: !fixedTotalWeight ? 0 : power / fixedTotalWeight, power };
        });
        weights.push(...incentivesState.gauges
            .filter((gauge) => gauge.isPerpetual && weights.every((sponsorshipWeight) => gauge.id !== sponsorshipWeight.gaugeId))
            .map((gauge) => ({ gaugeId: gauge.id, power: 0, weight: 0 })),
        );
        return createSponsorshipRecords(fixedTotalWeight, weights, incentivesState.gauges, rollapps, ammState.pools);
    }, [ ammState.pools, hubCurrency, incentivesState.gauges, loading, rollapps, sponsorshipDistribution ]);

    const vote = useMemo(() => {
        if (!incentivesState.gauges || !ammState.pools || !hubCurrency || (!sponsorshipVote && voteLoading)) {
            return undefined;
        }
        if (!sponsorshipVote) {
            return [];
        }
        const totalVotingPower = Decimal.fromAtomics(sponsorshipVote.votingPower, hubCurrency.decimals).toFloatApproximation();
        const weights: SponsorshipWeight[] = sponsorshipVote.weights.map((gaugeWeight) => {
            const weight = Decimal.fromAtomics(gaugeWeight.weight, 18).toFloatApproximation() / 100;
            return { gaugeId: gaugeWeight.gaugeId, weight, power: weight * totalVotingPower };
        });
        return createSponsorshipRecords(totalVotingPower, weights, incentivesState.gauges, rollapps, ammState.pools);
    }, [ ammState.pools, hubCurrency, incentivesState.gauges, rollapps, sponsorshipVote, voteLoading ]);

    const {
        totalStreamed,
        dailyStreamed,
        daysStreamed,
        startTime,
        endTime,
    } = useMemo((): { totalStreamed: number, dailyStreamed: number, daysStreamed: number, startTime: number, endTime: number } => {
        const streams = incentivesState.streams?.filter((stream) => stream.sponsored);
        if (!streams?.length || !hubCurrency) {
            return { totalStreamed: 0, dailyStreamed: 0, daysStreamed: 0, startTime: 0, endTime: 0 };
        }
        const { totalStreamed, dailyStreamed, daysStreamed, startTime, endTime } = streams.reduce(({
            totalStreamed,
            dailyStreamed,
            daysStreamed,
            startTime,
            endTime,
        }, stream) => {
            const amount = stream.coins
                .filter((coin) => coin.denom === hubCurrency.baseDenom)
                .reduce((amount, coin) => Number(coin.amount) + amount, 0);

            const dayMilliseconds = epochToMilliseconds('day');
            const epochMilliseconds = epochToMilliseconds(stream.distrEpochIdentifier);
            const days = (epochMilliseconds * stream.numEpochsPaidOver) / dayMilliseconds;
            const dailyAmount = amount / days;
            const currentStartTime = new Date(stream?.startTime || Date.now()).getTime();
            const currentEndTime = new Date(currentStartTime + (epochMilliseconds * stream.numEpochsPaidOver)).getTime();

            return {
                totalStreamed: totalStreamed + amount,
                dailyStreamed: dailyStreamed + dailyAmount,
                daysStreamed: Math.max(daysStreamed, days),
                startTime: isNaN(currentStartTime) ? startTime : Math.min(startTime),
                endTime: isNaN(currentEndTime) ? endTime : Math.max(endTime, currentEndTime),
            };
        }, { totalStreamed: 0, dailyStreamed: 0, daysStreamed: 0, startTime: 0, endTime: 0 });
        return {
            totalStreamed: getMaxDenomAmount(totalStreamed, hubCurrency),
            dailyStreamed: getMaxDenomAmount(dailyStreamed, hubCurrency),
            daysStreamed,
            startTime,
            endTime,
        };
    }, [ hubCurrency, incentivesState.streams ]);

    const sponsorshipPoolAprs = useMemo((): { [poolId: number]: number } => {
        return (distribution || []).reduce((current, record) => {
            if (!record.pool) {
                return current;
            }
            const streamed = (record?.weight || 0) * totalStreamed;
            const totalLockedValue = ammState.totalLockedValues?.[record.pool.lpTokenDenom];
            const value = !hubCurrency ? 0 :
                getTokenPrice({ amount: streamed, currency: hubCurrency, networkId: hubNetwork?.chainId || '' }) || 0;
            const dayMilliseconds = epochToMilliseconds('day');
            const yearMilliseconds = epochToMilliseconds('year');
            const yearPart = (daysStreamed * dayMilliseconds) / yearMilliseconds;
            const sponsorshipApr = !totalLockedValue ? 0 : value / (totalLockedValue * yearPart);
            return { ...current, [record.pool.id]: sponsorshipApr };
        }, {} as { [poolId: number]: number });
    }, [ ammState.totalLockedValues, daysStreamed, distribution, getTokenPrice, hubCurrency, hubNetwork?.chainId, totalStreamed ]);

    useEffect(() => {
        if (!hubNetwork || !clientState) {
            return;
        }
        setLoading(true);
        cancelAndSetDistributionPromise((signal) => loadSponsorshipDistribution(hubNetwork, signal))
            .then(setSponsorshipDistribution)
            .catch((error) => {
                handleClientError(error);
                setLoading(false);
            });
    }, [ handleClientError, hubNetwork, clientState, cancelAndSetDistributionPromise ]);

    useEffect(() => {
        if (!hubNetwork || !hubCurrency) {
            return;
        }
        setParamsLoading(true);
        cancelAndSetParamsPromise((signal) => loadSponsorshipParams(hubNetwork, signal))
            .then((params) => setParams({
                minAllocationWeight: convertDecimalToInt(Number(params.minAllocationWeight)),
                minVotingPower: getMaxDenomAmount(Number(params.minVotingPower), hubCurrency),
            }))
            .catch(handleClientError)
            .finally(() => setParamsLoading(false));
    }, [ cancelAndSetParamsPromise, handleClientError, hubCurrency, hubNetwork ]);

    useEffect(() => {
        if ((clientState && !clientState.client && !clientState.connecting) || (!networkState.addressLoading && !networkState.address)) {
            setStakingLoading(false);
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address || !networkState.network || !toLoadVote) {
            return;
        }
        setStakingLoading(true);
        const stakeCurrency = getStakingCurrency(networkState.network);
        cancelAndSetDelegationsPromise(loadDelegations(clientState.client, stakeCurrency, networkState.address))
            .then((delegations) => {
                const totalAmount = delegations.reduce((current, delegation) => current + delegation.coins.amount, 0);
                setStakedAmount({ currency: stakeCurrency, amount: totalAmount, networkId: hubNetwork?.chainId || '' });
            })
            .catch(handleClientError)
            .finally(() => setStakingLoading(false));
    }, [
        hubNetwork?.chainId,
        cancelAndSetDelegationsPromise,
        clientState,
        handleClientError,
        networkState.address,
        networkState.addressLoading,
        networkState.network,
        toLoadVote,
    ]);

    useEffect(() => {
        if ((clientState && !clientState.client && !clientState.connecting) ||
            (!networkState.addressLoading && !networkState.address)) {
            setVoteLoading(false);
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address || !toLoadVote) {
            return;
        }
        setVoteLoading(true);
        cancelAndSetSponsorshipVotePromise(loadSponsorshipVote(clientState.client, networkState.address))
            .then(setSponsorshipVote)
            .catch(() => setVoteLoading(false));
    }, [
        cancelAndSetSponsorshipVotePromise,
        clientState,
        handleClientError,
        networkState.address,
        networkState.addressLoading,
        toLoadVote,
    ]);

    useEffect(() => {
        if (incentivesState.loading) {
            return;
        }
        if (sponsorshipDistribution) {
            setLoading(false);
        }
        if (sponsorshipVote) {
            setVoteLoading(false);
        }
    }, [ incentivesState.loading, sponsorshipDistribution, sponsorshipVote ]);

    return <SponsorshipContext.Provider
        value={{
            loading,
            paramsLoading,
            params,
            distribution,
            totalStreamed,
            dailyStreamed,
            daysStreamed,
            startTime,
            endTime,
            vote,
            voteLoading,
            stakedAmount,
            stakingLoading,
            loadVote,
            sponsorshipPoolAprs,
        }}
    >
        {children}
    </SponsorshipContext.Provider>;
};
