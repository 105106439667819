import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { roundNumber } from '../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { TxState } from '../../tx/tx-state';
import { useTx } from '../../tx/use-tx';
import { useSponsorship } from '../sponsorship-context';
import { createSponsorshipVoteMessage } from '../sponsorship-service';
import { SponsorshipRecord } from '../sponsorship-types';

interface UseSponsorshipVoteValue {
    txState: TxState;
    totalWeight: number;
    currentVote: SponsorshipRecord[] | undefined;
    setCurrentVote: (vote: SponsorshipRecord[]) => void;
    broadcast: () => Promise<void>;
}

export const useSponsorshipVote = (): UseSponsorshipVoteValue => {
    const { handleClientError } = useClient();
    const networkState = useHubNetworkState();
    const { vote, distribution } = useSponsorship();
    const [ currentVote, setCurrentVote ] = useState<SponsorshipRecord[]>();

    const totalWeight = useMemo(() => currentVote?.reduce((current, record) => current + record.weight, 0) || 0, [ currentVote ]);

    useEffect(() => {
        if (distribution && vote) {
            setCurrentVote(() => distribution
                .map((record) => {
                    const voteRecord = vote.find((voteRecord) => voteRecord.gaugeId === record.gaugeId);
                    return { ...record, weight: roundNumber((voteRecord?.weight || 0) * 100, 12, true) };
                })
                .sort((record1, record2) => record2.weight - record1.weight));
        }
    }, [ distribution, vote ]);

    const voteMessagesCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !currentVote) {
            return [];
        }
        return [ createSponsorshipVoteMessage(networkState.address, currentVote.filter((record) => record.weight)) ];
    }, [ networkState.address, currentVote ]);

    const { txState, broadcast, calculateFee, clearFee } = useTx({
        networkState,
        txMessagesCreator: voteMessagesCreator,
    });

    useEffect(() => {
        if (txState.fee) {
            return;
        }
        if (networkState.address && currentVote) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, currentVote, networkState.address, txState.fee ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, txState.error ]);

    return { txState, currentVote, totalWeight, setCurrentVote, broadcast };
};
