import React, { useMemo } from 'react';
import Dialog, {
    DialogAction,
    DialogContent,
    DialogFooter,
    DialogProps,
    DialogTitle,
} from '../../../../../../../../shared/components/dialog/dialog';
import { App } from '../../../../../../../client/station-clients/dymension/generated/rollapp/app';
import { getFeeCurrency } from '../../../../../../../currency/currency-service';
import { useNetwork } from '../../../../../../../network/network-context';
import TransactionFee from '../../../../../../../tx/transaction-fee/transaction-fee';
import useHandleTxResponses from '../../../../../../../tx/use-handle-tx-responses';
import { useWallet } from '../../../../../../../wallet/wallet-context';
import { useRemoveApp } from './use-remove-app';
import './remove-app-dialog.scss';

interface RemoveAppDialogProps extends DialogProps {
    app: App;
}

const RemoveAppDialog: React.FC<RemoveAppDialogProps> = ({ app, ...dialogProps }) => {
    const { hubNetwork } = useNetwork();
    const { hubWallet } = useWallet();
    const { appTxState, broadcastAppTx } = useRemoveApp(app);
    useHandleTxResponses(appTxState, hubWallet, dialogProps.onRequestClose);

    const fee = useMemo(() => {
        const feeCurrency = hubNetwork && getFeeCurrency(hubNetwork);
        if (!feeCurrency) {
            return undefined;
        }
        return {
            label: 'Transaction fee',
            loading: appTxState.feeLoading,
            value: appTxState.fee?.coins || { currency: feeCurrency, amount: 0, networkId: hubNetwork?.chainId },
        };
    }, [ appTxState.fee?.coins, appTxState.feeLoading, hubNetwork ]);

    return (
        <Dialog className='remove-app-dialog' {...dialogProps}>
            <DialogTitle className='dialog-title'>Remove App</DialogTitle>
            <DialogContent>
                Are you sure you want to remove the {app.name} app?
            </DialogContent>
            <DialogAction secondary close>Cancel</DialogAction>
            <DialogAction
                secondary
                className='remove-button'
                loading={appTxState.broadcasting}
                disabled={appTxState.broadcasting}
                onClick={() => broadcastAppTx()}
            >
                Remove
            </DialogAction>
            <DialogFooter className='dialog-footer section small'>
                {fee && <TransactionFee fee={fee} hideAmount={!hubWallet} />}
            </DialogFooter>
        </Dialog>
    );
};

export default RemoveAppDialog;
