import { WalletType } from '../wallet-types';
import { EthereumProvider, EthereumWallet } from './ethereum-wallet';


declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        ethereum?: EthereumProvider;
    }
}

export class MetaMaskWallet extends EthereumWallet {
    constructor() {
        super('ethereum#initialized');
    }

    public getWalletType(): WalletType {
        return 'MetaMask';
    }

    public getCurrentProvider(): EthereumProvider | undefined {
        return window.ethereum?.eip6963ProviderDetails?.find((details) => details.info.rdns === 'io.metamask')?.provider || window.ethereum;
    }
}

