import React, { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import { Asset } from '../../asset/asset-types';
import { useIRO } from '../iro-context';
import './iro-status-indicator.scss';

export interface IroStatusIndicatorProps {
    className?: string;
    asset: Asset;
}

const IroStatusIndicator: React.FC<IroStatusIndicatorProps> = ({ className, asset }) => {
    const { getIroPlan } = useIRO();

    const iroPlan = useMemo(() => getIroPlan(asset.network.chainId), [ getIroPlan, asset.network.chainId ]);

    const endTime = useMemo(() => iroPlan?.preLaunchTime ? new Date(iroPlan.preLaunchTime) : new Date(), [ iroPlan?.preLaunchTime ]);

    const readyToLaunch = useMemo(() => endTime <= new Date(), [ endTime ]);

    const infoTooltip = useMemo(() => {
        if (readyToLaunch) {
            return 'Ready to Launch';
        }
        if (asset.futureIRO) {
            return 'When the deadline has passed, the RollApp IRO will begin.';
        }
        return 'When the deadline passes, the RollApp can launch at any time, ending the IRO.';
    }, [ asset.futureIRO, readyToLaunch ]);

    const iroStatusColorVariable = useMemo(() => {
        if (readyToLaunch) {
            return '--orange';
        }
        if (asset.futureIRO) {
            return '--red';
        }
        return '--light-green';
    }, [ asset.futureIRO, readyToLaunch ]);

    return (
        <Tooltip title={infoTooltip} placement='top'>
            <div className='iro-status-indicator-click-area'>
                <span
                    className={classNames('iro-status-indicator', className)}
                    style={{ '--color': `var(${iroStatusColorVariable})` } as CSSProperties}
                />
            </div>
        </Tooltip>
    );
};

export default IroStatusIndicator;
