import React, { useCallback, useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { roundNumber } from '../../../../shared/utils/number-utils';
import { useAsset } from '../../../asset/asset-context';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';

const StakingAprProperty: React.FC = () => {
    const { network } = useNetworkDashboard();
    const { mainAssetMap } = useAsset();

    const asset = useMemo(() => mainAssetMap && mainAssetMap[network.chainId], [ mainAssetMap, network.chainId ]);

    const formatValue = useCallback((value: number) => `${roundNumber(value, 2)}%`, []);

    return (
        <Property label='Staking APR' na={!asset?.stakingApr || !network.totalSupply}>
            <StatisticsChange period='day' currentValue={asset?.stakingApr || 0} previousValue={asset?.previousDayStakingApr || 0}>
                {formatValue(asset?.stakingApr || 0)}
            </StatisticsChange>
        </Property>
    );
};

export default StakingAprProperty;
