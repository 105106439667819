import React, { ReactElement, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { validateAndGetChildrenAsArray } from '../../utils/react-utils';
import Tooltip from '../tooltip/tooltip';
import './tab-bar.scss';

interface TabProps {
    label: ReactNode;
    children?: ReactNode;
    className?: string;
    headerClassName?: string;
    labelClassName?: string;
    tooltip?: ReactNode;
    tabKey: string;
    disabled?: boolean;
    scrolled?: boolean;
    containerRef?: RefObject<HTMLDivElement>;
}

interface TabBarProps {
    className?: string;
    children: ReactNode;
    size?: 'medium' | 'large';
    activeTabKey?: string;
    uniformTabWidth?: boolean;
    onTabChange?: (tabKey: string) => void;
}

export const Tab: React.FC<TabProps> = ({ children, className, scrolled, containerRef }) => {
    return (
        <div className={classNames('tab', className, { scrolled })} ref={containerRef}>
            {children}
        </div>
    );
};

const TabBar: React.FC<TabBarProps> = ({ className, activeTabKey, onTabChange, size = 'medium', children, uniformTabWidth }) => {
    const tabs = validateAndGetChildrenAsArray(children, Tab);

    const [ selectedTabKey, setSelectedTabKey ] = useState<string>(activeTabKey || tabs[0].props.tabKey);

    useEffect(() => {
        if (activeTabKey) {
            setSelectedTabKey(activeTabKey);
        }
    }, [ activeTabKey ]);

    const activeTab = useMemo(() => tabs.find((tab) => tab.props.tabKey === selectedTabKey), [ selectedTabKey, tabs ]);

    const onTabClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, tab: ReactElement<TabProps>): void => {
        if (tab.props.disabled) {
            event.preventDefault();
        } else {
            setSelectedTabKey(tab.props.tabKey);
            onTabChange?.(tab.props.tabKey);
        }
    };

    const renderTab = (tab: ReactElement<TabProps>): ReactElement => {
        return (
            <button
                onClick={(event) => onTabClick(event, tab)}
                className={classNames(
                    'tab-action',
                    tab.props.headerClassName,
                    { active: tab.props.tabKey === selectedTabKey, disabled: tab.props.disabled },
                )}
            >
                <h6 className={tab.props.labelClassName}>{tab.props.label}</h6>
            </button>
        );
    };

    return (
        <div className={classNames('tab-bar', className, size, { 'uniform-tab-width': uniformTabWidth })}>
            <ul className='tab-action-list'>
                {tabs.map((tab) => (
                    <li key={tab.props.tabKey} className='tab-container'>
                        {tab.props.tooltip ? <Tooltip title={tab.props.tooltip}>{renderTab(tab)}</Tooltip> : renderTab(tab)}
                    </li>
                ))}
            </ul>
            {activeTab}
        </div>
    );
};

export default TabBar;
