import classNames from 'classnames';
import { QRCodeSVG } from 'qrcode.react';
import React, { useMemo } from 'react';
import Button from '../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../shared/components/dialog/dialog';
import Duration from '../../../shared/components/form-controls/duration/duration';
import Input from '../../../shared/components/form-controls/input/input';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import Icon from '../../../shared/components/icon/icon';
import InfoIndicator from '../../../shared/components/info-indicator/info-indicator';
import { ReactComponent as ThunderIcon } from '../../../assets/icons/thunder.svg';
import Spinner from '../../../shared/components/spinner/spinner';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { getTimeLeftText, getTimeOffset, timeToMilliseconds } from '../../../shared/utils/date-utils';
import { generatePinCode } from '../../../shared/utils/text-utils';
import { AccountNetworkState } from '../../account/account-network-state';
import { QUICK_AUTH_MESSAGE_TYPES, QuickAuthMessageType } from '../quick-auth-types';
import { useQuickAuth } from '../quick-auth-context';
import './quick-auth-dialog.scss';

interface QuickAuthDialogProps extends DialogProps {
    networkState: AccountNetworkState;
}

const QuickAuthDialog: React.FC<QuickAuthDialogProps> = ({ networkState, ...otherDialogProps }) => {
    const {
        isConnected,
        connecting,
        pinCode,
        accountSerialization,
        pinCodeExpiration,
        expiration,
        sessionDuration,
        quickAuthMessageTypes,
        setQuickAuthMessageTypes,
        setSessionDuration,
        connect,
        revoke,
    } = useQuickAuth();

    const qrCodeData = useMemo(() => {
        if (!accountSerialization) {
            return generatePinCode(200);
        }
        if (window.location.pathname.includes('/connect')) {
            return `${document.referrer}?qrAccount=${accountSerialization}`;
        }
        return `${window.location.origin}/account/${accountSerialization}`;
    }, [ accountSerialization ]);

    return (
        <Dialog className='quick-auth-dialog' closable onClickOutside={otherDialogProps.onRequestClose} {...otherDialogProps}>
            <DialogTitle className='dialog-title'><Icon><ThunderIcon /></Icon> Quick Auth</DialogTitle>
            <DialogContent className='dialog-content'>
                <div className='configuration-section'>
                    <p className='quick-auth-description'>
                        Quick Auth is a secure session with <b className='primary-text'>{networkState.network?.chainName}</b>, allowing users to interact without signing each transaction, accessible via browser or mobile.
                        <InfoIndicator className='description-info-indicator'>
                            Quick auth works via AuthZ abstraction which allows granting arbitrary privileges from your account to a temporary self custody account embedded in your browser/mobile.
                        </InfoIndicator>
                    </p>

                    {networkState.network?.type === 'Hub' && (
                        <div className='control-container'>
                            <div className='control-label-container'><label>Approved Actions</label></div>
                            <Select
                                multiselect
                                placeholder='Select actions'
                                value={quickAuthMessageTypes}
                                onSelect={(values) => setQuickAuthMessageTypes(values as QuickAuthMessageType[])}
                            >
                                {QUICK_AUTH_MESSAGE_TYPES.map((type) => <Option value={type} key={type}>{type}</Option>)}
                            </Select>
                        </div>
                    )}

                    <div className='control-container'>
                        <div className='control-label-container'><label>Session Duration</label></div>
                        <Duration
                            minTimeUnit='minutes'
                            value={sessionDuration}
                            maxTimeUnit='hours'
                            minDuration={timeToMilliseconds({ minutes: 1 })}
                            maxDuration={timeToMilliseconds({ days: 1 })}
                            onValueChange={setSessionDuration}
                        />
                    </div>

                    <span className='space' />

                    <Button
                        className={classNames('connect-action', { revoke: isConnected })}
                        disabled={connecting}
                        loading={connecting}
                        onClick={isConnected ? revoke : connect}
                    >
                        {!isConnected ? 'Connect' : <b>Revoke ({getTimeLeftText(getTimeOffset(expiration))})</b>}
                    </Button>
                </div>

                <div className={classNames('qr-section', { visible: isConnected })}>
                    <label className='qr-label'>
                        Scan for Mobile Access
                        <small className='secondary-text'>Expires in {getTimeOffset(pinCodeExpiration).seconds} seconds</small>
                    </label>

                    {qrCodeData ? (
                        <QRCodeSVG
                            value={qrCodeData}
                            className='qr-code margin-horizontally-centered'
                            size={256}
                            fgColor={getCssVariableValue('--black-dark')}
                            bgColor={getCssVariableValue('--cream')}
                        />
                    ) : <div className='qr-code-spinner-container'><Spinner /></div>}

                    <div className='control-container confirmation-pin-code-container'>
                        <div className='control-label-container'><label>Confirmation pin code</label></div>
                        <Input value={pinCode} className='confirmation-pin-code' disabled />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default QuickAuthDialog;
