import React, { Children, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import useWindowSize from '../../hooks/use-window-size';
import Swiper from '../swiper/swiper';
import './statistics-cards.scss';

export interface StatisticsCardsProps {
    children: ReactNode;
    className?: string;
    vertically?: boolean;
    wide?: boolean;
    threeInLine?: boolean;
}

const StatisticsCards: React.FC<StatisticsCardsProps> = ({ children, className, vertically, wide }) => {
    const { isTablet } = useWindowSize();

    const threeInLine = useMemo(() => Children.toArray(children).length % 2 !== 0, [ children ]);

    const cards = Children.toArray(children).map((element, elementIndex) => (
        <div
            key={elementIndex}
            className={classNames('statistics-card section small', { wide: wide || isTablet, 'three-in-line': threeInLine })}
        >
            {element}
        </div>
    ));

    return <>
        <div className={classNames('statistics-cards', className, { vertically })}>{cards}</div>
        <Swiper containerClassName={classNames('statistics-cards-swiper', className)} showOneInMobile>{cards}</Swiper>
    </>;
};

export default StatisticsCards;
