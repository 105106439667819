import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Property from '../../../../shared/components/property/property';
import StatisticsCards from '../../../../shared/components/statistics-cards/statistics-cards';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAsset } from '../../../asset/asset-context';
import ClaimableTokensDialog from '../../../account/claimable-tokens/claimable-tokens-dialog';
import { IbcTransferDetails } from '../../ibc-status/ibc-status-types';
import { useEibcClient } from '../eibc-client-context';
import './eibc-client-statistics.scss';

const EibcClientStatistics: React.FC = () => {
    const { orders, ordersLoading } = useEibcClient();
    const { getTokenPrice } = useAsset();
    const { isMobile } = useWindowSize();
    const [ claimableTokensDialogOrders, setClaimableTokensDialogOrders ] = useState<IbcTransferDetails[]>();

    const pendingOrders = useMemo(() => orders?.filter((transfer) => {
        const latestFinalizedHeight = transfer.type === 'In' ?
            transfer.sourceNetwork?.latestFinalizedHeight : transfer.destinationNetwork?.latestFinalizedHeight;
        return !latestFinalizedHeight || latestFinalizedHeight < transfer.proofHeight;
    }), [ orders ]);

    const finalizedOrders = useMemo(() => orders?.filter((transfer) => {
        const latestFinalizedHeight = transfer.type === 'In' ?
            transfer.sourceNetwork?.latestFinalizedHeight : transfer.destinationNetwork?.latestFinalizedHeight;
        return latestFinalizedHeight && latestFinalizedHeight >= transfer.proofHeight;
    }), [ orders ]);

    const getOrdersTotalAmount = useCallback(
        (orders?: IbcTransferDetails[]) => orders?.reduce((current, order) =>
            current + ((order.coins && getTokenPrice(order.coins)) || 0), 0) || 0,
        [ getTokenPrice ],
    );

    const getOrdersTotalFees = useCallback(
        (orders?: IbcTransferDetails[]) => orders?.reduce((current, order) =>
            current + ((order.eibcFeeCoins && getTokenPrice(order.eibcFeeCoins)) || 0), 0) || 0,
        [ getTokenPrice ],
    );

    const renderOrdersProperty = (label: string, actionLabel: string, orders?: IbcTransferDetails[]): ReactElement => {
        return (
            <Property
                label={label}
                loading={!orders && ordersLoading}
                valueClassName='orders-property-value'
                valueSuffix={(
                    <Button disabled={!orders?.length} onClick={() => setClaimableTokensDialogOrders(orders)}>{actionLabel}</Button>
                )}
            >
                <div className='value-container'>
                    <h3 className='nowrap'>{orders?.length || 0} order{orders?.length === 1 ? '' : 's'}</h3>
                    <small className='secondary-text amount-fees'>
                        <span className='nowrap'>Total amount: {formatPrice(getOrdersTotalAmount(orders))}</span>
                        {isMobile ? '' : <>&nbsp;•&nbsp;</>}
                        <span className='nowrap'>Total fees: {formatPrice(getOrdersTotalFees(orders))}</span>
                    </small>
                </div>
            </Property>
        );
    };

    return <>
        <StatisticsCards className='eibc-client-statistics'>
            {renderOrdersProperty('Pending Orders', 'Show', pendingOrders)}
            {renderOrdersProperty('Finalized Orders', 'Claim', finalizedOrders)}
        </StatisticsCards>

        {claimableTokensDialogOrders &&
            <ClaimableTokensDialog
                transfers={claimableTokensDialogOrders}
                loading={ordersLoading}
                onRequestClose={() => setClaimableTokensDialogOrders(undefined)}
            />}
    </>;
};

export default EibcClientStatistics;
