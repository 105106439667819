import classNames from 'classnames';
import { ParameterChangeProposal } from 'cosmjs-types/cosmos/params/v1beta1/params';
import { SoftwareUpgradeProposal } from 'cosmjs-types/cosmos/upgrade/v1beta1/upgrade';
import { ClientUpdateProposal } from 'cosmjs-types/ibc/core/client/v1/client';
import { SnackbarMessage } from '../../../shared/components/snackbar/snackbar-types';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import { SubmitFraudProposal } from '../../client/station-clients/dymension/generated/rollapp/proposal';
import {
    ReplaceStreamDistributionProposal,
    UpdateStreamDistributionProposal,
} from '../../client/station-clients/dymension/generated/streamer/gov_distribution';
import { CreateStreamProposal, TerminateStreamProposal } from '../../client/station-clients/dymension/generated/streamer/gov_stream';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Alert from '../../../shared/components/alert/alert';
import Button from '../../../shared/components/button/button';
import Input, { InputProps } from '../../../shared/components/form-controls/input/input';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import TextArea from '../../../shared/components/form-controls/text-area/text-area';
import useWindowSize from '../../../shared/hooks/use-window-size';
import PathNav, { PathNavItem } from '../../path-nav/path-nav';
import AmountTx from '../../tx/amount-tx/amount-tx';
import { Network } from '../../network/network-types';
import { DeliveryTxCode, TxResponse } from '../../tx/tx-types';
import { useWallet } from '../../wallet/wallet-context';
import { WalletInfoMap } from '../../wallet/wallet-types';
import { PROPOSAL_TYPES, ProposalType, ROLLAPP_PROPOSAL_TYPES } from '../governance-types';
import ClientUpdate from './proposal-types/client-update';
import CreateStream from './proposal-types/create-stream';
import ParameterChange from './proposal-types/parameter-change';
import SoftwareUpgrade from './proposal-types/software-upgrade';
import SubmitFraud from './proposal-types/submit-fraud';
import TerminateStream from './proposal-types/terminate-stream';
import UpdateReplaceStreamDistribution from './proposal-types/update-replace-stream-distribution';
import { useCreateProposal } from './use-create-proposal';
import './create-proposal.scss';

interface CreateProposalProps {
    network: Network;
}

const CreateProposal: React.FC<CreateProposalProps> = ({ network }) => {
    const { isTablet } = useWindowSize();
    const { networkWalletMap } = useWallet();
    const {
        networkState,
        createProposalState,
        txState,
        availableBalances,
        amountTxState,
        isProposalValid,
        setType,
        setInitialDeposit,
        initContent,
        updateContent,
        broadcast,
    } = useCreateProposal(network);
    const [ descriptionPreviewVisible, setDescriptionPreviewVisible ] = useState(false);

    const wallet = networkState.network && networkWalletMap[networkState.network?.chainId];

    const proposalsWithoutContent = useMemo((): ProposalType[] => [ 'Cancel Software Upgrade', 'Text' ], []);

    const proposalTypes = useMemo(() => network.type === 'Hub' ? PROPOSAL_TYPES : ROLLAPP_PROPOSAL_TYPES, [ network.type ]);

    const onTitleChange = useCallback((text: InputProps['value']) => updateContent({ title: text?.toString() || '' }), [ updateContent ]);

    const onDescriptionChange = useCallback(
        (text: InputProps['value']) => updateContent({ description: text?.toString() || '' }),
        [ updateContent ],
    );

    const proposalContentVisible = useMemo(() => Boolean(createProposalState.type &&
        !proposalsWithoutContent.includes(createProposalState.type)), [ createProposalState.type, proposalsWithoutContent ]);

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            return { content: 'Proposal successfully created!' };
        }
    }, []);

    useEffect(() => {
        if (createProposalState.type && proposalsWithoutContent.includes(createProposalState.type)) {
            updateContent({}, true);
        }
    }, [ createProposalState.type, proposalsWithoutContent, updateContent ]);

    const renderProposalContent = (): ReactElement | undefined => {
        switch (createProposalState.type) {
            case 'Parameter Change':
                return <ParameterChange
                    content={createProposalState.content as ParameterChangeProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;
            case 'Create Stream':
                return <CreateStream
                    content={createProposalState.content as CreateStreamProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;
            case 'Client Update':
                return <ClientUpdate
                    content={createProposalState.content as ClientUpdateProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;

            case 'Update Stream Distribution':
                return <UpdateReplaceStreamDistribution
                    content={createProposalState.content as UpdateStreamDistributionProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;
            case 'Replace Stream Distribution':
                return <UpdateReplaceStreamDistribution
                    content={createProposalState.content as ReplaceStreamDistributionProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;
            case 'Terminate Stream':
                return <TerminateStream
                    content={createProposalState.content as TerminateStreamProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;
            case 'Submit Fraud Proposal':
                return <SubmitFraud
                    content={createProposalState.content as SubmitFraudProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;
            case 'Software Upgrade':
                return <SoftwareUpgrade
                    content={createProposalState.content as SoftwareUpgradeProposal}
                    onInit={initContent}
                    onChange={updateContent}
                />;
        }
    };

    const renderConfirmSection = (): ReactElement => {
        return (
            <div className='section confirm-section'>
                <h5 className='proposal-section-header'>Initial deposit</h5>

                <AmountTx
                    controlSize='large'
                    txState={txState}
                    amountTxState={amountTxState}
                    networkState={networkState}
                    availableBalances={availableBalances}
                    onCoinsChange={setInitialDeposit}
                    getTxResponseMessage={getTxResponseMessage}
                    reduceFeeFromBalances
                    submitButtonContainer={<>
                        <Button
                            className='confirm-button'
                            loading={txState.broadcasting || txState.feeLoading}
                            onClick={() => broadcast()}
                            disabled={txState.broadcasting || txState.feeLoading || !amountTxState.coins?.amount || !isProposalValid}
                        >
                            Create
                        </Button>
                    </>}
                />
            </div>
        );
    };

    return (
        <div className={classNames('page create-proposal', { expanded: proposalContentVisible })}>
            {network.type === 'RollApp' ? (
                <PathNav>
                    <PathNavItem label='RollApps' url='/rollapps' />
                    <PathNavItem label={network.chainName} url={`/rollapps/${network.chainId}`} />
                    <PathNavItem label='Governance' url={`/rollapps/${network.chainId}/governance`} />
                    <PathNavItem label='Create New Proposal' />
                </PathNav>
            ) : (
                <PathNav>
                    <PathNavItem label='Dymension' url='/dymension/metrics' />
                    <PathNavItem label='Governance' url={`/dymension/governance`} />
                    <PathNavItem label='Create New Proposal' />
                </PathNav>
            )}

            {wallet && WalletInfoMap[wallet.getWalletType()].type === 'evm' && (
                <Alert type='error' className='unsupported-wallet-message'>
                    Creation of governance proposals is currently not supported on evm wallets.
                </Alert>
            )}
            <h3 className='create-proposal-header'>Create New Proposal</h3>

            <div className='proposal-parts'>
                <div className='proposal-details-part'>
                    <div className='section'>
                        <h5 className='proposal-section-header'>Proposal details</h5>
                        <label className='proposal-control-label'>Type</label>
                        <Select
                            value={createProposalState.type}
                            placeholder='Choose proposal type'
                            className='proposal-type-selector'
                            onSelect={(value) => setType(value as ProposalType)}
                        >
                            {proposalTypes.map((type) => {
                                const disabled = type === 'Create Denom Metadata Proposal' ||
                                    type === 'Update Denom Metadata Proposal' ||
                                    type === 'Register ERC20 Proposal';
                                return (
                                    <Option value={type} key={type} disabled={disabled}>
                                        {disabled ? <Tooltip placement='top' title={'Coming soon'}>
                                            <div>{type}</div>
                                        </Tooltip> : type}
                                    </Option>
                                );
                            })}
                        </Select>

                        <label className='proposal-control-label'>Title</label>
                        <Input
                            disabled={!createProposalState.content}
                            placeholder='Enter proposal title'
                            value={createProposalState.content?.title}
                            onTypeFinish={onTitleChange}
                        />

                        <label className='proposal-control-label'>
                            Description
                            <Button
                                className='description-preview-button'
                                buttonType='secondary'
                                size='xs'
                                onClick={() => setDescriptionPreviewVisible(!descriptionPreviewVisible)}
                            >
                                Preview
                            </Button>
                        </label>
                        {descriptionPreviewVisible ? (
                            <Markdown className='markdown proposal-description-markdown' remarkPlugins={[ remarkGfm ]}>
                                {createProposalState.content?.description}
                            </Markdown>
                        ) : (
                            <TextArea
                                disabled={!createProposalState.content}
                                placeholder='Describe the proposal'
                                value={createProposalState.content?.description}
                                onValueChange={onDescriptionChange}
                            />
                        )}
                    </div>

                    {!isTablet && renderConfirmSection()}
                </div>

                {proposalContentVisible ? (
                    <div className='proposal-type-part section'>
                        <h5 className='proposal-section-header'>{createProposalState.type}</h5>
                        {renderProposalContent()}
                    </div>
                ) : undefined}

                {isTablet && renderConfirmSection()}
            </div>


        </div>
    );
};

export default CreateProposal;
