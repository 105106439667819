import { MsgFinalizePacketEncodeObject } from 'cosmjs/packages/stargate';
import { rollappPacket_TypeFromJSON } from 'cosmjs/packages/stargate/build/modules/delayedack/ts-client/tx';
import { IbcTransferDetails } from '../../ibc-transfer/ibc-status/ibc-status-types';

export const createClaimMessage = (address: string, transfer: IbcTransferDetails): MsgFinalizePacketEncodeObject => {
    return {
        typeUrl: '/dymensionxyz.dymension.delayedack.MsgFinalizePacket',
        value: {
            sender: address,
            packetProofHeight: transfer.proofHeight,
            rollappId: transfer.type === 'In' ? transfer.sourceNetwork?.chainId : transfer.destinationNetwork?.chainId,
            packetSequence: transfer.sequence,
            packetType: rollappPacket_TypeFromJSON(transfer.packetType),
            packetSrcChannel: transfer.sourceChannel,
        },
    };
};
