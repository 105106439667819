import React, { useState } from 'react';
import Icon from '../../shared/components/icon/icon';
import Spinner from '../../shared/components/spinner/spinner';
import HubWalletSelectorDialog from '../account/account-menu/hub-wallet-selector-dialog/hub-wallet-selector-dialog';
import { useHubNetworkState } from '../account/hub-network-state-context';
import { ReactComponent as DymensionLogo } from '../../assets/logos/dymension-logo.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icons/discord.svg';
import { ReactComponent as DymensionFullLogo } from '../../assets/logos/dymension-full-logo.svg';
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg';
import grainImage from '../../assets/images/grain.svg';
import './splashscreen.scss';

function Splashscreen(): JSX.Element {
    const networkState = useHubNetworkState();
    const [ walletSelectorDialogOpen, setWalletSelectorDialogOpen ] = useState(false);

    return (
        <div className='splashscreen'>
            <img src={grainImage} className='grain-background' alt='grain background' />

            <div className='header'>
                <a href='/' className='logo'>
                    <Icon><DymensionLogo /></Icon>
                </a>
            </div>

            <div className='content'>
                <Icon className='header-logo'><DymensionFullLogo /></Icon>
                <Spinner />
            </div>

            <div className='footer'>
                <a className='social-link' href='https://dymension.xyz/' target='_blank' rel='noreferrer'>
                    <Icon><DymensionLogo /></Icon>
                </a>
                <a className='social-link' href='https://twitter.com/dymension' target='_blank' rel='noreferrer'>
                    <Icon><TwitterIcon /></Icon>
                </a>
                <a className='social-link' href='https://discord.gg/dymension' target='_blank' rel='noreferrer'>
                    <Icon><DiscordIcon /></Icon>
                </a>
                <a className='social-link' href='https://github.com/dymensionxyz' target='_blank' rel='noreferrer'>
                    <Icon><GithubIcon /></Icon>
                </a>
            </div>

            {walletSelectorDialogOpen &&
                <HubWalletSelectorDialog networkState={networkState} onRequestClose={() => setWalletSelectorDialogOpen(false)} />}
        </div>
    );
}

export default Splashscreen;
