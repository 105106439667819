import { readStream } from '../../shared/utils/file-utils';
import { Network } from './network-types';

export const getNetworkLogoPath = (network: Network, fallback = true) => {
    if (!network.logo) {
        return fallback ? require('../../assets/icons/token.svg').default : undefined;
    }
    if (/^https?:\/\//i.test(network.logo)) {
        return network.logo;
    }
    return `${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}${network.logo || '/logos/logo.png'}`;
};

export const getNetworkData = async <T>(networkId: string, dataType: string, single?: boolean, signal?: AbortSignal): Promise<T> => {
    const response = await fetch(
        `${process.env.REACT_APP_FETCH_NETWORK_DATA}?networkId=${networkId}&dataType=${dataType}&single=${single}`,
        { signal },
    ).catch((error) => {
        if (!signal?.aborted) {
            console.error('Failed to fetch network data', { error });
        }
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    return JSON.parse(responseText || (single ? '{}' : '[]')) as T;
};

export const getNetworkDataItem = async <T>(
    networkId: string,
    dataType: string,
    itemId: string | number,
    signal?: AbortSignal,
): Promise<T> => {
    const response = await fetch(
        `${process.env.REACT_APP_FETCH_NETWORK_DATA_ITEM}?networkId=${networkId}&dataType=${dataType}&itemId=${itemId}`,
        { signal },
    ).catch((error) => {
        console.error('Failed to fetch network data item', { error });
    });
    const responseText = response?.body ? await readStream(response.body) : undefined;
    return JSON.parse(responseText || '{}') as T;
};

export const fetchRollAppIdName = (rollAppID: string): string => {
    return rollAppID.split(/[-_]/)[0];
};

export const fetchRollAppIdNumber = (rollAppID: string): string => {
    return rollAppID.split(/[-_]/)[1];
};

