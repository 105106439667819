export type TimeUnit = 'days' | 'hours' | 'minutes' | 'seconds';
export type DurationTime = { [unit in TimeUnit]: number };

export const getShortDateString = (value?: Date | string | number, showYearAlways?: boolean): string => {
    const date = value ? new Date(value) : new Date();
    let dateString = date.toDateString().replace(/^\S+\s/, '');
    if (!showYearAlways && date.getFullYear() === new Date().getFullYear()) {
        dateString = dateString.replace(/\s[0-9]{4}$/, '');
    }
    return dateString;
};

export const getShortTimeString = (value?: Date | string | number): string => {
    const date = value ? new Date(value) : new Date();
    let timeString = date.toTimeString();
    return timeString.split(':').slice(0, 2).join(':');
};

export const getShortDateTimeString = (value?: Date | string | number, showYearAlways?: boolean): string => {
    return getShortDateString(value, showYearAlways) + ', ' + getShortTimeString(value);
};

export const getFormattedLocalDateTime = (date: string | Date): string => {
    date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getTimeOffset = (date: number | Date): DurationTime => {
    const timeOffset = Math.abs((typeof date === 'number' ? date : date.getTime()) - new Date().getTime());
    return millisecondsToTime(timeOffset);
};

export const getTimeLeftText = (
    { days, hours, minutes, seconds }: { days: number, hours: number, minutes: number, seconds: number },
    timeUnitsMode: 'single' | 'double' | 'dynamic' = 'dynamic',
    longTimeUnitFormat?: boolean,
): string => {
    const daysFormat = longTimeUnitFormat ? ` day${days > 1 ? 's' : ''}` : 'd';
    const hoursFormat = longTimeUnitFormat ? ` hr${hours > 1 ? 's' : ''}` : 'h';
    const minutesFormat = longTimeUnitFormat ? ` min${minutes > 1 ? 's' : ''}` : 'm';
    const secondsFormat = longTimeUnitFormat ? ` sec${seconds > 1 ? 's' : ''}` : 's';
    if (days > 0) {
        return `${days}${daysFormat}` + (timeUnitsMode === 'double' || (timeUnitsMode === 'dynamic' && hours > 0) ?
            ` ${hours.toString().padStart(2, '0')}${hoursFormat}` : '');
    }
    if (hours > 0) {
        return `${hours}${hoursFormat}` + (timeUnitsMode === 'double' || (timeUnitsMode === 'dynamic' && minutes > 0) ?
            ` ${minutes.toString().padStart(2, '0')}${minutesFormat}` : '');
    }
    if (minutes > 0) {
        return `${minutes}${minutesFormat}` + (timeUnitsMode === 'double' || (timeUnitsMode === 'dynamic' && seconds > 0) ?
            ` ${seconds.toString().padStart(2, '0')}${secondsFormat}` : '');
    }
    return `${seconds}${secondsFormat}`;
};

export const timeToMilliseconds = (time: Partial<DurationTime>): number => {
    const msInOneSecond = 1000;
    const msInOneMinute = msInOneSecond * 60;
    const msInOneHour = msInOneMinute * 60;
    const msInOneDay = msInOneHour * 24;
    return (time.days || 0) * msInOneDay + (time.hours || 0) * msInOneHour +
        (time.minutes || 0) * msInOneMinute + (time.seconds || 0) * msInOneSecond;
};

export const millisecondsToTime = (value?: number): DurationTime => {
    const seconds = Math.round((value || 0) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return { seconds: seconds % 60, minutes: minutes % 60, hours: hours % 24, days };
};

