import React, { cloneElement, ReactElement, ReactNode, RefAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import useMouseHover from '../../hooks/use-mouse-hover';
import useScrollPosition from '../../hooks/use-scroll-position';
import useWindowSize from '../../hooks/use-window-size';
import './tooltip.scss';

export type TooltipPlacement =
    'top' |
    'top-start' |
    'top-end' |
    'left' |
    'left-start' |
    'left-end' |
    'right' |
    'right-start' |
    'right-end' |
    'bottom' |
    'bottom-start' |
    'bottom-end';

export interface TooltipBounds {
    left?: number;
    top?: number;
    maxWidth?: number;
}

interface TooltipProps {
    className?: string;
    title?: ReactNode;
    clickTitle?: ReactNode;
    attachedTo?: HTMLElement | null;
    children: ReactElement;
    placement?: TooltipPlacement;
    bounds?: TooltipBounds;
    visibleAlways?: boolean;
}

const MIN_EDGE_MARGIN = 8;

const Tooltip: React.FC<TooltipProps> = ({
    className,
    children,
    title,
    clickTitle,
    attachedTo,
    bounds,
    placement = 'bottom',
    visibleAlways,
}) => {
    const [ triggerRef, hover ] = useMouseHover();
    const { scrollPosition } = useScrollPosition();
    const { width } = useWindowSize();
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [ position, setPosition ] = useState<TooltipBounds>(bounds || {});
    const [ showClickTitle, setShowClickTitle ] = useState(false);

    const calculatePosition = useCallback(() => {
        const fixedAttachedTo = attachedTo || triggerRef.current;
        if (!fixedAttachedTo || !tooltipRef.current) {
            return { left: 0, top: 0 };
        }

        const triggerBounds = fixedAttachedTo.getBoundingClientRect();
        const tooltipBounds = tooltipRef.current.getBoundingClientRect();

        let left = bounds?.left || 0;
        let top = bounds?.top || 0;
        if (placement === 'left' || placement === 'left-start' || placement === 'left-end') {
            top += triggerBounds.top;
            left += (triggerBounds.left - tooltipBounds.width);
            if (placement === 'left') {
                top += (triggerBounds.height - tooltipBounds.height) / 2;
            } else if (placement === 'left-end') {
                top += (triggerBounds.height - tooltipBounds.height);
            }
        } else if (placement === 'right' || placement === 'right-start' || placement === 'right-end') {
            top += triggerBounds.top;
            left += (triggerBounds.left + triggerBounds.width);
            if (placement === 'right') {
                top += (triggerBounds.height - tooltipBounds.height) / 2;
            } else if (placement === 'right-end') {
                top += (triggerBounds.height - tooltipBounds.height);
            }
        } else if (placement === 'top' || placement === 'top-start' || placement === 'top-end') {
            top += (triggerBounds.top - tooltipBounds.height);
            left += triggerBounds.left;
            if (placement === 'top') {
                left += (triggerBounds.width - tooltipBounds.width) / 2;
            } else if (placement === 'top-end') {
                left += (triggerBounds.width - tooltipBounds.width);
            }
        } else if (placement === 'bottom' || placement === 'bottom-start' || placement === 'bottom-end') {
            top += triggerBounds.bottom;
            left += triggerBounds.left;
            if (placement === 'bottom') {
                left += (triggerBounds.width - tooltipBounds.width) / 2;
            } else if (placement === 'bottom-end') {
                left += (triggerBounds.width - tooltipBounds.width);
            }
        }
        left = Math.max(MIN_EDGE_MARGIN, Math.min(width - tooltipBounds.width - MIN_EDGE_MARGIN, left));
        setPosition({ ...bounds, left, top });
    }, [ attachedTo, bounds, placement, triggerRef, width ]);

    useEffect(() => {
        if (hover || visibleAlways) {
            setShowClickTitle(false);
        }
    }, [ hover, visibleAlways ]);

    const tooltipVisible = useMemo(() => title || (showClickTitle && clickTitle), [ clickTitle, showClickTitle, title ]);

    useEffect(() => {
        if (hover || visibleAlways || showClickTitle || tooltipVisible) {
            calculatePosition();
        }
    }, [ hover, calculatePosition, scrollPosition, visibleAlways, showClickTitle, tooltipVisible ]);

    const triggerElement = cloneElement<RefAttributes<HTMLElement>>(children, {
        ...children.props,
        onClick: (event: React.MouseEvent): void => {
            children.props.onClick?.(event);
            setShowClickTitle(true);
        },
        ref: triggerRef,
    });

    const tooltipClassName = classNames('tooltip', className, {
        left: placement === 'left' || placement === 'left-start' || placement === 'left-end',
        right: placement === 'right' || placement === 'right-start' || placement === 'right-end',
        top: placement === 'top' || placement === 'top-start' || placement === 'top-end',
        bottom: placement === 'bottom' || placement === 'bottom-start' || placement === 'bottom-end',
    });

    return (
        <>
            {triggerElement}
            <CSSTransition timeout={200} classNames='tooltip' in={hover || visibleAlways} unmountOnExit nodeRef={tooltipRef}>
                <span className={tooltipClassName} style={{ ...position, display: tooltipVisible ? 'initial' : 'none' }} ref={tooltipRef}>
                    {showClickTitle && clickTitle ? clickTitle : title}
                </span>
            </CSSTransition>
        </>
    );
};

export default Tooltip;
