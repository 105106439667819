import { useCallback, useState } from 'react';

export const useCancelablePromise = <T>(): ((newPromise?: Promise<T> | ((signal: AbortSignal) => Promise<T>)) => CancelablePromise<T>) => {
    const [ , setPromise ] = useState<CancelablePromise<T>>();

    return useCallback((newPromise?: Promise<T> | ((signal: AbortSignal) => Promise<T>)): CancelablePromise<T> => {
        const cancelablePromise = newPromise ? new CancelablePromise(newPromise) : undefined;
        setPromise((previousPromise) => {
            previousPromise?.cancel();
            return cancelablePromise;
        });
        return cancelablePromise || new CancelablePromise<T>(new Promise<T>(() => null));
    }, []);
};

class CancelablePromise<T> {
    private promise: Promise<T>;
    private canceled: boolean;
    private abortController?: AbortController;

    constructor(originalPromise: Promise<T> | ((signal: AbortSignal) => Promise<T>)) {
        this.canceled = false;
        let promise: Promise<T>;
        if (originalPromise && typeof originalPromise === 'function') {
            this.abortController = new AbortController();
            promise = originalPromise(this.abortController.signal);
        } else {
            promise = originalPromise;
        }
        this.promise = new Promise<T>((resolve, reject) => promise
            .then((result) => !this.canceled ? resolve(result) : null)
            .catch((error) => !this.canceled ? reject(error) : null));
    }

    public cancel(): void {
        this.canceled = true;
        this.abortController?.abort();
    }

    public then<TResult1 = T, TResult2 = never>(
        onFulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
    ): Promise<TResult1 | TResult2> {
        return this.promise.then(onFulfilled, onRejected);
    }

    public catch<TResult = never>(onRejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): Promise<T | TResult> {
        return this.promise.catch(onRejected);
    }

    public finally(onFinally: (() => void) | undefined | null): Promise<T> {
        return this.promise.finally(onFinally);
    }
}

