import classNames from 'classnames';
import React, { useMemo } from 'react';
import Badge from '../../../../shared/components/badge/badge';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import Spinner from '../../../../shared/components/spinner/spinner';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { formatNumber, roundNumber } from '../../../../shared/utils/number-utils';
import { getMainCurrency } from '../../../currency/currency-service';
import { PROPOSAL_VOTE_OPTIONS, ProposalVotesSummary } from '../../governance-types';
import ProposalStatusBadge from '../../proposal-status-badge/proposal-status-badge';
import { useProposal } from '../proposal-context';
import './votes-summary.scss';

const VotesSummary: React.FC = () => {
    const { network, proposalState, currentQuorum } = useProposal();

    const mainCurrency = useMemo(() => getMainCurrency(network), [ network ]);

    const defaultEmptySummary = useMemo(() => PROPOSAL_VOTE_OPTIONS
        .reduce((current, option) => ({ ...current, [option]: { part: 0, amount: 0 } }), {} as ProposalVotesSummary), []);

    const mostAnsweredOption = useMemo(() => {
        const summary = proposalState.summary;
        if (!summary) {
            return undefined;
        }
        return PROPOSAL_VOTE_OPTIONS
            .reduce((current, option) => summary[option].amount > summary[current].amount ? option : current, 'YES');
    }, [ proposalState.summary ]);

    const { proposal, summary = defaultEmptySummary } = proposalState;
    if (!proposal) {
        return null;
    }

    return (
        <div className='votes-summary'>
            <h5 className='summary-header'>
                Proposal Results
                <span className='space' />
                {proposal.status === 'Voting Period' && currentQuorum < (proposalState.params?.quorum || 0) && (
                    <Badge
                        className='quorum-not-met-badge'
                        rounded
                        size='large'
                        label='Quorum Not Met'
                        color={getCssVariableValue('--red-rgb').split(',').map(Number)}
                    />
                )}
                <ProposalStatusBadge status={proposal.status} size='large' />
            </h5>

            <div className='vote-options-container'>
                {PROPOSAL_VOTE_OPTIONS.map((option) => (
                    <div
                        key={option}
                        className={classNames('vote-option', option.toLowerCase(), { 'most-answered': option === mostAnsweredOption })}
                    >
                        <span className='option-name'>
                            {option}
                            <span className='space' />
                            {roundNumber(summary[option].part * 100, 2)}%
                        </span>
                        <span className='space' />
                        <span className='option-amount'>
                            {formatNumber(summary[option].amount, { maximumSignificantDigits: 6 })}&nbsp;
                            {mainCurrency.displayDenom}
                        </span>
                        {/*<span className='votes-amount'>*/}
                        {/*    {proposalState.votesCountLoading ? <Spinner size='xs' /> :*/}
                        {/*        (!proposalState.votesCount ? '-' :*/}
                        {/*            `${formatNumber(Math.round(summary[option].part * proposalState.votesCount))} votes`)}*/}
                        {/*</span>*/}
                    </div>
                ))}
            </div>

            {proposal.status === 'Voting Period' && <>
                <h6 className='quorum-header'>
                    Turnout / Quorum
                    <InfoIndicator>
                        <b>Turnout</b><br />Current participation percentage out of total available voting power.<br /><br />
                        <b>Quorum</b><br />Required participation percentage out of total available voting power.
                    </InfoIndicator>
                </h6>
                <div className='quorum-progress'>
                    <div className='quorum-trace'>
                        {proposalState.params?.quorum && <>
                            <span
                                className={classNames('turnout-bar', { success: currentQuorum >= proposalState.params.quorum })}
                                style={{ width: `${currentQuorum * 100}%` }}
                            />
                            <span className='turnout-value'>{roundNumber(currentQuorum * 100, 1, true)}%</span>
                        </>}
                        {proposalState.params === undefined && proposalState.paramsLoading && <Spinner size='xs' className='quorum-loader' />}
                    </div>
                    <span className='quorum-value'>33.4%</span>
                </div>
            </>}
        </div>
    );
};

export default VotesSummary;
