import React, { ReactNode, useMemo } from 'react';
import Badge from '../../../../../../shared/components/badge/badge';
import Spinner from '../../../../../../shared/components/spinner/spinner';
import useWindowSize from '../../../../../../shared/hooks/use-window-size';
import { getCssVariableValue } from '../../../../../../shared/utils/color-utils';
import { BridgeTransfer } from '../../bridge-hisotry-types';
import './bridge-status-badge.scss';

interface BridgeStatusBadgeProps {
    transfer: BridgeTransfer;
}

const BridgeStatusBadge: React.FC<BridgeStatusBadgeProps> = ({ transfer }) => {
    const { isMobile } = useWindowSize();

    const statusColor = useMemo((): (number[] | undefined) => {
        switch (transfer.status) {
            case 'Success':
                return getCssVariableValue('--light-green-rgb').split(',').map(Number);
            case 'Failed':
                return getCssVariableValue('--red-rgb').split(',').map(Number);
        }
        return getCssVariableValue('--orange-rgb').split(',').map(Number);
    }, [ transfer ]);

    const renderLabel = (): ReactNode => {
        if (transfer.status === 'Pending' || transfer.status === 'BridgeTransferring' || transfer.status === 'IbcTransferring') {
            return <>
                <Spinner className='status-spinner' size='xs' />
                {transfer.status === 'BridgeTransferring' ? 'Transferring' :
                    transfer.status === 'IbcTransferring' ? 'IBC Transferring' : transfer.status}
            </>;
        }
        return transfer.status;
    };

    return (
        <Badge
            className='bridge-status-badge'
            size={isMobile ? 'small' : 'medium'}
            label={renderLabel()}
            color={statusColor}
        />
    );
};

export default BridgeStatusBadge;
