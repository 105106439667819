import React from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useAsset } from '../../../asset/asset-context';
import { getMaxDenomAmount } from '../../../currency/currency-service';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';

const TotalTvlProperty: React.FC = () => {
    const { network } = useNetworkDashboard();
    const { vsAsset } = useAsset();

    return (
        <Property label='TVL' na={network.status === 'IRO'}>
            <StatisticsChange
                period='day'
                currentValue={network.totalSupply?.value.tvl || 0}
                previousValue={network.totalSupply?.previousDayValue?.tvl || 0}
            >
                {formatPrice(getMaxDenomAmount(network.totalSupply?.value.tvl || 0, vsAsset?.currency))}
            </StatisticsChange>
        </Property>
    );
};

export default TotalTvlProperty;
