export const NETWORK_COMMENTS_PATH = 'network-comments';
export const NETWORK_COMMENT_LIKES_PATH = 'network-comment-likes';

export const DEFAULT_COMMENTS_PAGE_LIMIT = 10;

export enum SortType {TOP = 'Top', RECENT = 'Recent'}

export interface NetworkComment {
    id: string;
    body: string;
    date: number;
    likes: number;
    networkId: string;
    image?: string;
    relatedTo?: string;
    userId?: string;
}

export interface NetworkCommentLike {
    userId: string;
    commentId: string;
    date: number;
}

