import React, { ReactElement, useMemo } from 'react';
import './bridge-transfer-dialog.scss';
import Button from '../../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import Icon from '../../../../../shared/components/icon/icon';
import InfoIndicator from '../../../../../shared/components/info-indicator/info-indicator';
import Link from '../../../../../shared/components/link/link';
import { ReactComponent as ExternalLinkIcon } from '../../../../../assets/icons/external-link.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy.svg';
import useCopyToClipboard from '../../../../../shared/hooks/use-copy-to-clipboard';
import { getTimeLeftText, getTimeOffset } from '../../../../../shared/utils/date-utils';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { filterNonEmptyValues } from '../../../../../shared/utils/object-utils';
import { useAsset } from '../../../../asset/asset-context';
import { getCurrencyLogoPath } from '../../../../currency/currency-service';
import { useNetwork } from '../../../../network/network-context';
import { getNetworkLogoPath } from '../../../../network/network-service';
import { convertToHexAddress } from '../../../../wallet/wallet-service';
import { BridgeTransfer } from '../bridge-hisotry-types';
import Addresses from '../../../../../shared/components/addresses/addresses';
import BridgeStatusBadge from './bridge-status-badge/bridge-status-badge';

interface BridgeTransferDialogProps extends DialogProps {
    transfer: BridgeTransfer;
}

const BridgeTransferDialog: React.FC<BridgeTransferDialogProps> = ({ transfer, ...otherProps }) => {
    const { networks, getNetwork } = useNetwork();
    const { vsAsset, mainAssetMap, getAssetLink } = useAsset();
    const copyToClipboard = useCopyToClipboard();

    const sourceNetwork = useMemo(
        () => networks.find((network) =>
            network.type === 'EVM' ? Number(network.evm?.chainId).toString() === transfer.sourceId : network.chainId === transfer.sourceId),
        [ networks, transfer.sourceId ],
    );
    const destinationNetwork = useMemo(
        () => networks.find((network) => network.type === 'EVM' ?
            Number(network.evm?.chainId).toString() === transfer.destinationId : network.chainId === transfer.destinationId),
        [ networks, transfer.destinationId ],
    );

    const intermediateNetwork = useMemo(
        () => transfer.intermediateId ? getNetwork(transfer.intermediateId) : undefined,
        [ getNetwork, transfer.intermediateId ],
    );

    const renderHashProperty = (hash: string, hashLabel: string, baseExploreTxUrl?: string, info?: string): ReactElement => {
        const exploreTxUrl = baseExploreTxUrl ? baseExploreTxUrl + hash : undefined;
        return (
            <div className='transfer-property'>
                <span className='property-label'>
                    {hashLabel}{info && <InfoIndicator indicatorSize='small'>{info}</InfoIndicator>}
                </span>
                <span className='property-value external-link-property' onClick={() => window.open(exploreTxUrl, '_blank')}>
                    <span className='ellipsis'>{hash}</span>
                    {exploreTxUrl && <Icon className='external-link-icon'><ExternalLinkIcon /></Icon>}
                    <Button
                        size='small'
                        buttonType='icon'
                        onClick={(event) => {
                            event.stopPropagation();
                            copyToClipboard(hash, 'Transaction Hash');
                        }}
                    >
                        <CopyIcon />
                    </Button>
                </span>
            </div>
        );
    };

    const renderStatusProperty = (): ReactElement => {
        return (
            <div className='transfer-property'>
                <span className='property-label'>Status</span>
                <span className='property-value'>
                    <BridgeStatusBadge transfer={transfer} />
                </span>
            </div>
        );
    };

    const renderTimeProperty = (): ReactElement => {
        const timeOffset = getTimeOffset(transfer.time);
        return (
            <div className='transfer-property date-property'>
                <span className='property-label'>
                    Time<InfoIndicator indicatorSize='small'>The transaction's creation timestamp</InfoIndicator>
                </span>
                <span className='property-value'>
                    {new Date(transfer.time).toUTCString().replace('GMT', 'UTC')}
                    <span className='secondary-text time-left'>
                        ({getTimeLeftText(timeOffset, 'single', true)} ago)
                    </span>
                </span>
            </div>
        );
    };

    const renderNetworkAddressProperty = (direction: 'Source' | 'Destination' | 'Intermediate'): ReactElement => {
        const network = direction === 'Source' ? sourceNetwork : direction === 'Destination' ? destinationNetwork : intermediateNetwork;
        const address = direction === 'Source' ? transfer.sourceAddress :
            direction === 'Destination' ? transfer.destinationAddress : transfer.intermediateAddress;
        const hexAddress = address && network?.type === 'Hub' ? convertToHexAddress(address) : undefined;
        const addresses = filterNonEmptyValues([ address, hexAddress ]);
        const networkAsset = network && mainAssetMap?.[network.chainId];
        const networkUrl = network?.type === 'Hub' ? '/dymension/dashboard' : networkAsset ? getAssetLink(networkAsset) : undefined;
        return (
            <div className='transfer-property network-address-property'>
                <span className='property-label'>{direction}</span>
                <span className='property-value'>
                    {network?.chainName && <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />}
                    <span className='name-address'>
                        <Link className='internal-link' inline url={networkUrl}>{network?.chainName}</Link>
                        <span className='address'>(<Addresses canCopy addresses={addresses} />)</span>
                    </span>
                </span>
            </div>
        );
    };

    const renderAmountProperty = (): ReactElement => {
        const assetUrl = !vsAsset ? '' : getAssetLink(vsAsset);

        return (
            <div className='transfer-property'>
                <span className='property-label'>Amount</span>
                <span className='property-value'>
                    {vsAsset?.network &&
                        <img className='asset-logo' src={getCurrencyLogoPath(vsAsset.currency, vsAsset.network)} alt='asset logo' />}
                    <Link inline url={assetUrl} className='internal-link'>
                        {formatPrice(transfer.amount, vsAsset?.currency.displayDenom)}
                    </Link>
                </span>
            </div>
        );
    };

    return (
        <Dialog closable className='bridge-transfer-dialog' {...otherProps}>
            <DialogTitle>Transfer Details</DialogTitle>
            <DialogContent className='dialog-content'>
                {transfer.ibcHash && renderHashProperty(
                    transfer.ibcHash,
                    'IBC Transaction Hash',
                    sourceNetwork?.exploreTxUrl,
                    'The transaction hash for the transfer from the source chain to the intermediate chain.',
                )}
                {transfer.hash && renderHashProperty(
                    transfer.hash,
                    'Transaction Hash',
                    transfer.intermediateId ? intermediateNetwork?.exploreTxUrl : sourceNetwork?.exploreTxUrl,
                )}
                {renderStatusProperty()}
                {renderTimeProperty()}
                <span className='border' />
                {renderNetworkAddressProperty('Source')}
                {transfer.intermediateId && renderNetworkAddressProperty('Intermediate')}
                {renderNetworkAddressProperty('Destination')}
                {renderAmountProperty()}
            </DialogContent>
        </Dialog>
    );
};

export default BridgeTransferDialog;
