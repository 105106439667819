import { RouteResponse } from '@skip-go/client';
import { CoinsAmount } from '../../currency/currency-types';
import { Network } from '../../network/network-types';
import { TxResponse } from '../../tx/tx-types';

export interface BridgeState {
    fee?: CoinsAmount;
    route?: RouteResponse;
    intermediateNetwork?: Network;
    intermediateNetworkAddress?: string;
    routeSearching?: boolean;
    broadcasting?: boolean;
    toUseBridge?: boolean;
    response?: TxResponse;
    error?: any;
}

export type BridgeAction =
    { type: 'set-fee', payload: CoinsAmount | undefined } |
    { type: 'set-route', payload: RouteResponse | undefined } |
    { type: 'set-intermediate-network', payload: Network | undefined } |
    { type: 'set-intermediate-network-address', payload: string | undefined } |
    { type: 'set-route-searching', payload?: boolean } |
    { type: 'set-broadcasting', payload?: boolean } |
    { type: 'set-to-use-bridge', payload?: boolean } |
    { type: 'set-response', payload?: TxResponse } |
    { type: 'set-error', payload: any };

export const bridgeStateReducer = (state: BridgeState, action: BridgeAction): BridgeState => {
    switch (action.type) {
        case 'set-route':
            return action.payload ?
                { ...state, route: action.payload, routeSearching: false } :
                { ...state, route: action.payload, routeSearching: false, fee: undefined, intermediateNetwork: undefined };
        case 'set-fee':
            return { ...state, fee: action.payload };
        case 'set-intermediate-network':
            return { ...state, intermediateNetwork: action.payload };
        case 'set-intermediate-network-address':
            return { ...state, intermediateNetworkAddress: action.payload };
        case 'set-route-searching':
            return { ...state, routeSearching: action.payload ?? true, response: undefined };
        case 'set-to-use-bridge':
            return { ...state, toUseBridge: action.payload ?? true };
        case 'set-broadcasting':
            return { ...state, broadcasting: action.payload ?? true, response: undefined, error: undefined };
        case 'set-response':
            return { ...state, broadcasting: false, response: action.payload, error: undefined };
        case 'set-error':
            return { ...state, broadcasting: false, response: undefined, error: action.payload };
        default:
            return state;
    }
};
