import React, { BaseHTMLAttributes, forwardRef, ReactNode, useRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as WarringIcon } from '../../../assets/icons/warning.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import Icon from '../icon/icon';
import './alert.scss';

interface AlertProps extends Omit<BaseHTMLAttributes<HTMLDivElement>, 'title'> {
    className?: string;
    title?: ReactNode;
    type?: 'info' | 'success' | 'warning' | 'error';
    children: ReactNode;
}

const Alert: React.ForwardRefRenderFunction<HTMLDivElement, AlertProps> = (
    { className, children, title, type = 'info' },
    forwardedRef,
) => {
    const divRef = useRef<HTMLDivElement>();

    const onRef = (element: HTMLDivElement): void => {
        divRef.current = element || undefined;
        if (typeof forwardedRef === 'function') {
            forwardedRef(element);
        } else if (forwardedRef) {
            forwardedRef.current = element;
        }
    };

    return (
        <div className={classNames('alert-container', className, type)} ref={onRef}>
            <span className='alert-icon-container'>
                <Icon>{type === 'success' ? <SuccessIcon /> : <WarringIcon />}</Icon>
            </span>
            {title ? <h5 className='alert-title'>{title}</h5> : null}
            {children}
        </div>
    );
};

export default forwardRef(Alert);

