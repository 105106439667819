import classNames from 'classnames';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';
import Dialog, { DialogContent, DialogTitle } from '../../../shared/components/dialog/dialog';
import Icon from '../../../shared/components/icon/icon';
import Spinner from '../../../shared/components/spinner/spinner';
import QuickAuthAction from '../../quick-auth/quick-auth-action/quick-auth-action';
import { useNetwork } from '../../network/network-context';
import Splashscreen from '../../splashscreen/splashscreen';
import { ReactComponent as WarringIcon } from '../../../assets/icons/warning.svg';
import { useSplashscreen } from '../../splashscreen/splashscreen-context';
import TermsAgreementDialog from '../../terms-agreement/terms-agreement-dialog';
import TopBar from '../../top-bar/top-bar';
import Snackbar from '../../../shared/components/snackbar/snackbar';
import { useWallet } from '../../wallet/wallet-context';
import { useSnackbar } from '../../../shared/components/snackbar/snackbar-context';
import Footer from '../../footer/footer';
import { WalletInfoMap } from '../../wallet/wallet-types';
import './app-layout.scss';

function AppLayout(): JSX.Element {
    const { showMessage, showErrorMessage, showWarningMessage } = useSnackbar();
    const { walletError } = useWallet();
    const { hubNetwork, loading } = useNetwork();
    const { loading: splashscreenLoading } = useSplashscreen();
    const [ phantomSettingsRequired, setPhantomSettingsRequired ] = useState(false);

    const testEnv = useMemo(() => process.env.REACT_APP_ENV !== 'mainnet' && process.env.REACT_APP_ENV !== 'mainnet-staging', []);

    // todo: handle in some global error handler
    // case 'UNSUPPORTED_CURRENCY':
    //     message.content = `Unsupported currency${networkSuffix}`;
    //     break;

    useEffect(() => {
        if (!walletError) {
            return;
        }
        const walletType = walletError.walletType;
        switch (walletError.code) {
            case 'FAILED_INTEGRATE_CHAIN':
                if (walletError.originalError?.message?.toLowerCase()?.includes('ledger is unsupported')) {
                    showErrorMessage(`${walletType || 'The wallet'} is currently not supporting
                     ${walletError.network ? walletError.network.chainName : 'this chain'} with Ledger.`);
                } else {
                    showErrorMessage(`Failed integrate ${walletError.network?.chainName || 'chain'} with ${walletType || 'the wallet'}`);
                }
                break;
            case 'FAILED_INIT_WALLET':
                showErrorMessage(`Failed init ${walletType || 'the wallet'}`);
                break;
            case 'KEY_NOT_FOUND':
                showWarningMessage(`Create or import an account in your ${walletType || 'wallet'}`);
                break;
            case 'NO_OFFLINE_SIGNER':
                showErrorMessage(`${walletType || 'The wallet'} account not detected`);
                break;
            case 'UNSUPPORTED_WALLET':
                showErrorMessage(`${walletType || 'The selected wallet is'} not supported`);
                break;
            case 'UPGRADE_WALLET':
                showWarningMessage(`Please use the recent version of ${walletType || 'the wallet'} extension`);
                break;
            case 'WALLET_NOT_CONNECTED':
                showWarningMessage(`No wallet connected for ${walletError.network?.chainName || 'the designated network'}`);
                break;
            case 'INSTALL_WALLET':
                const installUrl = walletType && WalletInfoMap[walletType].installUrl;
                if (walletType === 'MetaMask' && isMobile) {
                    showWarningMessage({ content: 'Open your MetaMask app and use the in-app browser' });
                } else {
                    showWarningMessage({
                        content: `${walletType || 'The'} wallet not installed`,
                        action: !installUrl ? undefined :
                            { label: 'Install', close: true, callback: () => window.open(installUrl, '_blank') },
                    });
                }
                break;
            case 'UNSUPPORTED_NETWORK':
                showErrorMessage(`Can't link ${walletType || 'the'} wallet to the designated network`);
                break;
            case 'ACCOUNTS_ALREADY_REQUESTED':
                showErrorMessage(`Connect to ${walletType || 'the wallet'}`);
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage(`Connecting to ${walletType || 'the wallet'} rejected by the user`);
                break;
            case 'SWITCH_NETWORK':
                showErrorMessage(
                    `Add or Switch to ${walletError.network?.chainName || 'the designated network'} on ${walletType || 'the wallet'}`,
                );
                break;
            case 'UNSUPPORTED_MESSAGE':
                if (walletType === 'Quick Auth') {
                    showWarningMessage('Quick Auth session has expired');
                } else {
                    showWarningMessage(`This message not supported by ${walletType || 'the wallet'}`);
                }
                break;
            case 'PHANTOM_SETTINGS_REQUIRED':
                setPhantomSettingsRequired(true);
                break;
            default:
                showWarningMessage(`${walletType || 'Wallet'} connection failed, please try again later`);
        }
    }, [ walletError, showMessage, showErrorMessage, showWarningMessage ]);

    const renderPhantomSettingsRequiredDialog = (): ReactElement => {
        return (
            <Dialog closable className='phantom-alert-dialog' onRequestClose={() => setPhantomSettingsRequired(false)}>
                <DialogTitle className='dialog-title'><Icon><WarringIcon /></Icon>Phantom Alert</DialogTitle>
                <DialogContent className='dialog-content'>
                    Phantom is currently set as your default EVM wallet which prevents you from interacting with dymension. Unless configured otherwise you cannot continue to interact w/ Dymension.
                    <br /><br />
                    To proceed with a different extension, turn off Settings → Default App Wallet, and select MetaMask. Then refresh the page and reconnect.
                </DialogContent>
            </Dialog>
        );
    };

    return (
        <div className={classNames('app-layout', { 'test-env': testEnv })}>
            {splashscreenLoading ? <Splashscreen /> : <>
                <div className='test-env-bar'>{hubNetwork?.chainId && <>Dymension&nbsp;<b>{hubNetwork.chainId}</b>&nbsp;Testnet</>}</div>
                <TopBar />
                <main>{!loading ? <Outlet /> : <Spinner className='app-loader' />}</main>
                <QuickAuthAction />
                <Footer />
                <TermsAgreementDialog />
            </>}
            <Snackbar />
            {phantomSettingsRequired && renderPhantomSettingsRequiredDialog()}
        </div>
    );
}

export default AppLayout;
