import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Icon from '../icon/icon';
import Tooltip, { TooltipBounds, TooltipPlacement } from '../tooltip/tooltip';
import { ReactComponent as InfoOutlineIcon } from '../../../assets/icons/info-outline.svg';
import { ButtonProps } from '../button/button';
import '../button/button.scss';
import './info-indicator.scss';

interface InfoIndicatorProps {
    children: ReactNode;
    className?: string;
    bounds?: TooltipBounds;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: string;
    indicatorSize?: ButtonProps['size'];
}

const InfoIndicator: React.FC<InfoIndicatorProps> = ({
    children,
    tooltipPlacement,
    bounds,
    className,
    tooltipClassName,
    indicatorSize,
}) => {
    return (
        <Tooltip className={classNames('info-indicator', tooltipClassName)} placement={tooltipPlacement} title={children} bounds={bounds}>
            <a className={classNames('button icon info-indicator-icon', className, indicatorSize)}><Icon><InfoOutlineIcon /></Icon></a>
        </Tooltip>
    );
};

export default InfoIndicator;
