import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { getShortenedAddress } from '../../../../../shared/utils/text-utils';
import PathNav, { PathNavItem } from '../../../../path-nav/path-nav';
import IbcTransferStatus from '../ibc-transfer-status';
import { useIbcTransferStatus } from '../use-ibc-transfer-status';
import './ibc-transfer-status-page.scss';

const IbcTransferStatusPage: React.FC = () => {
    const { id } = useParams();
    const { transfer, loading } = useIbcTransferStatus({ transferId: id });

    if (transfer === undefined && loading) {
        return <Spinner size='large' className='ibc-transfer-status-loader' />;
    }
    if (!transfer) {
        return <h5 className='not-found-message'>IBC Transfer not found</h5>;
    }
    return (
        <div className='page ibc-transfer-status'>
            <PathNav>
                <PathNavItem label='Transfer'  />
                <PathNavItem label='History' url='/ibc/status' />
                <PathNavItem label={getShortenedAddress(transfer.hash, 6, 6)} />
            </PathNav>
            <h5 className='ibc-transfer-status-header'>Transfer Details</h5>
            <IbcTransferStatus transfer={transfer} />
        </div>
    );
};

export default IbcTransferStatusPage;
