import { DeliverTxResponse } from 'cosmjs/packages/stargate';
import { ReactElement, ReactNode } from 'react';
import { Network } from '../network/network-types';
import { CoinsAmount } from '../currency/currency-types';
import { EncodeObject } from 'cosmjs/packages/proto-signing';

export type TxMessagesCreator = (fee?: CoinsAmount, params?: any, broadcasting?: boolean) => EncodeObject[];

export type EvmContractCreator = (messages: EncodeObject[]) => undefined | ContractMessage;

export enum DeliveryTxCode {
    SUCCESS = 0,
    INSUFFICIENT_FUNDS = 5,
    OUT_OF_GAS = 11,
    UNKNOWN = 10000
}

export interface ContractMessage {
    address: string;
    data: string;
    value?: string;
}

export interface TxResponse {
    hash: string;
    network: Network;
    deliveryTxCode: DeliveryTxCode | number;
    nativeResponse?: DeliverTxResponse;
    params?: any;
}

export type SignMethod = 'eip712' | 'direct' | 'amino';

export interface Fee {
    label: ReactNode;
    value?: ReactElement | CoinsAmount | string | number;
    loading?: boolean;
}
