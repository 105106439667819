import classNames from 'classnames';
import React, { useMemo } from 'react';
import Property from '../../../../../shared/components/property/property';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { AccountNetworkState } from '../../../account-network-state';
import { useAmm } from '../../../../amm/amm-context';
import { getPositionPart } from '../../../../amm/amm.service';
import './account-total-liquidity-value.scss';

interface AccountTotalLiquidityValueProps {
    className?: string;
    networkState: AccountNetworkState;
}

const AccountTotalLiquidityValue: React.FC<AccountTotalLiquidityValueProps> = ({ className, networkState }) => {
    const { ammState, getPoolLiquidity } = useAmm();

    const totalLiquidityValue = useMemo(() => ammState.pools?.reduce((current, pool) => {
        if (!pool.position) {
            return current;
        }
        const liquidity = getPoolLiquidity(pool) || 0;
        const sharesPart = getPositionPart(pool);
        return current + (liquidity * sharesPart);
    }, 0), [ ammState.pools, getPoolLiquidity ]);

    return (
        <Property
            label='Total Liquidity'
            className={classNames('account-total-liquidity-value', className)}
            valueClassName='property-value'
            labelClassName='property-label'
        >
            {totalLiquidityValue === undefined && (ammState.loading || ammState.positionsLoading) ?
                <Spinner /> : formatPrice(totalLiquidityValue || 0, undefined, undefined, 10)}
        </Property>
    );
};

export default AccountTotalLiquidityValue;
