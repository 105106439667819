import { Duration } from '../client/station-clients/dymension/generated/google/protobuf/duration';
import { CoinsAmount } from '../currency/currency-types';

export const LOCK_DEFAULT_DURATION: Duration = { seconds: 60, nanos: 0 };

export const EPOCH_IDENTIFIERS = [
    'minute',
    'hour',
    'hourly',
    'day',
    'daily',
    'week',
    'weekly',
    'month',
    'monthly',
    'year',
    'yearly',
] as const;
export type EpochIdentifier = typeof EPOCH_IDENTIFIERS[number];

export interface Incentive {
    denom: string;
    coins: CoinsAmount[];
    distributedCoins: CoinsAmount[];
    startTime: Date;
    endTime: Date;
    epoch: EpochIdentifier;
    yearPart: number;
}

export interface IncentivesParams {
    epochIdentifier: EpochIdentifier;
}
