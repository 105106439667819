import { readStream } from '../../shared/utils/file-utils';
import { filterNonEmptyValues } from '../../shared/utils/object-utils';
import { Asset } from '../asset/asset-types';
import { getFixedDenom, getMaxDenomAmount, isDenomsEquals } from '../currency/currency-service';
import { Currency } from '../currency/currency-types';
import { createIroDenom } from '../iro/iro-service';
import { Trade, TradeBase, TradeGroup, TradeGroupPeriod } from './types';

const TRADE_BASE_FIELDS: (keyof TradeBase)[] = [
    'id',
    'tokenInDenom',
    'tokenInAmount',
    'tokenInNetwork',
    'tokenOutDenom',
    'tokenOutAmount',
    'tokenOutNetwork',
    'time',
    'usdVolume',
];

const TRADE_FIELDS: (keyof Trade)[] = [
    ...TRADE_BASE_FIELDS,
    'type',
    'address',
    'blockHeight',
    'hash',
];

const TRADE_GROUP_FIELDS: (keyof TradeGroup)[] = [
    ...TRADE_BASE_FIELDS,
    'low',
    'high',
    'open',
    'close',
    'usdLow',
    'usdHigh',
    'usdOpen',
    'usdClose',
    'period',
];

const TRADE_NUMBER_FIELDS: (keyof (Trade & TradeGroup))[] = [
    'time',
    'blockHeight',
    'usdVolume',
    'open',
    'close',
    'high',
    'low',
    'usdOpen',
    'usdClose',
    'usdHigh',
    'usdLow',
    'period',
];

const TRADE_BIGINT_FIELDS: (keyof (Trade & TradeGroup))[] = [
    'tokenInAmount',
    'tokenOutAmount',
];

export type TradesResponse<T> = { totalCount?: number, nodes?: { [key in (keyof T)]: string }[] };

export const loadTrades = async (
    hubId: string,
    asset?: Asset,
    vsAsset?: Asset,
    page?: number,
    pageSize?: number,
    signal?: AbortSignal,
): Promise<{ records: Trade[], count: number }> => {
    const assetDenoms = !asset ? undefined : (asset.network.type === 'Hub' ? [ asset.currency.baseDenom ] :
        filterNonEmptyValues([ asset.currency.type === 'main' && createIroDenom(asset.networkId), asset.ibc?.representation ]))
        .map((denom) => `"${denom}"`).join(',');
    const vsDenom = !vsAsset ? undefined : getFixedDenom(vsAsset);
    const query = `{
        trades(
            filter: {
                network: { equalTo: "${hubId}" },
                ${assetDenoms ? `or: [
                    { tokenInDenom: { in: [ ${assetDenoms} ] }, ${vsDenom ? `tokenOutDenom: { equalTo: "${vsDenom}" }` : ''} },
                    { tokenOutDenom: { in: [ ${assetDenoms} ] }, ${vsDenom ? `tokenInDenom: { equalTo: "${vsDenom}" }` : ''} }
                ]` : ''}
            },
            orderBy: TIME_DESC,
            ${page !== undefined && pageSize !== undefined ? `
                first: ${pageSize},
                offset: ${page * pageSize}
            ` : ''}
        ) { totalCount, nodes { ${TRADE_FIELDS.join(' ')} } }
    }`;
    const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
        signal,
    });
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const tradesResponse = JSON.parse(responseText || '{}');
    return fetchTradeOrGroupFromResponse(tradesResponse.data.trades as TradesResponse<Trade>, TRADE_FIELDS);
};

export const loadTradeGroups = async (
    hubId: string,
    asset: Asset,
    vsAsset: Asset,
    period: TradeGroupPeriod,
    toTime?: number,
    signal?: AbortSignal,
): Promise<{ records: TradeGroup[], count: number }> => {
    const assetDenoms = (asset.network.type === 'Hub' ? [ asset.currency.baseDenom ] :
        filterNonEmptyValues([
            asset.currency.type === 'main' && createIroDenom(asset.networkId),
            asset.ibc?.representation,
        ])).map((denom) => `"${denom}"`);
    const vsDenom = getFixedDenom(vsAsset);
    const query = `{
        tradesGroups(
            filter: {
                network: { equalTo: "${hubId}" },
                period: { equalTo: ${period} }
                or: [
                    { tokenInDenom: { in: [ ${assetDenoms} ] }, tokenOutDenom: { equalTo: "${vsDenom}" } },
                    { tokenInDenom: { equalTo: "${vsDenom}" }, tokenOutDenom: { in: [ ${assetDenoms} ] } },
                ],
                ${toTime ? `time: { lessThanOrEqualTo: "${toTime}" }` : ''}
            },
            orderBy: TIME_DESC
        ) { totalCount, nodes { ${TRADE_GROUP_FIELDS.join(' ')} } }
    }`;
    const response = await fetch(process.env.REACT_APP_SUBQUERY_URL || '', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
        signal,
    });
    const responseText = response?.body ? await readStream(response.body).catch(() => '') : undefined;
    const tradesResponse = JSON.parse(responseText || '{}');
    return fetchTradeOrGroupFromResponse(tradesResponse.data.tradesGroups as TradesResponse<TradeGroup>, TRADE_GROUP_FIELDS);
};

export const getFixedTradeBase = <T extends TradeBase>(trade: T, assets: Asset[], vsCurrency?: Currency): T | undefined => {
    const assetIn = assets.find((asset) => isDenomsEquals(asset, trade.tokenInDenom, undefined, true));
    const assetOut = assets.find((asset) => isDenomsEquals(asset, trade.tokenOutDenom, undefined, true));
    if (!assetIn || !assetOut) {
        return undefined;
    }
    return {
        ...trade,
        tokenIn: {
            ...assetIn,
            baseAmount: trade.tokenInAmount,
            amount: getMaxDenomAmount(Number(trade.tokenInAmount), assetIn.currency),
        },
        tokenOut: {
            ...assetOut,
            baseAmount: trade.tokenOutAmount,
            amount: getMaxDenomAmount(Number(trade.tokenOutAmount), assetOut.currency),
        },
        usdVolume: getMaxDenomAmount(trade.usdVolume, vsCurrency),
        tokenInNetwork: trade.tokenInNetwork || assetIn.networkId,
        tokenOutNetwork: trade.tokenOutNetwork || assetOut.networkId,
    };
};

const fetchTradeOrGroupFromResponse = <T = (Trade | TradeGroup)>(
    response: TradesResponse<T>,
    fields: (keyof T)[],
): { records: T[], count: number } => {
    const records = (response.nodes || []).map((node) => fields.reduce((current, field) => {
        let value: string | number | bigint = node[field];
        if (TRADE_BIGINT_FIELDS.includes(field as any)) {
            value = BigInt(value);
        } else if (TRADE_NUMBER_FIELDS.includes(field as any)) {
            value = Number(value);
        }
        return { ...current, [field]: value };
    }, {} as T));
    return { records, count: response.totalCount || records.length };
};

