import React from 'react';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../shared/components/dialog/dialog';
import useHandleTxResponses from '../../tx/use-handle-tx-responses';
import { useWallet } from '../../wallet/wallet-context';
import { SponsorshipRecord } from '../sponsorship-types';
import { useSponsorshipRevoke } from './use-sponsorship-revoke';
import './sponsorship-revoke-dialog.scss';

interface SponsorshipRevokeDialogProps extends DialogProps {
    sponsorship: SponsorshipRecord;
}

const SponsorshipRevokeDialog: React.FC<SponsorshipRevokeDialogProps> = ({ sponsorship, ...dialogProps }) => {
    const { hubWallet } = useWallet();
    const { txState, broadcast } = useSponsorshipRevoke(sponsorship);
    useHandleTxResponses(txState, hubWallet, dialogProps.onRequestClose);

    const getSponsorshipName = (): string => {
        if (sponsorship.rollapp) {
            return sponsorship.rollapp.chainName;
        } else if (sponsorship.pool) {
            const poolAssets = sponsorship.pool.assets;
            return `${poolAssets[0]?.currency.displayDenom} / ${poolAssets[1]?.currency.displayDenom}`;
        }
        return '';
    };

    return (
        <Dialog className='sponsorship-revoke-dialog' closable {...dialogProps}>
            <DialogTitle>{`Revoke ${getSponsorshipName()} endorsement`}</DialogTitle>
            <DialogContent className='dialog-content'>
                Are you sure you want to revoke your endorsement?
            </DialogContent>
            <DialogAction secondary close>Cancel</DialogAction>
            <DialogAction
                primary
                className='revoke-button'
                loading={txState.broadcasting}
                disabled={txState.broadcasting || txState.feeLoading}
                onClick={() => broadcast()}
            >
                Revoke
            </DialogAction>
        </Dialog>
    );
};

export default SponsorshipRevokeDialog;
