import React from 'react';
import useHandleTxResponses from '../../tx/use-handle-tx-responses';
import { useWallet } from '../../wallet/wallet-context';
import { useStaking } from '../staking-context';
import { TotalValidators } from '../validator/total-validators/total-validators';
import StakedValidators from '../validator/staked-validators/staked-validators';
import StakingAnalytics from '../staking-analytics/staking-analytics';
import './stake-dashboard.scss';

export default function StakeDashboard(): JSX.Element {
    const { networkWalletMap } = useWallet();
    const { rewardsTxState, totalValidatorsData, network } = useStaking();
    useHandleTxResponses(rewardsTxState, networkWalletMap[network.chainId]);

    return (
        <div className='stake-dashboard'>
            <StakingAnalytics />

            <StakedValidators />

            {totalValidatorsData ? <TotalValidators validatorsData={totalValidatorsData} /> : null}
        </div>
    );
}

