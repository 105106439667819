import React, { cloneElement, ReactElement, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ClearIcon } from '../../../assets/icons/clear.svg';
import Button, { ButtonProps } from '../button/button';
import Overlay, { OverlayProps } from '../overlay/overlay';
import { getChildrenByTypes } from '../../utils/react-utils';
import useKeyPress from '../../hooks/use-key-press';
import './dialog.scss';

interface DialogTitleProps {
    children: ReactNode;
    className?: string;
}

interface DialogFooterProps {
    children: ReactNode;
    className?: string;
}

interface DialogContentProps {
    children: ReactNode;
    className?: string;
}

interface DialogActionProps extends ButtonProps {
    children: ReactNode;
    primary?: boolean;
    secondary?: boolean;
    close?: boolean;
}

export interface DialogProps extends OverlayProps {
    onRequestClose?: () => void;
    closable?: boolean;
    preventCloseOnEscape?: boolean;
}

export function DialogTitle({ children, className }: DialogTitleProps): JSX.Element {
    return <h4 className={classNames('dialog-title', className)}>{children}</h4>;
}

export function DialogFooter({ children, className }: DialogFooterProps): JSX.Element {
    return <div className={classNames('dialog-footer', className)}>{children}</div>;
}

export function DialogContent({ children, className }: DialogContentProps): JSX.Element {
    return className ? <div className={className}>{children}</div> : <>{children}</>;
}

export function DialogAction({ primary, secondary, close, ...buttonProps }: DialogActionProps): JSX.Element {
    return <Button buttonType={primary ? 'primary' : 'secondary'}  {...buttonProps} />;
}

const Dialog: React.FC<DialogProps> = ({ onRequestClose, className, preventCloseOnEscape, closable, children, ...overlayProps }) => {
    const isEscapePressed = useKeyPress('Escape');
    const [ escapePressed, setEscapePressed ] = useState(false);

    useEffect(() => {
        if (isEscapePressed) {
            setEscapePressed(true);
        }
    }, [ isEscapePressed ]);

    useEffect(() => {
        if (escapePressed) {
            setEscapePressed(false);
        }
    }, [ escapePressed ]);

    useEffect(() => {
        if (!preventCloseOnEscape && escapePressed && onRequestClose) {
            onRequestClose();
        }
    }, [ preventCloseOnEscape, escapePressed, onRequestClose ]);

    const renderOverlayContent = (): ReactElement => {
        const dialogTitle = getChildrenByTypes(children, DialogTitle);
        const dialogContent = getChildrenByTypes(children, DialogContent);
        const dialogActions = getChildrenByTypes(children, DialogAction);
        const dialogFooter = getChildrenByTypes(children, DialogFooter);

        return <>
            {dialogTitle || closable ? (
                <header className='dialog-header'>
                    {dialogTitle}
                    <span className='space' />
                    {closable && <Button buttonType='icon' className='close-button' onClick={onRequestClose}><ClearIcon /></Button>}
                </header>
            ) : null}
            {dialogContent}
            <div className='dialog-actions'>
                {dialogActions.map((action) => cloneElement(action, {
                    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
                        action.props.onClick?.(event);
                        if (action.props.close) {
                            onRequestClose?.();
                        }
                    },
                }))}
            </div>
            {dialogFooter}
        </>;
    };

    return (
        <Overlay className={classNames('dialog section', className)} dimContainer {...overlayProps}>
            {renderOverlayContent()}
        </Overlay>
    );
};

export default Dialog;
