import classNames from 'classnames';
import React, { ReactNode } from 'react';
import NetworkComments from '../network-comments';
import PostComment from '../post-comment/post-comment';
import './stickable-network-comments.scss';

interface StickableNetworkCommentsProps {
    sticky?: boolean;
    className?: string;
    children?: ReactNode;
}

const StickableNetworkComments: React.FC<StickableNetworkCommentsProps> = ({ sticky, children, className }) => {
    return (
        <div className={classNames('stickable-network-comments', className, { sticky })}>
            {children}
            {sticky && <NetworkComments className='comments' />}
            <PostComment className='post-comment-section' expandedAlways={!sticky} />
            {!sticky && <NetworkComments className='comments' />}
        </div>
    );
};

export default StickableNetworkComments;

