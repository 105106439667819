import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect } from 'react';
import Alert from '../../../shared/components/alert/alert';
import InfoIndicator from '../../../shared/components/info-indicator/info-indicator';
import Spinner from '../../../shared/components/spinner/spinner';
import TabBar, { Tab } from '../../../shared/components/tabs/tab-bar';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getTimeLeftText, getTimeOffset } from '../../../shared/utils/date-utils';
import { useLayout } from '../../app/layout/layout-context';
import IroBuySell from '../../iro/iro-buy-sell/iro-buy-sell';
import { useIRO } from '../../iro/iro-context';
import HolderList from '../holder/holder-list';
import StickableNetworkComments from '../../network/network-comments/stickable-network-comments/stickable-network-comments';
import TradeList from '../../trade/trade-list/trade-list';
import PoolList from '../../amm/pools/pool-list/pool-list';
import SwapBuySell from '../../amm/tokens-swap/swap-buy-sell/swap-buy-sell';
import { Asset } from '../asset-types';
import { useHolderList } from '../holder/use-holder-list';
import ScrollingTradePairs from '../scrolling-trade-pairs/scrolling-trade-pairs';
import AssetInfo from './asset-info/asset-info';
import AssetStatistics from './asset-statistics/asset-statistics';
import BondingCurveChart from './charts/bonding-curve-chart/bonding-curve-chart';
import PriceChart from './charts/price-chart/price-chart';
import './asset-dashboard.scss';

interface AssetDashboardProps {
    asset: Asset;
}

const AssetDashboard: React.FC<AssetDashboardProps> = ({ asset }) => {
    const { width, isTablet } = useWindowSize();
    const { setFooterClassName, setCtaClassName } = useLayout();
    const { getIroPlan } = useIRO();
    const { countLoading, totalCount } = useHolderList(asset);

    useEffect(() => {
        setFooterClassName('asset-dashboard-footer');
        setCtaClassName('asset-dashboard-cta');
        return () => {
            setFooterClassName();
            setCtaClassName();
        };
    }, [ setCtaClassName, setFooterClassName ]);

    const renderBuySell = useCallback((detailsExpanded?: boolean): ReactNode => {
        let unavailableReason = '';
        if (asset.network.status === 'IRO') {
            const iroPlan = getIroPlan(asset.networkId);
            const startTime = iroPlan?.startTime ? new Date(iroPlan.startTime) : new Date();
            if (!iroPlan) {
                unavailableReason = 'No IRO plan';
            } else if (startTime > new Date()) {
                unavailableReason = `IRO start in ${getTimeLeftText(getTimeOffset(startTime), undefined, true)}`;
            } else {
                return <IroBuySell detailsExpanded={detailsExpanded} asset={asset} className='buy-sell-section' />;
            }
        } else if (asset.pools.length) {
            return <SwapBuySell detailsExpanded={detailsExpanded} asset={asset} className='buy-sell-section' />;
        } else {
            unavailableReason = 'No liquidity pool';
        }
        return (
            <Alert type='warning' className='buy-sell-section'>
                <b>Trading is currently unavailable</b><br />
                <span className='unavailable-reason'>{unavailableReason}</span>
            </Alert>
        );
    }, [ asset, getIroPlan ]);

    return (
        <div
            className={classNames('asset-dashboard', { 'no-comments': asset.network.type !== 'RollApp' })}
        >
            <AssetStatistics className='asset-statistics' vertically={width > 1440} wide={width <= 1440} asset={asset} />

            <PriceChart className='asset-price-chart' asset={asset} />

            {asset.network.type === 'RollApp' && !isTablet ? (
                <StickableNetworkComments className='trading-section' sticky>
                    {renderBuySell(false)}
                </StickableNetworkComments>
            ) : (
                <div className='trading-section'>
                    <h5 className='subtitle trading-title'>Trade</h5>
                    {renderBuySell(!isTablet)}
                </div>
            )}

            <div className='asset-info-section'>
                <h5 className='subtitle'>Info</h5>
                <AssetInfo asset={asset} />
            </div>

            <div className='asset-middle-section'>
                {asset.network.status === 'IRO' && (
                    <div className='bonding-curve-section'>
                        <h5 className='subtitle'>Bonding Curve</h5>
                        <BondingCurveChart asset={asset} />
                    </div>
                )}

                <TabBar className='asset-data-lists-section'>
                    <Tab label='Trades' tabKey='trades'><TradeList asset={asset} /></Tab>
                    {asset.network.status === 'IRO' ? (
                        <Tab
                            label={(
                                <span className='horizontally-centered'>
                                    Holders&nbsp;{countLoading ? <Spinner size='xs' /> : `(${totalCount || 0})`}
                                    <InfoIndicator>Top 100 IRO Holders</InfoIndicator>
                                </span>
                            )}
                            tabKey='holders'
                        >
                            <HolderList asset={asset} />
                        </Tab>
                    ) : <Tab label='Pools' tabKey='pools'><PoolList asset={asset} /></Tab>}
                </TabBar>
            </div>

            {asset.network.type === 'RollApp' && isTablet && (
                <StickableNetworkComments className='comments-section'>
                    <h5 className='subtitle'>Community</h5>
                </StickableNetworkComments>
            )}

            <ScrollingTradePairs className='scrolling-trade-pairs' />
        </div>
    );
};

export default AssetDashboard;
