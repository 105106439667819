import classNames from 'classnames';
import React from 'react';
import { formatNumber } from '../../../shared/utils/number-utils';
import { Asset } from '../../asset/asset-types';
import './iro-progress.scss';

interface IroProgressProps {
    asset: Asset;
    className?: string;
}

const IroProgress: React.FC<IroProgressProps> = ({ asset, className }) => {
    return (
        <div className={classNames('bonding-curv-progress-container', className)}>
            <progress value={asset.iroProgress} max={100} className='bonding-curv-progress' />
            <span className='bonding-curv-progress-value'>
                {formatNumber(asset.iroProgress, asset.iroProgress < 1 ? { maximumSignificantDigits: 2 } : { maximumFractionDigits: 2 })}%
            </span>
        </div>
    );
};

export default IroProgress;
