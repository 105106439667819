import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../shared/components/button/button';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../shared/components/form-controls/input/input';
import { ReactComponent as MagicWandIcon } from '../../../assets/icons/magic-wand.svg';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import Spinner from '../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { useGovernance } from '../governance-context';
import { Proposal, PROPOSAL_STATUSES, ProposalStatus } from '../governance-types';
import ProposalStatusBadge from '../proposal-status-badge/proposal-status-badge';
import ProposalTypeBadge from '../proposal-type-badge/proposal-type-badge';
import './proposal-list.scss';

const ProposalList: React.FC = () => {
    const navigate = useNavigate();
    const { governanceState, setSearchText, setStatusFilter } = useGovernance();
    const { isTablet, isMobile } = useWindowSize();

    const renderBottomBar = (): ReactElement | undefined => {
        if (governanceState.filteredSortedProposals === undefined && governanceState.proposalsLoading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!governanceState.filteredSortedProposals?.length) {
            return <div className='no-data'>No Proposals</div>;
        }
    };

    const renderProposalIdColumn = (proposal: Proposal): ReactElement => {
        return <TableColumn className='index-column' contentClassName='index-column-content'>#{proposal.id.toString()}</TableColumn>;
    };

    const renderProposalNameColumn = (proposal: Proposal): ReactElement => {
        return <TableColumn contentClassName='proposal-name-column'>{proposal.title}</TableColumn>;
    };

    const renderProposalTypeColumn = (proposal: Proposal): ReactElement => {
        return (
            <TableColumn>
                <ProposalTypeBadge className='column-badge' size={isMobile ? 'small' : 'medium'} proposal={proposal} />
            </TableColumn>
        );
    };

    const renderProposalStatusColumn = (proposal: Proposal): ReactElement => {
        return (
            <TableColumn>
                <ProposalStatusBadge className='column-badge' size={isMobile ? 'small' : 'medium'} status={proposal.status} />
            </TableColumn>
        );
    };

    const renderProposalVotingEndTimeColumn = (proposal: Proposal): ReactElement => {
        const endDate = new Date(proposal.status === 'Deposit Period' ? proposal.depositEndTime : proposal.votingEndTime);
        return (
            <TableColumn className='voting-end-time-column' contentClassName='voting-end-time-column-content' align='right'>
                <span className='date-part'>{endDate.toDateString().slice(4)}</span>
                <span className='time-part'>{endDate.toTimeString().slice(0, 5)}</span>
            </TableColumn>
        );
    };

    const renderProposalHeaderRow = (): ReactElement => {
        const haveProposalInDepositPeriod =
            governanceState.filteredSortedProposals?.some((proposal) => proposal.status === 'Deposit Period');
        return (
            <TableRow header>
                <TableColumn className='index-column' contentClassName='index-column-content'>ID</TableColumn>
                <TableColumn>Title</TableColumn>
                <TableColumn>Type</TableColumn>
                <TableColumn>Status</TableColumn>
                <TableColumn align='right' className='voting-end-time-column'>
                    Voting{haveProposalInDepositPeriod ? '/Deposit' : ''} End
                </TableColumn>
            </TableRow>
        );
    };

    const renderProposalRow = (proposal: Proposal): ReactElement => {
        const proposalUrl = `${window.location.pathname.replace('governance', '')}proposal/${proposal.id}`;
        return (
            <TableRow key={proposal.id} className='proposal-row' onSelect={() => navigate(proposalUrl)}>
                {renderProposalIdColumn(proposal)}
                {renderProposalNameColumn(proposal)}
                {isMobile ? renderProposalStatusColumn(proposal) : renderProposalTypeColumn(proposal)}
                {isMobile ? renderProposalTypeColumn(proposal) : renderProposalStatusColumn(proposal)}
                {renderProposalVotingEndTimeColumn(proposal)}
            </TableRow>
        );
    };

    return <>
        <h5 className='proposal-list-header'>
            All Proposals ({governanceState.filteredSortedProposals?.length || 0})
            <span className='space' />

            <div className='proposals-controls'>
                <ControlsComposer className='proposals-filters'>
                    <Input
                        controlSize='medium'
                        value={governanceState.searchText}
                        type='search'
                        placeholder='Search proposals...'
                        onValueChange={setSearchText}
                    />
                    <Select
                        value={governanceState.statusFilter || ''}
                        className='proposal-status-select'
                        optionsOverlayAlign='right'
                        onSelect={(type) => setStatusFilter(type as ProposalStatus)}
                        controlSize='medium'
                    >
                        {PROPOSAL_STATUSES.map((status) => (
                            <Option key={status} value={status}>{status}</Option>
                        ))}
                        <Option key='all' value=''>All</Option>
                    </Select>
                </ControlsComposer>

                <Button
                    tooltipPlacement='top'
                    tooltip={isTablet ? 'Create new proposal' : ''}
                    buttonType='primary'
                    onClick={() => navigate('../create-proposal', { relative: 'path' })}
                >
                    <span className='create-proposal-button-text'>Create New Proposal&nbsp;&nbsp;</span><MagicWandIcon />
                </Button>
            </div>
        </h5>

        <div className='proposal-list-container'>
            <Table className='proposal-list' firstColumnSticky bottomBar={renderBottomBar()}>
                {renderProposalHeaderRow()}
                {governanceState.filteredSortedProposals?.map(renderProposalRow)}
            </Table>
        </div>
    </>;
};

export default ProposalList;
