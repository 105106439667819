import React from 'react';
import Swap from '../../../trade/swap/swap';
import { TradeContextProvider } from '../../../trade/trade-context';
import { TokensSwapContext, TokensSwapContextProvider } from '../tokens-swap-context';
import './tokens-swap-page.scss';

const TokensSwapPage: React.FC = () => {
    return (
        <div className='page tokens-swap-page'>
            <TokensSwapContextProvider>
                <TokensSwapContext.Consumer>
                    {(context) => (
                        <TradeContextProvider {...context} slippageToleranceMode='alert'>
                            <Swap title='Token Swap' actionLabel='Swap' />
                        </TradeContextProvider>
                    )}
                </TokensSwapContext.Consumer>
            </TokensSwapContextProvider>
        </div>
    );
};

export default TokensSwapPage;
