import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import { useFileUploader } from '../../../../shared/components/file-uploader/use-file-uploader';
import TextArea from '../../../../shared/components/form-controls/text-area/text-area';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/clear.svg';
import { ReactComponent as AddPhotoIcon } from '../../../../assets/icons/add-photo.svg';
import { ReactComponent as AccountCircleIcon } from '../../../../assets/icons/account-cricle.svg';
import { ReactComponent as AddReactionIcon } from '../../../../assets/icons/add-reaction.svg';
import Icon from '../../../../shared/components/icon/icon';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { useAuthUser } from '../../../account/auth-user/auth-user-context';
import { useNetworkComments } from '../network-comments-context';
import './post-comment.scss';

const MAX_COMMENT_LENGTH = 1024;
const MAX_COMMENT_IMAGE_SIZE = 720;
const EXPANDED_HEIGHT = 176;
const SMALL_EXPANDED_HEIGHT = 120;

interface PostCommentProps {
    className?: string;
    expandedAlways?: boolean;
}

const PostComment: React.FC<PostCommentProps> = ({ className, expandedAlways = false }) => {
    const { isTablet } = useWindowSize();
    const { user, userSigning, signInUser } = useAuthUser();
    const {
        currentCommentMessage,
        postedSuccessfully,
        posting,
        setCurrentCommentMessage,
        setCurrentCommentImageFile,
        postComment,
    } = useNetworkComments();
    const { file, imageContent, clear, handleBrowseFile } =
        useFileUploader({ acceptImages: true, maxWidth: MAX_COMMENT_IMAGE_SIZE, maxHeight: MAX_COMMENT_IMAGE_SIZE });
    const [ expanded, setExpanded ] = useState(expandedAlways);
    const [ imageHeight, setImageHeight ] = useState(0);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const expandedHeight = useMemo(() => isTablet ? SMALL_EXPANDED_HEIGHT : EXPANDED_HEIGHT, [ isTablet ]);

    useEffect(() => {
        if (expandedAlways) {
            setExpanded(true);
        }
    }, [ expandedAlways ]);

    useEffect(() => setCurrentCommentImageFile(file), [ file, setCurrentCommentImageFile ]);

    const clearImage = useCallback(() => {
        clear();
        setImageHeight(0);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }, [ clear ]);

    const onPostClick = useCallback(() => {
        if (!expandedAlways && !expanded) {
            setExpanded(true);
            setTimeout(() => textAreaRef.current?.focus(), 350);
            return;
        }
        return postComment();
    }, [ expanded, expandedAlways, postComment ]);

    useEffect(() => {
        if (postedSuccessfully) {
            clearImage();
            if (!expandedAlways) {
                setExpanded(false);
            }
        }
    }, [ clearImage, expandedAlways, postedSuccessfully ]);

    return (
        <div className={classNames('post-comment-section', className, { expanded })}>
            <div className='comment-body-container' style={{ height: expanded ? expandedHeight + imageHeight : 0 }}>
                <div className='horizontally-centered'>
                    {!userSigning && user.address ? <>
                        {user.avatar ?
                            <img className='user-avatar' alt='user avatar' src={user.avatar} /> :
                            <Icon className='fallback-user-avatar'><AccountCircleIcon /></Icon>}
                        {user.displayName}
                    </> : (
                        <Button
                            className='sign-in-button'
                            buttonType='icon'
                            onClick={signInUser}
                            loading={userSigning}
                            disabled={userSigning}
                        >
                            <AccountCircleIcon />
                        </Button>
                    )}
                    <span className='space' />
                    <span className='secondary-text'>{currentCommentMessage.length}/{MAX_COMMENT_LENGTH}</span>
                    {!expandedAlways &&
                        <Button className='close-icon' buttonType='icon' onClick={() => setExpanded(false)}><ClearIcon /></Button>}
                </div>
                <TextArea
                    className='comment-body-textarea'
                    value={currentCommentMessage}
                    onValueChange={setCurrentCommentMessage}
                    placeholder='How do you feel today?'
                    maxLength={MAX_COMMENT_LENGTH}
                    ref={textAreaRef}
                />
                {expanded && imageContent && <>
                    <Button
                        className='remove-image-button'
                        size='xs'
                        style={{ top: expandedHeight }}
                        buttonType='icon'
                        onClick={clearImage}
                    >
                        <ClearIcon />
                    </Button>
                    <img
                        src={imageContent}
                        alt='comment attachment'
                        className='comment-image'
                        onLoad={(event) => setImageHeight(event.currentTarget.height + (isTablet ? 8 : 16))}
                    />
                </>}
            </div>
            <div className='comment-actions'>
                <div className='expanded-actions'>
                    <Button buttonType='icon' onClick={() => fileInputRef.current?.click()}>
                        <AddPhotoIcon />
                        <input
                            type='file'
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleBrowseFile}
                            accept='image/*'
                        />
                    </Button>
                    <Button buttonType='icon'><AddReactionIcon /></Button>
                </div>
                <Button className='post-button' onClick={onPostClick} loading={posting} disabled={posting}>
                    Post{expanded ? '' : ' Comment'}
                </Button>
            </div>
        </div>
    );
};

export default PostComment;
