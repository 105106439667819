import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo } from 'react';
import { roundNumber } from '../../../shared/utils/number-utils';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { TxState } from '../../tx/tx-state';
import { useTx } from '../../tx/use-tx';
import { useSponsorship } from '../sponsorship-context';
import { createSponsorshipRevokeMessage, createSponsorshipVoteMessage } from '../sponsorship-service';
import { SponsorshipRecord } from '../sponsorship-types';

interface UseSponsorshipRevokeValue {
    txState: TxState;
    broadcast: () => Promise<void>;
}

export const useSponsorshipRevoke = (sponsorship: SponsorshipRecord): UseSponsorshipRevokeValue => {
    const { handleClientError } = useClient();
    const { vote } = useSponsorship();
    const networkState = useHubNetworkState();

    const updatedVote = useMemo(() => vote?.filter((record) => record.gaugeId !== sponsorship.gaugeId).map((record) => ({
        ...record, weight: roundNumber((record?.weight || 0) * 100, 12, true),
    })), [ sponsorship.gaugeId, vote ]);

    const voteMessagesCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !updatedVote) {
            return [];
        }
        const message = updatedVote.length ?
            createSponsorshipVoteMessage(networkState.address, updatedVote) :
            createSponsorshipRevokeMessage(networkState.address);
        return [ message ];
    }, [ networkState.address, updatedVote ]);

    const { txState, broadcast, calculateFee, clearFee } = useTx({
        networkState,
        txMessagesCreator: voteMessagesCreator,
    });

    useEffect(() => {
        if (txState.fee) {
            return;
        }
        if (networkState.address && updatedVote) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, networkState.address, txState.fee, updatedVote ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, txState.error ]);

    return { txState, broadcast };
};
