import { useEffect, useState } from 'react';
import { useSnackbar } from '../../shared/components/snackbar/snackbar-context';
import { SnackbarMessage } from '../../shared/components/snackbar/snackbar-types';
import Spinner from '../../shared/components/spinner/spinner';
import { useClient } from '../client/client-context';
import { getMainCurrency } from '../currency/currency-service';
import { Wallet } from '../wallet/wallet-types';
import { TxError } from './tx-error';
import { TxState } from './tx-state';
import { DeliveryTxCode } from './tx-types';

const TRANSACTION_IN_PROGRESS_KEY = 'transactionInProgress';

const useHandleTxResponses = (txState: TxState, networkWallet?: Wallet, onSuccess?: () => void): void => {
    const { removeMessage, showMessage, showErrorMessage, showWarningMessage } = useSnackbar();
    const { clientError } = useClient();
    const [ handledResponses, setHandledResponses ] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        if (txState?.broadcasting) {
            if (!txState.signing) {
                removeMessage(TRANSACTION_IN_PROGRESS_KEY);
                showMessage({
                    content: (
                        <div className='horizontally-centered'>
                            <Spinner size='small' />&nbsp;&nbsp;Transaction is in progress.
                        </div>
                    ),
                    duration: 600000,
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            } else {
                showMessage({
                    content: 'Your wallet is waiting for confirmation and a signature...',
                    key: TRANSACTION_IN_PROGRESS_KEY,
                });
            }
        } else {
            setTimeout(() => removeMessage(TRANSACTION_IN_PROGRESS_KEY), 50);
        }
    }, [ removeMessage, showMessage, txState?.broadcasting, txState.signing ]);

    useEffect(() => () => removeMessage(TRANSACTION_IN_PROGRESS_KEY), [ removeMessage ]);

    useEffect(() => {
        if (!txState.response || handledResponses[txState.response.hash]) {
            return;
        }
        const { hash, network, deliveryTxCode } = txState.response;
        const exploreLink = network.exploreTxUrl ? (new URL(hash, network.exploreTxUrl)).href : null;
        const action: SnackbarMessage['action'] = exploreLink ?
            { label: 'Explore', callback: () => window.open(exploreLink, '_blank') } :
            undefined;
        let content: string;
        switch (deliveryTxCode) {
            case DeliveryTxCode.SUCCESS:
                content = 'Your transaction successfully submitted!';
                break;
            case DeliveryTxCode.INSUFFICIENT_FUNDS:
                console.error(txState.response);
                content = 'Transaction delivery failed - insufficient funds';
                break;
            case DeliveryTxCode.OUT_OF_GAS:
                console.error(txState.response);
                content = 'Transaction delivery failed - out of gas';
                break;
            default:
                if (networkWallet?.getWalletType() === 'Quick Auth' &&
                    [ 'failed to update grant', 'authorization not found', 'fee-grant not found' ]
                        .some((message) => txState.response?.nativeResponse?.rawLog?.includes(message))) {
                    content = 'Unauthorized transaction, check if your Quick Auth session has expired';
                } else {
                    console.log('Transaction delivery failed with code: ' + deliveryTxCode);
                    content = 'Transaction delivery failed, please try again later';
                }
        }
        showMessage({ content, action, type: deliveryTxCode === DeliveryTxCode.SUCCESS ? 'success' : 'error', key: hash });
        setHandledResponses({ ...handledResponses, [txState.response.hash]: true });
        if (deliveryTxCode === DeliveryTxCode.SUCCESS) {
            onSuccess?.();
        }
    }, [ handledResponses, networkWallet, onSuccess, showMessage, txState.response ]);

    useEffect(() => {
        if (!txState?.error || !(txState.error instanceof TxError)) {
            return;
        }
        switch (txState.error.code) {
            case 'MISSING_DATA':
                showErrorMessage('Transaction delivery failed: invalid transaction parameters.');
                break;
            case 'MISSING_ROUTE':
                showErrorMessage(`Transaction delivery failed: can't establish a route.`);
                break;
            default:
                showErrorMessage('Transaction delivery failed, please try again later');
        }
    }, [ txState?.error, showErrorMessage, showWarningMessage ]);

    // todo: handle errors different
    useEffect(() => {
        if (!clientError) {
            return;
        }
        const networkNameLabel = clientError.network?.chainName || 'the';
        switch (clientError.code) {
            case 'FETCH_DATA_FAILED':
                showErrorMessage(`Can't fetch data from ${networkNameLabel} client, please try again later`);
                break;
            case 'INSUFFICIENT_FEES':
                showErrorMessage(`The transaction broadcast encountered a failure due to insufficient fees`);
                break;
            case 'INSUFFICIENT_FUNDS':
                if (clientError.originalError?.message?.includes?.('send coins to txfees account')) {
                    const denom = clientError.originalError?.message?.includes?.('adym') ? 'DYM' : 'tokens';
                    showErrorMessage(`insufficient ${denom} for fees.`);
                    break;
                }
                showWarningMessage(`Insufficient balance in your ${clientError.network ? `${clientError.network.chainName} ` : ''}account`);
                break;
            case 'SIMULATE_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to calculate fee, please try again later`);
                break;
            case 'BROADCAST_TX_FAILED':
                showErrorMessage(`${networkNameLabel} client was unable to broadcast the transaction, please try again later`);
                break;
            case 'SIGNATURE_VERIFICATION_FAILED':
                showErrorMessage(`Signature verification failed`);
                break;
            case 'NO_BALANCES':
                const currency = clientError.network ? getMainCurrency(clientError.network) : undefined;
                const action: SnackbarMessage['action'] = !currency || !clientError.network?.faucetUrl ? undefined :
                    {
                        label: 'Get ' + currency.displayDenom,
                        callback: () => window.open(clientError.network?.faucetUrl, '_blank'),
                        close: true,
                    };
                showWarningMessage({
                    content: <>
                        There are no balances in your {clientError.network ? `${clientError.network.chainName} ` : ''}account.<br />
                        Send some tokens there before trying to query or make a transaction.
                    </>,
                    action, duration: 20000,
                    key: 'no-balances-' + clientError.network?.chainId,
                });
                break;
            case 'REQUEST_REJECTED':
                showWarningMessage('The request rejected by the user');
                break;
            default:
                // todo: handle errors different
                showErrorMessage(`${networkNameLabel} client connection failed, please try again later`);
        }
    }, [ clientError, showErrorMessage, showWarningMessage ]);
};

export default useHandleTxResponses;
