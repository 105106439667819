import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/components/spinner/spinner';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getTimeLeftText } from '../../../shared/utils/date-utils';
import { useGovernance } from '../governance-context';
import { getTimeLeft } from '../governance-service';
import ProposalStatusBadge from '../proposal-status-badge/proposal-status-badge';
import ProposalTypeBadge from '../proposal-type-badge/proposal-type-badge';
import './live-proposals.scss';

const LiveProposals: React.FC = () => {
    const navigate = useNavigate();
    const { isMobile } = useWindowSize();
    const { governanceState } = useGovernance();

    const liveProposals = useMemo(
        () => governanceState.proposals?.filter((proposal) => proposal.status === 'Deposit Period' || proposal.status === 'Voting Period'),
        [ governanceState.proposals ],
    );

    return <>
        <h5 className='live-proposals-header'>Live Proposals ({liveProposals?.length || 0})</h5>
        <div className='live-proposals-container'>
            {!liveProposals?.length && (
                <div className='no-live-proposals section small'>
                    {liveProposals === undefined && governanceState.proposalsLoading ? <Spinner /> : 'No live proposals'}
                </div>
            )}
            {liveProposals?.map((proposal) => {
                const timeLeft = getTimeLeft(proposal);
                const proposalUrl = `${window.location.pathname.replace('governance', '')}proposal/${proposal.id}`;
                return (
                    <div key={proposal.id} className='live-proposal-card section small' onClick={() => navigate(proposalUrl)}>
                        <div className='proposal-card-header'>
                            <ProposalStatusBadge status={proposal.status} />
                            <span className='space' />
                            <span className={classNames('expires-in-label', { alert: timeLeft.days < 2 })}>
                                Expires in {getTimeLeftText(timeLeft)}
                            </span>
                        </div>
                        <div className='proposal-card-body'>
                            <span className='proposal-id'>#{proposal.id}</span>
                            <span className='proposal-title'>{proposal.title}</span>
                            <ProposalTypeBadge proposal={proposal} size={isMobile ? 'small' : 'medium'} />
                        </div>
                    </div>
                );
            })}
        </div>
    </>;
};

export default LiveProposals;
