import classNames from 'classnames';
import React, { ReactElement } from 'react';
import Spinner from '../../../shared/components/spinner/spinner';
import { formatNumber } from '../../../shared/utils/number-utils';
import { CoinsAmount } from '../../currency/currency-types';
import { Fee } from '../tx-types';
import './transaction-fee.scss';

interface TransactionFeeProps {
    fee: Fee;
    loading?: boolean;
    hideAmount?: boolean;
    className?: string;
    valueClassName?: string;
}

const TransactionFee: React.FC<TransactionFeeProps> = ({ fee, loading, hideAmount, className, valueClassName }: TransactionFeeProps) => {
    const getFeeValue = (): ReactElement | string => {
        if (loading || fee.loading) {
            return <Spinner size='small' />;
        }
        if (typeof fee.value === 'string' || typeof fee.value === 'number') {
            return fee.value.toString();
        }
        if ((fee.value as ReactElement)?.type) {
            return fee.value as ReactElement;
        }
        const coinsFeeValue = fee.value as (CoinsAmount | undefined);
        const feeDenom = coinsFeeValue?.currency?.displayDenom;
        if (!coinsFeeValue?.amount || hideAmount) {
            return feeDenom ? `- ${feeDenom}` : '';
        }
        if (coinsFeeValue.amount < 0.0001) {
            return `< 0.0001 ${feeDenom}`;
        }
        return `${formatNumber(coinsFeeValue.amount || 0, { minimumFractionDigits: 2, maximumFractionDigits: 4 })} ${feeDenom}`;
    };

    return (
        <div className={classNames('transaction-fee', className)}>
            {fee.label}<span className='space' />
            <span className={classNames('transaction-fee-value', valueClassName)}>{getFeeValue()}</span>
        </div>
    );
};

export default TransactionFee;
