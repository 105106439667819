import React, { useCallback, useMemo } from 'react';
import Alert from '../../../shared/components/alert/alert';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../shared/components/dialog/dialog';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import Spinner from '../../../shared/components/spinner/spinner';
import { CoinsAmount } from '../../currency/currency-types';
import { useNetwork } from '../../network/network-context';
import { getNetworkLogoPath } from '../../network/network-service';
import { Network } from '../../network/network-types';
import WalletLogo from '../../wallet/wallet-logo/wallet-logo';
import { WALLET_TYPES, WalletInfoMap, WalletType } from '../../wallet/wallet-types';
import { useImportToken } from './use-import-token';
import './import-token-dialog.scss';

export interface ImportTokenDialogProps extends DialogProps {
    token: CoinsAmount;
    walletType?: WalletType;
    network?: Network;
}

const ImportTokenDialog: React.FC<ImportTokenDialogProps> = ({ token, walletType, network, ...otherDialogProps }) => {
    const { hubNetwork, rollapps, getNetwork } = useNetwork();
    const { importTokenState, importToken, selectNetwork, selectWallet } = useImportToken(token, walletType, network);

    const supportedNetworks = useMemo(() => [ hubNetwork, ...rollapps ].filter(Boolean) as Network[], [ hubNetwork, rollapps ]);

    const supportedWallets = useMemo(
        () => WALLET_TYPES.filter((walletType) => walletType !== 'PortalWallet' && walletType !== 'Quick Auth'),
        [],
    );

    const searchFilterPredicate = useCallback((searchText: string, value: string | number): boolean => {
        const searchRegExp = new RegExp(searchText, 'i');
        const network = getNetwork(value.toString());
        return Boolean(network && (searchRegExp.test(network.chainName) || searchRegExp.test(network.chainId)));
    }, [ getNetwork ]);

    return (
        <Dialog
            className='import-token-dialog'
            closable
            onClickOutside={otherDialogProps.onClickOutside}
            {...otherDialogProps}
        >
            <DialogTitle>Import {token.currency.displayDenom} to wallet</DialogTitle>
            <DialogContent className='dialog-content'>
                <label>Import to wallet:</label>
                <Select
                    controlSize='large'
                    className='wallet-select'
                    placeholder={<WalletIcon />}
                    value={importTokenState.walletType}
                    onSelect={(value) => selectWallet(value as WalletType)}
                    disabled={supportedWallets.length === 1}
                >
                    {supportedWallets.map((walletType) => (
                        <Option key={walletType} value={walletType} iconColorMode='original'>
                            <WalletLogo type={walletType} />&nbsp;&nbsp;{walletType}
                        </Option>
                    ))}
                </Select>

                {(WalletInfoMap[importTokenState.walletType].type === 'evm') && <>
                    <label>In network:</label>
                    <Select
                        controlSize='large'
                        placeholder='Select network'
                        className='network-select'
                        searchFilterPredicate={searchFilterPredicate}
                        searchPlaceholder='Search...'
                        emptySearchResultsLabel='No network found'
                        value={importTokenState.network?.chainId}
                        onSelect={(networkId) => selectNetwork(networkId.toString())}
                    >
                        {supportedNetworks.map((network) => (
                            <Option
                                value={network.chainId}
                                key={network.chainId}
                                disabled={network.disabled || network.chainId === token.networkId}
                            >
                                <img className='network-logo' src={getNetworkLogoPath(network)} alt='network-logo' />
                                <span className='network-name'>{network.chainName}</span>
                            </Option>
                        ))}
                    </Select>

                    {importTokenState.coinsToImportLoading && <Spinner className='token-available-loader' />}

                    {!importTokenState.coinsToImport && !importTokenState.coinsToImportLoading && importTokenState.network && (
                        <Alert type='error'>
                            Token does not exist on network {importTokenState.network.chainName}
                        </Alert>
                    )}
                </>}
            </DialogContent>
            <DialogAction
                primary
                disabled={!importTokenState.coinsToImport || importTokenState.importing}
                loading={importTokenState.importing}
                onClick={importToken}
            >
                Import
            </DialogAction>
        </Dialog>
    );
};

export default ImportTokenDialog;

