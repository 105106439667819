import React, { useMemo } from 'react';
import { useAsset } from '../../../asset/asset-context';
import ClaimIro from '../../../iro/claim-iro/claim-iro';
import BuySell, { BuySellProps } from '../../../trade/buy-sell/buy-sell';
import { TradeContextProvider } from '../../../trade/trade-context';
import { useAmm } from '../../amm-context';
import { Asset } from '../../../asset/asset-types';
import { TokensSwapContext, TokensSwapContextProvider } from '../tokens-swap-context';

const SwapBuySell: React.FC<Omit<BuySellProps, 'tradeType' | 'setTradeType' | 'inverseAsset' | 'asset'> & { asset: Asset }> = (props) => {
    const { ammState } = useAmm();
    const { hubAsset } = useAsset();

    const inverseAsset = useMemo(
        () => props.asset.network.type === 'Hub' ? ammState.params?.vsCoins : hubAsset,
        [ ammState.params?.vsCoins, hubAsset, props.asset.network.type ],
    );

    return (
        <TokensSwapContextProvider
            initialAsset1={props.asset.network.type === 'Hub' ? ammState.params?.vsCoins : hubAsset}
            initialAsset2={props.asset}
        >
            <TokensSwapContext.Consumer>
                {(context) => (
                    <TradeContextProvider {...context} slippageToleranceMode='alert'>
                        <ClaimIro asset={props.asset} />

                        <BuySell
                            {...props}
                            onTradeTypeChange={context.switchTokens}
                            asset={props.asset}
                            isExactAmountSpend
                            inverseAsset={inverseAsset}
                            canUseInverseAssetInBuy
                        />
                    </TradeContextProvider>
                )}
            </TokensSwapContext.Consumer>
        </TokensSwapContextProvider>
    );
};

export default SwapBuySell;
