import React, { ReactNode, useState } from 'react';
import Button, { ButtonProps } from '../../../shared/components/button/button';
import Confirm from '../../../shared/components/confirm/confirm';
import { useClient } from '../../client/client-context';
import { getMaxDenomAmount } from '../../currency/currency-service';
import { useNetwork } from '../../network/network-context';
import { useWallet } from '../../wallet/wallet-context';
import { DEFAULT_SLIPPAGE_TOLERANCE, useTrade } from '../trade-context';

const ConfirmTradeButton: React.FC<ButtonProps> = (props) => {
    const { networkWalletMap } = useWallet();
    const { clientStateMap } = useClient();
    const { hubNetwork } = useNetwork();
    const {
        asset1AmountTxState,
        asset2AmountTxState,
        txState,
        slippageTolerance,
        currentSlippage,
        slippageToleranceMode,
        disabled,
        getTokensMinAmounts,
        broadcast,
    } = useTrade();
    const [ slippageAlertDialogOpen, setSlippageAlertDialogOpen ] = useState(false);

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];
    const networkWallet = hubNetwork && networkWalletMap[hubNetwork.chainId];

    const renderSlippageAlertDialog = (): ReactNode => {
        if (!asset1AmountTxState.coins || !asset2AmountTxState.coins || !getTokensMinAmounts) {
            return;
        }
        const { tokenOutMinAmount } = getTokensMinAmounts(asset1AmountTxState.coins, asset2AmountTxState.coins);
        const fixedMinAmount = getMaxDenomAmount(Number(tokenOutMinAmount), asset2AmountTxState.coins.currency);

        return (
            <Confirm
                title='Slippage Alert'
                className='slippage-alert-confirm'
                closable
                onRequestClose={() => setSlippageAlertDialogOpen(false)}
                onConfirm={() => broadcast()}
                content={<>
                    Note: slippage is above your tolerance configuration.<br />
                    The minimum amount of tokens received from this trade is {fixedMinAmount} {asset2AmountTxState.coins?.currency.displayDenom}.
                </>}
                okLabel='Confirm'
                cancelLabel='Abort'
                warning
            />
        );
    };

    const confirmButtonDisabled = Boolean(
        disabled ||
        txState.broadcasting ||
        txState.feeLoading ||
        !asset1AmountTxState.coins?.amount ||
        !asset2AmountTxState.coins?.amount ||
        (networkWallet && (!clientState?.client || clientState?.connecting)));

    return <>
        <Button
            {...props}
            loading={txState.broadcasting || txState.feeLoading}
            disabled={confirmButtonDisabled}
            onClick={() => {
                if (slippageToleranceMode === 'alert' &&
                    currentSlippage && currentSlippage >= (slippageTolerance ?? DEFAULT_SLIPPAGE_TOLERANCE)) {
                    setSlippageAlertDialogOpen(true);
                } else {
                    broadcast();
                }
            }}
        >
            {props.children || 'Trade'}
        </Button>

        {slippageToleranceMode === 'alert' && slippageAlertDialogOpen && renderSlippageAlertDialog()}
    </>;
};

export default ConfirmTradeButton;
