/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "dymensionxyz.dymension.dymns";

/** MultipleAliases contains a list of alias. */
export interface MultipleAliases {
  /** aliases is a list of alias, available for a RollApp. */
  aliases: string[];
}

function createBaseMultipleAliases(): MultipleAliases {
  return { aliases: [] };
}

export const MultipleAliases = {
  encode(message: MultipleAliases, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.aliases) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MultipleAliases {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMultipleAliases();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.aliases.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MultipleAliases {
    return { aliases: Array.isArray(object?.aliases) ? object.aliases.map((e: any) => String(e)) : [] };
  },

  toJSON(message: MultipleAliases): unknown {
    const obj: any = {};
    if (message.aliases) {
      obj.aliases = message.aliases.map((e) => e);
    } else {
      obj.aliases = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MultipleAliases>, I>>(object: I): MultipleAliases {
    const message = createBaseMultipleAliases();
    message.aliases = object.aliases?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
