import React, { useMemo } from 'react';
import Badge from '../../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { SponsorshipRecord } from '../../sponsorship-types';

interface SponsorshipTypeBadgeProps {
    size?: 'small' | 'medium' | 'large';
    sponsorship: SponsorshipRecord;
    className?: string;
}

const SponsorshipTypeBadge: React.FC<SponsorshipTypeBadgeProps> = ({ sponsorship, size = 'medium', className }) => {
    const colorVariable = useMemo(() => {
        if (sponsorship.type === 'Pool') {
            return '--light-green-rgb';
        } else if (sponsorship.type === 'RollApp') {
            return '--light-blue-rgb';
        }
        return '--cream-very-dark-rgb';
    }, [ sponsorship.type ]);

    return (
        <Badge
            size={size}
            className={className}
            rounded
            color={getCssVariableValue(colorVariable).split(',').map(Number)}
            label={sponsorship.type}
            info={sponsorship.type === 'RollApp' ?
                'Incentives are streamed to the RollApp creator Address' :
                'Incentives are streamed to Bonded LPs of this Pool'}
        />
    );
};

export default SponsorshipTypeBadge;
