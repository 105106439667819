import classNames from 'classnames';
import { uniqBy } from 'lodash';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FireIcon } from '../../../../assets/icons/fire.svg';
import { ReactComponent as RocketIcon } from '../../../../assets/icons/rocket.svg';
import { ReactComponent as ThunderIcon } from '../../../../assets/icons/thunder2.svg';
import { ReactComponent as TrophyIcon } from '../../../../assets/icons/trophy.svg';
import { ReactComponent as ScheduleIcon } from '../../../../assets/icons/schedule.svg';
import Addresses from '../../../../shared/components/addresses/addresses';
import Badge from '../../../../shared/components/badge/badge';
import Icon from '../../../../shared/components/icon/icon';
import Spinner from '../../../../shared/components/spinner/spinner';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import Swiper from '../../../../shared/components/swiper/swiper';
import { useCancelablePromise } from '../../../../shared/hooks/use-cancelable-promise';
import useVisibility from '../../../../shared/hooks/use-visibility';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useAmm } from '../../../amm/amm-context';
import { tradesObservable, useAsset } from '../../../asset/asset-context';
import { getCurrencyLogoPath, getMainCurrency, getMinDenomAmount, isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import DisplayNameWithFuture from '../../../currency/display-name-with-future/display-name-with-future';
import { useNetwork } from '../../../network/network-context';
import { getNetworkLogoPath } from '../../../network/network-service';
import { Network } from '../../../network/network-types';
import { TradeType } from '../../../trade/buy-sell/buy-sell';
import { getFixedTradeBase, loadTrades } from '../../../trade/trade-service';
import { Trade } from '../../../trade/types';
import './rollapp-highlights.scss';
import { convertToHexAddress } from '../../../wallet/wallet-service';

interface RollappsHighlightProps {
    label: ReactElement;
    rollapps: Network[];
}

const HIGHLIGHT_CARD_SIZE = 4;
const TOP_GAINERS_MARKET_CAP_THRESHOLD = 10000;
const TOP_GAINERS_VOLUME_THRESHOLD = 1000;

const RollappsHighlightCard: React.FC<RollappsHighlightProps> = ({ label, rollapps }) => {
    const navigate = useNavigate();
    const { mainAssetMap, loading } = useAsset();

    return (
        <div className='highlight-card section small'>
            <h6 className='card-label'>{label}</h6>
            {rollapps.map((rollapp) => {
                const asset = mainAssetMap?.[rollapp.chainId];
                return (
                    <div className='rollapp-row' key={rollapp.chainId} onClick={() => navigate(`/rollapps/${rollapp.chainId}`)}>
                        <img className='asset-logo' src={getNetworkLogoPath(rollapp)} alt='asset logo' />
                        <div className='rollapp-name-container'>
                            <span className='rollapp-name'>{rollapp.chainName}</span>
                            {!rollapp.tokenless &&
                                <span className='asset-name'>{asset?.currency.displayDenom || getMainCurrency(rollapp).displayDenom}</span>}
                        </div>
                        {asset ? (
                            <StatisticsChange
                                showPeriod={false}
                                currentValue={asset.price}
                                previousValue={asset.previousDayPrice}
                                align='right'
                                period={'day'}
                            >
                                {formatPrice(asset.price, undefined, undefined, 6)}
                            </StatisticsChange>
                        ) : rollapp.tokenless ? <span className='secondary-text'>DYM Native</span> : undefined}
                    </div>
                );
            })}
            {loading && !mainAssetMap ? <Spinner /> : undefined}
        </div>
    );
};

const RollappsHighlights: React.FC = () => {
    const navigate = useNavigate();
    const { hubNetwork, getNetwork } = useNetwork();
    const { getAssetLink, vsAsset } = useAsset();
    const { rollapps } = useNetwork();
    const { assets } = useAsset();
    const { ammState } = useAmm();
    const visibility = useVisibility();
    const [ tradesLoading, setTradesLoading ] = useState(true);
    const [ toLoadTrades, setToLoadTrades ] = useState(true);
    const [ lastTrades, setLastTrades ] = useState<Trade[]>([]);
    const [ newTradeId, setNewTradeId ] = useState<string>();
    const cancelAndSetTradesPromise = useCancelablePromise<{ records: Trade[], count: number }>();

    const topGainersMarketCapThreshold = useMemo(
        () => getMinDenomAmount(TOP_GAINERS_MARKET_CAP_THRESHOLD, vsAsset?.currency),
        [ vsAsset?.currency ],
    );

    const topGainersVolumeThreshold = useMemo(
        () => getMinDenomAmount(TOP_GAINERS_VOLUME_THRESHOLD, vsAsset?.currency),
        [ vsAsset?.currency ],
    );

    useEffect(() => {
        if (!toLoadTrades || !hubNetwork || !assets || !ammState.params) {
            return;
        }
        cancelAndSetTradesPromise((signal) => loadTrades(hubNetwork.chainId, undefined, undefined, 0, HIGHLIGHT_CARD_SIZE, signal))
            .then(({ records }) => {
                const lastFixedTrades =
                    filterNonEmptyValues(records.map((trade) => getFixedTradeBase(trade, assets, ammState.params?.vsCoins.currency)));
                setLastTrades((lastTrades) =>
                    uniqBy([ ...lastFixedTrades, ...lastTrades ], (trade) => trade.id).slice(0, HIGHLIGHT_CARD_SIZE));
                setToLoadTrades(false);
            })
            .finally(() => setTradesLoading(false));
    }, [ ammState.params, assets, cancelAndSetTradesPromise, hubNetwork, lastTrades.length, toLoadTrades ]);

    useEffect(() => {
        if (visibility && rollapps.length) {
            setToLoadTrades(true);
        }
    }, [ rollapps.length, visibility ]);

    useEffect(() => {
        const subscription = tradesObservable.subscribe((newTrade) => {
            setLastTrades((trades) => {
                if (trades.some((trade) => trade.id === newTrade.id)) {
                    return trades;
                }
                setNewTradeId(newTrade.id);
                setTimeout(() => setNewTradeId(undefined), 50);
                return [ newTrade, ...trades ].slice(0, HIGHLIGHT_CARD_SIZE);
            });
        });
        return () => subscription.unsubscribe();
    }, []);

    const topGainers = useMemo(() => {
        const rollappAssets = (assets || []).filter((asset) => asset.network.type === 'RollApp');
        let filteredAssets = rollappAssets.filter((asset) => asset.currency.type !== 'main' ? asset.volume >= topGainersVolumeThreshold :
            (asset.network.totalSupply?.previousDayValue?.marketCap || 0) >= topGainersMarketCapThreshold);
        if (filteredAssets.length < HIGHLIGHT_CARD_SIZE) {
            filteredAssets = rollappAssets;
        }
        const sortedAssets = filteredAssets
            .sort((asset1, asset2) =>
                (!asset2.previousDayPrice ? 0 : (asset2.price || 0) / asset2.previousDayPrice) -
                (!asset1.previousDayPrice ? 0 : (asset1.price || 0) / asset1.previousDayPrice))
            .slice(0, HIGHLIGHT_CARD_SIZE);
        if (!sortedAssets.length || sortedAssets[0].price <= sortedAssets[0].previousDayPrice) {
            return [];
        }
        return sortedAssets.map((asset) => asset.network);
    }, [ assets, topGainersMarketCapThreshold, topGainersVolumeThreshold ]);

    const topIROs = useMemo(() => (assets || [])
        .filter((asset) => asset.network.status === 'IRO')
        .sort((asset1, asset2) => {
            return asset2.iroDymRaised - asset1.iroDymRaised;
        })
        .map((asset) => asset.network)
        .slice(0, HIGHLIGHT_CARD_SIZE), [ assets ]);

    const newlyCreated = useMemo(() => [ ...rollapps ]
        .sort((rollapp1, rollapp2) => (rollapp2.creationDate || 0) - (rollapp1.creationDate || 0))
        .slice(0, HIGHLIGHT_CARD_SIZE), [ rollapps ]);

    const trending = useMemo(() => [ ...rollapps ]
        .sort((rollapp1, rollapp2) => {
            const rollapp1Transfers = (rollapp1?.ibcTransfers?.value.totalIn || 0) + (rollapp1?.ibcTransfers?.value.totalOut || 0);
            const rollapp1PreviousWeekTransfers =
                (rollapp1?.ibcTransfers?.previousWeekValue?.totalIn || 0) + (rollapp1?.ibcTransfers?.previousWeekValue?.totalOut || 0);
            const rollapp2Transfers = (rollapp2?.ibcTransfers?.value.totalIn || 0) + (rollapp2?.ibcTransfers?.value.totalOut || 0);
            const rollapp2PreviousWeekTransfers =
                (rollapp2?.ibcTransfers?.previousWeekValue?.totalIn || 0) + (rollapp2?.ibcTransfers?.previousWeekValue?.totalOut || 0);
            return (rollapp2Transfers - rollapp2PreviousWeekTransfers) - (rollapp1Transfers - rollapp1PreviousWeekTransfers);
        })
        .slice(0, HIGHLIGHT_CARD_SIZE), [ rollapps ]);

    const getTargetAssetAndTradeType = (trade: Trade): { asset: CoinsAmount, type: TradeType } => {
        const vsCoins = ammState.params?.vsCoins;
        if (vsCoins && isCoinsEquals(trade.tokenIn, vsCoins)) {
            return { asset: trade.tokenOut, type: TradeType.BUY };
        }
        if (vsCoins && (isCoinsEquals(trade.tokenOut, vsCoins))) {
            return { asset: trade.tokenIn, type: TradeType.SELL };
        }
        return {
            asset: trade.tokenInNetwork === hubNetwork?.chainId ? trade.tokenOut : trade.tokenIn,
            type: trade.tokenInNetwork === hubNetwork?.chainId ? TradeType.BUY : TradeType.SELL,
        };
    };

    const renderTradesHighlightCard = (): ReactElement => {
        return (
            <div className='highlight-card activity section small'>
                <h6 className='card-label'><Icon><ThunderIcon /></Icon>&nbsp;Activity</h6>
                {tradesLoading && !lastTrades.length ? <Spinner /> : undefined}
                {lastTrades.map((trade) => {
                    const { asset, type } = getTargetAssetAndTradeType(trade);
                    const network = getNetwork(asset.networkId);
                    const typeColor = type === TradeType.BUY ? '--light-green-rgb' : '--red-rgb';
                    return (
                        <div
                            key={trade.id}
                            className={classNames('trade-row', { new: newTradeId === trade.id })}
                            onClick={() => navigate(getAssetLink(asset))}
                        >
                            {network && <img className='asset-logo' src={getCurrencyLogoPath(asset.currency, network)} alt='asset logo' />}
                            <span className='trade-amount'>{formatPrice(asset.amount, '', { notation: 'compact' })}</span>
                            &nbsp;<DisplayNameWithFuture className='trade-token' coins={asset} />
                            <Addresses className='ellipsis' canCopy addresses={[ convertToHexAddress(trade.address), trade.address ]} />
                            <span className='space' />
                            <Badge
                                className='trade-type-badge'
                                label={type}
                                size='small'
                                rounded
                                color={getCssVariableValue(typeColor).split(',').map(Number)}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Swiper showOneInMobile>
            {topGainers.length ?
                <RollappsHighlightCard label={<><Icon><RocketIcon /></Icon>&nbsp;Top Gainers</>} rollapps={topGainers} /> :
                <RollappsHighlightCard label={<><Icon><FireIcon /></Icon>&nbsp;Trending</>} rollapps={trending} />}
            <RollappsHighlightCard label={<><Icon><TrophyIcon /></Icon>&nbsp;Top IROs</>} rollapps={topIROs} />
            <RollappsHighlightCard label={<><Icon><ScheduleIcon /></Icon>&nbsp;Newly Created</>} rollapps={newlyCreated} />
            {renderTradesHighlightCard()}
        </Swiper>
    );
};

export default RollappsHighlights;
