import React, { useMemo } from 'react';
import Badge, { BadgeProps } from '../../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../../shared/utils/color-utils';
import { ROLLAPP_STATUS_INFO_MAP, RollappStatus } from '../../../network/network-types';

interface RollappStatusBadgeProps extends Omit<BadgeProps, 'label'> {
    status?: RollappStatus;
}

const RollappStatusBadge: React.FC<RollappStatusBadgeProps> = ({ status = 'Registered', ...badgeProps }) => {
    const color = useMemo(() => getCssVariableValue(ROLLAPP_STATUS_INFO_MAP[status].variableColor).split(',').map(Number), [ status ]);

    return <Badge label={status} rounded color={color} info={ROLLAPP_STATUS_INFO_MAP[status].infoText} {...badgeProps} />;
};

export default RollappStatusBadge;

