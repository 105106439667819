import classNames from 'classnames';
import { Decimal } from 'cosmjs/packages/math';
import React, { useMemo } from 'react';
import Addresses from '../../../../shared/components/addresses/addresses';
import Button from '../../../../shared/components/button/button';
import Link from '../../../../shared/components/link/link';
import Property from '../../../../shared/components/property/property';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import useCopyToClipboard from '../../../../shared/hooks/use-copy-to-clipboard';
import { formatPrice } from '../../../../shared/utils/number-utils';
import { useNetwork } from '../../../network/network-context';
import { Sequencer } from '../../../network/network-types';
import { convertToHexAddress } from '../../../wallet/wallet-service';
import '../info-section.scss';

interface SequencerInfoProps {
    sequencer: Sequencer;
    className?: string;
}

const SequencerInfo: React.FC<SequencerInfoProps> = ({ sequencer, className }) => {
    const { hubCurrency } = useNetwork();
    const copyToClipboard = useCopyToClipboard();

    const sequencersBond = useMemo(() => {
        if (!hubCurrency) {
            return;
        }
        const bond = sequencer.tokens
            .filter((token) => token.denom === hubCurrency.baseDenom)
            .reduce((current, token) => current + BigInt(token.amount), BigInt(0));
        return Decimal.fromAtomics(bond.toString(), hubCurrency.decimals);
    }, [ hubCurrency, sequencer ]);

    const xAccountName = useMemo(() => {
        if (!sequencer.x) {
            return '';
        }
        const xAccountUrlPart = sequencer.x.split('/');
        return `@${xAccountUrlPart[xAccountUrlPart.length - 1]}`;
    }, [ sequencer.x ]);

    return (
        <div className={classNames('info-section section small', className)}>
            {sequencer.moniker && (
                <Property label='Name' className='info-property' valueClassName='info-property-value'>
                    <Button
                        size='small'
                        buttonType='icon'
                        className='info-property-value-content'
                        onClick={() => copyToClipboard(sequencer.moniker || '', 'moniker')}
                    >
                        {sequencer.moniker}&nbsp;&nbsp;
                        <CopyIcon />
                    </Button>
                </Property>
            )}

            <Property label='Address' className='info-property' valueClassName='info-property-value'>
                <Addresses
                    className='info-property-value-content'
                    display='long'
                    addresses={[ sequencer.address, convertToHexAddress(sequencer.address) ]}
                    canCopy
                />
            </Property>

            <Property label='Bond' className='info-property' valueClassName='info-property-value'>
                {sequencersBond && formatPrice(sequencersBond.toFloatApproximation(), hubCurrency?.displayDenom)}
            </Property>

            {sequencer.website && (
                <Property label='Website' className='info-property' valueClassName='info-property-value'>
                    <Link url={sequencer.website} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{sequencer.website}</span>
                    </Link>
                </Property>
            )}

            {sequencer.telegram && (
                <Property label='Telegram' className='info-property' valueClassName='info-property-value'>
                    <Link url={sequencer.telegram} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{sequencer.telegram}</span>
                    </Link>
                </Property>
            )}

            {sequencer.x && (
                <Property label='X (Twitter)' className='info-property' valueClassName='info-property-value'>
                    <Link url={sequencer.x} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{xAccountName}</span>
                    </Link>
                </Property>
            )}

            {sequencer.explorerUrl && (
                <Property label='Explorer' className='info-property' valueClassName='info-property-value'>
                    <Link url={sequencer.explorerUrl} external className='info-property-value-content' size='small'>
                        <span className='ellipsis'>{sequencer.explorerUrl}</span>
                    </Link>
                </Property>
            )}

            {sequencer.details && (
                <Property label='Description' className='info-property' valueClassName='info-property-value text'>
                    {sequencer.details}
                </Property>
            )}
        </div>
    );
};

export default SequencerInfo;
