import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthUserContextProvider } from './modules/account/auth-user/auth-user-context';
import { HubNetworkStateContextProvider } from './modules/account/hub-network-state-context';
import { QuickAuthContextProvider } from './modules/quick-auth/quick-auth-context';
import { AmmContextProvider } from './modules/amm/amm-context';
import App from './modules/app/app';
import { LayoutContextProvider } from './modules/app/layout/layout-context';
import { AssetContextProvider } from './modules/asset/asset-context';
import { ClientContextProvider } from './modules/client/client-context';
import { DymnsContextProvider } from './modules/dymns/dymns-context';
import { IbcStatusContextProvider } from './modules/ibc-transfer/ibc-status/ibc-status-context';
import { IncentivesContextProvider } from './modules/incentives/incentives-context';
import { IroContextProvider } from './modules/iro/iro-context';
import { NetworkContextProvider } from './modules/network/network-context';
import { SplashscreenContextProvider } from './modules/splashscreen/splashscreen-context';
import { SponsorshipContextProvider } from './modules/sponsorship/sponsorship-context';
import { WalletContextProvider } from './modules/wallet/wallet-context';
import reportWebVitals from './reportWebVitals';
import { SnackbarContextProvider } from './shared/components/snackbar/snackbar-context';
import { initializeFirebase } from './shared/utils/firebase-utils';
import './assets/fonts/TWKEverett/TWKEverett-Medium.woff2';
import './index.scss';

initializeFirebase();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <SnackbarContextProvider>
        <NetworkContextProvider>
            <WalletContextProvider>
                <ClientContextProvider>
                    <HubNetworkStateContextProvider>
                        <AuthUserContextProvider>
                            <DymnsContextProvider>
                                <IncentivesContextProvider>
                                    <AmmContextProvider>
                                        <IroContextProvider>
                                            <AssetContextProvider>
                                                <IbcStatusContextProvider>
                                                    <SponsorshipContextProvider>
                                                        <SplashscreenContextProvider>
                                                            <QuickAuthContextProvider>
                                                                <LayoutContextProvider>
                                                                    <App />
                                                                </LayoutContextProvider>
                                                            </QuickAuthContextProvider>
                                                        </SplashscreenContextProvider>
                                                    </SponsorshipContextProvider>
                                                </IbcStatusContextProvider>
                                            </AssetContextProvider>
                                        </IroContextProvider>
                                    </AmmContextProvider>
                                </IncentivesContextProvider>
                            </DymnsContextProvider>
                        </AuthUserContextProvider>
                    </HubNetworkStateContextProvider>
                </ClientContextProvider>
            </WalletContextProvider>
        </NetworkContextProvider>
    </SnackbarContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
