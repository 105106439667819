import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import useVisibility from '../../shared/hooks/use-visibility';
import { convertDecimalToInt } from '../../shared/utils/number-utils';
import { MultipleAliases } from '../client/station-clients/dymension/generated/dymns/alias';
import { Params } from '../client/station-clients/dymension/generated/dymns/params';
import { useNetwork } from '../network/network-context';
import { getNetworkData } from '../network/network-service';
import { DYMNS_STATE_DEFAULT, dymnsReducer, DymnsState } from './dymns-state';

interface DymnsContextValue {
    dymnsState: DymnsState;
    refreshAliases: () => void;
}

export const DymnsContext = createContext<DymnsContextValue>({} as DymnsContextValue);

export const useDymns = (): DymnsContextValue => useContext(DymnsContext);

export const DymnsContextProvider = ({ children }: { children: ReactNode }) => {
    const { hubNetwork } = useNetwork();
    const visibility = useVisibility();
    const [ dymnsState, dymnsStateDispatch ] = useReducer(dymnsReducer, DYMNS_STATE_DEFAULT);
    const [ toLoadAliases, setToLoadAliases ] = useState(true);
    const cancelAndSetParamsPromise = useCancelablePromise<Params>();
    const cancelAndSetAliasesPromise = useCancelablePromise<{ [key: string]: MultipleAliases }>();

    useEffect(() => {
        if (visibility) {
            setToLoadAliases(true);
        }
    }, [ visibility ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        dymnsStateDispatch({ type: 'set-params-loading' });
        cancelAndSetParamsPromise((signal) => getNetworkData<Params>(hubNetwork.chainId, 'dymns-params', true, signal))
            .then((params) => {
                const aliasPriceSteps = params.price?.aliasPriceSteps.map((step) => convertDecimalToInt(Number(step))) || [];
                dymnsStateDispatch({ type: 'set-params', payload: { aliasPriceSteps } });
            })
            .finally(() => dymnsStateDispatch({ type: 'set-params-loading', payload: false }));
    }, [ cancelAndSetParamsPromise, hubNetwork ]);

    useEffect(() => {
        if (!hubNetwork || !toLoadAliases) {
            return;
        }
        setToLoadAliases(false);
        dymnsStateDispatch({ type: 'set-aliases-loading' });
        cancelAndSetAliasesPromise(
            (signal) => getNetworkData<{ [key: string]: MultipleAliases }>(hubNetwork.chainId, 'aliases', true, signal))
            .then((aliases) => dymnsStateDispatch({ type: 'set-aliases', payload: aliases }))
            .finally(() => dymnsStateDispatch({ type: 'set-aliases-loading', payload: false }));
    }, [ cancelAndSetAliasesPromise, hubNetwork, toLoadAliases ]);

    return (
        <DymnsContext.Provider value={{ dymnsState, refreshAliases: () => setToLoadAliases(true) }}>
            {children}
        </DymnsContext.Provider>
    );
};
