import { Account } from '../../../types';

const DATABASE_NAME = 'AccountsDB';
const DATABASE_VERSION_KEY = 'accounts-db-version';

export class AccountStorage {
    private db!: IDBDatabase;

    constructor(private storeName: string) {}

    public async openDatabase(): Promise<void> {
        return new Promise((resolve, reject) => {
            const currentVersion = Number(localStorage.getItem(DATABASE_VERSION_KEY)) || 2;
            localStorage.setItem(DATABASE_VERSION_KEY, (currentVersion + 1).toString());
            const request = indexedDB.open(DATABASE_NAME, currentVersion);

            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                const db = (event.target as IDBOpenDBRequest).result;
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName, { keyPath: 'address' });
                }
            };
            request.onsuccess = () => {
                this.db = request.result;
                resolve();
            };
            request.onerror = () => {
                console.error('Database error: ' + request.error);
                reject(request.error);
            };
        });
    }

    public async close(): Promise<void> {
        this.db.close();
    }

    public async getAllAccounts(): Promise<Account[]> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([ this.storeName ], 'readonly');
            const objectStore = transaction.objectStore(this.storeName);
            const request = objectStore.getAll();

            request.onsuccess = (event: Event) => {
                const accounts = (event.target as IDBRequest<Account[]>).result;
                resolve(accounts);
            };
            request.onerror = () => {
                console.error('Error retrieving all accounts:', request.error);
                reject(request.error);
            };
        });
    }

    public async replaceAllAccounts(newAccounts: Account[], batchSize: number = 1000): Promise<void> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([ this.storeName ], 'readwrite');
            const objectStore = transaction.objectStore(this.storeName);
            const clearRequest = objectStore.clear();

            clearRequest.onsuccess = async () => {
                if (!newAccounts.length) {
                    return;
                }
                try {
                    for (let i = 0; i < newAccounts.length; i += batchSize) {
                        const batch = newAccounts.slice(i, i + batchSize);
                        batch.forEach(account => {
                            const addRequest = objectStore.add(account);
                            addRequest.onerror = () => {
                                console.error(`Error adding account: ${account.address}`, addRequest.error);
                                reject(addRequest.error);
                            };
                        });
                    }
                } catch (error) {
                    console.error('Error adding accounts in batches:', error);
                    reject(error);
                }
            };
            clearRequest.onerror = () => {
                console.error('Error clearing existing accounts:', clearRequest.error);
                reject(clearRequest.error);
            };
            transaction.oncomplete = () => resolve();
            transaction.onerror = () => {
                console.error('Transaction error during replacing accounts:', transaction.error);
                reject(transaction.error);
            };
        });
    }
}
