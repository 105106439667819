import { MultipleAliases } from '../client/station-clients/dymension/generated/dymns/alias';
import { DymnsParams } from './dymns-types';

export interface DymnsState {
    params?: DymnsParams;
    aliasesMap: { [key: string]: MultipleAliases };
    aliasesLoading?: boolean;
    paramsLoading?: boolean;
}

export const DYMNS_STATE_DEFAULT: DymnsState = {
    paramsLoading: true,
    aliasesLoading: true,
    aliasesMap: {},
};

export type DymnsAction =
    { type: 'set-params', payload: DymnsParams | undefined } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-aliases', payload: { [key: string]: MultipleAliases } } |
    { type: 'set-aliases-loading', payload?: boolean };

export const dymnsReducer = (state: DymnsState, action: DymnsAction): DymnsState => {
    switch (action.type) {
        case 'set-params':
            return { ...state, params: action.payload, paramsLoading: false };
        case 'set-aliases':
            return { ...state, aliasesMap: action.payload, aliasesLoading: false };
        case 'set-params-loading':
            return { ...state, paramsLoading: action.payload ?? true };
        case 'set-aliases-loading':
            return { ...state, aliasesLoading: action.payload ?? true };
        default:
            return state;
    }
};

