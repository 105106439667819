/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Coin, DecProto } from "../cosmos/base/v1beta1/coin";

export const protobufPackage = "dymensionxyz.dymension.eibc";

/** FulfillOrderAuthorization allows the grantee to fulfill eIBC demand orders from the granter's account. */
export interface FulfillOrderAuthorization {
  /** rollapps is an optional list of rollapp criteria that the grantee can fulfill demand orders from */
  rollapps: RollappCriteria[];
}

export interface RollappCriteria {
  /** rollapp_id is the unique identifier of the rollapp that the grantee can fulfill demand orders from */
  rollappId: string;
  /** denoms is an optional list of denoms that the grantee can fulfill demand orders for */
  denoms: string[];
  /** max_price is the optional maximum order price acceptable to the granter */
  maxPrice: Coin[];
  /** min_lp_fee_percentage is the minimum fee earning percentage the LP is willing to get from a demand order */
  minLpFeePercentage:
    | DecProto
    | undefined;
  /** operator_fee_share is the share of the fee earnings willing to give to the operator */
  operatorFeeShare:
    | DecProto
    | undefined;
  /** settlement_validated is the flag to only fulfill demand orders that have been settlement validated */
  settlementValidated: boolean;
  /** spend_limit is the optional maximum amount of coins that can be spent by the grantee */
  spendLimit: Coin[];
}

function createBaseFulfillOrderAuthorization(): FulfillOrderAuthorization {
  return { rollapps: [] };
}

export const FulfillOrderAuthorization = {
  encode(message: FulfillOrderAuthorization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rollapps) {
      RollappCriteria.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FulfillOrderAuthorization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFulfillOrderAuthorization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rollapps.push(RollappCriteria.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FulfillOrderAuthorization {
    return {
      rollapps: Array.isArray(object?.rollapps) ? object.rollapps.map((e: any) => RollappCriteria.fromJSON(e)) : [],
    };
  },

  toJSON(message: FulfillOrderAuthorization): unknown {
    const obj: any = {};
    if (message.rollapps) {
      obj.rollapps = message.rollapps.map((e) => e ? RollappCriteria.toJSON(e) : undefined);
    } else {
      obj.rollapps = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FulfillOrderAuthorization>, I>>(object: I): FulfillOrderAuthorization {
    const message = createBaseFulfillOrderAuthorization();
    message.rollapps = object.rollapps?.map((e) => RollappCriteria.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRollappCriteria(): RollappCriteria {
  return {
    rollappId: "",
    denoms: [],
    maxPrice: [],
    minLpFeePercentage: undefined,
    operatorFeeShare: undefined,
    settlementValidated: false,
    spendLimit: [],
  };
}

export const RollappCriteria = {
  encode(message: RollappCriteria, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rollappId !== "") {
      writer.uint32(10).string(message.rollappId);
    }
    for (const v of message.denoms) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.maxPrice) {
      Coin.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.minLpFeePercentage !== undefined) {
      DecProto.encode(message.minLpFeePercentage, writer.uint32(34).fork()).ldelim();
    }
    if (message.operatorFeeShare !== undefined) {
      DecProto.encode(message.operatorFeeShare, writer.uint32(42).fork()).ldelim();
    }
    if (message.settlementValidated === true) {
      writer.uint32(48).bool(message.settlementValidated);
    }
    for (const v of message.spendLimit) {
      Coin.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RollappCriteria {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRollappCriteria();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rollappId = reader.string();
          break;
        case 2:
          message.denoms.push(reader.string());
          break;
        case 3:
          message.maxPrice.push(Coin.decode(reader, reader.uint32()));
          break;
        case 4:
          message.minLpFeePercentage = DecProto.decode(reader, reader.uint32());
          break;
        case 5:
          message.operatorFeeShare = DecProto.decode(reader, reader.uint32());
          break;
        case 6:
          message.settlementValidated = reader.bool();
          break;
        case 7:
          message.spendLimit.push(Coin.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RollappCriteria {
    return {
      rollappId: isSet(object.rollappId) ? String(object.rollappId) : "",
      denoms: Array.isArray(object?.denoms) ? object.denoms.map((e: any) => String(e)) : [],
      maxPrice: Array.isArray(object?.maxPrice) ? object.maxPrice.map((e: any) => Coin.fromJSON(e)) : [],
      minLpFeePercentage: isSet(object.minLpFeePercentage) ? DecProto.fromJSON(object.minLpFeePercentage) : undefined,
      operatorFeeShare: isSet(object.operatorFeeShare) ? DecProto.fromJSON(object.operatorFeeShare) : undefined,
      settlementValidated: isSet(object.settlementValidated) ? Boolean(object.settlementValidated) : false,
      spendLimit: Array.isArray(object?.spendLimit) ? object.spendLimit.map((e: any) => Coin.fromJSON(e)) : [],
    };
  },

  toJSON(message: RollappCriteria): unknown {
    const obj: any = {};
    message.rollappId !== undefined && (obj.rollappId = message.rollappId);
    if (message.denoms) {
      obj.denoms = message.denoms.map((e) => e);
    } else {
      obj.denoms = [];
    }
    if (message.maxPrice) {
      obj.maxPrice = message.maxPrice.map((e) => e ? Coin.toJSON(e) : undefined);
    } else {
      obj.maxPrice = [];
    }
    message.minLpFeePercentage !== undefined
      && (obj.minLpFeePercentage = message.minLpFeePercentage
        ? DecProto.toJSON(message.minLpFeePercentage)
        : undefined);
    message.operatorFeeShare !== undefined
      && (obj.operatorFeeShare = message.operatorFeeShare ? DecProto.toJSON(message.operatorFeeShare) : undefined);
    message.settlementValidated !== undefined && (obj.settlementValidated = message.settlementValidated);
    if (message.spendLimit) {
      obj.spendLimit = message.spendLimit.map((e) => e ? Coin.toJSON(e) : undefined);
    } else {
      obj.spendLimit = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RollappCriteria>, I>>(object: I): RollappCriteria {
    const message = createBaseRollappCriteria();
    message.rollappId = object.rollappId ?? "";
    message.denoms = object.denoms?.map((e) => e) || [];
    message.maxPrice = object.maxPrice?.map((e) => Coin.fromPartial(e)) || [];
    message.minLpFeePercentage = (object.minLpFeePercentage !== undefined && object.minLpFeePercentage !== null)
      ? DecProto.fromPartial(object.minLpFeePercentage)
      : undefined;
    message.operatorFeeShare = (object.operatorFeeShare !== undefined && object.operatorFeeShare !== null)
      ? DecProto.fromPartial(object.operatorFeeShare)
      : undefined;
    message.settlementValidated = object.settlementValidated ?? false;
    message.spendLimit = object.spendLimit?.map((e) => Coin.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
