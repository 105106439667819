import { useCallback, useEffect, useReducer } from 'react';
import { useIbcStatus } from '../ibc-transfer/ibc-status/ibc-status-context';
import { loadPendingTransfers } from '../ibc-transfer/ibc-status/ibc-status-service';
import { IbcTransferDetails } from '../ibc-transfer/ibc-status/ibc-status-types';
import { useNetwork } from '../network/network-context';
import { useWallet } from '../wallet/wallet-context';
import { useClient } from '../client/client-context';
import { accountNetworkReducer, AccountNetworkState } from './account-network-state';
import { Network } from '../network/network-types';
import { getBalances, getEvmBalances, getSolanaBalances } from './account-service';
import { CoinsAmount } from '../currency/currency-types';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';

export const useAccountNetwork = (
    network?: Network,
    loadBalances: boolean = true,
    loadAddress: boolean = true,
    stateDefaults?: AccountNetworkState,
): [ AccountNetworkState, (network?: Network) => void ] => {
    const { rollAppParams, networkDenoms } = useNetwork();
    const { getFixedTransfer } = useIbcStatus();
    const { networkWalletMap, networkWalletTypeMap, handleWalletError } = useWallet();
    const { clientStateMap, connectClient } = useClient();
    const [ networkState, networkStateDispatch ] = useReducer(accountNetworkReducer, { ...stateDefaults, network });
    const cancelAndSetAddressPromise = useCancelablePromise<{ address?: string, hexAddress?: string }>();
    const cancelAndSetBalancesPromise = useCancelablePromise<{ balances: CoinsAmount[], fetchError?: any }>();
    const cancelAndSetEvmSolanaBalancesPromise = useCancelablePromise<CoinsAmount[]>();
    const cancelAndSetPendingTransfersPromise = useCancelablePromise<IbcTransferDetails[]>();
    const networkWallet = networkState.network ? networkWalletMap[networkState.network.chainId] : null;
    const clientState = networkState.network ? clientStateMap[networkState.network.chainId] : null;

    const setNetwork = useCallback((network?: Network): void => networkStateDispatch({ type: 'set-network', payload: network }), []);

    useEffect(() => {
        if (networkState.network?.rpc) {
            connectClient(networkState.network);
        }
    }, [ connectClient, networkState.network ]);

    useEffect(() => {
        if (networkState.network && !networkWallet && !networkWalletTypeMap[networkState.network.chainId]) {
            networkStateDispatch({ type: 'clear-data' });
            cancelAndSetBalancesPromise();
            cancelAndSetAddressPromise();
            cancelAndSetPendingTransfersPromise();
        }
    }, [
        networkWallet,
        cancelAndSetPendingTransfersPromise,
        cancelAndSetAddressPromise,
        cancelAndSetBalancesPromise,
        networkState.network,
        networkWalletTypeMap,
    ]);

    useEffect(() => {
        if (network) {
            networkStateDispatch({ type: 'set-network', payload: network });
            cancelAndSetBalancesPromise();
            cancelAndSetAddressPromise();
            cancelAndSetPendingTransfersPromise();
        }
    }, [ cancelAndSetPendingTransfersPromise, cancelAndSetAddressPromise, cancelAndSetBalancesPromise, network ]);

    useEffect(() => {
        if (!networkWallet || !networkState.network) {
            return;
        }
        networkStateDispatch({ type: 'set-address-loading' });
        if (loadBalances) {
            networkStateDispatch({ type: 'set-balances-loading' });
            if (networkState.network.type === 'Hub') {
                networkStateDispatch({ type: 'set-claimable-transfers-loading' });
            }
        }
        cancelAndSetAddressPromise(networkWallet.getAddress(networkState.network))
            .then((addresses) => networkStateDispatch({ type: 'set-address', payload: addresses }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-address', payload: undefined });
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                handleWalletError(error);
            });
    }, [ networkWallet, cancelAndSetAddressPromise, networkState.network, loadBalances, handleWalletError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            networkStateDispatch({ type: 'set-balances-loading', payload: false });
            return;
        }
        if (!loadBalances ||
            !networkWallet ||
            !networkState.network ||
            networkState.network.type === 'EVM' ||
            networkState.network.type === 'Solana' ||
            !networkState.address ||
            !clientState?.client ||
            clientState?.connecting) {
            return;
        }
        networkStateDispatch({ type: 'set-balances-loading' });
        cancelAndSetBalancesPromise(getBalances(clientState.client, networkState.address))
            .then(({ balances, fetchError }) => {
                networkStateDispatch({ type: 'set-balances-fetch-error', payload: fetchError });
                networkStateDispatch({ type: 'set-balances', payload: balances });
            })
            .catch((error) => {
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        networkWallet,
        cancelAndSetBalancesPromise,
        clientState,
        networkState.address,
        networkState.network,
        loadBalances,
    ]);

    useEffect(() => {
        if (!loadBalances ||
            !networkWallet ||
            !networkDenoms?.length ||
            !networkState.hexAddress ||
            !networkState.network ||
            (networkState.network?.type !== 'EVM')) {
            return;
        }
        networkStateDispatch({ type: 'set-balances-loading' });
        cancelAndSetEvmSolanaBalancesPromise(
            getEvmBalances(networkState.network, networkState.hexAddress, networkDenoms))
            .then((balances) => networkStateDispatch({ type: 'set-balances', payload: balances }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetEvmSolanaBalancesPromise,
        loadBalances,
        clientState,
        networkDenoms,
        networkState.hexAddress,
        networkState.network,
        networkWallet,
    ]);

    useEffect(() => {
        if (!loadBalances ||
            !networkWallet ||
            !networkDenoms?.length ||
            !networkState.address ||
            !networkState.network ||
            (networkState.network?.type !== 'Solana')) {
            return;
        }
        networkStateDispatch({ type: 'set-balances-loading' });
        cancelAndSetEvmSolanaBalancesPromise(getSolanaBalances(networkState.network, networkState.address, networkDenoms))
            .then((balances) => networkStateDispatch({ type: 'set-balances', payload: balances }))
            .catch((error) => {
                networkStateDispatch({ type: 'set-balances', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetEvmSolanaBalancesPromise,
        loadBalances,
        networkDenoms,
        clientState,
        networkState.address,
        networkState.network,
        networkWallet,
    ]);

    useEffect(() => {
        if (!loadBalances ||
            !rollAppParams ||
            !networkWallet ||
            !networkState.network ||
            !networkState.address ||
            !clientState ||
            networkState.network.type !== 'Hub') {
            return;
        }
        networkStateDispatch({ type: 'set-claimable-transfers-loading' });
        cancelAndSetPendingTransfersPromise((signal) => loadPendingTransfers(networkState.network!.chainId, networkState.address!, signal))
            .then((transfers) => {
                const fixedTransfers = transfers.map((transfer) => getFixedTransfer(transfer))
                    .filter((transfer) => transfer?.coins) as IbcTransferDetails[];
                const claimableTransfers = fixedTransfers.filter((transfer) => transfer.type === 'In' || transfer.status === 'Refunding');
                networkStateDispatch({ type: 'set-claimable-transfers', payload: claimableTransfers });
            })
            .catch((error) => {
                networkStateDispatch({ type: 'set-claimable-transfers', payload: undefined });
                networkStateDispatch({ type: 'set-error', payload: error });
            });
    }, [
        cancelAndSetPendingTransfersPromise,
        getFixedTransfer,
        loadBalances,
        clientState,
        networkState.address,
        networkState.network,
        networkWallet,
        rollAppParams,
    ]);

    return [ networkState, setNetwork ];
};


