import { deleteObject, uploadBytes } from '@firebase/storage';
import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { getDownloadURL } from 'firebase/storage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from '../../../../../../../../shared/components/snackbar/snackbar-context';
import { getFileSuffix } from '../../../../../../../../shared/utils/file-utils';
import { URL_REGEX } from '../../../../../../../../shared/utils/text-utils';
import { useHubNetworkState } from '../../../../../../../account/hub-network-state-context';
import { useClient } from '../../../../../../../client/client-context';
import { ClientError } from '../../../../../../../client/client-error';
import { App } from '../../../../../../../client/station-clients/dymension/generated/rollapp/app';
import { convertToCoinsAmount } from '../../../../../../../currency/currency-service';
import { CoinsAmount } from '../../../../../../../currency/currency-types';
import { useNetwork } from '../../../../../../../network/network-context';
import { TxState } from '../../../../../../../tx/tx-state';
import { DeliveryTxCode } from '../../../../../../../tx/tx-types';
import { useTx } from '../../../../../../../tx/use-tx';
import { useWallet } from '../../../../../../../wallet/wallet-context';
import { WalletError } from '../../../../../../../wallet/wallet-error';
import { useCreateRollapp } from '../../../../create-rollapp-context';
import { getRollappLogoFileRef } from '../../../../create-rollapp-service';
import { createAddAppMessage, createEmptyRollappApp, createUpdateAppMessage, getAppLogoFileRef } from '../apps-service';

interface UseEditAppValue {
    app: App;
    appLogoFile?: File;
    appTxState: TxState;
    saveApp: () => Promise<void>;
    updateApp: (key: keyof App, value: App[keyof App]) => void;
    setAppLogoFile: (files: File) => void;
    appRegistrationFee: CoinsAmount | null;
    appRegistrationFeeLoading: boolean;
    showErrors: boolean;
    logoUploading: boolean;
    appNameAlreadyExists?: boolean;
}

export const useEditApp = (appToEdit?: App): UseEditAppValue => {
    const { showErrorMessage } = useSnackbar();
    const { hubNetwork, rollAppParams, refreshRollapp } = useNetwork();
    const networkState = useHubNetworkState();
    const { clientStateMap, handleClientError } = useClient();
    const { handleWalletError } = useWallet();
    const [ appRegistrationFee, setAppRegistrationFee ] = useState<CoinsAmount | null>(null);
    const [ appRegistrationFeeLoading, setAppRegistrationFeeLoading ] = useState(true);
    const { rollapp } = useCreateRollapp();
    const [ app, setApp ] = useState(appToEdit || createEmptyRollappApp(rollapp));
    const [ appLogoFile, setAppLogoFile ] = useState<File>();
    const [ showErrors, setShowErrors ] = useState(false);
    const [ logoUploading, setLogoUploading ] = useState(false);
    const [ appTxResponseHash, setAppTxResponseHash ] = useState('');

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    const appNameAlreadyExists = useMemo(
        () => rollapp.apps?.some((otherApp) => otherApp.name === app.name && otherApp.id !== app.id),
        [ app.id, app.name, rollapp.apps ],
    );

    const appMessagesCreator = useCallback((fee?: CoinsAmount, params?: { logoUrl?: string }): EncodeObject[] => {
        if (!networkState.address || !app.name || appNameAlreadyExists) {
            return [];
        }
        return appToEdit ?
            [ createUpdateAppMessage(app, networkState.address, params?.logoUrl) ] :
            [ createAddAppMessage(app, networkState.address, params?.logoUrl) ];
    }, [ app, appNameAlreadyExists, appToEdit, networkState.address ]);

    const {
        txState: appTxState,
        broadcast: broadcastAppTx,
        calculateFee: calculateAppTxFee,
        clearFee: clearAppTxFee,
    } = useTx({
        networkState: networkState,
        txMessagesCreator: appMessagesCreator,
    });

    const updateApp = useCallback((key: keyof App, value: App[keyof App]): void => {
        setApp((app) => ({ ...app, [key]: value }));
    }, []);

    useEffect(() => {
        const { deliveryTxCode, hash, params } = appTxState.response || {};
        if (deliveryTxCode !== DeliveryTxCode.SUCCESS || hash === appTxResponseHash) {
            return;
        }
        setAppTxResponseHash(hash || '');
        refreshRollapp(app.rollappId);

        if (params?.logoUrl) {
            if (app.imageUrl && app.imageUrl !== params.logoUrl) {
                try {
                    const fileRef = getRollappLogoFileRef(app.imageUrl);
                    deleteObject(fileRef).then().catch((error) => {
                        console.error(`Can't delete app logo`, error);
                    });
                } catch (ignore) {}
            }
            updateApp('imageUrl', params.logoUrl);
            setAppLogoFile(undefined);
        }
    }, [ app.imageUrl, app.rollappId, appTxResponseHash, appTxState.response, refreshRollapp, updateApp ]);

    const uploadRollappLogo = useCallback(async (): Promise<string> => {
        if (!appLogoFile) {
            return '';
        }
        const fileName = `${rollapp.chainId}-app-logo-${new Date().getTime()}.${getFileSuffix(appLogoFile.name)}`;
        const fileRef = getAppLogoFileRef(fileName);
        return uploadBytes(fileRef, appLogoFile)
            .then((result) => getDownloadURL(result.ref))
            .catch((error) => {
                console.error(`Can't upload app logo`, error);
                showErrorMessage(`Can't upload app logo, please try again later`);
                throw error;
            });
    }, [ rollapp.chainId, appLogoFile, showErrorMessage ]);

    const handleError = useCallback((error: any): void => {
        if (!error) {
            return;
        }
        if (error instanceof ClientError) {
            handleClientError(error);
        } else if (error instanceof WalletError) {
            handleWalletError(error);
        } else {
            console.error(error);
        }
        calculateAppTxFee(false);
        const { logoUrl } = appTxState.params || {};
        if (logoUrl) {
            deleteObject(getAppLogoFileRef(logoUrl)).then().catch((error) => {
                console.error(`Can't delete app logo`, error);
            });
        }
    }, [ appTxState.params, calculateAppTxFee, handleClientError, handleWalletError ]);

    useEffect(() => handleError(appTxState.error), [ handleError, appTxState.error ]);

    useEffect(() => {
        if (networkState.address && app.name?.trim() && !appNameAlreadyExists) {
            calculateAppTxFee();
        } else {
            clearAppTxFee();
        }
    }, [ networkState.address, calculateAppTxFee, clearAppTxFee, app.name, appNameAlreadyExists ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            setAppRegistrationFeeLoading(false);
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address || !rollAppParams?.appRegistrationFee) {
            return;
        }
        convertToCoinsAmount(rollAppParams.appRegistrationFee, clientState.client)
            .then(setAppRegistrationFee)
            .catch(handleClientError)
            .finally(() => setAppRegistrationFeeLoading(false));
    }, [ clientState, handleClientError, networkState.address, rollAppParams?.appRegistrationFee ]);

    const saveApp = useCallback(async () => {
        if (!app.url || !URL_REGEX.test(app.url) || !app.name?.trim() || appNameAlreadyExists || (!appToEdit && !appLogoFile)) {
            setShowErrors(true);
            return;
        }
        setShowErrors(false);
        let logoUrl = '';
        if (appLogoFile) {
            setLogoUploading(true);
            logoUrl = await uploadRollappLogo();
            setLogoUploading(false);
        }
        return broadcastAppTx(undefined, { logoUrl });
    }, [ app.name, app.url, appLogoFile, appNameAlreadyExists, appToEdit, broadcastAppTx, uploadRollappLogo ]);

    return {
        app,
        appLogoFile,
        showErrors,
        updateApp,
        appTxState,
        saveApp,
        logoUploading,
        appRegistrationFee,
        appRegistrationFeeLoading,
        appNameAlreadyExists,
        setAppLogoFile,
    };
};
