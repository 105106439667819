import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useMemo } from 'react';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { Asset } from '../../asset/asset-types';
import { useClient } from '../../client/client-context';
import { ClientError } from '../../client/client-error';
import { isDenomsEquals } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { TxState } from '../../tx/tx-state';
import { useTx } from '../../tx/use-tx';
import { useIRO } from '../iro-context';
import { createClaimIroMessage, createIroDenom } from '../iro-service';

const useClaimIro = (asset: Asset): { txState: TxState, broadcast: () => void, iroBalance?: CoinsAmount } => {
    const { handleClientError } = useClient();
    const { getIroPlan } = useIRO();
    const networkState = useHubNetworkState();

    const iroBalance = useMemo(() => {
        const iroDenom = createIroDenom(asset.networkId);
        return networkState.balances?.find((balance) => isDenomsEquals(balance, iroDenom));
    }, [ asset.networkId, networkState.balances ]);

    const iroPlan = useMemo(() => getIroPlan(asset.networkId), [ asset.networkId, getIroPlan ]);

    const claimIroMessageCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !iroPlan || !iroBalance?.amount) {
            return [];
        }
        return [ createClaimIroMessage(iroPlan.id.toString(), networkState.address) ];
    }, [ iroBalance?.amount, iroPlan, networkState.address ]);

    const { txState, broadcast, calculateFee, clearFee } = useTx({
        networkState: networkState,
        txMessagesCreator: claimIroMessageCreator,
    });

    useEffect(() => {
        if (networkState.address && iroPlan && iroBalance?.amount) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, iroBalance?.amount, iroPlan, networkState.address ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, txState.error ]);

    return { txState, iroBalance, broadcast };
};

export default useClaimIro;
