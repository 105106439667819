import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import useVisibility from '../../shared/hooks/use-visibility';
import { ClientState, SigningClientState } from './client-types';
import { SigningWasmStationClient } from './station-clients/signing-wasm-station-client';
import { StationClient } from './station-clients/station-client';
import { Network } from '../network/network-types';
import { useWallet } from '../wallet/wallet-context';
import { SigningStationClient } from './station-clients/signing-station-client';
import { ClientError } from './client-error';
import { WalletError } from '../wallet/wallet-error';
import { useNetwork } from '../network/network-context';

interface ClientContextValue {
    clientStateMap: { [networkId: string]: ClientState | undefined };
    signingClientStateMap: { [networkId: string]: SigningClientState | undefined };
    clientError?: ClientError;
    refreshClient: (networkId: string) => void;
    connectClient: (network: Network) => void;
    connectSigningClient: (network: Network) => void;
    handleClientError: (error: any) => void;
}

const DENIED_MESSAGE_SIGNATURE = -32603;

export const ClientContext = createContext<ClientContextValue>({} as ClientContextValue);

export const useClient = (): ClientContextValue => useContext(ClientContext);

export const ClientContextProvider = ({ children }: { children: ReactNode }) => {
    const { networks, hubNetwork, hubChannelNetworkMap, networkDenoms, vfcMap } = useNetwork();
    const { networkWalletMap, handleWalletError } = useWallet();
    const [ clientStateMap, setClientStateMap ] = useState<{ [networkId: string]: ClientState | undefined }>({});
    const [ signingClientStateMap, setSigningClientStateMap ] = useState<{ [networkId: string]: SigningClientState | undefined }>({});
    const [ clientError, setClientError ] = useState<ClientError>();
    const isVisible = useVisibility();

    useEffect(() => {
        if (isVisible) {
            setClientStateMap((clientStateMap) => ({
                ...Object.keys(clientStateMap)
                    .reduce((current, networkId) => ({ ...current, [networkId]: { ...clientStateMap[networkId] } }), {}),
            }));
        }
    }, [ isVisible ]);

    const refreshClient = useCallback((networkId: string): void => {
        setClientStateMap({ ...clientStateMap, [networkId]: { ...clientStateMap[networkId] } });
    }, [ clientStateMap ]);

    const updateClientState = useCallback((networkId: string, data: ClientState): void => {
        setClientStateMap((clientStateMap) => ({
            ...clientStateMap, [networkId]: { ...clientStateMap[networkId], ...data },
        }));
    }, []);

    const updateSigningClientState = useCallback((networkId: string, data: SigningClientState): void => {
        setSigningClientStateMap((signingClientStateMap) => ({
            ...signingClientStateMap, [networkId]: { ...signingClientStateMap[networkId], ...data },
        }));
    }, []);

    const handleClientError = useCallback((error: any): void => {
        if (error?.originalError instanceof WalletError) {
            handleWalletError(error.originalError);
        } else if (error instanceof ClientError && error.code !== 'SIMULATE_TX_FAILED' && error.code !== 'CONNECT_CLIENT_FAILED') {
            if (error.originalError?.message?.includes?.('Send some tokens there before trying to query')) {
                error = new ClientError('NO_BALANCES', error.network, error.originalError);
            } else if (error.originalError?.message?.includes?.('insufficient funds')) {
                error = new ClientError('INSUFFICIENT_FUNDS', error.network, error.originalError);
            } else if (error.originalError?.message?.includes?.('insufficient fees')) {
                error = new ClientError('INSUFFICIENT_FEES', error.network, error.originalError);
            } else if (error.originalError?.message?.includes?.('signature verification failed')) {
                error = new ClientError('SIGNATURE_VERIFICATION_FAILED', error.network, error.originalError);
            } else if (error.originalError?.message?.includes?.('invalid coins')) {
                error = new ClientError('INVALID_COINS', error.network, error.originalError);
            } else if (error.originalError?.message?.includes('reject') ||
                error.originalError?.message?.includes('User denied') ||
                error.originalError?.code === DENIED_MESSAGE_SIGNATURE) {
                error = new ClientError('REQUEST_REJECTED', error.network, error.originalError);
            } else if (error.originalError?.message?.includes('Message not supported')) {
                error = new ClientError('UNSUPPORTED_MESSAGE', error.network, error.originalError);
            }
            setClientError(error);
            setTimeout(() => setClientError(undefined), 50); // todo: do it different (the error object should be paces at the specific modules)
        } else {
            console.error(error);
        }
    }, [ handleWalletError ]);

    const connectClient = useCallback((network: Network): void => {
        if (network.type === 'EVM' || network.type === 'Solana') {
            return;
        }
        setClientStateMap((clientStateMap) => {
            const clientState = clientStateMap[network.chainId];
            if (!hubNetwork || clientState?.client || clientState?.connecting) {
                return clientStateMap;
            }
            StationClient.connectForNetwork(network, hubNetwork, networks, hubChannelNetworkMap, networkDenoms, vfcMap)
                .then((client) => updateClientState(network.chainId, { client, connecting: !Boolean(networkDenoms) }))
                .catch((error) => {
                    handleClientError(error);
                    updateClientState(network.chainId, { connecting: false });
                });

            return { ...clientStateMap, [network.chainId]: { client: undefined, connecting: true } };
        });
    }, [ handleClientError, hubChannelNetworkMap, hubNetwork, networkDenoms, networks, updateClientState, vfcMap ]);

    useEffect(() => {
        if (!networkDenoms || !vfcMap) {
            return;
        }
        const clientsWithoutHubNetworkDenoms = Object.keys(clientStateMap).filter((key) => {
            const clientState = clientStateMap[key];
            return clientState?.client && !clientState.client.getHubNetworkDenoms();
        });
        if (!clientsWithoutHubNetworkDenoms.length) {
            return;
        }
        clientsWithoutHubNetworkDenoms.forEach((key) => {
            const clientState = clientStateMap[key];
            if (clientState?.client) {
                clientState.client.setHubNetworkDenoms(networkDenoms);
                clientState.client.setHubVfcMap(vfcMap);
                clientState.connecting = false;
            }
        });
        setClientStateMap(
            clientsWithoutHubNetworkDenoms.reduce((current, key) => ({ ...current, [key]: { ...clientStateMap[key] } }), clientStateMap));
    }, [ clientStateMap, networkDenoms, vfcMap ]);

    const connectSigningClient = useCallback((network: Network): void => {
        if (network.type === 'EVM' || network.type === 'Solana') {
            return;
        }
        setSigningClientStateMap((signingClientStateMap) => {
            const wallet = networkWalletMap[network.chainId];
            const queryClient = clientStateMap[network.chainId]?.client;
            if (!wallet || !queryClient) {
                return {};
            }
            const signingClientState = signingClientStateMap[network.chainId];
            if (signingClientState?.connecting ||
                (signingClientState?.client && signingClientState.client.getWallet().getWalletType() === wallet.getWalletType())) {
                return signingClientStateMap;
            }
            const connectPromises: [ Promise<SigningStationClient>, Promise<SigningWasmStationClient>? ] =
                [ SigningStationClient.connectForQueryClient(queryClient, wallet) ];
            if (network.evmType === 'WASM') {
                connectPromises.push(SigningWasmStationClient.connectForQueryClient(queryClient, wallet));
            }
            Promise.all(connectPromises)
                .then(([ client, wasmClient ]) => updateSigningClientState(network.chainId, { client, wasmClient, connecting: false }))
                .catch((error) => {
                    if (error instanceof WalletError) {
                        handleWalletError(error);
                    } else {
                        handleClientError(error);
                    }
                    updateSigningClientState(network.chainId, { connecting: false });
                });
            return { ...signingClientStateMap, [network.chainId]: { client: undefined, connecting: true } };
        });
    }, [ clientStateMap, handleClientError, handleWalletError, networkWalletMap, updateSigningClientState ]);

    useEffect(() => {
        // todo: move to other place
        if (hubNetwork) {
            connectClient(hubNetwork);
        }
    }, [ connectClient, hubNetwork ]);

    useEffect(() => {
        if (hubNetwork && clientStateMap[hubNetwork.chainId]?.client) {
            connectSigningClient(hubNetwork);
        }
    }, [ clientStateMap, connectSigningClient, hubNetwork ]);

    return (
        <ClientContext.Provider
            value={{
                clientStateMap,
                signingClientStateMap,
                refreshClient,
                clientError,
                connectClient,
                connectSigningClient,
                handleClientError,
            }}
        >
            {children}
        </ClientContext.Provider>
    );
};
