import classNames from 'classnames';
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../shared/components/alert/alert';
import Confirm from '../../shared/components/confirm/confirm';
import Input from '../../shared/components/form-controls/input/input';
import Icon from '../../shared/components/icon/icon';
import InfoIndicator from '../../shared/components/info-indicator/info-indicator';
import { MenuRefProps } from '../../shared/components/menu/menu';
import Spinner from '../../shared/components/spinner/spinner';
import TabBar, { Tab } from '../../shared/components/tabs/tab-bar';
import ToggleSwitch from '../../shared/components/toggle-switch/toggle-switch';
import useWindowSize from '../../shared/hooks/use-window-size';
import { formatNumber } from '../../shared/utils/number-utils';
import ImportTokenDialog, { ImportTokenDialogProps } from '../asset/import-token-dialog/import-token-dialog';
import { isCoinsEquals } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import NetworkSelector from '../network/network-selector/network-selector';
import GetTokensSection from '../tx/amount-tx/get-tokens-section/get-tokens-section';
import { DEFAULT_EIBC_FEE, useIbcTransfer } from './ibc-transfer-context';
import { ReactComponent as SwapIcon } from '../../assets/icons/swap-vert.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as ExplorerIcon } from '../../assets/icons/explorer.svg';
import { ReactComponent as HistoryIcon } from '../../assets/icons/history.svg';
import { useSnackbar } from '../../shared/components/snackbar/snackbar-context';
import AmountTx from '../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../tx/tx-types';
import { SnackbarMessage } from '../../shared/components/snackbar/snackbar-types';
import { AccountNetworkState } from '../account/account-network-state';
import { IbcDirection, Network } from '../network/network-types';
import Button from '../../shared/components/button/button';
import { useNetwork } from '../network/network-context';
import { DepositWithdrawMode } from './ibc-transfer-types';
import BridgeConfirmationDialog from './bridge/bridge-confirmation-dialog/bridge-confirmation-dialog';
import './ibc-transfer.scss';

export interface IbcTransferProps {
    title?: string;
    getTokensSectionVisible?: boolean;
    depositWithdrawMode?: DepositWithdrawMode;
    availableBalances?: CoinsAmount[],
    onDepositWithdrawModeChange?: (mode: DepositWithdrawMode) => void;
    historyTab?: ReactNode;
}

const IbcTransfer: React.FC<IbcTransferProps> = ({
    title,
    getTokensSectionVisible,
    depositWithdrawMode,
    availableBalances,
    onDepositWithdrawModeChange,
    historyTab,
}) => {
    const navigate = useNavigate();
    const { showErrorMessage } = useSnackbar();
    const { isMobile } = useWindowSize();
    const { rollAppParams, rollAppParamsLoading } = useNetwork();
    const {
        sourceData,
        destinationData,
        hubNetworkData,
        txState,
        amountTxState,
        error,
        optionalNetworks,
        optionalSourceNetworks,
        optionalDestinationNetworks,
        transferEnabled,
        noRoutesBalances,
        cctpMinAmountToTransfer,
        destinationNetworksWithRoutes,
        bridgeState,
        useEIbc,
        eibcFee,
        setUseEIbc,
        setEibcFee,
        getInitializedIbcTransferDetailsId,
        transfer,
        setSource,
        setDestination,
        setCoins,
    } = useIbcTransfer();
    const [ importTokenDialogProps, setImportTokenDialogProps ] = useState<ImportTokenDialogProps>();
    const [ eibcSwitchConfirmDialogOpen, setEibcSwitchConfirmDialogOpen ] = useState(false);
    const [ bridgeConfirmationVisible, setBridgeConfirmationVisible ] = useState(false);
    const selectRefs = useRef<MenuRefProps[]>([]);
    const [ finishedValue, setFinishedValue ] = useState<number>();

    const noRoute = useMemo(
        () => noRoutesBalances?.some((balance) => amountTxState.coins && isCoinsEquals(balance, amountTxState.coins)),
        [ amountTxState.coins, noRoutesBalances ],
    );

    const intermediateHub = useMemo(
        () => (sourceData.network?.type === 'RollApp' && destinationData.network?.type !== 'Hub') ||
            (sourceData.network?.type !== 'Hub' && destinationData.network?.type === 'RollApp'),
        [ destinationData.network?.type, sourceData.network?.type ],
    );

    const isNetworkSelectable = useCallback((network: Network, direction: IbcDirection, canSwap?: boolean) => {
        if (network.hidden) {
            return false;
        }
        return (direction === 'Source' ?
                (!optionalSourceNetworks || optionalSourceNetworks.includes(network.chainId)) :
                (!optionalDestinationNetworks || optionalDestinationNetworks.includes(network.chainId))) &&
            (canSwap || (direction === 'Source' ?
                network.chainId !== destinationData.network?.chainId : network.chainId !== sourceData.network?.chainId));
    }, [
        destinationData.network?.chainId,
        optionalDestinationNetworks,
        optionalSourceNetworks,
        sourceData.network?.chainId,
    ]);

    const destinationNetworks = useMemo(() => optionalNetworks.sort((network1, network2) => {
        const network1HaveRoute = destinationNetworksWithRoutes?.some((network) => network.chainId === network1.chainId);
        const network2HaveRoute = destinationNetworksWithRoutes?.some((network) => network.chainId === network2.chainId);
        return (network2HaveRoute ? 1 : 0) - (network1HaveRoute ? 1 : 0);
    }), [ optionalNetworks, destinationNetworksWithRoutes ]);

    const getTxResponseMessage = useCallback((response: TxResponse): Partial<SnackbarMessage> | undefined => {
        if (response.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            let title = 'IBC transfer successfully sent!';
            if (sourceData.network?.type === 'RollApp') {
                title = useEIbc ? 'Fast withdrawal order successfully sent!' : 'IBC transfer successfully sent!';
            }
            const showIbcStatusLink = (sourceData.network?.type === 'Hub' || destinationData.network?.type === 'Hub' ||
                sourceData.network?.type === 'RollApp' || destinationData.network?.type === 'RollApp');
            return {
                duration: (sourceData.network?.type === 'RollApp' ? 86400000 : sourceData.network?.ibc?.timeout) || 600000,
                content: response.network.type !== 'RollApp' ? title : <>
                    <b>{title}</b>
                    <span className='finalization-text'>
                        {useEIbc ? 'Tokens will be received in your wallet post fulfillment' : 'Pending Hub finalization'}
                    </span>
                </>,
                type: showIbcStatusLink ? 'pending' : 'success',
                key: showIbcStatusLink ? getInitializedIbcTransferDetailsId() : response.hash,
                action: showIbcStatusLink ? {
                    label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>,
                    callback: () => navigate(`/ibc/status/${getInitializedIbcTransferDetailsId()}`),
                } : !response.network.exploreTxUrl ? undefined : {
                    label: <><ExplorerIcon />&nbsp;&nbsp;Explore</>,
                    callback: () => window.open(response.network.exploreTxUrl + response.hash, '_blank'),
                },
            };
        }
    }, [
        destinationData.network?.type,
        getInitializedIbcTransferDetailsId,
        navigate,
        sourceData.network?.ibc?.timeout,
        sourceData.network?.type,
        useEIbc,
    ]);

    const swapNetworksEnabled = useMemo(
        () => !depositWithdrawMode && sourceData.network && destinationData.network &&
            isNetworkSelectable(destinationData.network, 'Source', true) &&
            isNetworkSelectable(sourceData.network, 'Destination', true),
        [ depositWithdrawMode, destinationData.network, isNetworkSelectable, sourceData.network ],
    );

    const bridgingDisabled = useMemo(
        () => txState.broadcasting || bridgeState.broadcasting,
        [ bridgeState.broadcasting, txState.broadcasting ],
    );

    const onEibcValueChange = useCallback((value: string, previousValue: string) => {
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        const amountPattern = new RegExp('^[0-9]*(\\.[0-9]{0,2})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        if (!value) {
            setEibcFee(undefined);
            return '';
        }
        const amount = Number(value);
        if (amount) {
            setEibcFee(Math.min(20, Math.max(0, amount)));
        } else {
            setEibcFee(undefined);
        }
        return amount > 20 ? '20' : undefined;
    }, [ setEibcFee ]);

    useEffect(() => {
        if (!error) {
            return;
        }
        switch (error.code) {
            case 'MISSING_CHANNEL':
                showErrorMessage(`Missing IBC channel for ${error.network?.chainName || 'the current network'}`);
                break;
            default:
                showErrorMessage(`Can't perform IBC transaction, please try again later`);
        }
    }, [ error, showErrorMessage ]);

    const swapNetworks = useCallback((): void => {
        if (swapNetworksEnabled) {
            setSource(destinationData.network);
            setDestination(sourceData.network);
        }
    }, [ destinationData.network, setDestination, setSource, sourceData.network, swapNetworksEnabled ]);

    const renderNetworkSelector = (
        label: string,
        direction: IbcDirection,
        onNetworkSelect: (chainId: string) => void,
        networkState?: AccountNetworkState,
    ): ReactElement => {
        return (
            <div className='network-selectors-container'>
                <label className='network-label'>{label}</label>
                <NetworkSelector
                    ref={(ref) => ref && selectRefs.current.push(ref)}
                    size={isMobile ? 'small' : 'medium'}
                    networkData={networkState}
                    networks={direction === 'Destination' ? destinationNetworks : optionalNetworks}
                    isNetworkSelectable={(network, commonNetwork) => isNetworkSelectable(network, direction, commonNetwork)}
                    switchNetworkAfterConnect={direction === 'Source'}
                    walletSelectorDisabled={(bridgeState.toUseBridge && bridgeState.broadcasting)}
                    optionsMenuOpenDisabled={(bridgeState.toUseBridge && bridgeState.broadcasting) || direction === 'Source' ?
                        (optionalSourceNetworks && optionalSourceNetworks?.length <= 1) :
                        (optionalDestinationNetworks && optionalDestinationNetworks.length <= 1)}
                    onNetworkSelect={(chainId, commonNetwork) => {
                        if (commonNetwork) {
                            selectRefs.current.forEach((ref) => ref.toggleMenu(false));
                            if (direction === 'Source' ?
                                destinationData.network?.chainId === chainId :
                                sourceData.network?.chainId === chainId) {
                                swapNetworks();
                                return;
                            }
                        }
                        onNetworkSelect(chainId.toString());
                    }}
                />
            </div>
        );
    };

    const renderDialogContent = (): ReactNode => {
        return <>
            {renderNetworkSelector('From network', 'Source', setSource, sourceData)}

            <Button
                style={{ opacity: depositWithdrawMode ? 0 : 1 }}
                className='swap-button'
                buttonType='icon'
                size='x-large'
                disabled={!swapNetworksEnabled}
                onClick={swapNetworks}
            >
                <SwapIcon />
            </Button>

            {renderNetworkSelector('To network', 'Destination', setDestination, destinationData)}

            <label className='amount-label'>Amount</label>
            <AmountTx
                txState={txState}
                amountTxState={amountTxState}
                networkState={sourceData}
                getTxResponseMessage={getTxResponseMessage}
                onCoinsChange={setCoins}
                availableBalances={availableBalances}
                onTypeFinish={(value) => setFinishedValue(Number(value))}
                showMessages={!bridgeState.toUseBridge}
                controlSize='large'
                amountDisabled={bridgeState.broadcasting}
                loading={bridgeState.routeSearching && !txState.fee}
                emptyFee={Boolean(bridgeState.toUseBridge && !bridgeState.intermediateNetwork)}
                extraFees={sourceData.network?.type === 'RollApp' && useEIbc ?
                    [
                        {
                            label: (
                                <span className='bridging-fee'>
                                eIBC tip
                                <InfoIndicator indicatorSize='xs'>
                                    Fast withdrawal fee is paid out to Bridge Liquidity Providers for fulfilling transfers with no dispute periods.
                                </InfoIndicator>
                            </span>
                            ),
                            value: (
                                <Input
                                    className='eibc-fee-input'
                                    suffix='%'
                                    value={eibcFee}
                                    placeholder={DEFAULT_EIBC_FEE.toString()}
                                    onValueChange={onEibcValueChange}
                                />
                            ),
                        },
                    ] :
                    !depositWithdrawMode && !bridgeState.toUseBridge ? [] :
                        [ { label: 'External bridging fee', value: bridgeState.fee, loading: bridgeState.routeSearching } ]}
                submitButtonContainer={<>
                    {intermediateHub && (
                        <Alert className='ibc-alert' type='warning'>
                            <span>
                                Direct transfers between <b>{sourceData.network?.chainName}</b> and <b>{destinationData.network?.chainName}</b> are not supported in the current version.
                                Tokens must first be transferred to the <b>{hubNetworkData.network?.chainName}</b> before they can be sent to the destination network.
                            </span>
                        </Alert>
                    )}
                    {noRoute && (
                        // todo: temporary code - do it generic
                        <Alert className='ibc-alert' type='warning'>
                            No routes found for {amountTxState.coins?.currency.displayDenom} between <b>{sourceData.network?.chainName}</b> and <b>{destinationData.network?.chainName}</b>.<br />
                            {amountTxState.coins?.currency.baseDenom === 'erc20/tether/usdt' &&
                                sourceData.network?.type === 'EVM' && destinationData.network?.type === 'Hub' &&
                                <span>Choose <b>Kava</b> as a intermediate destination for transferring USDT to <b>{destinationData.network?.chainName}</b>.</span>}
                            {destinationData.network?.type === 'EVM' && amountTxState.coins?.currency.baseDenom === 'weth-wei' && (
                                <span>
                                    Use <a className='skip-link' href='https://ibc.fun/' rel='noreferrer' target='_blank'>Skip</a> to send
                                    &nbsp;{amountTxState.coins?.currency.displayDenom} to <b>{destinationData.network?.chainName}</b>.
                                </span>
                            )}
                        </Alert>
                    )}
                    {!noRoute && !intermediateHub && sourceData.network?.type === 'RollApp' &&
                        (!useEIbc || (eibcFee && eibcFee < DEFAULT_EIBC_FEE)) && (
                            <Alert className='ibc-alert' type='error'>
                                Transacting with a low eIBC tip or none at all may result in a wait period of {rollAppParamsLoading ?
                                <Spinner className='rollapp-params-spinner' size='xs' /> :
                                <b>{formatNumber(rollAppParams?.disputePeriodInBlocks || 0)}</b>} blocks.
                                For faster withdrawals consider increasing the tip.
                            </Alert>
                        )}
                    {amountTxState.coins?.amount && finishedValue && !noRoute && finishedValue < cctpMinAmountToTransfer ? (
                        <Alert className='ibc-alert' type='warning'>
                            Circle CCTP transfer cannot be less than {cctpMinAmountToTransfer} {amountTxState.coins?.currency.displayDenom}.
                        </Alert>
                    ) : undefined}
                    <Button
                        size='x-large'
                        loading={bridgingDisabled}
                        disabled={!transferEnabled || noRoute || intermediateHub}
                        onClick={() => bridgeState.toUseBridge ? setBridgeConfirmationVisible(true) : transfer()}
                    >
                        {depositWithdrawMode || 'Transfer'}
                    </Button>
                </>}
            />

            {sourceData.network?.type === 'RollApp' && (
                <ToggleSwitch
                    containerClassName='eibc-switch'
                    isChecked={useEIbc}
                    onCheck={(value) => {
                        if (value) {
                            setUseEIbc(value);
                        } else {
                            setEibcSwitchConfirmDialogOpen(true);
                            return false;
                        }
                    }}
                    size='small'
                >
                    Include fast withdrawal tip (eIBC)
                    <InfoIndicator>
                        RollApp withdrawals default uses Fast Withdrawals (eIBC), regular withdrawal transfers require dymension finalization which would delay the transfer.
                    </InfoIndicator>
                </ToggleSwitch>
            )}
        </>;
    };

    return <>
        <section className={classNames('section ibc-transfer-section', { wide: depositWithdrawMode === 'History' })}>
            {!depositWithdrawMode ? <h3 className='ibc-transfer-title'>{title || 'Transfer'}</h3> : (
                <TabBar
                    uniformTabWidth
                    size={isMobile ? 'medium' : 'large'}
                    className={classNames('deposit-withdraw-tab-bar', { history: depositWithdrawMode === 'History' })}
                    activeTabKey={depositWithdrawMode}
                    onTabChange={(tabKey) => {
                        onDepositWithdrawModeChange?.(tabKey as DepositWithdrawMode);
                        setSource(undefined);
                        setDestination(undefined);
                    }}
                >
                    <Tab
                        disabled={bridgingDisabled}
                        labelClassName='deposit-withdraw-tab-label'
                        label={<>
                            <Icon
                                color={!bridgingDisabled && depositWithdrawMode === 'Deposit' ? 'primary' : 'secondary'}
                                className='deposit-withdraw-icon'
                            >
                                <DownloadIcon />
                            </Icon>
                            Deposit
                        </>}
                        tabKey='Deposit'
                    />
                    <Tab
                        disabled={bridgingDisabled}
                        labelClassName='deposit-withdraw-tab-label'
                        label={<>
                            <Icon
                                color={!bridgingDisabled && depositWithdrawMode === 'Withdraw' ? 'primary' : 'secondary'}
                                className='deposit-withdraw-icon'
                            >
                                <UploadIcon />
                            </Icon>
                            Withdraw
                        </>}
                        tabKey='Withdraw'
                    />
                    {historyTab && (
                        <Tab
                            disabled={bridgingDisabled}
                            labelClassName='deposit-withdraw-tab-label'
                            label={<>
                                <Icon
                                    color={!bridgingDisabled && depositWithdrawMode === 'History' ? 'primary' : 'secondary'}
                                    className='deposit-withdraw-icon'
                                >
                                    <HistoryIcon />
                                </Icon>
                                History
                            </>}
                            tabKey='History'
                        />
                    )}
                </TabBar>
            )}

            {depositWithdrawMode === 'History' ? historyTab : renderDialogContent()}
        </section>

        {amountTxState.coins && getTokensSectionVisible &&
            <GetTokensSection coins={amountTxState.coins} coinsNetwork={sourceData.network} className='get-tokens-section' />}

        {importTokenDialogProps &&
            <ImportTokenDialog {...importTokenDialogProps} onRequestClose={() => setImportTokenDialogProps(undefined)} />}

        {eibcSwitchConfirmDialogOpen && <Confirm
            onRequestClose={() => setEibcSwitchConfirmDialogOpen(false)}
            onConfirm={() => setUseEIbc(false)}
            content='Transacting without Fast withdrawals (eIBC) will delay your transfers for the entire dispute period.'
            okLabel='Confirm'
            cancelLabel='Abort'
            warning
        />}

        {bridgeConfirmationVisible && <BridgeConfirmationDialog onRequestClose={() => setBridgeConfirmationVisible(false)} />}
    </>;
};

export default IbcTransfer;
