import { timeToMilliseconds } from '../../shared/utils/date-utils';
import { ClientError } from '../client/client-error';
import { Params as IncentivesModuleParams } from '../client/station-clients/dymension/generated/incentives/params';
import { Gauge } from '../client/station-clients/dymension/generated/incentives/gauge';
import { MsgCreateGaugeEncodeObject } from '../client/station-clients/dymension/generated/incentives/messages';
import { LockQueryType } from '../client/station-clients/dymension/generated/lockup/lock';
import { Stream } from '../client/station-clients/dymension/generated/streamer/stream';
import { StationClient } from '../client/station-clients/station-client';
import { convertToCoin, convertToCoinsAmount } from '../currency/currency-service';
import { CoinsAmount } from '../currency/currency-types';
import { getNetworkData } from '../network/network-service';
import { EpochIdentifier, Incentive, IncentivesParams, LOCK_DEFAULT_DURATION } from './types';

export const loadIncentivesParams = async (client: StationClient, signal: AbortSignal): Promise<IncentivesParams> => {
    const network = client.getNetwork();
    const params = await getNetworkData<IncentivesModuleParams>(network.chainId, 'incentives-params', true, signal);
    if (!params) {
        throw new ClientError('FETCH_DATA_FAILED', network, new Error(`Can't fetch incentive params`));
    }
    return { epochIdentifier: params.distrEpochIdentifier as EpochIdentifier };
};

export const loadIncentives = async (
    client: StationClient,
    params: IncentivesParams,
    signal: AbortSignal,
): Promise<{ gauges: Gauge[], streams: Stream[], incentives: { [denom: string]: Incentive[] } }> => {
    const network = client.getNetwork();
    const incentivesData = await getNetworkData<{ gauges?: Gauge[], streams?: Stream[] }>(network.chainId, 'incentives', true, signal);
    if (!incentivesData) {
        throw new ClientError('FETCH_DATA_FAILED', network, new Error(`Can't fetch incentives data`));
    }
    const streamGauges = (incentivesData.streams || []).filter((stream) => !stream.sponsored).reduce((current, stream) => {
        const records = (stream.distributeTo?.records || []).map((record) => {
            const gauge = incentivesData.gauges?.find((gauge) => gauge.id === record.gaugeId);
            if (!gauge) {
                return undefined;
            }
            return { gauge, stream, weight: Number(record.weight) };
        });
        return [ ...current, ...(records.filter(Boolean) as { gauge: Gauge, stream: Stream, weight: number }[]) ];
    }, [] as { gauge: Gauge, stream: Stream, weight: number }[]);

    const perpetualIncentives = await Promise.all(streamGauges?.map(({ gauge, stream, weight }) =>
        createIncentive(client, params, gauge, stream, weight)));

    const unPerpetualIncentives = await Promise.all((incentivesData.gauges || []).filter((gauge) => !gauge.isPerpetual).map((gauge) =>
        createIncentive(client, params, gauge)));

    const incentives = [ ...perpetualIncentives, ...unPerpetualIncentives ].reduce<{ [denom: string]: Incentive[] }>(
        (current, incentive) =>
            !incentive ? current : { ...current, [incentive.denom]: [ ...(current[incentive.denom] || []), incentive ] }, {});

    return { gauges: incentivesData.gauges || [], streams: incentivesData.streams || [], incentives };
};

const createIncentive = async (
    client: StationClient,
    params: IncentivesParams,
    gauge: Gauge,
    stream?: Stream,
    streamGaugeWeight?: number,
): Promise<Incentive | undefined> => {
    const denom = gauge.asset?.denom;
    if (!denom) {
        return undefined;
    }
    let baseCoins = gauge.coins;
    let baseDistributedCoins = gauge.distributedCoins;
    if (stream && streamGaugeWeight) {
        const part = streamGaugeWeight / Number(stream.distributeTo?.totalWeight);
        baseCoins = stream.coins.map((coin) => ({ ...coin, amount: (Number(coin.amount) * part).toString() }));
        baseDistributedCoins = stream.distributedCoins.map((coin) => ({ ...coin, amount: (Number(coin.amount) * part).toString() }));
    }
    const startTime = new Date(stream?.startTime || gauge.startTime || Date.now());
    const numEpochsPaidOver = stream?.numEpochsPaidOver || gauge.numEpochsPaidOver;
    const epochIdentifier = (stream?.distrEpochIdentifier as EpochIdentifier) || params.epochIdentifier;
    const endTime = new Date(startTime.getTime() + (epochToMilliseconds(epochIdentifier) * numEpochsPaidOver));
    if (isNaN(endTime.getTime())) {
        return undefined;
    }
    const coins = (await Promise.all(baseCoins.map(async (coin) => convertToCoinsAmount(coin, client)))).filter(Boolean) as CoinsAmount[];
    const distributedCoins =
        (await Promise.all(baseDistributedCoins.map(async (coin) => convertToCoinsAmount(coin, client)))).filter(Boolean) as CoinsAmount[];
    const epochMilliseconds = epochToMilliseconds(epochIdentifier);
    const yearMilliseconds = epochToMilliseconds('year');
    const yearPart = !epochMilliseconds ? 1 : (epochMilliseconds * numEpochsPaidOver / yearMilliseconds);
    return { denom, coins, distributedCoins, startTime, endTime, yearPart, epoch: epochIdentifier };
};

export const createCreateGaugeMessage = (
    owner: string,
    coinsAmount: CoinsAmount,
    denom: string,
    startTime: Date,
    endTime: Date,
    incentivesParams: IncentivesParams,
    baseAmountWithoutFee?: bigint,
): MsgCreateGaugeEncodeObject => {
    const numEpochsPaidOver = Math.round((endTime.getTime() - startTime.getTime()) / epochToMilliseconds(incentivesParams.epochIdentifier));
    const coins = [ convertToCoin(coinsAmount) ];
    if (baseAmountWithoutFee !== undefined && baseAmountWithoutFee < BigInt(coins[0].amount)) {
        coins[0].amount = baseAmountWithoutFee.toString();
    }
    return {
        typeUrl: '/dymensionxyz.dymension.incentives.MsgCreateGauge',
        value: {
            owner,
            coins,
            distributeTo: {
                denom,
                lockQueryType: LockQueryType.ByDuration,
                duration: LOCK_DEFAULT_DURATION,
                timestamp: undefined,
            },
            isPerpetual: false,
            numEpochsPaidOver,
            startTime,
        },
    };
};

export const epochToMilliseconds = (epochIdentifier: EpochIdentifier | string): number => {
    switch (epochIdentifier) {
        case 'minute':
            return timeToMilliseconds({ minutes: 1 });
        case 'hour':
            return timeToMilliseconds({ hours: 1 });
        case 'day':
        case 'daily':
            return timeToMilliseconds({ days: 1 });
        case 'week':
        case 'weekly':
            return timeToMilliseconds({ days: 7 });
        case 'month':
        case 'monthly':
            return timeToMilliseconds({ days: 30 });
        case 'year':
        case 'yearly':
            return timeToMilliseconds({ days: 365 });
        default:
            return 0;
    }
};

export const getDisplayedTargetTime = (date: Date): string => {
    const diffDate = date.getTime() - new Date().getTime();
    const minutes = diffDate / (1000 * 60);
    if (minutes <= 1) {
        return 'less than 1 minute';
    } else if (minutes < 120.5) {
        return `${Math.round(minutes)} minutes`;
    }
    const hours = minutes / 60;
    if (hours < 48.5) {
        return `${Math.round(hours)} hours`;
    }
    return `${Math.round(hours / 24)} days`;
};
