import classNames from 'classnames';
import { Decimal } from 'cosmjs/packages/math';
import React, { useMemo, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { Point } from 'recharts/types/shape/Curve';
import { getCssVariableValue } from '../../../../../shared/utils/color-utils';
import { formatNumber, formatPrice } from '../../../../../shared/utils/number-utils';
import { useIRO } from '../../../../iro/iro-context';
import { calculateBondingCurvePoints, convertToBondingCurve, fetchPlanTargetRaise, getCurveType } from '../../../../iro/iro-service';
import ChartContainer from '../../../../network/statistics/charts/chart-container/chart-container';
import ChartTooltip from '../../../../network/statistics/charts/chart-tooltip/chart-tooltip';
import { CURVE_OPTIONS, DEFAULT_DENOM_EXPONENT } from '../../../../rollapp/manage-rollapps-page/create-rollapp-page/types';
import { Asset } from '../../../asset-types';
import './bonding-curve-chart.scss';

interface BondingCurveChartProps {
    asset: Asset;
    className?: string;
}

export const BondingCurveChart: React.FC<BondingCurveChartProps> = ({ asset, className }) => {
    const { getIroPlan, loading: iroLoading } = useIRO();
    const [ chartWidth, setChartWidth ] = useState(0);

    const iroPlan = useMemo(() => getIroPlan(asset.network.chainId), [ getIroPlan, asset.network.chainId ]);

    const loading = useMemo(
        () => !iroPlan && (iroLoading || asset.network.status === 'IRO'),
        [ asset.network.status, iroLoading, iroPlan ],
    );

    const bondingCurve = useMemo(
        () => iroPlan?.bondingCurve ? convertToBondingCurve(iroPlan?.bondingCurve) : undefined,
        [ iroPlan?.bondingCurve ],
    );

    const curveType = useMemo(() => !bondingCurve ? undefined : getCurveType(bondingCurve), [ bondingCurve ]);

    const curveOption = useMemo(() => CURVE_OPTIONS.find((option) => option.type === curveType), [ curveType ]);

    const points = useMemo(() => {
        if (!iroPlan?.bondingCurve || !iroPlan.totalAllocation) {
            return [];
        }
        const allocation = Decimal.fromAtomics(iroPlan.totalAllocation.amount, DEFAULT_DENOM_EXPONENT).toFloatApproximation();
        return calculateBondingCurvePoints(convertToBondingCurve(iroPlan.bondingCurve), allocation, 1001);
    }, [ iroPlan ]);

    const currentSold = useMemo((): { value: number, point: Point } | undefined => {
        if (!iroPlan?.soldAmt) {
            return undefined;
        }
        const soldAmount = Decimal.fromAtomics(iroPlan.soldAmt, DEFAULT_DENOM_EXPONENT).toFloatApproximation();
        return {
            value: soldAmount,
            point: points.reduce((current, point) => Math.abs(point.x - soldAmount) < Math.abs(current.x - soldAmount) ? point : current),
        };
    }, [ iroPlan?.soldAmt, points ]);

    const yAxisDomain = useMemo(
        () => !points.length ? undefined : [ 0, points[points.length - 1].y * (curveType === 'Fixed' ? 2 : 1) ],
        [ curveType, points ],
    );

    const targetRaise = useMemo(() => iroPlan && fetchPlanTargetRaise(iroPlan), [ iroPlan ]);

    const showLabelCentered = useMemo(
        () => points.length && chartWidth * (1 - (currentSold?.point.x || 0) / points[points.length - 1].x) >= 75,
        [ chartWidth, currentSold?.point.x, points ],
    );

    return (
        <ChartContainer
            label={curveOption?.title || 'Bonding Curve'}
            info={curveOption?.info}
            className={classNames('bonding-curve-chart-container', className)}
            hideStatisticsChange
            loading={loading}
            headerClassName='curve-chart-header'
            defaultOptionalPeriods={[]}
            onResize={({ width }) => setChartWidth(width)}
            maxHeight={256}
        >
            <AreaChart data={points} margin={{ top: 24, right: 0 }}>
                <CartesianGrid strokeDasharray='3 3' className='chart-grid' />

                <XAxis
                    dataKey='x'
                    height={24}
                    tickFormatter={(value) => formatPrice(value, '', { notation: 'compact' })}
                    fontSize={12}
                    tickMargin={6}
                />

                <YAxis
                    fontSize={12}
                    width={90}
                    tickFormatter={(value) => {
                        let options: Intl.NumberFormatOptions;
                        if (value < 0.001) {
                            options = { maximumSignificantDigits: 2 };
                        } else if (value < 1) {
                            options = { minimumFractionDigits: 2, maximumFractionDigits: 5 };
                        } else {
                            options = { notation: 'compact', minimumFractionDigits: 2, maximumFractionDigits: 3 };
                        }
                        return `${formatPrice(value, 'DYM', options, undefined, 2)}`;
                    }}
                    domain={yAxisDomain}
                />

                {!currentSold?.value ? undefined : (
                    <ReferenceLine
                        x={currentSold.point.x}
                        stroke={getCssVariableValue('--green')}
                        strokeWidth={2}
                        strokeDasharray='3 3'
                        label={{
                            value: formatPrice(currentSold.value, asset.currency.displayDenom, { notation: 'compact' }),
                            fill: getCssVariableValue('--green'),
                            fontSize: 12,
                            ...(showLabelCentered ? {
                                position: 'top',
                                offset: 12,
                            } : {
                                position: 'insideTopRight',
                                offset: 0,
                                baselineShift: 21,
                            }),
                        }}
                    />
                )}

                {!targetRaise ? undefined : (
                    <ReferenceLine
                        x={points[points.length - 1].x}
                        strokeWidth={0}
                        label={{
                            value: formatPrice(targetRaise, 'DYM', { notation: 'compact' }),
                            fontSize: 12,
                            fontWeight: 'bold',
                            fill: getCssVariableValue('--cream'),
                            baselineShift: 4,
                            position: 'insideBottomRight',
                        }}
                    />
                )}

                <Tooltip
                    content={(props) => <ChartTooltip
                        {...props}
                        formatDataKey={() => 'Price'}
                        formatLabel={(value) => `${formatNumber(value)} ${asset.currency.displayDenom}`}
                        formatValue={(value) => formatPrice(value, 'DYM')}
                    />}
                />

                <defs>
                    <linearGradient id='area-background' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopOpacity={0.7} className={classNames('area-background')} />
                        <stop offset='95%' stopOpacity={0.1} className={classNames('area-background')} />
                    </linearGradient>
                </defs>
                <Area type='monotone' dataKey='y' className='area-chart' fill='url(#area-background)' />
            </AreaChart>
        </ChartContainer>
    );
};

export default BondingCurveChart;
