import React, { ReactNode } from 'react';

export const getTextFromReactNode = (node: ReactNode): string => {
    if (typeof node === 'string') {
        return node;
    }
    if (Array.isArray(node)) {
        return node.map(getTextFromReactNode).join('');
    }
    if (React.isValidElement(node)) {
        return getTextFromReactNode(node.props.children);
    }
    return '';
};
