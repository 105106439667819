export const BRIDGE_TRANSFERS_PATH = 'bridge-transfers';

export type BridgeTransferStatus = 'Pending' | 'Success' | 'Failed' | 'IbcTransferring' | 'BridgeTransferring';

export interface BridgeTransfer {
    sourceId: string;
    destinationId: string;
    intermediateId?: string;
    sourceAddress: string;
    destinationAddress: string;
    intermediateAddress?: string;
    denom: string;
    amount: number;
    time: number;
    ibcHash?: string;
    hash?: string;
    status: BridgeTransferStatus;
}
