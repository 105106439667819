import {
    DocumentReference,
    onSnapshot,
    QueryConstraint,
    DocumentSnapshot,
    Unsubscribe,
    getDocs,
    addDoc,
    collection,
    orderBy,
    query,
    where,
    startAfter,
    limit,
    documentId,
    doc,
    runTransaction,
    increment,
} from 'firebase/firestore';
import { firestore } from '../../../shared/utils/firebase-utils';
import { User, USERS_PATH } from '../../account/auth-user/types';
import {
    DEFAULT_COMMENTS_PAGE_LIMIT,
    NETWORK_COMMENT_LIKES_PATH,
    NETWORK_COMMENTS_PATH,
    NetworkComment,
    NetworkCommentLike,
    SortType,
} from './types';

export const LIKE_ABORTED_ERROR_MESSAGE = 'like-aborted';
const PENDING_LIKES: { [commentId: string]: number } = {};

export interface NetworkCommentsListener {
    next: (comments: NetworkComment[], lastSnapshot: DocumentSnapshot) => void;
    error?: (error: any) => void;
    complete?: () => void;
}

export interface UserCommentLikesListener {
    next: (userLikes: { [commentId: string]: boolean }) => void;
    error?: (error: any) => void;
    complete?: () => void;
}

export const postNetworkComment = async (comment: NetworkComment): Promise<DocumentReference> => {
    const networkCommentsRef = collection(firestore, NETWORK_COMMENTS_PATH);
    return addDoc(networkCommentsRef, { ...comment, likes: 0 });
};

export const likeNetworkComment = async (commentId: string, userId: string, pendingLike: number): Promise<boolean> => {
    if (PENDING_LIKES[commentId]) {
        delete PENDING_LIKES[commentId];
        return pendingLike === 1;
    }
    const like: NetworkCommentLike = { userId, commentId, date: new Date().getTime() };
    const commentRef = doc(firestore, NETWORK_COMMENTS_PATH, commentId);
    const commentLikeRef = doc(firestore, NETWORK_COMMENT_LIKES_PATH, `${commentId}-${userId}`);
    PENDING_LIKES[commentId] = pendingLike;

    return runTransaction(firestore, async (transaction) => {
        const commentDoc = await transaction.get(commentRef);
        if (!commentDoc.exists()) {
            throw new Error('Comment does not exist!');
        }
        const commentLikeDoc = await transaction.get(commentLikeRef);
        const shouldUnlike = commentLikeDoc.exists();
        if (shouldUnlike) {
            transaction.update(commentRef, { likes: increment(-1) });
            transaction.delete(commentLikeRef);
        } else {
            transaction.update(commentRef, { likes: increment(1) });
            transaction.set(commentLikeRef, like);
        }
        if (!PENDING_LIKES[commentId]) {
            throw new Error(LIKE_ABORTED_ERROR_MESSAGE);
        }
        return !shouldUnlike;
    }).finally(() => delete PENDING_LIKES[commentId]);
};

export const setUserCommentLikesListener = (userId: string, listener: UserCommentLikesListener): Unsubscribe => {
    const networkCommentLikesRef = collection(firestore, NETWORK_COMMENT_LIKES_PATH);
    return onSnapshot(query(networkCommentLikesRef, where('userId', '==', userId)), {
        next: (snapshot) => {
            const likes = snapshot.docs.map((doc) => doc.data() as NetworkCommentLike);
            listener.next(likes.reduce((current, like) => ({ ...current, [like.commentId]: true }), {}));
        },
        error: listener.error,
        complete: listener.complete,
    });
};

export const setNetworkCommentsListener = (
    networkId: string,
    sortBy: SortType,
    lastSnapshot: DocumentSnapshot | undefined,
    listener: NetworkCommentsListener,
): Unsubscribe => {
    const queryConstraints: QueryConstraint[] = [
        where('networkId', '==', networkId),
        limit(DEFAULT_COMMENTS_PAGE_LIMIT),
        orderBy(sortBy === SortType.TOP ? 'likes' : 'date', 'desc'),
    ];
    if (lastSnapshot) {
        queryConstraints.push(startAfter(lastSnapshot));
    }
    const networkCommentsRef = collection(firestore, NETWORK_COMMENTS_PATH);
    const commentsQuery = query(networkCommentsRef, ...queryConstraints);
    return onSnapshot(commentsQuery, { includeMetadataChanges: true }, {
        next: (snapshot) => {
            if (!snapshot.metadata.hasPendingWrites && !snapshot.metadata.fromCache) {
                const comments = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as NetworkComment));
                listener.next(comments, snapshot.docs[snapshot.docs.length - 1]);
            }
        },
        error: listener.error,
        complete: listener.complete,
    });
};

export const loadUsers = async (userIds: string[]): Promise<User[]> => {
    if (!userIds.length) {
        return [];
    }
    const usersRef = collection(firestore, USERS_PATH);
    return getDocs(query(usersRef, where(documentId(), 'in', userIds)))
        .then((snapshot) => snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as User));
};
