import React, { ReactElement, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { Option } from '../../../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../../../shared/components/form-controls/select/select';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { AnalyticsData } from '../../../../analytics/analytics-types';
import PercentageChangeProperty from '../../properties/percentage-change-property/percentage-change-property';
import {
    ANALYTICS_CHANGE_PERIODS,
    AnalyticsChangePeriod,
} from '../../../../../shared/components/statistics/statistics-change/statistics-change-types';
import { Currency } from '../../../../currency/currency-types';
import Button from '../../../../../shared/components/button/button';
import { ResponsiveContainer } from 'recharts';
import { getPeriodLabel } from '../../../../../shared/components/statistics/statistics-change/statistics-change-service';
import './chart-container.scss';

export interface SelectableChartProps {
    chartOptions?: string[];
    selectedChart?: string;
    onChartSelect?: (value: string) => void;
    disabledOptions?: string[];
}

export interface ChartContainerProps<T> extends SelectableChartProps {
    label: string;
    info?: ReactNode;
    children: ReactElement | ReactElement[];
    currency?: Currency,
    data?: AnalyticsData<T>,
    fetchComparableValues?: (value: T) => number,
    activePeriod?: AnalyticsChangePeriod;
    onActivePeriodChange?: (period: AnalyticsChangePeriod) => void;
    defaultOptionalPeriods?: AnalyticsChangePeriod[];
    periodLabelMap?: (period: AnalyticsChangePeriod) => string;
    formatValueOptions?: Intl.NumberFormatOptions;
    formatValue?: (value: number, options?: Intl.NumberFormatOptions, isTick?: boolean) => string;
    valueInfo?: (value: number) => string;
    onResize?: (params: { width: number, height: number }) => void;
    compareDiffs?: boolean,
    loading?: boolean;
    className?: string;
    na?: boolean;
    hideStatisticsChange?: boolean;
    headerClassName?: string;
    maxHeight?: number;
}

export default function ChartContainer<T>({
    label,
    className,
    headerClassName,
    data,
    info,
    fetchComparableValues,
    activePeriod = 'week',
    chartOptions,
    disabledOptions,
    selectedChart,
    onChartSelect,
    onActivePeriodChange,
    valueInfo,
    formatValueOptions,
    formatValue,
    compareDiffs,
    loading,
    currency,
    children,
    onResize,
    na,
    hideStatisticsChange,
    defaultOptionalPeriods,
    maxHeight = 250,
    periodLabelMap,
}: ChartContainerProps<T>): ReactElement {
    const getPeriodSelectorOptionLabel = (period: AnalyticsChangePeriod): string => {
        if (periodLabelMap) {
            return periodLabelMap(period);
        }
        switch (period) {
            case 'day':
                return '1D';
            case 'week':
                return '1W';
            case 'month':
                return '1M';
            case 'year':
                return '1Y';
            case 'total':
                return 'ALL';
        }
    };

    const renderPeriodSelectorOption = (period: AnalyticsChangePeriod): ReactElement => {
        return (
            <Button
                key={period}
                size='small'
                buttonType='secondary'
                className='period-selector-option'
                active={period === activePeriod}
                onClick={() => onActivePeriodChange?.(period)}
            >
                {getPeriodSelectorOptionLabel(period)}
            </Button>
        );
    };

    const optionalPeriods = useMemo(() => {
        return defaultOptionalPeriods || ANALYTICS_CHANGE_PERIODS.filter((period) => (!compareDiffs || period !== 'total') && (
            (period === 'day' && data?.dayHistory) ||
            (period === 'week' && data?.monthHistory) ||
            (period === 'month' && data?.monthHistory) ||
            (period === 'year' && data?.totalHistory) ||
            (period === 'total' && data?.totalHistory)
        ));
    }, [ compareDiffs, data?.dayHistory, data?.monthHistory, data?.totalHistory, defaultOptionalPeriods ]);

    return (
        <div className={classNames('chart-container section small', className)}>
            <div className={classNames('chart-container-header', headerClassName)}>
                <PercentageChangeProperty
                    na={na}
                    className='chart-property'
                    labelClassName='property-label'
                    valueClassName='property-value'
                    formatValueOptions={formatValueOptions}
                    formatValue={formatValue}
                    label={compareDiffs ? `${label} (${getPeriodLabel(activePeriod)})` : label}
                    info={info}
                    valueInfo={valueInfo}
                    period={activePeriod}
                    loading={loading}
                    compareDiffs={compareDiffs}
                    hideStatisticsChange={hideStatisticsChange}
                    data={data}
                    fetchComparableValues={fetchComparableValues}
                    currency={currency}
                />
                <div className='selectors-container'>
                    {onActivePeriodChange && optionalPeriods.map(renderPeriodSelectorOption)}
                    {chartOptions && chartOptions.length >= 2 ? (
                        <Select
                            className='chart-selector'
                            value={selectedChart}
                            onSelect={(value) => onChartSelect?.(value.toString())}
                        >
                            {chartOptions.map((option) =>
                                <Option value={option} key={option} disabled={disabledOptions?.includes(option)}>{option}</Option>)}
                        </Select>
                    ) : undefined}
                </div>

            </div>

            <div className='chart-elements'>
                {(Array.isArray(children) ? children : [ children ]).map((element, elementIndex) => (
                    <ResponsiveContainer
                        key={elementIndex}
                        minHeight={200}
                        maxHeight={maxHeight}
                        onResize={(width, height) => onResize?.({ width, height })}
                    >
                        {loading ? <div className='chart-spinner-container'><Spinner /></div> : element}
                    </ResponsiveContainer>
                ))}
            </div>
        </div>
    );
}

