import classNames from 'classnames';
import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { useNetworkAddress } from '../../../modules/account/use-network-address';
import { getShortenedAddress } from '../../utils/text-utils';
import Icon from '../icon/icon';
import Menu, { MenuAction } from '../menu/menu';
import Tooltip from '../tooltip/tooltip';
import './addresses.scss';

interface AddressesProps {
    addresses: string[];
    canCopy?: boolean;
    className?: string;
    size?: 'small' | 'medium';
    display?: 'short' | 'long' | 'full';
    hideShortAddressPrefix?: boolean;
}

const Addresses: React.FC<AddressesProps> = ({
    addresses,
    canCopy,
    className,
    size = 'medium',
    display = 'short',
    hideShortAddressPrefix,
}) => {
    const { copyAddress } = useNetworkAddress();
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);

    const addressContainer = (
        <div
            className={classNames('address-container', className, size, { canCopy, isMenuOpen })}
            onClick={(event) => {
                if (canCopy) {
                    event.stopPropagation();
                    copyAddress(addresses[0], true);
                }
            }}
        >
            {display === 'full' ? addresses[0] : getShortenedAddress(
                addresses[0] || '',
                hideShortAddressPrefix ? 0 : display === 'long' ? 12 : undefined,
                display === 'long' ? 10 : undefined,
            )}
        </div>
    );
    if (!canCopy) {
        return addressContainer;
    }
    if (addresses.length === 1) {
        return <Tooltip title='Copy address' clickTitle='Address copied!'>{addressContainer}</Tooltip>;
    }
    return (
        <Menu overlayAlign='center' triggerOnHover trigger={addressContainer} onOpenChange={setIsMenuOpen}>
            {addresses.map((address) => (
                <MenuAction onClick={() => copyAddress(address, true)} key={address} size='small'>
                    <Icon><CopyIcon /></Icon>&nbsp;&nbsp;
                    {display === 'full' ? address : getShortenedAddress(
                        address,
                        display === 'long' ? 12 : undefined,
                        display === 'long' ? 10 : undefined,
                    )}
                </MenuAction>
            ))}
        </Menu>
    );
};

export default Addresses;
