import { AccountData } from 'cosmjs/packages/proto-signing';
import { timeToMilliseconds } from '../../shared/utils/date-utils';

export const DEFAULT_SESSION_DURATION = timeToMilliseconds({ minutes: 30 });
export const SESSION_DURATION_DELAY = timeToMilliseconds({ seconds: 20 });
export const PIN_CODE_EXPIRATION_DURATION = timeToMilliseconds({ seconds: 60 });
export const PIN_CODE_LENGTH = 6;

export const QUICK_AUTH_MESSAGE_TYPES_KEY = 'quick-auth-message-types';

export const QUICK_AUTH_MESSAGE_TYPES = [ 'Trades', 'Endorsements', 'Pool LP' ] as const;
export type QuickAuthMessageType = typeof QUICK_AUTH_MESSAGE_TYPES[number];

export const QUICK_AUTH_TYPE_MESSAGES_MAP: { [type in QuickAuthMessageType]: string[] } = {
    Trades: [
        '/dymensionxyz.dymension.gamm.v1beta1.MsgSwapExactAmountIn',
        '/dymensionxyz.dymension.gamm.v1beta1.MsgSwapExactAmountOut',
        '/dymensionxyz.dymension.iro.MsgBuy',
        '/dymensionxyz.dymension.iro.MsgBuyExactSpend',
        '/dymensionxyz.dymension.iro.MsgSell',
    ],
    Endorsements: [
        '/dymensionxyz.dymension.sponsorship.MsgVote',
    ],
    'Pool LP': [
        '/dymensionxyz.dymension.gamm.v1beta1.MsgJoinSwapShareAmountOut',
        '/dymensionxyz.dymension.gamm.v1beta1.MsgJoinPool',
        '/dymensionxyz.dymension.gamm.v1beta1.MsgExitPool',
        '/dymensionxyz.dymension.gamm.poolmodels.balancer.v1beta1.MsgCreateBalancerPool',
    ],
};

export const QUICK_AUTH_ROLLAPP_EVM_MESSAGES: string[] = [
    '/ethermint.evm.v1.MsgEthereumTx',
];

export const QUICK_AUTH_ROLLAPP_WASM_MESSAGES: string[] = [
    '/cosmwasm.wasm.v1.MsgExecuteContract',
];

export interface QuickAuthAccount {
    sourceAccount: AccountData;
    mnemonic: string;
    expiration: number;
}
