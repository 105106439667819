import Pica from 'pica';

const pica = Pica();

export const readStream = async (readableStream: ReadableStream<Uint8Array>): Promise<string> => {
    const reader = readableStream.getReader();
    if (!reader) {
        return '';
    }
    const pump = (controller: ReadableStreamDefaultController): Promise<void> => {
        return reader.read().then(({ done, value }) => {
            if (!done) {
                controller.enqueue(value);
                return pump(controller);
            }
            return controller.close();
        });
    };
    const stream = new ReadableStream({ start: pump });
    return new Response(stream, { headers: { 'Content-Type': 'text/html' } }).text();
};

export const exportFile = (url: string, filename: string): void => {
    const anchorElement = document.createElement('a');
    anchorElement?.setAttribute('href', url);
    anchorElement?.setAttribute('download', filename);
    anchorElement?.click();
    anchorElement?.remove();
};

export const convertToFile = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([ blob ], '', { type: blob.type });
};

export const getFileSuffix = (fileName: string): string => {
    const fileNameParts = fileName.split('.');
    return fileNameParts[fileNameParts.length - 1];
};

export const resizeImage = (imageFile: File, maxWidth?: number, maxHeight?: number): Promise<File> => {
    if (!imageFile.type.startsWith('image/')) {
        throw new Error('Non image file');
    }
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = async () => {
            try {
                const canvas = document.createElement('canvas');
                let width = image.width;
                let height = image.height;
                if (maxWidth && width > height && width > maxWidth) {
                    height = Math.round((height *= maxWidth / width));
                    width = maxWidth;
                } else if (maxHeight && height >= width && height > maxHeight) {
                    width = Math.round((width *= maxHeight / height));
                    height = maxHeight;
                }
                canvas.width = image.width;
                canvas.height = image.height;
                const context = canvas.getContext('2d');
                if (!context) {
                    reject(new Error('Could not get canvas context'));
                    return;
                }
                try {
                    const outputCanvas = document.createElement('canvas');
                    outputCanvas.width = width;
                    outputCanvas.height = height;
                    context.drawImage(image, 0, 0);
                    const result = await pica.resize(canvas, outputCanvas);
                    const blob = await pica.toBlob(result, imageFile.type);
                    const resizedFile = new File([ blob ], imageFile.name, { type: imageFile.type });
                    resolve(resizedFile);
                } catch (error) {
                    console.warn('Pica failed, falling back to native canvas resizing:', error);
                    context.drawImage(image, 0, 0, width, height);
                    canvas.toBlob((blob) => {
                        if (!blob) {
                            reject(new Error('Could not create blob from canvas'));
                            return;
                        }
                        const resizedFile = new File([ blob ], imageFile.name, { type: imageFile.type });
                        resolve(resizedFile);
                    }, imageFile.type);
                }
            } catch (error) {
                reject(error);
            }
        };
        image.onerror = () => reject(new Error('Could not load image'));

        const reader = new FileReader();
        reader.onload = async (e) => image.src = e.target?.result as string;
        reader.onerror = () => reject(new Error('Could not read file'));
        reader.readAsDataURL(imageFile);
    });
};
