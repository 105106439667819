import React, { useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';

const IbcTransfersProperty: React.FC = () => {
    const { network } = useNetworkDashboard();

    const currentValue = useMemo(() => {
        const ibcTransfers = network?.ibcTransfers;
        const currentValue = (ibcTransfers?.value.totalIn || 0) + (ibcTransfers?.value.totalOut || 0);
        const previousValue = (ibcTransfers?.previousDayValue?.totalIn || 0) + (ibcTransfers?.previousDayValue?.totalOut || 0);
        return currentValue - previousValue;
    }, [ network?.ibcTransfers ]);

    const previousValue = useMemo(() => {
        const ibcTransfers = network?.ibcTransfers;
        const currentValue = (ibcTransfers?.previousDayValue?.totalIn || 0) + (ibcTransfers?.previousDayValue?.totalOut || 0);
        const previousValue = (ibcTransfers?.previousTwoDaysValue?.totalIn || 0) + (ibcTransfers?.previousTwoDaysValue?.totalOut || 0);
        return currentValue - previousValue;
    }, [ network?.ibcTransfers ]);

    return (
        <Property label='IBC Transfers (24h)' className='flex-wrap' na={network.status === 'IRO'}>
            <StatisticsChange period='day' currentValue={currentValue} previousValue={previousValue}>
                {formatNumber(currentValue)}
            </StatisticsChange>
        </Property>
    );
};

export default IbcTransfersProperty;
