import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { useHubNetworkState } from '../account/hub-network-state-context';
import { useClient } from '../client/client-context';
import { CoinsAmount } from '../currency/currency-types';
import { useIncentives } from '../incentives/incentives-context';
import { Incentive } from '../incentives/types';
import { useNetwork } from '../network/network-context';
import { useWallet } from '../wallet/wallet-context';
import {
    getPrice,
    loadAmmParams,
    loadLockedAssets,
    loadPools,
    loadPositions,
    loadTotalLockedValues,
} from './amm.service';
import { AmmParams, LockedAsset, Pool, PoolPosition } from './types';
import { AMM_STATE_DEFAULT, ammReducer, AmmState } from './amm-state';

interface AmmContextValue {
    ammState: AmmState;
    sortedFilteredPools: Pool[];
    getTokenPrice: (coins: CoinsAmount, vsCoins?: CoinsAmount, priceOfOneToken?: boolean) => number | undefined;
    getPoolLiquidity: (pool: Pool) => number | undefined;
    incentiveAprs: { [poolId: number]: number };
    commonTokens: CoinsAmount[];
    loadMore: () => void;
    setSearchText: (searchText: string) => void;
}

const PAGE_SIZE = 15;

export const AmmContext = createContext<AmmContextValue>({} as AmmContextValue);

export const useAmm = (): AmmContextValue => useContext(AmmContext);

export const AmmContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { networks, hubNetwork, networkDenoms, toHubCoins } = useNetwork();
    const { hubWallet } = useWallet();
    const { clientStateMap, handleClientError } = useClient();
    const { incentivesState } = useIncentives();
    const [ ammState, ammStateDispatch ] = useReducer(ammReducer, AMM_STATE_DEFAULT);
    const networkState = useHubNetworkState();
    const [ page, setPage ] = useState(0);
    const cancelAndSetPoolsPromise = useCancelablePromise<Pool[]>();
    const cancelAndSetLockedAssetsPromise = useCancelablePromise<LockedAsset[]>();
    const cancelAndSetPositionsPromise = useCancelablePromise<PoolPosition[]>();
    const cancelAndSetParamsPromise = useCancelablePromise<AmmParams>();
    const cancelAndSetTotalLockedValuesPromise = useCancelablePromise<{ [denom: string]: number }>();

    const clientState = hubNetwork && clientStateMap[hubNetwork.chainId];

    const commonTokens = useMemo(() => networks.reduce((current, network) => {
        if (!networkDenoms?.length) {
            return [];
        }
        return [
            ...current,
            ...network.currencies
                .filter((currency) => currency.common)
                .map<CoinsAmount>((currency) => {
                    const networkDenom = networkDenoms?.find((networkDenom) =>
                        networkDenom.baseDenom === currency.baseDenom && networkDenom.ibcNetworkId === network.chainId);
                    return {
                        currency,
                        amount: 0,
                        ibc: network.type === 'Hub' || !networkDenom ? undefined :
                            { representation: networkDenom.denom, path: networkDenom.path },
                        networkId: networkDenom?.ibcNetworkId || network.chainId,
                    };
                }),
        ];
    }, [] as CoinsAmount[]), [ networks, networkDenoms ]);

    const getTokenPrice = useCallback((coins: CoinsAmount, vsCoins?: CoinsAmount, priceOfOneToken?: boolean): number | undefined => {
        if (ammState.pools && ammState.params) {
            coins = priceOfOneToken ? { ...coins, amount: 1 } : coins;
            coins = toHubCoins(coins);
            return getPrice(ammState.pools, ammState.params, coins, vsCoins || ammState.params.vsCoins);
        }
    }, [ ammState.params, ammState.pools, toHubCoins ]);

    const getPoolLiquidity = useCallback((pool: Pool): number | undefined => {
        if (ammState.pools && ammState.params && pool.assets.length) {
            return getPrice(ammState.pools, ammState.params, pool.assets[0], ammState.params.vsCoins) * 2;
        }
    }, [ ammState.pools, ammState.params ]);

    const incentiveAprs = useMemo(() => {
        return (ammState.pools || []).reduce((current, pool) => {
            const incentives = incentivesState.incentives?.[pool.lpTokenDenom];
            const totalLockedValue = ammState.totalLockedValues?.[pool.lpTokenDenom];
            const incentiveApr = !incentives || !totalLockedValue ? 0 : incentives.reduce((current, incentive: Incentive) => {
                if (incentive.startTime > new Date()) {
                    return current;
                }
                const value = incentive.coins.reduce((current, coinsAmount) => current + (getTokenPrice(coinsAmount) || 0), 0);
                const currentIncentiveApr = value / (totalLockedValue * incentive.yearPart);
                return current + currentIncentiveApr;
            }, 0);
            return { ...current, [pool.id]: incentiveApr };
        }, {} as { [poolId: number]: number });
    }, [ ammState.pools, ammState.totalLockedValues, getTokenPrice, incentivesState.incentives ]);

    const sortedFilteredPools = useMemo(() => {
        let filteredPools = ammState.pools || [];
        if (ammState.searchText) {
            const searchRegExp = new RegExp(ammState.searchText.trim(), 'i');
            filteredPools = filteredPools.filter(pool => pool.assets.some(asset =>
                searchRegExp.test(asset.currency.displayDenom) || searchRegExp.test(asset.currency.baseDenom)));
        }
        return filteredPools
            .sort((pool1, pool2) => (getPoolLiquidity(pool2) || 0) - (getPoolLiquidity(pool1) || 0))
            .slice(0, (page + 1) * PAGE_SIZE);
    }, [ ammState.pools, ammState.searchText, getPoolLiquidity, page ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === sortedFilteredPools.length) {
            setPage(page + 1);
        }
    }, [ page, sortedFilteredPools.length ]);

    const setSearchText = useCallback((searchText: string) => ammStateDispatch({ type: 'set-search-text', payload: searchText }), []);

    useEffect(() => {
        if (hubWallet) {
            ammStateDispatch({ type: 'set-pool-positions-loading' });
            ammStateDispatch({ type: 'set-locked-assets-loading' });
        } else {
            ammStateDispatch({ type: 'set-pool-positions', payload: undefined });
            ammStateDispatch({ type: 'set-locked-assets', payload: undefined });
        }
    }, [ hubWallet ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-pools-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        ammStateDispatch({ type: 'set-pools-loading' });
        cancelAndSetPoolsPromise((signal) => loadPools(clientState.client!, signal))
            .then((pools) => ammStateDispatch({ type: 'set-pools', payload: pools }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-pools-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetPoolsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-locked-assets-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address) {
            return;
        }
        ammStateDispatch({ type: 'set-locked-assets-loading' });
        cancelAndSetLockedAssetsPromise(loadLockedAssets(clientState.client, networkState.address))
            .then((lockedAssets) => ammStateDispatch({ type: 'set-locked-assets', payload: lockedAssets }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-locked-assets-loading', payload: false });
                handleClientError(error);
            });
    }, [ cancelAndSetLockedAssetsPromise, clientState, handleClientError, networkState.address ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-pool-positions-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting || !networkState.address || !ammState.lockedAssets) {
            return;
        }
        ammStateDispatch({ type: 'set-pool-positions-loading' });
        cancelAndSetPositionsPromise(loadPositions(clientState.client, networkState.address, ammState.lockedAssets))
            .then((positions) => ammStateDispatch({ type: 'set-pool-positions', payload: positions }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-pool-positions-loading', payload: false });
                handleClientError(error);
            });
    }, [ ammState.lockedAssets, cancelAndSetPositionsPromise, clientState, handleClientError, networkState.address ]);

    useEffect(() => {
        if (clientState && !clientState.client && !clientState.connecting) {
            ammStateDispatch({ type: 'set-params-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting || ammState.params) {
            return;
        }
        ammStateDispatch({ type: 'set-params-loading' });
        cancelAndSetParamsPromise((signal) => loadAmmParams(clientState.client!, signal))
            .then((params) => ammStateDispatch({ type: 'set-params', payload: params }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-params-loading', payload: false });
                handleClientError(error);
            });
    }, [ ammState.params, cancelAndSetParamsPromise, clientState, handleClientError ]);

    useEffect(() => {
        if ((clientState && !clientState.client && !clientState.connecting) ||
            (!ammState.pools && !ammState.loading) ||
            (!ammState.params && !ammState.paramsLoading)) {
            ammStateDispatch({ type: 'set-total-locked-values-loading', payload: false });
            return;
        }
        if (!ammState.pools || !ammState.params || !clientState?.client || clientState?.connecting) {
            return;
        }
        ammStateDispatch({ type: 'set-total-locked-values-loading' });
        cancelAndSetTotalLockedValuesPromise(
            (signal) => loadTotalLockedValues(clientState.client!, ammState.pools!, ammState.params!, signal))
            .then((values) => ammStateDispatch({ type: 'set-total-locked-values', payload: values }))
            .catch((error) => {
                ammStateDispatch({ type: 'set-total-locked-values-loading', payload: false });
                handleClientError(error);
            });
    }, [
        ammState.loading,
        ammState.params,
        ammState.paramsLoading,
        ammState.pools,
        cancelAndSetTotalLockedValuesPromise,
        clientState,
        handleClientError,
    ]);

    return (
        <AmmContext.Provider
            value={{
                ammState,
                incentiveAprs,
                commonTokens,
                sortedFilteredPools,
                getTokenPrice,
                getPoolLiquidity,
                loadMore,
                setSearchText,
            }}
        >
            {children}
        </AmmContext.Provider>
    );
};
