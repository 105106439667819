import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import Tooltip, { TooltipPlacement } from '../tooltip/tooltip';
import './icon.scss';

interface IconProps {
    children: ReactElement;
    iconColorMode?: 'fill' | 'stroke' | 'original';
    className?: string;
    color?: 'primary' | 'secondary';
    tooltip?: ReactNode;
    tooltipPlacement?: TooltipPlacement;
}

const Icon: React.FC<IconProps> = ({ children, tooltip, tooltipPlacement, className, iconColorMode = 'fill', color = 'primary' }) => {
    const icon = <span className={classNames('icon-container', className, color, `icon-color-${iconColorMode}`)}>{children}</span>;
    return tooltip ? <Tooltip title={tooltip} placement={tooltipPlacement}>{icon}</Tooltip> : icon;
};

export default Icon;
