export const DEFAULT_PIN_CODE_LENGTH = 6;
export const PIN_CODE_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export const PIN_CODE_DIGITS_CHARACTERS = '0123456789';

export const URL_REGEX = /^(https?:\/\/)(www\.)?([a-zA-Z0-9-\\.]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;

export const validateUrl = (url: string): string | undefined => {
    if (URL_REGEX.test(url)) {
        return undefined;
    }
    if (!url.startsWith('http')) {
        return 'Missing protocol (http/s)';
    }
    return 'Invalid URL';
};

const SUB_NUMBER_MAP: { [digit: string]: string } = { 0: '₀', 1: '₁', 2: '₂', 3: '₃', 4: '₄', 5: '₅', 6: '₆', 7: '₇', 8: '₈', 9: '₉' };

const SUPER_NUMBER_MAP: { [digit: string]: string } = { 0: '⁰', 1: '¹', 2: '²', 3: '³', 4: '⁴', 5: '⁵', 6: '⁶', 7: '⁷', 8: '⁸', 9: '⁹' };

export const escapeRegExp = (text: string): string => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const generatePinCode = (length = DEFAULT_PIN_CODE_LENGTH, digits?: boolean): string => {
    const characters = digits ? PIN_CODE_DIGITS_CHARACTERS : PIN_CODE_CHARACTERS;
    return Array.from({ length })
        .reduce<string>((current) => current + characters.charAt(Math.floor(Math.random() * characters.length)), '');
};

export const getShortenedAddress = (address: string, prefixLetters = 6, suffixLetters = 4): string => {
    return `${address.slice(0, prefixLetters)}...${address.slice(address.length - suffixLetters)}`;
};

export const isJsonValid = (json: string | object, andNotEmpty?: boolean): boolean => {
    try {
        let jsonObject: object | number | string;
        if (typeof json === 'string') {
            jsonObject = JSON.parse(json);
        } else {
            JSON.stringify(json);
            jsonObject = json;
        }
        return !andNotEmpty || typeof jsonObject === 'number' || Object.keys(jsonObject).length > 0;
    } catch {
        return false;
    }
};

export const sortObject = <T>(obj: T): T => {
    if (Array.isArray(obj)) {
        return obj.map(sortObject) as T;
    }
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    return Object.keys(obj).sort().reduce((result, key) => {
        (result as any)[key] = sortObject(obj[key as keyof T]);
        return result;
    }, {}) as T;
};

export const convertToSubNumber = (number: number) => {
    return String(number).split('').map((digit) => SUB_NUMBER_MAP[digit] || digit).join('');
};
export const convertToSuperNumber = (number: number) => {
    return String(number).split('').map((digit) => SUPER_NUMBER_MAP[digit] || digit).join('');
};
