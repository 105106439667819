import React, { ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import Button from '../../../../shared/components/button/button';
import { ReactComponent as MagicWandIcon } from '../../../../assets/icons/magic-wand.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import ControlsComposer from '../../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../../shared/components/form-controls/input/input';
import Select from '../../../../shared/components/form-controls/select/select';
import { Option } from '../../../../shared/components/form-controls/options-modal/options-modal';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import EditValidatorDialog from '../edit-validator/edit-validator-dialog';
import { createValidatorAddress } from '../edit-validator/edit-validator-service';
import { VALIDATOR_STATUSES, ValidatorStatus } from '../validator-types';
import Spinner from '../../../../shared/components/spinner/spinner';
import ValidatorList, { ValidatorListHeader, ValidatorListProps } from '../validator-list/validator-list';
import { ValidatorListData } from '../validator-list/use-validator-list';
import { useStaking } from '../../staking-context';
import './total-validators.scss';

interface TotalValidatorsProps extends ValidatorListProps {
    validatorsData: ValidatorListData;
    simpleMode?: boolean;
    header?: ReactNode;
    headerClassName?: string;
}

export function TotalValidators({
    validatorsData,
    simpleMode,
    header,
    headerClassName,
    ...otherListProps
}: TotalValidatorsProps): JSX.Element {
    const { network, networkState } = useStaking();
    const { isTablet } = useWindowSize();
    const [ createValidatorDialogVisible, setCreateValidatorDialogVisible ] = useState(false);

    const { state, setOrder, setSearchText, setStatus } = validatorsData;
    const validatorsName = network.type === 'RollApp' ? 'Governors' : 'Validators';
    const validatorName = network.type === 'RollApp' ? 'Governor' : 'Validator';

    const ownedValidator = useMemo(() => {
        const allValidators = Object.values(state.totalValidatorsMap || {})
            .reduce((current, statusValidators) => [ ...current, ...statusValidators ], []);
        const validatorAddress = networkState.address && createValidatorAddress(networkState.address, network);
        return !validatorAddress ? undefined : allValidators.find((validator) => validator.address === validatorAddress);
    }, [ network, networkState.address, state.totalValidatorsMap ]);

    const editValidatorButtonLabel = useMemo(
        () => ownedValidator ? `Edit My ${validatorName}` : `Create New ${validatorName}`,
        [ ownedValidator, validatorName ],
    );

    const totalValidatorsCount = useMemo(() => Object.values(state.totalValidatorsMap || {})
        .reduce((current, validators) => current + (validators?.length || 0), 0), [ state.totalValidatorsMap ]);

    return <>
        <ValidatorListHeader className={classNames('total validators-list-header', headerClassName, { 'simple-mode': simpleMode })}>
            <span className='horizontally-centered'>
                {header ?? `All ${validatorsName}`}
                {network.type === 'RollApp' && (
                    <InfoIndicator>
                        RollApps separate block production from governance. Sequencers produce blocks while Governors are those in charge for
                        voting w/ delegated stake and distributing revenue or new tokens minted.
                    </InfoIndicator>
                )}
            </span>

            <span className='space' />

            <div className='validators-controls'>
                <ControlsComposer className='validators-filters'>
                    <Input
                        controlSize='medium'
                        type='search'
                        value={state.searchText}
                        onValueChange={setSearchText}
                        placeholder={`Search ${validatorsName.toLowerCase()}...`}
                    />
                    <Select
                        className='validators-status-select'
                        value={state.filterStatus}
                        optionsOverlayAlign='right'
                        onSelect={(type) => setStatus(type as ValidatorStatus)}
                        controlSize='medium'
                    >
                        {VALIDATOR_STATUSES.map((status) => (
                            <Option key={status} value={status}>
                                {status}
                                {state.loading && state.totalValidatorsMap === undefined ?
                                    <Spinner size='small' className='validator-status-loader' /> :
                                    ` (${state.totalValidatorsMap?.[status].length || 0})`}
                            </Option>
                        ))}
                        <Option key='all' value=''>
                            All {!state.loading ?
                            ` (${totalValidatorsCount})` :
                            <Spinner size='small' className='validator-status-loader' />}
                        </Option>
                    </Select>
                </ControlsComposer>

                {network.type === 'RollApp' && (
                    <Button
                        className='create-validator-button'
                        tooltipPlacement='top'
                        disabled={!networkState.address || (!state.validators?.length && state.loading)}
                        tooltip={isTablet ? editValidatorButtonLabel : ''}
                        buttonType='primary'
                        onClick={() => setCreateValidatorDialogVisible(true)}
                    >
                        <span className='create-validator-button-text'>{editValidatorButtonLabel}&nbsp;&nbsp;</span>
                        {ownedValidator ? <EditIcon /> : <MagicWandIcon />}
                    </Button>
                )}
            </div>
        </ValidatorListHeader>

        <ValidatorList
            state={state}
            onSort={setOrder}
            indexColumn={!simpleMode}
            menuVisibility={!simpleMode}
            fields={simpleMode ? [ 'Name', 'VotingPower', 'Commission' ] : undefined}
            {...otherListProps}
        />

        {createValidatorDialogVisible && (
            <EditValidatorDialog
                validator={ownedValidator?.originalValidator}
                onRequestClose={() => setCreateValidatorDialogVisible(false)}
            />
        )}
    </>;
}

