import classNames from 'classnames';
import { CommissionRates, Validator } from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import { Decimal } from 'cosmjs/packages/math';
import React, { useCallback, useEffect } from 'react';
import Alert from '../../../../shared/components/alert/alert';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Input from '../../../../shared/components/form-controls/input/input';
import TextArea from '../../../../shared/components/form-controls/text-area/text-area';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import { SnackbarMessage } from '../../../../shared/components/snackbar/snackbar-types';
import { validateUrl } from '../../../../shared/utils/text-utils';
import { MAX_URL_LENGTH } from '../../../rollapp/manage-rollapps-page/create-rollapp-page/types';
import AmountTx from '../../../tx/amount-tx/amount-tx';
import { DeliveryTxCode, TxResponse } from '../../../tx/tx-types';
import { useWallet } from '../../../wallet/wallet-context';
import { WalletInfoMap } from '../../../wallet/wallet-types';
import { useStaking } from '../../staking-context';
import './edit-validator-dialog.scss';
import useEditValidator from './use-edit-validator';

interface EditValidatorDialogProps extends DialogProps {
    validator?: Validator;
}

const EditValidatorDialog: React.FC<EditValidatorDialogProps> = ({ validator, ...otherDialogProps }) => {
    const { networkWalletMap } = useWallet();
    const { network, networkState } = useStaking();
    const {
        description,
        commission,
        showErrors,
        txState,
        amountTxState,
        availableBalances,
        commissionError,
        saveValidator,
        updateDescription,
        updateCommission,
        setCoins,
    } = useEditValidator(validator);

    const validatorName = network.type === 'RollApp' ? 'Governor' : 'Validator';
    const wallet = networkWalletMap[network.chainId];
    const unsupported = wallet && WalletInfoMap[wallet.getWalletType()].type === 'evm';

    const getTxResponseMessage = useCallback((response: TxResponse): SnackbarMessage | undefined => {
        if (response.deliveryTxCode !== DeliveryTxCode.SUCCESS && response.nativeResponse?.code === 12) {
            return { content: 'Commission cannot be changed more than once in 24h', type: 'error', key: 'commission-error' };
        }
    }, []);

    useEffect(() => {
        if (txState.response?.deliveryTxCode === DeliveryTxCode.SUCCESS) {
            otherDialogProps.onRequestClose?.();
        }
    }, [ otherDialogProps, txState.response?.deliveryTxCode ]);

    const onCommissionValueChange = useCallback((
        key: keyof CommissionRates,
        value: string,
        previousValue: string,
    ) => {
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        if (!value) {
            updateCommission({ [key]: '' });
            return '';
        }
        const amountPattern = new RegExp('^[0-9]*(\\.[0-9]{0,4})?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        const amount = Number(value);
        const fixedCommissionValue = Decimal.fromUserInput(Math.min(100, amount).toString(), 16).atomics;
        updateCommission({ [key]: fixedCommissionValue });
        return amount > 100 ? '100' : undefined;
    }, [ updateCommission ]);

    return (
        <Dialog preventCloseOnEscape closable className='edit-validator-dialog' {...otherDialogProps}>
            <DialogTitle>
                {validator ? `Update ${validator.description?.moniker || validatorName}` : `Create New ${validatorName}`}
            </DialogTitle>
            <DialogContent className='dialog-content'>
                {unsupported && (
                    <Alert type='error' className='unsupported-wallet-message'>
                        Editing a {validatorName} is currently not supported on evm wallets.
                    </Alert>
                )}
                <div className='control-container'>
                    <div className='control-label-container'><label>{validatorName} Name</label></div>
                    <Input
                        onValueChange={(value) => updateDescription({ moniker: value })}
                        value={description.moniker}
                        maxLength={70}
                        error={showErrors && !description.moniker && `Missing ${validatorName} name`}
                    />
                </div>
                <div className='control-container'>
                    <div className='control-label-container'><label>Website</label></div>
                    <Input
                        value={description.website}
                        onValueChange={(value) => updateDescription({ website: value })}
                        placeholder='e.g., https://xyz.com/'
                        maxLength={MAX_URL_LENGTH}
                        error={showErrors && description.website && validateUrl(description.website)}
                    />
                </div>
                <div className='control-container'>
                    <div className='control-label-container'><label>Details</label></div>
                    <TextArea
                        onValueChange={(value) => updateDescription({ details: value })}
                        value={description.details}
                        maxLength={280}
                    />
                </div>
                <div className='controls-row'>
                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label>Commission Rate</label>
                            <InfoIndicator indicatorSize='small'>The commission rate charged to delegators.</InfoIndicator>
                        </div>
                        <Input
                            suffix='%'
                            value={commission.rate && Decimal.fromAtomics(commission.rate, 16).toString()}
                            error={showErrors && commissionError}
                            onValueChange={(value, previousValue) => onCommissionValueChange('rate', value, previousValue)}
                        />
                    </div>

                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label>Max Rate</label>
                            <InfoIndicator indicatorSize='small'>The maximum commission rate which validator can ever charge.</InfoIndicator>
                        </div>
                        <Input
                            suffix='%'
                            value={commission.maxRate && Decimal.fromAtomics(commission.maxRate, 16).toString()}
                            disabled={Boolean(validator)}
                            onValueChange={(value, previousValue) => onCommissionValueChange('maxRate', value, previousValue)}
                        />
                    </div>

                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label>Max Change Rate</label>
                            <InfoIndicator indicatorSize='small'>The maximum daily increase of the validator commission.</InfoIndicator>
                        </div>
                        <Input
                            suffix='%'
                            value={commission.maxChangeRate && Decimal.fromAtomics(commission.maxChangeRate, 16).toString()}
                            disabled={Boolean(validator)}
                            onValueChange={(value, previousValue) => onCommissionValueChange('maxChangeRate', value, previousValue)}
                        />
                    </div>
                </div>

                <span className={classNames('commission-error error-label', { visible: commissionError })}>{commissionError}</span>

                <div className='control-container initial-delegation-control'>
                    <div className='control-label-container'><label>{validator ? 'Total' : 'Initial'} Delegation</label></div>
                    <AmountTx
                        txState={txState}
                        amountTxState={amountTxState}
                        networkState={networkState}
                        onCoinsChange={setCoins}
                        getTxResponseMessage={getTxResponseMessage}
                        amountDisabled={Boolean(validator)}
                        tokenSelectorDisabled={Boolean(validator)}
                        availableBalances={availableBalances}
                        submitButtonContainer={(
                            <Button
                                size='large'
                                className='submit-button'
                                loading={txState.broadcasting}
                                disabled={unsupported || txState.broadcasting || txState.feeLoading || !amountTxState.coins?.amount}
                                onClick={saveValidator}
                            >
                                {validator ? 'Save' : 'Create'} {validatorName}
                            </Button>
                        )}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditValidatorDialog;
