import React, { useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Link from '../../../../shared/components/link/link';
import { filterNonEmptyValues } from '../../../../shared/utils/object-utils';
import { useAsset } from '../../../asset/asset-context';
import { getMainCurrency } from '../../../currency/currency-service';
import { DepositWithdrawMode } from '../../../ibc-transfer/ibc-transfer-types';
import { AccountNetworkState } from '../../account-network-state';
import { ReactComponent as DollarIcon } from '../../../../assets/icons/dollar-symbol.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/send.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { CoinsAmount } from '../../../currency/currency-types';
import IbcTransferDialog from '../../../ibc-transfer/ibc-transfer-dialog/ibc-transfer-dialog';
import { useNetwork } from '../../../network/network-context';
import AccountTotalValue from './account-total-value/account-total-value';
import BalancesList from '../../balances-list/balances-list';
import ClaimableTokensDialog from '../../claimable-tokens/claimable-tokens-dialog';
import SendFundsDialog from './send-funds-dialog/send-funds-dialog';
import './account-balances.scss';

interface AccountBalancesProps {
    className?: string;
    networkState: AccountNetworkState;
    onBalanceClick?: (balance: CoinsAmount) => void;
    dataRefreshing?: boolean;
}

const AccountBalances: React.FC<AccountBalancesProps> = ({ className, networkState, dataRefreshing, onBalanceClick }) => {
    const { networks, hubNetwork } = useNetwork();
    const { mainAssetMap } = useAsset();
    const [ sendFundsDialogOpen, setSendFundsDialogOpen ] = useState(false);
    const [ depositWithdrawDialogOpen, setDepositWithdrawDialogOpen ] = useState(false);
    const [ claimableTokensDialogOpen, setClaimableTokensDialogOpen ] = useState(false);
    const [ depositWithdrawMode, setDepositWithdrawMode ] = useState<DepositWithdrawMode>('Deposit');

    const network = networkState.network;

    const asset = useMemo(() => network?.chainId ? mainAssetMap?.[network?.chainId] : undefined, [ mainAssetMap, network?.chainId ]);

    const depositWithdrawOptionalVsNetworks = useMemo(
        () => network?.type === 'Hub' ?
            networks.filter((network) => network.type !== 'Hub').map((network) => network.chainId) :
            filterNonEmptyValues([ hubNetwork?.chainId ]),
        [ hubNetwork?.chainId, network?.type, networks ],
    );

    return <>
        <AccountTotalValue networkState={networkState} />
        <div className='account-menu-actions'>
            <Button
                className='account-menu-action'
                size='small'
                disabled
                tooltip='Coming soon'
            >
                <DollarIcon />&nbsp;Buy
            </Button>
            <Button className='account-menu-action' size='small' onClick={() => setDepositWithdrawDialogOpen(true)}>
                <DownloadIcon />&nbsp;Deposit
            </Button>
            <Button className='account-menu-action' size='small' onClick={() => setSendFundsDialogOpen(true)}>
                <SendIcon />&nbsp;Send
            </Button>
        </div>

        {networkState.error && (
            <span className='error-label visible unavailable-endpoints'>
                {networkState.network?.type === 'RollApp' ? 'RollApp endpoints' : 'Endpoints'} unavailable
            </span>
        )}

        <BalancesList
            balances={networkState.balances?.filter((balance) => balance.amount ||
                (networkState.network && getMainCurrency(networkState.network).baseDenom === balance.currency.baseDenom)) || []}
            loading={(dataRefreshing || networkState.balances === undefined) && networkState.balancesLoading}
            onBalanceClick={onBalanceClick}
            className={className}
            sortByValue
            header={networkState.claimableTransfers?.length ? (
                <Link className='claimable-transfers-link' onClick={() => setClaimableTokensDialogOpen(true)}>
                    {networkState.claimableTransfers.length} pending transfer(s)
                </Link>
            ) : undefined}
        />

        {sendFundsDialogOpen &&
            <SendFundsDialog networkState={networkState} onRequestClose={() => setSendFundsDialogOpen(false)} />}

        {depositWithdrawDialogOpen && network && hubNetwork && (
            <IbcTransferDialog
                className='network-deposit-withdraw-dialog'
                optionalSourceNetworks={depositWithdrawMode === 'Deposit' ? depositWithdrawOptionalVsNetworks : [ network.chainId ]}
                optionalDestinationNetworks={
                    depositWithdrawMode === 'Deposit' ? [ network.chainId ] : depositWithdrawOptionalVsNetworks}
                initialSourceNetwork={
                    depositWithdrawMode === 'Deposit' && network.type === 'Hub' ? depositWithdrawOptionalVsNetworks[0] : undefined}
                initialDestinationNetwork={
                    depositWithdrawMode === 'Withdraw' && network.type === 'Hub' ? depositWithdrawOptionalVsNetworks[0] : undefined}
                onRequestClose={() => {
                    setDepositWithdrawDialogOpen(false);
                    setDepositWithdrawMode('Deposit');
                }}
                depositWithdrawMode={depositWithdrawMode}
                onDepositWithdrawModeChange={setDepositWithdrawMode}
                initialAsset={depositWithdrawMode === 'Deposit' ? asset : undefined}
            />
        )}


        {claimableTokensDialogOpen && (
            <ClaimableTokensDialog
                transfers={networkState.claimableTransfers}
                loading={networkState.claimableTransfersLoading}
                onRequestClose={() => setClaimableTokensDialogOpen(false)}
            />
        )}
    </>;
};

export default AccountBalances;
