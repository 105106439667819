import { uniqBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import Alert from '../../../../../../../shared/components/alert/alert';
import { convertToBech32Address, isValidBech32Address } from '../../../../../../wallet/wallet-service';
import { useCreateRollapp } from '../../../create-rollapp-context';
import Button from '../../../../../../../shared/components/button/button';
import FileUploader from '../../../../../../../shared/components/file-uploader/file-uploader';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import Property from '../../../../../../../shared/components/property/property';
import { useSnackbar } from '../../../../../../../shared/components/snackbar/snackbar-context';
import StatisticsCards from '../../../../../../../shared/components/statistics-cards/statistics-cards';
import useWindowSize from '../../../../../../../shared/hooks/use-window-size';
import { formatNumber, formatPrice } from '../../../../../../../shared/utils/number-utils';
import { getAccountsSupply } from '../../../create-rollapp-service';
import { Account, DEFAULT_DENOM_EXPONENT, MAX_ACCOUNTS } from '../../../types';
import EditAccountsDialog from './edit-accounts-dialog/edit-accounts-dialog';
import './accounts-section.scss';

const ACCOUNT_OBJECT_STRUCTURE = [
    {
        'address': '<lower-hex-address>',
        'amount': '<base-denom-amount>',
    },
    {
        'address': '<lower-hex-address>',
        'amount': '<base-denom-amount>',
        'vesting': { 'start_time': '<unix-timestamp>', 'end_time': '<unix-timestamp>' },
    },
    {
        'address': '<lower-hex-address>',
        'amount': '<base-denom-amount>',
        'hub': true,
    },
];

const AccountsSection: React.FC = () => {
    const { isMobile, isTablet } = useWindowSize();
    const { showErrorMessage } = useSnackbar();
    const {
        rollapp,
        token,
        accounts,
        supplyAllocationAmounts,
        updateAccounts,
        setShouldGenerateGenesis,
    } = useCreateRollapp();
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ fileLoading, setFileLoading ] = useState(false);
    const [ accountsDialogOpen, setAccountsDialogOpen ] = useState(false);

    const toJsDateTime = useCallback((unixDate: number | string) => {
        const numberDate = Number(unixDate);
        return numberDate < 1e12 ? numberDate * 1000 : numberDate;
    }, []);

    const onAccountsFileSelect = useCallback(async (file: File): Promise<void> => {
        setErrorMessage('');
        setFileLoading(true);
        try {
            const fileContent = await file.text();
            const fileAccounts = JSON.parse(fileContent) as Account[];
            let invalidAccountIndex = fileAccounts.findIndex((account) => !account.amount || !account.address);
            if (invalidAccountIndex >= 0) {
                setErrorMessage(`Account #${invalidAccountIndex} doesn't have 'amount' or 'address' field.`);
                return;
            }
            invalidAccountIndex = fileAccounts.findIndex((account) => !account.address.startsWith('0x'));
            if (invalidAccountIndex >= 0) {
                setErrorMessage(`Account #${invalidAccountIndex} has an invalid address. The address must start with the '0x' prefix.`);
                return;
            }
            invalidAccountIndex = fileAccounts.findIndex((account) => account.address.length !== 42);
            if (invalidAccountIndex >= 0) {
                setErrorMessage(`Account #${invalidAccountIndex} has an invalid address length. Address must be exactly 42 characters long (including '0x').`);
                return;
            }
            invalidAccountIndex = fileAccounts.findIndex((account) => isNaN(Number(account.amount)));
            if (invalidAccountIndex >= 0) {
                setErrorMessage(`Account #${invalidAccountIndex} has an invalid amount value.`);
                return;
            }
            invalidAccountIndex = fileAccounts.findIndex((account) =>
                account.vesting && (!account.vesting.start_time || !account.vesting.end_time));
            if (invalidAccountIndex >= 0) {
                setErrorMessage(`Account #${invalidAccountIndex} is vesting but doesn't have 'start_time' or 'end_time' fields.`);
                return;
            }
            invalidAccountIndex = fileAccounts.findIndex((account) =>
                account.vesting && account.vesting.start_time >= account.vesting.end_time);
            if (invalidAccountIndex >= 0) {
                setErrorMessage(`Account #${invalidAccountIndex} has an invalid vesting dates. End time must be after start time.`);
                return;
            }
            for (let accountIndex = 0; accountIndex < fileAccounts.length; accountIndex++) {
                const account = fileAccounts[accountIndex];
                const bech32Prefix = account.hub ? 'dym' : rollapp.bech32Prefix;
                try {
                    account.bech32Address = bech32Prefix && convertToBech32Address(account.address, bech32Prefix);
                    account.amount = BigInt(account.amount);
                    if (account.vesting) {
                        account.vesting.start_time = toJsDateTime(account.vesting.start_time);
                        account.vesting.end_time = toJsDateTime(account.vesting.end_time);
                    }
                } catch {
                    setErrorMessage(`Can't convert account #${accountIndex}'s address to bech32 address.`);
                    return;
                }
                if (account.bech32Address && bech32Prefix && !isValidBech32Address(account.bech32Address, bech32Prefix)) {
                    setErrorMessage(`Account #${accountIndex}'s address converted to an invalid bech32 address.`);
                    return;
                }
            }
            const mergedAccounts = uniqBy([ ...fileAccounts, ...accounts ], (account) => account.address.toLowerCase());
            await updateAccounts(mergedAccounts);
            setShouldGenerateGenesis(true);
        } catch {
            setErrorMessage('Invalid accounts file: unable to fetch and parse account data.');
        } finally {
            setFileLoading(false);
        }
    }, [ accounts, updateAccounts, setShouldGenerateGenesis, rollapp.bech32Prefix, toJsDateTime ]);

    const accountsTotalSupply = useMemo(() => getAccountsSupply(accounts), [ accounts ]);

    const vestingAccounts = useMemo(() => accounts.filter((account) => account.vesting), [ accounts ]);

    const hubAccounts = useMemo(() => accounts.filter((account) => account.hub), [ accounts ]);

    const vestingAccountsBalance =
        useMemo(() => Number(getAccountsSupply(vestingAccounts)) / (10 ** DEFAULT_DENOM_EXPONENT), [ vestingAccounts ]);

    const hubAccountsBalance = useMemo(() => Number(getAccountsSupply(hubAccounts)) / (10 ** DEFAULT_DENOM_EXPONENT), [ hubAccounts ]);

    const allAccountsBalance = useMemo(() => Number(accountsTotalSupply) / (10 ** DEFAULT_DENOM_EXPONENT), [ accountsTotalSupply ]);

    return <>
        <div className='accounts-section section'>
            <h5 className='section-header'>Accounts and Balances</h5>
            <div className='controls-row'>
                <div className='accounts-controls'>
                    <div className='control-container file-uploader-container'>
                        <div className='control-label-container'>
                            <label>Upload Accounts File</label>
                            <InfoIndicator indicatorSize='small'>
                                This JSON file should contain all the allocations of the RollApp token supply.
                            </InfoIndicator>
                        </div>
                        <FileUploader
                            disabled={rollapp.sealed || fileLoading}
                            loading={fileLoading}
                            onFileSelect={onAccountsFileSelect}
                            onBrowseFile={(event) => {
                                if (!token.name) {
                                    showErrorMessage('Missing token name');
                                    event.preventDefault();
                                }
                            }}
                            error={errorMessage}
                        />
                    </div>

                    <Button
                        className='edit-accounts-dialog-button'
                        size='small'
                        buttonType='secondary'
                        disabled={rollapp.sealed}
                        onClick={() => {
                            if (!token.name) {
                                showErrorMessage('Missing token name');
                                return;
                            }
                            setAccountsDialogOpen(!accountsDialogOpen);
                        }}
                    >
                        Add / Edit Accounts
                    </Button>

                    <p className='accounts-limitation-description secondary-text'>
                        For assistance with more than {formatNumber(MAX_ACCOUNTS)} accounts, please reach out to technical support on Discord.
                    </p>
                </div>

                <div className='control-container accounts-json-example'>
                    <div className='control-label-container'><label>Accounts File Example</label></div>

                    <ReactJson
                        style={{ backgroundColor: 'transparent' }}
                        src={ACCOUNT_OBJECT_STRUCTURE}
                        theme='embers'
                        name={false}
                        indentWidth={isMobile ? 2 : 4}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        collapsed={isTablet ? 0 : 2}
                    />
                </div>
            </div>

            <StatisticsCards className='accounts-summary' vertically={isTablet}>
                <Property
                    label={`Regular Accounts Supply (${formatNumber(accounts.length - vestingAccounts.length - hubAccounts.length)})`}
                >
                    {formatPrice(allAccountsBalance - vestingAccountsBalance - hubAccountsBalance, token.name)}
                </Property>
                <Property label={`Vesting Accounts Supply (${formatNumber(vestingAccounts.length)})`}>
                    {formatPrice(vestingAccountsBalance, token.name)}
                </Property>
                <Property label={`Dymension Accounts Supply (${formatNumber(hubAccounts.length)})`}>
                    {formatPrice(hubAccountsBalance, token.name)}
                </Property>
                <Property label={`Total Accounts Supply (${formatNumber(accounts.length)})`}>
                    {formatPrice(allAccountsBalance, token.name)}
                </Property>
            </StatisticsCards>

            {supplyAllocationAmounts.accounts !== accountsTotalSupply && (
                <Alert type='warning' className='total-supply-alert'>
                    The declared accounts supply (<b>{supplyAllocationAmounts.displayAccounts}</b>) doesn't match the amount allocated on your accounts
                    (<b>{formatNumber(allAccountsBalance)}</b>). Adjust your accounts distribution to match the declared supply,
                    or update the declared supply to align with the distributed amount.
                    <Button
                        className='alert-edit-accounts-dialog-button'
                        size='small'
                        buttonType='primary'
                        disabled={rollapp.sealed}
                        onClick={() => {
                            if (!token.name) {
                                showErrorMessage('Missing token name');
                                return;
                            }
                            setAccountsDialogOpen(!accountsDialogOpen);
                        }}
                    >
                        Add / Edit Accounts
                    </Button>
                </Alert>
            )}
        </div>

        {accountsDialogOpen ? <EditAccountsDialog onRequestClose={() => setAccountsDialogOpen(false)} /> : undefined}
    </>;
};

export default AccountsSection;
