import classNames from 'classnames';
import React from 'react';
import Badge from '../../../shared/components/badge/badge';
import { getCssVariableValue } from '../../../shared/utils/color-utils';
import { CoinsAmount } from '../currency-types';
import './display-name-with-future.scss';

interface DisplayNameWithFutureProps {
    coins: CoinsAmount;
    className?: string;
}

const DisplayNameWithFuture: React.FC<DisplayNameWithFutureProps> = ({ coins, className }) => {
    if (!coins.iroDenom) {
        return className ? <span className={className}>{coins.currency.displayDenom}</span> : <>{coins.currency.displayDenom}</>;
    }
    return (
        <span className={classNames('horizontally-centered', className)}>
            {coins.currency.displayDenom}
            <Badge
                className='future-badge'
                rounded
                color={getCssVariableValue('--beige-rgb').split(',').map(Number)}
                size='small'
                label='IRO'
            />
        </span>
    );
};

export default DisplayNameWithFuture;
