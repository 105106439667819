import { CommissionRates, Description, Validator } from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import { encodeEd25519Pubkey } from 'cosmjs/packages/amino';
import { Decimal } from 'cosmjs/packages/math';
import { encodePubkey } from 'cosmjs/packages/proto-signing';
import { MsgCreateValidatorEncodeObject, MsgEditValidatorEncodeObject } from 'cosmjs/packages/stargate';
import { convertToCoin } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { Network } from '../../../network/network-types';
import { convertToBech32Address, convertToHexAddress } from '../../../wallet/wallet-service';

const DEFAULT_MIN_SELF_DELEGATION = '1';

export const createCreateValidatorMessage = (
    description: Description,
    commission: CommissionRates,
    delegatorAddress: string,
    coins: CoinsAmount,
    network: Network,
    baseAmountWithoutFee?: BigInt,
): MsgCreateValidatorEncodeObject => {
    const value = convertToCoin(coins);
    if (baseAmountWithoutFee !== undefined && baseAmountWithoutFee < BigInt(value.amount)) {
        value.amount = baseAmountWithoutFee.toString();
    }
    const validatorAddress = createValidatorAddress(delegatorAddress, network);
    const pubkey = encodePubkey(encodeEd25519Pubkey(crypto.getRandomValues(new Uint8Array(32))));
    description = { ...description, moniker: description.moniker || '-' };
    return {
        typeUrl: '/cosmos.staking.v1beta1.MsgCreateValidator',
        value: {
            description,
            commission,
            delegatorAddress,
            value,
            pubkey,
            validatorAddress,
            minSelfDelegation: DEFAULT_MIN_SELF_DELEGATION,
        },
    };
};

export const createUpdateValidatorMessage = (
    validator: Validator,
    description: Description,
    commission: CommissionRates,
): MsgEditValidatorEncodeObject => {
    return {
        typeUrl: '/cosmos.staking.v1beta1.MsgEditValidator',
        value: {
            description,
            validatorAddress: validator.operatorAddress,
            commissionRate: commission.rate === validator.commission?.commissionRates?.rate ? undefined : commission.rate,
        },
    };
};

export const createValidatorAddress = (delegatorAddress: string, network: Network): string => {
    return convertToBech32Address(convertToHexAddress(delegatorAddress), `${network.bech32Prefix}valoper`);
};

export const createEmptyDescription = (): Description => ({
    moniker: '',
    details: '',
    website: '',
    identity: crypto.randomUUID(),
    securityContact: '',
});

export const createEmptyCommission = (): CommissionRates => ({
    rate: Decimal.fromUserInput('0.05', 18).atomics,
    maxRate: Decimal.fromUserInput('0.2', 18).atomics,
    maxChangeRate: Decimal.fromUserInput('0.01', 18).atomics,
});
