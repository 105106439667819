export const USERS_PATH = 'users';

export interface User {
    id: string;
    address: string;
    avatar: string;
    displayName: string;
}

export const EMPTY_USER: User = { id: '', displayName: '', address: '', avatar: '' };
