import classNames from 'classnames';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import Badge, { BadgeProps } from '../../../shared/components/badge/badge';
import Spinner from '../../../shared/components/spinner/spinner';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import { formatNumber } from '../../../shared/utils/number-utils';
import { getCurrencyLogoPath } from '../../currency/currency-service';
import DisplayNameWithFuture from '../../currency/display-name-with-future/display-name-with-future';
import { useNetwork } from '../../network/network-context';
import { useSponsorship } from '../../sponsorship/sponsorship-context';
import { useIncentives } from '../incentives-context';
import { getDisplayedTargetTime } from '../incentives.service';
import { Incentive } from '../types';
import './incentive-badge.scss';

export interface IncentiveBadgeProps extends Omit<BadgeProps, 'label'> {
    denom: string;
    label?: ReactNode;
    dot?: boolean;
}

const IncentiveBadge: React.FC<IncentiveBadgeProps> = ({
    denom,
    label = 'Incentives',
    infoPlacement = 'top',
    dot,
    className,
    ...otherBadgeProps
}) => {
    const { getNetwork, hubNetwork, hubCurrency } = useNetwork();
    const { incentivesState } = useIncentives();
    const { totalStreamed, endTime, distribution } = useSponsorship();

    const sponsorshipStreamed = useMemo(() => {
        const record = distribution?.find((record) => record.pool?.lpTokenDenom === denom);
        return totalStreamed * (Number(record?.weight) || 0);
    }, [ denom, distribution, totalStreamed ]);

    const activeIncentives = useMemo(
        () => incentivesState.incentives?.[denom]?.filter((incentive) => incentive.startTime <= new Date()),
        [ incentivesState.incentives, denom ],
    );

    const upcomingIncentives = useMemo(
        () => incentivesState.incentives?.[denom]?.filter((incentive) => incentive.startTime > new Date()),
        [ incentivesState.incentives, denom ],
    );

    const upcoming = useMemo(
        () => Boolean(!activeIncentives?.length && upcomingIncentives?.length),
        [ activeIncentives?.length, upcomingIncentives?.length ],
    );

    const renderIncentivesBadgeInfo = (): ReactElement => {
        if (!incentivesState.incentives) {
            return <Spinner />;
        }
        return <div className='tooltip-content'>
            {activeIncentives?.length ? (
                <>
                    <h6>Incentive plans:</h6>
                    {renderIncentives(activeIncentives)}
                </>
            ) : undefined}
            {activeIncentives?.length && upcomingIncentives?.length ? <span className='border' /> : undefined}
            {upcomingIncentives?.length ? (
                <>
                    <h6>Upcoming incentive plans:</h6>
                    {renderIncentives(upcomingIncentives, true)}
                </>
            ) : undefined}
            {sponsorshipStreamed && (activeIncentives?.length || upcomingIncentives?.length) ? <span className='border' /> : undefined}
            {sponsorshipStreamed ? (
                <>
                    <h6>Endorsements:</h6>
                    {renderSponsorshipIncentives()}
                </>
            ) : undefined}
        </div>;
    };

    const renderActiveIncentiveAmounts = (amount: number, incentive: Incentive, coinsIndex: number): ReactElement => {
        const distributedAmount = incentive.distributedCoins[coinsIndex]?.amount || 0;
        return <>
            {formatNumber(distributedAmount, { notation: 'compact' })} / {formatNumber(amount, { notation: 'compact' })}
            &nbsp;streamed&nbsp;&nbsp;
            <span className='end-time'>
              ends in {getDisplayedTargetTime(incentive.endTime)}
            </span>
        </>;
    };

    const renderSponsorshipIncentiveAmounts = (): ReactElement => {
        return <>
            {formatNumber(sponsorshipStreamed, { maximumFractionDigits: 2, notation: 'compact' })} total&nbsp;&nbsp;
            <span className='end-time'>
                ends in {getDisplayedTargetTime(new Date(endTime))}
            </span>
        </>;
    };

    const renderUpcomingIncentiveAmounts = (amount: number, incentive: Incentive): ReactElement => {
        return <>
            {formatNumber(amount, { maximumFractionDigits: 2, notation: 'compact' })} total&nbsp;&nbsp;
            <span className='start-time'>
                starts in {getDisplayedTargetTime(incentive.startTime)}
            </span>
        </>;
    };

    const renderIncentives = (incentives: Incentive[], upcoming?: boolean): ReactElement => {
        return (
            <ul className='incentive-coins-list'>
                {incentives.map((incentive, incentiveIndex) => incentive.coins.map((coins, coinsIndex) => {
                    const currencyNetwork = getNetwork(coins.networkId);
                    if (!currencyNetwork) {
                        return undefined;
                    }
                    return (
                        <li className='coins' key={`${incentiveIndex}-${coinsIndex}`}>
                            <img className='currency-logo' src={getCurrencyLogoPath(coins.currency, currencyNetwork)} alt='currency logo' />
                            <DisplayNameWithFuture coins={coins} />
                            <span className='amount'>
                                {upcoming ?
                                    renderUpcomingIncentiveAmounts(coins.amount, incentive) :
                                    renderActiveIncentiveAmounts(coins.amount, incentive, coinsIndex)}
                            </span>
                        </li>
                    );
                }))}
            </ul>
        );
    };

    const renderSponsorshipIncentives = (): ReactElement => {
        return (
            <ul className='incentive-coins-list'>
                <li className='coins' key='sponsorship'>
                    {hubCurrency && hubNetwork &&
                        <img className='currency-logo' src={getCurrencyLogoPath(hubCurrency, hubNetwork)} alt='currency logo' />}
                    {hubCurrency?.displayDenom}
                    <span className='amount'>{renderSponsorshipIncentiveAmounts()}</span>
                </li>
            </ul>
        );
    };

    if (dot) {
        return (
            <Tooltip title={renderIncentivesBadgeInfo()}>
                <button className={classNames('dot-badge', className, { upcoming })} />
            </Tooltip>
        );
    }
    return (
        <Badge
            className={classNames('incentives-badge', className, { upcoming })}
            label={dot ? '' : label}
            rounded={otherBadgeProps.rounded}
            info={renderIncentivesBadgeInfo()}
            tooltipClassName='tooltip-container'
            infoPlacement={infoPlacement}
            {...otherBadgeProps}
        />
    );
};

export default IncentiveBadge;
