import classNames from 'classnames';
import React, { useMemo } from 'react';
import Dialog, { DialogContent, DialogProps } from '../../../../shared/components/dialog/dialog';
import { isCoinsEquals } from '../../../currency/currency-service';
import { CoinsAmount } from '../../../currency/currency-types';
import { useNetwork } from '../../../network/network-context';
import Swap from '../../../trade/swap/swap';
import { TradeContextProvider } from '../../../trade/trade-context';
import { useAmm } from '../../amm-context';
import { TokensSwapContext, TokensSwapContextProvider } from '../tokens-swap-context';
import './tokens-swap-dialog.scss';

export interface TokensSwapDialogProps extends DialogProps {
    fromCoins?: CoinsAmount;
    toCoins?: CoinsAmount;
}

const TokensSwapDialog: React.FC<TokensSwapDialogProps> = ({ fromCoins, toCoins, className, ...otherDialogProps }) => {
    const { hubNetwork, hubCurrency } = useNetwork();
    const { ammState } = useAmm();

    const { asset1, asset2 } = useMemo((): { asset1?: CoinsAmount, asset2?: CoinsAmount } => {
        if (fromCoins && toCoins && !isCoinsEquals(fromCoins, toCoins)) {
            return { asset1: fromCoins, asset2: toCoins };
        }
        const vsCoins = ammState.params?.vsCoins;
        const mainCoins: CoinsAmount | undefined = hubCurrency &&
            { currency: hubCurrency, amount: 0, networkId: hubNetwork?.chainId || '' };
        if (!vsCoins || !mainCoins) {
            return {};
        }
        if (!fromCoins && !toCoins) {
            return { asset1: vsCoins, asset2: mainCoins };
        }
        const otherAsset = isCoinsEquals((fromCoins || toCoins) as CoinsAmount, mainCoins) ? vsCoins : mainCoins;
        return fromCoins ? { asset1: fromCoins, asset2: otherAsset } : { asset1: otherAsset, asset2: toCoins };
    }, [ ammState.params?.vsCoins, fromCoins, hubCurrency, hubNetwork?.chainId, toCoins ]);

    return (
        <Dialog className={classNames('tokens-swap-dialog', className)} closable {...otherDialogProps}>
            <DialogContent>
                <TokensSwapContextProvider initialAsset1={asset1} initialAsset2={asset2}>
                    <TokensSwapContext.Consumer>
                        {(context) => (
                            <TradeContextProvider {...context} slippageToleranceMode='alert'>
                                <Swap title='Token Swap' actionLabel='Swap' />
                            </TradeContextProvider>
                        )}
                    </TokensSwapContext.Consumer>
                </TokensSwapContextProvider>
            </DialogContent>
        </Dialog>
    );
};

export default TokensSwapDialog;
