import React, { ReactElement, useCallback, useState } from 'react';
import Button from '../../../../../../../shared/components/button/button';
import Icon from '../../../../../../../shared/components/icon/icon';
import Link from '../../../../../../../shared/components/link/link';
import Menu, { MenuAction } from '../../../../../../../shared/components/menu/menu';
import Table, { TableColumn, TableRow } from '../../../../../../../shared/components/table/table';
import Tooltip from '../../../../../../../shared/components/tooltip/tooltip';
import useWindowSize from '../../../../../../../shared/hooks/use-window-size';
import { formatNumber } from '../../../../../../../shared/utils/number-utils';
import { ReactComponent as AppIcon } from '../../../../../../../assets/icons/app.svg';
import { ReactComponent as MoreMenuIcon } from '../../../../../../../assets/icons/menu-more.svg';
import { App } from '../../../../../../client/station-clients/dymension/generated/rollapp/app';
import { useCreateRollapp } from '../../../create-rollapp-context';
import EditAppDialog from './edit-app-dialog/edit-app-dialog';
import './apps-section.scss';
import RemoveAppDialog from './remove-app-dialog/remove-app-dialog';

const AppsSection: React.FC = () => {
    const { isTablet } = useWindowSize();
    const { rollapp } = useCreateRollapp();
    const [ editAppDialogOpen, setEditAppDialogOpen ] = useState(false);
    const [ appToRemove, setAppToRemove ] = useState<App>();
    const [ appToEdit, setAppToEdit ] = useState<App>();

    const toggleEditAppDialog = useCallback((open: boolean, appToEdit?: App) => {
        setEditAppDialogOpen(open);
        setAppToEdit(appToEdit);
    }, []);

    const renderBottomBar = (): ReactElement | undefined => {
        if (!rollapp.apps?.length) {
            return <div className='no-data'>No Apps</div>;
        }
    };

    const renderAppsHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn>Name</TableColumn>
                <TableColumn>URL</TableColumn>
                <TableColumn>Order</TableColumn>
                {!isTablet && <TableColumn>Description</TableColumn>}
                <TableColumn align='right'></TableColumn>
            </TableRow>
        );
    };

    const renderAppRow = (app: App, appIndex: number): ReactElement => {
        return (
            <TableRow key={appIndex} onSelect={() => toggleEditAppDialog(true, app)}>
                <TableColumn className='name-column'>
                    {!app.imageUrl ?
                        <Icon className='app-logo'><AppIcon /></Icon> :
                        <img alt='app logo' src={app.imageUrl} className='app-logo' />}
                    {app.name}
                </TableColumn>
                <TableColumn><Link className='app-url' external url={app.url}>{app.url}</Link></TableColumn>
                <TableColumn>{app.order === -1 ? '' : formatNumber(app.order)}</TableColumn>
                {!isTablet && <TableColumn contentClassName='description-column ellipsis'>{app.description}</TableColumn>}
                <TableColumn align='right'>
                    <Menu closeWhenScroll trigger={<Button buttonType='icon'><MoreMenuIcon /></Button>}>
                        <MenuAction onClick={() => toggleEditAppDialog(true, app)}>Edit</MenuAction>
                        <MenuAction onClick={() => setAppToRemove(app)}>Remove</MenuAction>
                    </Menu>
                </TableColumn>
            </TableRow>
        );
    };

    return (
        <div className='apps-section section'>
            <h5 className='section-header'>
                Apps<span className='space' />
                <Tooltip title={rollapp.status ? undefined : 'Enabled only after RollApp registration.'} placement='left'>
                    <Button size='small' disabled={!rollapp.status} onClick={() => toggleEditAppDialog(true)}>
                        Add App
                    </Button>
                </Tooltip>
            </h5>

            <div className='apps-table-container'>
                <Table indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderAppsHeaderRow()}
                    {rollapp.apps?.map(renderAppRow)}
                </Table>
            </div>

            {editAppDialogOpen && <EditAppDialog appToEdit={appToEdit} closable onRequestClose={() => toggleEditAppDialog(false)} />}

            {appToRemove && <RemoveAppDialog app={appToRemove} closable onRequestClose={() => setAppToRemove(undefined)} />}
        </div>
    );
};

export default AppsSection;
