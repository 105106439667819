import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../shared/components/button/button';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../shared/components/form-controls/input/input';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import Spinner from '../../../shared/components/spinner/spinner';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { formatNumber, formatPrice, roundNumber } from '../../../shared/utils/number-utils';
import { useAsset } from '../../asset/asset-context';
import { useDymns } from '../../dymns/dymns-context';
import { useNetwork } from '../../network/network-context';
import { useSponsorship } from '../sponsorship-context';
import SponsorshipRevokeDialog from '../sponsorship-revoke/sponsorship-revoke-dialog';
import SponsorshipVoteDialog from '../sponsorship-vote/sponsorship-vote-dialog';
import SponsorshipName from './sponsorship-name/sponsorship-name';
import SponsorshipTypeBadge from './sponsorship-type-badge/sponsorship-type-badge';
import { SponsorshipRecord, SPONSORSHIP_TYPES, SponsorshipType } from '../sponsorship-types';
import './sponsorship-list.scss';

interface SponsorshipListProps {
    loading: boolean;
    records: SponsorshipRecord[];
    title?: string;
    editable?: boolean;
    simpleMode?: boolean;
    className?: string;
    headerClassName?: string;
    sponsoredColumnName?: string;
    pagination?: boolean;
    isSponsored?: (sponsorship: SponsorshipRecord) => boolean;
    onEdit?: (gaugeId: number, weight: number | undefined) => void;
}

const MIN_SHOWN_DYM_AMOUNT = 0.1;
const MIN_SHOWN_SPONSORED_WEIGHT = 0.0001;
const PAGE_SIZE = 25;

const SponsorshipList: React.FC<SponsorshipListProps> = ({
    editable,
    headerClassName,
    simpleMode,
    loading,
    className,
    records,
    sponsoredColumnName = 'Endorsed',
    title = 'Endorsements',
    isSponsored,
    pagination,
    onEdit,
}) => {
    const { distribution, totalStreamed, daysStreamed, paramsLoading } = useSponsorship();
    const { hubNetwork, hubCurrency } = useNetwork();
    const { dymnsState } = useDymns();
    const { getTokenPrice } = useAsset();
    const { isMobile } = useWindowSize();
    const { canLoadMore } = useScrollPosition();
    const [ searchText, setSearchText ] = useState('');
    const [ typeFilter, setTypeFilter ] = useState<SponsorshipType | undefined>();
    const [ page, setPage ] = useState(0);
    const [ voteDialogSponsorship, setVoteDialogSponsorship ] = useState<SponsorshipRecord>();
    const [ revokeDialogSponsorship, setRevokeDialogSponsorship ] = useState<SponsorshipRecord>();

    useEffect(() => {
        if (pagination && canLoadMore) {
            setPage((page) => page + 1);
        }
    }, [ pagination, canLoadMore ]);

    const filteredRecords = useMemo(() => {
        let filteredRecords = records.filter((record) => !typeFilter || record.type === typeFilter);
        if (searchText) {
            const fixedSearchText = searchText.trim().toLowerCase();
            filteredRecords = filteredRecords.filter((record) => record.rollapp ?
                record.rollapp.chainName.toLowerCase().includes(fixedSearchText) ||
                record.rollapp.chainId.includes(fixedSearchText) ||
                record.rollapp.currencies.some((currency) =>
                    currency.displayDenom.toLowerCase().includes(fixedSearchText) ||
                    currency.baseDenom.toLowerCase().includes(fixedSearchText)) ||
                dymnsState.aliasesMap[record.rollapp.chainId]?.aliases?.[0]?.toLowerCase().includes(fixedSearchText) : record.pool ?
                    record.pool.assets.some((asset) =>
                        asset.currency.displayDenom.toLowerCase().includes(fixedSearchText) ||
                        asset.currency.baseDenom.toLowerCase().includes(fixedSearchText)) : false,
            );
        }
        if (pagination) {
            filteredRecords = filteredRecords.slice(0, (page + 1) * PAGE_SIZE);
        }
        return filteredRecords;
    }, [ records, searchText, pagination, typeFilter, dymnsState.aliasesMap, page ]);

    useEffect(() => setPage(0), [ searchText, typeFilter ]);

    const onVotingPowerChange = useCallback((gaugeId: number, value: string, previousValue: string): string => {
        if (value.startsWith('.')) {
            value = '0' + value;
        }
        const amountPattern = new RegExp('^([0-9]{1,3})?(\\.[0-9]*)?$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        if (!value) {
            onEdit?.(gaugeId, undefined);
            return '';
        }
        const numberValue = roundNumber(Number(value) || 0, 4);
        onEdit?.(gaugeId, Math.min(100, numberValue));
        if (numberValue > 100) {
            return '100';
        }
        if (value.includes('.')) {
            const valueParts = value.split('.');
            if (valueParts[1].length > 4) {
                return `${valueParts[0]}.${valueParts[1].slice(0, 4)}`;
            }
        }
        return value;
    }, [ onEdit ]);

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!filteredRecords?.length) {
            return <div className='no-data'>No Endorsements</div>;
        }
    };

    const renderNameColumn = (sponsorship: SponsorshipRecord): ReactElement => {
        return <TableColumn className='name-column'><SponsorshipName sponsorship={sponsorship} /></TableColumn>;
    };

    const renderTypeColumn = (sponsorship: SponsorshipRecord): ReactElement => {
        return (
            <TableColumn className='type-column'>
                <SponsorshipTypeBadge
                    className='column-badge'
                    size={simpleMode || isMobile ? 'small' : 'medium'}
                    sponsorship={sponsorship}
                />
            </TableColumn>
        );
    };

    const renderEditedVotingPowerColumn = (sponsorship: SponsorshipRecord): ReactElement => {
        return (
            <TableColumn align={simpleMode ? 'right' : 'left'} className='sponsored-column'>
                <Input
                    className='voting-power-input'
                    value={sponsorship.weight || ''}
                    disabled={paramsLoading}
                    onValueChange={(value, previousValue) => onVotingPowerChange(sponsorship.gaugeId, value, previousValue)}
                    suffix='%'
                />
            </TableColumn>
        );
    };

    const renderTotalSponsoredColumn = (sponsorship: SponsorshipRecord): ReactElement => {
        return (
            <TableColumn className='sponsored-column' contentClassName='sponsored-column' align={simpleMode ? 'right' : 'left'}>
                <span className='horizontally-centered nowrap'>
                    {sponsorship.power && sponsorship.power < MIN_SHOWN_DYM_AMOUNT ? `< ${MIN_SHOWN_DYM_AMOUNT}` :
                        formatPrice(sponsorship.power, '', { minimumFractionDigits: 0 })}&nbsp;
                    <small><b>{hubCurrency?.displayDenom}</b></small>
                </span>
                {sponsorship.power < MIN_SHOWN_DYM_AMOUNT ? undefined :
                    <small className='weight secondary-text'>
                        ({sponsorship.weight < MIN_SHOWN_SPONSORED_WEIGHT ? `< ${MIN_SHOWN_SPONSORED_WEIGHT * 100}` :
                        formatNumber(
                            sponsorship.weight * 100,
                            sponsorship.weight < 0.001 ? { maximumSignificantDigits: 2 } : { maximumFractionDigits: 2 },
                        )}%)
                    </small>}
            </TableColumn>
        );
    };

    const renderStreamedColumn = (sponsorship: SponsorshipRecord): ReactElement => {
        const record = distribution?.find((record) => record.gaugeId === sponsorship.gaugeId);
        const streamed = (record?.weight || 0) * totalStreamed;
        const price = !hubCurrency ? 0 :
            getTokenPrice({ amount: streamed, currency: hubCurrency, networkId: hubNetwork?.chainId || '' }) || 0;

        return (
            <TableColumn className='streamed-column'>
                <span className='horizontally-centered'>
                    {streamed && streamed < MIN_SHOWN_DYM_AMOUNT ? `< ${MIN_SHOWN_DYM_AMOUNT}` : formatPrice(streamed, '')}&nbsp;
                    <small><b>{hubCurrency?.displayDenom}</b></small>
                </span>
                {streamed >= MIN_SHOWN_DYM_AMOUNT && <small className='price secondary-text'>{formatPrice(price)}</small>}
            </TableColumn>
        );
    };

    const renderActionsColumn = (sponsorship: SponsorshipRecord): ReactElement => {
        const sponsored = isSponsored?.(sponsorship);

        return (
            <TableColumn className='actions-column' align='right'>
                {sponsored && (
                    <Button
                        className='revoke-action'
                        size='small'
                        onClick={(event) => {
                            event.stopPropagation();
                            setRevokeDialogSponsorship(sponsorship);
                        }}
                    >
                        Revoke
                    </Button>
                )}
                <Button
                    buttonType='secondary'
                    size='small'
                    onClick={() => setVoteDialogSponsorship(sponsorship)}
                >
                    {sponsored ? 'Update' : 'Endorse'}
                </Button>
            </TableColumn>
        );
    };

    const renderSponsorshipHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn className='name-column'>Name</TableColumn>
                <TableColumn className='type-column'>Type</TableColumn>
                <TableColumn className='sponsored-column' align={simpleMode ? 'right' : 'left'}>{sponsoredColumnName}</TableColumn>
                {!simpleMode && (
                    <TableColumn className='streamed-column'>
                        Expected Incentives ({formatNumber(daysStreamed)} day{daysStreamed === 1 ? '' : 's'})
                    </TableColumn>
                )}
                {!simpleMode && <TableColumn className='actions-column'></TableColumn>}
            </TableRow>
        );
    };

    const renderSponsorshipRow = (sponsorship: SponsorshipRecord): ReactElement => {
        return (
            <TableRow
                key={sponsorship.gaugeId}
                className='sponsorship-row'
                onSelect={() => !simpleMode && setVoteDialogSponsorship(sponsorship)}
            >
                {renderNameColumn(sponsorship)}
                {renderTypeColumn(sponsorship)}
                {editable ? renderEditedVotingPowerColumn(sponsorship) : renderTotalSponsoredColumn(sponsorship)}
                {!simpleMode && renderStreamedColumn(sponsorship)}
                {!simpleMode && renderActionsColumn(sponsorship)}
            </TableRow>
        );
    };

    return (
        <div className={className}>
            <h5 className={classNames('sponsorship-list-header', headerClassName, { simple: simpleMode })}>
                {!simpleMode ? <>{title} ({filteredRecords?.length || 0})</> : undefined}
                <span className='space' />

                <div className='sponsorships-controls'>
                    <ControlsComposer className='sponsorships-filters'>
                        <Input
                            controlSize='medium'
                            value={searchText}
                            type='search'
                            placeholder='Search endorsements...'
                            onValueChange={setSearchText}
                        />
                        <Select
                            value={typeFilter || ''}
                            className='sponsorship-type-select'
                            optionsOverlayAlign='right'
                            onSelect={(type) => setTypeFilter(type as SponsorshipType)}
                            controlSize='medium'
                        >
                            {SPONSORSHIP_TYPES.map((type) => <Option key={type} value={type}>{type}</Option>)}
                            <Option key='all' value=''>All</Option>
                        </Select>
                    </ControlsComposer>
                </div>
            </h5>

            <div className={classNames('sponsorship-list-container', { simple: simpleMode })}>
                <Table className='sponsorship-list' indexColumn={!simpleMode} firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderSponsorshipHeaderRow()}
                    {filteredRecords?.map(renderSponsorshipRow)}
                </Table>
            </div>
            {voteDialogSponsorship &&
                <SponsorshipVoteDialog sponsorship={voteDialogSponsorship} onRequestClose={() => setVoteDialogSponsorship(undefined)} />}

            {revokeDialogSponsorship &&
                <SponsorshipRevokeDialog
                    sponsorship={revokeDialogSponsorship}
                    onRequestClose={() => setRevokeDialogSponsorship(undefined)}
                />}
        </div>
    );
};

export default SponsorshipList;
