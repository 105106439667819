import { StationClient } from '../../client/station-clients/station-client';
import { getNetworkData } from '../../network/network-service';
import { Network } from '../../network/network-types';
import { Delegation, Reward } from '../staking-types';
import { BondStatus, Validator as StakingValidator } from 'cosmjs-types/cosmos/staking/v1beta1/staking';
import { ClientError } from '../../client/client-error';
import { getMaxDenomAmount } from '../../currency/currency-service';
import { fromTimestamp, Long } from 'cosmjs-types/helpers';
import { Currency } from '../../currency/currency-types';
import { convertDecimalToInt, roundNumber } from '../../../shared/utils/number-utils';
import { MAX_VALIDATORS_COUNT, Validator, ValidatorStatus } from './validator-types';

export const VALIDATORS_PAGINATION = {
    limit: Long.fromInt(MAX_VALIDATORS_COUNT),
    offset: Long.fromInt(0),
    countTotal: true,
    reverse: false,
    key: Uint8Array.of(0),
};

export const loadValidators = async (client: StationClient, stakeCurrency: Currency, signal: AbortSignal): Promise<Validator[]> => {
    const network = client.getNetwork();
    let validators: StakingValidator[] = [];
    if (network.collectData) {
        validators = await getNetworkData<StakingValidator[]>(network.chainId, 'validators', false, signal);
    }
    if (!validators.length) {
        const statuses = [ BondStatus.BOND_STATUS_BONDED, BondStatus.BOND_STATUS_UNBONDED, BondStatus.BOND_STATUS_UNBONDING ];
        const validatorsPromises = statuses.map((status) => client.getStakingQueryClient()
            .Validators({ status: BondStatus[status], pagination: VALIDATORS_PAGINATION }));
        const validatorsResponses = await Promise.all(validatorsPromises).catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', network, error);
        });
        validators = validatorsResponses.reduce((current, response) => [ ...current, ...response.validators ], [] as StakingValidator[]);
    }
    return validators.map((validator) => convertValidator(validator, stakeCurrency)).filter(Boolean) as Validator[];
};

export const loadDelegatedValidators = async (
    client: StationClient,
    stakeCurrency: Currency,
    delegatorAddress: string,
): Promise<Validator[]> => {
    const { validators } = await client.getStakingQueryClient()
        .DelegatorValidators({ delegatorAddr: delegatorAddress, pagination: VALIDATORS_PAGINATION })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    return validators.map((validator) => convertValidator(validator, stakeCurrency)).filter(Boolean) as Validator[];
};

export const loadUndelegatingValidators = async (
    client: StationClient,
    stakeCurrency: Currency,
    delegatorAddress: string,
): Promise<Validator[]> => {
    const { unbondingResponses } = await client.getStakingQueryClient()
        .DelegatorUnbondingDelegations({ delegatorAddr: delegatorAddress, pagination: VALIDATORS_PAGINATION })
        .catch((error) => {
            throw new ClientError('FETCH_DATA_FAILED', client.getNetwork(), error);
        });

    const validatorsGroups = await Promise.all(unbondingResponses.filter((response) => response.entries.length)
        .map(async (response) => {
            const { validator: stakedValidator } =
                await client.getStakingQueryClient().Validator({ validatorAddr: response.validatorAddress });
            if (!stakedValidator) {
                return [];
            }
            const validator = convertValidator(stakedValidator, stakeCurrency);
            return response.entries.map((entry) => {
                if (!entry.completionTime || !entry.balance) {
                    return undefined;
                }
                return ({
                    ...validator,
                    unstaking: {
                        completionTime: fromTimestamp(entry.completionTime),
                        amount: getMaxDenomAmount(Number(entry.balance) || 0, stakeCurrency),
                        creationHeight: entry.creationHeight.toNumber(),
                    },
                });
            }).filter(Boolean) as Validator[];
        }));

    return validatorsGroups.reduce((current, group) => [ ...current, ...group ], []);
};


export const getValidatorsWithDelegations = (validators: Validator[], delegations: Delegation[]): Validator[] => {
    return validators.map((validator) => {
        const validatorDelegation = delegations.find((delegation) => delegation.validatorAddress === validator.address);
        return { ...validator, amountStaked: validatorDelegation?.coins?.amount };
    });
};

export const getValidatorsWithRewards = (validators: Validator[], rewards: Reward[]): Validator[] => {
    return validators.map((validator) => {
        const validatorReward = rewards.find((reward) => reward.validatorAddress === validator.address);
        return { ...validator, reward: validatorReward?.coins?.amount || 0 };
    });
};

export const getValidatorLogoPath = (network: Network, validator: Validator) => {
    return encodeURI(`${process.env.REACT_APP_ROLLAPP_REGISTRY_RAW_BASE_URL}/${network.chainId}/logos/governors/${validator.name.trim()}.jpeg`);
};

const getValidatorStatus = (bondStatus: BondStatus): ValidatorStatus | undefined => {
    switch (bondStatus) {
        case BondStatus.BOND_STATUS_BONDED:
            return 'Active';
        case BondStatus.BOND_STATUS_UNBONDED:
            return 'Inactive';
        case BondStatus.BOND_STATUS_UNBONDING:
            return 'Deactivating';
        default:
            return undefined;
    }
};

const convertValidator = (validator: StakingValidator, currency: Currency): Validator | undefined => {
    const status = getValidatorStatus(validator.status);
    if (!validator.description?.moniker || !status) {
        return undefined;
    }
    const validatorTokens = getMaxDenomAmount(Number(validator.tokens) || 0, currency);
    return {
        status,
        name: validator.description?.moniker,
        address: validator.operatorAddress,
        commission: roundNumber(convertDecimalToInt(Number(validator.commission?.commissionRates?.rate) || 0) * 100, 2),
        maxCommission: roundNumber(convertDecimalToInt(Number(validator.commission?.commissionRates?.maxRate) || 0) * 100, 2),
        tokens: { amount: validatorTokens, currency },
        originalValidator: validator,
    } as Validator;
};
