import classNames from 'classnames';
import { isArray } from 'lodash';
import React, { ReactElement, useMemo, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Dialog, { DialogContent, DialogProps, DialogTitle } from '../../../../shared/components/dialog/dialog';
import Icon from '../../../../shared/components/icon/icon';
import InfoIndicator from '../../../../shared/components/info-indicator/info-indicator';
import Tooltip from '../../../../shared/components/tooltip/tooltip';
import { ReactComponent as ThunderIcon } from '../../../../assets/icons/thunder.svg';
import useWindowSize from '../../../../shared/hooks/use-window-size';
import { AccountNetworkState } from '../../account-network-state';
import { useWallet } from '../../../wallet/wallet-context';
import WalletLogo from '../../../wallet/wallet-logo/wallet-logo';
import { isWalletSupported } from '../../../wallet/wallet-service';
import { WALLET_TYPES, WalletType } from '../../../wallet/wallet-types';
import { PORTAL_WALLET_SOURCE_TYPES, PortalWalletSourceType } from '../../../wallet/wallets/portal-wallet/types';
import PortalWalletSourceLogo from '../../../wallet/wallets/portal-wallet/portal-wallet-source-logo';
import ConnectQuickAuthWalletDialog from '../connect-quick-auth-wallet-dialog/connect-quick-auth-wallet-dialog';
import InitPortalWalletDialog from '../init-portal-wallet-dialog/init-portal-wallet-dialog';
import './hub-wallet-selector-dialog.scss';

interface HubWalletSelectorDialogProps extends DialogProps {
    networkState: AccountNetworkState;
}

const HubWalletSelectorDialog: React.FC<HubWalletSelectorDialogProps> = ({ networkState, className, ...otherDialogProps }) => {
    const { connectWallet } = useWallet();
    const { isTablet } = useWindowSize();
    const [ portalWalletSourceType, setPortalWalletSourceType ] = useState<PortalWalletSourceType>();
    const [ connectQuickAuthWalletDialogOpen, setConnectQuickAuthWalletDialogOpen ] = useState(false);

    const supportedWallets = useMemo(
        () => WALLET_TYPES
            .filter((type) => type !== 'PortalWallet' && type !== 'Quick Auth' && networkState.network &&
                isWalletSupported(networkState.network, type)),
        [ networkState.network ],
    );

    const onWalletOptionClick = (walletType: WalletType): void => {
        if (networkState.network) {
            connectWallet(networkState.network.chainId, walletType, true, true);
            otherDialogProps.onRequestClose?.();
        }
    };

    const renderConnectionOptions = (
        title: string,
        recommendedText: string,
        description: string,
        options: { name: string | string[], logo: ReactElement | ReactElement[], disabled?: boolean, onClick: () => void }[],
    ): ReactElement => {
        return (
            <div className='connection-options-group'>
                <h5 className='group-title'>
                    {title}
                    <InfoIndicator tooltipPlacement='bottom'>{description}</InfoIndicator>
                </h5>
                <span className='recommended-subtitle'>{recommendedText}</span>

                {options.map((option, optionIndex) => {
                    const names = isArray(option.name) ? option.name : [ option.name ];
                    const logos = isArray(option.logo) ? option.logo : [ option.logo ];
                    const button = (
                        <Button
                            key={optionIndex}
                            buttonType='secondary'
                            size='large'
                            className='connection-button'
                            iconColorMode='original'
                            onClick={option.onClick}
                            disabled={option.disabled}
                        >
                            {names.map((name, nameIndex) => [
                                nameIndex > 0 ? <span key={`divider-${nameIndex}`} className='divider' /> : undefined,
                                <div className='horizontally-centered' key={nameIndex}>{logos[nameIndex]}{name}</div>,
                            ])}
                        </Button>
                    );
                    return option.disabled ? <Tooltip key={optionIndex} title='Coming soon'>{button}</Tooltip> : button;
                })}
            </div>
        );
    };

    return <>
        <Dialog closable className={classNames('hub-wallet-selector-dialog', className)} {...otherDialogProps}>
            <DialogTitle>Connect</DialogTitle>
            <DialogContent>
                <p className='description'>Connect and interact with Dymension Portal.</p>
                <div className='connection-groups'>
                    {isTablet && renderConnectionOptions(
                        'Mobile',
                        'Recommended for mobile experience',
                        'Uses quick auth to open a secure connection with temporary authorizations to your mobile device',
                        [
                            {
                                name: 'Quick Auth - Link Desktop Wallet',
                                logo: <Icon className='connection-icon quick-auth'><ThunderIcon /></Icon>,
                                onClick: () => setConnectQuickAuthWalletDialogOpen(true),
                            },
                        ],
                    )}

                    {renderConnectionOptions(
                        'Wallet',
                        'Recommended for best security',
                        'Direct wallet strictly uses your private key',
                        supportedWallets.map((walletType) => ({
                            name: walletType,
                            logo: <WalletLogo className='connection-icon' type={walletType} />,
                            onClick: () => onWalletOptionClick(walletType),
                        })),
                    )}

                    {renderConnectionOptions(
                        'Log In',
                        'Recommended for easy experience',
                        'Log in will generate a local secure Dymension wallet derived from your social account or wallet. ' +
                        'You can always generate the same wallet if you have access to your PIN and social account/wallet.',
                        PORTAL_WALLET_SOURCE_TYPES.map((sourceType) => ({
                            name: sourceType === 'MetaMask' ? 'Ethereum Key' : sourceType,
                            disabled: sourceType === 'Apple',
                            logo: <PortalWalletSourceLogo className='connection-icon' type={sourceType} />,
                            onClick: () => setPortalWalletSourceType(sourceType),
                        })),
                    )}
                </div>
            </DialogContent>
        </Dialog>

        {portalWalletSourceType && networkState.network && (
            <InitPortalWalletDialog
                network={networkState.network}
                sourceType={portalWalletSourceType}
                onRequestClose={() => setPortalWalletSourceType(undefined)}
                onConnect={otherDialogProps.onRequestClose}
            />
        )}

        {connectQuickAuthWalletDialogOpen && networkState.network && (
            <ConnectQuickAuthWalletDialog
                onRequestClose={() => setConnectQuickAuthWalletDialogOpen(false)}
                onConnect={otherDialogProps.onRequestClose}
            />
        )}
    </>;
};

export default HubWalletSelectorDialog;
