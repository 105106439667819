/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "dymensionxyz.dymension.dymns";

/**
 * DymNameConfigType specifies the type of the Dym-Name configuration.
 * Currently only supports Name, similar to DNS.
 */
export enum DymNameConfigType {
  DCT_UNKNOWN = 0,
  DCT_NAME = 1,
  UNRECOGNIZED = -1,
}

export function dymNameConfigTypeFromJSON(object: any): DymNameConfigType {
  switch (object) {
    case 0:
    case "DCT_UNKNOWN":
      return DymNameConfigType.DCT_UNKNOWN;
    case 1:
    case "DCT_NAME":
      return DymNameConfigType.DCT_NAME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DymNameConfigType.UNRECOGNIZED;
  }
}

export function dymNameConfigTypeToJSON(object: DymNameConfigType): string {
  switch (object) {
    case DymNameConfigType.DCT_UNKNOWN:
      return "DCT_UNKNOWN";
    case DymNameConfigType.DCT_NAME:
      return "DCT_NAME";
    case DymNameConfigType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * DymName defines a Dym-Name, the mainly purpose is to store ownership and resolution information.
 * Dym-Name is similar to DNS. It is a human-readable name that maps to a chain address.
 * One Dym-Name can have multiple configurations, each configuration is a resolution record.
 * Dym-Name is owned by an account, and is able to grant permission to another account to control the Dym-Name.
 */
export interface DymName {
  /** name is the human-readable name of the Dym-Name. */
  name: string;
  /** owner is the account address that owns the Dym-Name. Owner has permission to transfer ownership. */
  owner: string;
  /**
   * controller is the account address that has permission update configuration for the Dym-Name.
   * Default is the owner. Able to transfer control to another account by the owner.
   * Users can set Dym-Name owned by Cold-Wallet and controlled by Hot-Wallet.
   */
  controller: string;
  /**
   * expire_at is the UTC epoch represent the last effective date of the Dym-Name,
   * after which the Dym-Name is no longer valid.
   * NOTE: Expired Dym-Names are not deleted from the store
   * because iterating through store is very expensive because expiry date must be checked every use.
   */
  expireAt: number;
  /** configs are configuration records for the Dym-Name. */
  configs: DymNameConfig[];
  /**
   * contact is an optional information for the Dym-Name.
   * Convenient for retails users.
   */
  contact: string;
}

/**
 * DymNameConfig contains the resolution configuration for the Dym-Name.
 * Each record is a resolution record, similar to DNS.
 */
export interface DymNameConfig {
  /** type is the type of the Dym-Name configuration (equals to Type in DNS). */
  type: DymNameConfigType;
  /**
   * chain_id is the chain-id of the Dym-Name configuration (equals to top-level-domain).
   * If empty, the configuration is for host chain (Dymension Hub).
   */
  chainId: string;
  /**
   * path of the Dym-Name configuration (equals to Host in DNS).
   * If the type of this config record is Name, it is the Sub-Name of the Dym-Name Address.
   */
  path: string;
  /**
   * value of the Dym-Name configuration resolves to (equals to Value in DNS).
   * If the type of this config record is Name, it is the address which the Dym-Name Address resolves to.
   */
  value: string;
}

/** ReverseLookupDymNames contains a list of Dym-Names for reverse lookup. */
export interface ReverseLookupDymNames {
  /** dym_names is a list of name of the Dym-Names linked to the reverse-lookup record. */
  dymNames: string[];
}

function createBaseDymName(): DymName {
  return { name: "", owner: "", controller: "", expireAt: 0, configs: [], contact: "" };
}

export const DymName = {
  encode(message: DymName, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.owner !== "") {
      writer.uint32(18).string(message.owner);
    }
    if (message.controller !== "") {
      writer.uint32(26).string(message.controller);
    }
    if (message.expireAt !== 0) {
      writer.uint32(32).int64(message.expireAt);
    }
    for (const v of message.configs) {
      DymNameConfig.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.contact !== "") {
      writer.uint32(50).string(message.contact);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DymName {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDymName();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.owner = reader.string();
          break;
        case 3:
          message.controller = reader.string();
          break;
        case 4:
          message.expireAt = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.configs.push(DymNameConfig.decode(reader, reader.uint32()));
          break;
        case 6:
          message.contact = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DymName {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      owner: isSet(object.owner) ? String(object.owner) : "",
      controller: isSet(object.controller) ? String(object.controller) : "",
      expireAt: isSet(object.expireAt) ? Number(object.expireAt) : 0,
      configs: Array.isArray(object?.configs) ? object.configs.map((e: any) => DymNameConfig.fromJSON(e)) : [],
      contact: isSet(object.contact) ? String(object.contact) : "",
    };
  },

  toJSON(message: DymName): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.owner !== undefined && (obj.owner = message.owner);
    message.controller !== undefined && (obj.controller = message.controller);
    message.expireAt !== undefined && (obj.expireAt = Math.round(message.expireAt));
    if (message.configs) {
      obj.configs = message.configs.map((e) => e ? DymNameConfig.toJSON(e) : undefined);
    } else {
      obj.configs = [];
    }
    message.contact !== undefined && (obj.contact = message.contact);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DymName>, I>>(object: I): DymName {
    const message = createBaseDymName();
    message.name = object.name ?? "";
    message.owner = object.owner ?? "";
    message.controller = object.controller ?? "";
    message.expireAt = object.expireAt ?? 0;
    message.configs = object.configs?.map((e) => DymNameConfig.fromPartial(e)) || [];
    message.contact = object.contact ?? "";
    return message;
  },
};

function createBaseDymNameConfig(): DymNameConfig {
  return { type: 0, chainId: "", path: "", value: "" };
}

export const DymNameConfig = {
  encode(message: DymNameConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.chainId !== "") {
      writer.uint32(18).string(message.chainId);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    if (message.value !== "") {
      writer.uint32(34).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DymNameConfig {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDymNameConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.int32() as any;
          break;
        case 2:
          message.chainId = reader.string();
          break;
        case 3:
          message.path = reader.string();
          break;
        case 4:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DymNameConfig {
    return {
      type: isSet(object.type) ? dymNameConfigTypeFromJSON(object.type) : 0,
      chainId: isSet(object.chainId) ? String(object.chainId) : "",
      path: isSet(object.path) ? String(object.path) : "",
      value: isSet(object.value) ? String(object.value) : "",
    };
  },

  toJSON(message: DymNameConfig): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = dymNameConfigTypeToJSON(message.type));
    message.chainId !== undefined && (obj.chainId = message.chainId);
    message.path !== undefined && (obj.path = message.path);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DymNameConfig>, I>>(object: I): DymNameConfig {
    const message = createBaseDymNameConfig();
    message.type = object.type ?? 0;
    message.chainId = object.chainId ?? "";
    message.path = object.path ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseReverseLookupDymNames(): ReverseLookupDymNames {
  return { dymNames: [] };
}

export const ReverseLookupDymNames = {
  encode(message: ReverseLookupDymNames, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dymNames) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReverseLookupDymNames {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReverseLookupDymNames();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dymNames.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReverseLookupDymNames {
    return { dymNames: Array.isArray(object?.dymNames) ? object.dymNames.map((e: any) => String(e)) : [] };
  },

  toJSON(message: ReverseLookupDymNames): unknown {
    const obj: any = {};
    if (message.dymNames) {
      obj.dymNames = message.dymNames.map((e) => e);
    } else {
      obj.dymNames = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReverseLookupDymNames>, I>>(object: I): ReverseLookupDymNames {
    const message = createBaseReverseLookupDymNames();
    message.dymNames = object.dymNames?.map((e) => e) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
