import { Long, PageRequest as LongPageRequest } from 'cosmjs-types/helpers';
import { PageRequest } from './station-clients/dymension/generated/cosmos/base/query/v1beta1/pagination';
import { SigningWasmStationClient } from './station-clients/signing-wasm-station-client';
import { StationClient } from './station-clients/station-client';
import { SigningStationClient } from './station-clients/signing-station-client';

export const DEFAULT_MAX_VALUE = 100000;

export const DEFAULT_GAS_PRICE_STEPS = { low: 0.1, average: 0.25, high: 0.4 };
export const DEFAULT_GAS_ADJUSTMENT = 1.3;
export const DEFAULT_ETH_GAS_PRICE = 11250000;

export interface ClientState {
    client?: StationClient;
    connecting?: boolean;
}

export interface SigningClientState {
    client?: SigningStationClient;
    wasmClient?: SigningWasmStationClient;
    connecting?: boolean;
}

export const ALL_ITEMS_PAGINATION: PageRequest = {
    reverse: false,
    limit: DEFAULT_MAX_VALUE,
    offset: 0,
    countTotal: false,
    key: new Uint8Array(0),
};

export const ALL_ITEMS_PAGINATION_LONG: LongPageRequest = {
    reverse: false,
    limit: Long.fromNumber(DEFAULT_MAX_VALUE),
    offset: Long.fromNumber(0),
    countTotal: false,
    key: new Uint8Array(0),
};

