import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useHubNetworkState } from '../../account/hub-network-state-context';
import { useNetwork } from '../../network/network-context';
import { Network, ROLLAPP_STATUSES } from '../../network/network-types';

interface ManageRollappContextValue {
    managedRollapps?: Network[];
    loading?: boolean;
}

export const ManageRollappsContext = createContext<ManageRollappContextValue>({} as ManageRollappContextValue);

export const useManageRollapps = (): ManageRollappContextValue => useContext(ManageRollappsContext);

export const ManageRollappsContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const { networks, loading: networksLoading } = useNetwork();
    const networkState = useHubNetworkState();

    const loading = useMemo(() => networksLoading || networkState.addressLoading, [ networksLoading, networkState.addressLoading ]);

    const managedRollapps = useMemo(
        () => networks
            .filter((network) => network.type === 'RollApp' && network.owner === networkState.address)
            .sort((rollapp1, rollapp2) =>
                (rollapp2.status ? ROLLAPP_STATUSES.indexOf(rollapp2.status) : -1) -
                (rollapp1.status ? ROLLAPP_STATUSES.indexOf(rollapp1.status) : -1)),
        [ networkState.address, networks ],
    );

    return (
        <ManageRollappsContext.Provider value={{ managedRollapps, loading }}>
            {children}
        </ManageRollappsContext.Provider>
    );
};
