import { PoolPosition, Pool, AmmParams, LockedAsset } from './types';

export interface AmmState {
    pools?: Pool[];
    positions?: PoolPosition[];
    lockedAssets?: LockedAsset[];
    aprs?: { [poolId: number]: number };
    totalLockedValues?: { [denom: string]: number };
    params?: AmmParams;
    loading?: boolean;
    paramsLoading?: boolean;
    lockedAssetsLoading?: boolean;
    positionsLoading?: boolean;
    totalLockedValuesLoading?: boolean;
    searchText?: string;
}

export const AMM_STATE_DEFAULT: AmmState = {
    loading: true,
    positionsLoading: true,
    lockedAssetsLoading: true,
    paramsLoading: true,
    totalLockedValuesLoading: true,
};

const WEEKS_IN_YEAR = 365 / 7;

export type AmmAction =
    { type: 'set-pools', payload: Pool[] | undefined } |
    { type: 'set-params', payload: AmmParams | undefined } |
    { type: 'set-total-locked-values', payload: { [denom: string]: number } | undefined } |
    { type: 'set-locked-assets', payload: LockedAsset[] | undefined } |
    { type: 'set-pool-positions', payload: PoolPosition[] | undefined } |
    { type: 'set-pools-loading', payload?: boolean } |
    { type: 'set-params-loading', payload?: boolean } |
    { type: 'set-total-locked-values-loading', payload?: boolean } |
    { type: 'set-locked-assets-loading', payload?: boolean } |
    { type: 'set-pool-positions-loading', payload?: boolean } |
    { type: 'set-search-text', payload: string | undefined };

export const ammReducer = (state: AmmState, action: AmmAction): AmmState => {
    switch (action.type) {
        case 'set-pools':
            return { ...getFixedPools({ ...state, pools: action.payload }), loading: false };
        case 'set-pools-loading':
            return { ...state, loading: action.payload ?? true };
        case 'set-pool-positions':
            return { ...getFixedPools({ ...state, positions: action.payload }), positionsLoading: false };
        case 'set-pool-positions-loading':
            return { ...state, positionsLoading: action.payload ?? true };
        case 'set-locked-assets':
            return { ...state, lockedAssets: action.payload, lockedAssetsLoading: false };
        case 'set-locked-assets-loading':
            return { ...state, lockedAssetsLoading: action.payload ?? true };
        case 'set-params':
            return { ...state, params: action.payload, paramsLoading: false };
        case 'set-total-locked-values':
            return { ...state, totalLockedValues: action.payload, totalLockedValuesLoading: false };
        case 'set-params-loading':
            return { ...state, paramsLoading: action.payload ?? true };
        case 'set-total-locked-values-loading':
            return { ...state, totalLockedValuesLoading: action.payload ?? true };
        case 'set-search-text':
            return { ...state, searchText: action.payload };
        default:
            return state;
    }
};

const getFixedPools = (state: AmmState): AmmState => {
    const pools = state.pools?.map((pool) => {
        const { liquidity, volume, swapFee } = pool;
        const currentLiquidity = liquidity?.value.value;
        const volumeDiff = volume?.value ? (volume.value - (volume?.previousWeekValue || 0)) : 0;
        const apr = currentLiquidity && volumeDiff && swapFee ? volumeDiff * WEEKS_IN_YEAR * swapFee / liquidity.value.value : 0;
        return { ...pool, apr, position: state.positions?.find((position) => position.poolId === pool.id) };
    });
    return { ...state, pools };
};
