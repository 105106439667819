import React, { useMemo } from 'react';
import Property from '../../../shared/components/property/property';
import StatisticsCards from '../../../shared/components/statistics-cards/statistics-cards';
import Tooltip from '../../../shared/components/tooltip/tooltip';
import { getMainCurrency, getStakingCurrency, isCoinsEquals } from '../../currency/currency-service';
import { ReactComponent as WalletIcon } from '../../../assets/icons/wallet.svg';
import { useStaking } from '../staking-context';
import { formatPrice } from '../../../shared/utils/number-utils';
import Button from '../../../shared/components/button/button';
import './staking-analytics.scss';

export default function StakingAnalytics(): JSX.Element {
    const {
        network,
        networkState,
        stakingDataState,
        stakedValidatorsData,
        unstakingValidatorsData,
        rewardsTxState,
        withdrawRewards,
    } = useStaking();

    const stakingCurrency = useMemo(() => getStakingCurrency(network), [ network ]);

    const mainCurrency = useMemo(() => getMainCurrency(network), [ network ]);

    const availableBalanceAmount = useMemo(() => {
        const amount = networkState.balances?.find((balance) =>
            isCoinsEquals(balance, { currency: stakingCurrency, amount: 0, networkId: networkState.network?.chainId || '' }))?.amount;
        return amount === undefined ? undefined : formatPrice(amount, '');
    }, [ networkState.balances, networkState.network?.chainId, stakingCurrency ]);

    const stakedAmount = useMemo(() => {
        const amount = stakingDataState?.delegations?.reduce((current, validator) => current + (validator?.coins?.amount || 0), 0);
        return amount === undefined ? undefined : formatPrice(amount, '');
    }, [ stakingDataState?.delegations ]);

    const unstakingAmount = useMemo(() => {
        const amount = unstakingValidatorsData?.state?.validators
            ?.reduce((current, validator) => current + (validator.unstaking?.amount || 0), 0);
        return amount === undefined ? undefined : formatPrice(amount, '');
    }, [ unstakingValidatorsData?.state?.validators ]);

    const totalRewardsAmount = useMemo(
        () => stakedValidatorsData?.state?.validators?.reduce((current, validator) => current + (validator.reward || 0), 0),
        [ stakedValidatorsData?.state?.validators ],
    );

    return (
        <StatisticsCards>
            <Property
                label='My Available Balance'
                valueSuffix={stakingCurrency.displayDenom}
                loading={availableBalanceAmount === undefined && networkState.balancesLoading}
                na={!networkState.address}
            >
                {availableBalanceAmount || 0}
            </Property>

            <Property
                label='My Staked Amount'
                valueSuffix={stakingCurrency.displayDenom}
                loading={stakedAmount === undefined && stakingDataState?.delegationsLoading}
                na={!networkState.address}
            >
                {stakedAmount || 0}
            </Property>

            <Property
                label='My Unstaking Amount'
                valueSuffix={stakingCurrency.displayDenom}
                loading={unstakingAmount === undefined && unstakingValidatorsData?.state?.loading}
                na={!networkState.address}
            >
                {unstakingAmount || 0}
            </Property>

            <Property
                label='My Claimable Rewards'
                className='withdraw-property'
                valueSuffix={mainCurrency.displayDenom}
                loading={totalRewardsAmount === undefined && (stakedValidatorsData?.state?.loading || stakingDataState?.rewardsLoading)}
                na={!networkState.address}
            >
                {!totalRewardsAmount ? 0 : `${(totalRewardsAmount < 0.0001 ? '< 0.0001' : formatPrice(totalRewardsAmount, ''))}`}

                <Tooltip title='Claim all' placement='left'>
                    <Button
                        className='withdraw-button'
                        buttonType='icon'
                        disabled={!totalRewardsAmount || rewardsTxState?.broadcasting}
                        loading={rewardsTxState?.broadcasting}
                        onClick={() => withdrawRewards()}
                    >
                        <WalletIcon />
                    </Button>
                </Tooltip>
            </Property>
        </StatisticsCards>
    );
}


