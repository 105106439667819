import React, { useCallback, useEffect, useMemo } from 'react';
import Duration from '../../../../../../../shared/components/form-controls/duration/duration';
import Input from '../../../../../../../shared/components/form-controls/input/input';
import InfoIndicator from '../../../../../../../shared/components/info-indicator/info-indicator';
import { getFormattedLocalDateTime, timeToMilliseconds } from '../../../../../../../shared/utils/date-utils';
import { formatPrice } from '../../../../../../../shared/utils/number-utils';
import { useAsset } from '../../../../../../asset/asset-context';
import { useIRO } from '../../../../../../iro/iro-context';
import { useNetwork } from '../../../../../../network/network-context';
import { useCreateRollapp } from '../../../create-rollapp-context';
import './iro-plan-section.scss';

const IroPlanSection: React.FC = () => {
    const { hubNetwork, hubCurrency } = useNetwork();
    const { getTokenPrice } = useAsset();
    const { iroParams, iroParamsLoading } = useIRO();
    const { showErrors, iro, haveIRO, supplyAllocation, rollapp, setIro } = useCreateRollapp();

    const minPlanDuration = useMemo(
        () => timeToMilliseconds({ seconds: iroParams?.minPlanDuration?.seconds || 0 }),
        [ iroParams?.minPlanDuration?.seconds ],
    );

    useEffect(() => {
        if (iro.planDuration < minPlanDuration) {
            setIro({ ...iro, planDuration: minPlanDuration });
        }
    }, [ iro, minPlanDuration, setIro ]);

    const onTargetRaiseChange = useCallback((value: string, previousValue: string) => {
        const amountPattern = new RegExp('^[0-9]{0,10}$');
        if (!amountPattern.test(value)) {
            return previousValue;
        }
        const fixedValue = !value ? undefined : Math.min(Number(value), Math.pow(10, 9));
        setIro({ ...iro, targetRaise: fixedValue });
        return fixedValue ? fixedValue.toString() : undefined;
    }, [ iro, setIro ]);

    const onStartTimeChange = useCallback(
        (value: string) => setIro({ ...iro, startTime: !value ? undefined : new Date(value).getTime() }),
        [ iro, setIro ],
    );

    const onPlanDurationChange = useCallback((value: number) => setIro({ ...iro, planDuration: value }), [ iro, setIro ]);

    const targetRaisePrice = useMemo(
        () => !hubCurrency ? 0 :
            getTokenPrice({ currency: hubCurrency, amount: iro.targetRaise || 0, networkId: hubNetwork?.chainId || '' }) || 0,
        [ getTokenPrice, hubCurrency, hubNetwork?.chainId, iro.targetRaise ],
    );

    return (
        <div className='iro-plan-section section'>
            <h5 className='section-header'>
                Initial RollApp Offering (IRO) Settings
                <InfoIndicator indicatorSize='small'>
                    Initial RollApp Offering (IRO) is a pre-launch sale of RollApp tokens.<br />
                    DYM raised is used to seed the token liquidity pool when the RollApp launches,
                    while unsold tokens are used as incentives for liquidity providers.
                </InfoIndicator>
            </h5>

            <div className='controls-row'>
                {!rollapp.preLaunchTime ? (
                    <div className='control-container'>
                        <div className='control-label-container'>
                            <label className='required'>Target Raise (DYM)</label>
                            <InfoIndicator indicatorSize='small'>
                                Specify the total amount you aim to raise for token liquidity.
                                This is the projected income if all allocated IRO tokens are sold.<br /><br />
                                <b>Note</b>:
                                <ul className='list'>
                                    <li>This amount shapes the bonding curve, influencing token pricing and sale dynamics. A higher target raise makes it harder to sell all tokens and slows down price increases.</li>
                                    <li>Any unsold tokens will be used as liquidity incentives. Ensure your target aligns with funding goals and market demand.</li>
                                </ul>
                            </InfoIndicator>
                        </div>
                        <Input
                            value={iro.targetRaise}
                            onValueChange={onTargetRaiseChange}
                            disabled={Boolean(rollapp.sealed || !haveIRO)}
                            className='target-raise'
                            error={showErrors && !iro.targetRaise && 'Target raise is required'}
                            suffix={(
                                <p className='target-raise-suffix'>
                                    ≈&nbsp;
                                    {formatPrice(targetRaisePrice, undefined, { notation: 'compact', maximumFractionDigits: 3 })}
                                </p>
                            )}
                        />
                        <small className='target-valuation'>
                            Target valuation:&nbsp;
                            {formatPrice(targetRaisePrice * 100 / (supplyAllocation.iro || 1), undefined, { notation: 'compact' })}
                        </small>
                    </div>
                ) : undefined}

                <div className='control-container'>
                    <div className='control-label-container'>
                        <label>Pre-Sale Start Time</label>
                        <InfoIndicator indicatorSize='small'>
                            Set the date and time when the Initial RollApp Offering (IRO) will begin.
                            This marks the start of the pre-sale period for your RollApp tokens.
                        </InfoIndicator>
                    </div>
                    <Input
                        loading={!iroParams && iroParamsLoading}
                        value={getFormattedLocalDateTime(iro.startTime ? new Date(iro.startTime) : new Date())}
                        type='datetime-local'
                        disabled={Boolean(!iroParams || rollapp.sealed || !haveIRO)}
                        onValueChange={onStartTimeChange}
                    />
                </div>

                <div className='control-container'>
                    <div className='control-label-container'>
                        <label>Minimum IRO Duration</label>
                        <InfoIndicator indicatorSize='small'>
                            The RollApp cannot launch before this period ends, but the IRO can continue beyond this duration until
                            the RollApp sequencer bonds DYM to launch the RollApp.
                        </InfoIndicator>
                    </div>
                    <Duration
                        minTimeUnit='minutes'
                        value={iro.planDuration}
                        minDuration={minPlanDuration}
                        disabled={Boolean(!iroParams || rollapp.sealed || !haveIRO)}
                        onValueChange={onPlanDurationChange}
                        loading={!iroParams && iroParamsLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default IroPlanSection;
