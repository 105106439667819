import { camelCase } from 'lodash';

export const convertKeysToCamelCase = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = camelCase(key);
            acc[camelKey] = convertKeysToCamelCase(obj[key]);
            return acc;
        }, {} as { [key: string]: any });
    }
    return obj;
};

export const filterNonEmptyValues = <T>(list: (T | undefined | null | '' | false | 0)[]): T[] => {
    return list.filter(Boolean) as T[];
}
