import { RouteRequestGivenIn, RouteResponse, SkipClient } from '@skip-go/client';
import { getMinDenomAmount } from '../../currency/currency-service';
import { CoinsAmount } from '../../currency/currency-types';
import { BridgeData } from './bridge-types';

export const findRoute = async (
    client: SkipClient,
    bridgeData: BridgeData,
    amountIn: CoinsAmount,
    options?: Partial<RouteRequestGivenIn>,
): Promise<RouteResponse> => {
    const amount = !amountIn.currency.cctp ? amountIn.amount : Math.max(amountIn.amount, Number(process.env.REACT_APP_CCTP_MIN_AMOUNT) / 2);
    return client.route({
        amountIn: getMinDenomAmount(amount, amountIn.currency).toString(),
        sourceAssetDenom: bridgeData.sourceDenom || '',
        sourceAssetChainID: bridgeData.sourceBridgeId || '',
        destAssetDenom: bridgeData.destinationDenom || '',
        destAssetChainID: bridgeData.destinationBridgeId || '',
        smartRelay: true,
        goFast: true,
        allowUnsafe: true,
        bridges: [ 'CCTP', 'IBC' ],
        experimentalFeatures: [ 'hyperlane', 'cctp' ],
        ...options,
    });
};
