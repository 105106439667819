import React, { useMemo } from 'react';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { useAmm } from '../../../../amm/amm-context';
import { useAsset } from '../../../../asset/asset-context';
import { getMaxDenomAmount } from '../../../../currency/currency-service';
import { useNetworkDashboard } from '../../../../network-dashboard/network-dashboard-context';
import AreaChart from '../area-chart/area-chart';
import { SelectableChartProps } from '../chart-container/chart-container';

interface MarketCapChartProps extends SelectableChartProps {
    className?: string;
}

export const MarketCapChart: React.FC<MarketCapChartProps> = ({ className, ...selectableChartProps }) => {
    const { analyticsState, network } = useNetworkDashboard();
    const { ammState } = useAmm();
    const { mainAssetMap } = useAsset();

    const nativeAsset = useMemo(() => mainAssetMap?.[network.chainId], [ mainAssetMap, network.chainId ]);

    return (
        <AreaChart
            {...selectableChartProps}
            na={!analyticsState.analytics?.totalSupply || nativeAsset?.invalidMarketCap}
            label={network.type === 'RollApp' ? 'FDV Market Cap' : 'Market Cap'}
            valueLabel='Value'
            fetchComparableValues={(item) => {
                if (!ammState.params || !item.marketCap || nativeAsset?.invalidMarketCap) {
                    return 0;
                }
                const circulatingSupplyPart = network.type === 'Hub' ? (item.amount - item.vesting - item.onChainGov) / item.amount : 1;
                return getMaxDenomAmount(item.marketCap * circulatingSupplyPart, ammState.params?.vsCoins.currency);
            }}
            formatValue={(value, options) =>
                formatPrice(value, undefined, { ...options, minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            className={className}
            loading={!analyticsState.analytics && (analyticsState.loading || ammState.paramsLoading)}
            data={analyticsState.analytics?.totalSupply}
        />
    );
};

export default MarketCapChart;
